import createAction from '@/helpers/redux/createAction'
import createTableActions from '@/helpers/redux/createTableActions'

export const GET_ANALYTICS_ALARMS_TABLE = createAction('@/GET_ANALYTICS_ALARMS_TABLE')
export const GET_ANALYTICS_ALARMS_FILE = createAction('@/GET_ANALYTICS_ALARMS_FILE')
export const GET_ANALYTICS_ALARMS_ALARM = createAction('@/GET_ANALYTICS_ALARMS_ALARM')
export const GET_ANALYTICS_ALARMS_WIDGET = createAction('@/GET_ANALYTICS_ALARMS_WIDGET')
export const ANALYTICS_ALARMS_TABLE = createTableActions('@/ANALYTICS_ALARMS_TABLE')

export const SET_TABEL_DATA = '@/ANALYTICS_ALARMS_SET_TABEL_DATA'
export const SET_EVENTS_TYPES = '@/ANALYTICS_ALARMS_SET_EVENTS_TYPES'
export const SET_STATUS_TYPES = '@/ANALYTICS_ALARMS_SET_STATUS_TYPES'
export const SET_SELECTED_ELEMENT = '@/ANALYTICS_ALARMS_SET_SELECTED_ELEMENT'
export const SET_SIDE_BAR_FILTER = '@/ANALYTICS_ALARMS_SET_SIDE_BAR_FILTER'
export const SET_FILTER_VALUES = '@/ANALYTICS_ALARMS_SET_FILTER_VALUES'
export const RESET_FILTER_VALUES = '@/ANALYTICS_ALARMS_RESET_FILTER_VALUES'

export const setSelectedElement = (payload) => ({
  type: SET_SELECTED_ELEMENT,
  payload,
})
export const setEventTypes = (payload) => ({
  type: SET_EVENTS_TYPES,
  payload,
})
export const setStatusTypes = (payload) => ({
  type: SET_STATUS_TYPES,
  payload,
})
export const setSideBarFilter = (payload) => ({
  type: SET_SIDE_BAR_FILTER,
  payload,
})
export const setFilterValues = (payload) => ({
  type: SET_FILTER_VALUES,
  payload,
})
export const resetFilterValues = (payload) => ({
  type: RESET_FILTER_VALUES,
  payload,
})
