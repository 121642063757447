import React from 'react'
import pt from 'prop-types'
import cookie from 'react-cookies'
import isString from 'lodash/isString'
import { FormattedMessage as Lang } from 'react-intl'
import { routerHistoryType } from '@/propTypes/router'
import { DASHBOARD_URL } from '@/constants/routes'
import TabsController from '@/components/blocks/TabsController'
import SignInForm from '@/forms/SignInForm'
import ForgotPassword from '@/forms/ForgotPassword'
import CoreIcons from '@/components/icons/core'
import Loader from '@/components/blocks/Loader'
import GlobalPopup from '@/components/blocks/GlobalPopup'
import InstallMobilePopup from '@/components/blocks/installMobilePopup'
import {
  RU_LANGUAGE,
  EN_LANGUAGE,
  SLS24_ACCESS_TOKEN,
  ACTIVE_PING_ITEM,
  ACTIVE_REQUEST_ITEM,
} from '@/constants/names'
import {
  IDLE,
} from '@/constants/requests'
import {
  SignInStyleWrapper,
  FormWrapper,
  StyledButton,
  Text,
} from './styles'

class SignIn extends React.Component {
  state = {
    tabs: [
      {
        title: 'Русский',
        value: RU_LANGUAGE,
      },
      {
        title: 'English',
        value: EN_LANGUAGE,
      },
    ],
    submitted: false,
    forgotPassword: false,
    popupOpen: false,
    isIos: false,
    deferredPrompt: null,
  };

  componentDidMount = () => {
    if (window.localStorage.getItem('a') || cookie.load(SLS24_ACCESS_TOKEN)) {
      const { history } = this.props
      history.push(DASHBOARD_URL)

      // clear values for handle activity. See axios helper and useHandleActivity hook for more info
      window.localStorage.setItem(ACTIVE_REQUEST_ITEM, '0')
      window.localStorage.setItem(ACTIVE_PING_ITEM, '0')
    }

    const viewport = document.querySelector("meta[name=viewport]");
    viewport.setAttribute('content', 'width=device-width');
    if (navigator.userAgent.includes('iPhone') && !window.matchMedia('(display-mode: standalone)').matches) {
      this.setState({ popupOpen: true })
    }
    window.addEventListener('beforeinstallprompt', (event) => {
      event.preventDefault();
      this.setState({ deferredPrompt: event })
      if (navigator.userAgent.includes('Mobile')) {
        this.setState({ popupOpen: true })
      }
    });
  }

  handleChangeTab = (value) => {
    const { onSetLanguage } = this.props
    onSetLanguage(value)
  }

  handleForgotPassword = () => {
    this.setState((state) => ({ forgotPassword: !state.forgotPassword }))
  }

  handleSubmitted = (value) => {
    this.setState({ submitted: value })
  }

  handleResetLoginError = () => {
    const { resetLoginError } = this.props
    resetLoginError({})
  }

  handleGoToAuthorization = () => {
    this.setState({
      submitted: false,
      forgotPassword: false,
    })
  }

  handleInstallClick = () => {
    const { deferredPrompt  } = this.state

    if (navigator.userAgent.includes('iPhone')) {
      this.setState({ isIos: true })
      return
    }
    if (deferredPrompt) {
      deferredPrompt.prompt();
    }
    this.setState({ popupOpen: false })
  }

  handleClick = () => {
    this.setState({ popupOpen: false })

  }

  renderForm = () => {
    const { forgotPassword, submitted } = this.state
    const {
      onLoginUser, requestUserForgotPassword, forgotPasswordStatus, loginError,
    } = this.props
    if (
      isString(loginError.error)
      && loginError.error.includes('auth_credentials')
      && loginError.error.includes('blocked')
    ) {
      return (
        <>
          <Text error>
            <Lang id="signIn.errorText" />
          </Text>
          <StyledButton styleType="text" onClick={this.handleResetLoginError}>
            <Lang id="signIn.goToAuthorization" />
          </StyledButton>
        </>
      )
    }
    if (submitted && forgotPasswordStatus === IDLE) {
      return (
        <>
          <Text>
            <Lang id="signIn.submittedText" />
          </Text>
          <StyledButton styleType="text" onClick={this.handleGoToAuthorization}>
            <Lang id="signIn.goToAuthorization" />
          </StyledButton>
        </>
      )
    }
    if (forgotPassword) {
      return (
        <ForgotPassword
          handleForgotPassword={this.handleForgotPassword}
          requestUserForgotPassword={requestUserForgotPassword}
          handleSubmitted={this.handleSubmitted}
        />
      )
    }
    return (
      <SignInForm
        onFormSubmit={onLoginUser}
        handleForgotPassword={this.handleForgotPassword}
      />
    )
  }

  render() {
    const { tabs, popupOpen, isIos } = this.state
    const { language, loading } = this.props

    const disabledSlsLogo = process.env.REACT_APP_DISABLE_SLS_LOGO === 'true'

    return (
      <SignInStyleWrapper hidden={loading}>
        {popupOpen && <GlobalPopup content={<InstallMobilePopup onClick={this.handleInstallClick} onClose={this.handleClick} isIos={isIos}/>}/>}
        {loading && (<Loader fullScreen />)}
        <FormWrapper hidden={loading}>
          {disabledSlsLogo ? (<CoreIcons.BLGLogoIcon />) : (<CoreIcons.LogoIcon />)}
          <TabsController
            nameStyles="fullWidth"
            activeTab={language}
            tabs={tabs}
            onTabChange={this.handleChangeTab}
            withUrlChange={false}
          />
          {this.renderForm()}
        </FormWrapper>
      </SignInStyleWrapper>
    )
  }
}

SignIn.propTypes = {
  language: pt.string.isRequired,
  forgotPasswordStatus: pt.string.isRequired,
  onSetLanguage: pt.func.isRequired,
  onLoginUser: pt.func.isRequired,
  resetLoginError: pt.func.isRequired,
  history: routerHistoryType.isRequired,
  requestUserForgotPassword: pt.func.isRequired,
  loading: pt.bool.isRequired,
  loginError: pt.objectOf.isRequired,
}

export default SignIn
