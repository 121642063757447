import styled from 'styled-components'

export const EmptyDataStyled = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100% - 49px);
  font-size: 15px;
  color: #5694f3;
  @media (max-width: 910px){
    width: 100vw;
  }
`