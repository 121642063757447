import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import moment from 'moment/moment'
import { SCHEDULE_MANAGER_FAILURE_LOG_TABLE_ENDPOINT } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import {
  successScheduleManagerFailureLogTable,
  errorScheduleManagerFailureLogTable
} from '@/store/actions/scheduleManager/getFailureLogTable'
import {
  GET_SCHEDULE_MANAGER_FAILURE_LOG_TABLE,
  SCHEDULE_MANAGER_FAILURE_LOG_TABLE,
  SET_ERROR_STATUS
} from '@/store/actions/scheduleManager'
import {
  getErrorStatus,
  getFailureLogEndDate,
  getFailureLogStartDate,
  getFailureLogTableParameters
} from '@/store/selectors/scheduleManager/failureLog'
import { getScheduleSelectedNode } from '@/store/selectors/scheduleManager'
import { ROOT } from '@/constants/objectTypes'

function* getScheduleManagerFailureLogTableSaga() {
  try {
    const tableParameters = yield select(getFailureLogTableParameters)
    const startDate = yield select(getFailureLogStartDate)
    const endDate = yield select(getFailureLogEndDate)
    const status = yield select(getErrorStatus)
    const scheduleSelectedNode = yield select(getScheduleSelectedNode)

    const response = yield request({
      url: SCHEDULE_MANAGER_FAILURE_LOG_TABLE_ENDPOINT({
        params: {
          pageNumber: tableParameters.page - 1,
          pageSize: tableParameters.perPage,
          search: tableParameters.requestQuery || '',
          sort: [`${tableParameters.sort.field}: ${tableParameters.sort.direction}`],
          fromDate: startDate ? moment(startDate).format('DD.MM.YYYY') : null,
          toDate: endDate ? moment(endDate).format('DD.MM.YYYY') : null,
          geoZoneId: !!scheduleSelectedNode.type && scheduleSelectedNode.type !== ROOT ? scheduleSelectedNode.id : null,
          scheduleId: !scheduleSelectedNode.type ? scheduleSelectedNode.id : null,
          isResolved: status
          },
      }),
      method: 'get',
    })

    const { content, pageable } = response.data
    yield put(successScheduleManagerFailureLogTable({
      data: content.map(item => {
        return {
          ...item,
          сreatedDateTime: moment(item.сreatedDateTime).format('DD.MM.YYYY')
        }
      }),
      totalElements: pageable.totalElements,
    }))

  } catch (error) {
      yield put(errorScheduleManagerFailureLogTable(error))
      const toast = createNotifications()
      toast({
        title: 'Ошибка операции!',
        description: 'Не удалось получить данные журнала сбоя в работе расписаний.\nПовторите попытку позже.',
        type: 'error',
      })
  }
  return null
}

export default function* root() {
  yield takeLatest(GET_SCHEDULE_MANAGER_FAILURE_LOG_TABLE.REQUEST, getScheduleManagerFailureLogTableSaga)
  yield takeLatest(SCHEDULE_MANAGER_FAILURE_LOG_TABLE.SORT, getScheduleManagerFailureLogTableSaga)
  yield takeLatest(SCHEDULE_MANAGER_FAILURE_LOG_TABLE.PER_PAGE, getScheduleManagerFailureLogTableSaga)
  yield takeLatest(SCHEDULE_MANAGER_FAILURE_LOG_TABLE.PAGE, getScheduleManagerFailureLogTableSaga)
  yield takeLatest(SCHEDULE_MANAGER_FAILURE_LOG_TABLE.REQUEST_QUERY, getScheduleManagerFailureLogTableSaga)
  yield takeLatest(SET_ERROR_STATUS, getScheduleManagerFailureLogTableSaga)
}