import React from 'react';
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import DraggableItemWrapper, { MoveIconWrapper , Title, Row, IconWrapper} from './styles'
import HeaderIcons from '@/components/icons/header'
import Icon from '@/components/icons/mobileIcons/index'


const DraggableItemMobile = ({ id, title, onDeleteWidget }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id })

  const style = {
    transition,
    transform: CSS.Transform.toString(transform)
  }

  return (
   <DraggableItemWrapper ref={setNodeRef} style={style} >
       <Row>
        <IconWrapper>
        <Icon.DeleteIcon onClick={() => onDeleteWidget(id)}/>
        </IconWrapper>
        <Title>{title}</Title>
          <MoveIconWrapper {...listeners} {...attributes}>
          <HeaderIcons.MenuHamburgerIcon />
          </MoveIconWrapper>
        </Row>
   </DraggableItemWrapper>
  )
}

export default DraggableItemMobile