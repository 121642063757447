import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import moment from 'moment'
import request from '@/helpers/axios'
import {
  GET_CONSUMPTION_ANALYSIS_PLAN_GRAPH_DATA,
} from '@/store/actions/consumptionAnalysis'
import {
  responsePlanGraphData,
  errorPlanGraphData,
} from '@/store/actions/consumptionAnalysis/getPlanGraphData'
import { getSelectedNode } from '@/store/selectors/appSettings'
import createNotifications from '@/helpers/notification'
import {
  getForm,
  getTableParameters,
} from '@/store/selectors/consumptionAnalysis'
import {
  RESOURCE_ANALYTICS_PLAN_GRAPH_ENDPOINT,
} from '@/constants/apiRoutes'
import { INSTALLED, UNINSTALLED } from '@/constants/objectStatuses'

function* getPlanGraphDataSaga() {
  const toast = createNotifications()
  try {
    const formValues = yield select(getForm)
    const node = yield select(getSelectedNode)
    const tableParameters = yield select(getTableParameters)
    const {
      type,
      graphView,
      dateFirst,
      dateSecond,
    } = formValues

    const response = yield request({
      url: RESOURCE_ANALYTICS_PLAN_GRAPH_ENDPOINT({
        objectStates: [INSTALLED, UNINSTALLED],
        aliasId: node.asuId,
        startDate: moment(dateFirst).format('DD.MM.YYYY'),
        endDate: moment(dateSecond).format('DD.MM.YYYY'),
        includedColumns: tableParameters.activeFields,
        pageNumber: tableParameters.page - 1,
        pageSize: tableParameters.perPage,
        parentId: node.id,
        query: tableParameters.requestQuery || '',
        sort: [`${tableParameters.sort.field}: ${tableParameters.sort.direction}`],
        type: type.toUpperCase()
      }),
      method: 'get',
    })

    if (response) {
      const { error } = response.data

      if (error) {
        yield put(errorPlanGraphData())
      } else {
        const calculatorValue = response.data.content
          .reduce((accumulator, row) => accumulator + row.difference, 0)
        yield put(responsePlanGraphData({
          ...response.data,
          graphType: graphView,
          calculatorValue: Math.ceil(calculatorValue * 100) / 100,
        }))
      }
    } else {
      yield put(errorPlanGraphData())
    }
  } catch (error) {
    const node = yield select(getSelectedNode)
    yield put(errorPlanGraphData(error))
    toast({
      title: 'Ошибка сервиса!',
      description: `Hе удалось получить данные "${node.name}".\nПовторите попытку позже`,
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_CONSUMPTION_ANALYSIS_PLAN_GRAPH_DATA.REQUEST, getPlanGraphDataSaga)
}
