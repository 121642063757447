import { GET_ALL_CONNECTED_INTEGRATIONS } from '@/store/actions/dictionaries/index'

export const requestGetAllConnectedIntegrations = () => ({
    type: GET_ALL_CONNECTED_INTEGRATIONS.REQUEST,
})

export const responseGetAllConnectedIntegrations = (data) => ({
    type: GET_ALL_CONNECTED_INTEGRATIONS.SUCCESS,
    payload: data,
})

export const errorGetAllConnectedIntegrations = (error) => ({
    type: GET_ALL_CONNECTED_INTEGRATIONS.ERROR,
    payload: error,
})