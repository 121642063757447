import React from 'react'

import { Title, CheckBoxContainer, RadioButtonStyled } from './styles'
import { CONNECTED_LINES, MESH, OBJECTS } from '@/constants/instalationPassport/types'


const MapLayerMobile = ({
  mapLayer,
  setMapLayer
}) => {
  const handleSetCheckBox = (id) => {
    setMapLayer(id)
  }

  return (
    <>
   <Title main>Отображение слоев</Title>
   <CheckBoxContainer>
        <RadioButtonStyled
          active={mapLayer === OBJECTS}
          onChange={() => handleSetCheckBox(OBJECTS)}
          label="Слой объектов"
        />
      </CheckBoxContainer>
      <CheckBoxContainer >
        <RadioButtonStyled
          active={mapLayer === CONNECTED_LINES}
          onChange={() => handleSetCheckBox(CONNECTED_LINES)}
          label="Слой линий связи"
        />
      </CheckBoxContainer>
      <CheckBoxContainer>
        <RadioButtonStyled
          active={mapLayer === MESH}
          onChange={() => handleSetCheckBox(MESH)}
          label="Слой Mesh сети"
        />
      </CheckBoxContainer>
   </>
  )
}



export default MapLayerMobile
