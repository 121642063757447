import {
  ROLE_SUPER_ADMIN,
  ROLE_TENANT_ADMIN,
  ROLE_USER,
} from '@/constants/names'

export default {
  group: 'Group',
  signOutButton: 'Log out',
  oldPassword: 'Old password',
  newPassword: 'New password',
  changePasswordButton: 'Change Password',
  forgotPasswordButton: 'Forgot your password?',
  number: '8 888 888 88 88',
  numberDescription: 'Customer Service',
  roles: {
    [ROLE_SUPER_ADMIN]: 'Super Admin',
    [ROLE_TENANT_ADMIN]: 'Administrator',
    [ROLE_USER]: 'User',
  },
  jobTitle: 'Job title'
}