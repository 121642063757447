import {
  takeLatest, put,
} from 'redux-saga/effects'
import moment from 'moment'
import get from 'lodash/get'
import request from '@/helpers/axios'
import { TENANT_ENDPOINT } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import {
  NAME,
  PHONE_NUMBER,
  EMAIL,
  SUB_END_DATA,
} from '@/constants/forms/group'
import { requestGetTenant } from '@/store/actions/users/tenant/getTenant'
import { errorCreateTenant } from '@/store/actions/users/tenant/createTenant'
import { requestGetAllTenants } from '@/store/actions/users/tenant/getAllTenants'
import { updateSelectedUserType, CREATE_TENANT, setFormIsModifying } from '@/store/actions/users'
import { formatErrorCode } from '@/helpers/formatErrors'
import { setTenantServiceDesk } from '@/store/actions/serviceDeskMyCity'

const ERRORS_MESSAGES = {
  'validation_failed.tenant_name:not_unique': 'Организация с таким же названием тенанта уже существует в системе',
  'validation_failed.phone:not_unique': 'Организация с таким же номером телефона уже существует в системе',
  'validation_failed.email:not_unique': 'Организация с таким же email-адресом уже существует в системе',
  'validation_failed.email:invalid': 'Неправильный формат данных email',
  'validation_failed.tenant_name:invalid': 'Неправильный формат данных названия организации',
  'validation_failed.subscription_expiration:null': 'Неправильный формат данных дата окончания подписки',
}

const FORM_ERROR = {
  'validation_failed.tenant_name:not_unique': 'Название организации уже существует',
  'validation_failed.phone:not_unique': 'Телефон уже существует',
  'validation_failed.email:not_unique': 'Email уже существует',
  'validation_failed.email:invalid': 'Неправильный формат данных',
  'validation_failed.tenant_name:invalid': 'Неправильный формат данных',
  'validation_failed.subscription_expiration:null': 'Неправильный формат данных',
}

const ERRORS = {
  'Is.not.unique': 'Значение уже существует',
  not_unique: 'Значение уже существует',
  invalid: 'Неправильный формат данных',
}
const NAMES_ALIASES = {
  tenant_name: NAME,
  subscription_expiration: SUB_END_DATA,
}

function* createTenantSaga({ payload }) {
  const { groupForm, permissions } = payload
  const toast = createNotifications()
  try {
    const dataJson = {
      [NAME]: groupForm.values[NAME],
      [EMAIL]: groupForm.values[EMAIL],
      [PHONE_NUMBER]: groupForm.values[PHONE_NUMBER],
      [SUB_END_DATA]: moment(groupForm.values[SUB_END_DATA], 'DD.MM.YYYY').utcOffset(0, true),
      permissions,
    }
    const response = yield request({
      url: TENANT_ENDPOINT,
      method: 'post',
      body: dataJson,
    })

    if (get(response, 'data.validationErrors', []).length > 0) {
      const errors = get(response, 'data.validationErrors', [])
        .reduce((accumulator, error) => {
          const fieldName = `${error.subject[0].toLowerCase()}${error.subject.slice(1)}`
          return ({
            ...accumulator,
            [fieldName]: ERRORS[error.message] || 'Ошибка',
          })
        }, {})
      groupForm.setErrors(errors)
    } else {
      groupForm.setSubmitting(true)
      yield put(setTenantServiceDesk([]))
      yield put(requestGetAllTenants())
      yield put(requestGetTenant({ id: response.data.id, setTenant: true }))
      yield put(updateSelectedUserType('tenant_edit'))
      yield put(setFormIsModifying(false))
      toast({
        title: 'Успешная операция!',
        description: `Организация "${response.data[NAME]}" успешно добавлена!`,
      })
    }
  } catch (error) {
    const { errorCode } = error.response.data
    const [errorType, field] = formatErrorCode(errorCode)
    yield put(errorCreateTenant())
    if (errorType === 'validation_failed') {
      groupForm.setErrors({
        [NAMES_ALIASES[field] || field]: FORM_ERROR[errorCode],
      })
      toast({
        title: 'Ошибка операции!',
        description: ERRORS_MESSAGES[errorCode],
        type: 'error',
      })
    }
  }
}

export default function* root() {
  yield takeLatest(CREATE_TENANT.REQUEST, createTenantSaga)
}
