import {
  takeEvery, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { INSTALLATION_UPLOAD_FILE } from '@/constants/apiRoutes'
import {
  UPLOAD_INSTALLATION_OBJECT_FILE,
} from '@/store/actions/installation'
import {
  successUploadInstallationObjectFile,
  errorUploadInstallationObjectFile,
} from '@/store/actions/installation/uploadObjectFile'
import createNotifications from '@/helpers/notification'
import get from 'lodash/get'
import { setGlobalLoading } from '@/store/actions/appSettings'

const typeToConfig = {
  'error.wrong.file.size': {
    type: 'error',
    title: 'ОШИБКА ОПЕРАЦИИ!',
    description: 'Загружаемый файл не соответствует требованиям системы.\nМаксимальный размер 200кб.\nЗагрузите другой файл.',
  },
  'error.wrong.pdf.size': {
    type: 'error',
    title: 'ОШИБКА ОПЕРАЦИИ!',
    description: 'Загружаемый файл не соответствует требованиям системы.\nМаксимальный размер 100Мбайт.\nЗагрузите другой файл.',
  },
  'error.wrong.file.media.type': {
    type: 'error',
    title: 'ОШИБКА ОПЕРАЦИИ!',
    description: 'Загружаемый файл не соответствует требованиям системы.\nНеверный формат.\nЗагрузите другой файл.',
  },
  undefined: {
    type: 'error',
    title: 'ОШИБКА ОПЕРАЦИИ!',
    description: 'Ошибка загрузки файла.',
  },
}

function* uploadPassportPhotoSaga({ payload }) {
  const {
    elementId,
    file,
    title,
    fileType,
  } = payload
  const toast = createNotifications()
  try {
    yield put(setGlobalLoading(true))
    const formData = new window.FormData()
    formData.append('elementId', elementId)
    formData.append('file', file)
    formData.append('title', title)
    formData.append('fileType', fileType)
    yield request({
      url: INSTALLATION_UPLOAD_FILE,
      method: 'post',
      body: formData,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    yield put(setGlobalLoading(false))
    yield put(successUploadInstallationObjectFile())
  } catch (error) {
    yield put(setGlobalLoading(false))
    const errorDetails = get(error, 'response.data.details', 'undefined')
    if (errorDetails.length) {
      errorDetails.forEach(error => toast(typeToConfig[error.message]))
    }
    yield put(errorUploadInstallationObjectFile())
    console.log('function*uploadPassportPhotoSaga -> error', error)
  }
}

export default function* root() {
  yield takeEvery(UPLOAD_INSTALLATION_OBJECT_FILE.REQUEST, uploadPassportPhotoSaga)
}
