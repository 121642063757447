import BASE_STATION from './BASE_STATION'
import CONTACTOR from './CONTACTOR'
import CONTROL_CUPBOARD from './CONTROL_CUPBOARD'
import DOOR_OPEN_SENSOR from './DOOR_OPEN_SENSOR'
import LINE from './LINE'
import METEO from './METEO'
import ROAD_TEMPERATURE_SENSOR from './ROAD_TEMPERATURE_SENSOR'
import SMOKE_SENSOR from './SMOKE_SENSOR'
import UTILITY_POLE from './UTILITY_POLE'
import LIGHT_FIXTURE from './LIGHT_FIXTURE'
import LAMP_MODULE from './LAMP_MODULE'
import CONTROLLER from './CONTROLLER'
import MULTIRATE_METER from './MULTIRATE_METER'

export default {
  BASE_STATION,
  CONTACTOR,
  CONTROL_CUPBOARD,
  DOOR_OPEN_SENSOR,
  LINE,
  METEO,
  ROAD_TEMPERATURE_SENSOR,
  SMOKE_SENSOR,
  UTILITY_POLE,
  LIGHT_FIXTURE,
  LAMP_MODULE,
  CONTROLLER,
  MULTIRATE_METER,
}
