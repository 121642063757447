export default {
  infrastructure: 'Infrastructure: ',
  objectsConnected: 'Connected to the system',
  urbanProcesses: 'Urban Processes',
  technicalCondition: 'Technical Condition',
  allObjects: 'Total objects:',
  correct: 'Correct:',
  faulty: 'Faulty:',
  warning: 'Warning:',
  undefined: 'Undefined:',
  notReplaced: 'Dont need to be replaced:',
  replacement: 'Approaching replacement:',
  necessaryReplace: 'Need to replace:',
  depreciationEquipment: 'Equipment Wear',
  performanceIndicators: 'Performance indicators',
  outdoorLighting: 'Outdoor lighting',
  heatSupply: 'Heat supply',
  automaticFaultReporting: 'Automatic Fault Reporting',
  totalAlarms: 'Total alarms:',
  roadObjects: 'Road Objects',
  stopsTotalTransport: 'Stops total transport',
  fixed: 'Fixed:',
  title: 'Dashboard',
  requestsAndAlarms: 'Requests And Alarms',
  consumtion: 'Resource consumption relative to planned value',
  period: 'Select period',
  interval: 'Interval',
  alarmRegistry: 'Automatic fault logging',
  tyumenOurHomeBigWidget: 'Portal "Tyumen-OUR HOME"',
  averageDecisionTime: 'Avg. decision time:',
  inWork: 'In Work:',
  decisionWasMade: 'Decision made:',
  decisionWasMadeMany: 'Decisions made:',
  successfullyResolved: 'Successfully resolved:',
  denialDecision: 'Decision denied:',
  lightingObjects: 'Object state',
  serviceDesk: 'Service Desk "My City"',
  totalCalls: 'Total appeals:',
  AvgTimeTaken: 'Avg. pick up time:',
  overdue: 'Overdue',
  delayed: 'Delayed',
  build: 'Build',
  resetFilter: 'Reset filter',
  received: 'Received',
  completed: 'Completed',
  progress: 'In progress',
  inProgress: 'Task in progress',
  inProgress1: 'Tasks in progress',
  inProgress2: 'Task in progress',
  waiting: 'Waiting for control',
  executionOverdue: 'Overdue for execution',
  executionTime: 'Average execution time',
  registered: 'Registered',
  work: 'In progress',
  resolved: 'Resolved',
  decisionMade: 'Decision was made for',
  currentMonth: 'For current month',
  wholePeriod: 'For entire period',
}