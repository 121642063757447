import { LIGHT, SHUO } from '@/constants/objectTypes'
import controller from './controller'
import light from './light'
import line from './line'
import utilityPole from './utilityPole'
import {
  BASE_STATION,
  LIGHT_FIXTURE,
  CONTROL_CUPBOARD,
  CONTROLLER,
  LINE,
  UTILITY_POLE,
} from '@/constants/instalationPassport/types'

export default {
  [CONTROLLER]: controller,
  [BASE_STATION]: controller,
  [SHUO]: controller,
  [CONTROL_CUPBOARD]: controller,
  [LIGHT]: light,
  [LIGHT_FIXTURE]: light,
  [LINE]: line,
  [UTILITY_POLE]: utilityPole,
}
