import { connect } from 'react-redux'
import {
  getExportFileStatus,
  getTableData,
  getSelector,
  getSelectedElement,
  getCheckBoxOption,
  getLoading,
  getFilterOptions
} from '@/store/selectors/analyticsAlarms'
import {
  getTableDataSummary,
} from '@/store/selectors/faultAnalytics'
import { setSelectedNode } from '@/store/actions/appSettings'
import {
  getGlobalLoading,
  getSelectedNode
} from '@/store/selectors/appSettings'
import {
  setEventTypes,
  setStatusTypes,
  setSelectedElement,
  setSideBarFilter,
  setFilterValues,
  resetFilterValues
} from '@/store/actions/analyticsAlarms'
import {
  requestAnalyticsAlarmsTable,
} from '@/store/actions/analyticsAlarms/getTable'
import {
  requestSummaryAlarmAnalyticsTable,
} from '@/store/actions/faultAnalytics/getTable'
import {
  requestAnalyticsAlarmsAlarm,
} from '@/store/actions/analyticsAlarms/getAlarmInformation'
import {
  requestAlarmManagerFile,
} from '@/store/actions/analyticsAlarms/getFile'

import AlarmsAnalytics from './component'
import { getRequestState } from '@/store/selectors/widgetDashboard'
import {
  getEventFilters
} from '@/store/selectors/appSettings'
import { requestGetSearchFieldsOptions } from '@/store/actions/dictionaries/searchFieldsOptions'
import {requestGetAllConnectedIntegrations} from "@/store/actions/dictionaries/connectedIntegrations"
import {
  getSearchFieldsOptionsData,
  getSearchFieldsOptionsLoading,
} from '@/store/selectors/dictionaries/searchFieldsOptions'
import { getConnectedIntegrationsOptions } from '@/store/selectors/dictionaries/connectedIntegrations'

const mapStateToProps = (state) => ({
  data: getTableData(state),
  dataSummary: getTableDataSummary(state),
  exportFileStatus: getExportFileStatus(state),
  selector: getSelector(state),
  selectedElement: getSelectedElement(state),
  checkBoxOption: getCheckBoxOption(state),
  filterOptions: getFilterOptions(state),
  selectedNode: getSelectedNode(state),
  loading: getLoading(state),
  requestState: getRequestState(state),
  globalLoading: getGlobalLoading(state),
  eventFilterValues: getEventFilters(state),
  searchFieldsOptions: getSearchFieldsOptionsData(state),
  connectedIntegrations: getConnectedIntegrationsOptions(state),
  searchFieldsOptionsLoading: getSearchFieldsOptionsLoading(state),
})
const mapDispatchToProps = (dispatch) => ({
  setEventTypes: (payload) => dispatch(setEventTypes(payload)),
  setStatusTypes: (payload) => dispatch(setStatusTypes(payload)),
  setSelectedElement: (payload) => dispatch(setSelectedElement(payload)),
  setSideBarFilter: (payload) => dispatch(setSideBarFilter(payload)),
  setFilterValues: (payload) => dispatch(setFilterValues(payload)),
  resetFilterValues: (payload) => dispatch(resetFilterValues(payload)),
  setNode: (payload) => dispatch(setSelectedNode(payload)),
  requestAnalyticsAlarmsTable: (payload) => dispatch(requestAnalyticsAlarmsTable(payload)),
  requestAnalyticsAlarmsAlarm: (payload) => dispatch(requestAnalyticsAlarmsAlarm(payload)),
  requestSummaryAlarmAnalyticsTable: (payload) => dispatch(requestSummaryAlarmAnalyticsTable(payload)),
  requestAlarmManagerFile: (payload) => dispatch(requestAlarmManagerFile(payload)),
  requestGetSearchFieldsOptions: (payload) => dispatch(requestGetSearchFieldsOptions(payload)),
  requestGetAllConnectedIntegrations: (payload) => dispatch(requestGetAllConnectedIntegrations(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(AlarmsAnalytics)
