import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import {
  STATUS_COLORS,
} from '@/constants/styles/mapsMarks'
import {
  NOT_VERIFIED,
  VERIFIED,
  NEW,
  NOT_EXIST,
  NOT_INSTALLED,
  INSTALLED,
  UNINSTALLED,
} from '@/constants/objectStatuses'
import {
  GEOZONE,
  PROJECT,
  INTEGRATION,
  OBJECT_ELEMENT,
  DOWNLOAD_INSTALLATION_FILE,
} from '@/constants/objectTypes'

export const allFilterOption = [NOT_VERIFIED, VERIFIED, NEW, NOT_EXIST, NOT_INSTALLED, INSTALLED, UNINSTALLED]
export const filterOptions = [
  { value: NOT_VERIFIED, color: STATUS_COLORS[NOT_VERIFIED], title: <Lang id={"mapsPage.titlesByStatus.notVerified"}/> },
  { value: VERIFIED, color: STATUS_COLORS[VERIFIED], title: <Lang id={"mapsPage.titlesByStatus.verified"}/> },
  { value: NEW, color: STATUS_COLORS[NEW], title: <Lang id={"mapsPage.titlesByStatus.statusNew"}/> },
  { value: NOT_EXIST, color: STATUS_COLORS[NOT_EXIST], title: <Lang id={"mapsPage.titlesByStatus.notExist"}/> },
  { value: NOT_INSTALLED, color: STATUS_COLORS[NOT_INSTALLED], title: <Lang id={"mapsPage.titlesByStatus.notInstalled"}/> },
  { value: INSTALLED, color: STATUS_COLORS[INSTALLED], title: <Lang id={"mapsPage.titlesByStatus.installed"}/> },
  { value: UNINSTALLED, color: STATUS_COLORS[UNINSTALLED], title: <Lang id={"mapsPage.titlesByStatus.unInstalled"}/> },
]

export const createOptions = [
  { value: GEOZONE, title: <Lang id={"globalNames.objectTypes.GEOZONE"}/> },
  { value: PROJECT, title: <Lang id={"globalNames.objectTypes.PROJECT"}/> },
  { value: INTEGRATION, title: <Lang id={"globalNames.objectTypes.INTEGRATION"}/> },
  { value: OBJECT_ELEMENT, title: <Lang id={"globalNames.objectTypes.OBJECT_ELEMENT"}/> },
  { value: DOWNLOAD_INSTALLATION_FILE, title: <Lang id={"installation.uploadFile"}/> },
]
