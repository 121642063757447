import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import getDeclension from '@/helpers/getDeclension'
import {
  DANGER,
  NORMAL,
} from '@/constants/semanticNames'
import {
  USERS_WIDGET,
  PASPORTIZATION_WIDGET,
  ALARMS_WIDGET,
  SCHEDULE_WIDGET,
  INSTALLATION_WIDGET,
  INVENTARISATION_WIDGET,
  REPORT_MANAGER_WIDGET,
  LIFE_WIDGET,
  OBJECTS_WIDGET,
  SUPPORT_WIDGET,
  STATISTICS_ON_OBJECTS_LIGHTING_WIDGET,
  ANALYTICS_EXPENSES_WIDGET,
  ACTIVITY_LOG_WIDGET,
} from '@/constants/widgets'

export default [
  [
    {
      ...USERS_WIDGET,
      type: 'big',
      title: <Lang id="widgets.users" />,
      descriptionFields: [
        {
          selector: 'name',
          highLight: NORMAL,
        },
        {
          selector: 'description',
        },
      ],
    },
    {
      ...ACTIVITY_LOG_WIDGET,
      title: <Lang id="widgets.activityLog" />,
    },
    {
      ...PASPORTIZATION_WIDGET,
      title: <Lang id="widgets.passportization" />,
    },
    {
      ...INSTALLATION_WIDGET,
      title: <Lang id="widgets.installation" />,
    },
    {
      ...INVENTARISATION_WIDGET,
      title: <Lang id="widgets.inventorisation" />,
    },
  ],
  [
    {
      ...OBJECTS_WIDGET,
      type: 'big',
      count: 0,
      title: <Lang id="mapsPage.title" />,
      valueList: [
        {
          title: (count) => getDeclension(count, [
            <Lang id="widgets.mountOne" />,
            <Lang id="widgets.mountTwo" />,
            <Lang id="widgets.mountSome" />,
          ]),
          countSelector: 'pins',
          highLight: NORMAL,
        },
        {
          title: (count) => getDeclension(count, [
            <Lang id="widgets.projectOne" />,
            <Lang id="widgets.projectTwo" />,
            <Lang id="widgets.projectSome" />,
          ]),
          countSelector: 'countOfProjects',
        },
      ],
    },
    {
      ...REPORT_MANAGER_WIDGET,
      title: <Lang id="widgets.reportManager" />,
    },
    {
      ...SUPPORT_WIDGET,
      title: <Lang id="widgets.operationAndTechnicalSupport" />,
    },
    {
      ...SCHEDULE_WIDGET,
      title: <Lang id="widgets.scheduleManager" />,
    },
    {
      ...STATISTICS_ON_OBJECTS_LIGHTING_WIDGET,
      title: <Lang id="widgets.statisticsOnObjectsControl" />,
    },
  ],
  [
    {
      ...ALARMS_WIDGET,
      type: 'big',
      title: <Lang id="widgets.alarms" />,
      notification: 'alarm',
      valueList: [
        {
          title: <Lang id="widgets.eventsCount" />,
          countSelector: 'eventCount',
        },
        {
          title: <Lang id="widgets.eventsNewCount" />,
          countSelector: 'newEventCount',
          highLight: DANGER,
        },
      ],

    },
    {
      ...LIFE_WIDGET,
      title: <Lang id="widgets.life" />,
    },
    {
      ...ANALYTICS_EXPENSES_WIDGET,
      title: <Lang id="widgets.analyticConsumption" />,
    },
  ],
]
