import {
    ERROR,
    INFO,
    OK,
    WARNING,
    NORMAL
} from '@/constants/objectStatuses'

export const globalFiltersDefaultValues ={
    [OK]: true,
    [WARNING]: true,
    [ERROR]: true,
    [INFO]: true,
}

export const eventFiltersDefaultValues ={
    [NORMAL]: true,
    [WARNING]: true,
    [ERROR]: true,
    [INFO]: true,
}
