export default {
  titles: {
    users: 'Пользователи',
    search: 'Поиск',
    applications: 'Доступные приложения'
  },
  applications: {
    USERS: 'Пользователи',
    ACTIVITY_LOG: 'Журнал действий',
    PASSPORTIZATION: 'Паспортизация',
    INSTALLATION: 'Инсталляция',
    INVENTORY: 'Инвентаризация',
    MONITORING_CENTER: 'Центр мониторинга',
    REPORT_MANAGER: 'Менеджер отчетов',
    SUPPORT: 'Эксплуатация и тех. поддержка',
    SCHEDULE_MANAGER: 'Менеджер расписаний',
    CONTROL_OBJECTS_STATISTICS: 'Статистика по объектам контроля',
    ALARM_LOG: 'Журнал неисправностей',
    LIFETIME: 'Срок службы',
    RESOURCE_ANALYTICS: 'Аналитика потребления ресурсов',
    HEATING: 'Теплоснабжение',
    WATER: 'Водоснабжение',
    CHARGING_STATIONS: 'Зарядные автостанции',
    ART_LIGHTING: 'Архитектурное освещение',
    WASTE_COLLECTION: 'Контроль наполняемости мусорных баков',
    ROAD_MONITORING: 'Мониторинг дорожной инфраструктуры',
    TRAFFIC_LIGHTS: 'Управление светофорами',
    INFO_BOARDS: 'Размещение инфотабло',
    DISABLED_PARKING_SPACES: 'Контроль занятости мест для инвалидов',
    DASHBOARD: 'Дашборд',
    ALARM_ANALYTICS: 'Аналитика неисправностей',
    TYUMEN_PORTAL: 'Портал “Тюмень-НАШ ДОМ”',
    SERVICE_DESK: 'Сервис Деск “Мой город”',
    MAP_SERVICE: 'Сервис карт',
    DASHBOARD2: 'Дашборд 2',
    OBJECTS_CONTROL: 'Управление объектами',
    ADVANCED_SEARCH: 'Расширенный поиск',
    PPR: 'ППР',
    OBJECTS_GROUPS: 'Группы объектов'
  },
  access: {
    dataTitle: 'Новый процесс',
    functionalTitle: 'Новый процесс',
    processParameters: 'Параметры процесса',
    typeTitle: 'Городские процессы',
    accessTitle: 'Функциональность процесса',
    all: 'Включить все',
    stateMonitoring: 'Мониторинг состояния объектов',
    alarms: 'Оповещения (алармы)',
    reportManager: 'Менеджер отчетов',
    analytic: 'Аналитика и прогнозирование',
    passportization: 'Паспортизация',
    inventory: 'Инвентаризация',
    installations: 'Инсталляции',
    integration: 'Интеграции',
    notSelected: 'Не выбран',
    allUrbanProcesses: 'Все городские процессы',
  },
  group: {
    dataTitle: 'Новая организация',
    accessTitle: 'Городские процессы и функции',
    name: 'Название организации*',
    numbers: 'Номер телефона контактного лица*',
    email: 'Email контактного лица*',
    subscriptionEnd: 'Дата окончания подписки (дд.мм.гггг)*',
    requiredFields: '*Поля обязательные для заполнения',
    blockOrganization: 'Заблокировать организацию',
    cancelNewProcessAdding: 'Отменить добавление нового процесса?',
    cancelNewCompanyAdding: 'Отменить добавление новой организации?',
    addNewProcess: 'Добавить новый процесс?',
    addNewCompany: 'Добавить организацию?',
    deleteCompany: 'Удалить организацию?',
    deleteProcess: 'Удалить процесс?',
    blockUsers: 'Заблокировать пользователей',
    selectFromList: 'Выбрать из списка',
    rebase: 'Переместить в группу',
  },
  adminSortPanel: {
    multipleSelect: 'Выбрать несколько',
    bySurname: 'По фамилии',
    byDate: 'По дате регистрации',
    sort: 'Сортировка',
  },
  groupOfUsers: {
    name: 'Название группы*',
    requiredFields: '*Поля обязательные для заполнения',
    blockGroup: 'Заблокировать группу',
    blockUsers: 'Заблокировать пользователей',
    blockUser: 'Заблокировать пользователя',
    unBlockUsers: 'Разблокировать пользователей',
    unBlockUser: 'Разблокировать пользователя',
    cancelNewProcessAdding: 'Отменить добавление нового процесса?',
    cancelEditProcessAdding: 'Отменить редактирование?',
    cancelEditGroup: 'Отменить редактирование?',
    cancelNewGroupAdding: 'Отменить добавление новой группы?',
    addNewProcess: 'Добавить новый процесс?',
    addNewGroup: 'Добавить новую группу?',
    update: 'Применить изменения?',
    deleteCompany: 'Удалить группу?',
    deleteProcess: 'Удалить процесс?',
    default: 'новая группа',
    cancelAdding: 'Отменить добавление?',
  },
  user: {
    name: 'Имя пользователя:',
    text: 'Сопроводительный текст:',
    email: 'E-mail пользователя:',
    password: 'Временный пароль:',
    sendButton: 'Отправить запрос',
  },
  list: {
    groupsTab: 'Группы',
    usersTab: 'Пользователи',
    userDescription: 'Группа:',
    groupDescription: 'Пользователей:',
    selectAll: 'Выделить все',
    selected: 'Выделено:',
    cancelSelect: 'Отменить выделение',
    edit: 'Редактировать',
    delete: 'Удалить',
    usersTitle: 'Пользователей',
    groupsTitle: 'Групп',
    addNewUser: 'Добавить нового пользователя',
    addNewGroup: 'Добавить новую группу',
  },
  adminBoard: {
    view: 'Отображать',
    sort: 'Сортировать',
    order: 'Упорядочить',
  },
  formHeader: {
    save: 'Сохранить',
    cancel: 'Отмена',
    creatingGroup: 'Добавление группы',
    creatingTenant: 'Добавление организации',
    creatingAdmin: 'Добавление администратора',
    creatingUser: 'Добавление пользователя',
    changingGroup: 'Редактирование группы',
    changingTenant: 'Редактирование организации',
    changingAdmin: 'Редактирование администратора',
    changingUser: 'Редактирование пользователя',
    changingProfile: 'Редактирование пользователя',
    changeGroup: 'Перенос пользователей',
    changeTitle: 'Сохранить изменения?',
    addButton: 'Добавить',
    confirm: 'Добавить администратора?',
    confirmUser: 'Добавить пользователя?',
    newAdmin: 'НОВЫЙ АДМИНИСТРАТОР',
    newUser: 'Новый пользователь',
    admin: 'Администратор',
    user: 'Пользователь',
    myProfile: 'Мой Профиль',
    addAdmin: 'Aдминистратора',
    addUser: 'Пользователя',
    addTenant: 'Организацию',
    addGroup: 'Группу',
    cancelAdmin: 'Отменить добавление\nнового администратора?',
    cancelUser: 'Отменить добавление\nнового пользователя?',
    changeForm: 'Применить изменения?',
    cancelChangeForm: 'Отменить изменения?',
    deleteAdmin: 'Удалить администратора?',
    deleteUser: 'Удалить пользователя?',
    deleteMultiUser: 'Удалить пользователей?',
    changeAdmin: 'Применить изменения?',
    yes: 'Да',
    no: 'Нет',
  },
  popup: {
    attention: 'Внимание',
    cancelTenant: 'Вы действительно хотите отменить добавление организации?',
    cancelAdmin: 'Вы действительно хотите отменить добавление администратора?',
    cancelChangeAdmin: 'Вы действительно хотите отменить редактирование администратора?',
    cancelChangeUser: 'Вы действительно хотите отменить редактирование пользователя?',
    cancelUser: 'Вы действительно хотите отменить добавление пользователя?',
    cancelGroup: 'Вы действительно хотите отменить добавление группы?',
    saveTenant: 'Для сохранения организации необходимо установить все обязательные параметры',
    saveAdmin: 'Для сохранения администратора необходимо установить все обязательные параметры',
    saveUser: 'Для сохранения пользователя необходимо установить все обязательные параметры',
    saveGroup: 'Для сохранения группы необходимо установить все обязательные параметры',
    impossibleExecute: 'Невозможно выполнить!',
    warningOrganization: 'Для продолжения необходимо выбрать организацию',
    warningGroup: 'Для продолжения необходимо выбрать группу',
    accept: 'Принять',
    yes: 'Да',
    no: 'Нет',
    deleteTenant: 'Вы действительно хотите удалить из системы организацию ',
    deleteGroup: 'Вы действительно хотите удалить из системы группу ',
    deleteAdmin: 'Вы действительно хотите удалить из системы администратора ',
    deleteUser: 'Вы действительно хотите удалить из системы пользователя ',
    changeUserGroup: 'Вы действительно хотите отменить изменения?',
    cancelChangeTenant: 'Вы действительно хотите отменить редактирование организации?',
    cancelChangeGroup: 'Вы действительно хотите отменить редактирование группы?',
  },
  createAdmin: {
    fields: {
      surname: 'Фамилия*',
      name: 'Имя*',
      patronymic: 'Отчество',
      email: 'Email*',
    },
    messages: {
      default: '*Поля обязательные для заполнения',
      info: 'Письмо с данными для входа в систему,\nбыло успешно отправлено пользователю на указанный email.',
      confirm: 'Пользователь подтвердил регистрацию.',
      notConfirm: 'Пользователь не подтвердил регистрацию.',
    },
  },
  editAdmin: {
    surname: 'Фамилия*',
    name: 'Имя*',
    patronymic: 'Отчество',
    telephone: 'Телефон*',
    email: 'Email*',
    position: 'Должность',
    aboutMe: 'О себе',
    block: 'Заблокировать пользователя',
    group: 'Группа',
  },
  editUser: {
    surname: 'Фамилия*',
    name: 'Имя*',
    patronymic: 'Отчество',
    telephone: 'Телефон*',
    email: 'Email*',
    position: 'Должность',
    aboutMe: 'О себе',
    block: 'Заблокировать пользователя',
  },
  buttons: {
    download: 'ЗАГРУЗИТЬ ФОТОГРАФИЮ ПРОФИЛЯ',
    delete: 'УДАЛИТЬ ФОТОГРАФИЮ ПРОФИЛЯ',
    yes: 'Да',
    no: 'Нет',
  },
  messages: {
    loading: 'Loading...',
    selectFunctionalModule: 'Выберите функциональный модуль',
  },
}
