import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const AddIcon = ({ color, onClick }) => {

  return (
    <svg onClick={onClick} width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1069_1633)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5002 25.6667C20.9435 25.6667 26.1668 20.4434 26.1668 14C26.1668 7.55669 20.9435 2.33337 14.5002 2.33337C8.05682 2.33337 2.8335 7.55669 2.8335 14C2.8335 20.4434 8.05682 25.6667 14.5002 25.6667ZM15.4724 8.16671V13.0278H20.3335V14.9723H15.4724V19.8334H13.5279V14.9723H8.66683V13.0278H13.5279V8.16671H15.4724Z" fill="#90C737"/>
      </g>
      <defs>
      <clipPath id="clip0_1069_1633">
      <rect width="28" height="28" fill="white" transform="translate(0.5)"/>
      </clipPath>
      </defs>
    </svg>
  )
}

AddIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

AddIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(AddIcon)