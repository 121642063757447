import styled from 'styled-components'
export const ValueWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
`
export const DriverContainer = styled.div`
  padding-bottom: 15px;
  width: 100%;
  &:last-child {
    padding-bottom: 0;
  }
`

export const DriverWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 10px;
`

export const DriverTitle = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme, online }) => theme.colors.colors.redisign.default};
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: left;
  max-width: 50%;
  margin-bottom: 0;
`