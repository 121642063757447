import { GET_ALL_OBJECT_TYPES } from '@/store/actions/dictionaries/index'

export const requestGetAllObjectTypes = () => ({
    type: GET_ALL_OBJECT_TYPES.REQUEST,
})

export const responseGetAllObjectTypes = (data) => ({
    type: GET_ALL_OBJECT_TYPES.SUCCESS,
    payload: data,
})

export const errorGetAllObjectTypes = (error) => ({
    type: GET_ALL_OBJECT_TYPES.ERROR,
    payload: error,
})