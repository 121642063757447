import React, { useState, useCallback, useMemo } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import {
  PieChart,
  Pie,
  Cell,
  Sector,
} from 'recharts'
import { STATUS_COLORS } from '@/constants/alarms'
import getDeclension from '@/helpers/getDeclension'
import { ANDGLE_INCREMENT_SMALL } from '@/constants/graph/pieChart'

const CustomPieChart = ({ data, onClick, disableActions }) => {
  const filteredData = useMemo(() => data.filter(({ value }) => value > 0), [data])
  const [activeIndex, setActiveIndex] = useState(0)
  const [isUserHover, setIsUserHover] = useState(false)

  const onPieEnter = useCallback((element, index) => {
    setActiveIndex(index)
    setIsUserHover(true)
  }, [])
  const onPieLeave = useCallback(() => {
    setActiveIndex(0)
    setIsUserHover(false)
  }, [])

  const renderActiveShape = useCallback(({
    cx,
    cy,
    innerRadius,
    startAngle,
    endAngle,
    fill,
    percent,
    value,
  }) => (
    <g>
      <text x={cx} y={cy - 5} dy={8} fontSize={25} textAnchor="middle" fill={fill}>
        {(percent * 100).toFixed(0)}
        %
      </text>
      <text x={cx} y={cy + 5} dy={8} fontSize={9} textAnchor="middle" fill={fill}>
        {value}
      </text>
      <text x={cx} y={cy + 15} dy={8} fontSize={9} textAnchor="middle" fill={fill}>
        {getDeclension(value, ['объект', 'объекта', 'объектов'])}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={65}
        startAngle={startAngle}
        endAngle={filteredData.length > 1 ? endAngle : endAngle + ANDGLE_INCREMENT_SMALL}
        fill={fill}
      />
    </g>
  ), [filteredData.length])

  return (
    <PieChart width={150} height={150}>
      <Pie
        onClick={disableActions ? noop : () => onClick(filteredData[activeIndex]?.type)}
        activeIndex={activeIndex}
        activeShape={renderActiveShape}
        data={filteredData}
        cx={75}
        cy={75}
        innerRadius={35}
        outerRadius={isUserHover ? 55 : 65}
        startAngle={0}
        paddingAngle={isUserHover ? 5 : 0}
        onMouseEnter={disableActions ? noop : onPieEnter}
        onMouseLeave={disableActions ? noop : onPieLeave}
        isAnimationActive={false}
      >
        {filteredData.map(({ type }) => (
          <Cell fill={STATUS_COLORS[type]} stroke={STATUS_COLORS[type]} />
        ))}
      </Pie>
    </PieChart>
  )
}

CustomPieChart.propTypes = {
  data: pt.arrayOf(pt.object),
  onClick: pt.func,
  disableActions: pt.bool,
}

CustomPieChart.defaultProps = {
  data: [],
  onClick: noop,
  disableActions: false,
}

export default CustomPieChart
