import { fork, takeLatest, all } from 'redux-saga/effects'

import {
  CREATE_INTEGRATION,
  DELETE_INTEGRATION,
  UPDATE_INTEGRATION,
  REPEAT_INTEGRATION,
  VERIFY_INTEGRATION,
  INTEGRATION_VERIFICATION_PROGRESS,
} from '@/store/actions/integrations'
import createIntegrationSaga from './createIntegration'
import deleteIntegrationSaga from './deleteIntegration'
import updateIntegrationSaga from './updateIntegration'
import repeatIntegrationSaga from './repeatIntegration'
import verifyIntegrationSaga from './verifyIntegration'
import integrationVerificationProgressSaga from './integrationVerificationProgress'

function* watchStateRequest() {
  yield takeLatest(CREATE_INTEGRATION.EVENTS, createIntegrationSaga)
  yield takeLatest(DELETE_INTEGRATION.EVENTS, deleteIntegrationSaga)
  yield takeLatest(UPDATE_INTEGRATION.EVENTS, updateIntegrationSaga)
  yield takeLatest(REPEAT_INTEGRATION.EVENTS, repeatIntegrationSaga)
  yield takeLatest(VERIFY_INTEGRATION.EVENTS, verifyIntegrationSaga)
  yield takeLatest(INTEGRATION_VERIFICATION_PROGRESS.EVENTS, integrationVerificationProgressSaga)
}

const stateSaga = [fork(watchStateRequest)]

export default function* rootSaga() {
  yield all([...stateSaga])
}
