import { handleActions } from 'redux-actions'
import { REQUEST_STATUSES } from '@/constants/requests'
import {
    GET_OBJECT_STATE,
    GET_SERVICE_DESK_WIDGET,
    GET_GRAPH_DATA,
    GET_TUMEN_OUR_HOME_DATA,
    GET_DEPRECIATION_EQUIPMENT,
    GET_AUTOMATIC_REPORTING,
    GET_INFRASTRUCTURE_COUNT, GET_TREE_FOR_WIDGETS,
} from '@/store/actions/widgetDashboard'
import {
  SET_SELECTED_NODE,
} from '@/store/actions/appSettings'
import {
  HEAT_SUPPLY,
  OUTDOOR_LIGHTING,
} from '@/constants/names'

const initialState = {
  objectState: {},
  serviceDeskWidget: {},
  graphData: {},
  tyumenOurHome: {},
  depreciationEquipment: {},
  automaticReporting: {},
  infrastructureCount: {},
  tree: [],
  requestsState: {
    objectState: REQUEST_STATUSES.NOT_REQUESTED,
    serviceDeskWidget: REQUEST_STATUSES.NOT_REQUESTED,
    graphData: {
      [HEAT_SUPPLY]: REQUEST_STATUSES.NOT_REQUESTED,
      [OUTDOOR_LIGHTING]: REQUEST_STATUSES.NOT_REQUESTED,
    },
    tyumenOurHome: REQUEST_STATUSES.NOT_REQUESTED,
    depreciationEquipment: REQUEST_STATUSES.NOT_REQUESTED,
    automaticReporting: REQUEST_STATUSES.NOT_REQUESTED,
    infrastructureCount: REQUEST_STATUSES.NOT_REQUESTED,
    tree: REQUEST_STATUSES.NOT_REQUESTED,
  },
}

export const reducer = handleActions(
  {
    [GET_AUTOMATIC_REPORTING.REQUEST]: (state, { payload }) => ({
      ...state,
      automaticReporting: {},
      requestsState: {
        ...state.requestsState,
        automaticReporting: REQUEST_STATUSES.PENDING,
      },
    }),
    [GET_AUTOMATIC_REPORTING.SUCCESS]: (state, { payload }) => ({
      ...state,
      automaticReporting: payload,
      requestsState: {
        ...state.requestsState,
        automaticReporting: REQUEST_STATUSES.IDLE,
      },
    }),
    [GET_AUTOMATIC_REPORTING.ERROR]: (state, { payload }) => ({
      ...state,
      automaticReporting: payload,
      requestsState: {
        ...state.requestsState,
        automaticReporting: REQUEST_STATUSES.IDLE,
      },
    }),

    [GET_DEPRECIATION_EQUIPMENT.REQUEST]: (state, { payload }) => ({
      ...state,
      depreciationEquipment: {},
      requestsState: {
        ...state.requestsState,
        depreciationEquipment: REQUEST_STATUSES.PENDING,
      },
    }),
    [GET_DEPRECIATION_EQUIPMENT.SUCCESS]: (state, { payload }) => ({
      ...state,
      depreciationEquipment: payload,
      requestsState: {
        ...state.requestsState,
        depreciationEquipment: REQUEST_STATUSES.IDLE,
      },
    }),
    [GET_DEPRECIATION_EQUIPMENT.ERROR]: (state, { payload }) => ({
      ...state,
      depreciationEquipment: {},
      requestsState: {
        ...state.requestsState,
        depreciationEquipment: REQUEST_STATUSES.IDLE,
      },
    }),

    [GET_SERVICE_DESK_WIDGET.REQUEST]: (state, { payload }) => ({
      ...state,
      serviceDeskWidget: {},
      requestsState: {
        ...state.requestsState,
        serviceDeskWidget: REQUEST_STATUSES.PENDING,
      },
    }),
    [GET_SERVICE_DESK_WIDGET.SUCCESS]: (state, { payload }) => ({
      ...state,
      serviceDeskWidget: payload,
      requestsState: {
        ...state.requestsState,
        serviceDeskWidget: REQUEST_STATUSES.IDLE,
      },
    }),
    [GET_SERVICE_DESK_WIDGET.ERROR]: (state, { payload }) => ({
      ...state,
      serviceDeskWidget: {},
      requestsState: {
        ...state.requestsState,
        serviceDeskWidget: REQUEST_STATUSES.IDLE,
      },
    }),

    [GET_OBJECT_STATE.REQUEST]: (state, { payload }) => ({
      ...state,
      objectState: {},
      requestsState: {
        ...state.requestsState,
        objectState: REQUEST_STATUSES.PENDING,
      },
    }),
    [GET_OBJECT_STATE.SUCCESS]: (state, { payload }) => ({
      ...state,
      objectState: payload,
      requestsState: {
        ...state.requestsState,
        objectState: REQUEST_STATUSES.IDLE,
      },
    }),
    [GET_OBJECT_STATE.ERROR]: (state, { payload }) => ({
      ...state,
      objectState: {},
      requestsState: {
        ...state.requestsState,
        objectState: REQUEST_STATUSES.IDLE,
      },
    }),
    [GET_GRAPH_DATA.REQUEST]: (state, { payload: { type } }) => ({
      ...state,
      graphData: {
        ...state.graphData,
        [type]: [],
      },
      requestsState: {
        ...state.requestsState,
        graphData: {
          ...state.requestsState.graphData,
          [type]: REQUEST_STATUSES.PENDING,
        },
      },
    }),
    [GET_GRAPH_DATA.SUCCESS]: (state, { payload: { data, type } }) => ({
      ...state,
      graphData: {
        ...state.graphData,
        [type]: data,
      },
      requestsState: {
        ...state.requestsState,
        graphData: {
          ...state.requestsState.graphData,
          [type]: REQUEST_STATUSES.IDLE,
        },
      },
    }),
    [GET_GRAPH_DATA.ERROR]: (state, { payload: { data, type } }) => ({
      ...state,
      graphData: {
        ...state.graphData,
        [type]: data,
      },
      requestsState: {
        ...state.requestsState,
        graphData: {
          ...state.requestsState.graphData,
          [type]: REQUEST_STATUSES.IDLE,
        },
      },
    }),

    [GET_TUMEN_OUR_HOME_DATA.REQUEST]: (state, { payload }) => ({
      ...state,
      tyumenOurHome: {},
      requestsState: {
        ...state.requestsState,
        tyumenOurHome: REQUEST_STATUSES.PENDING,
      },
    }),
    [GET_TUMEN_OUR_HOME_DATA.SUCCESS]: (state, { payload }) => ({
      ...state,
      tyumenOurHome: payload,
      requestsState: {
        ...state.requestsState,
        tyumenOurHome: REQUEST_STATUSES.IDLE,
      },
    }),
    [GET_TUMEN_OUR_HOME_DATA.ERROR]: (state, { payload }) => ({
      ...state,
      tyumenOurHome: {},
      requestsState: {
        ...state.requestsState,
        tyumenOurHome: REQUEST_STATUSES.IDLE,
      },
    }),

    [GET_INFRASTRUCTURE_COUNT.REQUEST]: (state, { payload }) => ({
      ...state,
      infrastructureCount: {},
      requestsState: {
        ...state.requestsState,
        infrastructureCount: REQUEST_STATUSES.PENDING,
      },
    }),
    [GET_INFRASTRUCTURE_COUNT.SUCCESS]: (state, { payload }) => ({
      ...state,
      infrastructureCount: payload,
      requestsState: {
        ...state.requestsState,
        infrastructureCount: REQUEST_STATUSES.IDLE,
      },
    }),
    [GET_INFRASTRUCTURE_COUNT.ERROR]: (state, { payload }) => ({
      ...state,
      infrastructureCount: {},
      requestsState: {
        ...state.requestsState,
        infrastructureCount: REQUEST_STATUSES.IDLE,
      },
    }),

    [GET_TREE_FOR_WIDGETS.REQUEST]: (state, { payload }) => ({
      ...state,
      tree: [],
      requestsState: {
        ...state.requestsState,
        tree: REQUEST_STATUSES.PENDING,
      },
    }),
    [GET_TREE_FOR_WIDGETS.SUCCESS]: (state, { payload }) => ({
      ...state,
      tree: payload,
      requestsState: {
        ...state.requestsState,
        tree: REQUEST_STATUSES.IDLE,
      },
    }),
    [GET_TREE_FOR_WIDGETS.ERROR]: (state, { payload }) => ({
      ...state,
      tree: [],
      requestsState: {
        ...state.requestsState,
        tree: REQUEST_STATUSES.IDLE,
      },
    }),

    [SET_SELECTED_NODE]: () => ({
      ...initialState,
    }),
  },
  initialState,
)

export default reducer
