import styled from 'styled-components'
import {
  LIGHT_THEME_NAME,
} from '@/constants/names'

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding-left: 10px;

  ${({ withDelimiter, theme }) => withDelimiter && `
    border-right: 1px solid ${theme.colors.borders.default};
  `}
`

export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 5px;
  position: relative;

  #notification {
    ${({ theme, animated }) => animated && theme.animations.notification.connect()}
  }

  ${({ spaced }) => spaced && `
    margin-right: 20px;
  `}
`

export const IconWrapper = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  ${({ disableAnimation }) => !disableAnimation && `  
    svg {
      transform-origin: 50% 50%;
      animation: itemRotation 3s linear infinite;
    }
  `}

  @keyframes itemRotation {
    0% { transform: rotate(0deg); }

    50% { transform: rotate(180deg); }

    100% { transform: rotate(360deg); }
  }
`

export const NotificationsCount = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: auto;
  height: 18px;
  padding: 0 4px;
  margin-left: -5px;
  margin-bottom: 20px;
  background: #F83D14;
  box-shadow: 0 0 6px rgba(248, 61, 20, 0.8), 0 0 25px rgba(248, 34, 20, 0.8);
  border-radius: 4px;
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1rem;
  letter-spacing: 0.01em;
  color: #FFFFFF;
`

export const Image = styled.img`
  cursor: pointer;
  height: 24px;
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return 'border-radius: 50%;'
    }
    return 'border-radius: 4px;'
  }}
`
