import { DELETE_PROJECT_CUSTOM_FIELD } from './index'

export const requestDeleteProjectCustomField = (payload) => ({
    type: DELETE_PROJECT_CUSTOM_FIELD.REQUEST,
    payload,
})

export const successDeleteProjectCustomField = (payload) => ({
    type: DELETE_PROJECT_CUSTOM_FIELD.SUCCESS,
    payload,
})

export const errorDeleteProjectCustomField = (payload) => ({
    type: DELETE_PROJECT_CUSTOM_FIELD.ERROR,
    payload,
})