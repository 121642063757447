import React, {
  useCallback, useEffect, useMemo,
  useState
} from 'react'
import { Form, Formik } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import get from 'lodash/get'
import pt from 'prop-types'
import {
  Container,
  Header,
  AddTaskButton,
  LabelContainer,
  Label,
  CheckBoxContainer,
  TaskContainer,
  TasksWrapper,
  DeleteTaskButton,
  InputAndLabelContainer,
  FieldsContainer,
  AddActionButton,
  CustomFieldContainer,
  ActionsWrapper,
  ChartContainer,
  ActionBlock,
  PercentContainer,
  InfoBlock,
  SaveTaskButton,
  IconTooltip,
  ActionsContainer,
  ChartTitle,
  ChartWrapper
} from './styles'
import UsersIcons from '@/components/icons/users'
import CheckBox from '@/components/controls/CheckBox'
import HeaderIcons from '@/components/icons/header'
import MapIcons from '@/components/icons/maps'
import TextField from '@/components/fields/TextField'
import { createTaskValidator } from '@/constants/validationFields'
import {
  IconContainer,
} from '@/forms/ScheduleManager/CreateEditScheduleForm/styles'
import taskConfig from '@/forms/ScheduleManager/CreateEditTaskForm/config'
import {
  ACTIONS,
  DATE_PICKER,
  TEXT
} from '@/constants/semanticNames'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import CoreIcons from '@/components/icons/core'
import DatePickerField from '@/components/fields/DatePickerField'
import {
  END_DATE,
  HOURS,
  MINUTES,
  PERCENT
} from '@/constants/forms/createSchedule'
import {
  formatDataForGraph,
  getScheduleChartOptions,
} from '@/constants/graph/scheduleManager'
import ValueSelectField from '@/components/fields/ValueSelectField'
import moment from 'moment'
import GlobalPopup from '@/components/blocks/GlobalPopup'
import GlobalPopupContent from '@/components/blocks/GlobalPopupContent'
import MenuIcon from '@/components/icons/menu'
import CanvasJSReact from "@canvasjs/react-charts";
import _ from 'lodash'

// eslint-disable-next-line no-extend-native
Date.prototype.addDays = function(days) {
  let date = new Date(this.valueOf())
  date.setDate(date.getDate() + days)
  return date
}
// eslint-disable-next-line no-extend-native
Date.prototype.subDays = function(days) {
  let date = new Date(this.valueOf())
  date.setDate(date.getDate() - days)
  return date
}

const CreateEditTaskForm = React.forwardRef( ({
  formValues,
  initialValues,
  edit,
  setFormValues,
  selectedDeviceType,
  setIsTaskFormValid,
  intl
}, ref) => {
  const calculateMinDate = useCallback((tasks, id) => {
    const minDate = moment(tasks.filter(task => task.id === id - 1)[0]?.endDate?.addDays(1)).format('MM.DD.YYYY')

    return new Date(minDate)
  }, [])

  const calculateMaxDate = useCallback((tasks, id) => {
    if (id === tasks.length - 1) {
      return null
    }

    const maxDate = moment(tasks.filter(task => task.id === id + 1)[0]?.startDate?.subDays(1)).format('MM.DD.YYYY')

    return new Date(maxDate)
  }, [])

  const [selectedTask, setSelectedTask] = useState({
    id: 0,
    showCheckbox: false,
    name: `Новое задание 1`,
    workingHours: 0,
    actions: [
      {
        disabled: false,
        timeDisabled: true,
        canDelete: false,
        hours: '00',
        minutes: '00',
        percent: 100
      }
    ]
  })
  const [checkedTasks, setCheckedTasks] = useState([])
  const [tasks, setTasks] = useState([selectedTask])
  const [firstChartData, setFirstChartData] = useState([])
  const [secondChartData, setSecondChartData] = useState([])
  const [minDate, setMinDate] = useState(Date.now())
  const [maxDate, setMaxDate] = useState()
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isFormValid, setIsFormValid] = useState(false)
  const [canAddTask, setCanAddTask] = useState(edit)
  const [daysCount, setDaysCount] = useState(0)
  const isLight = useMemo(() => selectedDeviceType === 'RM' ,[selectedDeviceType])

  const handleSetPopup = useCallback((value) => () => {
    setIsPopupOpen(value)
  }, [])

  useEffect(() => {
    if (edit && initialValues.length) {
      const localInitialValues = initialValues.sort((a, b) => a.id - b.id).map(item => {
        return {
          ...item,
          startDate: new Date(item.originalStartDate),
          endDate: new Date(item.originalEndDate)
        }
      })
      const updatedValues = isLight ? localInitialValues : localInitialValues.map(item => {
        return {
          ...item,
          actions: item.actions.map(action => {
            return {
              ...action,
              percent: action.percent ? 100 : 0
            }
          })
        }
      })
      setTasks(updatedValues)
      setSelectedTask(updatedValues[0])
      setFormValues({...formValues, tasks: updatedValues})
      setMaxDate(calculateMaxDate(updatedValues, 0))
      setIsTaskFormValid(true)
      return
    }
    setFormValues({...formValues, tasks: [selectedTask]})
  }, [edit, initialValues, calculateMaxDate, setIsTaskFormValid, selectedDeviceType, isLight])

  useEffect(() => {
    setFirstChartData([])
    setSecondChartData([])
    setDaysCount(0)
  }, [selectedDeviceType])

  const calculateWorkingHours = useCallback((data, startDate, endDate) => {
    if (!data) {
      return
    }
    let workingHours = 0
    const daysCount = moment.duration(moment(endDate, 'hh:mm').diff(moment(startDate, 'hh:mm'))).asDays() + 1
    data.forEach((item, index) => {
      if (item.y) {
        if (index <= data.length - 2) {
          const start = moment(item.label, 'hh:mm')
          const end = moment(data[index + 1].label, 'hh:mm')
          const duration = moment.duration(end.diff(start))
          workingHours += duration.asMilliseconds()
        }
      }
    })

    return {
      daysCount: daysCount,
      workingHours: workingHours
    }
  }, [])

  const addTaskHandler = useCallback(() => {
    setCanAddTask(false)
    setIsFormValid(false)
    setFirstChartData([])
    setSecondChartData([])
    setDaysCount(0)
    const tasksLength = tasks.length
    const actionsLength = tasks[tasksLength - 1].actions.length
    const defaultTask = {
      id: tasks.length,
      showCheckbox: true,
      checked: false,
      name: `Новое задание ${tasks.length + 1}`,
      startDate: tasks[tasksLength - 1].endDate?.addDays(1),
      actions: [
        {
          disabled: true,
          canDelete: false,
          hours: '00',
          minutes: '00',
          percent: tasks[tasksLength - 1]?.actions[actionsLength - 1]?.percent
        }
      ]
    }
    setTasks([...tasks, defaultTask])
  }, [setTasks, tasks])

  const deleteSelectedTasksHandler = useCallback(() => {
    setIsPopupOpen(false)
    setCheckedTasks([])
    setCanAddTask(true)
    const updatedTasks = tasks.filter(item => !checkedTasks.includes(item)).map((task, index) => {
      if (!index) {
        return task
      }
      const previousTaskActionsLength = tasks[index - 1].actions.length
      const actions = task.actions
      if (!actions.length) {
        return task
      }
      actions[0].percent = tasks[index - 1].actions[previousTaskActionsLength - 1].percent

      return {
        ...task,
        id: tasks[index - 1].id + 1,
        actions,
      }
    })
    setTasks(updatedTasks)
    setSelectedTask(updatedTasks[0])
    setFormValues({...formValues, tasks: updatedTasks})
    setFirstChartData([])
    setSecondChartData([])
    setDaysCount(0)
  }, [checkedTasks, tasks, formValues, setFormValues])

  const saveAction = useCallback((values) => {
    const fields = values.actions || []
    const chartData = fields.map(action => {
      if (action.hours === '00' && action.minutes === '00' && action.canDelete) {
        return {
          time: '24:00',
          percent: Number(action.percent),
        }
      }
      return {
        time: !!action.hours && !!action.minutes ? `${action.hours}:${action.minutes}` : '00:00',
        percent: Number(action.percent || 0),
      }
    })
    const { daysCount, workingHours } = calculateWorkingHours(formatDataForGraph(chartData, intl), values.startDate, values.endDate)
    setDaysCount(daysCount)
    values.dailyWorkingHours = workingHours
    setFirstChartData(chartData)
    setSecondChartData(chartData)
    if (daysCount > 1) {
      const secondDayData = calculateWorkingHours(formatDataForGraph(chartData, intl, true), values.startDate, values.endDate)
      values.workingHours = secondDayData.workingHours * (daysCount - 1) + workingHours
      return
    }
    values.workingHours = workingHours
  }, [calculateWorkingHours, intl])

  const saveTaskHandler = useCallback((values) => () => {
    setIsFormValid(false)
    setCanAddTask(true)
    setIsTaskFormValid(true)
    const fields = values.actions || []
    saveAction(values)
    let updatedTasks = tasks.filter(task => task.id !== values.id)
    updatedTasks.push(values)
    updatedTasks.sort((a, b) => a.id - b.id)
    const id = updatedTasks.findIndex(task => task.id === values.id)
    if (updatedTasks.length > 1 && updatedTasks[id + 1] && updatedTasks[id + 1].actions) {
      updatedTasks[id + 1].actions[0].percent = values?.actions[fields.length - 1]?.percent
    }
    setTasks(updatedTasks)
    setSelectedTask(values)
    setFormValues({...formValues, tasks: updatedTasks})
  }, [
    tasks,
    formValues,
    setFormValues,
    saveAction,
    setIsTaskFormValid
  ])

  const checkTask = useCallback((task) => {
    const updatedTasks = tasks.map(item => {
      if (item.id === task.id) {
        return {
          ...item,
          checked: !item.checked
        }
      } else {
         return item
      }
    })

    const checkedTasks = []
    updatedTasks.forEach(item => {
      if (item.checked) {
        checkedTasks.push(item)
      }
    })

    setTasks(updatedTasks)
    setCheckedTasks(checkedTasks)
  }, [tasks, setCheckedTasks])

  const removeAction = useCallback((values, setFieldValue, index) => () => {
    values.actions.splice(index, 1)

    const fields = values.actions || []
    setFieldValue(
      ACTIONS,
      [
       ...fields,
      ],
    )
    saveAction(values)
    setSelectedTask(values)
  }, [saveAction])

  const createActionHandler = useCallback((values, setFieldValue) => () => {
    const fields = values.actions || []
    let isValid
    fields.forEach(field => {
      isValid = !!field.hours || !!field.minutes || field.percent >= 0
    })
    if (!isValid && !fields) {
      return
    }
    setFieldValue(
      ACTIONS,
      [
       ...fields,
       {
         disabled: false,
         canDelete: true,
         percent: 0
       },
      ],
    )
  }, [])

  const onSubmit = useCallback((values) => {
    console.log(values)
  }, [])

  const setFieldToNull = (selector, setFieldValue) => () => {
    setFieldValue(selector, '')
  }

  const renderTask = (task) => {
    const handleOpenDetails = () => {
      setSelectedTask(task)
      setFirstChartData([])
      setSecondChartData([])
      setDaysCount(0)
      setMinDate(calculateMinDate(tasks, task.id))
      setMaxDate(calculateMaxDate(tasks, task.id))
    }
    const handleCheck = (event) => {
      event.stopPropagation()
      checkTask(task)
    }

    return (
      <TaskContainer onClick={handleOpenDetails}>
        {
         task.showCheckbox &&
           <CheckBoxContainer onClick={handleCheck}>
             <CheckBox value={task.checked} />
           </CheckBoxContainer>
        }
        {task.name}
        <MapIcons.BackIcon />
      </TaskContainer>
    )
  }

  const renderOption = (errors, touched, isSubmitting, values, setFieldValue) => (element) => {
    const {
      selector,
      type,
      format,
      mask,
      immutable,
      pattern,
      disabled = false
    } = element

    return (
      <InputAndLabelContainer>
        {type === TEXT && (
          <>
            <LabelWithIcon
              isError={(touched[selector] && errors[selector])}
              title={<Lang id={`scheduleManager.form.${selector}`} />}
            />
            <TextField
              error={(touched[selector] && errors[selector])}
              name={selector}
              mask={mask}
              fieldProps={{
                autoComplete: 'off',
                format,
                pattern,
              }}
              controls={(
                <>
                  {(get(values, selector, null)) && !(isSubmitting || isSubmitting || immutable)
                    && (
                       <PortalTooltip
                         title={(<Lang id="tooltip.erase" />)}
                         renderChildren={(
                           wrapperRef,
                           onMouseEnterHandler,
                           onMouseLeaveHandler,
                         ) => (
                             <IconContainer
                               type="cross"
                               onClick={setFieldToNull(selector, setFieldValue)}
                               ref={wrapperRef}
                               onMouseEnter={onMouseEnterHandler}
                               onMouseLeave={onMouseLeaveHandler}
                             >
                               <CoreIcons.EraserIcon />
                             </IconContainer>
                         )}
                       />
                      )
                  }
                </>
              )}
            />
          </>
        )}
        {type === DATE_PICKER && (
          <>
            <LabelWithIcon
              isError={(touched[selector] && errors[selector])}
              title={<Lang id={`scheduleManager.form.${selector}`} />}
            />
              <DatePickerField
                error={(touched[selector] && errors[selector])}
                name={selector}
                minDate={minDate}
                maxDate={element.selector === END_DATE ? maxDate : null}
                disabled={disabled}
                fieldProps={{
                  autoComplete: 'off',
                  format,
                }}
              />
          </>
        )}
        {type === ACTIONS && (
          <ActionsWrapper>
            <LabelWithIcon
              title={<Lang id={`scheduleManager.form.actions`} />}
            />
            {(values.actions || []).map((action, index) => {
              const localSelector = `${selector}[${index}]`
              const localSelectorHours = `${localSelector}.hours`
              const localSelectorMinutes = `${localSelector}.minutes`
              const localSelectorPercent = `${localSelector}.percent`
              const hoursTouch = get(touched, localSelectorHours, null)
              const hoursError = get(errors, localSelectorHours, null)
              const beforeHoursChange = (newState) => {
                let { value } = newState
                let selection = newState.selection

                if (Number(value.slice(0,1)) >= 3) {
                  value = '__'
                  selection = { start: 0, end: 0 }
                }

                if (Number(value) >= 24) {
                  value = `${value.slice(0,1)}_`
                  selection = { start: 1, end: 1 }
                }

                return {
                  value,
                  selection
                }
              }
              const beforeMinutesChange = (newState) => {
                let { value } = newState
                let selection = newState.selection

                if (Number(value.slice(0,1)) >= 6) {
                  value = '__'
                  selection = { start: 0, end: 0 }
                }

                return {
                  value,
                  selection
                }
              }

                return (
                  <CustomFieldContainer>
                    <ActionBlock>
                      <LabelWithIcon
                        title={<Lang id={`scheduleManager.form.${HOURS}`} />}
                        isError={hoursTouch && hoursError}
                      />
                      <TextField
                        error={hoursTouch && hoursError}
                        name={localSelectorHours}
                        mask={'99'}
                        beforeMaskedValueChange={beforeHoursChange}
                        fieldProps={{
                          autoComplete: 'off',
                          format: 'number',
                          pattern:'[0-9]',
                          disabled: action.disabled || action.timeDisabled
                        }}
                      />
                    </ActionBlock>
                    <ActionBlock>
                      <LabelWithIcon
                        title={<Lang id={`scheduleManager.form.${MINUTES}`} />}
                      />
                      <TextField
                        name={localSelectorMinutes}
                        mask='99'
                        beforeMaskedValueChange={beforeMinutesChange}
                        fieldProps={{
                          autoComplete: 'off',
                          disabled: action.disabled || action.timeDisabled,
                          format: 'number',
                          pattern: '[0-9]',
                        }}
                      />
                    </ActionBlock>
                    <ActionBlock>
                      <LabelWithIcon
                        title={isLight ? <Lang id={`scheduleManager.form.${PERCENT}`} /> : <Lang id={`scheduleManager.form.condition`} />}
                      />
                      <PercentContainer>
                        {isLight ?
                          <TextField
                            name={localSelectorPercent}
                            fieldProps={{
                              autoComplete: 'off',
                              disabled: action.disabled,
                              format: 'number',
                              pattern: '[0-9]',
                            }}
                          /> :
                          <ValueSelectField
                            disabled={action.disabled}
                            name={localSelectorPercent}
                            min={0}
                            max={100}
                            light
                            noTooltip={!isLight}
                            step={100}
                          />
                        }
                      </PercentContainer>
                    </ActionBlock>
                    {
                      <>
                        {
                          !!action.hours && !!action.minutes && action.percent >= 0 &&
                            <IconContainer onClick={() => saveAction(values)}>
                              <PortalTooltip
                                title={<Lang id={`scheduleManager.tooltip.chart`} />}
                                renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                                  <IconTooltip
                                    ref={wrapperRef}
                                    onMouseEnter={onMouseEnterHandler}
                                    onMouseLeave={onMouseLeaveHandler}
                                  >
                                    <CoreIcons.HistogramChartIcon/>
                                  </IconTooltip>
                                )}
                              />
                            </IconContainer>
                         }
                        {
                          action.canDelete &&
                            <IconContainer onClick={removeAction(values, setFieldValue, index)}>
                              <PortalTooltip
                                title={<Lang id={`scheduleManager.tooltip.delete`} />}
                                renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                                  <IconTooltip
                                    ref={wrapperRef}
                                    onMouseEnter={onMouseEnterHandler}
                                    onMouseLeave={onMouseLeaveHandler}
                                  >
                                    <UsersIcons.TrashIcon />
                                  </IconTooltip>
                                )}
                              />
                            </IconContainer>
                        }
                      </>
                    }
                  </CustomFieldContainer>
                )
            })}
          </ActionsWrapper>
        )}
      </InputAndLabelContainer>
    )
  }

  const CanvasJSChart = CanvasJSReact.CanvasJSChart
  const firstChartOptions= getScheduleChartOptions(isLight, firstChartData, intl)
  const secondChartOptions= getScheduleChartOptions(isLight, secondChartData, intl, true)
  const areEqual = _.isEqual(firstChartOptions.data, secondChartOptions.data)

  return (
      <>
        <Container>
          <Header>
            <LabelContainer row>
              <Label>
                <Lang id="scheduleManager.form.tasks"/>
                {checkedTasks.length
                    ? ` (${checkedTasks.length}/${tasks.length})`
                    : ` (${tasks.length})`}
              </Label>
            </LabelContainer>
            {checkedTasks.length
                ? <PortalTooltip
                    title={<Lang id={`scheduleManager.tooltip.deleteSelected`} />}
                    renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                      <IconTooltip
                        ref={wrapperRef}
                        onMouseEnter={onMouseEnterHandler}
                        onMouseLeave={onMouseLeaveHandler}
                      >
                        <DeleteTaskButton onClick={handleSetPopup(true)}>
                          <HeaderIcons.DeleteIcon/>
                        </DeleteTaskButton>
                      </IconTooltip>
                    )}
                />
                : canAddTask && <PortalTooltip
                    title={<Lang id={`scheduleManager.tooltip.addTask`} />}
                    renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                      <IconTooltip
                        ref={wrapperRef}
                        onMouseEnter={onMouseEnterHandler}
                        onMouseLeave={onMouseLeaveHandler}
                      >
                        <AddTaskButton onClick={addTaskHandler}>
                          <UsersIcons.PlusIcon />
                        </AddTaskButton>
                      </IconTooltip>
                    )}
                />
            }
          </Header>
          <TasksWrapper>
            {tasks.map(task => renderTask(task))}
          </TasksWrapper>
        </Container>
        <>
          <ActionsContainer>
            <Formik
              onSubmit={onSubmit}
              validationSchema={createTaskValidator}
              validate={(values) => {
                createTaskValidator.validate(values)
                  .then(() => setIsFormValid(true))
                  .catch(() => {
                    setIsFormValid(false)
                    setCanAddTask(false)
                  })
              }}
              enableReinitialize
              validateOnChange
              initialValues={selectedTask}
              render={({
                touched, errors, isSubmitting, values, setFieldValue,
              }) => {
                return (
                  <Form id="TaskForm" name="TaskForm">
                    <>
                      <Header>
                        <Label>
                          {selectedTask?.name}
                        </Label>
                      </Header>
                      <FieldsContainer>
                        {taskConfig.map(renderOption(touched, errors, isSubmitting, values, setFieldValue))}
                        <AddActionButton onClick={createActionHandler(values, setFieldValue)}>
                          <Lang id={`scheduleManager.form.addAction`} />
                          <UsersIcons.PlusIcon />
                        </AddActionButton>
                        <SaveTaskButton onClick={saveTaskHandler(values)} disabled={!isFormValid}>
                          <Lang id={`scheduleManager.form.saveTask`} />
                        </SaveTaskButton>
                        <InfoBlock>
                          <LabelWithIcon
                            title={<Lang id={'scheduleManager.form.dailyWorkingHours'}/>}
                          />
                          {`${Math.floor(moment.duration(selectedTask?.dailyWorkingHours).asHours()) || 0} ${intl.messages[`widgets.hour`]} ${moment.duration(selectedTask?.dailyWorkingHours).minutes() || 0} ${intl.messages[`widgets.minutes`]}`}
                        </InfoBlock>
                        <InfoBlock>
                          <LabelWithIcon
                            title={<Lang id={'scheduleManager.form.workingHours'}/>}
                          />
                          {`${Math.floor(moment.duration(selectedTask?.workingHours).asHours()) || 0} ${intl.messages[`widgets.hour`]} ${moment.duration(selectedTask?.workingHours).minutes() || 0} ${intl.messages[`widgets.minutes`]}`}
                        </InfoBlock>
                      </FieldsContainer>
                    </>
                  </Form>
                )
              }}
            />
          </ActionsContainer>
          <ChartContainer>
            <ChartWrapper>
              <ChartTitle>
                {areEqual ? <Lang id={'scheduleManager.graphTitle'}/> : <Lang id={'scheduleManager.firstGraphTitle'}/>}
              </ChartTitle>
              <CanvasJSChart options={firstChartOptions} />
            </ChartWrapper>
            {!areEqual && daysCount > 1 &&
              <ChartWrapper>
                <ChartTitle>
                  <Lang id={'scheduleManager.secondGraphTitle'}/>
                </ChartTitle>
                <CanvasJSChart options={secondChartOptions} />
              </ChartWrapper>
            }
          </ChartContainer>
        </>
        {isPopupOpen && (
          <GlobalPopup
            content={(
              <GlobalPopupContent
                type={'error'}
                onClose={handleSetPopup(false)}
                title={<Lang id={'scheduleManager.popup.attention'}/>}
                message={<Lang id={'scheduleManager.popup.deleteTaskMessage'}/>}
                config={
                  {
                    error: {
                      icon: MenuIcon.TreshIcon,
                      buttons: [
                        {
                          statusType: 'flat',
                          onClickSelector: handleSetPopup(false),
                          title: <Lang id="scheduleManager.popup.no" />,
                        },
                        {
                          statusType: 'error',
                          onClickSelector: deleteSelectedTasksHandler,
                          title: <Lang id="scheduleManager.popup.yes" />,
                        },
                      ],
                    },
                  }
                }
              />
            )}
          />
        )}
      </>
  )
})

CreateEditTaskForm.defaultProps = {
  setFormValues: noop,
  submit: noop,
  formValues: {},
  edit: false,
  selectedDeviceType: ''
}
CreateEditTaskForm.propTypes = {
  selectedSchedule: pt.objectOf(pt.object),
  setFormValues: pt.func,
  submit: pt.func,
  formValues: pt.objectOf(pt.string),
  edit: pt.bool,
  selectedDeviceType: pt.string,
}

export default CreateEditTaskForm