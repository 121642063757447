import {
    put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { DELETE_PROJECT_CUSTOM_FIELD } from '@/store/actions/installation'
import { generateDeleteProjectCustomField } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification';
import { successDeleteProjectCustomField } from '@/store/actions/installation/deleteProjectCustomField';

function* deleteProjectCustomFieldSaga({ payload }) {
    const { id } = payload
    try {
        yield request({
            url: generateDeleteProjectCustomField(id),
            method: 'delete',
        })
        yield put(successDeleteProjectCustomField())

    } catch (error) {
        const toast = createNotifications()
        toast({
            title: 'ОШИБКА ОПЕРАЦИИ!',
            type: 'error',
            description: 'Невозможно удалить поле',
        })
    }
}

export default function* root() {
    yield takeLatest(DELETE_PROJECT_CUSTOM_FIELD.REQUEST, deleteProjectCustomFieldSaga)
}
