export default {
  createGeoZoneForm: {
    name: 'Geozone name*',
    parentId: 'Parent geozone*',
    parentGeoZoneName: 'Parent geozone',
    parentName: 'Parent project',
    lat: 'Geographic latitude*',
    lon: 'Geographic longitude*',
    'location[0]': 'Latitude',
    'location[1]': 'Geographic longitude',
    'geoLocationDto.latitude': 'Geographic latitude',
    'geoLocationDto.longitude': 'Geographic longitude',
    'point.latitude': 'Geographic latitude',
    'point.longitude': 'Geographic longitude',
    region: 'Region',
    city: 'City',
    mark: 'Note',
    note: 'Note',
    type: 'Type*',
    geoZoneInstallation: 'Geozone installation',
    geoZoneInfo: 'Geozone info:',
    projectInfo: 'Project info:',
    integrationInfo: 'Integration info:',
    infoStructure: 'Infrastructure:',
    save: 'Save',
    cancel: 'Cancel',
    projectId: 'Project*',
    integrationType: 'Integration Type*',
    radius: 'Radius, km*',
    maxRadius: 'Maximum radius of internal geozones, km*'
  },
  projectForm: {
    name: 'Project name*',
    parentId: 'Parent geozone*',
    parentGeoZoneName: 'Parent geozone',
    parentProjectName: 'Parent project',
    lat: 'Geographic latitude*',
    lon: 'Geographic longitude*',
    'location[0]': 'Latitude',
    'location[1]': 'Geographic longitude',
    'geoLocationDto.latitude': 'Geographic latitude',
    'geoLocationDto.longitude': 'Geographic longitude',
    'point.latitude': 'Geographic latitude',
    'point.longitude': 'Geographic longitude',
    region: 'Region',
    city: 'City',
    mark: 'Note',
    note: 'Note',
    type: 'Type*',
    geoZoneInstallation: 'Geozone installation',
    geoZoneInfo: 'Geozone info:',
    projectInfo: 'Project info:',
    integrationInfo: 'Integration info:',
    infoStructure: 'Infrastructure:',
    save: 'Save',
    cancel: 'Cancel',
    projectId: 'Project*',
    integrationType: 'Integration Type*',
    customField: 'Add custom field',
    customFieldTitle: 'Name:',
    customFieldValue: 'Content:',
    newField: 'New field',
    deleted: 'deleted',
    objectType: 'Object type*',
    selectObject: 'Select equipment',
  },
  integrationForm: {
    name: 'Integration name*',
    parentId: 'Parent geozone*',
    parentGeoZoneName: 'Parent geozone',
    parentProjectName: 'Parent project',
    lat: 'Geographic latitude*',
    lon: 'Geographic longitude*',
    'location[0]': 'Latitude',
    'location[1]': 'Geographic longitude',
    'geoLocationDto.latitude': 'Geographic latitude',
    'geoLocationDto.longitude': 'Geographic longitude',
    'point.latitude': 'Geographic latitude',
    'point.longitude': 'Geographic longitude',
    region: 'Region',
    city: 'City',
    mark: 'Note',
    note: 'Note',
    type: 'Type*',
    geoZoneInstallation: 'Geozone installation',
    geoZoneInfo: 'Geozone info:',
    projectInfo: 'Project info:',
    integrationInfo: 'Integration info:',
    infoStructure: 'Infrastructure:',
    save: 'Save',
    cancel: 'Cancel',
    projectId: 'Project*',
    integrationType: 'Integration Type*',
  },
  title: 'Installation',
  addGeoZoneTitle: 'Installation geozone',
  editGeoZoneTitle: 'Editing geozone',
  addProjectTitle: 'Adding project',
  editProjectTitle: 'Editing project',
  addIntegrationTitle: 'Creating integration',
  editIntegrationTitle: 'Editing integration',
  addObjectTitle: 'Installation object',
  editObjectTitle: 'Editing object',
  cancelAdd: 'Cancel adding',
  cancel: 'Cancel',
  add: 'Add',
  filterObjects: 'Filtering objects',
  allObjects: 'All objects',
  uploadFile: 'Upload installation file',
  downloadFile: 'Download installation file template',
  popup: {
    attention: 'Attention!',
    impossible: 'Impossible to execute!',
    editObject: 'Edit object',
    editObjectMessage: 'Save current settings?',
    syncObject: 'Synchronizing an object',
    syncObjectMessage: 'Go through verification\nor save current settings?',
    deleteObject: 'Do you really want delete an object from the system?',
    deleteMessage: 'Are you sure you want to delete',
    cancelMessage: 'Do you really want\ncancel',
    installation: 'installation',
    edit: 'edit',
    edit1: 'edit',
    add: 'add',
    add1: 'add',
    continueMessage: 'To continue you must\nchoose',
    geoZoneContinueMessage: 'To continue you must\nchoose geozone',
    deleteGeoZoneMessage: 'All geozones, projects, integrations and control objects included in this geofence will be deleted.',
    deleteProjectMessage: 'All integrations and control objects included in this project will be deleted.',
    deleteIntegrationMessage: 'All control objects included in this integration will be deleted.',
    for: 'For',
    requiredMessage: 'necessary\n set all required parameters',
    fromSystem: 'from the system',
    geozone: 'geozone',
    geozone1: 'geozone',
    project: 'project',
    project1: 'project',
    object: 'object',
    object1: 'object',
    integration: 'integration',
    integration1: 'integration',
    objectOrIntegration: 'object or integration',
  },
  card: {
    geoZones: 'Geozones',
    projects: 'Projects',
    integrations: 'Integrations',
    objects: 'Objects',
  }
}