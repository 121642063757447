import styled from 'styled-components'

export const Pagination = styled.div`
  display: flex;
  justify-content: center;
`

export const PageButton = styled.button`
  margin: 0 5px;
  padding: 5px 10px;
  cursor: pointer;
  border: 1px solid #ddd;
  background-color: ${props => (props.active ? '#FF9A02' : '#f9f9f9')};
  color: ${props => (props.active ? 'white' : 'black')};
  border-radius: 4px;
`

export const FilterSelect = styled.select`
  appearance: none;
  background-color: white;
  border: 1px solid #ccc;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 1.1rem;
  color: #333;
  outline: none;
  cursor: pointer;
  transition: all 0.3s ease;
  background-image: url('data:image/svg+xml;utf8,<svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" 
  stroke="black"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 9l-7 7-7-7"/></svg>');
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
  width: 100px;
  height: 30px;

  &:hover {
    border-color: #888;
  }

  &:focus {
    border-color: #FF9A02;
    box-shadow: 0 0 5px #f8b451;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  gap: 5px;
  height: 19px;
  position: relative;
`

export const FilterInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: start;
  height: 50px;
  position: relative;
`

export const FilterInputError = styled.span`
  color: red;
  font-size: 1.1rem;
`

export const FilterOption = styled.option`
  background-color: white;
  color: #333;
  padding: 10px;
`;

export const FilterInput = styled.input`
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 1.1rem;
  outline: none;
  transition: all 0.3s ease;
  width: 200px;
  height: 30px;

  &:hover {
    border-color: #888;
  }

  &:focus {
    border-color: #FF9A02;
    box-shadow: 0 0 5px #f8b451;
  }

  &::placeholder {
    color: #aaa;
  }
`;

export const FilterButton = styled.button`
  background-color: #FF9A02;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  height: 30px;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

export const ClearButton = styled(FilterButton)`
  background-color: #FF5733;
`;

export const SuggestionsList = styled.ul`
  position: absolute;
  top: calc(100% - 15px);
  left: 0;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
  overflow-y: auto;
  z-index: 1000;
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

export const SuggestionItem = styled.li`
  padding: 8px;
  cursor: pointer;
  background-color: ${({ isActive }) => (isActive ? '#f0f0f0' : 'white')};
  font-weight: 600;

  &:hover {
    background-color: #f0f0f0;
  }
`