import widgets from '../widgets'

const {
    CIRCUIT_STATE,
    ALARM_STATE,
    STATUS
} = widgets

export default [
    STATUS({}),
    CIRCUIT_STATE({}),
    ALARM_STATE({}),
]