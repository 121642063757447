import styled from 'styled-components'

export const TooltipWrapper = styled.div`

`
export const ToolTip = styled.div`
  z-index: 10000;
  position: absolute;
  top: ${({ top }) => top}px;
  left: ${({ left }) => left}px;
  font-size: 1.4rem;
  font-weight: 400;
  padding: 5px 10px;
  border-radius: 4px;
  color: white;
  ${({ isVisible }) => !isVisible && `
    visibility: hidden;
  `}
  color: ${({ theme }) => theme.colors.backgrounds.default};
  background: ${({ theme }) => theme.colors.colors.default};

  &&:before {
    ${({ theme, tipPosition }) => tipPosition && `
    z-index: 800;
      content: "";
      position: absolute;
      margin-left: -10px;
      border-width: 7px;
      border-style: solid;
      border-color: transparent;
      border-top-color: ${theme.colors.colors.default};
    `}

    ${({ tipPosition }) => {
    if (tipPosition === 'top') {
      return `
          top: 100%;
          left: 50%;
          `
    }
    if (tipPosition === 'bottom') {
      return `
      top: 0%;
      left: 50%;
      transform-origin: top;
      transform: rotate(180deg);
      `
    }
    if (tipPosition === 'left') {
      return `
        top: 50%;
        left: 100%;
        transform-origin: center;
        transform: translate(70%, -50%) rotate(-90deg);
      `
    }
    if (tipPosition === 'right') {
      return `
        top: 50%;
        left: 0%;
        transform-origin: center;
        transform: translate(-25%, -50%) rotate(90deg);
      `
    }
    return ''
  }}
}

`
