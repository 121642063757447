import createAction from '@/helpers/redux/createAction'

export const GET_PASSPORT_AND_CARD_DATA = createAction('@/PASSPORTIZATION_GET_PASSPORT_AND_CARD_DATA')
export const PUT_PASSPORT_AND_CARD_DATA = createAction('@/PUT_PASSPORT_AND_CARD_DATA')
export const GET_OBJECT_EQUIPMENT = createAction('@/PASSPORTIZATION_GET_OBJECT_EQUIPMENT')
export const GET_OPERATED_ELEMENT = createAction('@/PASSPORTIZATION_GET_OPERATED_ELEMENT')
export const ADD_PASSPORT_FILE = createAction('@/ADD_PASSPORT_FILE')
export const ADD_PASSPORT_PHOTO = createAction('@/ADD_PASSPORT_PHOTO')
export const GET_PASSPORT_FILE = createAction('@/GET_PASSPORT_FILE')
export const GET_PASSPORT_PHOTO = createAction('@/GET_PASSPORT_PHOTO')
export const DELETE_PASSPORT_FILE = createAction('@/DELETE_PASSPORT_FILE')
export const DELETE_PASSPORT_PHOTO = createAction('@/DELETE_PASSPORT_PHOTO')
export const EXPORT_PASSPORT_FILE = createAction('@/EXPORT_PASSPORT_FILE')
export const GET_PASSPORTIZATION_DATA = createAction('@/GET_PASSPORTIZATION_DATA')
export const SET_SELECTED_FIELDS = 'SET_SELECTED_FIELDS'
export const SET_OPERATED_ELEMENT = 'SET_OPERATED_ELEMENT'
export const SET_FIELDS_PASSPORT = createAction('SET_FIELDS_PASSPORT')
export const GET_SETTINGS_PASSPORT = 'GET_SETTINGS_PASSPORT'


export const setOperatedElement = (payload) => ({
  type: SET_OPERATED_ELEMENT,
  payload,
})

export const requestGetPassportAndCardData = (payload) => ({
  type: GET_PASSPORT_AND_CARD_DATA.REQUEST,
  payload,
})

export const successGetPassportAndCardData = (payload) => ({
  type: GET_PASSPORT_AND_CARD_DATA.SUCCESS,
  payload,
})

export const errorGetPassportAndCardData = (payload) => ({
  type: GET_PASSPORT_AND_CARD_DATA.ERROR,
  payload,
})

export const requestGetObjectEquipment = (payload) => ({
  type: GET_OBJECT_EQUIPMENT.REQUEST,
  payload,
})

export const successGetObjectEquipment = (payload) => ({
  type: GET_OBJECT_EQUIPMENT.SUCCESS,
  payload,
})

export const errorGetObjectEquipment = (payload) => ({
  type: GET_OBJECT_EQUIPMENT.ERROR,
  payload,
})

export const requestGetOperatedElement = (payload) => ({
  type: GET_OPERATED_ELEMENT.REQUEST,
  payload,
})

export const successGetOperatedElement = (payload) => ({
  type: GET_OPERATED_ELEMENT.SUCCESS,
  payload,
})

export const errorGetOperatedElement = (payload) => ({
  type: GET_OPERATED_ELEMENT.ERROR,
  payload,
})

export const setSelectedFields = (payload) => ({
  type: SET_SELECTED_FIELDS,
  payload,
})

export const requestActiveFieldsPassport = (payload) => ({
  type: SET_FIELDS_PASSPORT.REQUEST,
  payload,
})

export const successActiveFieldsPassport = (payload) => ({
  type: SET_FIELDS_PASSPORT.SUCCESS,
  payload,
})

export const getSettingsPassport = () => ({
  type: GET_SETTINGS_PASSPORT,
})

