import styled from 'styled-components'
import CheckBox from '@/components/controls/CheckBox'
import RadioButton from '@/components/controls/RadioButton'

export const StyledCheckBox = styled(CheckBox)`
  margin-right: 10px;
`

export const StyledRadioButton = styled(RadioButton)`
  margin-right: 10px;
`

export const StyledResetIcon = styled.div`
  margin-right: 10px;
  cursor: pointer;
`

export const Selection = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 28px;
  ${({ userDisabled }) => userDisabled && `
    cursor: default;
  `}
  font-size: 1.4rem;
  line-height: 3rem;
  padding-left: 10px;
`

export const Error = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  color: #828D99;
  font-size: 1.2rem;
  line-height: 1.4rem;
  width: 100%;
  margin-top: 5px;
  transition: color 100ms ease-in-out;
  text-align: left;
  word-break: break-word;
`

export const Value = styled.div`
  position: relative;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  font-weight: 500;
  font-size: 1.5rem;
  letter-spacing: 0.01em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  ${({ overflow }) => overflow && `
    max-width: 160px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: inline-block;
  `}

  svg {
    margin: auto 0 auto 10px;
  }
  ${({ light }) => light && `
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.5rem;
  `}
`

export const Arrow = styled.div`
  display: flex;
  padding: 5px;
  font-size: 10px;

  ${({ customElement }) => customElement && `
       margin-top: -3px;
  `}

  ${({ small }) => small && `
    padding: 10px 5px;
  `}
  ${({ withSearch }) => withSearch && `
    margin-left: 12px;
    font-size: inherit;
    right: 12px;
    svg,
    .svgFill {
      width: 15px;
    }
  `}
`

export const EnumerationContainer = styled.div`
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  flex-direction: column;
  align-items: center;
`

export const OptionsContainer = styled.div`
  ${({ enumeration }) => enumeration && `
    display: flex;
    flex-wrap: wrap;
  `}
`

export const Options = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 10;
  overflow: auto;
  ${({ optionsPosition }) => optionsPosition && `
    left: ${optionsPosition.left};
    right: ${optionsPosition.right};
    top: ${optionsPosition.top};
    bottom: ${optionsPosition.bottom};
  `}
  max-height: 400px;
  width: 100%;
  padding: 20px 15px;
  border-radius: 4px;
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  border: 1px solid ${({ theme }) => theme.colors.borders.textField};
  box-shadow: -8px 12px 18px rgba(0, 0, 0, 0.17);
  ${({ customPadding }) => customPadding && `
    padding-left: ${customPadding.left};
    padding-right: ${customPadding.right};
    padding-top: ${customPadding.top};
    padding-bottom: ${customPadding.bottom};
  `}
  ${({ top }) => (top
    ? 'bottom: calc(0px + 100%);'
    : 'top: 32px;')}
  ${({ withSearch }) => withSearch && `
    width: 100%;
    padding: 0;

    ${OptionsContainer} {
      padding: 10px;
    }
  `}
  ${({ enumeration }) => enumeration && `
    width: 290px;
  `}
`

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
  height: 48px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
`

export const MessageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 5px 10px;
  border-radius: 4px;
  background: #EEF0F1;
  color: #838D99;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 500;
  text-align: left;
  svg {
    width: 12px;
    margin: -1px 5px 0 0;
  }
`

export const Option = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  margin-bottom: 19px;
  font-size: 1.4rem;
  ${({ firstOptionBorder, theme }) => firstOptionBorder && `
    margin-bottom: 9.5px;
    padding-bottom: 9.5px;
    border-bottom: 1px solid ${theme.colors.borders.default};
  `}
  ${({ optionFontSize }) => optionFontSize && `
    font-size: ${optionFontSize};
  `}
  color: ${({ theme }) => theme.colors.colors.redisign.default};

  svg {
    max-width: 28px;
    max-height: 28px;
    margin: auto 10px auto 0;
  }

  &:hover {
    text-decoration: underline;
    ${({ enumeration, theme }) => enumeration && `
    background: ${theme.colors.scheme.backgrounds.default};
    color: ${theme.colors.colors.light};
    text-decoration: none;
  `}
  }
  &:last-child {
    margin-bottom: 0;
  }

  ${({ selected }) => selected && `
    background: ${({ theme }) => theme.colors.backgrounds.select};
    text-decoration: underline;
  `}

  ${({ disabled }) => disabled && `
    opacity: 0.7;
    cursor: not-allowed;
  `}
  ${({ enumeration }) => enumeration && `
    margin-bottom: 0px;
    min-width: 28px;
    max-width: 28px;
    min-height: 28px;
    max-height: 28px;
    margin: 4px;
    justify-content: center;
    border-radius: 4px;
  `}
  ${({ selected, enumeration, theme }) => selected && enumeration && `
    background: ${theme.colors.scheme.backgrounds.default};
    color: ${theme.colors.colors.light};
    text-decoration: none;
  `}
`

export const Select = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  height: 28px;
  background: ${({ theme, light }) => {
    if (light) {
      return theme.colors.white
    }
    return theme.colors.backgrounds.redisign.lightGray
  }};
  ${({ userDisabled, userForm, theme }) => (userDisabled || userForm) && `
    background: ${theme.colors.white};
  `}
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  box-sizing: border-box;
  border-radius: 4px;
  ${({ autoWidth }) => autoWidth && `
    width: auto;
  `}
  ${({ largeOptions }) => largeOptions && `
    ${Options} {
      min-width: 100%;
      width: fit-content;
    }
    ${Option} {
      white-space: nowrap;
    }
  `}
  ${({ opened, theme }) => opened && `
    background: ${theme.colors.backgrounds.default};
  `}
  &:focus {
    outline: 0;
  }

  ${({ opened, customIcon }) => opened && `
    ${Arrow} {
      svg {
        transform-origin: center center;
        transform: rotate(${customIcon ? 135 : 180}deg);
      }
    }
  `}

  ${({ disabled }) => disabled && `
    opacity: 0.7;
    ${Selection} {
      cursor: not-allowed;
    }
  `}
  ${({ styleType, opened, theme }) => {
    if (styleType === 'flat' && opened) {
      return (`
        ${Options} {
          width: 270px;
          border: 1px solid #BDC3C7;
          box-shadow: 2px 3px 15px rgba(61, 61, 61, 0.3);
          border-radius: 4px;
          padding: 5px 0;
          left: -35px;
        }
        ${Option} {
          font-weight: 500;
          color: ${theme.colors.colors.default};
          &:hover {
            color: ${theme.colors.colors.tooltip};
            background: ${theme.colors.colors.default};
            text-decoration: none;
          }
        }
        ${Arrow} {
          svg {
            .svgFill {
              fill: ${theme.colors.colors.tooltip};
            }
            .svgStroke {
              stroke: ${theme.colors.colors.tooltip};
            }
          }
        }
      `)
    }
  }}
  ${({ isError, theme }) => `
     border: 1px solid
      ${isError ? theme.colors.messages.error : theme.colors.borders.default};
  `}
`
