import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_ALERT_FILDS_OPTIONS } from '@/constants/apiRoutes'
import { GET_ALERT_FIELDS_OPTIONS } from '@/store/actions/dictionaries'
import createNotifications from '@/helpers/notification'
import { responseGetAlertFieldsOptions } from '@/store/actions/dictionaries/alertFildsOptions'
import moment from 'moment'
import { SET_ALARMS_FILTER_VALUES } from '@/store/actions/appSettings'
import getIsReadByStatus from '@/helpers/alarmsHelper'




function* getAlertDictionary({ payload }) {
    const { eventTypes, eventStatus, dates } = payload

    try {
        const { data } = yield request({
            url: GET_ALERT_FILDS_OPTIONS({
                params: {
                    alertTypes: eventTypes,
                    fromDate: dates.start ? moment(dates.start).format('DD.MM.YYYY') : null,
                    toDate: dates.end ? moment(dates.end).format('DD.MM.YYYY') : null,
                    isRead: getIsReadByStatus(eventStatus),
                }
            }),
            method: 'get',
        })

        const formattedData = data.description.map(item => {
            return {
                title: item.value,
                value: item.value,
            }
        })

        yield put(responseGetAlertFieldsOptions({ description: formattedData }))
       
    } catch (error) {
        const toast = createNotifications()
        toast({
            title: 'Ошибка операции!',
            description: 'Не удалось получить данные для формы расширенного поиска.\nПовторите попытку позже.',
            type: 'error',
        })
    }
}

export default function* () {
    yield takeLatest(GET_ALERT_FIELDS_OPTIONS.REQUEST, getAlertDictionary)
    yield takeLatest(SET_ALARMS_FILTER_VALUES, getAlertDictionary)
}