export default {
  messages: {
    default: 'Select a geozone and set the interval for displaying the energy saving chart',
    date: 'Date:',
    time: 'Time:',
    saved: 'Saved',
    lose: 'Overrun',
  },
  intervalOptions: {
    annualy: 'Annually',
    monthly: 'Monthly',
    weekly: 'Weekly',
    daily: 'Daily',
  },
  legend: {
    saving: '- saving',
    overrun: '- overrun',
    previous: '- previous period',
    abbreviatedPrevious: ' period',
    current: '- current period',
    actualConsumption: '- actual consumption',
    plannedConsumption: '- planned consumption',
  },
  names: {
    linearChart: 'Line chart',
    histogramChart: 'Histogram',
    export: 'Export',
  },
}