import styled from 'styled-components'

export const HeaderPage = styled.div`
  width: 100vw;
  display: flex;
  height: 50px;
  align-items: flex-end
  justify-content: space-between;
  margin-bottom: 5px;
  padding: 20px 5px 10px 0;
  background-color: #FAFBFB;
  z-index: 100;
  border-bottom: 1px solid #DDDDDD;
  position: absolute;
  left: 0;
  top: 0;

  & > svg {
    cursor: pointer;
  }
`

export const Title = styled.h1`
  font-size: 24px;
  color: #1061DF;
  padding-left: 10px;
`
