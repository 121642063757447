import React, { useCallback, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { FormattedMessage as Lang } from 'react-intl'
import isEqual from 'lodash/isEqual'
import noop from 'lodash/noop'
import pt from 'prop-types'
import {
  Content,
  Main
} from '@/containers/pages/Search/styles'
import SearchFormSidebar from '@/containers/pages/Search/components/SearchFormSidebar'
import {
  searchExportOptions,
  searchFields
} from '@/constants/tablesConfig/search'
import { getSearchTableParameters } from '@/store/selectors/search'
import { globalFiltersDefaultValues } from '@/constants/filters'
import { SEARCH_TABLE } from '@/store/actions/search'
import { PASPORTIZATION_URL } from '@/constants/routes'
import Loader from '@/components/blocks/Loader'
import MenuIcon from '@/components/icons/menu'
import useReduxTable from '@/hooks/useReduxTable'
import DutyTable from '@/components/blocks/DutyTable'
import GlobalPopup from '@/components/blocks/GlobalPopup'
import GlobalPopupContent from '@/components/blocks/GlobalPopupContent'
import useHtmlTitle from '@/hooks/useHtmlTitle'

const Search = ({
  data,
  loadingTable,
  selectedNode,
  globalFilters,
  searchFieldsOptions,
  searchFieldsOptionsLoading,
  requestGetSearchTable,
  requestGetSearchNode,
  resetGlobalFilterValues,
  setSearchValues,
  resetSearchTableParameters,
  searchValues,
  resetSearchValues,
  globalLoading,
  fileStatus,
  requestGetSearchReportFile,
  intl
}) => {
  useHtmlTitle(intl.messages['menu.advancedSearch'])
  const history = useHistory()
  const [isOpen, setIsOpen] = useState(true)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [searchNodeId, setSearchNodeId] = useState(null)
  const [fileOptions, setFileOptions] = useState(null)

  const tableProps = useReduxTable(SEARCH_TABLE, getSearchTableParameters)

  useEffect(() => {
    if (selectedNode?.id === searchNodeId && isEqual(globalFiltersDefaultValues, globalFilters)) {
      history.push(PASPORTIZATION_URL)
    }
  }, [selectedNode, searchNodeId, history, globalFilters])

  const handleGetTableData = useCallback((values) => {
    resetSearchTableParameters()
    setSearchValues(values)
    requestGetSearchTable()
  }, [requestGetSearchTable, setSearchValues, resetSearchTableParameters])

  const handleSearchReset = useCallback(() => {
    resetSearchValues()
  }, [resetSearchValues])

  const setSelectedElement = useCallback((element) => {
    if (!isEqual(globalFiltersDefaultValues, globalFilters)) {
      resetGlobalFilterValues()
    }
    setSearchNodeId(element.id)
    requestGetSearchNode({
      id: element.id,
      parentId: element.parentId
    })
  }, [globalFilters, resetGlobalFilterValues, setSearchNodeId, requestGetSearchNode])

  const handleFileDownload = useCallback((columns) => {
    requestGetSearchReportFile({
      allColumns: columns
    })
    setIsPopupOpen(false)
  }, [requestGetSearchReportFile])

  const handleFileSelect = useCallback((payload) => {
    const { totalElements, allColumns } = payload
    setFileOptions(allColumns)
    if (totalElements > 5000) {
      setIsPopupOpen(true)
    } else {
      handleFileDownload(allColumns)
    }
  }, [setFileOptions, handleFileDownload])

  return (
    <Main>
      <Content>
        <SearchFormSidebar
           isOpen={isOpen}
           toggleIsOpen={setIsOpen}
           handleGetTableData={handleGetTableData}
           searchFieldsOptions={searchFieldsOptions}
           searchFieldsOptionsLoading={searchFieldsOptionsLoading}
           searchValues={searchValues}
        />
        {isPopupOpen && (
          <GlobalPopup
            content={(
              <GlobalPopupContent
                type={'warning'}
                onClose={() => setIsPopupOpen(false)}
                message={<Lang id="search.file.text" />}
                config={
                  {
                    warning: {
                      icon: MenuIcon.AttentionIcon,
                      buttons: [
                        {
                          statusType: 'flat',
                          onClickSelector: () => setIsPopupOpen(false),
                          title: <Lang id="search.buttons.cancel" />,
                        },
                        {
                          statusType: 'warning',
                          onClickSelector: () => handleFileDownload(fileOptions),
                          title: <Lang id="search.buttons.continue" />,
                        },
                      ],
                    },
                  }
              }
              />
            )}
          />
        )}
        {loadingTable
           ? <Loader center />
           : (
             <DutyTable
               {...tableProps}
               fields={searchFields}
               exportOptions={searchExportOptions}
               data={data}
               rowSelector={setSelectedElement}
               onUpdateData={requestGetSearchTable}
               resetSearch={handleSearchReset}
               isDataLoading={globalLoading}
               fileStatus={fileStatus}
               onFileSelect={handleFileSelect}
             />)}
      </Content>
    </Main>
  )
}

Search.propTypes = {
  data: pt.arrayOf(pt.object),
  selectedNode: pt.shape({
    id: pt.oneOfType([pt.number, pt.string]),
    name: pt.string,
  }),
  globalFilters: pt.shape({}),
  searchFieldsOptions: pt.shape({}),
  searchValues: pt.shape({}),
  loadingTable: pt.bool,
  fileStatus: pt.string,

  requestGetSearchTable: pt.func,
  requestGetSearchNode: pt.func,
  requestGetSearchFieldsOptions: pt.func,
  resetGlobalFilterValues: pt.func,
  setSearchValues: pt.func,
  getReportFile: pt.func,
}

Search.defaultProps = {
  data: {},
  selectedNode: {},
  globalFilters: {},
  searchFieldsOptions: {},
  searchValues: {},
  loadingTable: false,
  fileStatus: null,

  requestGetSearchTable: noop,
  requestGetSearchNode: noop,
  requestGetSearchFieldsOptions: noop,
  resetGlobalFilterValues: noop,
  setSearchValues: noop,
  getReportFile: noop,
}

export default Search