import { CREATE_SCHEDULE } from './index'

export const requestCreateSchedule = (payload) => ({
  type: CREATE_SCHEDULE.REQUEST,
  payload,
})

export const successCreateSchedule = (payload) => ({
  type: CREATE_SCHEDULE.SUCCESS,
  payload,
})

export const errorCreateSchedule = (payload) => ({
  type: CREATE_SCHEDULE.ERROR,
  payload,
})