import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { FormattedMessage as Lang } from 'react-intl'
import useReduxTable from '@/hooks/useReduxTable'
import { REPORT_MANAGER_TABLE } from '@/store/actions/reportManager'
import { getTableParameters } from '@/store/selectors/reportManager'
import useHtmlTitle from '@/hooks/useHtmlTitle'
import DutyTable from '@/components/blocks/DutyTable'
import GlobalPopup from '@/components/blocks/GlobalPopup'
import GlobalPopupContent from '@/components/blocks/GlobalPopupContent'
import MenuIcon from '@/components/icons/menu'
import Loader from '@/components/blocks/Loader'
import { REQUEST_STATUSES } from '@/constants/requests'
import {
  fields,
  exportOptions,
} from '@/constants/tablesConfig/reportManager'
import ReportSideBar from './components/ReportSideBar'
import {
  Main,
  Content,
} from './styles'
import { GEOZONE, OBJECT_ELEMENT, ROOT } from '@/constants/objectTypes'
import TreeSidebar from '@/containers/pages/Installation/components/TreeSidebar'

const ReportManager = ({
  data,
  getTableData,
  getTypes,
  reportTypes,
  isTypesLoading,
  getObjectTypes,
  reportObjectTypes,
  isObjectTypesLoading,
  getCommonUsers,
  commonUsers,
  isCommonUsersLoading,
  createReport,
  setFormIsModifying,
  reportFormOpen,
  requestGetReport,
  selectedReport,
  loadingSelectedReport,
  selectedNode,
  setNode,
  loadingTable,
  requestChangeReport,
  requestDeleteReport,
  requestReportManagerFile,
  exportFileStatus,
  setTrueStatusGlobalFilter,
  globalLoading,
  intl,
}) => {
  useHtmlTitle(intl.messages['menu.reportManager'])
  const [isDataRequested, setIsDataRequested] = useState(false)
  const [reportViewed, setReportViewed] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const tableProps = useReduxTable(REPORT_MANAGER_TABLE, getTableParameters)

  const getPageData = useCallback(
      () => {
        getTableData()
      },
      [
        getTableData,
      ],
  )

  useEffect(() => {
    if (selectedNode && selectedNode.id && !isDataRequested) {
      getPageData()
      setIsDataRequested(true)
    }
  }, [selectedNode, getPageData, isDataRequested])

  const handleSetPopup = useCallback((value) => () => {
    setIsPopupOpen(value)
  }, [])

  const handleSetNode = useCallback((currentNode, event) => {
    if (event === 'click') {
      setNode(currentNode)
      getPageData()
    }
  },
  [setNode, getPageData])

  const geoZoneId = useMemo(() => (
    selectedNode.treeNodeType === GEOZONE || selectedNode.treeNodeType === ROOT ? selectedNode.id : selectedNode.path && selectedNode.path[1]
  ), [selectedNode])

  const objectId = useMemo(() => {
    return selectedNode.treeNodeType === OBJECT_ELEMENT ? selectedNode.id : null
  }, [selectedNode])

  const parentId = useMemo(() => {
    return selectedNode.parentId
  }, [selectedNode])

  const geoZoneName = useMemo(() => {
    return selectedNode.treeNodeType === GEOZONE || selectedNode.treeNodeType === ROOT ? selectedNode.name : null
  }, [selectedNode])

  const handleSetFormIsModifying = useCallback((value) => () => {
    if (geoZoneId) {
      setFormIsModifying(value)
      setReportViewed(false)
    } else {
      setIsPopupOpen(true)
    }
  }, [geoZoneId, setFormIsModifying])

  const handleRowSelector = useCallback((report) => {
    if (report && report.id) {
      requestGetReport({ id: report.id })
      setReportViewed(true)
      setFormIsModifying(false)
    }
  }, [requestGetReport, setFormIsModifying])

  const handleRequestReportManagerFile = useCallback(() => {
    requestReportManagerFile({ geoZoneId })
  }, [requestReportManagerFile, geoZoneId])

  return (
    <Main>
      {isPopupOpen && (
        <GlobalPopup
          content={(
            <GlobalPopupContent
              type="warning"
              onClose={handleSetPopup(false)}
              title={<Lang id="usersPage.popup.impossibleExecute" />}
              message={<Lang id="reportManager.header.createReport" />}
              config={{
                warning: {
                  icon: MenuIcon.AttentionIcon,
                  buttons: [
                    {
                      statusType: 'warning',
                      onClickSelector: handleSetPopup(false),
                      title: <Lang id="usersPage.popup.accept" />,
                    },
                  ],
                },
              }}
            />
            )}
        />
      )}
      <Content>
        {!reportFormOpen && (
            <TreeSidebar
                title={intl.messages['menu.reportManager']}
                onSelect={handleSetNode}
                hidden={false}
                selectedNode={selectedNode}
            />
        )}
        {(reportFormOpen || reportViewed) && (
          <ReportSideBar
            setFormIsModifying={setFormIsModifying}
            reportFormOpen={reportFormOpen}
            getTypes={getTypes}
            reportTypes={reportTypes}
            isTypesLoading={isTypesLoading}
            getObjectTypes={getObjectTypes}
            reportObjectTypes={reportObjectTypes}
            isObjectTypesLoading={isObjectTypesLoading}
            getCommonUsers={getCommonUsers}
            commonUsers={commonUsers}
            isCommonUsersLoading={isCommonUsersLoading}
            createReport={createReport}
            setReportViewed={setReportViewed}
            selectedReport={selectedReport}
            loadingSelectedReport={loadingSelectedReport}
            reportViewed={reportViewed}
            geoZoneId={geoZoneId}
            geoZoneName={geoZoneName}
            objectId={objectId}
            parentId={parentId}
            requestChangeReport={requestChangeReport}
            requestDeleteReport={requestDeleteReport}
            setTrueStatusGlobalFilter={setTrueStatusGlobalFilter}
          />
        )}
        {loadingTable
          ? <Loader center />
          : (
            <DutyTable
              {...tableProps}
              fields={fields}
              exportOptions={exportOptions}
              data={data}
              onUpdateData={getPageData}
              createReport={handleSetFormIsModifying}
              rowSelector={handleRowSelector}
              onFileSelect={handleRequestReportManagerFile}
              fileStatus={exportFileStatus}
              scrollAfterUpdating
              isDataLoading={globalLoading}
            />
          )}
      </Content>
    </Main>
  )
}

ReportManager.propTypes = {
  tree: pt.objectOf(pt.object),
  data: pt.arrayOf(pt.object),
  setSelectedElement: pt.func,
  setEventTypes: pt.func,
  setStatusTypes: pt.func,
  requestAlarmManagerTable: pt.func,
  requestAlarmManagerFile: pt.func,
  getTableData: pt.func,
  getTypes: pt.func,
  getObjectTypes: pt.func,
  getCommonUsers: pt.func,
  setFormIsModifying: pt.func,
  setNode: pt.func,
  requestGetReport: pt.func,
  requestChangeReport: pt.func,
  requestDeleteReport: pt.func,
  requestReportManagerFile: pt.func,
  createReport: pt.func,
  isTypesLoading: pt.bool,
  isObjectTypesLoading: pt.bool,
  isCommonUsersLoading: pt.bool,
  reportFormOpen: pt.bool,
  loadingTable: pt.bool,
  loadingSelectedReport: pt.bool,
  commonUsers: pt.arrayOf(pt.shape({
    blocked: pt.bool,
    email: pt.string,
    enabled: pt.bool,
    firstName: pt.string,
    lastName: pt.string,
    id: pt.oneOfType([pt.string, pt.number]),
    tenantId: pt.oneOfType([pt.string, pt.number]),
    title: pt.string,
    value: pt.oneOfType([pt.string, pt.number]),
  })),
  reportObjectTypes: pt.arrayOf(pt.shape({
    value: pt.string,
    code: pt.string,
    title: pt.string,
  })),
  reportTypes: pt.arrayOf(pt.shape({
    value: pt.string,
    code: pt.string,
    title: pt.string,
  })),
  selectedReport: pt.shape({
    externalRecipients: pt.arrayOf(pt.shape({
      id: pt.string,
      email: pt.string,
      name: pt.string,
    })),
    externalSendEnabled: pt.bool,
    geoZoneId: pt.shape({
      id: pt.oneOfType([pt.string, pt.number]),
      title: pt.string,
    }),
    id: pt.oneOfType([pt.string, pt.number]),
    lastSendDate: pt.string,
    nextSendDate: pt.string,
    objectTypes: pt.arrayOf(pt.shape({
      code: pt.string,
      title: pt.string,
    })),
    schedule: pt.shape({
      start: pt.oneOfType([pt.string, pt.number]),
      timeValue: pt.oneOfType([pt.string, pt.number]),
      timeZone: pt.oneOfType([pt.string, pt.number]),
      type: pt.oneOfType([pt.string, pt.number]),
    }),
    scheduleView: pt.string,
    timeZone: pt.oneOfType([pt.string, pt.number]),
    title: pt.string,
    type: pt.shape({
      code: pt.oneOfType([pt.string, pt.number]),
      title: pt.string,
    }),
    users: pt.arrayOf(pt.shape({
      blocked: pt.bool,
      email: pt.string,
      enabled: pt.bool,
      firstName: pt.string,
      lastName: pt.string,
      id: pt.oneOfType([pt.string, pt.number]),
      tenantId: pt.oneOfType([pt.string, pt.number]),
      title: pt.string,
      value: pt.oneOfType([pt.string, pt.number]),
    })),
    usersId: pt.arrayOf(pt.oneOfType([pt.string, pt.number])),
  }),
  zones: pt.objectOf(pt.object),
  exportFileStatus: pt.oneOf([
    REQUEST_STATUSES.NOT_REQUESTED,
    REQUEST_STATUSES.EVENT,
    REQUEST_STATUSES.ERROR,
    REQUEST_STATUSES.SUCCESS,
    REQUEST_STATUSES.PENDING,
  ]),
  selectedNode: {
    id: pt.oneOfType([pt.string, pt.number]),
    isHasInstallations: pt.bool,
    location: pt.arrayOf(pt.oneOfType([pt.string, pt.number])),
    name: pt.string,
    path: pt.string,
    type: pt.string,
    children: pt.arrayOf(pt.object),
  },
}
ReportManager.defaultProps = {
  tree: {},
  data: [],
  setSelectedElement: noop,
  setEventTypes: noop,
  setStatusTypes: noop,
  requestAlarmManagerTable: noop,
  requestAlarmManagerFile: noop,
  getTableData: pt.func,
  getTypes: noop,
  getObjectTypes: noop,
  getCommonUsers: noop,
  setFormIsModifying: noop,
  setNode: noop,
  requestGetReport: noop,
  requestChangeReport: noop,
  requestDeleteReport: noop,
  requestReportManagerFile: noop,
  createReport: noop,
  isTypesLoading: false,
  isObjectTypesLoading: false,
  isCommonUsersLoading: false,
  reportFormOpen: false,
  loadingSelectedReport: false,
  loadingTable: false,
  selectedReport: {},
  commonUsers: [],
  reportObjectTypes: [],
  reportTypes: [],
  zones: {},
  selectedNode: {},
  exportFileStatus: REQUEST_STATUSES.NOT_REQUESTED,
}

export default ReportManager
