import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import createNotifications from '@/helpers/notification'
import { SET_VERIFICATION_STATUS } from '@/store/actions/objectsGroups'
import { SET_VERIFICATION_STATUS_URL } from '@/constants/apiRoutes'
import { requestGetVerificationOptions } from '@/store/actions/objectsGroups/getVerificationOptions'
import {
  errorSetVerificationStatus,
  successSetVerificationStatus
} from '@/store/actions/objectsGroups/setVerificationStatus'

function* setVerificationStatus({ payload }) {
  try {
    const { groupId, intl } = payload
    const { data } = yield request({
      url: SET_VERIFICATION_STATUS_URL(groupId),
      method: 'post',
    })
    if (data) {
      yield put(successSetVerificationStatus())
      yield put(requestGetVerificationOptions({groupId, intl}))
    }
  } catch (error) {
    yield put(errorSetVerificationStatus(error))
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось верифицировать группу объектов. \nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* () {
  yield takeLatest(SET_VERIFICATION_STATUS.REQUEST, setVerificationStatus)
}
