import React, {useState} from 'react'

import { Title, CheckBoxContainer, RadioButtonStyled, Container, CloseIconContainer, IconContainer } from './styles'
import Icons from '@/components/icons/users'



const SortShutter = ({
  title,
  onClose,
}) => {

  const [typeSort, setTypeSort] = useState('ascendingDate')
  
  const handleSetCheckBox = (value) => {
    setTypeSort(value)
  }

 

  return (
    <Container>
      <Title main>{title}</Title>
      <CloseIconContainer>
        <Icons.CrossIcon onClick={onClose}/>
      </CloseIconContainer>
      <CheckBoxContainer>
        <RadioButtonStyled
          active={typeSort === 'ascendingDate'}
          onChange={() => handleSetCheckBox('ascendingDate')}
          label='Дата возникновения'
        />
        <IconContainer>
          <Icons.SortDescendingIcon/>
        </IconContainer>
      </CheckBoxContainer>
      <CheckBoxContainer >
        <RadioButtonStyled
          active={typeSort === 'descendingDate'}
          onChange={() => handleSetCheckBox('descendingDate')}
          label='Дата возникновения'
        />
        <IconContainer>
          <Icons.SortAscendingIcon/>
        </IconContainer>
      </CheckBoxContainer>
    </Container>
  )
}



export default SortShutter
