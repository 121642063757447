import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect
} from 'react'
import pt from 'prop-types'
import QRCode from 'qrcode.react'
import coreIcons from '@/components/icons/core'
import memoWithName from '@/hocs/memoWithName'
import { QRCODE_SIZE } from '@/constants/sizes'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import { FormattedMessage as Lang } from 'react-intl'
import CoreIcons from '@/components/icons/core'
import TextField from '@/components/fields/TextField'
import { Formik } from 'formik'
import PopupIcons from '@/components/icons/popup'
import {
  Container,
  ImageContainer,
  ImageTitle,
  StatusCircle,
  ControlsContainer,
  QRCodeContainer,
  PassportView,
  PassportViewVideo,
  IconContainer,
  FormWraper,
  IconWraper,
} from './styles'

const ImagesSlider = ({ images, setStreamUrl, id, streamUrl, getStreamUrl }) => {
  const ImageIcon = useMemo(() => coreIcons.ImageIcon, [])
  const QRCodeIcon = useMemo(() => coreIcons.QRCodeIcon, [])
  const PrintIcon = useMemo(() => coreIcons.PrintIcon, [])
  const VideoIcon = useMemo(() => coreIcons.VideoIcon, [])

  const [activeIndex, setActiveIndex] = useState(0)
  const ref = useRef()
  const [editMode, setEditMode] = useState(false)
  const [isError, setIsError] = useState(false)


  useEffect(() => {
    setEditMode(false)
    getStreamUrl(id)
    setIsError(false)
  }, [id, getStreamUrl, setIsError])

  const toggleEditMode = () => setEditMode((editMode) => !editMode)

  const switchToQRCode = useCallback(() => {
    const newIndex = images.findIndex((element) => element.type === 'qrCode')
    if (newIndex === -1) {
      return null
    }
    setActiveIndex(newIndex)
    return null
  }, [images])

  const switchToVideo = useCallback(() => {
    const newIndex = images.findIndex((element) => element.type === 'video')
    if (newIndex === -1) {
      return null
    }
    setActiveIndex(newIndex)
    return null
  }, [images])

  const switchToImage = useCallback(() => {
    const newIndex = images.findIndex((element) => element.type === 'image')
    if (newIndex === -1) {
      return null
    }
    setActiveIndex(newIndex)
    return null
  }, [images])

  const renderVideoTitle = useCallback(() => (
    <>
      <ImageTitle>
        {images[activeIndex].title}
        {' ( '}
        <StatusCircle active={images[activeIndex].online} />
        {'  realtime )'}
        <PortalTooltip
              title={(<Lang id="tooltip.edit" />)}
              renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                <IconContainer
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                  onClick={toggleEditMode}
                >
                  <CoreIcons.EditIcon />
                </IconContainer>
              )}
            />
      </ImageTitle>
    </>
  ), [images, activeIndex])

  const renderObjectQRCode = useCallback(() => (
    <>
      <ImageIcon onClick={switchToImage} />
      <PrintIcon />
      <VideoIcon onClick={switchToVideo} />
    </>
  ), [switchToImage, switchToVideo])

  const renderObjectVideo = useCallback(() => (
    <>
      <ImageIcon onClick={switchToImage} />
      <QRCodeIcon onClick={switchToQRCode} />
      {renderVideoTitle()}
    </>
  ), [renderVideoTitle, switchToImage, switchToQRCode])

  const renderObjectImage = useCallback(() => (
    <>
      <QRCodeIcon onClick={switchToQRCode} />
      <VideoIcon onClick={switchToVideo} />
    </>
  ), [switchToVideo, switchToQRCode])
  const renderImage = useCallback(() => {
    switch (images[activeIndex].type) {
      case 'video': {
        return (
        <PassportViewVideo>
              <iframe src={streamUrl} title={'stream'} allowfullscreen={'true'} width={'100%'} height={'100%'}></iframe>
        </PassportViewVideo>
    )
      }
      case 'qrCode': {
        return (
        <QRCodeContainer>
          <QRCode value={images[activeIndex].url} size={QRCODE_SIZE} />
        </QRCodeContainer>
        )
      }
      case 'image': {
        return <PassportView src={images[activeIndex].url} url={images[activeIndex].url} base64Url={images[activeIndex].url}/>
      }
      default: return <></>
    }
      }, [activeIndex, images, streamUrl])

  const renderControlBar = useCallback(() => (
    <ControlsContainer>
      {images[activeIndex].type === 'image' && renderObjectImage()}
      {images[activeIndex].type === 'qrCode' && renderObjectQRCode()}
      {images[activeIndex].type === 'video' && renderObjectVideo()}
    </ControlsContainer>
  ), [activeIndex, images, renderObjectImage, renderObjectQRCode, renderObjectVideo])

  const onSetValues = (values) => {
    if (!values.streamUrl.length || values.streamUrl.startsWith('https://')) {
      setStreamUrl({...values, id})
      setEditMode(false)
      setIsError(false)
    } else {
      setIsError(true)
    }
  }


  return (
    <Container>
      <ImageContainer>
        {renderImage()}
      </ImageContainer>
      {images.length > 1 && (
        renderControlBar()
      )}
      {editMode &&
         <Formik
          ref={ref}
          enableReinitialize
          onSubmit={onSetValues}
          initialValues={{streamUrl: streamUrl || ''}}
          render={({
            handleSubmit,
          }) => {
            return <FormWraper>
                <TextField
                    name={'streamUrl'}
                    isError={isError}
                    fieldProps={{
                      autoComplete: 'off',
                  }}
                />
                <IconWraper>
                <PopupIcons.DoneFilterIcon color="#0EB755" onClick={handleSubmit}/>
                </IconWraper>
            </FormWraper>
          }}
       />
      }
    </Container>
  )
}

ImagesSlider.propTypes = {
  images: pt.arrayOf(pt.string).isRequired,
}
ImagesSlider.defaultProps = {}

export default memoWithName(ImagesSlider)
