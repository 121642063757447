import widgets from '../widgets'

const {
  FIRE_STATE,
  ALARM_STATE,
  STATUS
} = widgets

export default [
  STATUS({}),
  FIRE_STATE({}),
  ALARM_STATE({}),
]
