export const NAME = 'name'
export const TIMEZONE = 'timezone'
export const COMMENT = 'comment'
export const TASK_NAME = 'name'
export const DEVICE_TYPE = 'deviceType'
export const OBJECTS_GROUP = 'objectsGroup'
export const PHASE = 'phase'
export const REPEAT = 'repeat'
export const START_DATE = 'startDate'
export const END_DATE = 'endDate'
export const HOURS = 'hours'
export const MINUTES = 'minutes'
export const PERCENT = 'percent'
export const ACTIONS = 'actions'
export const JUSTIFICATION_COMMENT = 'justificationComment'

export default {
  NAME,
  TIMEZONE,
  COMMENT,
  TASK_NAME,
  DEVICE_TYPE,
  OBJECTS_GROUP,
  PHASE,
  REPEAT,
  START_DATE,
  END_DATE,
  ACTIONS,
  JUSTIFICATION_COMMENT
}