import { connect } from 'react-redux'
import {
  getExportFileStatus,
  getTableDataSummary,
  getSelector,
  getEventTypes,
  getEventStatus,
  getSelectedElement,
  getEventData,
  getLoading,
} from '@/store/selectors/faultAnalytics'

import {
  getFilterOptions
} from '@/store/selectors/analyticsAlarms'

import {
  setSelectedElement,
} from '@/store/actions/faultAnalytics'
import {
  getGlobalLoading,
  getSelectedNode
} from '@/store/selectors/appSettings'
import {
  requestSummaryAlarmAnalyticsTable,
} from '@/store/actions/faultAnalytics/getTable'

import {
  requestSummaryAlarmAnalyticsFile,
} from '@/store/actions/faultAnalytics/getFile'

import {
  getEventFilters
} from '@/store/selectors/appSettings'

import {
  setFilterValues,
  resetFilterValues
} from '@/store/actions/analyticsAlarms'

import FaultAnalytics from './component'
import { getRequestState } from '@/store/selectors/widgetDashboard'
import { setSelectedNode } from '@/store/actions/appSettings'
import { getTreeForWidgets } from '@/store/selectors/widgetDashboard'
import { requestGetSearchFieldsOptions } from '@/store/actions/dictionaries/searchFieldsOptions'
import {requestGetAllConnectedIntegrations} from "@/store/actions/dictionaries/connectedIntegrations"
import {
  getSearchFieldsOptionsData,
  getSearchFieldsOptionsLoading
} from '@/store/selectors/dictionaries/searchFieldsOptions'
import { getConnectedIntegrationsOptions } from '@/store/selectors/dictionaries/connectedIntegrations'
import { setSideBarFilter } from '@/store/actions/analyticsAlarms'

const mapStateToProps = (state) => ({
  data: getTableDataSummary(state),
  exportFileStatus: getExportFileStatus(state),
  selector: getSelector(state),
  eventTypes: getEventTypes(state),
  eventStatus: getEventStatus(state),
  selectedNode: getSelectedNode(state),
  selectedElement: getSelectedElement(state),
  eventData: getEventData(state),
  loading: getLoading(state),
  requestState: getRequestState(state),
  globalLoading: getGlobalLoading(state),
  eventFilterValues: getEventFilters(state),
  filterOptions: getFilterOptions(state),
  tree: getTreeForWidgets(state),
  searchFieldsOptions: getSearchFieldsOptionsData(state),
  connectedIntegrations: getConnectedIntegrationsOptions(state),
  searchFieldsOptionsLoading: getSearchFieldsOptionsLoading(state),
})
const mapDispatchToProps = (dispatch) => ({
  setSelectedElement: (payload) => dispatch(setSelectedElement(payload)),
  setFilterValues: (payload) => dispatch(setFilterValues(payload)),
  resetFilterValues: (payload) => dispatch(resetFilterValues(payload)),
  requestSummaryAlarmAnalyticsTable:
    (payload) => dispatch(requestSummaryAlarmAnalyticsTable(payload)),
  requestSummaryAlarmAnalyticsFile:
    (payload) => dispatch(requestSummaryAlarmAnalyticsFile(payload)),
  setNode: (payload) => dispatch(setSelectedNode(payload)),
  requestGetSearchFieldsOptions: (payload) => dispatch(requestGetSearchFieldsOptions(payload)),
  requestGetAllConnectedIntegrations: (payload) => dispatch(requestGetAllConnectedIntegrations(payload)),
  setSideBarFilter: (payload) => dispatch(setSideBarFilter(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FaultAnalytics)
