import { handleActions } from 'redux-actions'

import {
    GET_SEARCH_FIELDS_OPTIONS,
} from '@/store/actions/dictionaries'

const initialState = {
    data: {},
    isLoading: false
}

export const reducer = handleActions(
    {
        [GET_SEARCH_FIELDS_OPTIONS.REQUEST]: (state) => ({
            ...state,
            isLoading: true
        }),
        [GET_SEARCH_FIELDS_OPTIONS.SUCCESS]: (state, { payload }) => ({
            ...state,
            data: payload,
            isLoading: false
        }),
        [GET_SEARCH_FIELDS_OPTIONS.ERROR]: (state) => ({
            ...state,
            isLoading: false
        }),
    },
    initialState,
)

export default reducer