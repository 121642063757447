import HandicappedIcon from './HandicappedIcon'
import SupportIcon from './SupportIcon'
import PassportizationIcon from './PassportizationIcon'
import InstallationIcon from './InstallationIcon'
import EquipmentIcon from './EquipmentIcon'
import ObjectsIcon from './ObjectsIcon'
import ReportManagerIcon from './ReportManagerIcon'
import ScheduleManagerIcon from './ScheduleManagerIcon'
import ObjectStatisticIcon from './ObjectStatisticIcon'
import AlarmsIcon from './AlarmsIcon'
import LifeIcon from './LifeIcon'
import AnalyticsExpensesIcon from './AnalyticsExpensesIcon'
import HeatSupplyIcon from './HeatSupplyIcon'
import WaterSupplyIcon from './WaterSupplyIcon'
import CarElectricStationsIcon from './CarElectricStationsIcon'
import IlluminationIcon from './IlluminationIcon'
import SmartRecycleBinIcon from './SmartRecycleBinIcon'
import RoadIcon from './RoadIcon'
import TrafficLightIcon from './TrafficLightIcon'
import InfoFrameIcon from './InfoFrameIcon'
import WidgetsDashboard from './WidgetsDashboard'
import TyumenIcon from './TyumenIcon'
import ServiceDeskIcon from './ServiceDeskIcon'
import MapServiceIcon from './MapServiceIcon'
import PprIcon from './PprIcon'
import ObjectsGroupsIcon from './ObjectsGroupsIcon'

export default {
  HandicappedIcon,
  SupportIcon,
  PassportizationIcon,
  InstallationIcon,
  EquipmentIcon,
  ObjectsIcon,
  ReportManagerIcon,
  ScheduleManagerIcon,
  ObjectStatisticIcon,
  AlarmsIcon,
  LifeIcon,
  AnalyticsExpensesIcon,
  HeatSupplyIcon,
  WaterSupplyIcon,
  CarElectricStationsIcon,
  IlluminationIcon,
  SmartRecycleBinIcon,
  RoadIcon,
  TrafficLightIcon,
  InfoFrameIcon,
  WidgetsDashboard,
  TyumenIcon,
  ServiceDeskIcon,
  MapServiceIcon,
  PprIcon,
  ObjectsGroupsIcon
}
