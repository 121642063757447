import styled from 'styled-components'
import { Form } from 'formik'
import { RadioButtonLabel } from '@/components/controls/RadioButton/styles'
import CheckBox from '@/components/controls/CheckBox'

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.colors.default};
  font-size: 15px;
  line-height: 18px;
  margin-bottom: 25px;
  text-align: start;
  ${({ mt }) => mt && 'margin-top: 10px'}
`

export const StyledCheckBox = styled(CheckBox)`
  margin-right: 10px;
`

export const FormContainer = styled(Form)`
  position: relative;
  width: 100%;
  ${RadioButtonLabel} {
    text-align: left;
    line-height: 2rem;
    padding: 5px 8px;
  }
`

export const FieldContainer = styled.div`
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`

export const DecorativeElement = styled.div`
  width: 12px;
  height: 1px;
  background: ${({ theme }) => theme.colors.colors.default};
  margin: 0 9px;
`

export const DateInput = styled.div`
  display: flex;
  padding-left: 5px;
  align-items: center;
  justify-content: space-between;
  height: 28px;
  font-size: 1.4rem;
  line-height: 3rem;
  background: ${({ theme }) => theme.colors.backgrounds.select};
  border-radius: 4px;
  border: ${({ theme }) => `1px solid ${theme.colors.borders.default}`};
  color: ${({ theme }) => theme.colors.colors.select};
  ${({ theme, disabled }) => disabled && `
    cursor: not-allowed;
    background: ${theme.colors.backgrounds.disabled};
  `}
`
export const IconContainer = styled.div`
  margin-left: 15px;
  margin-right: 15px;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
`

export const IconDiagramContainer = styled.div`
  margin-right: 10px;
`

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  justify-content: space-between;
  padding: 0 10px;
`

export const SelectContainer = styled.div`
  margin: 0 10px;
  width: 100%;
`

export const SavingsItemContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const SecondaryTitle = styled.div`
  display: flex;
  justify-content: flex-start;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 3rem;
  min-width: 100px;
  color: ${({ theme }) => theme.colors.colors.default};
  ${({ value, theme }) => value && `
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    justify-content: flex-end;
    line-height: 20px;
    color: ${theme.colors.title};
    margin-right: 0;
  `}
`

export const CheckBoxContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between; 
  align-items: center;
  // ${SecondaryTitle} {
  //   width: 100%;
  // }
`

export const AlarmCell = styled.td`
  display: flex;
  justify-content: center;
  align-items: center;
`
export const ObjectIsCheckedCell = styled.td`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
`

export const CheckedMarker = styled.div`
  width: 8px;
  height: 8px;
  border-radius: 50%;
  ${({ theme, isChecked }) => isChecked
   && `background: ${theme.colors.scheme.backgrounds.default};
   margin-right: 10px;
   `}
`

export const AlarmEvent = styled.div`
  width: 20px;
  height: 20px;
  max-width: 20px;
  max-height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ sm }) => sm && `
    width: 18px;
    height: 18px;
    min-width: 18px;
    min-height: 18px;
    margin-left: 15px;
    svg,
    .svgStroke,
    .svgFill {
      height: 8px;
    }
  `} 
`

export const AlarmRowContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const AlarmTitleContainer = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${SecondaryTitle} {
    margin-left: 15px;  
  }
`

export const ButtonIconContainer = styled.div`
  margin-right: 10px;
`
