import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import pt from 'prop-types'
import noop from 'lodash/noop'
import HeaderIcons from '@/components/icons/header'
import Button from '@/components/blocks/Button'
import {
  WidgetContainer,
  WidgetDescription,
} from './styles'

const WidgetSettingsPlaceholder = ({ onOpenSettings }) => (
  <WidgetContainer>
    <WidgetDescription>
      <Lang id="widgets.doWidgetSettingsMessage" />
    </WidgetDescription>
    <Button onClick={onOpenSettings}>
      <HeaderIcons.SettingsIcon color="white" />
      <Lang id="widgets.doWidgetSettings" />
    </Button>
  </WidgetContainer>
)

WidgetSettingsPlaceholder.propTypes = {
  onOpenSettings: pt.func,
}
WidgetSettingsPlaceholder.defaultProps = {
  onOpenSettings: noop,
}

export default WidgetSettingsPlaceholder
