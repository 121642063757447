export const HEAT_SUPPLY = 'HEAT_SUPPLY'
export const WATER_SUPPLY = 'WATER_SUPPLY'
export const CHARGING_STATIONS = 'CHARGING_STATIONS'
export const ARCHITECTURE_ILLUMINATION = 'ARCHITECTURE_ILLUMINATION'
export const GARBAGE_COLLECTION = 'GARBAGE_COLLECTION'
export const ROAD_INFRASTRUCTURE = 'ROAD_INFRASTRUCTURE'
export const TRAFFIC_LIGHT = 'TRAFFIC_LIGHT'
export const INFO_FRAME = 'INFO_FRAME'
export const HANDICAPPED_PLACES = 'HANDICAPPED_PLACES'
export const SUPPORT = 'SUPPORT'

export const APP_BY_WIDGET_ID = {
  HEAT_SUPPLY: 'HEAT_SUPPLY',
  WATER_SUPPLY: 'WATER_SUPPLY',
  CHARGING_STATIONS: 'CHARGING_STATIONS',
  ARCHITECTURE_ILLUMINATION: 'ARCHITECTURE_ILLUMINATION',
  GARBAGE_COLLECTION: 'GARBAGE_COLLECTION',
  ROAD_INFRASTRUCTURE: 'ROAD_INFRASTRUCTURE',
  TRAFFIC_LIGHT: 'TRAFFIC_LIGHT',
  INFO_FRAME: 'INFO_FRAME',
  HANDICAPPED_PLACES: 'HANDICAPPED_PLACES',
  SUPPORT: 'SUPPORT',
  WIDGETS_DASHBOARD: 'WIDGETS_DASHBOARD',
  WIDGETS_DASHBOARD_GOV: 'WIDGETS_DASHBOARD_GOV',
  SEARCH: 'SEARCH',
  PPR: 'PPR',
  OBJECTS_GROUPS: 'OBJECTS_GROUPS',
}
