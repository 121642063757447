import { GET_OBJECTS_GROUPS_TREE } from './index'

export const requestGetObjectsGroupsTree = (payload) => ({
  type: GET_OBJECTS_GROUPS_TREE.REQUEST,
  payload,
})

export const successGetObjectsGroupsTree = (payload) => ({
  type: GET_OBJECTS_GROUPS_TREE.SUCCESS,
  payload,
})

export const errorGetObjectsGroupsTree = (payload) => ({
  type: GET_OBJECTS_GROUPS_TREE.ERROR,
  payload,
})