import { GET_OBJECT_CONNECTED_IDENTIFIERS } from './index'

export const requestGetObjectConnectedIdentifiers = (payload) => ({
    type: GET_OBJECT_CONNECTED_IDENTIFIERS.REQUEST,
    payload,
})

export const successGetObjectConnectedIdentifiers = (payload) => ({
    type: GET_OBJECT_CONNECTED_IDENTIFIERS.SUCCESS,
    payload,
})

export const errorGetObjectConnectedIdentifiers = (payload) => ({
    type: GET_OBJECT_CONNECTED_IDENTIFIERS.ERROR,
    payload,
})