import {
  LIGHT,
  UNICERSE_METER,
  CHECK_POINT,
} from '@/constants/objectTypes'
import {
  BRANCH_CIRCUIT,
  CONTACTOR,
  FUSE,
  CURRENT_SENSOR,
  DMX_DEVICE,
  CONTROLLER,
  LAMP,
  PHASE_CIRCUIT_BREAKER,
  PHASE,
  FIRE_SENSOR,
  ELECTRIC_METER
} from '@/constants/instalationPassport/types';
import controller from './controller'
import light from './light'
import unicerseMeter from './unicerseMeter'
import contactor from './contactor'
import branchCircuit from './branchCircuit'
import checkPoint from './checkPoint'
import currentSensor from './currentSensor'
import dmxDevice from './dmxdevice'
import phaze from './phaze'
import electricMeter from './electricMeter'
import phaseCircuit from './phaseCircuit'

export default {
  [CONTROLLER]: controller,
  [PHASE_CIRCUIT_BREAKER]: phaseCircuit,
  [LIGHT]: light,
  [LAMP]: light,
  [UNICERSE_METER]: unicerseMeter,
  [CONTACTOR]: contactor,
  [PHASE]: phaze,
  [BRANCH_CIRCUIT]: branchCircuit,
  [CHECK_POINT]: checkPoint,
  [FUSE]: branchCircuit,
  [CURRENT_SENSOR]: currentSensor,
  [DMX_DEVICE]: dmxDevice,
  [FIRE_SENSOR]: unicerseMeter,
  [ELECTRIC_METER]: electricMeter,
}
