export default {
  title: 'Настройки системы',
  appearanceTitle: 'Внешний вид',
  themeTitle: 'Тема оформления',
  darkThemeName: 'Ночной мегаполис',
  lightThemeName: 'Вершина Эвереста',
  colorTitle: 'Цветовая схема',
  clearSkyTitle: 'Чистое небо',
  springMeadowTitle: 'Весенний луг',
  summerEvening: 'Летний вечер',
  fontSizeTitle: 'Размер шрифта',
  fontSizeDefault: 'Стандартный',
  fontSizeLarge: 'Крупный',
  notificationsTitle: 'Уведомления',
  securityTitle: 'Безопасность',
  syncTitle: 'Синхронизация',
  widgetShop: 'Магазин умных решений',
  widgetShopApp: 'Приложения',
  widgetShopWidget: 'Виджеты',
  refreshRate: 'Частота обновления',
  rate10: '10 сек.',
  rate30: '30 сек.',
  rate60: '60 сек.',
}
