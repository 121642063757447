import { SET_VERIFICATION_STATUS } from './index'

export const requestSetVerificationStatus = (payload) => ({
  type: SET_VERIFICATION_STATUS.REQUEST,
  payload,
})

export const successSetVerificationStatus = (payload) => ({
  type: SET_VERIFICATION_STATUS.SUCCESS,
  payload,
})

export const errorSetVerificationStatus = (payload) => ({
  type: SET_VERIFICATION_STATUS.ERROR,
  payload,
})