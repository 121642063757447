import React, {
  useCallback,
  useRef,
  useMemo,
} from 'react'
import { Formik } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'
import SelectFieldMobile from '@/components/fields/SelectFieldMobile'
import useUrlParseAndPush from '@/hooks/useUrlParseAndPush'
import {
  START_DATE,
  END_DATE,
  DROP_DOWN_OGJECT,
  DROP_DOWN_STATUS,
  OVERDUE,
} from '@/constants/forms/serviceDeskMyCity'
import PopupIcons from '@/components/icons/popup'
// import SwitchButton from '@/components/controls/SwitchButton'
import { dropDownStatus } from '@/forms/ServiceDeskMyCityForm/config'
import Icons from '@/components/icons/users'
import {
  FormContainer,
  Text,
  CustomDatePicker,
  StyledButton,
  Title,
  TitleAndSelectWrapper,
  IconContainer,
  DateWrapper,
  ButtonsWrapper,
} from './styles'

const SDFilterForm = ({ optionsStatus, typeOfObjects, onClose }) => {
  const formicForm = useRef(null)
  const [urlValues, setUrlValues] = useUrlParseAndPush()

  const handleResetFilter = useCallback(() => {
    setUrlValues({ widgetId: urlValues.widgetId })
    formicForm.current.resetForm({})
  }, [setUrlValues, urlValues.widgetId])

  const parsedInitialValues = useMemo(() => {
    let newUrlParse = { ...urlValues }
    if (urlValues[OVERDUE]) {
      newUrlParse = {
        ...urlValues,
        [OVERDUE]: urlValues[OVERDUE] === 'true' || false,
      }
    }
    if (urlValues[START_DATE]) {
      newUrlParse[START_DATE] = new Date(urlValues[START_DATE])
    }
    if (urlValues[END_DATE]) {
      newUrlParse[END_DATE] = new Date(urlValues[END_DATE])
    }
    return newUrlParse
  }, [urlValues])

  const handleSetValues = useCallback((formValues) => {
    const newFormValues = { ...formValues }
    let overdueValue = false
    if (!formValues[OVERDUE]) {
      delete newFormValues[OVERDUE]
    }
    if (urlValues[START_DATE] && formValues[START_DATE]
      && new Date(urlValues[START_DATE]).getTime() !== formValues[START_DATE].getTime()
    ) {
      delete newFormValues[START_DATE]
    }
    if (urlValues[END_DATE] && formValues[END_DATE]
      && new Date(urlValues[END_DATE]).getTime() !== formValues[END_DATE].getTime()
    ) {
      delete newFormValues[END_DATE]
    }
    if (urlValues[OVERDUE] === 'true') {
      overdueValue = true
    } else {
      overdueValue = false
    }
    if (formValues[DROP_DOWN_OGJECT] !== urlValues[DROP_DOWN_OGJECT]
      || formValues[DROP_DOWN_STATUS] !== urlValues[DROP_DOWN_STATUS]
      || Boolean(formValues[OVERDUE]) !== overdueValue
    ) {
      // setUrlValues(newFormValues)
    }
  }, [urlValues])

  // const handleSetDatePicker = useCallback((formValues) => () => {
  //   const newValues = { ...urlValues }
  //   if (formValues[START_DATE]) {
  //     newValues[START_DATE] = formValues[START_DATE]
  //   }
  //   if (formValues[END_DATE]) {
  //     newValues[END_DATE] = formValues[END_DATE]
  //   }
  //   setUrlValues(newValues)
  // }, [setUrlValues, urlValues])

  // const datePickerButtonCondition = useCallback((formValues) => {
  //   if (urlValues[START_DATE] && urlValues[START_DATE]) {
  //     return (
  //       formValues[START_DATE]
  //       && formValues[END_DATE]
  //       && (
  //         new Date(urlValues[START_DATE]).getTime() !== formValues[START_DATE].getTime()
  //         || new Date(urlValues[END_DATE]).getTime() !== formValues[END_DATE].getTime()
  //       )
  //     )
  //   }
  //   return formValues[START_DATE] && formValues[END_DATE]
  // }, [urlValues])

  const onSubmit = useCallback((formValues) => {
    setUrlValues({
      ...formValues
    })
  }, [setUrlValues])

  return (
    <Formik
      ref={formicForm}
      initialValues={parsedInitialValues}
      enableReinitialize
      onSubmit={onSubmit}
      validate={handleSetValues}
      render={({
        handleSubmit,
        values,
      }) => (
        <FormContainer>
          <TitleAndSelectWrapper isMobile>
            <Title>
              Фильтр обращений:
            </Title>
            <IconContainer>
              <Icons.CrossIcon onClick={onClose}/>
            </IconContainer>
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              Переиод:
            </Title>
            <DateWrapper>
            <CustomDatePicker name={START_DATE} />
          <Text datePicker>
            -
          </Text>
          <CustomDatePicker name={END_DATE} />
            </DateWrapper>
          
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              Тип объекта:
            </Title>
            <SelectFieldMobile
              multiselect
              name={DROP_DOWN_OGJECT}
              placeholder="Тип объекта"
              withSearch
              options={typeOfObjects}
            />
            </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              Статус:
            </Title>
            <SelectFieldMobile
              multiselect
              name={DROP_DOWN_STATUS}
              placeholder="Статус"
              withSearch
              options={optionsStatus}
            />
            </TitleAndSelectWrapper>
            {/* <Switch>
            <Text><Lang id="serviceDeskMyCity.overdue" /></Text>
              <SwitchButton/>
            </Switch> */}
          <ButtonsWrapper>
              <StyledButton usage="reset" onClick={handleResetFilter}>
                <PopupIcons.ResetFilterIcon color="#3D3D3D" />
                <Lang id="globalFilter.buttons.reset" />
              </StyledButton>
              <StyledButton usage="accept"onClick={handleSubmit} >
                <PopupIcons.DoneFilterIcon color="#FFFFFF" />
                <Lang id="globalFilter.buttons.accept" />
              </StyledButton>
            </ButtonsWrapper>
        </FormContainer>
      )}
    />
  )
}

SDFilterForm.propTypes = {
}

SDFilterForm.defaultProps = {
  typeOfObjects: [],
  optionsStatus: dropDownStatus,
}

export default React.memo(SDFilterForm)
