import {
  takeLatest, select, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { TENANT_ENDPOINT } from '@/constants/apiRoutes'
import {
  ADMIN,
} from '@/constants/viewTree'
import {
  getSelectedTenant,
} from '@/store/selectors/users'
import {
  ADMIN_NAME,
  ADMIN_EMAIL,
  ADMIN_SURNAME,
  ADMIN_PATRONYMIC,
} from '@/constants/forms/admin'
import {
  setSelectedTenant,
  CREATE_TENANT_ADMIN,
  setFormIsModifying,
} from '@/store/actions/users'
import {
  requestGetTenant,
} from '@/store/actions/users/tenant/getTenant'
import { requestGetAdminTenant } from '@/store/actions/users/administrator/getAdminTenant'
import { requestGetAllAdminTenants } from '@/store/actions/users/administrator/getAllAdminTenants'
import { errorCreateAdminTenant } from '@/store/actions/users/administrator/createAdminTenant'
import createNotifications from '@/helpers/notification'
import { requestGetAllTenants } from '@/store/actions/users/tenant/getAllTenants'

const ERRORS = {
  'validation_failed.email:not_unique': 'Пользователь с таким адресом электронной почты уже существует',
  'validation_failed.email:invalid': 'Неверный адрес электронной почты',
}

function* createAdminTenantSaga({ payload }) {
  const { forms, tenantId } = payload
  const toast = createNotifications()
  try {
    const selectedTenant = yield select(getSelectedTenant)
    const selectedTenantChildren = selectedTenant.children || {}
    const newSelectedTenantChildren = selectedTenantChildren
      .filter((element) => element.id !== ADMIN)
    const newSelectedTenant = {
      ...selectedTenant,
      children: [
        ...newSelectedTenantChildren,
      ],
    }
    const dataJson = {
      name: forms.state.values[ADMIN_NAME],
      lastName: forms.state.values[ADMIN_SURNAME],
      middleName: forms.state.values[ADMIN_PATRONYMIC] || '',
      [ADMIN_EMAIL]: forms.state.values[ADMIN_EMAIL],
      role: 'ROLE_TENANT_ADMIN',
    }
    const response = yield request({
      url: `${TENANT_ENDPOINT}/${tenantId}/users`,
      method: 'post',
      body: dataJson,
    })
    toast({
      title: 'Успешная операция!',
      description: `Администратор “${response.data.firstName}” успешно добавлен!`,
    })
    yield put(setFormIsModifying(false))
    forms.setSubmitting(false)
    yield put(requestGetAllTenants())
    yield put(requestGetAdminTenant({ tenantId, id: response.data.id }))
    yield put(setSelectedTenant(newSelectedTenant))
    yield put(requestGetTenant({ id: tenantId, onlyUpdateSelectedTenant: true }))
    yield put(requestGetAllAdminTenants({ id: tenantId }))
  } catch (error) {
    yield put(errorCreateAdminTenant())
    toast({
      title: 'Ошибка операции!',
      description: `Ошибка при создании администратора! Повторите попытку позже. Код ошибки: ${ERRORS[error.response.data.errorCode]}`,
      type: 'error',
    })
    forms.setErrors({
      [error.response.data.subject]: ERRORS[error.response.data.errorCode],
    })
  }
}

export default function* root() {
  yield takeLatest(CREATE_TENANT_ADMIN.REQUEST, createAdminTenantSaga)
}
