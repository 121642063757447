import { all } from 'redux-saga/effects'
import widgetSettingsRoot from './widgetSettings'
import socketEventsRoot from './events'
import getMapEquipmentRoot from './getMapEquipment'
import refreshMapElementRoot from './refreshMapElement'
import getObjectImageRoot from './getObjectImage'
import getMapStatisticRoot from './getMapStatistic'
import getStreamUrl from './getStreamUrl'
import setStreamUrl from './setStreamUrl'

export default function* root() {
  yield all([
    widgetSettingsRoot(),
    getMapEquipmentRoot(),
    refreshMapElementRoot(),
    socketEventsRoot(),
    getObjectImageRoot(),
    getMapStatisticRoot(),
    getStreamUrl(),
    setStreamUrl(),
  ])
}
