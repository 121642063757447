import { GET_PPR_INSTALLATION_OBJECT } from './index'

export const requestPprInstallationObject = (payload) => ({
  type: GET_PPR_INSTALLATION_OBJECT.REQUEST,
  payload,
})

export const successPprInstallationObject = (payload) => ({
  type: GET_PPR_INSTALLATION_OBJECT.SUCCESS,
  payload,
})

export const errorPprInstallationObject = (payload) => ({
  type: GET_PPR_INSTALLATION_OBJECT.ERROR,
  payload,
})
