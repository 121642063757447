import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const AlertIcon = ({ color, onClick }) => {

  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M18.3748 12.0012C18.8748 12.0012 19.3748 11.9212 19.8748 11.7912V17.0012L21.8748 19.0012V20.0012H3.87476V19.0012L5.87476 17.0012V11.0012C5.87476 7.90122 7.90476 5.17122 10.8748 4.29122V4.00122C10.8748 2.90122 11.7748 2.00122 12.8748 2.00122C13.4848 2.00122 14.0248 2.28122 14.3748 2.71122C13.4548 3.70122 12.8748 5.03122 12.8748 6.50122C12.8748 9.53122 15.3448 12.0012 18.3748 12.0012ZM14.8748 21.0012C14.8748 22.1112 13.9848 23.0012 12.8748 23.0012C11.7748 23.0012 10.8748 22.1112 10.8748 21.0012H14.8748Z" fill="#30333A"/>
      <path d="M21.8748 6.50122C21.8748 8.43122 20.3048 10.0012 18.3748 10.0012C16.4448 10.0012 14.8748 8.43122 14.8748 6.50122C14.8748 4.57122 16.4448 3.00122 18.3748 3.00122C20.3048 3.00122 21.8748 4.57122 21.8748 6.50122Z" fill="#EE2747"/>
    </svg>
  )
}

AlertIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

AlertIcon.defaultProps = {
  color: '#1E2127',
  onClick: noop,
}

export default memoWithName(AlertIcon)