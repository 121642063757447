import { all } from 'redux-saga/effects'

import getTree from './getTree'
import getCreateObjectsGroupTree from './getCreateObjectsGroupTree'
import createObjectsGroup from './createObjectsGroup'
import editObjectsGroup from './editObjectsGroup'
import deleteObjectsGroup from './deleteObjectsGroup'
import getObjectsGroup from './getObjectsGroup'
import getMapObjects from './getMapObjects'
import getScheduleOptions from './getScheduleOptions'
import getCreateObjectsGroupMapObjects from './getCreateObjectsGroupMapObjects'
import getVerificationOptions from './getVerificationOptions'
import setVerificationStatus from './setVerificationStatus'
import verificationAll from './verificationAll'
import getControlData from './getControlData'
import setControlData from './setControlData'
import setPassportizationData from './setPassportizationData'
import getPassportizationOptions from './getPassportizationOptions'
import setSchedule from './setSchedule'
import getScheduleData from './getScheduleData'
import resetSchedule from './resetSchedule'
import getPassportizationTable from './getPassportizationTable'
import getPassportizationTableFile from './getPassportizationTableFile'

export default function* root(){
  yield all([
    getTree(),
    getCreateObjectsGroupTree(),
    createObjectsGroup(),
    editObjectsGroup(),
    deleteObjectsGroup(),
    getObjectsGroup(),
    getMapObjects(),
    getScheduleOptions(),
    getCreateObjectsGroupMapObjects(),
    getVerificationOptions(),
    setVerificationStatus(),
    verificationAll(),
    getControlData(),
    setControlData(),
    setPassportizationData(),
    getPassportizationOptions(),
    getScheduleData(),
    setSchedule(),
    resetSchedule(),
    getPassportizationTable(),
    getPassportizationTableFile(),
  ])
}