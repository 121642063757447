import React from 'react'
// import { renderToString } from 'react-dom/server'

const renderJSXToString = (Element, data, theme, intl, isMonitoring, isPassportization, pinColor ) => 
  <Element features={data} {...data} theme={theme} intl={intl} isMonitoring={isMonitoring} isPassportization={isPassportization} pinColor={pinColor || '#3D3D3D'}/>

  // .replace(
  //   /&[gl]t;|&amp;*/g,
  //   (match) => match
  //     .replace(/&gt;/g, '>')
  //     .replace(/&lt;/g, '<')
  //     .replace(/&amp;/g, '&'),
  // )

export default renderJSXToString
