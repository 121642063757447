import request from '@/helpers/axios';
import getQueryParams from '@/helpers/getQueryParams';
import { SCHEDULER_DASHBOARD_URL } from '@/constants/apiRoutes';

const fetchData = async (url, errorMessage, setLoading) => {
  if(setLoading) setLoading(true)

  try {
    const response = await request({
      url,
      method: 'get',
    })
    return response.data
  } catch (error) {
    console.error(errorMessage, error)
    return []
  } finally {
    if(setLoading) setLoading(false)
  }
}

export const getSettings = (page, size, setLoading) => {
  const url = SCHEDULER_DASHBOARD_URL + `?${getQueryParams({pageNumber: page, pageSize: size})}`
  return fetchData(url, 'Ошибка при получении настроек:', setLoading)
}

export const getCount = (setLoading) => {
  const url = `${SCHEDULER_DASHBOARD_URL}/count`
  return fetchData(url, 'Ошибка при получении количества страниц:', setLoading)
}

export const getCountByName = (taskName, setLoading) => {
  const url = `${SCHEDULER_DASHBOARD_URL}/count-by-name?taskName=${taskName}`
  return fetchData(url, 'Ошибка при получении количества страниц по имени:', setLoading)
}

export const getCountById = (id, setLoading) => {
  const url = `${SCHEDULER_DASHBOARD_URL}/count-by-id?id=${id}`
  return fetchData(url, 'Ошибка при получении количества страниц по id:', setLoading)
}

export const getTaskTimes = (id, page, size) => {
  const url = SCHEDULER_DASHBOARD_URL + `/${id}?${getQueryParams({pageNumber: page, pageSize: size})}`
  return fetchData(url, 'Ошибка при получении time settings:')
}

export const getTaskSettingsByName = (taskName, page, size, setLoading) => {
  const url = `${SCHEDULER_DASHBOARD_URL}/filter-name/?taskName=${taskName}&${getQueryParams({pageNumber: page, pageSize: size})}`
  return fetchData(url, 'Ошибка при получении time settings по имени:', setLoading)
}

export const getTaskSettingsById = (id, page, size, setLoading) => {
  const url = `${SCHEDULER_DASHBOARD_URL}/filter-id/?id=${id}&${getQueryParams({pageNumber: page, pageSize: size})}`
  return fetchData(url, 'Ошибка при получении time settings по id:', setLoading)
}