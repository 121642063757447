import React, {
  useCallback,
  useEffect,
  useState
} from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import pt from 'prop-types'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import SelectControl from '@/components/controls/SelectControl'
import Loader from '@/components/blocks/Loader'
import { REQUEST_STATUSES } from '@/constants/requests'
import { FieldsContainer } from '@/forms/ObjectsGroups/CreateEditObjectsGroupForm/styles'
import {
  MessageContainer,
  SubmitButton,
} from './styles'
import AlarmsIcons from '@/components/icons/alarms'
import { VERIFIED } from '@/constants/objectStatuses'

const VerificationTab = ({
  verificationOptions,
  groupId,
  intl,
  verificationOptionsStatus,
  verificationStatus,

  requestGetVerificationOptions,
  requestSetVerificationStatus,
  requestVerificationAll,
}) => {
  const [status, setStatus] = useState('')
  const [completedMessage, setCompletedMessage] = useState(false)
  useEffect(() => {
    if (groupId) {
      requestGetVerificationOptions({ groupId, intl })
      setStatus('')
      setCompletedMessage(false)
    }
  }, [requestGetVerificationOptions, groupId, intl])

  const handleStatusChange = useCallback((value) => {
    setStatus(value)
  }, [setStatus])

  const handleSetStatus = useCallback(() => {
    setCompletedMessage(true)
    if (status === VERIFIED) {
      requestVerificationAll({groupId, status, intl })
      return
    }
    requestSetVerificationStatus({ groupId, status, intl })
  }, [requestSetVerificationStatus, requestVerificationAll, groupId, status, intl])

  if (verificationOptionsStatus !== REQUEST_STATUSES.IDLE) {
    return (
      <Loader center />
    )
  }

  if (verificationStatus === REQUEST_STATUSES.PENDING) {
    return (
      <Loader center />
    )
  }
  return (
    <>
      <FieldsContainer>
       {!verificationOptions.length ? 
          <MessageContainer>
              <AlarmsIcons.WarningIcon />
              <Lang id={`objectsGroups.verification.warning`} />
          </MessageContainer>
          :
          <>
            <LabelWithIcon
              title={<Lang id={`objectsGroups.verification.label`} />}
            />
            <SelectControl
              value={status}
              onChange={handleStatusChange}
              options={verificationOptions}
              placeholder={<Lang id={`objectsGroups.verification.placeholder`} />}
              withSearch
            />
          </>
        }
        {!!status && !!verificationOptions.length &&
          <SubmitButton onClick={handleSetStatus} disabled={verificationStatus === REQUEST_STATUSES.PENDING}>
            {status === VERIFIED ? <Lang id={`objectsGroups.verification.checkAll`} /> : <Lang id={`objectsGroups.verification.verify`} />}
          </SubmitButton>
        }
        {verificationStatus === REQUEST_STATUSES.IDLE && completedMessage &&  
          <MessageContainer completed>
            <AlarmsIcons.OkIcon />
            <Lang id={`objectsGroups.control.status.completed`} />
          </MessageContainer>
        }
      </FieldsContainer>
    </>
  )
}

VerificationTab.defaultProps = {
  verificationOptions: [],
  initialStatus: {},
  groupId: null,
  requestGetVerificationOptions: noop,
  requestSetVerificationStatus: noop,
}
VerificationTab.propTypes = {
  verificationOptions: pt.array,
  initialStatus: pt.object,
  groupId: pt.number,
  requestGetVerificationOptions: pt.func,
  requestSetVerificationStatus: pt.func,
}

export default VerificationTab

