import get from 'lodash/get'
import {
  NOT_VERIFIED,
  VERIFIED,
  NEW,
  NOT_EXIST,
  NOT_INSTALLED,
  INSTALLED,
  UNINSTALLED,
} from '@/constants/objectStatuses'

import {
  INSTALLATION_SERVER_TO_LOCAL,
} from '@/constants/passportization/types'

const defaultConfig = {
  [NOT_VERIFIED]: 0,
  [VERIFIED]: 0,
  [NEW]: 0,
  [NOT_EXIST]: 0,
  [NOT_INSTALLED]: 0,
  [INSTALLED]: 0,
  [UNINSTALLED]: 0,
}
export const getStatistic = (nodeStatistic) => {
  return {...defaultConfig, ...nodeStatistic}
}

export const getObjectStateChildStatistic = (nodes) => {
  return nodes.reduce((acc, item) => {
    return {...acc, [item.status]: acc[item.status] + 1 }
  }, defaultConfig)
}

// export const getTreeWithTelemetry = (tree, node, config = {}) => {
//   const nodeSelector = node.treeNodeType || node.installationType  || 'UNKNOWN'
//   let localConfig = { ...config }
//   if (node.treeNodeType === OBJECT_ELEMENT || node.treeNodeType === GEOZONE) {
//     localConfig = {
//       ...localConfig,
//       [`${nodeSelector}-${node.id}`]: {
//         status: node.objectState,
//         name: node.name,
//         statistic: getStatistic(node.objectStateChildStatistic),
//       },
//     }
//   }
//
//   return {
//     ...localConfig,
//   }
// }

export const getTreeAndPins = (node, config = []) => {
  if ((node.children || []).length === 0) {
    return [
      ...config,
      {
        id: node.treeElementId,
        name: node.elementName,
        location: [
          get(node, 'point.latitude', null),
          get(node, 'point.longitude', null),
        ],
        ...node,
      },
    ]
  }
  return (node.children || []).reduce((accumulator, element) => {
    const childrenElements = (element.children || []).reduce((buffer, child) => [
      ...buffer,
      ...getTreeAndPins(child),
    ], [])
    return [
      ...accumulator,
      ...childrenElements,
      ...getTreeAndPins(element),
    ]
  }, config)
}

export const getGeoZonesDisplayElements = (
  node,
  config = [],
  rootId,
  parentId,
  parentGeoZoneId,
  path,
) => (node.children || []).reduce((accumulator, element) => [
  ...accumulator,
  {
    id: element.id,
    name: element.name,
    location: [
      get(element, 'point.latitude', null),
      get(element, 'point.longitude', null),
    ],
    type: element.objectType || INSTALLATION_SERVER_TO_LOCAL[element.type] || 'unknown',
    elementType: element.treeNodeType,
    rootGeoZone: rootId,
    parentGeoZoneId,
    parentTreeId: parentId,
    path: `${path}/${element.id}`,
  },
], [])

export const calculateNotGeoZoneChild = (node, config = []) => {
  if (node.type === 'GEOZONE') {
    return [
      ...config,
      {
        id: node.treeElementId,
        name: node.elementName,
        location: [
          get(node, 'point.latitude', null),
          get(node, 'point.longitude', null),
        ],
        type: node.objectType || INSTALLATION_SERVER_TO_LOCAL[node.type] || 'unknow',
      },
    ]
  }
}

export const calculateDisplayElementsForTreeById = (
  node,
  config = [],
  selectedElementId,
  isCalcSelectedElementChildren = false,
  rootId,
  parentId,
  parentGeoZoneId,
  path,
) => {
  let localConfig = [...config]
  const isChildSelect = (node.children || []).filter((element) => element.id === selectedElementId)[0] || false
  if (node.id === selectedElementId && !isCalcSelectedElementChildren && !isChildSelect) {
    const childElements = (node.children || []).reduce((accumulator, element) => [
      ...accumulator,
      ...calculateDisplayElementsForTreeById(
        element,
        [],
        selectedElementId,
        true,
        rootId,
        node.id,
        element.treeNodeType === 'GEOZONE' ? element.id : parentGeoZoneId,
        `${path}/${element.id}`,
      ),
    ], [])

    return [...localConfig, ...childElements]
  }

  if (get(node, 'point.latitude', false) && !isChildSelect) {
    localConfig = [
      ...localConfig,
      {
        id: node.id,
        name: node.name,
        location: [
          get(node, 'point.latitude', null),
          get(node, 'point.longitude', null),
        ],
        type: node.treeNodeType || INSTALLATION_SERVER_TO_LOCAL[node.type] || 'unknow',
        elementType: node.type,
        rootGeoZone: rootId,
        parentGeoZoneId: node.treeNodeType === 'GEOZONE' ? node.id : parentGeoZoneId,
        parentTreeId: parentId,
        path,
      },
    ]
    if (node.treeNodeType === 'GEOZONE') {
      return localConfig
    }
  }

  const childResults = (node.children || []).reduce((accumulator, element) => [
    ...accumulator,
    ...calculateDisplayElementsForTreeById(
      element,
      [],
      selectedElementId,
      isCalcSelectedElementChildren,
      rootId,
      node.treeElementId,
      element.type === 'GEOZONE' ? element.treeElementId : parentGeoZoneId,
      `${path}/${element.treeElementId}`,
    ),
  ], [])
  return [
    ...localConfig,
    ...childResults,
  ]
}
