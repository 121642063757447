import styled from 'styled-components'
import { Form } from 'formik'
import isBoolean from 'lodash/isBoolean'
import TextControl from '@/components/controls/TextControl'
import { RadioButtonLabel } from '@/components/controls/RadioButton/styles'

export const CustomTextControl = styled(TextControl)`
  padding-left: 10px;
  padding-right: 10px;
`

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  font-size: 1.4rem;
  line-height: 2rem;
  text-transform: uppercase;
  margin: 10px 0;
  text-align: start;
  color: ${({ theme }) => theme.colors.colors.subTitle};
  ${({ errorTitle, theme }) => errorTitle && `
    font-weight: normal;
    text-transform: none;
    color: ${theme.colors.colors.error}
    margin: 20px 0 10px 0;
  `}
  ${({ errorText, theme }) => errorText && `
    white-space: pre-wrap;
    font-weight: normal;
    text-transform: none;
    color: ${theme.colors.colors.disabled}
    margin: 0;
  `}
`

export const FormContainer = styled(Form)`
  position: relative;
  width: 100%;
  ${RadioButtonLabel} {
    text-align: left;
    line-height: 2rem;
    padding: 5px 8px;
  }
`

export const FieldContainer = styled.div`
  display: flex;
  align-items: center;
  input {
    width: 100%;
  }
`

export const DecorativeElement = styled.div`
  width: 12px;
  height: 1px;
  background: ${({ theme }) => theme.colors.backgrounds.select};
  margin: 0 9px;
`

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
`

export const IconDiagramContainer = styled.div`
  margin-right: 10px;
`

export const SavingsItemContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ expenseCalculator }) => expenseCalculator && `
    justify-content: center;
    margin-top: 10px;
  `}
`
export const SecondaryTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 3rem;
  color: ${({ theme }) => theme.colors.colors.default};
  ${({ expenseCalculator }) => expenseCalculator && `
    line-height: 1.4rem;
    text-align: left;
    width: 80px;
    min-width: 80px;
    padding-right: 5px
  `}
  ${({ saving, theme }) => isBoolean(saving) && !saving && `
    color: ${theme.colors.colors.online}
  `}
  ${({ saving, theme }) => isBoolean(saving) && saving && `
    color: ${theme.colors.colors.error}
  `}
`
