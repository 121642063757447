import {
  put, takeLatest, call,
} from 'redux-saga/effects'
import { treeHandlers } from 'react-hyper-tree'
import request from '@/helpers/axios'
import {
  successCreateProject,
} from '@/store/actions/installation/createProject'
import {
  PROJECT,
} from '@/constants/objectTypes'
import { CREATE_PROJECT } from '@/store/actions/installation'
import { INSTALLATIONS_PROJECT_ENDPOINT } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import updateNodeCount from '@/helpers/updateNodeCount'
import { setGlobalLoading } from '@/store/actions/appSettings'
import get from 'lodash/get'

function* createProjectSaga({ payload }) {
  const { formValues, values, setUrlFormValues } = payload
  const {
    customFields = [], mark, name,
  } = values
  try {
    yield put(setGlobalLoading(true))
    const filterFields = (customFields || []).filter((element) => !!element.remove === false)
    const formattedCustomFields = filterFields.map((element) => ({
      nameField: element.nameField,
      value: element.value,
    }))
    const body = {
      name,
      note: mark,
      parentId: formValues.id,
      customFields: formattedCustomFields,
    }
    const { data } = yield request({
      url: INSTALLATIONS_PROJECT_ENDPOINT,
      method: 'post',
      body,
    })
    const {
      id, rootId, path, FILTER
    } = formValues
    if (id && path && data.parentId) {
      const newPath = path.replace(id, data.parentId)
      const toast = createNotifications()

      const tree = treeHandlers.trees['installations-tree']
      const parent = tree.instance.getNodeById(data.parentId)
      const parentChildren = yield call(parent.data.getChildren, parent)
      tree.handlers.setRawChildren(parent, parentChildren, 'first', true)
      const node = tree.instance.getNodeById(data.id)
      updateNodeCount(tree, path, 'add')
      const selectedNodePath = get(node, 'options.path', '')
      tree.handlers.setSelectedByPath(selectedNodePath)
      setUrlFormValues({
        id: data.id,
        type: PROJECT,
        parentGeoZoneId: data.parentId,
        rootId,
        path: `${newPath}/${data.id}`,
        parentTreeId: data.parentId,
        FILTER,
      })
      yield put(setGlobalLoading(false))
      toast({
        title: 'Создание проекта',
        type: 'success',
        description: 'Проект успешно создан.',
      })
      yield put(successCreateProject())
    }
  } catch (error) {
    yield put(setGlobalLoading(false))
    const { FILTER } = formValues
    setUrlFormValues({ FILTER })
    console.log('function*createProjectSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось создать проект.\nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(CREATE_PROJECT.REQUEST, createProjectSaga)
}
