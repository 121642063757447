import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const Logo = ({ color, onClick }) => {

  return (
    <svg width="73" height="72" viewBox="0 0 73 72" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="0.5" width="72" height="72" rx="17" fill="#DDDDDD"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M56.7692 23.1429H26.2143V48.8571H56.7692C52.5115 55.5554 45.0248 60 36.5 60C23.2452 60 12.5 49.2548 12.5 36C12.5 22.7452 23.2452 12 36.5 12C45.0248 12 52.5115 16.4446 56.7692 23.1429Z" fill="url(#paint0_radial_2741_121113)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M27.0714 23.1429C19.9706 23.1429 14.2143 28.8992 14.2143 36C14.2143 43.1008 19.9706 48.8571 27.0714 48.8571H39.0431C31.9553 48.8419 26.2143 43.0914 26.2143 36C26.2143 28.8992 31.9706 23.1429 39.0714 23.1429H57.5283C57.5189 23.1429 57.5094 23.1429 57.5 23.1429H27.0714Z" fill="url(#paint1_linear_2741_121113)"/>
      <ellipse cx="47.2143" cy="36" rx="13.2857" ry="12.8571" fill="url(#paint2_radial_2741_121113)"/>
      <defs>
      <radialGradient id="paint0_radial_2741_121113" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(48.2857 15.4286) rotate(104.811) scale(46.1033 46.1033)">
      <stop stop-color="#EFEFEF"/>
      <stop offset="1" stop-color="#9EA09F"/>
      </radialGradient>
      <linearGradient id="paint1_linear_2741_121113" x1="38.1117" y1="23.1429" x2="38.1117" y2="48.8571" gradientUnits="userSpaceOnUse">
      <stop stop-color="#3C3C3A"/>
      <stop offset="1" stop-color="#8D8D8D"/>
      </linearGradient>
      <radialGradient id="paint2_radial_2741_121113" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(50.8572 33.2143) rotate(114.692) scale(20.5189 21.2029)">
      <stop stop-color="#F5F7FC"/>
      <stop offset="0.69" stop-color="#0185CE"/>
      <stop offset="1" stop-color="#0083CB"/>
      </radialGradient>
      </defs>
    </svg>
  )
}

Logo.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

Logo.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(Logo)
