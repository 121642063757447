import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { SET_PASSPORTIZATION_DATA_URL } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { SET_PASSPORTIZATION_DATA } from '@/store/actions/objectsGroups'
import {
  errorSetPassportizationData,
  successSetPassportizationData
} from '@/store/actions/objectsGroups/setPassportizationData'
import nameToCatalogSelector from '@/constants/instalationPassport/nameToCatalogSelector'
import { requestGetPassportizationTable } from '@/store/actions/objectsGroups/getPassportizationTable'

function* setPassportizationData({ payload }) {
  const toast = createNotifications()
  try {
    const { groupId, fields } = payload
    const body = {
      id: groupId,
      fields: fields.map(item => {
        return {
          fieldName: item.field,
          passportBlock: nameToCatalogSelector[item.field],
          value: item.value
        }
      })
    }
    yield request({
      url: SET_PASSPORTIZATION_DATA_URL,
      method: 'post',
      body
    })
    yield put(successSetPassportizationData())
    yield put(requestGetPassportizationTable({ groupId }))
    toast({
      title: 'Редактирование паспорта',
      type: 'success',
      description: 'Поля паспорта успешно отредактированы',
    })
  } catch (error) {
    yield put(errorSetPassportizationData(error))
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось сохранить данные паспортизации. \nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* () {
  yield takeLatest(SET_PASSPORTIZATION_DATA.REQUEST, setPassportizationData)
}
