import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export const dropDownObject = [
  { value: 'currentTransformer', title: 'Трансформатор тока' },
  { value: 'baseStation', title: 'Базовая Станция' },
  { value: 'mercuryCounter', title: 'Счетчик Меркурий' },
]

export const dropDownStatus = [
  { value: 'notViewed', title: <Lang id="serviceDeskMyCity.statusSD.notViewed" /> },
  { value: 'new', title: <Lang id="serviceDeskMyCity.statusSD.new" /> },
  { value: 'registered', title: <Lang id="serviceDeskMyCity.statusSD.registered" /> },
  { value: 'contractorAppointed', title: <Lang id="serviceDeskMyCity.statusSD.contractorAppointed" /> },
  { value: 'inProgress', title: <Lang id="serviceDeskMyCity.statusSD.inProgress" /> },
  { value: 'waitingControl', title: <Lang id="serviceDeskMyCity.statusSD.waitingControl" /> },
  { value: 'completed', title: <Lang id="serviceDeskMyCity.statusSD.completed" /> },
  { value: 'delayed', title: <Lang id="serviceDeskMyCity.statusSD.delayed" /> },
  { value: 'rejected', title: <Lang id="serviceDeskMyCity.statusSD.rejected" /> },
  { value: 'almostExpired', title: <Lang id="serviceDeskMyCity.statusSD.almostExpired" /> },
  { value: 'expired', title: <Lang id="serviceDeskMyCity.statusSD.expired" /> },
]

export const statusSD = {
  notViewed: <Lang id="serviceDeskMyCity.statusSD.notViewed" />,
  new: <Lang id="serviceDeskMyCity.statusSD.new" />,
  registered: <Lang id="serviceDeskMyCity.statusSD.registered" />,
  contractorAppointed: <Lang id="serviceDeskMyCity.statusSD.contractorAppointed" />,
  inProgress: <Lang id="serviceDeskMyCity.statusSD.inProgress" />,
  waitingControl: <Lang id="serviceDeskMyCity.statusSD.waitingControl" />,
  completed: <Lang id="serviceDeskMyCity.statusSD.completed" />,
  delayed: <Lang id="serviceDeskMyCity.statusSD.delayed" />,
  rejected: <Lang id="serviceDeskMyCity.statusSD.rejected" />,
  almostExpired: <Lang id="serviceDeskMyCity.statusSD.almostExpired" />,
  expired: <Lang id="serviceDeskMyCity.statusSD.expired" />,
}
