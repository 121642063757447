import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

import GlobalPopup from '@/components/blocks/GlobalPopup'
import GlobalPopupContent from '@/components/blocks/GlobalPopupContent'


const GlobalPopupMobile = ({
  onClose,
  handleSetSelectedNode,
  handleUnpinNode,
  pinnedNode
}) => {

  const configAlert = pinnedNode.name ? 
    {
      message: `Это действие снимет закрепление данного объекта.`,
      title: 'Открепить объект?',
      type: 'info'
    }
  :
    {
      message: 'Это действие закрепит данный объект и открепит ранее закреплённый.',
      title: 'Закрепить объект?',
      type: 'warning'
    }
  
  return (
    <GlobalPopup 
    content={
      <GlobalPopupContent 
        {...configAlert}
        onClose={onClose}
        config={
             {
              warning: {
                buttons: [
                  {
                    statusType: 'flat',
                    onClickSelector: onClose,
                    title: <Lang id="buttons.cancel" />,
                  },
                  {
                    statusType: 'info',
                    onClickSelector: handleSetSelectedNode,
                    title: <Lang id="menu.pin" />,
                  },
                ],
              },
              info: {
                buttons: [
                  {
                    statusType: 'flat',
                    onClickSelector: onClose,
                    title: <Lang id="buttons.cancel" />,
                  },
                  {
                    statusType: 'info',
                    onClickSelector: handleUnpinNode,
                    title: <Lang id="menu.unpin" />,
                  },
                ]
              }
            }}
      />} 
  />
  )

}


export default GlobalPopupMobile
