import React, { useCallback, useRef, useState } from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import { Formik } from 'formik'
import pt from 'prop-types'
import noop from 'lodash/noop'
import get from 'lodash/get'
import {
  ACTION_TYPE,
  CREATE,
  CREATE_TYPE,
  UPLOAD_SCHEDULE_FILE
} from '@/constants/semanticNames'
import {
  ContentContainer,
  FormContainer,
  HiddenInputContainer
} from './styles'
import SelectField from '@/components/fields/SelectField'
import { createOptions } from './config'
import UsersIcons from '@/components/icons/users'
import { IconWrapper } from '@/components/regions/sidebars/PageSidebar/styles'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import GlobalPopup from '@/components/blocks/GlobalPopup'
import GlobalPopupContent from '@/components/blocks/GlobalPopupContent'
import MenuIcon from '@/components/icons/menu'

const CreateScheduleMenu = ({
  setFormValues,
  requestUploadSchedule,
  isGeoZoneSelected
}) => {
 const formicForm = useRef(null)
 const hiddenInput = useRef(null)
 const [isPopupOpen, setIsPopupOpen] = useState(false)

 const handleSetPopup = useCallback((value) => () => {
   setIsPopupOpen(value)
 }, [])

 const requestUploadScheduleHandler = (event) => {
   const files = get(event, 'target.files', [])
     if (files.length !== 0) {
       requestUploadSchedule({
         file: files[0],
       })
     }
       event.target.value = null
    }

   const handleSetValues = useCallback((formValues) => {
     if (!isGeoZoneSelected) {
       setIsPopupOpen(true)
       return
     }
     if (formValues[CREATE] === UPLOAD_SCHEDULE_FILE) {
       hiddenInput.current.click()
       formValues[CREATE] = null
       return null
     }

     const newUrlValues = {
       ...formValues[CREATE]
       ? {
          [ACTION_TYPE]: CREATE,
          [CREATE_TYPE]: formValues[CREATE],
         }
       : {},
     }

     setFormValues({
       ...newUrlValues,
       createMode: newUrlValues[ACTION_TYPE] === CREATE
     })
   }, [setFormValues, hiddenInput, isGeoZoneSelected])

   const onSubmit = useCallback(() => {}, [])

   return (
     <FormContainer>
       <Formik
         ref={formicForm}
         onSubmit={onSubmit}
         initialValues={{}}
         validate={handleSetValues}
         render={() => (
           <>
             <HiddenInputContainer
               type="file"
               accept=".xlsx, .xls"
               ref={hiddenInput}
               onChange={requestUploadScheduleHandler}
             />
             <ContentContainer>
               <SelectField
                 largeOptions
                 doNotDisplayValue
                 name={CREATE}
                 options={createOptions}
                 selectIcon={
                   <PortalTooltip
                     title={<Lang id="scheduleManager.titles.add" />}
                     handleIconClick={() => {}}
                     renderChildren={(
                       wrapperRef,
                       onMouseEnterHandler,
                       onMouseLeaveHandler,
                       onClickHandler
                     ) => (
                       <IconWrapper
                         onClick={onClickHandler}
                         ref={wrapperRef}
                         onMouseEnter={onMouseEnterHandler}
                         onMouseLeave={onMouseLeaveHandler}
                       >
                         <UsersIcons.PlusIcon />
                       </IconWrapper>
                     )}
                   />
               }
               />
             </ContentContainer>
             {isPopupOpen && (
               <GlobalPopup
                 content={(
                   <GlobalPopupContent
                     type={'warning'}
                     onClose={handleSetPopup(false)}
                     title={<Lang id={'scheduleManager.popup.executeImpossible'}/>}
                     message={<Lang id={'scheduleManager.popup.createScheduleErrorMessage'}/>}
                     config={{
                       warning: {
                         icon: MenuIcon.AttentionIcon,
                         buttons: [
                           {
                            statusType: 'warning',
                            onClickSelector: handleSetPopup(false),
                            title: <Lang id={'scheduleManager.popup.accept'}/>,
                           },
                         ],
                       },
                     }}
                   />
                 )}
               />
             )}
           </>
         )}
       />
     </FormContainer>
    )
}

CreateScheduleMenu.propTypes = {
    setFormValues: pt.func,
    requestUploadSchedule: pt.func,
}

CreateScheduleMenu.defaultProps = {
    setFormValues: noop,
    requestUploadSchedule: noop,
}

export default React.memo(CreateScheduleMenu)