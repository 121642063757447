import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const CloseNotification = ({ color, onClick }) => {

  return (
    <svg onClick={onClick} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1292_1385)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.28588 12.714C5.88939 15.3175 10.1105 15.3175 12.714 12.714C15.3175 10.1105 15.3175 5.88945 12.714 3.28594C10.1105 0.682437 5.88939 0.682437 3.28588 3.28594C0.682376 5.88945 0.682376 10.1105 3.28588 12.714ZM10.7498 6.0358L8.7856 7.99999L10.7498 9.96417L9.96411 10.7498L7.99993 8.78566L6.03574 10.7498L5.25007 9.96417L7.21425 7.99999L5.25007 6.0358L6.03574 5.25013L7.99993 7.21431L9.96411 5.25013L10.7498 6.0358Z" fill="#FAFBFB"/>
      </g>
      <defs>
      <clipPath id="clip0_1292_1385">
      <rect width="16" height="16" fill="white"/>
      </clipPath>
      </defs>
    </svg>

  )
}

CloseNotification.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

CloseNotification.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(CloseNotification)