export const LIGHT_THEME_NAME = 'light'
export const DARK_THEME_NAME = 'dark'

export const SKY_SCHEME_NAME = 'sky'
export const SPRING_SCHEME_NAME = 'spring'
export const SUMMER_SCHEME_NAME = 'summer'

export const REFRESH_10 = 10
export const REFRESH_30 = 30
export const REFRESH_60 = 60

export const WIDGET_SHOP_APP = 'app'
export const WIDGET_SHOP_WIDGET = 'widget'

export const SLS24_ACCESS_TOKEN = 'sls24.access_token'
export const ACTIVE_REQUEST_ITEM = 'sls:request'
export const ACTIVE_PING_ITEM = 'sls:ping'

export const RU_LANGUAGE = 'ru'
export const EN_LANGUAGE = 'en'
export const LANGUAGES = [RU_LANGUAGE, EN_LANGUAGE]

export const ANNUALLY = 'annually'
export const MONTHLY = 'monthly'
export const WEEKLY = 'weekly'
export const DAILY = 'daily'

export const YEAR = 'year'
export const CURRENT_MONTH = 'currentMonth'
export const PREVIOUS_MONTH = 'previousMonth'
export const QUARTER = 'quarter'

export const DEMONSTRATION_GRAPH = 'demonstrationGraph'
export const DEMONSTRATION_TABLE = 'demonstrationTable'

export const DIFFERENCE_ANALSIS = 'differenceAnalysis'
export const COMPARATIVE_ANALSIS = 'comparativeAnalysis'
export const COMPARATIVE_PLANE_ANALSIS = 'comparativePlaneAnalysis'

export const ROLE_SUPER_ADMIN = 'ROLE_SUPER_ADMIN'
export const ROLE_TENANT_ADMIN = 'ROLE_TENANT_ADMIN'
export const ROLE_USER = 'ROLE_USER'

export const LINEAR_CHART = 'LINEAR_CHART'
export const HISTOGRAM_CHART = 'HISTOGRAM_CHART'

export const OUTDOOR_LIGHTING = 'OUTDOOR_LIGHTING'
export const HEAT_SUPPLY = 'HEAT_SUPPLY'

export const ASC = 'ASC'
export const DESC = 'DESC'
