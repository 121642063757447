export default {
  catalogs: {
// controller cart
    location: 'location',
    objectCharacteristics: 'object characteristics',
    expertiseData: 'data from the survey',
    objectService: 'object service',
// controller passport
    generalInfo: 'general info',
    technicalLimitations: 'technical parameters',
    exploitation: 'exploitation',
    inner: 'embeddings',
  },
  fields: {
// controller cart
    'LOCATION.PROJECT': 'Project',
    'LOCATION.LATITUDE': 'Geographic latitude',
    'LOCATION.LONGITUDE': 'Geographic longitude',
    'LOCATION.REGION': 'Region',
    'LOCATION.CITY': 'City',
    'LOCATION.STREET': 'Street',
    'LOCATION.HOME_NUMBER': 'House',
    'location.project': 'Project',
    'location.latitude': 'Geographic latitude',
    'location.longitude': 'Geographic longitude',
    'location.region': 'Region',
    'location.city': 'City',
    'location.street': 'Street',
    'location.homeNumber': 'House',
    'objectCharacteristics.equipmentType': 'Equipment Type',
    'objectCharacteristics.lampType': 'Lamp Type',
    'objectCharacteristics.lightingCategory': 'Lighting object category',
    'objectCharacteristics.importance': 'Importance of the object',
    'objectCharacteristics.workMode': 'Workmode',
    'objectCharacteristics.installationType': 'Installation Type',
    'objectCharacteristics.controlType': 'Control type',
    'objectCharacteristics.lightingType': 'Lighting object type',
    'objectCharacteristics.outgoingLinesNumber': 'Number of outgoing lines',
    'objectCharacteristics.setup': 'Setup',
    'objectCharacteristics.indicators': 'Indicators',
    'objectCharacteristics.connectedLampNumber': 'Number of connected lights',
    'objectCharacteristics.locationType': 'Pedestrian Space Feature',
    'expertiseData.connectedLampNumber': 'Number of connected lamps',
    'expertiseData.locationType': 'Pedestrian space attribute',
    'expertiseData.lampInstallationHeight': 'Lamp installation height in meters',
    'expertiseData.roadwayWidth': 'Width of the roadway in meters',
    'expertiseData.installationScheme': 'Pole Installation Scheme',
    'expertiseData.state': 'Prop state',
    'expertiseData.powerSupply': 'Power',
    'expertiseData.powerSupplyType': 'Power Type',
    'expertiseData.overhangHeight': 'Light point overhang in meters',
    'expertiseData.lampAngle': 'Inclination of the light point in degrees',
    'expertiseData.overhangLength': 'Console length in meters',
    'expertiseData.utilityPoleLampPosition': 'Lamp position on the pole in degrees',
    'objectService.inspectionDate': 'Inspection Date',
    'objectService.bypassDate': 'Bypass Date',
    'objectService.maintenanceDate': 'Maintenance Date',
    'objectService.repairDate': 'Repair Date',
    'objectService.capitalRepairDate': 'Date of major repair',
    'objectService.guaranteeExpirationDate': 'Warranty expiration date',
    'objectService.checkingDate': 'Date of verification',

// controller passport
    'generalInfo.identifier': 'Identifier',
    'generalInfo.name': 'Name',
    'generalInfo.installation': 'Installation',
    'generalInfo.equipmentType': 'Equipment Type',
    'generalInfo.versionApi': 'Software version (API)',
    'generalInfo.utilityPoleType': 'Prop Type',
    'generalInfo.modelMark': 'Model/Mark',
    'generalInfo.lampType': 'Lamp Type',
    'generalInfo.manufacturer': 'Manufacturer',
    'generalInfo.producerSupplier': 'Producer',
    'generalInfo.lightingControlBoxIdentifier': 'LCB ID',
    'generalInfo.automatedControlSystemIdentifier': 'ACS ID',
    'generalInfo.lampIdentifier': 'Lamp ID',
    'generalInfo.supportIdentifier': 'Prop ID',
    'generalInfo.lineIdentifier': 'Line ID',
    'generalInfo.baseStationIdentifier': 'Base Station ID',
    'generalInfo.utilityPoleIdentifier': 'Pole ID',
    'generalInfo.parentIdentifier': 'Parent identifier',
    'generalInfo.parentType': 'Parent type',
    'generalInfo.atmosphereTemperature': 'Atmospheric temperature',
    'generalInfo.atmospherePressure': 'Atmosphere pressure',
    'technicalLimitations.workingTemperatureRange': 'Working temperature range',
    'technicalLimitations.powerConsumption': 'Power Consumption',
    'technicalLimitations.workingVoltageRange': 'Working voltage range',
    'technicalLimitations.lightOutput': 'Light output',
    'technicalLimitations.calibrationInterval': 'Calibration Interval',
    'technicalLimitations.colourTemperature': 'Color temperature',
    'technicalLimitations.colourRenderingIndex': 'Color rendering index',
    'technicalLimitations.lampEfficiency': 'Lamp Efficiency',
    'technicalLimitations.scatteringAngle': 'Scattering Angle',
    'technicalLimitations.lightIntensityDistributionCurveType': 'Curve Type',
    'technicalLimitations.flickerPercent': 'Flicker factor',
    'technicalLimitations.ratedPower': 'Rated Power',
    'technicalLimitations.electricitySupplier': 'Electrical Power Supplier',
    'technicalLimitations.actualConsumptionPower': 'Actually Connected Power',
    'technicalLimitations.temperature': 'Temperature in the pipeline',
    'technicalLimitations.pipeDiameter': 'Nominal diameter of the pipe',
    'technicalLimitations.dealTemperature': 'Contractual value of cold water temperature',
    'technicalLimitations.dealPressure': 'Contractual value of cold water pressure',
    'technicalLimitations.tubeDealPressure': 'Contractual pressure value in the pipeline',
    'technicalLimitations.massFlow': 'Mass flow in the pipeline',
    'technicalLimitations.volumetricFlow': 'Volume flow in the pipeline',
    'technicalLimitations.totalVolume': 'Volume of resource consumed',
    'technicalLimitations.workTime': 'Work time',
    'technicalLimitations.conventionalStraitPipeline': 'Conditional diameter of the pipe in the supply pipeline',
    'technicalLimitations.conventionalReversPipeline': 'Conditional diameter of the pipe in the return pipeline',
    'technicalLimitations.temperatureInPipe': 'Supply temperature',
    'technicalLimitations.temperatureInReversePipe': 'Return temperature',
    'technicalLimitations.totalMassInPipe': 'Weight in supply line',
    'technicalLimitations.totalMassInReversePipe': 'Return weight',
    'technicalLimitations.massFlowInPipe': 'Mass flow in supply line',
    'technicalLimitations.massFlowInReversePipe': 'Return mass flow',
    'technicalLimitations.volumetricFlowInPipe': 'Volume in supply line',
    'technicalLimitations.volumetricFlowInReversePipe': 'Return volume',
    'technicalLimitations.straitPipelineVolumeConsumption': 'Volume flow in the supply line',
    'technicalLimitations.reversePipelineVolumeConsumption': 'Volume flow in the return line',
    'technicalLimitations.consumedVolume': 'Volume of resource consumed',
    'technicalLimitations.energy': 'Thermal energy',
    'technicalLimitations.heatCosts': 'Heating costs',
    'technicalLimitations.economicImportanceOfOverheat': 'Economic importance of reheat',
    'technicalLimitations.detectionOfUnderheat': 'Detection of underheating',
    'technicalLimitations.detectionOfOverheat': 'Detection of overheating',
    'exploitation.startDate': 'Exploitation start date',
    'exploitation.organization': 'Operating organization',
    'exploitation.owner': 'Owner',
    'exploitation.guaranteePeriod': 'Guarantee Period',
    'exploitation.lifeTime': 'Lifetime',
    'exploitation.totalNumberOfConnectedLightingDevices': 'Total number of connected lighting fixtures',
    'inner.photo': 'Photo',
    'inner.file': 'Product passport file',
    'plan.date' : 'Date plan SPM',
    'fact.date' : 'Date fact SPM',


    'objectCharacteristics.tariffOne': 'Tariff1',
    'objectCharacteristics.tariffTwo': 'Tariff2',
    'objectCharacteristics.tariffThree': 'Tariff3',
    'objectCharacteristics.tariffFour': 'Tariff4',


  },
  title: 'Passportization',
  changeTitle: 'Save changes?',
  passport: 'Passport',
  card: 'Card',
  passportSidebar: {
    equipment: 'installation equipment',
    discardAll: 'Mark all',
    passport: 'Passport',
    card: 'Card',
    view: 'View:',
    noImage: 'No image',
    passportExport: 'Passport export',
    fieldSettings: 'Field display settings:',
    yes: 'Yes',
    no: 'No',
  },
}
