import { GET_SCHEDULE } from './index'

export const requestGetSchedule = (payload) => ({
    type: GET_SCHEDULE.REQUEST,
    payload,
})

export const successGetSchedule = (payload) => ({
    type: GET_SCHEDULE.SUCCESS,
    payload,
})

export const errorGetSchedule = (payload) => ({
    type: GET_SCHEDULE.ERROR,
    payload,
})