import {
  takeLatest, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_ALL_INTEGRATIONS } from '@/store/actions/installation'
import {
  getFreeIntegrations,
  generateGetDeleteIntegrationCard, getConnectedIntegrations,
} from '@/constants/apiRoutes'
import {
  ATM,
  BRIZ,
  KULON,
  MESH,
  UNILIGHT,
} from '@/constants/integrations'
import {
  KULON as KULON_INSTALLATION,
  BRIZ as BRIZ_INSTALLATION,
  ONE_SIM as ONE_SIM_INSTALLATION,
  MESH as MESH_INSTALLATION,
  UNILIGHT as UNILIGHT_INSTALLATION,
} from '@/constants/instalationPassport/types'
import {
  successGetAllIntegrations,
  errorGetAllIntegrations,
} from '@/store/actions/installation/getAllIntegrations'
import createNotifications from "@/helpers/notification";

const syncModuleToIntegrationModule = {
  [ATM]: ONE_SIM_INSTALLATION,
  [BRIZ]: BRIZ_INSTALLATION,
  [KULON]: KULON_INSTALLATION,
  [MESH]: MESH_INSTALLATION,
  [UNILIGHT]: UNILIGHT_INSTALLATION,
}

function* getIntegrationsByType({ payload: { type, id, aliasId } }) {
  try {
    if (id) {
      const { data: selectedIntegration } = yield request({
        url: generateGetDeleteIntegrationCard(id),
        method: 'get',
      })
      yield put(successGetAllIntegrations([{
        value: selectedIntegration.integrationId,
        title: selectedIntegration.integrationName,
      }]))
    }
    if (aliasId) {
      const { data: filterIntegration } = yield request({
        url: getConnectedIntegrations(aliasId),
        method: 'get',
      })
      const formatFilterIntegration = [{
        value: filterIntegration.id,
        title: filterIntegration.name,
      }]
      yield put(successGetAllIntegrations(formatFilterIntegration))
    } else {
      const { data: filterIntegration } = yield request({
        url: getFreeIntegrations({
          params: {
            integrationType: syncModuleToIntegrationModule[type]
          }
        }),
        method: 'get',
      })
      const formatFilterIntegration =filterIntegration.map(element => (
          {
            value: element.id,
            title: element.tenantName ? `${element.tenantName} - ${element.name}` : element.name,
          }
      ))
      yield put(successGetAllIntegrations(formatFilterIntegration))
    }
  } catch (error) {
    yield put(errorGetAllIntegrations())
    console.log('TCL: function*exportCardFileSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_ALL_INTEGRATIONS.REQUEST, getIntegrationsByType)
}
