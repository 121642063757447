import {
    takeLatest, put
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import createNotifications from '@/helpers/notification'
import { generateGetObjectTelemetry } from '@/constants/apiRoutes'
import {
    errorGetObjectTelemetry,
    successGetObjectTelemetry
} from '@/store/actions/installation/getObjectTelemetry'
import { mapperByType } from '@/store/sagas/maps/events/monitoringNotifications'
import { mapMeshTelemetry } from '@/helpers/maps/telemetryMappers'
import { GET_OBJECT_TELEMETRY } from '@/store/actions/installation'

function* getObjectTelemetry({ payload }) {
    const {aliasId, integrationType, objectType, objectId} = payload
    try {
        if (aliasId) {
            const response = yield request({
                url: generateGetObjectTelemetry({
                    params: {
                        aliasId,
                        integrationType,
                        objectType,
                    },
                    id: objectId
                }),
                method: 'get',
            })

            const { eventType } = (response.data || {}).header || {}
            let mapTelemetryFunction = mapperByType[eventType]
            if (!eventType && response.data.type) {
                mapTelemetryFunction = mapMeshTelemetry
            }

            const telemetry = mapTelemetryFunction(response.data)[`${aliasId}-${objectId}`]
            yield put(successGetObjectTelemetry(telemetry))
        }
    } catch (error) {
        console.log('function*getObjectTelemetry -> error', error)
        yield put(errorGetObjectTelemetry())
        const toast = createNotifications()
        toast({
            title: 'Ошибка операции!',
            description: 'Не удалось получить телеметрию. Повторите попытку позже.',
            type: 'error',
        })
    }
}
export default function* root() {
    yield takeLatest(GET_OBJECT_TELEMETRY.REQUEST, getObjectTelemetry)
}
