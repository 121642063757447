import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import generateWidget from './generateWidget'
import {
  UnitvolumetricFlow,
  UnitVolume
} from './unitElements'

export const WATER_TEMPERATURE = generateWidget({
  config: {
    id: 'WATER_TEMPERATURE',
    title: <Lang id="widgets.temperature" />,
    minValue: 4,
    maxValue: 20,
    units: '°C',
  },
  selectorTemplate: 'temperature',
})

export const WATER_MASS_FLOW = generateWidget({
  config: {
    id: 'WATER_MASS_FLOW',
    title: <Lang id="widgets.massFlow" />,
    units: <Lang id="widgets.massFlowUnits" />,
  },
  selectorTemplate: 'massFlow',
})

export const WATER_VOLUMETRIC_FLOW = generateWidget({
  config: {
    id: 'WATER_VOLUMETRIC_FLOW',
    title: <Lang id="widgets.volumeFlow" />,
    units: UnitvolumetricFlow,
  },
  selectorTemplate: 'volumetricFlow',
})

export const WATER_VOLUMETRIC_CONSUMED_FLOW = generateWidget({
  config: {
    id: 'WATER_VOLUMETRIC_CONSUMED_FLOW',
    title: <Lang id="passportization.fields.technicalLimitations.consumedVolume" />,
    units: UnitVolume,
  },
  selectorTemplate: 'consumedVolume',
})
