import React from 'react'
import { Formik } from 'formik'
import pt from 'prop-types'
import noop from 'lodash/noop'
import {
  ButtonsWrapper,
  FormWrapper,
  StyledButton,
  StyledSelectField,
  Title,
  TitleAndSelectWrapper,
  StyledTextField,
  TextControlWrapper
} from './styles'
import { FormattedMessage as Lang, injectIntl } from 'react-intl'
import {
  EQUIPMENT_TYPE,
  GEOZONE,
  PROJECT,
  INTEGRATION,
  CITY,
  REGION,
  NAME
} from '@/constants/forms/search'
import UsersIcons from '@/components/icons/users'

const SearchForm = ({
  onSetValues,
  searchFieldsOptions,
  searchValues,
  intl
}) => {

  return (
    <Formik
      enableReinitialize
      onSubmit={onSetValues}
      initialValues={searchValues}
      render={({
        handleSubmit
      }) => (
        <FormWrapper>
          <TextControlWrapper>
            <StyledTextField
              dark
              name={NAME}
              fieldProps={{
                icon: UsersIcons.MagnifierIcon,
                placeholder: intl.messages['search.selectPlaceholders.name'],
                disabled: false
              }}
            />
          </TextControlWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="search.formTitles.equipmentType" />
            </Title>
            <StyledSelectField
               multiselect
               placeholder={<Lang id="search.selectPlaceholders.equipmentType" />}
               withSearch
               name={EQUIPMENT_TYPE}
               options={searchFieldsOptions[EQUIPMENT_TYPE] || []}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="search.formTitles.geozone" />
            </Title>
            <StyledSelectField
               multiselect
               withSearch
               name={GEOZONE}
               placeholder={<Lang id="search.selectPlaceholders.geozone" />}
               options={searchFieldsOptions[GEOZONE] || []}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="search.formTitles.project" />
            </Title>
            <StyledSelectField
              multiselect
              withSearch
              name={PROJECT}
              placeholder={<Lang id="search.selectPlaceholders.project" />}
              options={searchFieldsOptions[PROJECT] || []}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="search.formTitles.integration" />
            </Title>
            <StyledSelectField
              multiselect
              withSearch
              name={INTEGRATION}
              placeholder={<Lang id="search.selectPlaceholders.integration" />}
              options={searchFieldsOptions[INTEGRATION] || []}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="search.formTitles.region" />
            </Title>
            <StyledSelectField
              multiselect
              withSearch
              name={REGION}
              placeholder={<Lang id="search.selectPlaceholders.region" />}
              options={searchFieldsOptions[REGION] || []}
            />
          </TitleAndSelectWrapper>
          <TitleAndSelectWrapper>
            <Title>
              <Lang id="search.formTitles.city" />
            </Title>
            <StyledSelectField
              multiselect
              withSearch
              name={CITY}
              placeholder={<Lang id="search.selectPlaceholders.city" />}
              options={searchFieldsOptions[CITY] || []}
            />
          </TitleAndSelectWrapper>
          <ButtonsWrapper>
            <StyledButton usage="accept" onClick={handleSubmit}>
              <Lang id="search.buttons.accept" />
            </StyledButton>
          </ButtonsWrapper>
        </FormWrapper>
      )}
    />
  )

}

SearchForm.propTypes = {
  onSetValues: pt.func.isRequired,
  searchFieldsOptions: pt.object,
  searchValues: pt.shape({}),
}

SearchForm.defaultProps = {
  onSetValues: noop,
  searchFieldsOptions: {},
  searchValues: {},
}

export default injectIntl(SearchForm)