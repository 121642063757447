import { handleActions } from 'redux-actions'
import { REQUEST_STATUSES } from '@/constants/requests'
import { GET_ANALYTICS_ALARMS_WIDGET } from '@/store/actions/analyticsAlarms'
import { GET_TYUMEN_MESSAGES_WIDGET } from '@/store/actions/tyumenOurHome'
import { GET_SERVICE_DESK_INFO_WIDGET } from '@/store/actions/serviceDeskMyCity'

const initialState = {
  data: {},
  status: REQUEST_STATUSES.NOT_REQUESTED,
  loader: true,
}

export const reducer = handleActions(
  {
    [GET_ANALYTICS_ALARMS_WIDGET.REQUEST]: (state) => ({
      ...state,
      loader: true,
      status: REQUEST_STATUSES.PENDING,
    }),
    [GET_ANALYTICS_ALARMS_WIDGET.SUCCESS]: (state, { payload }) => ({
      ...state,
      data: payload,
      loader: false,
      status: REQUEST_STATUSES.IDLE,
    }),
    [GET_ANALYTICS_ALARMS_WIDGET.ERROR]: (state) => ({
      ...state,
      loader: false,
      status: REQUEST_STATUSES.ERROR,
    }),

    [GET_TYUMEN_MESSAGES_WIDGET.REQUEST]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.PENDING,
    }),
    [GET_TYUMEN_MESSAGES_WIDGET.SUCCESS]: (state, { payload }) => ({
      ...state,
      data: { ...state.data, ...payload },
      status: REQUEST_STATUSES.IDLE,
    }),
    [GET_TYUMEN_MESSAGES_WIDGET.ERROR]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.ERROR,
    }),

    [GET_SERVICE_DESK_INFO_WIDGET.REQUEST]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.PENDING,
    }),
    [GET_SERVICE_DESK_INFO_WIDGET.SUCCESS]: (state, { payload }) => ({
      ...state,
      data: { ...state.data, ...payload },
      status: REQUEST_STATUSES.IDLE,
    }),
    [GET_SERVICE_DESK_INFO_WIDGET.ERROR]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.ERROR,
    }),
  },
  initialState,
)

export default reducer
