import { all } from 'redux-saga/effects'

import getGraphTimeSaga from './getGraphTime'
import getTable from './getTable'
import getFile from './getFile'
import getObjectTypes from './getObjectTypes'

export default function* root() {
  yield all([
    getGraphTimeSaga(),
    getTable(),
    getFile(),
    getObjectTypes(),
  ])
}
