export default {
  ok: 'OK',
  cancel: 'Cancel',
  login: 'Login',
  logout: 'Logout',
  addToDesktop: 'Add to desktop',
  install: 'Install',
  clear: 'I see',
  apply: 'Apply',
  all: 'Select all',
  close: 'Close',
  applyForAll: 'Apply to all similar objects',
  applyForOne: 'Apply to the current object',
}
