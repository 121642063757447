import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ApplicationsIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path className="svgFill" fill={fill} d="M16.125 20.0001H20.125V16.0001H16.125V20.0001ZM16.125 14.0001H20.125V10.0001H16.125V14.0001ZM10.125 8.00012H14.125V4.00012H10.125V8.00012ZM16.125 8.00012H20.125V4.00012H16.125V8.00012ZM10.125 14.0001H14.125V10.0001H10.125V14.0001ZM4.125 14.0001H8.125V10.0001H4.125V14.0001ZM4.125 20.0001H8.125V16.0001H4.125V20.0001ZM10.125 20.0001H14.125V16.0001H10.125V20.0001ZM4.125 8.00012H8.125V4.00012H4.125V8.00012Z" />
    </svg> 
  )
}

ApplicationsIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ApplicationsIcon.defaultProps = {
  color: '#1E2127',
  onClick: noop,
}

export default memoWithName(ApplicationsIcon)
