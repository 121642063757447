import styled from 'styled-components'

export default styled.div`
  color: '';
`

export const Container = styled.div`
  width: 100%;
  height: calc(100vh - 144px);
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
`

export const ContainerMobile = styled.div`
display: flex;
flex-direction: column;
width: 100%;
height: 100%;
overflow-y: hidden;
overflow-x: hidden;
`
