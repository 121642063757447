import React, { useCallback, useEffect, useState } from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import {
  SCHEDULE, SCHEDULE_FAILURE_LOG,
  TABS_CONFIG, USER_ACTIVITY_LOG
} from './config'
import {
  Container,
  ContentContainer,
  EmptySchedule,
  TabButton,
  TabHeader,
  Text,
} from './styles'
import ScheduleDetails from '@/containers/pages/ScheduleManger/components/ScheduleDetails'
import ScheduleFailureLog from '@/containers/pages/ScheduleManger/components/ScheduleFailureLog/component'
import UserActivityLog from '@/containers/pages/ScheduleManger/components/UserActivityLog'

const ScheduleContent = ({
  formValues,
  setFormValues,
  isScheduleSelected,
  id,
  selectedSchedule,
  requestGetCard,
  requestDeleteSchedule,
  scheduleSelectedNode,

  failureTableData,
  loadingFailureTable,
  failureDates,
  errorStatus,
  requestGetFailureLogTable,
  setFailureLogDatesRange,
  setErrorStatus,

  activityTableData,
  loadingActivityTable,
  activityDates,
  action,
  requestGetActivityLogTable,
  setActivityLogDatesRange,
  setAction,
}) => {
  const [activeTab, setActiveTab] = useState(SCHEDULE)

  useEffect(() => {
    if (isScheduleSelected) {
      requestGetCard({id})
    }
  }, [id, requestGetCard, isScheduleSelected])

  const handleSetActiveTab = useCallback((tab) => () => (
    setActiveTab(tab)
  ), [])

  return (
    <Container>
      <TabHeader>
        {TABS_CONFIG.map((element) => {
          return (
            <TabButton
              key={element.id}
              onClick={handleSetActiveTab(element.id)}
              active={activeTab === element.id}
            >
              <Text active={activeTab === element.id}>{element.name}</Text>
            </TabButton>
          )
        })}
      </TabHeader>
        {activeTab === SCHEDULE &&
          <ContentContainer>
            {isScheduleSelected
              ? <ScheduleDetails
                  selectedSchedule={selectedSchedule}
                  formValues={formValues}
                  setFormValues={setFormValues}
                  requestDeleteSchedule={requestDeleteSchedule}
                />
              : <EmptySchedule>
                  <Lang id="scheduleManager.titles.emptyScheduleText" />
                </EmptySchedule>
            }
          </ContentContainer>
        }
        {activeTab === SCHEDULE_FAILURE_LOG &&
          <ContentContainer>
            {scheduleSelectedNode && scheduleSelectedNode.id
              ? <ScheduleFailureLog
                  data={failureTableData}
                  loadingTable={loadingFailureTable}
                  dates={failureDates}
                  errorStatus={errorStatus}
                  scheduleSelectedNode={scheduleSelectedNode}

                  requestGetTable={requestGetFailureLogTable}
                  setDatesRange={setFailureLogDatesRange}
                  setErrorStatus={setErrorStatus}
                />
              : <EmptySchedule>
                  <Lang id="scheduleManager.titles.emptyScheduleText" />
                </EmptySchedule>
            }
          </ContentContainer>
        }
        {activeTab === USER_ACTIVITY_LOG &&
          <ContentContainer>
            {scheduleSelectedNode && scheduleSelectedNode.id
              ? <UserActivityLog
                  data={activityTableData}
                  loadingTable={loadingActivityTable}
                  dates={activityDates}
                  action={action}
                  scheduleSelectedNode={scheduleSelectedNode}

                  requestGetTable={requestGetActivityLogTable}
                  setDatesRange={setActivityLogDatesRange}
                  setAction={setAction}
                />
              : <EmptySchedule>
                  <Lang id="scheduleManager.titles.emptyScheduleText" />
                </EmptySchedule>
            }
          </ContentContainer>
        }
    </Container>
  )
}

export default ScheduleContent
