import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const SortDescendingIcon = ({ color, onClick }) => {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_2023_10242)">
      <path d="M19 6.99854H22L18 2.99854L14 6.99854H17V20.9985H19V6.99854ZM2 16.9985H12V18.9985H2V16.9985ZM6 4.99854V6.99854H2V4.99854H6ZM2 10.9985H9V12.9985H2V10.9985Z" fill="#838D99"/>
      </g>
      <defs>
      <clipPath id="clip0_2023_10242">
      <rect width="24" height="24" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  )
}

SortDescendingIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

SortDescendingIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(SortDescendingIcon)
