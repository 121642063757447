import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

import { withRouter } from 'react-router-dom'
import { 
  Title, 
  Container, 
  Wrapper , 
  WrapperSystem, 
  WrapperIcon, 
  ContainerUserInfo, 
  WrapperUserProfile, 
  Lable,
  ContainerVersion,
  UserImage,
  Header,
  TitlePage,
} from './styles'
import Settigs from '@/components/icons/mobileIcons/index'
import WidgetsDashboardIcon from '@/components/icons/widgetsDashboard'




const SettingMobile = ({
  onLogout,
  user
}) => {

  const userIcon = require('@/assets/images/user.png')


  const titlesSystem = [
    {
      title: 'Личные данные',
      icon: Settigs.PersonalDataIcon
    }
    ,
    {
      title: 'Настройки уведомлений',
      icon: Settigs.NotificationsIcon
    },
    {
      title: 'Тема оформления',
      icon: Settigs.ThemIcon
    },
  ]

  const titleSecurity = [
    {
      title: 'Помощь',
      icon: Settigs.HelpIcon
    }
    ,
    {
      title: 'О Приложении',
      icon: Settigs.AboutAppIcon
    },
  ]

  const rendreUserName = () => {
    if (user.role === 'ROLE_SUPER_ADMIN') {
      return 'Супер Администратор'
    }
    return `${user.firstName} ${user.lastName ? user.lastName : ''}`
  }

  const toggleVersion = () => {
    const viewport = document.querySelector("meta[name=viewport]");
    viewport.setAttribute('content', 'width=1200');
  }

  return (
    <>
      <Header>
          <TitlePage>
            <Lang id="tooltip.settings" />
          </TitlePage>
          <WidgetsDashboardIcon.DotsIcon  />
        </Header>
      <Container>
        <WrapperUserProfile>
          <UserImage>
            <img alt="User" src={userIcon} />
          </UserImage>
          <ContainerUserInfo>
            <Lable>
            {rendreUserName()}
            </Lable>
            <Lable>
              {user.email}
            </Lable>
          </ContainerUserInfo>
        </WrapperUserProfile>
        <WrapperSystem>
          {titlesSystem.map((title) => {
            return (
              <Wrapper>
                <WrapperIcon>
                  <title.icon/>
                </WrapperIcon>
                <Title>{title.title}</Title>
              </Wrapper>
            )
          })}
        </WrapperSystem>
        <WrapperSystem>
          {titleSecurity.map((title) => {
            return (
              <Wrapper>
                <WrapperIcon>
                  <title.icon/>
                </WrapperIcon>
                <Title>{title.title}</Title>
              </Wrapper>
            )
          })}
          <Wrapper onClick={toggleVersion}>
                <WrapperIcon>
                </WrapperIcon>
                <Title>Версия для ПК</Title>
              </Wrapper>
        </WrapperSystem>
        <WrapperSystem onClick={onLogout}>
          <Wrapper>
            <WrapperIcon>
              <Settigs.ExitIcon/>
            </WrapperIcon>
            <Title>Выйти из системы</Title>
          </Wrapper>
        </WrapperSystem>
        <ContainerVersion>
          Версия 0.0.1
        </ContainerVersion>
      </Container>
    </>
  )
}

export default React.memo(withRouter(SettingMobile))

