import React from 'react'
import * as mapTypes from '@/propTypes/maps'
import {
  PinContainer,
  OverLay,
} from './styles'
import CheckedIcon from '@/components/icons/maps/CheckedIcon'

export const CheckedIconPin = ({ theme, pinColor }) => {
  const Icon = CheckedIcon
  return (
    <PinContainer theme={theme}>
      <OverLay theme={theme}>
        <Icon color={pinColor} />
      </OverLay>
    </PinContainer>
  )
}

CheckedIconPin.propTypes = {
  theme: mapTypes.theme.isRequired,
  color: mapTypes.markStatus,
}

export default CheckedIconPin
