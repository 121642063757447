import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { generatePutAdmin } from '@/constants/apiRoutes'
import {
  ADMIN,
  ADMIN_EDIT,
  USER,
  USER_EDIT,
} from '@/constants/viewTree'
import {
  getUserData,
} from '@/store/selectors/appSettings'
import {
  isSuperAdmin,
  isAdmin,
} from '@/helpers/userPermissions'
import {
  GET_TENANT_ADMIN,
  setEntity,
  updateSelectedUserType,
} from '@/store/actions/users'
import createNotifications from "@/helpers/notification";

function* getAdminTenantSaga({ payload }) {
  const { id } = payload
  try {
    const loginUserData = yield select(getUserData)
    const { id: authorizeUserId } = loginUserData
    const response = yield request({
      url: generatePutAdmin(id),
      method: 'get',
    })
    const userRole = response.data.role
    const responseData = {
      ...response.data,
      name: response.data.firstName,
      type: isAdmin(userRole) || isSuperAdmin(userRole) ? ADMIN : USER,
      isMe: response.data.id === authorizeUserId,
      children: [],
    }
    if ((responseData.enabled && (responseData.lastName || responseData.id === authorizeUserId))) {
      yield put(setEntity(responseData))
      yield put(updateSelectedUserType(
        isAdmin(userRole) || isSuperAdmin(userRole)
          ? ADMIN_EDIT
          : USER_EDIT,
      ))
    } else {
      yield put(setEntity(responseData))
      yield put(updateSelectedUserType(
        isAdmin(userRole) || isSuperAdmin(userRole)
          ? ADMIN
          : USER,
      ))
    }
  } catch (error) {
    console.log('TCL: function*getAdminTenantSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_TENANT_ADMIN.REQUEST, getAdminTenantSaga)
}
