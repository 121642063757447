import {
  CONTROLLER,
  CONTROLLER_FUSE,
  BASE_STATION,
  COMPANY_OBJECT,
  CONTROL_CUPBOARD,
} from '@/constants/instalationPassport/types'

export const isController = (type) => {
  return type === CONTROLLER
    || type === CONTROLLER_FUSE
    || type === BASE_STATION
    || type === COMPANY_OBJECT
    || type === CONTROL_CUPBOARD
    || type === CONTROLLER
}
export default isController
