import { takeLatest, put } from 'redux-saga/effects'
import request from '@/helpers/axios'
import { generateInstallationDeleteFile } from '@/constants/apiRoutes'
import {
  DELETE_INSTALLATION_OBJECT_FILE,
} from '@/store/actions/installation'
import {
  successDeleteInstallationObjectFile,
  errorDeleteInstallationObjectFile,
} from '@/store/actions/installation/deleteObjectFile'
import createNotifications from '@/helpers/notification'
import { setGlobalLoading } from '@/store/actions/appSettings'

function* deletePassportPhotoSaga({ payload: { id, fileId } }) {
  try {
    yield put(setGlobalLoading(true))
    yield request({
      url: generateInstallationDeleteFile(id, fileId),
      method: 'delete',
    })
    yield put(successDeleteInstallationObjectFile())
    yield put(setGlobalLoading(false))
  } catch (error) {
    yield put(setGlobalLoading(false))
    yield put(errorDeleteInstallationObjectFile())
    console.log('function*deletePassportPhotoSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(DELETE_INSTALLATION_OBJECT_FILE.REQUEST, deletePassportPhotoSaga)
}
