import styled from 'styled-components'
import {
  STATUS_COLORS,
} from '@/constants/styles/mapsMarks'

export const Container = styled.div`
  width: 100%;
  padding: 10px 15px;
  border-radius: 4px;
  background: ${({ theme }) => theme.colors.backgrounds.default};
  svg {
    cursor: pointer;
  }
`
export const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const Footer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  svg {
    margin-right: 5px;
  }

`
export const PrimaryTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.2rem;
  text-align: left;
  ${({ flex }) => flex && `
    flex: 1;
  `}
  color: ${({ theme, status }) => (status
    ? STATUS_COLORS[status]
    : theme.colors.colors.notation)};
`
export const SecondaryTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 3rem;
  color: ${({ theme }) => theme.colors.colors.notation};
`

export const MainTitle = styled.div`
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.4rem;
  padding: 10px 0px;
  color: ${({ theme }) => theme.colors.colors.notation};
`

export const ContentContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: space-between;
`

export const IconContainer = styled.div`
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-right: 10px;
  .svgFill,
  .svgStroke {
    height: 18px;
  }
`
export const Row = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 5px 0px;
  ${PrimaryTitle}:first-child {
    text-align: left;
    margin-right: 15px;
  }
`
