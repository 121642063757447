import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import moment from 'moment'
import request from '@/helpers/axios'
import {
  GET_ALARM_MANAGER_TABLE,

  ALARM_MANAGER_TABLE,
  SET_STATUS_TYPES,
  SET_EVENTS_TYPES,
} from '@/store/actions/alarmManager'
import { SET_ALARMS_FILTER_VALUES } from '@/store/actions/appSettings'
import { ALARM_MANAGER_TABLE_ENDPOINT } from '@/constants/apiRoutes'
import {
  successAlarmManagerTable,
  errorAlarmManagerTable,
} from '@/store/actions/alarmManager/getTable'
import { getSelectedNode } from '@/store/selectors/appSettings'
import { getTableParameters } from '@/store/selectors/alarmManager'
import createNotifications from '@/helpers/notification'
import get from 'lodash/get'
import { treeHandlers } from 'react-hyper-tree'
import { INSTALLED, UNINSTALLED } from '@/constants/objectStatuses'
import { setGlobalLoading } from '@/store/actions/appSettings'
import { getAlarmsFilters } from '@/store/selectors/appSettings'
import getIsReadByStatus from '@/helpers/alarmsHelper'


// const getIsReadByStatus = (statuses = []) => {
//   if (statuses.length <= 2 && statuses.includes('new')) {
//     return false
//   }
//   if (statuses.length <= 2 && statuses.includes('old')) {
//     return true
//   }

//   return null
// }

function* getAlarmManagerTableSaga() {
  try {
    yield put(setGlobalLoading(true))
    const node = yield select(getSelectedNode)

    const tableParameters = yield select(getTableParameters)
    const { eventTypes, eventStatus, dates, descriptions } = yield select(getAlarmsFilters)

    const response = yield request({
      url: ALARM_MANAGER_TABLE_ENDPOINT({
        params: {
          objectStates: [INSTALLED, UNINSTALLED],
          alertTypes: eventTypes,
          // fromDate: dates.start ? moment(dates.start).format('DD.MM.YYYY') : null,
          // toDate: dates.end ? moment(dates.end).format('DD.MM.YYYY') : null,
          pageNumber: tableParameters.page - 1,
          pageSize: tableParameters.perPage,
          parentId: node.id,
          search: tableParameters.requestQuery || '',
          sort: [`${tableParameters.sort.field}: ${tableParameters.sort.direction}`],
          isRead: getIsReadByStatus(eventStatus),
          occurrenceDateFrom: dates.start ? moment(dates.start).format("DD.MM.yyyy HH:mm") : null,
          occurrenceDateTo: dates.end ? moment(dates.end).format("DD.MM.yyyy HH:mm") : null,
          descriptions: descriptions?.map(item => item.value),
        },
      }),
      method: 'get',
    })

    const {
      content,
      pageable,
    } = response.data
    yield put(setGlobalLoading(false))
    yield put(successAlarmManagerTable({
      data: content,
      totalElements: pageable.totalElements,
      totalPages: pageable.totalPages,
    }))
    const selectedNodePath = get(node, 'original.options.path', null)

    setTimeout(() => {
      const tree = treeHandlers.trees['alarms-tree']
      if (selectedNodePath && tree && tree.handlers) {
        tree.handlers.setSelectedByPath(selectedNodePath)
      }
    })
  } catch (error) {
    yield put(setGlobalLoading(false))
    yield put(errorAlarmManagerTable(error))
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
  return null
}

export default function* root() {
  yield takeLatest(GET_ALARM_MANAGER_TABLE.REQUEST, getAlarmManagerTableSaga)
  yield takeLatest(ALARM_MANAGER_TABLE.SORT, getAlarmManagerTableSaga)
  yield takeLatest(ALARM_MANAGER_TABLE.PER_PAGE, getAlarmManagerTableSaga)
  yield takeLatest(ALARM_MANAGER_TABLE.PAGE, getAlarmManagerTableSaga)
  // yield takeLatest(ALARM_MANAGER_TABLE.SELECTED_FIELDS, getAlarmManagerTableSaga)
  yield takeLatest(ALARM_MANAGER_TABLE.REQUEST_QUERY, getAlarmManagerTableSaga)
  yield takeLatest(SET_STATUS_TYPES, getAlarmManagerTableSaga)
  yield takeLatest(SET_EVENTS_TYPES, getAlarmManagerTableSaga)
  yield takeLatest(SET_ALARMS_FILTER_VALUES, getAlarmManagerTableSaga)
}
