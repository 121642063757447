import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import moment from 'moment/moment'
import {
  SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE_ENDPOINT,
} from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import {
  successScheduleManagerActivityLogTable,
  errorScheduleManagerActivityLogTable
} from '@/store/actions/scheduleManager/getActivityLogTable'
import {
  GET_SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE,
  SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE,
  SET_ACTION
} from '@/store/actions/scheduleManager'
import {
  getAction,
  getActivityLogEndDate,
  getActivityLogStartDate,
  getActivityLogTableParameters
} from '@/store/selectors/scheduleManager/activityLog'
import { getScheduleSelectedNode } from '@/store/selectors/scheduleManager'
import { ROOT } from '@/constants/objectTypes'

function* getScheduleManagerActivityLogTableSaga() {
  try {
    const tableParameters = yield select(getActivityLogTableParameters)
    const startDate = yield select(getActivityLogStartDate)
    const endDate = yield select(getActivityLogEndDate)
    const action = yield select(getAction)
    const scheduleSelectedNode = yield select(getScheduleSelectedNode)

    const response = yield request({
      url: SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE_ENDPOINT({
        params: {
          pageNumber: tableParameters.page - 1,
          pageSize: tableParameters.perPage,
          search: tableParameters.requestQuery || '',
          sort: [`${tableParameters.sort.field}: ${tableParameters.sort.direction}`],
          fromDate: startDate ? moment(startDate).format('DD.MM.YYYY') : null,
          toDate: endDate ? moment(endDate).format('DD.MM.YYYY') : null,
          geoZoneId: !!scheduleSelectedNode.type && scheduleSelectedNode.type !== ROOT ? scheduleSelectedNode.id : null,
          scheduleId: !scheduleSelectedNode.type ? scheduleSelectedNode.id : null,
          userAction: action
        },
      }),
       method: 'get',
    })

    const { content, pageable } = response.data
    yield put(successScheduleManagerActivityLogTable({
      data: content.map(item => {
        return {
          ...item,
          createDateTime: moment(item.createDateTime).format('DD.MM.YYYY HH:mm')
        }
      }),
      totalElements: pageable.totalElements,
    }))

    } catch (error) {
        yield put(errorScheduleManagerActivityLogTable(error))
        const toast = createNotifications()
        toast({
          title: 'Ошибка операции!',
          description: 'Не удалось получить данные журнала действий пользователей.\nПовторите попытку позже.',
          type: 'error',
        })
    }
    return null
}

export default function* root() {
  yield takeLatest(GET_SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE.REQUEST, getScheduleManagerActivityLogTableSaga)
  yield takeLatest(SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE.SORT, getScheduleManagerActivityLogTableSaga)
  yield takeLatest(SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE.PER_PAGE, getScheduleManagerActivityLogTableSaga)
  yield takeLatest(SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE.PAGE, getScheduleManagerActivityLogTableSaga)
  yield takeLatest(SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE.REQUEST_QUERY, getScheduleManagerActivityLogTableSaga)
  yield takeLatest(SET_ACTION, getScheduleManagerActivityLogTableSaga)
}