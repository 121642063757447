import { GET_MAP_STATISTIC } from '@/store/actions/maps/index';

export const requestGetMapStatistic = () => ({
    type: GET_MAP_STATISTIC.REQUEST,
})

export const successGetMapStatistic = (data) => ({
    type: GET_MAP_STATISTIC.SUCCESS,
    payload: data,
})

export const errorGetMapStatistic = (data) => ({
    type: GET_MAP_STATISTIC.ERROR,
    payload: data,
})