import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import {
  ARIA,
  SELECT,
  TEXT
} from '@/constants/semanticNames'
import CREATE_SCHEDULE_NAMES from '@/constants/forms/createSchedule'
import { TIMEZONE_CONFIG } from '@/forms/ReportManagerForm/config'

export const DEVICE_TYPES = [
    {value: 'RM', title: <Lang id={`scheduleManager.form.rm`} />},
    {value: 'SHUNO', title: <Lang id={`scheduleManager.form.shuno`} />},
]

export const PHASES = [
    {value: 'PHASE_1', title: <Lang id={`scheduleManager.form.phase1`} />},
    {value: 'PHASE_2', title: <Lang id={`scheduleManager.form.phase2`} />},
    {value: 'PHASE_3', title: <Lang id={`scheduleManager.form.phase3`} />},
]

export const scheduleConfig = [
    {
        title: <Lang id={'scheduleManager.form.scheduleName'} />,
        type: TEXT,
        selector: CREATE_SCHEDULE_NAMES.NAME,
    },
    {
        title: <Lang id={'scheduleManager.form.timezone'} />,
        type: SELECT,
        selector: CREATE_SCHEDULE_NAMES.TIMEZONE,
        options: TIMEZONE_CONFIG
    },
    {
        title: <Lang id={'scheduleManager.form.deviceType'} />,
        type: SELECT,
        selector: CREATE_SCHEDULE_NAMES.DEVICE_TYPE,
        options: DEVICE_TYPES
    },
    {
        title: <Lang id={'scheduleManager.form.phase'} />,
        type: SELECT,
        selector: CREATE_SCHEDULE_NAMES.PHASE,
        options: PHASES
    },
    {
        title: <Lang id={'scheduleManager.form.comment'} />,
        type: ARIA,
        selector: CREATE_SCHEDULE_NAMES.COMMENT,
        charLimit: 150,
    },
]

export default scheduleConfig