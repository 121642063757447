import {
  put, takeLatest, select,
} from 'redux-saga/effects'
import shortId from 'shortid'
import {
  START_ADD_WIDGET_TO_GENERAL_PAGE,
  updateWidgetsList,
  updateApplicationsList,
} from '@/store/actions/appSettings'
import {
  WIDGET_SHOP_APP,
  WIDGET_SHOP_WIDGET,
} from '@/constants/names'
import { getWidgets, getApplications } from '@/store/selectors/appSettings'
import createNotifications from "@/helpers/notification";

function* addWidget({ payload: { type, widgetType, large } }) {
  try {
    if (widgetType === WIDGET_SHOP_APP) {
      const widgets = yield select(getApplications)
      const newWidget = {
        id: shortId.generate(),
        type,
        widgetType,
        isSettingSet: false,
        isActive: false,
      }
      yield put(updateApplicationsList([...widgets, newWidget]))
      return null
    }
    if (widgetType === WIDGET_SHOP_WIDGET) {
      const widgets = yield select(getWidgets)
      let prevLarge = false
      const hasSmallAfterLarge = widgets.some(({ large: largeWidget }) => {
        if (prevLarge && !largeWidget) {
          return true
        }
        prevLarge = largeWidget
        return false
      })
      const smallWidgets = widgets.filter(({ large: largeWidget }) => !largeWidget)
      const largeWidgets = widgets.filter(({ large: largeWidget }) => largeWidget)
      const newWidget = {
        id: shortId.generate(),
        type,
        large,
        widgetType,
        isSettingSet: false,
        isActive: false,
      }
      if (!hasSmallAfterLarge && !large && smallWidgets.length % 2 !== 0) {
        yield put(updateWidgetsList([...smallWidgets, newWidget, ...largeWidgets]))
      } else {
        yield put(updateWidgetsList([...widgets, newWidget]))
      }
      return null
    }
  } catch (error) {
    console.log('TCL: function*addWidgetToGeneralPage -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
  return null
}

export default function* () {
  yield takeLatest(START_ADD_WIDGET_TO_GENERAL_PAGE, addWidget)
}
