import {
  KULON,
  UNILIGHT,
  BRIZ,
  ONE_SIM,
  MESH,
  MULTIRATE_METER,
  ELECTRIC_METER,
  CONTROLLER,
  CONTACTOR,
  BRANCH_CIRCUIT,
  LAMP,
  PHASE,
  LIGHT_SENSOR,
  PHOTO_SENSOR,
  VOLUME_SENSOR,
  FIRE_SENSOR,
  TRANSFER_SWITCH,
  DMX_DEVICE,
  CONTROLLER_FUSE,
  FUSE,
  CHECKPOINT,
  CURRENT_SENSOR,
  GROUP,

  CONTROL_CUPBOARD,
  METEO,
  ROAD_TEMPERATURE_SENSOR,
  DOOR_OPEN_SENSOR,
  SMOKE_SENSOR,
  BASE_STATION,
  UTILITY_POLE,
  MODULE,
  LINE,
  COMPANY_OBJECT,
  DEVICE,
  SWITCHING_GROUP,
  STREETLIGHT_CONTROL_CABINET,
  STREETLIGHT_GROUP,
  STREETLIGHT,
  WASTE_CONTAINER,
  WITHOUT_ASU,
  LIGHT_FIXTURE,
  LAMP_MODULE,
  PHASE_CIRCUIT_BREAKER,
  COLD_WATER_DEVICE,
  CENTRAL_HEATING_DEVICE,
  SENSOR,
} from '@/constants/instalationPassport/types'
import {
  GEOZONE,
  INTEGRATION,
  OBJECT_ELEMENT,
  PROJECT,
  ROOT
} from '@/constants/objectTypes'
import {
  COMPANY,
  USERS_GROUP,
  GROUP as VIEW_TREE_GROUP,
  GROUPS,
  USER,
  ADMIN,
  TENANT,
  INTEGRATION_SYSTEM,
  INTEGRATION_ZONE,
  CHECK_BOX
} from '@/constants/viewTree'

export default {
  integrationType: {
    [KULON]: 'ACS "KULON"',
    [BRIZ]: 'ACS "BRIZ"',
    [ONE_SIM]: 'ACS "1SIM"',
    [MESH]: 'ACS "MESH"',
    [WITHOUT_ASU]: 'Without ACS',
    [UNILIGHT]: 'ACS "UNILIGHT"',
  },
  objectTypes: {
    [BASE_STATION]: 'Base station',
    [TRANSFER_SWITCH]: 'Transfer switch',
    [GROUP]: 'Group',
    [STREETLIGHT_GROUP]: 'Streetlight group',
    [SWITCHING_GROUP]: 'Switching group',
    [SMOKE_SENSOR]: 'Smoke sensor',
    [VOLUME_SENSOR]: 'Volume sensor',
    [LIGHT_SENSOR]: 'Light sensor',
    [DOOR_OPEN_SENSOR]: 'Door open sensor',
    [FIRE_SENSOR]: 'Fire sensor',
    [CURRENT_SENSOR]: 'Current sensor',
    [ROAD_TEMPERATURE_SENSOR]: 'Road temperature sensor',
    [CONTACTOR]: 'Contactor',
    [CONTROLLER]: 'Controller',
    [CHECKPOINT]: 'Checkpoint',
    [LAMP]: 'Lamp',
    [LINE]: 'Line',
    [METEO]: 'Meteo sensor',
    [MULTIRATE_METER]: 'Multi rate meter',
    [MODULE]: 'Module',
    [LAMP_MODULE]: 'Lamp module',
    [WASTE_CONTAINER]: 'Waste container',
    [COMPANY_OBJECT]: 'Company object',
    [UTILITY_POLE]: 'Utility pole',
    [BRANCH_CIRCUIT]: 'Branch circuit',
    [FUSE]: 'Fuse',
    [CONTROLLER_FUSE]: 'Controller fuse',
    [LIGHT_FIXTURE]: 'Light fixture',
    [COLD_WATER_DEVICE]: 'Cold water device',
    [CENTRAL_HEATING_DEVICE]: 'Central heating device',
    [STREETLIGHT]: 'Street light',
    [DEVICE]: 'Device',
    [PHASE]: 'Phase',
    [PHASE_CIRCUIT_BREAKER]: 'phase circuit breaker',
    [PHOTO_SENSOR]: 'Photo sensor',
    [STREETLIGHT_CONTROL_CABINET]: 'Street light control cabinet',
    [CONTROL_CUPBOARD]: 'Control cupboard',
    [ELECTRIC_METER]: 'Electric meter',
    [DMX_DEVICE]: 'DMX device',
    [SENSOR]: 'Sensor',
    [ROOT]: 'Root geozone',
    [GEOZONE]: 'Geozone',
    [PROJECT]: 'Project',
    [INTEGRATION]: 'Integration',
    [OBJECT_ELEMENT]: 'Object of control',
    [COMPANY]: 'Company',
    [USERS_GROUP]: 'User group',
    [VIEW_TREE_GROUP]: 'Group',
    [GROUPS]: 'Groups',
    [USER]: 'User',
    [ADMIN]: 'Administrator',
    [TENANT]: 'Tenant',
    [INTEGRATION_SYSTEM]: 'System',
    [INTEGRATION_ZONE]: 'Integration',
    [CHECK_BOX]: 'Checkbox',
  },
  integrationGroups: {
    COMMON: 'Common',
    LIGHTNING: 'Lightning',
    HEAT_SUPPLY: 'Heat supply',
    WATER_SUPPLY: 'Water supply',
    POWER: 'Power',
  },
  tree: {
    schedules: 'Schedules',
    objects: 'Objects',
    unknown: 'unknown'
  }
}
