import { takeLatest, put } from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_ONLINE_USERS_URL } from '@/constants/apiRoutes'
import {
  GET_ONLINE_USERS,
} from '@/store/actions/users'
import {
  errorGetOnlineUsers,
  successGetOnlineUsers
} from '@/store/actions/users/getOnlineUsers'
import createNotifications from "@/helpers/notification";

function* getOnlineUsers() {
  try {
    const response = yield request({
      url: GET_ONLINE_USERS_URL,
      method: 'get',
    })
    yield put(successGetOnlineUsers(response.data))
  } catch (error) {
    yield put(errorGetOnlineUsers(error))
    console.log('TCL: function*getUserSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось получить список онлайн пользователей.',
      type: 'error',
    })
  }
  return null
}

export default function* root() {
  yield takeLatest(GET_ONLINE_USERS.REQUEST, getOnlineUsers)
}
