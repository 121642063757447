import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_PASSPORTIZATION_OPTIONS_URL } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { GET_PASSPORTIZATION_OPTIONS } from '@/store/actions/objectsGroups'
import {
  errorGetPassportizationOptions,
  successGetPassportizationOptions
} from '@/store/actions/objectsGroups/getPassportizationOptions'
import { sortOptionsByTitle } from '@/helpers/sortOptionsByTitle'

function* getPassportizationOptions({ payload }) {
  try {
    const { groupId, intl } = payload
    const response = yield request({
      url: GET_PASSPORTIZATION_OPTIONS_URL(groupId),
      method: 'get',
    })

    const formattedData = response.data.map(item => {
      return {
        title: intl.messages[`passport.fields.${item}`],
        value: item
      }
    })

    const sortedData = sortOptionsByTitle(formattedData)

    yield put(successGetPassportizationOptions(sortedData))
  } catch (error) {
    yield put(errorGetPassportizationOptions(error))
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось получить список доступных полей паспорта. \nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* () {
  yield takeLatest(GET_PASSPORTIZATION_OPTIONS.REQUEST, getPassportizationOptions)
}
