import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const ExitIcon = ({ color, onClick }) => {

  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.4161 1.50499C2.00141 0.924566 2.79348 0.599976 3.61765 0.599976H11.0294C11.8536 0.599976 12.6457 0.924566 13.231 1.50499C13.8165 2.0857 14.1471 2.87509 14.1471 3.69998V5.79998C14.1471 6.35226 13.6993 6.79998 13.1471 6.79998C12.5948 6.79998 12.1471 6.35226 12.1471 5.79998V3.69998C12.1471 3.41095 12.0314 3.13206 11.8227 2.92511C11.6137 2.71788 11.3285 2.59998 11.0294 2.59998H3.61765C3.31854 2.59998 3.03335 2.71788 2.82439 2.92511C2.6157 3.13206 2.5 3.41095 2.5 3.69998V16.3C2.5 16.589 2.6157 16.8679 2.82439 17.0748C3.03335 17.2821 3.31854 17.4 3.61765 17.4H11.0294C11.3285 17.4 11.6137 17.2821 11.8227 17.0748C12.0314 16.8679 12.1471 16.589 12.1471 16.3V14.2C12.1471 13.6477 12.5948 13.2 13.1471 13.2C13.6993 13.2 14.1471 13.6477 14.1471 14.2V16.3C14.1471 17.1249 13.8165 17.9143 13.231 18.495C12.6457 19.0754 11.8536 19.4 11.0294 19.4H3.61765C2.79348 19.4 2.00141 19.0754 1.4161 18.495C0.830516 17.9143 0.5 17.1249 0.5 16.3V3.69998C0.5 2.87509 0.830516 2.0857 1.4161 1.50499ZM15.6135 6.14583C16.0024 5.75368 16.6355 5.75103 17.0277 6.13992L20.2041 9.28992C20.3935 9.47769 20.5 9.73331 20.5 9.99998C20.5 10.2666 20.3935 10.5223 20.2041 10.71L17.0277 13.86C16.6355 14.2489 16.0024 14.2463 15.6135 13.8541C15.2246 13.462 15.2272 12.8288 15.6194 12.4399L17.0714 11H6.79412C6.24183 11 5.79412 10.5523 5.79412 9.99998C5.79412 9.44769 6.24183 8.99998 6.79412 8.99998H17.0714L15.6194 7.56003C15.2272 7.17115 15.2246 6.53799 15.6135 6.14583Z" fill="#838D99"/>
    </svg>
  )
}

ExitIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ExitIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ExitIcon)