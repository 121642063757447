import {
  takeLatest, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { SCHEDULE_MANAGER_TREE_ENDPOINT } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { GET_SCHEDULE_MANAGER_TREE } from '@/store/actions/scheduleManager'
import { errorScheduleManagerTree, successScheduleManagerTree } from '@/store/actions/scheduleManager/getTree'
import {
  GEOZONE,
  ROOT,
  SCHEDULE
} from '@/constants/objectTypes'

function* getScheduleManagerTreeSaga() {
  try {
    const response = yield request({
      url: SCHEDULE_MANAGER_TREE_ENDPOINT,
      method: 'get',
    })

    if (response.data.length) {
      const root = response.data.filter(item => !item.parentId)[0]
      const children = response.data.filter(item => item.parentId).map(item => {
        return {
          id: item.id,
          name: item.name,
          parentTreeId: item.parentId,
          children: item.treeSchedules.map(child => {
            return {
              ...child,
              childType: SCHEDULE,
              parentTreeId: item.id
            }
          }),
          type: GEOZONE,
          childrenCount: item.treeSchedules.length
        }
      })
      let schedulesCount = 0
      children.forEach(item => {
        schedulesCount += item.childrenCount
      })
      const tree = [
        {
         id: root.id,
         name: root.name,
         type: ROOT,
         childrenCount: schedulesCount,
         children: children
        }]

      yield put(successScheduleManagerTree(tree))
      }

    } catch (error) {
        yield put(errorScheduleManagerTree(error))
        const toast = createNotifications()
        toast({
          title: 'Ошибка операции!',
          description: 'Не удалось получить данные дерева менеджера расписаний.\nПовторите попытку позже.',
          type: 'error',
        })
    }
}

export default function* root() {
  yield takeLatest(GET_SCHEDULE_MANAGER_TREE.REQUEST, getScheduleManagerTreeSaga)
}