import {
  OK,
  WARNING,
  ERROR,
  INFO,
} from '@/constants/objectStatuses'
import russiaBorders from '@/data/regionCoords/russia'
import {
  COUNTRY,
  CITY,
  ROOT,
  GEOZONE, OBJECT_ELEMENT,
} from '@/constants/objectTypes'
import { LIGHT_FIXTURE } from '@/constants/instalationPassport/types'

export const fakeTree = [
  {
    id: 1,
    internalId: 1,
    name: 'Россия',
    type: ROOT,
    treeNodeType: ROOT,
    childrenCount: 17,
    children: [
      {
        id: 108,
        internalId: 108,
        name: 'Благовещенск',
        type: CITY,
        treeNodeType: GEOZONE,
        childrenCount: 2,
        children: [
          {
            geoZoneId: 108,
            parentId: 108,
            type: LIGHT_FIXTURE,
            treeNodeType: OBJECT_ELEMENT,
            id: 1081,
            name: '44.1',
            status: 'NO_PROBLEM',
            objectState: 'INSTALLED',
            checked: false,
            path: [1, 108],
            children: []
          },
          {
            geoZoneId: 108,
            parentId: 108,
            type: LIGHT_FIXTURE,
            treeNodeType: OBJECT_ELEMENT,
            id: 1082,
            name: '44.2',
            status: 'NO_PROBLEM',
            objectState: 'INSTALLED',
            checked: false,
            path: [1, 108],
            children: []
          },
        ]
      },
      {
        id: 86,
        internalId: 86,
        name: 'Волоколамск',
        type: CITY,
        treeNodeType: GEOZONE,
        childrenCount: 0,
        children: []
      },
      {
        id: 71,
        internalId: 71,
        name: 'Екатеринбург',
        type: CITY,
        treeNodeType: GEOZONE,
        childrenCount: 0,
        children: []
      },
      {
        id: 70,
        internalId: 70,
        name: 'Когалым',
        type: CITY,
        treeNodeType: GEOZONE,
        childrenCount: 0,
        children: []
      },
      {
        id: 95,
        internalId: 95,
        name: 'Курган',
        type: CITY,
        treeNodeType: GEOZONE,
        childrenCount: 0,
        children: []
      },
      {
        id: 79,
        internalId: 79,
        name: 'Магнитогорск',
        type: CITY,
        treeNodeType: GEOZONE,
        childrenCount: 0,
        children: []
      },
      {
        id: 13,
        internalId: 13,
        name: 'Москва',
        type: CITY,
        treeNodeType: GEOZONE,
        childrenCount: 0,
        children: []
      },
      {
        id: 78,
        internalId: 78,
        name: 'Пермь',
        type: CITY,
        treeNodeType: GEOZONE,
        childrenCount: 0,
        children: []
      },
      {
        id: 74,
        internalId: 74,
        name: 'Салехард',
        type: CITY,
        treeNodeType: GEOZONE,
        childrenCount: 0,
        children: []
      },
      {
        id: 72,
        internalId: 72,
        name: 'Сургут',
        type: CITY,
        treeNodeType: GEOZONE,
        childrenCount: 0,
        children: []
      },
      {
        id: 75,
        internalId: 75,
        name: 'Тобольск',
        type: CITY,
        treeNodeType: GEOZONE,
        childrenCount: 0,
        children: []
      },
      {
        id: 73,
        internalId: 73,
        name: 'Троицк',
        type: CITY,
        treeNodeType: GEOZONE,
        childrenCount: 0,
        children: []
      },
      {
        id: 96,
        internalId: 96,
        name: 'Тюмень',
        type: CITY,
        treeNodeType: GEOZONE,
        childrenCount: 0,
        children: []
      },
      {
        id: 77,
        internalId: 77,
        name: 'Уфа',
        type: CITY,
        treeNodeType: GEOZONE,
        childrenCount: 0,
        children: []
      },
      {
        id: 76,
        internalId: 76,
        name: 'Челябинск',
        type: CITY,
        treeNodeType: GEOZONE,
        childrenCount: 0,
        children: []
      },
    ]
  }
]

export const fakeZones = {
  86: {
    id: 86,
    name: 'Волоколамск',
    type: CITY,
    location: [56.0360129, 35.9583358],
    border: null,
    count: 15,
    statistic: {
      [OK]: 0,
      [WARNING]: 0,
      [ERROR]: 0,
      [INFO]: 15,
    },
    zoneSelector: [86],
    pinSelector: 86,
  },
  108: {
    id: 108,
    name: 'Благовещенск',
    type: CITY,
    location: [50.2906, 127.527],
    border: null,
    count: 40,
    statistic: {
      [OK]: 40,
      [WARNING]: 0,
      [ERROR]: 0,
      [INFO]: 0,
    },
    zoneSelector: [108],
    pinSelector: 108,
  },
}

export default {
  1: {
    id: 1,
    name: 'Россия',
    type: COUNTRY,
    location: [60, 90],
    border: russiaBorders,
    count: 0,
    statistic: {
      [OK]: 0,
      [WARNING]: 0,
      [ERROR]: 0,
      [INFO]: 0,
    },
    zoneSelector: [63, 48, 86, 108, 40048, 30048, 30063, 366],
  },
}
