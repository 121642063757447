import styled from 'styled-components'
import SelectField from '@/components/fields/SelectField'
import CheckBoxField from '@/components/fields/CheckBoxField'
import Button from '@/components/blocks/Button'
import {
  WHITE,
  GRAY,
  MINE_SHAFT,
} from '@/constants/styles/defaultTheme'
import { ACCEPT, RESET } from '@/constants/forms/globalFilterForm'

export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.colors.default};
`

export const TitleAndSelectWrapper = styled.div`
  ${({ isMobile }) => isMobile && `
    @media (max-width: 910px) {
      padding-top: 10px;
      display: flex;
      justify-content: space-between
    }
  `}
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 500px;
  gap: 20px;
  margin: auto;
`

export const MultipleCheckBoxWrapper = styled.div`
  display: flex;
  flex-direction: row;
`

export const StyledCheckBox = styled(CheckBoxField)`
  margin-right: 10px;
  cursor: pointer;
`

export const StyledButton = styled(Button)`
  height: 38px;
  box-shadow: ${({ theme }) => theme.colors.shadows.field};
  border-radius: 4px;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  text-transform: none;
  display: flex;
  align-items: center;
  ${({ usage }) => usage === ACCEPT && `
    background: linear-gradient(180deg, #FBBA58 0%, #FF9A02 100%);
    color: ${WHITE};
  `}
  ${({ usage }) => usage === RESET && `
    background: ${GRAY};
    color: ${MINE_SHAFT};
  `}
  &:first-child {
    margin-right: 5px;
  }

  @media (max-width: 910px) {
  ${({ usage }) => usage === ACCEPT && `
    background: #1061DF;
  `}
  width: 165px;
  }
`

export const StyledSelectField = styled(SelectField)`
  width: 100%;
  min-width: 255px;
`


export const FormWrapper = styled.form`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 20px 20px;

  @media (max-width: 910px) {
   display: flex;
   flex-direction: column;
   gap: 10px;

  }
`
