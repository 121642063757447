
import PersonalDataIcon from './Settings/PersonalDataIcon'
import NotificationsIcon from './Settings/NotificationIcon'
import ThemIcon from './Settings/ThemIcon'
import HelpIcon from './Settings/HelpIcon'
import AboutAppIcon from './Settings/AboutAppIcon'
import ExitIcon from './Settings/ExitIcon'
import DeleteIcon from './Widget/DeleteIcon'
import AddIcon from './Widget/AddIcon'
import Draggable from './Widget/Draggable'
import ReportManagerIcon from './pagesIcons/ReportManagerIcon'
import LiveTimeIcon from './pagesIcons/LiveTimeIcon'
import AlarmsManagerIcon from './pagesIcons/AlarmsManagerIcon'
import TimingManagerIcon from './pagesIcons/TimingManagerIcon'
import MonitoringCenterIcon from './pagesIcons/MonitoringCenterIcon'
import ActionsLogIcon from './pagesIcons/ActionsLogIcon'
import PasspotizationIcon from './pagesIcons/PasspotizationIcon'
import ConsumptionAnalysisIcon from './pagesIcons/ConsumptionAnalysisIcon'
import InstallationIcon from './pagesIcons/InstallationIcon'
import CloseNotification from './Widget/closeNotification'
import NotificationIcon from './Widget/NotificationIcon'
import TabBar from './TabBar'
import AddHomeScreen from './AddHomeScreen'
import Add from './Add'
import Logo from './Logo'

export default {
  PersonalDataIcon,
  NotificationsIcon,
  ThemIcon,
  HelpIcon,
  AboutAppIcon,
  ExitIcon,
  DeleteIcon,
  AddIcon,
  Draggable,
  ReportManagerIcon,
  LiveTimeIcon,
  AlarmsManagerIcon,
  TimingManagerIcon,
  MonitoringCenterIcon,
  ActionsLogIcon,
  PasspotizationIcon,
  ConsumptionAnalysisIcon,
  InstallationIcon,
  CloseNotification,
  NotificationIcon,
  TabBar,
  AddHomeScreen,
  Add,
  Logo,
}