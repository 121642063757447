import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import generateWidget from './generateWidget'

export const ACTIVE_CONTROLLER = generateWidget({
  config: {
    id: 'ACTIVE_CONTROLLER',
    title: <Lang id="widgets.controllerAvailability" />,
    type: 'smallWidget',
    units: 'text',
    condition: {
      true: <Lang id="widgets.heActive" />,
      false: <Lang id="widgets.heNotActive" />,
    },
  },
  selectorTemplate: 'active',
})

export const GSM_LEVEL = generateWidget({
  config: {
    id: 'GSM_LEVEL',
    title: <Lang id="widgets.gsmLevel" />,
    type: 'smallWidget',
  },
  selectorTemplate: 'gsmLevel',
})

export const GSM_TYPE = generateWidget({
  config: {
    id: 'GSM_TYPE',
    title: <Lang id="widgets.gsmType" />,
    type: 'smallWidget',
  },
  selectorTemplate: 'gsmType',
})

export const HAS_CONNECTION = generateWidget({
  config: {
    id: 'HAS_CONNECTION',
    title: <Lang id="widgets.hasConnection" />,
    type: 'smallWidget',
    units: 'text',
    condition: {
      true: <Lang id="widgets.online" />,
      false: <Lang id="widgets.offline" />,
    },
  },
  selectorTemplate: 'hasConnection',
})

export const ALARM_STATE = generateWidget({
  config: {
    id: 'ALARM_STATE',
    title: <Lang id="widgets.alarmState" />,
    type: 'smallWidget',
    units: 'text',
    condition: {
      0: <Lang id="widgets.absent" />,
      1: <Lang id="widgets.notActivelyAcknowledged" />,
      2: <Lang id="widgets.notActivelyNotAcknowledged" />,
      3: <Lang id="widgets.activelyAcknowledged" />,
    },
  },
  selectorTemplate: 'alarmState',
})

// export const ACTIVE_LIGHT = generateWidget({
//   config: {
//     id: 'ACTIVE_LIGHT',
//     title: 'Состояние светильника',
//     type: 'smallWidget',
//     units: 'text',
//     condition: {
//       true: 'активен',
//       false: 'не активен',
//     },
//   },
//   selectorTemplate: 'active',
// })

export const PHASE_STATE = generateWidget({
  config: {
    id: 'PHASE_STATE',
    title: <Lang id="widgets.phaseState" />,
    type: 'smallWidget',
    units: 'text',
    condition: {
      true: <Lang id="widgets.itActive" />,
      false: <Lang id="widgets.itNotActive" />,
    },
  },
  selectorTemplate: 'active',
})

export const ELECTRIC_METER_STATE = generateWidget({
  config: {
    id: 'ELECTRIC_METER_STATE',
    title: <Lang id="widgets.counterStatus" />,
    type: 'smallWidget',
    units: 'text',
    condition: {
      true: <Lang id="widgets.heActive" />,
      false: <Lang id="widgets.neNotActive" />,
    },
  },
  selectorTemplate: 'active',
})

export const FIRE_STATE = generateWidget({
  config: {
    id: 'FIRE_STATE',
    title: <Lang id="widgets.fireSensorStatus" />,
    type: 'smallWidget',
    units: 'text',
    condition: {
      0: <Lang id="widgets.norm" />,
      1: <Lang id="widgets.sensorBreak" />,
      2: <Lang id="widgets.heatDetector" />,
      3: <Lang id="widgets.smokeDetector" />,
      4: <Lang id="widgets.shortCircuit" />,
    },
  },
  selectorTemplate: 'state',
})

export const CONTACTOR_ON = generateWidget({
  config: {
    id: 'CONTACTOR_ON',
    title: <Lang id="widgets.contactorIsOn" />,
    type: 'smallWidget',
    units: 'text',
    condition: {
      true: <Lang id="widgets.on" />,
      false: <Lang id="widgets.off" />,
    },
  },
  selectorTemplate: 'isOn',
})

export const ACTIVE_COMMAND = generateWidget({
  config: {
    id: 'CONTACTOR_ON',
    title: <Lang id="widgets.isActiveCommandOn" />,
    type: 'smallWidget',
    units: 'text',
    condition: {
      true: <Lang id="widgets.commandOn" />,
      false: <Lang id="widgets.commandOff" />,
    },
  },
  selectorTemplate: 'isActiveCommandOn',
})

export const MODE = generateWidget({
  config: {
    id: 'MODE',
    title: <Lang id="widgets.mode" />,
    type: 'smallWidget',
    units: 'text',
    condition: {
      0: <Lang id="widgets.local" />,
      1: <Lang id="widgets.remote" />,
    },
  },
  selectorTemplate: 'mode',
})

export const CONTROL_TYPE = generateWidget({
  config: {
    id: 'CONTROL_TYPE',
    title: <Lang id="widgets.controlType" />,
    type: 'smallWidget',
    units: 'text',
    condition: {
      0: <Lang id="widgets.manual" />,
      1: <Lang id="widgets.autonomic" />,
      2: <Lang id="widgets.cascade" />,
    },
  },
  selectorTemplate: 'controlType',
})

export const SWITCH_TIME = generateWidget({
  config: {
    id: 'SWITCH_TIME',
    title: <Lang id="widgets.contactorSwitchTime" />,
    type: 'smallWidget',
    units: 'time',
  },
  selectorTemplate: 'switchTime',
})

export const WILL_SWITCH = generateWidget({
  config: {
    id: 'WILL_SWITCH',
    title: <Lang id="widgets.willContactorSwitchOn" />,
    type: 'smallWidget',
    units: 'text',
    condition: {
      true: <Lang id="widgets.yes" />,
      false: <Lang id="widgets.no" />,
    },
  },
  selectorTemplate: 'willSwitchOn',
})

export const CIRCUIT_STATE = generateWidget({
  config: {
    id: 'CIRCUIT_STATE',
    title: <Lang id="widgets.circuitState" />,
    type: 'smallWidget',
    units: 'text',
    condition: {
      0: <Lang id="widgets.undefined" />,
      1: <Lang id="widgets.itOn" />,
      2: <Lang id="widgets.itOff" />,
      3: <Lang id="widgets.fault" />,
    },
  },
  selectorTemplate: 'state',
})

export const CHECK_POINT_STATE = generateWidget({
  config: {
    id: 'CHECK_POINT_STATE',
    title: <Lang id="widgets.checkPointState" />,
    type: 'smallWidget',
    units: 'text',
    condition: {
      true: <Lang id="widgets.yes" />,
      false: <Lang id="widgets.no" />,
    },
  },
  selectorTemplate: 'state',
})

export const CURRENT_VALUE = generateWidget({
  config: {
    id: 'CURRENT_VALUE',
    title: <Lang id="widgets.currentSensorValue" />,
    type: 'smallWidget',
  },
  selectorTemplate: 'value',
})

export const DMX_ID = generateWidget({
  config: {
    id: 'DMX_ID',
    title: <Lang id="widgets.dmxId" />,
    type: 'smallWidget',
    units: 'string',
  },
  selectorTemplate: 'brizApiId',
})
export const ACTIVE_SCRIPT = generateWidget({
  config: {
    id: 'ACTIVE_SCRIPT',
    title: <Lang id="widgets.isActive" />,
    type: 'smallWidget',
    units: 'text',
    condition: {
      true: <Lang id="widgets.yes" />,
      false: <Lang id="widgets.no" />,
    },
  },
  selectorTemplate: 'isActivated',
})
export const SCRIPT_ACTIVATION_TIME = generateWidget({
  config: {
    id: 'SCRIPT_ACTIVATION_TIME',
    title: <Lang id="widgets.activationTime" />,
    type: 'smallWidget',
    units: 'time',
  },
  selectorTemplate: 'activationTime',
})
export const CURRENT_SCRIPT_NUMBER = generateWidget({
  config: {
    id: 'CURRENT_SCRIPT_NUMBER',
    title: <Lang id="widgets.scenarioNumber" />,
    type: 'smallWidget',
    units: 'string',
  },
  selectorTemplate: 'scenarioNumber',
})
export const DEFERRED_SCRIPT_NUMBER = generateWidget({
  config: {
    id: 'DEFERRED_SCRIPT_NUMBER',
    title: <Lang id="widgets.deferredScenarioNumber" />,
    type: 'smallWidget',
    units: 'string',
  },
  selectorTemplate: 'deferredScenarioNumber',
})
export const DMX_ERROR_CODE = generateWidget({
  config: {
    id: 'DEFERRED_SCRIPT_NUMBER',
    title: <Lang id="widgets.dmxErrorCode" />,
    type: 'smallWidget',
    units: 'string',
  },
  selectorTemplate: 'errorCode',
})
