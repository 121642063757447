export default {
    actionLog: {
        action: 'Action',
        object: 'Object',
        geoZone: 'Geozone',
        date: 'Date',
        time: 'Time',
        problem: 'Malfunctions',
    },
    alarmManager: {
        objectName: 'Object',
        geoZoneName: 'Geozone',
        address: 'Address',
        alertState: 'Alert state',
        description: 'Description',
        occurrenceDate: 'Occurrence date',
        decisionDate: 'Decision date',
        statePeriod: 'State period',
    },
    analyticsAlarms: {
        objectName: 'Object',
        address: 'Address',
        containWarning: 'Warning',
        containFault: 'Refusal',
        description: 'Description',
        currentLifePercent: '% resource use',
        occurrenceDate: 'Occurrence date',
        statePeriod: 'State period',
    },
    consumptionAnalysis : {
        descr: 'Object',
        geoZone: 'Geozone',
        address: 'Address',
        currentPeriod: 'Current period',
        planedValue: 'Planned consumption for the current period (kWh)',
        currentValue: 'Actual consumption for the current period (kWh)',
        planedCurrentDifference: 'Deviation from planned consumption (kWh)',
        prevPeriod: 'Previous period',
        prevValue: 'Actual consumption for the previous period (kWh)',
        difference: 'Difference is fact flow rate from before period (kWh)',
        object: 'Object',
        date: 'Date',
        planT1: '1 (plan)',
        planT2: '2 (plan)',
        factT1: '1 (fact)',
        factT2: '2 (fact)',
        planQ: 'plan',
        factQ: 'fact',
        energy: 'GCal',
        overheat: 'Underheating, GCal',
        rate: 'Rate',
        overpayment: 'Saved',
    },
    faultAnalytics: {
        name: 'Geozone',
        objectCount: 'Installation',
        warningStatePercent: 'Warning rate',
        errorStatePercent: 'Fault rate',
        normalStatePercent: 'Correct rate',
        undefinedStatePercent: 'Undefined rate',
    },
    liveTime: {
        name: 'Object',
        identifier: 'Identifier',
        geoZoneName: 'Geozone',
        address: 'Address',
        workTime: 'Hours worked',
        currentLifePercent: '% resource consumption',
        startDateExploitation: 'Start date',
        dateEndExploitation: 'End date',
        currentDateExploitation: 'Current operating time',
    },
    objectControlStatistic: {
        dateStep: 'Date',
        geoZone: 'Geozone',
        project: 'Project',
        objectInstalled: 'Installed objects',
        OKObject: 'Objects in working order',
        persents: '% of objects in working order'
    },
    reportManager: {
        title: 'Report title',
        geoZoneName: 'Geozone',
        type: 'Report type',
        objectTypes: 'Object type for report',
        schedule: 'Schedule',
        externalRecipients: 'Recipients',
        lastSendDate: 'Date of last report sent',
        nextSendDate: 'Date of next report sending',
    },
    search: {
        name: 'Object',
        installationType: 'Type of equipment',
        geoZone: 'Geozone',
        project: 'Project',
        integrationName: 'Integration',
        region: 'Region',
        city: 'City',
        exploitationDateStart: 'Start date',
        exploitationDateEnd: 'End date',
        exploitationOrganization: 'Operating organization',
        owner: 'Owner',
    },
    serviceDeskMyCity: {
        essenceOfAppeal: 'Essence of the appeal',
        object: 'Object',
        objectType: 'Object type',
        registrationDate: 'Registration date',
        status: 'Status',
        plannedExecutionDate: 'Planned execution date',
        actualDateOfExecution: 'Actual execution date',
    },
    userPage: {
        firstName: 'User',
        groupName: 'Group',
        userStatus: 'Status',
        enter: 'Login',
        sessionTime: 'Session time',
    },
    PPR: {
        object: 'Object',
        address: 'Name of the equipment',
        registerNumber: 'Register number',
        installationType: 'Equipment',
        planDate: 'Date plan SPM',
        factDate: 'Date fact SPM',
    },
    scheduleManager: {
        name: 'Schedule name',
        geoZone: 'Geozone',
        deviceCount: 'Number of connected devices',
        error: 'Description of the error',
        originDate: 'Date of origin',
        errorStatus: 'Current error status',
        resolved: 'Resolved',
        notResolved: 'Not resolved',
        user: 'User (full name)',
        actionDateTime: 'Action date and time',
        action: 'Action',
        comment: 'Comment',
        CREATE: 'Schedule created',
        EDIT: 'Schedule changed',
        DELETE: 'Schedule removed',
    },
}