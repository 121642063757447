import React from 'react'
import LiveTime from '@/components/icons/livetime'
import { FormattedMessage as Lang } from 'react-intl'
import ColoredTextCell from '@/components/blocks/DutyTable/customCells/ColoredTextCell'
import {
  OK,
  WARNING,
  ERROR,
  UNKNOWN,
} from '@/constants/alarms'

export const fields = [
  {
    id: 'name',
    name: <Lang id="tableFields.faultAnalytics.name" />,
    value: 'ASC',
  },
  {
    id: 'objectCount',
    name: <Lang id="tableFields.faultAnalytics.objectCount" />,
    value: 'ASC',
  },
  {
    id: 'warningState',
    name: <Lang id="alarmManager.options.warning" />,
    value: 'ASC',
    customCell: (props) => <ColoredTextCell {...props} type={WARNING} />,
  },
  {
    id: 'warningStatePercent',
    name: <Lang id="tableFields.faultAnalytics.warningStatePercent" />,
    value: 'ASC',
  },
  {
    id: 'errorState',
    name: <Lang id="alarmManager.options.faulty" />,
    value: 'ASC',
    customCell: (props) => <ColoredTextCell {...props} type={ERROR} />,
  },
  {
    id: 'errorStatePercent',
    name: <Lang id="tableFields.faultAnalytics.errorStatePercent" />,
    value: 'ASC',
  },
  {
    id: 'normalState',
    name: <Lang id="alarmManager.options.correct" />,
    value: 'ASC',
    customCell: (props) => <ColoredTextCell {...props} type={OK} />,
  },
  {
    id: 'normalStatePercent',
    name: <Lang id="tableFields.faultAnalytics.normalStatePercent" />,
    value: 'ASC',
  },
  {
    id: 'undefinedState',
    name: <Lang id="alarmManager.options.undefined" />,
    value: 'ASC',
    customCell: (props) => <ColoredTextCell {...props} type={UNKNOWN} />,
  },
  {
    id: 'undefinedStatePercent',
    name: <Lang id="tableFields.faultAnalytics.undefinedStatePercent" />,
    value: 'ASC',
  },
]

// TODO: move to one for all tables
export const exportOptions = [
  {
    name: 'EXCEL',
    value: 'xlsx',
    icon: LiveTime.EXELIcon,
  },
  {
    name: 'PDF',
    value: 'pdf',
    icon: LiveTime.PDFIcon,
  },
]

export default {
  fields,
  exportOptions,
}
