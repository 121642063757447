import { all } from 'redux-saga/effects'

import getTable from './getTable'
import getFile from './getFile'
import getInstallationObject from './getInstallationObject'


export default function* root() {
  yield all([
    getTable(),
    getFile(),
    getInstallationObject(),
  ])
}