import { handleActions } from 'redux-actions'
import {
  LIGHT_THEME_NAME,
  REFRESH_30,
  SKY_SCHEME_NAME,
} from '@/constants/names'
import {
  SELECT_THEME,
  SELECT_FONTSIZE,
  SELECT_SCHEME,
  UPDATE_WIDGETS_LIST,
  UPDATE_APPLICATIONS_LIST,
  SET_SELECTED_NODE,
  UNPIN_NODE,
  MENU_SIDEBAR_PINNED,
  SET_GLOBAL_FILTER_VALUES,
  RESET_GLOBAL_FILTER_VALUES,
  LOCATION_CHANGE,
  SET_FALSE_STATUS_GLOBAL_FILTER,
  SET_TRUE_STATUS_GLOBAL_FILTER,
  SET_MAP_LAYER,
  SWITCH_STATE_NOTIFICATION,
  SET_GLOBAL_FILTER_OPEN,
  SWITCH_STATE_SWIPER,
  SET_FAKE_NODE,
  SET_ALARMS_FILTER_VALUES,
  TOGGLE_RUSSIAN_BORDERS,
  SELECT_REFRESH_RATE,
} from '@/store/actions/appSettings'
import {
  OK,
  WARNING,
  ERROR,
  UNKNOWN,
} from '@/constants/alarms'
import {
  ADD_SERVICE_DESK_INTEGRATION,
  DELETE_SERVICE_DESK_INTEGRATION,
  ADD_TENANT_SERVICE_DESK_INEGRATION,
  USER_CHANGE_SERVICE_DESK,
} from '@/store/actions/serviceDeskMyCity'
import {
  USER_LOGOUT,
  GET_SETTINGS,
  GET_WIDGET_SETTINGS
} from '@/store/actions/user'
import { globalFiltersDefaultValues, eventFiltersDefaultValues } from '@/constants/filters'
import { activeFields } from '@/constants/tablesConfig/alarmManager'
import { OBJECTS } from '@/constants/instalationPassport/types'
import { REQUEST_STATUSES } from '@/constants/requests'
import { SET_PASSPORTIZATION_TABLE_FILTER_VALUES } from '@/store/actions/objectsGroups'


const initialState = {
  appSettings: {
    theme: LIGHT_THEME_NAME,
    scheme: SKY_SCHEME_NAME,
    largeFont: false,
    isMenuSideBarPinned: false,
    mapLayer: OBJECTS,
    russianBorders: false,
    refreshRate: REFRESH_30
  },
  generalPage: {
    widgets: [],
    applications: [],
  },
  pinnedNode: {},
  selectedNode: {},
  fakeNode: {},
  serviceDeskIntegrationStatus: [],
  tenantServiceDeskIntegrationStatus: [],
  globalFilterValues: globalFiltersDefaultValues,
  alarmsManagerFilterValues: {
    eventTypes: [OK, WARNING, ERROR, UNKNOWN],
    eventStatus: ['all', 'new', 'old'],
    dates: {
      start: null,
      end: null,
    },
    activeFields: activeFields,
    descriptions: []
  },
  objectsGroupsPassportizationFilterValues: {
    descriptions: []
  },
  eventFilterValues: eventFiltersDefaultValues,
  statusGlobalFilter: false,
  isOpenNotification: true,
  isActiveSwiper: true,
  isGlobalFilterOpen: false,
  status: REQUEST_STATUSES.NOT_REQUESTED,
}

export const reducer = handleActions(
  {
    [SELECT_THEME]: (state, { payload: { theme } }) => ({
      ...state,
      appSettings: {
        ...state.appSettings,
        theme,
      },
    }),
    [SELECT_SCHEME]: (state, { payload: { scheme } }) => ({
      ...state,
      appSettings: {
        ...state.appSettings,
        scheme,
      },
    }),
    [SELECT_FONTSIZE]: (state, { payload: { large } }) => ({
      ...state,
      appSettings: {
        ...state.appSettings,
        largeFont: large,
      },
    }),
    [SELECT_REFRESH_RATE]: (state, { payload: { refreshRate } }) => ({
      ...state,
      appSettings: {
        ...state.appSettings,
        refreshRate,
      },
    }),
    [MENU_SIDEBAR_PINNED]: (state, { payload: { isOpen } }) => ({
      ...state,
      appSettings: {
        ...state.appSettings,
        isMenuSideBarPinned: isOpen,
      },
    }),
    [UPDATE_WIDGETS_LIST]: (state, { payload: { widgets } }) => ({
      ...state,
      generalPage: {
        ...state.generalPage,
        widgets,
      },
    }),
    [UPDATE_APPLICATIONS_LIST]: (state, { payload: { applications } }) => ({
      ...state,
      generalPage: {
        ...state.generalPage,
        applications,
      },
    }),
    [SET_SELECTED_NODE]: (state, { payload: { node, withoutPinned } }) => ({
      ...state,
      selectedNode: node,
      ...(withoutPinned ? {} : { pinnedNode: node }),
    }),
    [SET_FAKE_NODE]: (state, { payload: { node } }) => ({
      ...state,
      fakeNode: node,
    }),
    [UNPIN_NODE]: (state) => ({
      ...state,
      pinnedNode: {},
    }),
    [SET_GLOBAL_FILTER_VALUES]: (state, { payload }) => ({
      ...state,
      statusGlobalFilter: true,
      globalFilterValues: payload,
    }),
    [RESET_GLOBAL_FILTER_VALUES]: (state) => ({
      ...state,
      statusGlobalFilter: true,
      globalFilterValues: globalFiltersDefaultValues,
    }),
    [SET_FALSE_STATUS_GLOBAL_FILTER]: (state) => ({
      ...state,
      statusGlobalFilter: false,
    }),
    [SET_TRUE_STATUS_GLOBAL_FILTER]: (state) => ({
      ...state,
      statusGlobalFilter: true,
    }),
    [SET_GLOBAL_FILTER_OPEN]: (state) => ({
      ...state,
      isGlobalFilterOpen: !state.isGlobalFilterOpen,
    }),
    [SET_ALARMS_FILTER_VALUES]: (state, { payload }) => ({
      ...state,
      alarmsManagerFilterValues: payload,
    }),
    [SET_PASSPORTIZATION_TABLE_FILTER_VALUES]: (state, { payload }) => ({
      ...state,
      objectsGroupsPassportizationFilterValues: payload,
    }),
    [USER_LOGOUT.SUCCESS]: (state) => ({
      ...state,
      ...initialState,
    }),
    [GET_SETTINGS.SUCCESS]: (state, { payload: { appSettings, generalPage, globalFilterValues, pinnedNode, alarmsManagerFilterValues } }) => ({
      ...state,
      appSettings,
      globalFilterValues: globalFilterValues ? globalFilterValues : globalFiltersDefaultValues,
      pinnedNode,
      generalPage: {
        ...state.generalPage,
        applications: generalPage.applications,
      },
      alarmsManagerFilterValues: alarmsManagerFilterValues,
      statusGlobalFilter: true,
      status: REQUEST_STATUSES.IDLE,

    }),
    [GET_WIDGET_SETTINGS.SUCCESS]: (state, { payload: { serviceDeskIntegrationStatus, generalPage } }) => ({
      ...state,
      generalPage: {
        ...state.generalPage,
        widgets: generalPage.widgets,
     },
     serviceDeskIntegrationStatus
    }),
    [ADD_TENANT_SERVICE_DESK_INEGRATION]: (state, { payload }) => ({
      ...state,
      tenantServiceDeskIntegrationStatus: payload,
    }),
    [DELETE_SERVICE_DESK_INTEGRATION.SUCCESS]: (state, { payload }) => ({
      ...state,
      serviceDeskIntegrationStatus: payload,
    }),
    [ADD_SERVICE_DESK_INTEGRATION.SUCCESS]: (state, { payload }) => ({
      ...state,
      serviceDeskIntegrationStatus: payload,
    }),
    [USER_CHANGE_SERVICE_DESK]: (state, { payload }) => ({
      ...state,
      serviceDeskIntegrationStatus: payload,
    }),
    [LOCATION_CHANGE]: (state) => ({
      ...state,
      selectedNode: {},
    }),
    [SET_MAP_LAYER]: (state, { payload }) => ({
      ...state,
      appSettings: {
        ...state.appSettings,
        mapLayer: payload,
      },
    }),
    [TOGGLE_RUSSIAN_BORDERS]: (state, { payload }) => ({
      ...state,
      appSettings: {
        ...state.appSettings,
        russianBorders: !state.appSettings.russianBorders,
      },
    }),
    [SWITCH_STATE_NOTIFICATION]: (state) =>({
      ...state,
      isOpenNotification: !state.isOpenNotification
    }),
    [SWITCH_STATE_SWIPER]: (state) =>({
      ...state,
      isActiveSwiper: !state.isActiveSwiper
    })
  },
  initialState,
)

export default reducer
