import { CONTROLLER, LIGHT, SHUO } from '@/constants/objectTypes'
import controller from './controller'
import light from './light'
import { BASE_STATION, CONTROL_CUPBOARD, LIGHT_FIXTURE, LAMP_MODULE } from '@/constants/instalationPassport/types';

export default {
  [CONTROLLER]: controller,
  [BASE_STATION]: controller,
  [SHUO]: controller,
  [CONTROL_CUPBOARD]: controller,
  [LIGHT]: light,
  [LIGHT_FIXTURE]: light,
  [LAMP_MODULE]: light,
}
