import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { isUserHasPermission } from '@/helpers/userPermissions'
import BaseWidget from '../BaseWidget'
import WidgetsShopStyled from './styles'

const WidgetsShop = ({
  onAddWidget, widgets, user, type, addedWidgets, app = false
}) => (
  <WidgetsShopStyled>
    {widgets.map((widget) => {
      if (
          (widget.applicationId && !isUserHasPermission(user, widget.applicationId)) || (app && addedWidgets.some(item => item.type === widget.id))
      ) {
        return null
      }
      return (
        <BaseWidget
          key={widget.id}
          {...widget}
          widgetType={type}
          onAddWidget={onAddWidget}
          addedWidgets={addedWidgets}
        />
      )
    })}
  </WidgetsShopStyled>
)

WidgetsShop.propTypes = {
  onAddWidget: pt.func,
  type: pt.string,
  widgets: pt.arrayOf(pt.object),
  addedWidgets: pt.arrayOf(pt.object),
}
WidgetsShop.defaultProps = {
  onAddWidget: noop,
  type: null,
  widgets: [],
  addedWidgets: [],
}

export default WidgetsShop
