export default {
    titles: {
        main: 'Schedule manager',
        search: 'Find a schedule',
        create: 'Create\nschedule',
        edit: 'Edit\nschedule',
        add: 'Add schedule',
        schedule: 'Schedule manager',
        calendar: 'Calendar',
        scheduleFailureLog: 'ScheduleManager failure log',
        userActivityLog: 'User activity log',
        emptyScheduleText: 'Select a schedule from the list or create a new one',
        errorStatus: 'Error Status',
        action: 'Action',
    },
    tooltip: {
        edit: 'Edit',
        delete: 'Delete',
        deleteSelected: 'Delete selected',
        download: 'Download schedule file',
        chart: 'Show on chart',
        addTask: 'Add task',
    },
    dateInterval: {
        set: 'Build',
        reset: 'Reset',
    },
    form: {
        name: 'Name',
        temperature: 'Air temperature (°C)',
        pressure: 'Atmospheric pressure (mmHg)',
        note: 'Description / comment',
        newSchedule: 'New schedule',
        scheduleFile: 'Upload schedule file',
        scheduleName: 'ScheduleManager name*',
        timezone: 'Timezone',
        comment: 'Description/comment',
        select: 'Select',
        delete: 'Delete schedule',
        addButton: 'Add',
        tasks: 'Tasks',
        task: 'Task',
        taskName: 'Task name',
        deviceType: 'Device type',
        objectsGroup: 'Group of objects',
        phase: 'Phase',
        phase1: 'Phase 1 ',
        phase2: 'Phase 2 ',
        phase3: 'Phase 3 ',
        repeat: 'Repeat',
        startDate: 'Start date',
        endDate: 'End date',
        hours: 'H:',
        minutes: 'Min:',
        percent: 'Lvl(%):',
        condition: 'Cond.:',
        mode: 'Mode',
        on: 'On',
        off: 'Off',
        actions: 'Time and action',
        workingHours: 'Total working hours in the task',
        dailyWorkingHours: 'Total working hours per day',
        scheduleWorkingHours: 'Working hours in the schedule total',
        addAction: 'Add action',
        saveTask: 'Save task',
        rm: 'RM',
        shuno: 'SHUNO',
        commentText: 'Comment text...',
    },
    popup: {
        yes: 'Yes',
        no: 'No',
        accept: 'Accept',
        save: 'Save',
        cancel: 'Cancel',
        attention: 'Attention!',
        executeImpossible: 'Impossible to execute!',
        deleteTaskMessage: 'Are you sure you want to delete tasks?',
        cancelCreateScheduleMessage: 'Are you sure you want to cancel the schedule creation?',
        cancelEditScheduleMessage: 'Are you sure you want to cancel your schedule changes?',
        createScheduleErrorMessage: 'To create a new schedule, you must select a geofence',
        createScheduleValidationErrorMessage: 'To create a schedule, you must set all required parameters',
        editScheduleValidationErrorMessage: 'To save changes to the schedule, you must set all required parameters',
        createSchedule: 'Create new schedule?',
        createScheduleMessage: 'Enter a comment justification for creating a new schedule',
        editSchedule: 'Save changes to schedule?',
        editScheduleMessage: 'Enter a comment justifying changes to the schedule',
        deleteSchedule: 'Delete schedule?',
        deleteScheduleMessage: 'Enter a comment justification for deleting the schedule',
    },
    time: 'Time',
    level: 'Level',
    state: 'State',
    dimmingLevel: 'Dimming level',
    shunoWork: 'SHUNO work schedule',
    graphTitle: 'Daily schedule',
    firstGraphTitle: 'Schedule for the first day',
    secondGraphTitle: 'Schedule for the second and subsequent days',
}
