import { GET_NODE_STATISTIC } from './index';

export const requestGetNodeStatistic = (payload) => ({
    type: GET_NODE_STATISTIC.REQUEST,
    payload
})

export const successGetNodeStatistic = (payload) => ({
    type: GET_NODE_STATISTIC.SUCCESS,
    payload
})

export const errorGetNodeStatistic = (payload) => ({
    type: GET_NODE_STATISTIC.ERROR,
    payload
})