import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import UserWithCheckBoxAndPicture from '@/components/blocks/TableSellToType/UserWithCheckBoxAndPicture'
import TableSellToType from '@/components/blocks/TableSellToType'

export const fields = [
  {
    id: 'firstName',
    name: <Lang id="tableFields.userPage.firstName" />,
    value: 'ASC',
    customCell: UserWithCheckBoxAndPicture,
    withSelectAll: true,
  },
  {
    id: 'groupName',
    name: <Lang id="tableFields.userPage.groupName" />,
    value: 'ASC',
  },
  {
    id: 'userStatus',
    name: <Lang id="tableFields.userPage.userStatus" />,
    value: 'ASC',
    customCell: TableSellToType.status,
    showSortButtons: false,
  },
  {
    id: 'enter',
    name: <Lang id="tableFields.userPage.enter" />,
    value: 'ASC',
    showSortButtons: false,
  },
  {
    id: 'sessionTime',
    name: <Lang id="tableFields.userPage.sessionTime" />,
    value: 'ASC',
    showSortButtons: false,
  },
]

export const activeFields = [
  'firstName',
  'groupName',
  'userStatus',
  'enter',
  'sessionTime',
]
