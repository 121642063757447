import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const UserIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd"
        d="M8 -0.000411987C9.06087 -0.000411987 10.0783 0.421015 10.8284 1.17116C11.5786 1.92131 12 2.93872 12 3.99959C12 5.06045 11.5786 6.07787 10.8284 6.82801C10.0783 7.57816 9.06087 7.99959 8 7.99959C6.93913 7.99959 5.92172 7.57816 5.17157 6.82801C4.42143 6.07787 4 5.06045 4 3.99959C4 2.93872 4.42143 1.92131 5.17157 1.17116C5.92172 0.421015 6.93913 -0.000411987 8 -0.000411987ZM8 9.99959C12.42 9.99959 16 11.7896 16 13.9996V15.9996H0V13.9996C0 11.7896 3.58 9.99959 8 9.99959Z" fill={fill} className="svgFill"/>
    </svg>
  )
}

UserIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

UserIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(UserIcon)
