import {
  takeLatest, select, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_TENANT_GROUPS_URL } from '@/constants/apiRoutes'
import { GROUP } from '@/constants/viewTree'
import { GET_TENANT_GROUPS } from '@/store/actions/users'
import { getGroups, getTenants } from '@/store/selectors/users'
import {
  ERROR,
} from '@/constants/objectStatuses'
import {
  responseGetTenantGroups,
  errorGetTenantGroups,
} from '@/store/actions/users/group/getTenantGroup'
import {
  responseGetAllTenants,
} from '@/store/actions/users/tenant/getAllTenants'
import createNotifications from "@/helpers/notification";

function* getTenantGroupsSaga({ payload: id }) {
  try {
    const response = yield request({
      url: GET_TENANT_GROUPS_URL,
      method: 'get',
    })
    if (response) {
      const newTenantChildren = response.data.map((element) => ({
        type: GROUP,
        ...element,
        name: element.blocked ? `${element.name} (заблок.)` : element.name,
        status: element.blocked ? ERROR : null,
        value: element.id,
        title: element.blocked ? `${element.name} (заблок.)` : element.name,
        children: element.usersAmount > 0 ? [{}] : [],
      }))
      const groups = yield select(getGroups)
      const tenants = yield select(getTenants)
      const updatedTenants = tenants.map((element) => {
        if (element.id !== id) {
          return element
        }
        return {
          ...element,
          toggled: false,
          loading: false,
          children: [],
        }
      })
      if (response.error) {
        return null
      }
      const newGroups = {
        ...groups,
        [id]: newTenantChildren,
      }
      yield put(responseGetTenantGroups(newGroups))
      yield put(responseGetAllTenants(updatedTenants))
    } else {
      // error handler
    }
  } catch (error) {
    const tenants = yield select(getTenants)
    const updatedTenants = tenants.map((element) => {
      if (element.id !== id) {
        return element
      }
      return {
        ...element,
        toggled: false,
        loading: false,
        children: [],
      }
    })
    yield put(errorGetTenantGroups(updatedTenants))
    console.log('TCL: function*getGroupSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_TENANT_GROUPS.REQUEST, getTenantGroupsSaga)
}
