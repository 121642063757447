import { handleActions } from 'redux-actions'
import config from '@/data/syncronization'

import {
  UPDATE_CUSTOM_INTEGRATION_SCHEME,
  SET_SYNC,
  SET_MODE,
  SELECT_INTEGRATION,
  SELECT_SYSTEM,
  GET_INTEGRATIONS,
  GET_INTEGRATION,
  CREATE_INTEGRATION,
  UPDATE_INTEGRATION,
  REPEAT_INTEGRATION,
} from '@/store/actions/integrations'

import {
  REQUEST_STATUSES,
  NOT_REQUESTED,
  IDLE,
  PENDING,
  ERROR,
} from '@/constants/requests'

import {
  KULON,
  BRIZ,
  ATM,
  MESH,
  UNILIGHT,
} from '@/constants/integrations'

const {
  integrationScheme,
  integrationOptions,
} = config

const initialState = {
  status: REQUEST_STATUSES[NOT_REQUESTED],
  syncInProgress: false,
  error: null,
  selectedSystemId: null,
  mode: 'CONNECTED_INTEGRATION',
  integrationScheme,
  integrationOptions,
  integrationSchemeById: integrationScheme,
  integrationsIds: [],
  selectedIntegration: {},
  form: {},
  integrations: {
    [KULON]: {},
    [BRIZ]: {},
    [ATM]: {},
    [MESH]: {},
    [UNILIGHT]: {},
  },
}

export const reducer = handleActions(
  {
    [GET_INTEGRATIONS.BEGIN]: (state) => ({
      ...state,
      status: REQUEST_STATUSES[PENDING],
    }),
    [GET_INTEGRATIONS.SUCCESS]: (state, { payload: { data, ids  } }) => ({
      ...state,
      integrations: data,
      integrationsIds: ids,
      status: REQUEST_STATUSES[IDLE],
    }),
    [GET_INTEGRATIONS.ERROR]: (state, { payload }) => ({
      ...state,
      status: REQUEST_STATUSES[ERROR],
        error: payload,
    }),

    [GET_INTEGRATION.BEGIN]: (state) => ({
      ...state,
      status: REQUEST_STATUSES[PENDING],
    }),
    [GET_INTEGRATION.SUCCESS]: (state, { payload }) => ({
      ...state,
      integrations: payload,
      status: REQUEST_STATUSES[IDLE],
    }),
    [GET_INTEGRATION.ERROR]: (state, { payload }) => ({
      ...state,
      status: REQUEST_STATUSES[ERROR],
        error: payload,
    }),

    [CREATE_INTEGRATION.REQUEST]: (state, { payload: form }) => ({
      ...state,
      form,
      status: REQUEST_STATUSES[PENDING],
    }),
    [CREATE_INTEGRATION.SUCCESS]: (state, { payload: form }) => ({
      ...state,
      form,
      status: REQUEST_STATUSES[IDLE],
    }),
    [CREATE_INTEGRATION.ERROR]: (state, { payload }) => ({
      ...state,
      error: payload,
      status: REQUEST_STATUSES[ERROR],
    }),

    [UPDATE_INTEGRATION.REQUEST]: (state, { payload: form }) => ({
      ...state,
      status: REQUEST_STATUSES[PENDING],
      form,
    }),
    [UPDATE_INTEGRATION.SUCCESS]: (state, { payload: form }) => ({
      ...state,
      status: REQUEST_STATUSES[IDLE],
      form,
    }),
    [UPDATE_INTEGRATION.ERROR]: (state, { payload }) => ({
      ...state,
      syncInProgress: false,
      error: payload,
      status: REQUEST_STATUSES[ERROR],
    }),

    [REPEAT_INTEGRATION.REQUEST]: (state, { payload: { form } }) => ({
      ...state,
      status: REQUEST_STATUSES[PENDING],
      syncInProgress: true,
      form,
    }),
    [REPEAT_INTEGRATION.SUCCESS]: (state) => ({
      ...state,
      status: REQUEST_STATUSES[IDLE],
      syncInProgress: false,
    }),
    [REPEAT_INTEGRATION.ERROR]: (state) => ({
      ...state,
      status: REQUEST_STATUSES[ERROR],
      syncInProgress: false,
    }),

    // common
    [SELECT_SYSTEM]: (state, { payload }) => ({
      ...state,
      selectedSystemId: payload,
    }),
    [SELECT_INTEGRATION]: (state, { payload }) => ({
      ...state,
      selectedIntegration: payload,
    }),
    [SET_MODE]: (state, { payload }) => ({
      ...state,
      mode: payload,
    }),
    [SET_SYNC]: (state, { payload }) => ({
      ...state,
      syncInProgress: payload,
    }),
    [UPDATE_CUSTOM_INTEGRATION_SCHEME]: (state, { payload }) => ({
      ...state,
      integrationSchemeById: payload,
    }),
    [REPEAT_INTEGRATION.SUCCESS]: (state) => ({
      ...state,
      status: REQUEST_STATUSES[IDLE],
      syncInProgress: false,
    }),
    [REPEAT_INTEGRATION.ERROR]: (state, { payload }) => ({
      ...state,
      status: REQUEST_STATUSES[IDLE],
      syncInProgress: false,
      error: payload,
    }),
  },
  initialState,
)

export default reducer
