import {
  COUNTRY,
  REGION,
  CITY, OBJECT_ELEMENT,
} from '@/constants/objectTypes'
import { LIGHT_FIXTURE } from '@/constants/instalationPassport/types'

export const mash = [
  // first mash
  [
    // line
    [
      [57.107852935791016, 65.57592010498047], // from - 10.1
      [57.1077880859375, 65.57604217529297], // to - 11.2
    ],
    [
      [57.107967376708984, 65.57612609863281], // from - 13.2
      [57.1077880859375, 65.57604217529297], // to - 11.2
    ],
    [
      [57.107906341552734, 65.57626342773438], // from - 10.2
      [57.107967376708984, 65.57612609863281], // to - 13.2
    ],
    [
      [57.10808563232422, 65.57625579833984], // from - 11.1
      [57.1077880859375, 65.57604217529297], // to - 11.2
    ],
    [
      [57.1080436706543, 65.57638549804688], // from - 12.1
      [57.10822677612305, 65.57664489746094], // to - 12.2
    ],
    [
      [57.10808563232422, 65.57625579833984], // from - 11.1
      [57.10822677612305, 65.57664489746094], // to - 12.2
    ],
  ],
  [
    // line
    [
      [57.107852935791016, 65.57592010498047], // from - 10.1
      [57.1080436706543, 65.57638549804688], // from - 12.1
    ],
    [
      [57.107852935791016, 65.57592010498047], // from - 10.1
      [57.1077880859375, 65.57604217529297], // to - 11.2
    ],
    [
      [57.107906341552734, 65.57626342773438], // from - 10.2
      [57.107967376708984, 65.57612609863281], // to - 13.2
    ],
    [
      [57.10808563232422, 65.57625579833984], // from - 11.1
      [57.1077880859375, 65.57604217529297], // to - 11.2
    ],
    [
      [57.10822677612305, 65.57664489746094], // to - 12.2
      [57.1077880859375, 65.57604217529297], // to - 11.2
    ],
    [
      [57.1080436706543, 65.57638549804688], // from - 12.1
      [57.1077880859375, 65.57604217529297], // to - 11.2
    ],
    [
      [57.10808563232422, 65.57625579833984], // from - 11.1
      [57.107906341552734, 65.57626342773438], // from - 10.2
    ],
  ],
  [
    // line
    [
      [57.107852935791016, 65.57592010498047], // from - 10.1
      [57.1077880859375, 65.57604217529297], // to - 11.2
    ],
    [
      [57.107967376708984, 65.57612609863281], // from - 13.2
      [57.1077880859375, 65.57604217529297], // to - 11.2
    ],
    [
      [57.107906341552734, 65.57626342773438], // from - 10.2
      [57.107967376708984, 65.57612609863281], // to - 13.2
    ],
    [
      [57.10808563232422, 65.57625579833984], // from - 11.1
      [57.1077880859375, 65.57604217529297], // to - 11.2
    ],
    [
      [57.10808563232422, 65.57625579833984], // from - 11.1
      [57.10822677612305, 65.57664489746094], // to - 12.2
    ],
    [
      [57.1080436706543, 65.57638549804688], // from - 12.1
      [57.1077880859375, 65.57604217529297], // to - 11.2
    ],
    [
      [57.10808563232422, 65.57625579833984], // from - 11.1
      [57.107906341552734, 65.57626342773438], // from - 10.2
    ],
  ],
]

export const mashDisplayElements = [
  {
    geoZoneId: 63,
    parentId: 63,
    parentTreeId: 63,
    type: LIGHT_FIXTURE,
    installationType: LIGHT_FIXTURE,
    elementType: OBJECT_ELEMENT,
    mash: true,
    id: 2888,
    name: '44.1',
    status: 'NO_PROBLEM',
    telemetryState: 'NO_PROBLEM',
    objectState: 'INSTALLED',
    point: {
      latitude: 57.107852935791016,
      longitude: 65.57592010498047,
    },
    location: [57.107852935791016, 65.57592010498047],
    path: [1, 564, 63],
  },
  {
    geoZoneId: 63,
    parentId: 63,
    parentTreeId: 63,
    type: LIGHT_FIXTURE,
    installationType: LIGHT_FIXTURE,
    elementType: OBJECT_ELEMENT,
    mash: true,
    id: 2970,
    name: '43.2',
    status: 'NO_PROBLEM',
    telemetryState: 'NO_PROBLEM',
    objectState: 'INSTALLED',
    point: {
      latitude: 57.107906341552734,
      longitude: 65.57626342773438,
    },
    location: [57.107906341552734, 65.57626342773438],
    path: [1, 564, 63],
  },
  {
    geoZoneId: 63,
    parentId: 63,
    parentTreeId: 63,
    type: LIGHT_FIXTURE,
    installationType: LIGHT_FIXTURE,
    elementType: OBJECT_ELEMENT,
    mash: true,
    id: 2471,
    name: '42.1',
    status: 'NO_PROBLEM',
    telemetryState: 'NO_PROBLEM',
    objectState: 'INSTALLED',
    point: {
      latitude: 57.10808563232422,
      longitude: 65.57625579833984,
    },
    location: [57.10808563232422, 65.57625579833984],
    path: [1, 564, 63],
  },
  {
    geoZoneId: 63,
    parentId: 63,
    parentTreeId: 63,
    type: LIGHT_FIXTURE,
    installationType: LIGHT_FIXTURE,
    elementType: OBJECT_ELEMENT,
    mash: true,
    id: 2812,
    name: '44.2',
    status: 'NO_PROBLEM',
    telemetryState: 'NO_PROBLEM',
    objectState: 'INSTALLED',
    point: {
      latitude: 57.1077880859375,
      longitude: 65.57604217529297,
    },
    location: [57.1077880859375, 65.57604217529297],
    path: [1, 564, 63],
  },
  {
    geoZoneId: 63,
    parentId: 63,
    parentTreeId: 63,
    type: LIGHT_FIXTURE,
    installationType: LIGHT_FIXTURE,
    elementType: OBJECT_ELEMENT,
    mash: true,
    id: 2627,
    name: '42.2',
    status: 'NO_PROBLEM',
    telemetryState: 'NO_PROBLEM',
    objectState: 'INSTALLED',
    point: {
      latitude: 57.1080436706543,
      longitude: 65.57638549804688,
    },
    location: [57.1080436706543, 65.57638549804688],
    path: [1, 564, 63],
  },
  {
    geoZoneId: 63,
    parentId: 63,
    parentTreeId: 63,
    type: LIGHT_FIXTURE,
    installationType: LIGHT_FIXTURE,
    elementType: OBJECT_ELEMENT,
    mash: true,
    id: 2566,
    name: '41.2',
    status: 'NO_PROBLEM',
    telemetryState: 'NO_PROBLEM',
    objectState: 'INSTALLED',
    point: {
      latitude: 57.10822677612305,
      longitude: 65.57664489746094,
    },
    location: [57.10822677612305, 65.57664489746094],
    path: [1, 564, 63],
  },
  {
    geoZoneId: 63,
    parentId: 63,
    parentTreeId: 63,
    type: LIGHT_FIXTURE,
    installationType: LIGHT_FIXTURE,
    elementType: OBJECT_ELEMENT,
    mash: true,
    id: 2562,
    name: '43.1',
    status: 'NO_PROBLEM',
    telemetryState: 'NO_PROBLEM',
    objectState: 'INSTALLED',
    point: {
      latitude: 57.107967376708984,
      longitude: 65.57612609863281,
    },
    location: [57.107967376708984, 65.57612609863281],
    path: [1, 564, 63],
  },
]

const config = {
  id: 1,
  name: 'Россия',
  type: COUNTRY,
  point: {
    latitude: 60,
    longitude: 90,
  },
  children: [
    {
      id: 564,
      name: 'Уральский федеральный округ',
      type: REGION,
      point: {
        latitude: 56.44288053837966,
        longitude: 65.35090050933302,
      },
      path: [1],
      children: [
        {
          id: 63,
          name: 'Тюмень',
          type: CITY,
          point: {
            latitude: 57.153534,
            longitude: 65.542274,
          },
          path: [1, 564],
          children: [
            {
              geoZoneId: 63,
              parentId: 63,
              type: LIGHT_FIXTURE,
              treeNodeType: OBJECT_ELEMENT,
              mash: true,
              id: 2888,
              name: '44.1',
              status: 'NO_PROBLEM',
              telemetryState: 'NO_PROBLEM',
              objectState: 'INSTALLED',
              point: {
                latitude: 57.107852935791016,
                longitude: 65.57592010498047,
              },
              path: [1, 564, 63],
            },
            {
              geoZoneId: 63,
              parentId: 63,
              type: LIGHT_FIXTURE,
              treeNodeType: OBJECT_ELEMENT,
              mash: true,
              id: 2970,
              name: '43.2',
              status: 'NO_PROBLEM',
              telemetryState: 'NO_PROBLEM',
              objectState: 'INSTALLED',
              point: {
                latitude: 57.107906341552734,
                longitude: 65.57626342773438,
              },
              path: [1, 564, 63],
            },
            {
              geoZoneId: 63,
              parentId: 63,
              type: LIGHT_FIXTURE,
              treeNodeType: OBJECT_ELEMENT,
              mash: true,
              id: 2471,
              name: '42.1',
              status: 'NO_PROBLEM',
              telemetryState: 'NO_PROBLEM',
              objectState: 'INSTALLED',
              point: {
                latitude: 57.10808563232422,
                longitude: 65.57625579833984,
              },
              path: [1, 564, 63],
            },
            {
              geoZoneId: 63,
              parentId: 63,
              type: LIGHT_FIXTURE,
              treeNodeType: OBJECT_ELEMENT,
              mash: true,
              id: 2812,
              name: '44.2',
              status: 'NO_PROBLEM',
              telemetryState: 'NO_PROBLEM',
              objectState: 'INSTALLED',
              point: {
                latitude: 57.1077880859375,
                longitude: 65.57604217529297,
              },
              path: [1, 564, 63],
            },
            {
              geoZoneId: 63,
              parentId: 63,
              type: LIGHT_FIXTURE,
              treeNodeType: OBJECT_ELEMENT,
              mash: true,
              id: 2627,
              name: '42.2',
              status: 'NO_PROBLEM',
              telemetryState: 'NO_PROBLEM',
              objectState: 'INSTALLED',
              point: {
                latitude: 57.1080436706543,
                longitude: 65.57638549804688,
              },
              path: [1, 564, 63],
            },
            {
              geoZoneId: 63,
              parentId: 63,
              type: LIGHT_FIXTURE,
              treeNodeType: OBJECT_ELEMENT,
              mash: true,
              id: 2566,
              name: '41.2',
              status: 'NO_PROBLEM',
              telemetryState: 'NO_PROBLEM',
              objectState: 'INSTALLED',
              point: {
                latitude: 57.10822677612305,
                longitude: 65.57664489746094,
              },
              path: [1, 564, 63],
            },
            {
              geoZoneId: 63,
              parentId: 63,
              type: LIGHT_FIXTURE,
              treeNodeType: OBJECT_ELEMENT,
              mash: true,
              id: 2562,
              name: '43.1',
              status: 'NO_PROBLEM',
              telemetryState: 'NO_PROBLEM',
              objectState: 'INSTALLED',
              point: {
                latitude: 57.107967376708984,
                longitude: 65.57612609863281,
              },
              path: [1, 564, 63],
            },
          ],
        },
      ],
    },
  ],
}

export default config
