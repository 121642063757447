import styled from 'styled-components'
import {
  LIGHT_THEME_NAME,
} from '@/constants/names'
import { WHITE_SUN, WHITE_SUN_GRADIENT } from '@/constants/styles/defaultTheme'

export const WidgetContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  box-shadow: -8px 12px 18px ${({ theme }) => theme.colors.shadows.widget};
  border: 1px solid ${WHITE_SUN};
  border-radius: 10px;
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
        background: ${WHITE_SUN_GRADIENT};
      `
    }
    return `
      background: ${theme.colors.backgrounds.header};
    `
  }}
  @media (max-width: 910px) {
    width: 100%;
  }
`

export const TooltipWrapper = styled.div`
  display: none;
  ${WidgetContainer}:hover && {
    display: block
  }
`

export const WidgetHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const WidgetTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;
`

export const WidgetHeaderIcon = styled.div`
  margin-bottom: auto;
  cursor: pointer;

  ${({ withMargin }) => withMargin && `
    margin: 5px;
    margin-bottom: auto;
  `}
`

export const WidgetTitle = styled.div`
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2.7rem;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.colors.default};

  ${({ small }) => small && `
    font-size: 1.2rem;
    line-height: 1.7rem;
  `}
`

export const ChartContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ChartDescriptionsRow = styled.div`
  display: flex;
  flex-wrap: wrap;
`

export const ChartDescriptionMarker = styled.div`
  width: 9px;
  height: 9px;
  border-radius: 50%;
  background: ${({ color }) => color};
  margin-right: 5px;
`

export const ChartDescription = styled.div`
  width: 50%;
  font-size: 1.2rem;
  line-height: 1.7rem;
  display: flex;
  align-items: center;
`
