export default {
  formTitles: {
    ASU: 'АСУ:',
    integration: 'Интеграция:',
    objectTypes: 'Типы объектов:',
    process: 'Городские процессы:',
    objectState: 'Состояние объекта:',
    owner: 'Собственник:',
    exploitationOrganization: 'Эксплуатирующая организация:',
  },
  selectPlaceholders: {
    ASU: 'Все АСУ',
    integration: 'Все интеграции',
    objectTypes: 'Все типы объектов',
    process: 'Все городские процессы',
    exploitationOrganizations: 'Все организации',
    owners: 'Все собственники',
  },
  buttons: {
    reset: 'Сбросить',
    accept: 'Применить',
  },
}
