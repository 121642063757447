import React, { useState } from 'react'
import Button from '@/components/blocks/Button'
import { FormattedMessage as Lang } from 'react-intl'
import renderHTML from 'react-render-html';
import moment from 'moment'



import {
  Container,
  Header,
  Title,
  Footer,
  ButtonWrapper,
  SideBar,
  NumberVersion,
  Content,
  Descriptions,
  DescriptionsTitle,
} from './styles'

const VersionControl = ({
  setPopupOpen,
  data,
}) => {
 const [activeVersion, setActiveVersion] = useState(data[0])
  return (
    <Container>
      <Header>
        <Title><Lang id="constants.versionControl"/></Title>
      </Header>
      <Content>
      <SideBar>
        {data.map((version) => {
          return (
            <NumberVersion active={activeVersion.id === version.id} onClick={()=> setActiveVersion(version)}>
              {`V.${version.name} ${moment(version.createdAt).format('DD.MM.YYYY')}`}
            </NumberVersion>
          )
        })}
      </SideBar>
      <Descriptions>
        <DescriptionsTitle>
        {`V.${activeVersion.name} ${moment(activeVersion.createdAt).format('DD.MM.YYYY')}`}
        </DescriptionsTitle>
        {renderHTML(data.filter((version) => version.id === activeVersion.id)[0].content)}
      </Descriptions>
      </Content>
      <Footer>
        <ButtonWrapper>
          <Button onClick={() => setPopupOpen(false)}>
            <Lang id="buttons.close" />
          </Button>
        </ButtonWrapper>
      </Footer>
    </Container>
  )
}

VersionControl.defaultProps = {

}

VersionControl.propTypes = {

}

export default VersionControl
