import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import {
  MOBILE_SETTINGS_URL,
  DASHBOARD_URL,
  MOBILE_APPLICATIONS_URL
} from '@/constants/routes'
import {
  Container,
  Title,
  ContainerIcon,
  IconWreper,
  StyledLink,
  LinkWraper
} from './styles'
import CoreIcons from '@/components/icons/core'
import getActiveButton from "@/helpers/getActiveButton"



const AppFooter = () => {

  const { href } = window.location
  const { applicationsActive, settingsActive, dashboardActive } = getActiveButton(href)

  return (
    <Container>
      <LinkWraper>
      <StyledLink to={DASHBOARD_URL}>
        <ContainerIcon>
          <IconWreper>
            <CoreIcons.HomeIcon color={dashboardActive && '#1061DF'}/>
            <Title isActive={dashboardActive}> <Lang id="widgets.widgetsDashboard" /> </Title>
          </IconWreper>
        </ContainerIcon>
      </StyledLink>
      <StyledLink to={MOBILE_APPLICATIONS_URL}>
      <ContainerIcon>
        <IconWreper>
          <CoreIcons.ApplicationsIcon color={applicationsActive && '#1061DF'}/>
          <Title isActive={applicationsActive}> <Lang id="settingsSidebar.widgetShopApp" /> </Title>
        </IconWreper>
      </ContainerIcon>
      </StyledLink>
      <ContainerIcon>
        <IconWreper>
          <CoreIcons.AlertIcon/>
          <Title>
            <Lang id="settingsSidebar.notificationsTitle" />
          </Title>
        </IconWreper>
      </ContainerIcon>
      <StyledLink to={MOBILE_SETTINGS_URL}>
        <ContainerIcon>
          <IconWreper>
            <CoreIcons.SettingsUserIcon color={settingsActive && '#1061DF'}/>
            <Title isActive={settingsActive}> <Lang id="tooltip.settings" /> </Title>
          </IconWreper>
        </ContainerIcon>
      </StyledLink>
     </LinkWraper>
    </Container>
  )
}

export default AppFooter

