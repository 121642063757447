export default {
    errors: {
        requiredField: 'Required field'
    },
    graph: {
        receivedAppealsAmount: 'Received',
        solvedAppealsAmount: 'Solved',
        actualConsumption: 'actual consumption',
        plannedConsumption: 'planned consumption',
        avgDays: 'Average time for making and reviewing decisions',
        previousExpense: 'Previous',
        currentExpense: 'Current',
        planExpense: 'Planned consumption',
        actualExpense: 'Actual consumption',
        base: 'Current period',
        plus: 'Overspending',
        minus: 'Saving',
        beforePeriod: 'Previous period',
        electricity: 'kWh',
        energy: 'Gcal',
        inLimit: 'Within normal limits',
        lower: 'Below normal',
    },
    installationPassport: {
        outDoorLightning: 'Outdoor Lighting',
        water: 'Water supply',
        heat: 'Heat supply',
        garbage: 'Separate waste collection',
        charging: 'Charging stations',
        heatAndWater: 'Heat supply and water supply',
    },
    changeLog: 'Changelog',
    versionControl: 'Version Control',
    version: 'Версия',
}