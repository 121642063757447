import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import {
  ACTIONS,
  DATE_PICKER,
  TEXT
} from '@/constants/semanticNames'
import CREATE_SCHEDULE_NAMES from '@/constants/forms/createSchedule'

export const taskConfig = [
  {
    title: <Lang id={'scheduleManager.form.taskName'} />,
    type: TEXT,
    selector: CREATE_SCHEDULE_NAMES.TASK_NAME,
  },
  {
    title: <Lang id={'scheduleManager.form.startDate'} />,
    type: DATE_PICKER,
    selector: CREATE_SCHEDULE_NAMES.START_DATE,
  },
  {
    title: <Lang id={'scheduleManager.form.endDate'} />,
    type: DATE_PICKER,
    selector: CREATE_SCHEDULE_NAMES.END_DATE,
  },
  {
    title: <Lang id={'scheduleManager.form.actions'} />,
    type: ACTIONS,
    selector: CREATE_SCHEDULE_NAMES.ACTIONS,
  },
]

export default taskConfig