import {
  CHARGING_STATIONS,
  GARBAGE_COLLECTION, HEAT_AND_WATTER_SUPPLY,
  HEAT_SUPPLY,
  OUTDOOR_LIGHTING,
  WATER_SUPPLY,
  ROAD_REPAIRING,
  TRANSPORT_STATION,
  OUTDOOR_LIGHTNING
} from '@/constants/objectTypes'

export default {
  title: 'Портал Тюмень - НАШ ДОМ',
  graphs: 'Графики',
  find: 'Найти',
  appeals: 'Обращения',
  buildChart: 'Построить',
  noData: 'Отсутствуют данные',
  interval: 'Интервал',
  periodOptions: {
    all: 'За все время',
    year: 'Год',
    quarter: 'Квартал',
    month: 'Месяц',
    week: 'Неделя',
    day: 'День',
  },
  chartTypes: {
    all: 'Все типы графиков',
    topics: 'Тематика обращений',
    statuses: 'Статусы решений по обращениям',
    statistic: 'Статистика по обращениям',
    time: 'Среднее вермя принятия и рассмотрения решений',
  },
  processes: {
    [OUTDOOR_LIGHTING]: 'Наружное освещение',
    [OUTDOOR_LIGHTNING]: 'Наружное освещение',
    [WATER_SUPPLY]: 'Водоснабжение',
    [HEAT_SUPPLY]: 'Теплоснабжение',
    [GARBAGE_COLLECTION]: 'Раздельный сбор мусора',
    [CHARGING_STATIONS]: 'Зарядные автостанции',
    [HEAT_AND_WATTER_SUPPLY]: 'Теплоснабжение и Водоснабжение',
    [ROAD_REPAIRING]: 'Ремонт и реконструкция дорог',
    [TRANSPORT_STATION]: 'Остановки общественного транспорта',
  }
}
