import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export const CORRECT = 'correct'
export const FAULTY = 'faulty'
export const WARNING = 'warning'
export const UNDEFINED = 'undefined'

export const NOT_REPLACED = 'normal'
export const REPLACEMENT = 'warning'
export const NECESSARY_REPLACE = 'error'

export const OUTDOOR_LIGHTING = 'outdoorLighting'
export const HEAT_SUPPLY = 'heatSupply'
export const ROAD_OBJECTS = 'roadObjects'
export const STOPS_TOTAL_TRANSPORT = 'publicTransport'
export const FLOODING = 'flooding'

export const IN_WORK = 'inWork'
export const DECISION_MADE = 'decisionWasMade'
export const TOTAL_CALLS = 'totalCalls'
export const AVERAGE_TIME_TAKEN = 'averageDecisionExecutionTime'
export const AVERAGE_DECISION_TIME = 'averageDecisionTime'

export const SERVICEABLE = 'serviceableCount'
export const MALFUNCTION = 'malfunctioningCount'

export const DEPRECIATION_EQUIPMENT_STRUCTURE = [
  {
    id: NOT_REPLACED,
    name: <Lang id="widgetsDashboard.notReplaced" />,
    value: 0,
  },
  {
    id: REPLACEMENT,
    name: <Lang id="widgetsDashboard.replacement" />,
    value: 0,
  },
  {
    id: NECESSARY_REPLACE,
    name: <Lang id="widgetsDashboard.necessaryReplace" />,
    value: 0,
  },
]

// export const rightServiceDeskStructure = [
//   {
//     id: OUTDOOR_LIGHTING,
//     name: 'Наружное освещение',
//     value: 0,
//   },
//   {
//     id: HEAT_SUPPLY,
//     name: 'Ремонт дорог',
//     value: 0,
//     disabled: true,
//   },
//   {
//     id: ROAD_OBJECTS,
//     name: 'Остановки общ. транспорта',
//     value: 0,
//     disabled: true,
//   },
//   {
//     id: STOPS_TOTAL_TRANSPORT,
//     name: 'Прорыв теплотрасс',
//     value: 0,
//     disabled: true,
//   },
//   {
//     id: FLOODING,
//     name: 'Подтопления',
//     value: 0,
//     disabled: true,
//   },
// ]
//
// export const leftServiceDeskStructure = [
//   {
//     id: OVERDUE_ON_EXECUTION,
//     name: <Lang id="widgetsDashboard.overdue" />,
//     value: 0,
//   },
//   {
//     id: DELAYED,
//     name: <Lang id="widgetsDashboard.delayed" />,
//     value: 0,
//   },
// ]
