import { DELETE_SCHEDULE } from './index'

export const requestDeleteSchedule = (payload) => ({
  type: DELETE_SCHEDULE.REQUEST,
  payload,
})

export const successDeleteSchedule = (payload) => ({
  type: DELETE_SCHEDULE.SUCCESS,
  payload,
})

export const errorDeleteSchedule = (payload) => ({
  type: DELETE_SCHEDULE.ERROR,
  payload,
})