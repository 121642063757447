export default {
  messages: {
    setEvens: 'Настроить события',
    setStats: 'Статус',
    setFields: 'Настроить столбцы',
    export: 'Экспорт',
  },
  alarmsTypeTitle: {
    error: 'Неисправностей',
    warning: 'Предупреждений',
    fixed: 'Исправно',
  },
  alarmTypeTitle: {
    error: 'Неисправность',
    warning: 'Предупреждение',
    fixed: 'Исправно',
    unknown: 'Не определено',
  },
  buttonsTitles: {
    buildTable: 'построить таблицу',
    discardTable: 'сбросить диапазон',
  },
  forms: {
    dateRange: 'Диапазон дат',
    events: 'События',
  },
  options: {
    correct: 'Исправно',
    faulty: 'Неисправно',
    warning: 'Предупреждение',
    undefined: 'Не определено',
  },
  alarmToolTip: 'Отметить все как просмотренное',
}
