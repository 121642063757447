import { connect } from 'react-redux'
import { REQUEST_STATUSES } from '@/constants/requests'
import {
  getDates,
  getData,
  getDataMobile,
  getQuery,
  getSelectedMessageId,
  getMessageData,
  getStatus,
} from '@/store/selectors/tyumenOurHome'
import {
  setSelectedElement,
  setSearch,
  setDates,
} from '@/store/actions/tyumenOurHome'
import {
  requestTyumenMessagesList,
} from '@/store/actions/tyumenOurHome/getMessagesList'
import {
  requestTyumenMessage,
} from '@/store/actions/tyumenOurHome/getMessage'
import {getWidgetsData} from '@/store/selectors/customWidgetsData'

import Treatment from './component'

const mapStateToProps = (state) => ({
  dates: getDates(state),
  data: getData(state),
  dataMobile: getDataMobile(state),
  query: getQuery(state),
  selectedMessageId: getSelectedMessageId(state),
  messageData: getMessageData(state),
  isLoading: getStatus(state) === REQUEST_STATUSES.PENDING,
  test: getWidgetsData(state),

})
const mapDispatchToProps = (dispatch) => ({
  setSelectedElement: (payload) => dispatch(setSelectedElement(payload)),
  setSearch: (payload) => dispatch(setSearch(payload)),
  setDates: (payload) => dispatch(setDates(payload)),
  getMessagesList: (payload) => dispatch(requestTyumenMessagesList(payload)),
  getMessage: (payload) => dispatch(requestTyumenMessage(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Treatment)
