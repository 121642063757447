import React, {
  useCallback,
  useEffect, useMemo,
  useState
} from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import pt from 'prop-types'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import SelectControl from '@/components/controls/SelectControl'
import {
  CancelButton,
  MessageContainer,
  SubmitButton,
  FieldsContainer,
  MessageWrapper,
} from './styles'
import AlarmsIcons from '@/components/icons/alarms'
import GlobalPopup from '@/components/blocks/GlobalPopup'
import GlobalPopupContent from '@/components/blocks/GlobalPopupContent'
import { GlobalPopupMessage } from '@/forms/ScheduleManager/CreateEditScheduleForm/styles'
import TextAreaControl from '@/components/controls/TextAreaControl'
import CREATE_SCHEDULE_NAMES from '@/constants/forms/createSchedule'
import MenuIcon from '@/components/icons/menu'
import { ID_BY_APPLICATIONS } from '@/constants/widgets'

const ScheduleTab = ({
  intl,
  scheduleData,
  scheduleOptions,
  group,
  userData,
  selectedObjectsGroup,
  isSetScheduleErrorStatus,
  treeData,

  requestGetScheduleOptions,
  requestGetScheduleData,
  requestSetSchedule,
  requestResetSchedule,
  setIsSetScheduleErrorStatus
}) => {
  const [scheduleId, setScheduleId] = useState(null)
  const [justificationComment, setJustificationComment] = useState('')
  const [isJustificationPopupOpen, setIsJustificationPopupOpen] = useState(false)
  const [status, setStatus] = useState(false)

  useEffect(() => {
    if (group.id && group.isAllowedForSchedule) {
      requestGetScheduleData({ groupId: group.id })
      requestGetScheduleOptions({ installationType: group.allowedScheduleInstallationType })
    }
  }, [group, requestGetScheduleData, requestGetScheduleOptions])

  useEffect(() => {
    setScheduleId(null)
    setStatus(false)
  }, [selectedObjectsGroup])

  useEffect(() => {
    if (scheduleData.id) {
      setScheduleId(scheduleData.id)
      setStatus(true)
    }
  }, [scheduleData])

  useEffect(() => {
    if (isSetScheduleErrorStatus) {
      setScheduleId(null)
      setStatus(false)
    }
  }, [isSetScheduleErrorStatus])

  const handleScheduleChange = useCallback((value) => {
    setScheduleId(value)
    setIsSetScheduleErrorStatus(false)
  }, [setScheduleId, setIsSetScheduleErrorStatus])

  const handleSetSchedule = useCallback(() => {
    requestSetSchedule({ groupId: group.id, scheduleId })
    setStatus(true)
  }, [requestSetSchedule, group, scheduleId])

  const handleResetSchedule = useCallback(() => {
    setIsJustificationPopupOpen(true)
  }, [])

  const handleSetJustificationPopup = useCallback((value) => () => {
    setIsJustificationPopupOpen(value)
  }, [])

  const handleJustificationCommentChange = useCallback((value) => {
    setJustificationComment(value)
  }, [])

  const onResetSchedule = useCallback(() => {
    setIsJustificationPopupOpen(false)
    requestResetSchedule({ scheduleId, justificationComment })
    setScheduleId(null)
    setStatus(false)
    setJustificationComment('')
    setTimeout(() => {
      requestGetScheduleOptions({ installationType: group.allowedScheduleInstallationType })
    }, 500)
  }, [justificationComment, scheduleId, requestResetSchedule, requestGetScheduleOptions, group])

  const isHasPermission = userData.permissions.applicationModules
    .filter((module) => module.applicationId === ID_BY_APPLICATIONS.OBJECTS_GROUPS)
    .some((section) => section.code === 'GROUP_ASSIGN_SCHEDULE_EDIT')

  const groupId = useMemo(() => {
    return scheduleOptions.filter(item => item.value === scheduleId)[0]?.groupId
  }, [scheduleOptions, scheduleId])

  return (
    isHasPermission &&
    <>
      {!group.isAllowedForSchedule
        ? <MessageWrapper>
           <MessageContainer>
             <AlarmsIcons.WarningIcon />
             {group.allowedScheduleInstallationType ? <Lang id={`objectsGroups.verification.warning`} /> : <Lang id={`objectsGroups.schedule.warning`} />}
           </MessageContainer>
         </MessageWrapper>
        : <FieldsContainer>
            <LabelWithIcon
              title={<Lang id={`objectsGroups.schedule.label`} />}
            />
            <SelectControl
              value={scheduleId}
              onChange={handleScheduleChange}
              options={scheduleOptions}
              disabled={status}
              placeholder={scheduleOptions.length ? <Lang id={`objectsGroups.schedule.placeholder`} /> : <Lang id="objectsGroups.schedule.emptyStateMessage" />}
              withSearch
              light
            />
            { !!groupId && !status ?
              <MessageWrapper>
                <MessageContainer>
                  <AlarmsIcons.WarningIcon />
                  <Lang id={`objectsGroups.schedule.setScheduleErrorMessage1`} /> "{treeData.filter(item => item.id === groupId)[0].name}" <Lang id={`objectsGroups.schedule.setScheduleErrorMessage2`} />
                </MessageContainer>
              </MessageWrapper>
                 :
              <>
                {!status && !!scheduleId &&
                  <SubmitButton onClick={handleSetSchedule}>
                    <Lang id={`objectsGroups.schedule.set`} />
                  </SubmitButton>
                }
                {status && !!scheduleId &&
                  <CancelButton onClick={handleResetSchedule}>
                    <Lang id={`objectsGroups.schedule.reset`} />
                  </CancelButton>
                }
              </>
            }
          </FieldsContainer>
      }
      {isJustificationPopupOpen && (
        <GlobalPopup
          content={(
            <GlobalPopupContent
              type={'warning'}
              onClose={handleSetJustificationPopup(false)}
                title={<Lang id={'objectsGroups.popup.resetSchedule'}/>}
                message={
                  <GlobalPopupMessage>
                    <Lang id={'objectsGroups.popup.resetScheduleMessage'}/>
                    <TextAreaControl
                      name={CREATE_SCHEDULE_NAMES.JUSTIFICATION_COMMENT}
                      rows="3"
                      charLimit={100}
                      onAfterChange={(value) => handleJustificationCommentChange(value)}
                      placeholder={intl.messages['scheduleManager.form.commentText']}
                    />
                  </GlobalPopupMessage>
                }
                config={{
                  warning: {
                  icon: MenuIcon.AttentionIcon,
                    buttons: [
                      {
                       statusType: 'flat',
                       onClickSelector: handleSetJustificationPopup(false),
                       title: <Lang id="scheduleManager.popup.cancel" />,
                      },
                      {
                       statusType: 'warning',
                       onClickSelector: onResetSchedule,
                       disabled: !justificationComment.length,
                       title: <Lang id="scheduleManager.popup.save" />,
                      },
                    ],
                  },
                }}
            />
          )}
        />
      )}
    </>
  )
}

ScheduleTab.defaultProps = {
  scheduleOptions: [],
  scheduleData: {},
  group: {},

  requestGetScheduleOptions: noop,
  requestGetScheduleData: noop,
  requestSetSchedule: noop,
  requestResetSchedule: noop,
}
ScheduleTab.propTypes = {
  scheduleOptions: pt.array,
  group: pt.objectOf(pt.object),
  scheduleData: pt.object,
  groupId: pt.number,
  type: pt.string,

  requestGetScheduleOptions: pt.func,
  requestGetScheduleData: pt.func,
  requestSetSchedule: pt.func,
  requestResetSchedule: pt.func,
}

export default ScheduleTab

