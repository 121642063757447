import * as formatDataFunc from '@/helpers/dataFormator'
import { getIsElementary } from '@/helpers/maps'
import { getPlainDataFromTree } from '@/data/mapData'

const getPinsByTree = (tree) => {
  const plainData = getPlainDataFromTree(tree)
  const controllers = plainData
    .filter((item) => item.type === "CONTROLLER")
    .map((element) => formatDataFunc.getObjectStatistic(element))
  const objects = plainData.filter((item) => getIsElementary(item.type))

  // const controllers = plainData
  //     .filter((item) => item.installationType === CONTROL_CUPBOARD)
  //     .map((element) => formatDataFunc.getObjectStatistic(element, plainData))
  // const objects = plainData.filter((item) => item.treeNodeType === OBJECT_ELEMENT)

  return objects.concat(controllers)
}

export default getPinsByTree