import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { FormattedMessage as Lang } from 'react-intl'
import ViewTree from '@/components/blocks/ViewTree'
import CreateIntegrationForm from '@/forms/CreateIntegrationForm'
import Button from '@/components/blocks/Button'
import UsersIcons from '@/components/icons/users'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import { INTEGRATION_SYSTEM, INTEGRATION_ZONE } from '@/constants/viewTree'
import { FORM_SCHEMAS_DATA } from '@/constants/validationFields'
import { REQUEST_STATUSES } from '@/constants/requests'
import {
  PASSWORD,
  PORT,
  URL,
  LOGIN,
} from '@/constants/forms/integration'
import {
  ROLE_SUPER_ADMIN,
} from '@/constants/names'

import {
  INTEGRATION_SCHEMA_ID,
  INTEGRATION_SCHEMA_CHILD_ID,
  CONNECTION_SIGNAL,
} from '@/constants/syncModule'
import NewIntegration from './components/NewIntegration'
import INTEGRATION_SCHEME from './config'
import {
  Container,
  ItemContainer,
  ItemTitle,
  IconContainer,
  ContentContainer,
  SecondaryText,
  ButtonContainer,
  ContainerContainer,
} from './styles'

const CONNECTED_INTEGRATION = 'CONNECTED_INTEGRATION'
const ADD_NEW__INTEGRATION = 'ADD_NEW__INTEGRATION'
const WITH_EDITING = 'WITH_EDITING'

const IntegrationList = ({
  integrationOptions,
  connectedIntegrations,
  selectedSystemId,
  selectedIntegration,
  synchronizationErrors,
  mode,
  integrationScheme,

  requestGetIntegrations,
  requestGetIntegration,
  requestCreateIntegration,
  requestUpdateIntegration,
  requestRepeatIntegration,
  requestDeleteIntegration,
  errorCreateIntegration,

  onSelectSystem,
  onSetMode,
  onSetIntegration,
  schemeByID,
  updatedScheme,
  user,

  integration,
  requestGetAllIntegrations,
  integrationRequestStatus,
  cityProcesses,
  intl
}) => {
  useEffect(() => {
    requestGetIntegrations()

    return () => {
      onSetMode(CONNECTED_INTEGRATION)
    }
  }, [requestGetIntegrations, onSetMode])

  const [
    isAbortIntegrationMode,
    setIsAbortIntegrationMode,
  ] = useState(false)
  const [form, setForm] = useState({})

  const isHasIntegrations = useMemo(
    () => Object.keys(connectedIntegrations)
      .some((system) => get(
        connectedIntegrations[system],
        'children',
        [],
      ).length > 0),
    [connectedIntegrations],
  )

  const getConnectedIntegrationsAsArray = useCallback(
    () => Object.keys(connectedIntegrations)
      .reduce((accumulator, system) => {
        if (get(connectedIntegrations[system], 'children', []).length > 0) {
          return [
            ...accumulator,
            connectedIntegrations[system],
          ]
        }
        return accumulator
      }, []),
    [connectedIntegrations],
  )

  const getCustomIntegrationSchema = useCallback((id) => {
    const filteredArray = integrationOptions.filter(
      (element) => id === element.id,
    )
    const newData = {
      id: INTEGRATION_SCHEMA_CHILD_ID,
      name: 'Новая интеграция',
      syncStatus: CONNECTION_SIGNAL,
      type: INTEGRATION_ZONE,
      status: 'ok',
    }

    if (!filteredArray[0]) {
      return null
    }
    const newSchema = {
      ...filteredArray[0],
      status: 'ok',
      id: INTEGRATION_SCHEMA_ID,
      toggled: true,
      name: <Lang id={filteredArray[0].name} />,
      children: [
        newData,
      ],
    }
    return (newSchema)
  }, [
    integrationOptions,
  ])

  const addSpinnerToSyncScheme = useCallback(() => {
    const newSchemeByID = {
      ...schemeByID,
      children: [{
        ...(schemeByID.children || [])[0],
        isSync: true,
        integrationOption: true,
      }],
    }
    updatedScheme(newSchemeByID)
  }, [schemeByID, updatedScheme])

  const isFormWithErrors = useMemo(
    () => selectedIntegration.synchronizationStatus !== 'STARTED'
      && selectedIntegration.synchronizationStatus !== 'SUCCESS',
    [selectedIntegration.synchronizationStatus],
  )

  const handleEditIntegration = useCallback((data) => {
    addSpinnerToSyncScheme()
    requestUpdateIntegration({ ...data, type: selectedSystemId })
  },
  [
    addSpinnerToSyncScheme,
    requestUpdateIntegration,
    selectedSystemId,
  ])

  const handleAddIntegration = useCallback((data) => {
    addSpinnerToSyncScheme()
    requestCreateIntegration({ ...data, type: selectedSystemId })
  }, [
    addSpinnerToSyncScheme,
    requestCreateIntegration,
    selectedSystemId,
  ])

  const handleDeleteIntegration = useCallback(({ id, form: formProps }) => {
    requestDeleteIntegration({ id, type: selectedSystemId, form: formProps })
    requestGetIntegrations()
  }, [
    requestDeleteIntegration,
    selectedSystemId,
    requestGetIntegrations
  ])

  const handleRepeatIntegration = useCallback(({ id, form: formProps }) => {
    addSpinnerToSyncScheme()
    requestRepeatIntegration({ id, form: formProps, type: selectedSystemId })
  },
  [
    addSpinnerToSyncScheme,
    requestRepeatIntegration,
    selectedSystemId,
  ])

  const handleSynchronizationErrors = useMemo(() => {
    if (!isEmpty(selectedIntegration)) {
      switch (selectedIntegration.synchronizationStatus) {
        case 'UNKNOWN_ERROR': {
          return <Lang id="syncModule.formMessages.unknownError" />
        }
        case 'TIME_OUT_ERROR': {
          return <Lang id="syncModule.formMessages.timeOutError" />
        }
        default: {
          return false
        }
      }
    }
    return false
  }, [selectedIntegration])

  const formError = useMemo(() => {
    if (!isEmpty(selectedIntegration) && !isEmpty(form) && isEmpty(form.errors)) {
      switch (selectedIntegration.synchronizationStatus) {
        case 'CONNECTION_ERROR': {
          form.setErrors({
            [URL]: 'Ошибка сетевого доступа',
            [PORT]: 'Ошибка сетевого доступа',
          })
          break
        }
        case 'CREDENTIALS_ERROR': {
          form.setErrors({
            [LOGIN]: 'Неверные данные',
            [PASSWORD]: 'Неверные данные',
          })
          break
        }
        default: {
          return false
        }
      }
    }
    return false
  }, [form, selectedIntegration])

  const toggleNewConnectionMode = useCallback(() => {
    if (mode === ADD_NEW__INTEGRATION) {
      setIsAbortIntegrationMode(true)
      return null
    }
    if (mode === WITH_EDITING && form.dirty) {
      setIsAbortIntegrationMode(true)
      return null
    }
    if (mode === WITH_EDITING && !form.dirty) {
      onSelectSystem(null)
      setIsAbortIntegrationMode(false)
      onSetMode(CONNECTED_INTEGRATION)
      return null
    }

    onSelectSystem(null)
    setIsAbortIntegrationMode(false)
    onSetMode(ADD_NEW__INTEGRATION)
    return null
  }, [
    form,
    mode,
    setIsAbortIntegrationMode,
    onSelectSystem,
    onSetMode,
  ])

  const setIdOfNewIntegration = useCallback((item) => () => {
    onSelectSystem(item.id)
    const customIntegrationSchema = getCustomIntegrationSchema(item.id)
    updatedScheme(customIntegrationSchema)
  }, [
    onSelectSystem,
    getCustomIntegrationSchema,
    updatedScheme,
  ])

  const updateIntegrationSchemeById = useCallback((newName) => {
    const currentName = newName || 'Новая интеграция'
    const newSchemeByID = {
      ...schemeByID,
      toggled: true,
      children: [{
        ...(schemeByID.children || [])[0],
        name: currentName,
      }],
    }
    updatedScheme(newSchemeByID)
  }, [
    schemeByID,
    updatedScheme,
  ])

  const onContinueNewIntegrationAddingHandler = useCallback(() => {
    setIsAbortIntegrationMode(false)
  }, [setIsAbortIntegrationMode])

  const onAbortNewIntegrationAddingHandler = useCallback(() => {
    setIsAbortIntegrationMode(false)
    onSelectSystem(null)
    onSetMode(CONNECTED_INTEGRATION)
  }, [
    setIsAbortIntegrationMode,
    onSelectSystem,
    onSetMode,
  ])

  const onTreeIconToggleHandler = useCallback(() => {
    onSelectSystem(null)
  }, [onSelectSystem])

  const onSelectIntegration = useCallback((node, event) => () => {
    if (event === 'click' && node.type === INTEGRATION_ZONE) {
      const selectedIntegrationSystem = getConnectedIntegrationsAsArray()
        .filter(
          (element) => element.id === node.parentId,
        )
      const selectedIntegrationZone = selectedIntegrationSystem[0].children.filter(
        (element) => element.id === node.id,
      )
      const newScheme = {
        ...selectedIntegrationSystem[0],
        toggled: true,
        aliasId: node.id,
        children: [{
          ...selectedIntegrationZone[0],
        }],
      }
      updatedScheme(newScheme)

      requestGetIntegration(node.id)
      onSelectSystem(node.parentId)
      onSetMode(WITH_EDITING)
    }
  },
  [
    getConnectedIntegrationsAsArray,
    updatedScheme,
    requestGetIntegration,
    onSelectSystem,
    onSetMode,
  ])

  const renderIntegrationSchemaBySelectedId = () => (
    <ContentContainer>
      <ViewTree
        key={JSON.stringify(schemeByID)}
        treeData={schemeByID}
        onIconToggle={onTreeIconToggleHandler}
      />
    </ContentContainer>
  )

  const renderIntegrationSystemDependOnId = () => {
    if (!isHasIntegrations || !selectedSystemId) {
      return (
        <ContentContainer>
          <ViewTree
            treeData={integrationScheme}
            onIconToggle={onTreeIconToggleHandler}
          />
        </ContentContainer>
      )
    }
    if (isHasIntegrations) {
      const selectedIntegrationSchema = getConnectedIntegrationsAsArray.filter(
        (element) => element.id === selectedSystemId,
      )
      if (selectedIntegrationSchema.length === 0) return null
      const newData = {
        ...selectedIntegrationSchema[0],
        type: INTEGRATION_SYSTEM,
        status: 'info',
        children: [{
          name: 'новая инеграция',
          status: 'ok',
          type: INTEGRATION_ZONE,
        }],
      }
      return (
        <ContentContainer>
          <ViewTree
            treeData={newData}
          />
        </ContentContainer>
      )
    }
    return null
  }

  const renderFormForEditingIntegration = useCallback(() => (
    <CreateIntegrationForm
      selectedSystemId={selectedSystemId}
      onSubmitForm={handleEditIntegration}
      errorMessage={synchronizationErrors || isFormWithErrors}
      synchronizationErrors={handleSynchronizationErrors}
      formError={formError}
      clearError={errorCreateIntegration}
      integrationError={isFormWithErrors}
      onDelete={handleDeleteIntegration}
      onRepeat={handleRepeatIntegration}
      onNameChange={updateIntegrationSchemeById}
      isSubmitting={((schemeByID.children || [])[0] || {}).isSync}
      editing
      data={selectedIntegration}
      type="edit"
      user={user}
      saveFromProps={setForm}
      setIntegration={onSetIntegration}
      setSelectSystem={onSelectSystem}
      getIntegrations={requestGetIntegrations}
      integration={integration}
      requestGetAllIntegrations={requestGetAllIntegrations}
      integrationRequestStatus={integrationRequestStatus}
    />
  ),
  [
    selectedSystemId,
    handleEditIntegration,
    synchronizationErrors,
    isFormWithErrors,
    errorCreateIntegration,
    handleDeleteIntegration,
    handleRepeatIntegration,
    updateIntegrationSchemeById,
    schemeByID.children,
    selectedIntegration,
    onSetIntegration,
    onSelectSystem,
    requestGetIntegrations,
    formError,
    handleSynchronizationErrors,
    requestGetAllIntegrations,
    integrationRequestStatus,
    integration,
    user
  ])

  const renderFormForIntegration = () => (
    <CreateIntegrationForm
      selectedSystemId={selectedSystemId}
      onSubmitForm={handleAddIntegration}
      errorMessage={synchronizationErrors || isFormWithErrors}
      integrationError={isFormWithErrors}
      clearError={errorCreateIntegration}
      hasGlobalError={false}
      onNameChange={updateIntegrationSchemeById}
      data={FORM_SCHEMAS_DATA}
      user={user}
      type="connect"
      saveFromProps={setForm}
      setIntegration={onSetIntegration}
      setSelectSystem={onSelectSystem}
      integration={integration}
      requestGetAllIntegrations={requestGetAllIntegrations}
      integrationRequestStatus={integrationRequestStatus}
    />
  )

  const renderSelectedIntegration = () => (
    <NewIntegration
      cityProcesses={cityProcesses}
      integrations={connectedIntegrations}
      click={setIdOfNewIntegration}
      intl={intl}
    />
  )

  const renderNewIntegrationConnection = () => (
    <div>
      {selectedSystemId
        ? renderIntegrationSchemaBySelectedId()
        : renderIntegrationSystemDependOnId()}
      {!selectedSystemId
        ? renderSelectedIntegration()
        : renderFormForIntegration()}
    </div>
  )

  const continueOperationRender = useCallback(() => (
    <ButtonContainer>
      <Button styleType="primary" onClick={onContinueNewIntegrationAddingHandler}>
        <Lang id="syncModule.buttons.reject" />
      </Button>
      <Button styleType="flat" onClick={onAbortNewIntegrationAddingHandler}>
        <Lang id="syncModule.buttons.confirm" />
      </Button>
    </ButtonContainer>
  ), [
    onContinueNewIntegrationAddingHandler,
    onAbortNewIntegrationAddingHandler,
  ])

  const renderConnectedIntegration = useCallback(
    () => (
      <>
        <ContentContainer>
          {(mode === WITH_EDITING) && (
            <ViewTree
              key={JSON.stringify(schemeByID)}
              treeData={schemeByID}
            />
          )}
          {isHasIntegrations
            ? getConnectedIntegrationsAsArray()
              .map((element) => {
                if ((mode === WITH_EDITING) || (element && !element.id)) {
                  return null
                }
                return (
                  <ViewTree
                    key={JSON.stringify(element)}
                    treeData={element}
                    onSelect={user.role === ROLE_SUPER_ADMIN ? onSelectIntegration : noop}
                  />
                )
              })
            : (
              <SecondaryText>
                <Lang id="syncModule.messages.noConnectedIntegrations" />
              </SecondaryText>
            )}
        </ContentContainer>
        {mode === WITH_EDITING && renderFormForEditingIntegration(selectedIntegration)}
        {isAbortIntegrationMode && continueOperationRender()}
      </>
    ),
    [
      schemeByID,
      selectedIntegration,
      onSelectIntegration,
      isAbortIntegrationMode,
      mode,
      continueOperationRender,
      getConnectedIntegrationsAsArray,
      isHasIntegrations,
      renderFormForEditingIntegration,
      user,
    ],
  )

  const renderMainTitle = () => {
    if (mode === CONNECTED_INTEGRATION) {
      return (<Lang id="syncModule.labels.connectedIntegrations" />)
    }
    if (mode === WITH_EDITING && !isAbortIntegrationMode) {
      return (<Lang id="syncModule.labels.integrationParameters" />)
    }
    if (mode === WITH_EDITING && isAbortIntegrationMode) {
      return (<Lang id="syncModule.labels.integrationDiscardChanges" />)
    }
    if (mode === ADD_NEW__INTEGRATION && isAbortIntegrationMode) {
      return (<Lang id="syncModule.labels.rejectNewIntegration" />)
    }
    if (mode === ADD_NEW__INTEGRATION) {
      return (<Lang id="syncModule.labels.addNewIntegration" />)
    }
    return null
  }

  const renderContent = () => {
    if (mode === CONNECTED_INTEGRATION || mode === WITH_EDITING) {
      return renderConnectedIntegration()
    }
    if (mode === ADD_NEW__INTEGRATION && isAbortIntegrationMode) {
      return (
        <>
          {renderNewIntegrationConnection()}
          {continueOperationRender()}
        </>
      )
    }
    if (mode === ADD_NEW__INTEGRATION) {
      return renderNewIntegrationConnection()
    }
    return null
  }

  return (
    <Container>
      <ItemContainer>
        <ItemTitle>
          {renderMainTitle()}
        </ItemTitle>
        {mode !== isAbortIntegrationMode
          && user.role === ROLE_SUPER_ADMIN
          && (
            <PortalTooltip
              title={mode !== CONNECTED_INTEGRATION
                ? (<Lang id="tooltip.cancel" />)
                : (<Lang id="tooltip.createIntegration" />)}
              renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                <IconContainer
                  isCross={mode !== CONNECTED_INTEGRATION}
                  onClick={toggleNewConnectionMode}
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                >
                  <UsersIcons.PlusIcon />
                </IconContainer>
              )}
            />
          )}
      </ItemContainer>
      <ContainerContainer>
        {renderContent()}
      </ContainerContainer>
    </Container>
  )
}

IntegrationList.defaultProps = {
  integrationScheme: INTEGRATION_SCHEME,
  schemeByID: INTEGRATION_SCHEME,
  integrationTypes: [],
  integrationOptions: [],
  connectedIntegrations: [],
  selectedSystemId: null,
  selectedIntegration: null,
  user: {
    firstName: '',
    lastName: '',
    group: {},
  },
  intl: {},
  integration: [],
  mode: CONNECTED_INTEGRATION,
  onSetIntegration: noop,
  onSelectSystem: noop,
  onSetMode: noop,
  synchronizationErrors: '',
  requestGetIntegrations: noop,
  requestGetIntegration: noop,
  requestCreateIntegration: noop,
  requestUpdateIntegration: noop,
  requestRepeatIntegration: noop,
  requestDeleteIntegration: noop,
  errorCreateIntegration: noop,
  updatedScheme: noop,
  requestGetAllIntegrations: noop,
  integrationRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
}

IntegrationList.propTypes = {
  integrationScheme: pt.objectOf(pt.object),
  schemeByID: pt.objectOf(pt.object),
  integrationTypes: pt.arrayOf(pt.object),
  integrationOptions: pt.arrayOf(pt.object),
  connectedIntegrations: pt.arrayOf(pt.object),
  selectedSystemId: pt.string,
  selectedIntegration: pt.shape({
    id: pt.string,
    synchronizationStatus: pt.string,
  }),
  user: pt.shape({
    tenantId: pt.number,
    id: pt.number,
    firstName: pt.string,
    lastName: pt.string,
    role: pt.string,
    group: pt.shape({
      name: pt.string,
    }),
  }),
  integration: pt.shape({
    value: pt.number,
    Title: pt.string,
  }),
  mode: pt.string,
  intl: pt.objectOf(pt.object),
  onSetIntegration: pt.func,
  onSelectSystem: pt.func,
  onSetMode: pt.func,
  synchronizationErrors: pt.string,
  requestGetIntegrations: pt.func,
  requestGetIntegration: pt.func,
  requestCreateIntegration: pt.func,
  requestUpdateIntegration: pt.func,
  requestRepeatIntegration: pt.func,
  requestDeleteIntegration: pt.func,
  errorCreateIntegration: pt.func,
  updatedScheme: pt.func,
  requestGetAllIntegrations: pt.func,
  integrationRequestStatus: pt.string,
}

export default IntegrationList
