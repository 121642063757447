import styled from 'styled-components'
import { MARKS_COLORS } from '@/constants/styles/mapsMarks'
import {
  ERROR,
  CONNECTION,
  ERROR_SIGNAL,
  CONNECTION_SIGNAL,
} from '@/constants/syncModule'

export const StyledElementContainer = styled.div`
  width: auto;
  text-overflow:  ellipsis;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  line-height: 2.4rem;
  vertical-align: middle;
  flex: 1;
  margin-right: 6px;
  .svgFill
  .svgStroke {
    animation: 1s linear none infinite ${({ syncStatus }) => syncStatus};
    -ms-animation: 1s linear none infinite ${({ syncStatus }) => syncStatus};
  }
  @keyframes ${CONNECTION_SIGNAL} {
    0% {
      fill: ${({ theme }) => theme.colors.colors.online};
    }
    25% {
      fill: ${({ theme }) => theme.colors.colors.default};
    }
    75% {
      fill: ${({ theme }) => theme.colors.colors.default};
    }
    100% {
      fill: ${({ theme }) => theme.colors.colors.online};
    }
  }
  @-ms-keyframes ${CONNECTION_SIGNAL} {
    0% {
      fill: ${({ theme }) => theme.colors.colors.online};
    }
    25% {
      fill: ${({ theme }) => theme.colors.colors.default};
    }
    75% {
      fill: ${({ theme }) => theme.colors.colors.default};
    }
    100% {
      fill: ${({ theme }) => theme.colors.colors.online};
    }
  }

  @keyframes ${ERROR_SIGNAL} {
    0% {
      fill: ${({ theme }) => theme.colors.colors.error};
    }
    25% {
      fill: ${({ theme }) => theme.colors.colors.default};
    }
    75% {
      fill: ${({ theme }) => theme.colors.colors.default};
    }
    100% {
      fill: ${({ theme }) => theme.colors.colors.error};
    }
  }
  @-ms-keyframes ${ERROR_SIGNAL} {
    0% {
      fill: ${({ theme }) => theme.colors.colors.error};
    }
    25% {
      fill: ${({ theme }) => theme.colors.colors.default};
    }
    75% {
      fill: ${({ theme }) => theme.colors.colors.default};
    }
    100% {
      fill: ${({ theme }) => theme.colors.colors.error};
    }
  }

  @keyframes ${ERROR} {
    0% {
      fill: ${({ theme }) => theme.colors.colors.error};
    }
    100% {
      fill: ${({ theme }) => theme.colors.colors.error};
    }
  }
  @-ms-keyframes ${ERROR} {
    0% {
      fill: ${({ theme }) => theme.colors.colors.error};
    }
    100% {
      fill: ${({ theme }) => theme.colors.colors.error};
    }
  }
  @keyframes ${CONNECTION} {
    0% {
      fill: ${({ theme }) => theme.colors.colors.online};
    }
    100% {
      fill: ${({ theme }) => theme.colors.colors.online};
    }
  }
  @-ms-keyframes ${CONNECTION} {
    0% {
      fill: ${({ theme }) => theme.colors.colors.online};
    }
    100% {
      fill: ${({ theme }) => theme.colors.colors.online};
    }
  }
`

export const StyledIcon = styled.div`
  background: ${({ status }) => MARKS_COLORS[status]};
  height: 18px;
  width: 18px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  svg,
  .svgFill,
  .svgStroke {
    height: 14px;
    width: auto;
  }
`
export const Title = styled.div`
  text-overflow:  ellipsis;
  white-space: nowrap;
  font-size: 1.2rem;
  color: ${({ theme }) => theme.colors.colors.default};
  text-align: left;
`

export const Count = styled.div`
  width: 25px;
  text-align: left;
  margin-right: 10px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 3rem;
  color: ${({ theme }) => theme.colors.colors.disabled};
`

export const CountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${({ theme }) => theme.colors.colors.disabled};
  flex: 1;
`

export const Container = styled.div`
  color: ${({ theme }) => theme.colors.colors.default};
  vertical-align: "top";
  flex: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`
export const TitleContainer = styled.div`
  text-overflow:  ellipsis;
  white-space: nowrap;
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  width: 80%;
  align-self: flex-start;
`

export const TitleElement = styled.div`
  text-overflow:  ellipsis;
  display: inline;
  font-family: Roboto;
  font-style: normal;
  font-weight: 300;
  font-size: 1rem;
  line-height: 1rem;
  text-align: left;
  color: ${({ color }) => color};
`

export const StyledTooltip = styled.abbr`
  text-decoration: none;
`

export const SyncIconContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  > svg 
    .svgFill,
    .svgStroke {
    transform-origin: 50% 50%;
    animation: 1s linear none infinite syncRotation;
  }

  @keyframes syncRotation {
    0% {
      transform: rotate(0deg);
    }
    50% {
      transform: rotate(180deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const TreeNodeToolTip = styled.div`
  cursor: pointer;
  display: flex;
`
