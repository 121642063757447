import styled from 'styled-components'
import { Link } from 'react-router-dom'
import TextControl from '@/components/controls/TextControl'


export const Container = styled.div`
  background-color: #FAFBFB;
  z-index: 100;
  ${({withBorder}) => !withBorder &&`
    border-bottom: 1px solid #DDDDDD;
  `}
`
export const TitlePage = styled.h1`
  font-size: 20px;
  padding-left: 10px;
  @media (max-width: 345px) {
    font-size: 1.6em;
    margin-bottom: 5px;
  }

  ${({buttonBack}) => {
    if (buttonBack) {
      return `
      font-size: 1.6rem;
      font-weight: 500;
      padding-bottom: 5px;
      `
    }
    return `
      color: #1061DF;
    `
  }}
`
export const Header = styled.div`
  width: 100vw;
  display: flex;
  min-height: 50px;
  padding: 0 5px 10px 0;
  align-items: flex-end
  justify-content: space-between;
  padding: 0 0 10px 10px;


  & > svg {
    cursor: pointer;
  }
`
export const ControlsContainer = styled.div`
  display: flex;
  height: 100%;
  padding-bottom: 3px;
`

export const StyledLink = styled(Link)`
  text-decoration: none;
  padding-bottom 5px;
`
export const ButtonBackWraper = styled.div`
  padding-bottom 3px;
`
export const CustomTextControl = styled(TextControl)`
  width: 217px;
  margin: 5px 0 15px 15px;
  position: relative;
  @media (max-width: 910px) {
    width: calc(100% - 30px);
  }
`