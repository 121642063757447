import React, { useCallback, useState, useEffect } from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import { useRouteMatch } from 'react-router-dom'
import UsersIcons from '@/components/icons/users'
import ServiceIcons from '@/components/icons/serviceDeskMyCity'
import useUrlParseAndPush from '@/hooks/useUrlParseAndPush'
import {
  SERVICE_DESK_MY_CITY_URL,
  SERVICE_DESK_MY_CITY_TABLE_URL,
  DASHBOARD_URL,
} from '@/constants/routes'
import {
  Container,
  Title,
  CustomTextControl,
  CustomButton,
  Content,
  IconContainer,
} from './styles'

const Header = ({intl}) => {
  const { isExact } = useRouteMatch()
  const [searchValue, setSearchValue] = useState('')
  const [urlValues, setUrlValues] = useUrlParseAndPush()

  useEffect(() => {
    if (!urlValues.widgetId) {
      setUrlValues('', DASHBOARD_URL)
    }
  }, [setUrlValues, urlValues])

  useEffect(() => {
    if (urlValues.search) {
      setSearchValue(urlValues.search)
    } else {
      setSearchValue('')
    }
  }, [urlValues.search])

  const handleOnChange = useCallback((value) => {
    setSearchValue(value)
  }, [])

  const handleFind = useCallback(() => {
    setUrlValues({
      ...urlValues,
      search: searchValue,
    })
  }, [searchValue, setUrlValues, urlValues])

  const handleNavigation = useCallback((value) => () => {
    setUrlValues({ widgetId: urlValues.widgetId }, value)
  }, [setUrlValues, urlValues.widgetId])
  if (!isExact) {
    return (
      <Container>
        <Content>
          <Title>
            <Lang id="serviceDeskMyCity.mainTitle" />
          </Title>
          <CustomTextControl
            dark
            placeholder={intl.messages['mapsPage.titles.search']}
            name="search"
            value={searchValue}
            icon={UsersIcons.MagnifierIcon}
            onChange={handleOnChange}
          />
          <CustomButton onClick={handleFind}>
            <Lang id="serviceDeskMyCity.find" />
          </CustomButton>
          <Title tab appeals onClick={handleNavigation(SERVICE_DESK_MY_CITY_URL)}>
            <Lang id="serviceDeskMyCity.statistics" />
          </Title>
          <Title tab selected>
            <Lang id="serviceDeskMyCity.appeals" />
          </Title>
        </Content>
        <Content>
          <IconContainer>
            <ServiceIcons.PlusIcon />
          </IconContainer>
          <IconContainer>
            <ServiceIcons.UploadFileIcon />
          </IconContainer>
        </Content>
      </Container>
    )
  }
  return (
    <Container>
      <Content>
        <Title>
          <Lang id="serviceDeskMyCity.mainTitle" />
        </Title>
        <Title tab selected>
          <Lang id="serviceDeskMyCity.statistics" />
        </Title>
        <Title tab onClick={handleNavigation(SERVICE_DESK_MY_CITY_TABLE_URL)}>
          <Lang id="serviceDeskMyCity.appeals" />
        </Title>
      </Content>
    </Container>
  )
}

Header.propTypes = {
}
Header.defaultProps = {
}

export default Header
