import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const GroupObjectIcon = ({ color, onClick }) => {
    const {
        fill,
    } = useSvgColors(color)

    return (
        <svg onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
            <path fillRule="evenodd" clipRule="evenodd" d="M14 3H5v18h14V8l-5-5Zm2 11a1 1 0 1 1 0-2 1 1 0 1 1 0 2Zm-4-2a1 1 0 1 0 0 2 1 1 0 1 0 0-2Zm-4 2a1 1 0 1 1 0-2 1 1 0 1 1 0 2Z" fill={fill} className="svgFill"/>
        </svg>
    )
}

GroupObjectIcon.propTypes = {
    color: pt.string,
    onClick: pt.func,
}

GroupObjectIcon.defaultProps = {
    color: null,
    onClick: noop,
}

export default memoWithName(GroupObjectIcon)