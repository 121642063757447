import {
  takeLatest, select, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { CREAT_USER_URL } from '@/constants/apiRoutes'
import {
  ADMIN,
  USER,
} from '@/constants/viewTree'
import {
  getSelectedTenant,
} from '@/store/selectors/users'
import {
  ADMIN_NAME,
  ADMIN_EMAIL,
  ADMIN_SURNAME,
  ADMIN_PATRONYMIC,
} from '@/constants/forms/admin'
import {
  setSelectedTenant,
  CREATE_USER,
  setFormIsModifying,
} from '@/store/actions/users'
import {
  requestGetTenant,
} from '@/store/actions/users/tenant/getTenant'
import { requestGetUser } from '@/store/actions/users/user/getUser'
import { requestGetAllUsers } from '@/store/actions/users/user/getAllUsers'
import { errorCreateUser } from '@/store/actions/users/user/createUser'
import { requestGetTenantGroups } from '@/store/actions/users/group/getTenantGroup'
import { requestGetAllTenants } from '@/store/actions/users/tenant/getAllTenants'
import createNotifications from '@/helpers/notification'
import getFullName from '@/helpers/fullName'

const ERRORS = {
  'validation_failed.email:not_unique': 'Пользователь с таким адресом электронной почты уже существует',
  'validation_failed.email:invalid': 'Неверный адрес электронной почты',
}

function* createUserSaga({ payload }) {
  const { forms, tenantId, groupId } = payload
  const toast = createNotifications()
  try {
    const selectedTenant = yield select(getSelectedTenant)
    const selectedTenantChildren = selectedTenant.children || {}
    const newSelectedTenantChildren = selectedTenantChildren
      .filter((element) => element.type !== ADMIN)
    const selectedGroupIndex = newSelectedTenantChildren
      .findIndex((element) => element.id === groupId)
    // const newGroupChildren = newSelectedTenantChildren[selectedGroupIndex].children
    //   .filter((element) => element.id !== USER)
    newSelectedTenantChildren[selectedGroupIndex].children = newSelectedTenantChildren[selectedGroupIndex].children
        .filter((element) => element.type !== USER)
    const newSelectedTenant = {
      ...selectedTenant,
      children: [
        ...newSelectedTenantChildren,
      ],
    }
    const dataJson = {
      name: forms.state.values[ADMIN_NAME],
      [ADMIN_SURNAME]: forms.state.values[ADMIN_SURNAME],
      [ADMIN_PATRONYMIC]: forms.state.values[ADMIN_PATRONYMIC],
      [ADMIN_EMAIL]: forms.state.values[ADMIN_EMAIL],
      groupId,
      role: 'ROLE_USER',
    }
    const response = yield request({
      url: CREAT_USER_URL,
      method: 'post',
      body: dataJson,
    })
    const fullName = getFullName(
      response.data.lastName,
      response.data.firstName,
      response.data.middleName,
    )
    toast({
      title: 'Успешная операция!',
      description: `Пользователь “${fullName}” успешно добавлен!`,
    })
    yield put(setFormIsModifying(false))
    forms.setSubmitting(false)
    yield put(requestGetUser({ tenantId, id: response.data.id }))
    yield put(setSelectedTenant(newSelectedTenant))
    yield put(requestGetTenant({ id: tenantId, onlyUpdateSelectedTenant: true }))
    yield put(requestGetAllUsers({ tenantId, groupId }))
    yield put(requestGetTenantGroups(tenantId))
    yield put(requestGetAllTenants())
  } catch (error) {
    yield put(errorCreateUser())
    toast({
      title: 'Ошибка операции!',
      description: `Ошибка при создании пользователя! Повторите попытку позже. Код ошибки: ${ERRORS[error.response.data.errorCode]}`,
      type: 'error',
    })
    forms.setErrors({
      [error.response.data.subject]: ERRORS[error.response.data.errorCode],
    })
  }
}

export default function* root() {
  yield takeLatest(CREATE_USER.REQUEST, createUserSaga)
}
