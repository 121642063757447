import { CREATE_OBJECTS_GROUP } from './index'

export const requestCreateObjectsGroup = (payload) => ({
  type: CREATE_OBJECTS_GROUP.REQUEST,
  payload,
})

export const successCreateObjectsGroup = (payload) => ({
  type: CREATE_OBJECTS_GROUP.SUCCESS,
  payload,
})

export const errorCreateObjectsGroup = (payload) => ({
  type: CREATE_OBJECTS_GROUP.ERROR,
  payload,
})