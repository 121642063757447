import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

import BackIcon from '@/components/icons/maps/BackIcon'
import IconApplications from '@/components/icons/mobileIcons/index'
import PortalTooltip from '@/components/blocks/PortalTooltip'


import {
  Title,
  Header,
  InfoBlock,
  ColumnsContainer,
  Row,
  Icon,
  FooterInfoBlock,
  LabelBlock,
  Container
} from './styles'

const SettingsApplications = ({onCloseSettingsApplications, items}) => {
return (
  <>
    <Header>
      <BackIcon color={'#1061DF'} onClick={onCloseSettingsApplications}/>
      <Title>Настройки экрана приложений</Title>
    </Header>
    <Container>
      <InfoBlock>
        <Lang id="menu.dragMessage" />
      </InfoBlock>
      <LabelBlock>
        <Lang id="menu.usedElements" />
      </LabelBlock>
      <ColumnsContainer>
            {items.map((item) => {
              return (
                  <PortalTooltip
                    title={null}
                    renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                      <Row
                        ref={wrapperRef}
                        onMouseEnter={onMouseEnterHandler}
                        onMouseLeave={onMouseLeaveHandler}
                      >
                        <Icon><IconApplications.DeleteIcon/></Icon>
                        <Title>{item.title}</Title>
                        <IconApplications.Draggable/>
                      </Row>
                    )}
                  />
              )
            })}
        </ColumnsContainer>
        <FooterInfoBlock>
            <Lang id="menu.activateMessage" />
        </FooterInfoBlock>
    </Container>
  </>
)

}

export default SettingsApplications