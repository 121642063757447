import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import { HexColorPicker } from 'react-colorful'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import {
  ColorSelectStyled,
  Error
} from '@/components/controls/ColorSelect/styles'

const ColorSelect = ({
  value, onChange, error
}) => (
  <>
    <ColorSelectStyled isError={!!error}>
      <HexColorPicker color={value || '#b02020'} onChange={onChange} />
    </ColorSelectStyled>
    {!!error &&
      <Error>
        <Lang id={error} />
      </Error>
    }
  </>
)

ColorSelect.propTypes = {
  error: pt.string,
  value: pt.string,
  onChange: pt.func,
}
ColorSelect.defaultProps = {
  error: '',
  value: '',
  onChange: noop,
}

export default memoWithName(ColorSelect)
