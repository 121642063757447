import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export const SCHEDULE = 'schedule'
// export const CALENDAR = 'calendar'
export const SCHEDULE_FAILURE_LOG = 'scheduleFailureLog'
export const USER_ACTIVITY_LOG = 'userActivityLog'

export const TABS_CONFIG = [
  {
    id: SCHEDULE,
    name: <Lang id="scheduleManager.titles.schedule" />,
  },
  // {
  //   id: CALENDAR,
  //   name: <Lang id="scheduleManager.titles.calendar" />,
  // },
  {
    id: SCHEDULE_FAILURE_LOG,
    name: <Lang id="scheduleManager.titles.scheduleFailureLog" />,
  },
  {
    id: USER_ACTIVITY_LOG,
    name: <Lang id="scheduleManager.titles.userActivityLog" />,
  },
]