import { GET_LIVE_TIME_REPORT_OBJECT_TYPES } from './index'

export const requestLiveTimeReportObjectTypes = (payload) => ({
  type: GET_LIVE_TIME_REPORT_OBJECT_TYPES.REQUEST,
  payload,
})

export const successLiveTimeReportObjectTypes = (payload) => ({
  type: GET_LIVE_TIME_REPORT_OBJECT_TYPES.SUCCESS,
  payload,
})

export const errorLiveTimeReportObjectTypes = (payload) => ({
  type: GET_LIVE_TIME_REPORT_OBJECT_TYPES.ERROR,
  payload,
})
