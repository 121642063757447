import {
  takeEvery, put, select,
} from 'redux-saga/effects'
// import get from 'lodash/get'
import request from '@/helpers/axios'
import {
  GET_ANALYTICS_ALARMS_WIDGET,
} from '@/store/actions/analyticsAlarms'
import { ALARMS_ANALYTICS_SUMMARY_TABLE_ENDPOINT } from '@/constants/apiRoutes'
import {
  successAnalyticsAlarmsWidget,
  errorAnalyticsAlarmsWidget,
} from '@/store/actions/analyticsAlarms/getWidgetInformation'
import {
  OK,
  WARNING,
  ERROR,
  UNKNOWN,
} from '@/constants/alarms'
// import {
//   updateWidgetsList,
// } from '@/store/actions/appSettings'
import { getWidgets } from '@/store/selectors/appSettings'
import { getWidgetsData } from '@/store/selectors/customWidgetsData'

// const NO_INSTALLATION_ERROR = 'error.alert.statistic.not.found.by.installation.id'

function* getWidgetInformation({ payload: id }) {
  try {
    const widgets = yield select(getWidgets)
    const widgetById = widgets.find((widget) => widget.id === id)

    if (widgetById && widgetById.settings) {
      const response = yield request({
        url: ALARMS_ANALYTICS_SUMMARY_TABLE_ENDPOINT({
          params: {
            id: `${widgetById.settings.installationId}`,
            pageSize: 1,
          },
        }),
        method: 'get',
      })

      if (response.data.content) {
        const {
          normalState,
          warningState,
          errorState,
          undefinedState,
        } = response.data.content[0]
        const data = {
          [OK]: normalState,
          [WARNING]: warningState,
          [ERROR]: errorState,
          [UNKNOWN]: undefinedState,
        }
        const widgetsData = yield select(getWidgetsData)
        const updatedWidgetsData = ({
          ...widgetsData,
          [id]: data,
        })
        yield put(successAnalyticsAlarmsWidget(updatedWidgetsData))
      }
    }
  } catch (error) {
    // const { response = {} } = error
    // if (get(response, 'data[0].message', '').includes(NO_INSTALLATION_ERROR)) {
    //   const currentWidgets = yield select(getWidgets)
    //   const updatedWidgets = currentWidgets
    //     .filter((widget) => widget.id !== id)
    //
    //   yield put(updateWidgetsList(updatedWidgets))
    // }
    yield put(errorAnalyticsAlarmsWidget(error))
  }
}

export default function* root() {
  yield takeEvery(GET_ANALYTICS_ALARMS_WIDGET.REQUEST, getWidgetInformation)
}
