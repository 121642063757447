import styled from 'styled-components'
import { Form } from 'formik'
import {
  Arrow,
  Options,
  Select,
  Selection
} from '@/components/controls/SelectControl/styles'
import { IconWrapper } from '@/components/regions/sidebars/PageSidebar/styles'

export const FormContainer = styled(Form)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const HiddenInputContainer = styled.input`
  visibility: hidden;
  width: 0;
  height: 0;
`

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;
  ${({ file }) => file && `
    input {
      padding-right: 35px;
      width: 320px;
    }
  `}
  ${Arrow} {
    margin-top: 0;
    padding: 0;
  }
  ${IconWrapper} {
    min-width: 18px;
    width: 18px;
  }
  ${Selection} {
    padding-left: 0;
  }
  ${Select} {
    border: none;
    background: none;
  }
  ${Options} {
    left: -150px;
    width: 165px;
  }
`