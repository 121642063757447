import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  GET_ANALYTICS_ALARMS_TABLE,
  ANALYTICS_ALARMS_TABLE,
  SET_SIDE_BAR_FILTER,
  SET_STATUS_TYPES,
  SET_EVENTS_TYPES,
  SET_FILTER_VALUES,
  RESET_FILTER_VALUES
} from '@/store/actions/analyticsAlarms'
import moment from 'moment'
import { ALARMS_ANALYTICS_TABLE_ENDPOINT } from '@/constants/apiRoutes'
import {
  successAnalyticsAlarmsTable,
  errorAnalyticsAlarmsTable,
} from '@/store/actions/analyticsAlarms/getTable'
import { getSelectedNode } from '@/store/selectors/appSettings'
import {
  getTableParameters,
  getCheckBoxOption,
  getFilterOptions
} from '@/store/selectors/analyticsAlarms'
import { treeHandlers } from 'react-hyper-tree'
import { setGlobalLoading } from '@/store/actions/appSettings'

function* getAlarmManagerTableSaga() {
  try {
    yield put(setGlobalLoading(true))
    const node = yield select(getSelectedNode)

    const filterOptions = yield select(getCheckBoxOption)
    const filterOptionsArray = Object.keys(filterOptions)
      .reduce((accumulator, filter) => {
        if (filterOptions[filter]) {
          return [...accumulator, filter]
        }

        return accumulator
      }, [])

    const tableParameters = yield select(getTableParameters)
    const filterValues = yield select(getFilterOptions)

    const response = yield request({
      url: ALARMS_ANALYTICS_TABLE_ENDPOINT({
        params: {
          alertTypes: filterOptionsArray,
          pageNumber: tableParameters.page - 1,
          pageSize: tableParameters.perPage,
          parentId: node.id,
          search: tableParameters.requestQuery || '',
          sort: [`${tableParameters.sort.field}: ${tableParameters.sort.direction}`],
          projectIds: filterValues.project,
          installationTypes: filterValues.objectsTypes,
          fromDate: filterValues.dateFirst && moment(filterValues.dateFirst).format('DD.MM.YYYY'),
          toDate: filterValues.dateSecond && moment(filterValues.dateSecond).format('DD.MM.YYYY')
        },
      }),
      method: 'get',
    })

    if (response.data) {
      const {
        content,
        pageable,
      } = response.data
      yield put(setGlobalLoading(false))
      yield put(successAnalyticsAlarmsTable({
        data: content,
        totalElements: pageable.totalElements,
        totalPages: pageable.totalPages,
      }))
      const tree = treeHandlers.trees['analytics-alarms-tree']
      if (node && node.id && tree && tree.handlers) {
        tree.handlers.setSelectedByPath(`/${node.parentId}/${node.id}`)
      }
    }
  } catch (error) {
    yield put(setGlobalLoading(false))
    yield put(errorAnalyticsAlarmsTable(error))
  }

  return null
}

export default function* root() {
  yield takeLatest(GET_ANALYTICS_ALARMS_TABLE.REQUEST, getAlarmManagerTableSaga)
  yield takeLatest(ANALYTICS_ALARMS_TABLE.SORT, getAlarmManagerTableSaga)
  yield takeLatest(ANALYTICS_ALARMS_TABLE.PER_PAGE, getAlarmManagerTableSaga)
  yield takeLatest(ANALYTICS_ALARMS_TABLE.PAGE, getAlarmManagerTableSaga)
  // yield takeLatest(ANALYTICS_ALARMS_TABLE.SELECTED_FIELDS, getAlarmManagerTableSaga)
  yield takeLatest(ANALYTICS_ALARMS_TABLE.REQUEST_QUERY, getAlarmManagerTableSaga)
  yield takeLatest(SET_STATUS_TYPES, getAlarmManagerTableSaga)
  yield takeLatest(SET_EVENTS_TYPES, getAlarmManagerTableSaga)
  yield takeLatest(SET_SIDE_BAR_FILTER, getAlarmManagerTableSaga)
  yield takeLatest(SET_FILTER_VALUES, getAlarmManagerTableSaga)
  yield takeLatest(RESET_FILTER_VALUES, getAlarmManagerTableSaga)
}
