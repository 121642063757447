import { GET_SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE } from './index'

export const requestScheduleManagerActivityLogTable = (payload) => ({
  type: GET_SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE.REQUEST,
  payload,
})

export const successScheduleManagerActivityLogTable = (payload) => ({
  type: GET_SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE.SUCCESS,
  payload,
})

export const errorScheduleManagerActivityLogTable = (payload) => ({
  type: GET_SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE.ERROR,
  payload,
})