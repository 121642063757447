import { takeLatest, put } from 'redux-saga/effects'
import { GET_OBJECT_IMAGE } from '@/store/actions/maps'
import { SERVER_TO_LOCAL } from '@/constants/passportization/types'
import { errorObjectImage } from '@/store/actions/maps/getObjectImage'
import createNotifications from "@/helpers/notification";

function* getObjectImage( {payload: node} ) {
  try {
    const { type, name } = node
    if (type && name && SERVER_TO_LOCAL[type]) {
      // const response = yield request({
      //   url: `${
      //     GET_PASSPORT_DATA_URL
      //   }/?${
      //     getQueryParams({ identifier: name, installationType: SERVER_TO_LOCAL[type] })
      //   }`,
      //   method: 'get',
      // })
      // if (response && response.data && response.data.photo) {
      //   const responsePhoto = yield request({
      //     url: `${GET_PASSPORT_FILES_URL}?id=${response.data.photo.uid}`,
      //     method: 'get',
      //     options: {
      //       responseType: 'blob',
      //     },
      //   })
      //   const newBlob = new window.Blob([responsePhoto.data], { type: 'application/octet-stream' })
      //   const fileURL = window.URL.createObjectURL(newBlob)
      //   yield put(successObjectImage(fileURL))
      // }
    }
  } catch (error) {
    yield put(errorObjectImage(error))
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_OBJECT_IMAGE.REQUEST, getObjectImage)
}
