export default {
  title: 'Центр мониторинга',
  secondTitle: 'Топология “MESH”',
  widgets: {
    tiltAngel: 'Угол наклона',
    unknown: 'неизвестно',
  },
  titles: {
    newObject: 'Объекты',
    editUser: 'Редактор пользователя',
    newGroup: 'Новая группа',
    editGroup: 'Редактор группы',
    users: 'Пользователи',
    addTitile: 'Добавить',
    addUserButton: 'Нового пользователя',
    addGroupButton: 'Новую группу',
    search: 'Поиск',
    tableSearch: 'Поиск в таблице',
  },
  tabs: {
    allObjects: 'Все объекты',
    geoZone: 'Геозоны',
    instalations: 'Инсталляции',
    statistics: 'Статистика',
  },
  mapsControl: {
    count: 'Всего объектов',
    filter: 'Фильтр',
    list: 'Список',
    selectMode: 'Режим выбора',
    zoom: 'Масштаб',
    select: 'Выделить все',
    reset: 'Снять выделение',
  },
  filterTypes: {
    shuo: 'ШУНО',
    baseStation: 'Базовая станция',
    controller: 'Контроллер',
    universMeter: 'Многофункц. счетчик',
    support: 'Опора',
    let: 'Линия',
    ok: 'Активно',
    warning: 'Предупреждение',
    info: 'Неактивно',
    error: 'Ошибка',
    traffic: 'Светофор',
    light: 'Светильник',
    lightModule: 'Модуль светильника',
    trash: 'Мусорка',
    groupObject: 'Групповой объект',
    heatingDevice: 'Групповой объект',
    coldWaterDevice: 'Групповой объект',
    lightElement: 'Светоточка',
    outdoorLighting: 'Наружное освещение',
    waterSupply: 'Водоснабжение',
    heatSupply: 'Теплоснабжение',
    centralHeating: 'Узел учета ЦО',
    coldWater: 'Узел учета ХВС',
    lep: 'ЛЭП',
  },
  titlesByStatus: {
    ok: 'Исправно',
    warning: 'Возможны проблемы',
    info: 'Неактивно',
    error: 'Проблемы в работе',
    verified: 'Проверен',
    notVerified: 'Не проверен',
    statusNew: 'Новый',
    notExist: 'Не существует',
    installed: 'Установлен',
    notInstalled: 'Не установлен',
    unInstalled: 'Демонтирован',
  },
  defaultTitles: {
    geozonName: 'Название геозоны',
    clusterName: 'Кластер объектов',
  },
  mapLayers: {
    objects: 'Слой объектов',
    lines: 'Слой линий связи',
    mesh: 'Слой Mesh сети',
    russianBorders: 'Границы РФ'
  },
}
