import React, { useState } from 'react'
import {
  ClearButton,
  FilterContainer,
  FilterInput,
  FilterInputContainer,
  FilterInputError,
  FilterOption,
  FilterSelect,
  SuggestionItem,
  SuggestionsList
} from '@/containers/pages/Scheduler/conponents/styles';
import {
  getCountById,
  getCountByName,
  getTaskSettingsById,
  getTaskSettingsByName
} from '@/containers/pages/Scheduler/requests';

const options = {
  "Фильтр": "default",
  "Task name": "name",
  "Integration id": "id"
}

const taskNames = [
  'PARSE_EQUIPMENT',
  'PARSE_STATE_EQUIPMENT',
  // 'PARSE_WORK_TIME_FIXTURE',
  // 'SEND_COMMAND',
  // 'PARSE_SINGLE_OBJECT_STATE',
  // 'SEND_MODULE_DIMMING_WORK_MODE_COMMAND',
  // 'SEND_CUPBOARD_WORK_MODE_COMMAND',
  // 'MODULE_STATUS_TASK',
  // 'CUPBOARD_STATUS_TASK'
]

const SchedulerFilter = ({
  onSettingsChange,
  onPageCountChange,
  defaultSize,
  setFilter,
  filter,
  onClear,
  setLoading,
  setFilterValue,
  onPageChange
}) => {
  const [inputValue, setInputValue] = useState('')
  const [error, setError] = useState('')
  const [filteredTaskNames, setFilteredTaskNames] = useState([])
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0)
  const [suggestionsVisible, setSuggestionsVisible] = useState(false)

  const onSubmit = async (selectedValue = inputValue) => {
    if (filter === 'name') {
      if (!taskNames.includes(selectedValue.toUpperCase())) {
        return
      }
    } else if (!/^\d+$/.test(selectedValue)) {
      setError('Неверное id')
      return
    }

    const fetchData = async (fetchSettings, fetchCount) => {
      const settings = await fetchSettings(selectedValue.toUpperCase(), 0, defaultSize, setLoading)
      const count = await fetchCount(selectedValue.toUpperCase(), setLoading)
      onSettingsChange(settings)
      onPageCountChange(Math.ceil(count / defaultSize))
      setError('')
    }

    if (filter === 'name') {
      await fetchData(getTaskSettingsByName, getCountByName)
    } else {
      await fetchData(getTaskSettingsById, getCountById)
    }
  }

  const handleSelectChange = (e) => {
    setFilter(options[e.target.value])
    setInputValue('')
    setError('')
    setFilteredTaskNames([])
    setSuggestionsVisible(false)
  }

  const handleInputChange = (e) => {
    const value = e.target.value
    setInputValue(value)

    if (filter === 'name') {
      const filtered = taskNames.filter(task =>
        task.toLowerCase().includes(value.toLowerCase())
      )
      setFilteredTaskNames(filtered)
      setSuggestionsVisible(true)
      setActiveSuggestionIndex(0)
    } else {
      setFilteredTaskNames([])
      setSuggestionsVisible(false)
    }
  }

  const handleClear = () => {
    setError('')
    setInputValue('')
    onClear()
    setFilteredTaskNames([])
    setSuggestionsVisible(false)
  }

  const handleSuggestionClick = (taskName) => {
    setInputValue(taskName)
    setFilteredTaskNames([])
    setSuggestionsVisible(false)
    onPageChange(0)
    onSubmit(taskName)
  }

  const handleKeyDown = (e) => {
    if (e.key === 'ArrowDown') {
      setActiveSuggestionIndex((prevIndex) =>
        prevIndex === filteredTaskNames.length - 1 ? 0 : prevIndex + 1
      )
    } else if (e.key === 'ArrowUp') {
      setActiveSuggestionIndex((prevIndex) =>
        prevIndex === 0 ? filteredTaskNames.length - 1 : prevIndex - 1
      )
    } else if (e.key === 'Enter') {
      e.preventDefault()
      if (filteredTaskNames.length > 0) {
        const selectedTask = filteredTaskNames[activeSuggestionIndex]
        setFilterValue(selectedTask)
        handleSuggestionClick(selectedTask)
      } else {
        onSubmit()
      }
    }
  }

  return (
    <FilterContainer>
      <FilterSelect onChange={handleSelectChange} value={Object.keys(options).find(key => options[key] === filter)}>
        {Object.keys(options).map((option, index) => (
          <FilterOption value={option} key={index}>
            {option}
          </FilterOption>
        ))}
      </FilterSelect>
      <FilterInputContainer>
        <FilterInput
          onChange={handleInputChange}
          value={inputValue}
          onKeyDown={handleKeyDown}
        />
        {suggestionsVisible && filteredTaskNames.length > 0 && (
          <SuggestionsList>
            {filteredTaskNames.map((task, index) => (
              <SuggestionItem
                key={index}
                onClick={() => handleSuggestionClick(task)}
                isActive={index === activeSuggestionIndex}
              >
                {task}
              </SuggestionItem>
            ))}
          </SuggestionsList>
        )}
        <FilterInputError>{error}</FilterInputError>
      </FilterInputContainer>
      {filter !== 'default' && inputValue && <ClearButton onClick={handleClear}>Очистить</ClearButton>}
    </FilterContainer>
  )
}

export default SchedulerFilter
