import { GET_VERIFICATION_OPTIONS } from './index'

export const requestGetVerificationOptions = (payload) => ({
  type: GET_VERIFICATION_OPTIONS.REQUEST,
  payload,
})

export const successGetVerificationOptions = (payload) => ({
  type: GET_VERIFICATION_OPTIONS.SUCCESS,
  payload,
})

export const errorGetVerificationOptions = (payload) => ({
  type: GET_VERIFICATION_OPTIONS.ERROR,
  payload,
})