import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import useReduxTable from '@/hooks/useReduxTable'
import { APPEALS_TABLE } from '@/store/actions/serviceDeskMyCity'
import { getTableParameters } from '@/store/selectors/serviceDeskMyCity'
import DutyTable from '@/components/blocks/DutyTable'
import ServiceDeskMyCityForm from '@/forms/ServiceDeskMyCityForm'
import SDFilterForm from '@/forms/SDFilterForm'
import useUrlParseAndPush from '@/hooks/useUrlParseAndPush'
import Loader from '@/components/blocks/Loader'
import HeaderMobile from '@/containers/pages/AnalyticsAlarms/components/SummaryAlarmsAnalytics/HeaderMobile'
import useGetIsMobile from '@/hooks/useGetIsMobile'
import CoreIcon from '@/components/icons/core'
import HeaderIcons from '@/components/icons/header'
import CardList from '../CardList'
import SortShutterSD from '@/components/blocks/SortShutterSD'
import { useHistory } from 'react-router-dom'
import { DASHBOARD_URL, SERVICE_DESK_MY_CITY_URL } from '@/constants/routes'
import debounce from 'lodash/debounce'
import {
  DEBOUNCE_DELAY_LARGE,
} from '@/constants/time'
import {
  fields,
  exportOptions,
} from '@/constants/tablesConfig/serviceDeskMyCity'
import {
  SERVICE_DESK,
} from '@/constants/applications'
import RowInfoCard from '../RowInfoCard'
import {
  Container,
  ContainerMobile
} from './styles'
import qs from 'query-string'


const Appeals = ({
  tree,
  data,

  setEventTypes,
  setStatusTypes,
  requestAppealsTable,
  loading,
  typeOfObjects,
  requestGetInfoServiceDeskCard,
  card,
  loadingCard,
  integrationStatus,
  userApplications,
  currentUser,
  resetServiceDeskTable,
  infoCards,
  getInfoCards,
  globalLoading,
  intl
}) => {
  const [isDirty, setIsDirty] = useState(false)
  const [isInfoCardOpen, setIsInfoCardOpen] = useState(false)
  const [selectedRow, setSelectedRow] = useState(false)
  const [values] = useUrlParseAndPush()
  const isMobile = useGetIsMobile()
  const { push } = useHistory()
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [openTypePopup, setOpenTypePopup] = useState(null)
  const [typeSort, setTypeSort] = useState(null)

  useEffect(() => {
    return () => (
      resetServiceDeskTable()
    )
  }, [resetServiceDeskTable])

  const serviceDeskStatus = useMemo(() => (
    (integrationStatus || []).find(
      (status) => (
        currentUser.roles.isSuperAdmin
          ? values.widgetId === status.temporaryId
          : values.widgetId === status.widgetId
      ),
    )
  ), [integrationStatus, currentUser.roles.isSuperAdmin, values.widgetId])

  const serviceDeskId = useMemo(() => {
    const serviceDesk = userApplications.find((application) => application.code === SERVICE_DESK)
    return (serviceDeskStatus && serviceDeskStatus.id)
      || (serviceDesk && JSON.parse(serviceDesk.settings).id)
  }, [serviceDeskStatus, userApplications])

  useEffect(() => {
    if (serviceDeskId) {
      getInfoCards({
        serviceDeskId,
        serviceDeskConfig: serviceDeskStatus && serviceDeskStatus.propertyConfigContent,
      })
    }
  }, [getInfoCards, serviceDeskId, serviceDeskStatus])

  const handleRequestAppealsTable = useCallback(() => {
    requestAppealsTable({
      urlParse: values,
      serviceDeskId,
      serviceDeskConfig: serviceDeskStatus && serviceDeskStatus.propertyConfigContent,
    })
  }, [requestAppealsTable, serviceDeskId, serviceDeskStatus, values])

  useEffect(() => {
    serviceDeskId ? handleRequestAppealsTable() : push(DASHBOARD_URL)
  }, [handleRequestAppealsTable, serviceDeskId, push])

  const handleSetIsInfoCardOpen = useCallback((value) => {
    if (typeof value === 'object' && value !== null) {
      setSelectedRow(value)
      requestGetInfoServiceDeskCard({
        id: value.id,
        serviceDeskStatus,
      })
      setIsInfoCardOpen(true)
    } else {
      setIsInfoCardOpen(value)
    }
  }, [requestGetInfoServiceDeskCard, serviceDeskStatus])

  useEffect(() => {
    if (Object.keys(tree).length && !isDirty) {
      setIsDirty(true)
    }
  }, [tree, isDirty])

  const tableProps = useReduxTable(APPEALS_TABLE, getTableParameters)

  const { actions, values: tableValues } = tableProps

  const onChangeSearch = useCallback(debounce((value) => {
    actions.setRequestQuery(value)
  }, DEBOUNCE_DELAY_LARGE), [])

  const onChangeInput = (value) => {
    actions.setQuery(value)
    onChangeSearch(value)
  }

  const resetSearch = () => {
    actions.setQuery('')
    actions.setRequestQuery('')
  }

  const toggleSortPopup = () => {
    setIsOpenPopup(!isOpenPopup)
    setOpenTypePopup('sort')
  }
  const toggleFilterPopup = () => {
    setIsOpenPopup(!isOpenPopup)
    setOpenTypePopup('filter')
  }

  const controls = [
    {
      icon: <CoreIcon.SortIcon />,
      name: 'CoreIcon.SortIcon',
      onClick: toggleSortPopup,
      onClose: toggleSortPopup,
    },
    {
      icon: Object.keys(values).length > 1 
        ? <HeaderIcons.ActiveGlobalFilterIcon /> 
        : <HeaderIcons.GlobalFilterIcon /> ,
      name: 'HeaderIcons.GlobalFilterIcon',
      onClick: toggleFilterPopup,
    }
  ]

  const onClose = () => {
    const { widgetId } = values

    push({
      pathname: SERVICE_DESK_MY_CITY_URL,
      search: qs.stringify({
        widgetId: widgetId,
      })
    })
  }

  const contentPopup = useMemo(() => {
    if (openTypePopup === 'filter') {
      return (
        <SDFilterForm typeOfObjects={typeOfObjects} onClose={toggleFilterPopup} />
      )
    }
    if (openTypePopup === 'sort') {
      return (
        <SortShutterSD 
          title={'Сортировка обращений:'} 
          onClose={toggleSortPopup} 
          withName 
          {...tableProps} 
          setIsOpenPopup={setIsOpenPopup}
          typeSort={typeSort}
          setTypeSort={setTypeSort}
          />
      )
    }
  }, 
    [
      openTypePopup,
      typeSort,
      tableProps,
      typeOfObjects,
    ])

  if (isMobile) {
    return( 
      <ContainerMobile>
        <HeaderMobile 
        title={'обращения'} 
        onClose={onClose}
        controls={controls}
        searchActive
        onChangeInput={onChangeInput}
        resetSearch={resetSearch}
        swiperSD
        infoCards={infoCards}
        content={contentPopup} 
        openPopup={isOpenPopup}
        opacyti={true}  
        value={tableValues.query}
        {...tableProps}
        />
        {globalLoading ? <Loader center /> : <CardList data={data}/>}
    </ContainerMobile>
    )
  }

  return (
    <Container>
      {loading
        ? <Loader center />
        : (
          <>
            <ServiceDeskMyCityForm typeOfObjects={typeOfObjects} intl={intl} />
            <DutyTable
              {...tableProps}
              fields={fields}
              exportOptions={exportOptions}
              scrollAfterUpdating
              data={data}

              rowInfoCard={RowInfoCard}
              isInfoCardOpen={isInfoCardOpen}
              rowSelector={handleSetIsInfoCardOpen}
              customHeader
              setEventTypes={setEventTypes}
              setStatusTypes={setStatusTypes}
              card={card}
              selectedRow={selectedRow}
              loadingCard={loadingCard}
              onUpdateData={handleRequestAppealsTable}
              notToShowFooterPerPage
              serviceDesk
            />
          </>
        )}
    </Container>
  )
}

Appeals.propTypes = {
  tree: pt.objectOf(pt.object),
  data: pt.arrayOf(pt.object),

  setSelectedElement: pt.func,
  setEventTypes: pt.func,
  setStatusTypes: pt.func,
  requestAppealsTable: pt.func,
  requestAlarmManagerFile: pt.func,
}
Appeals.defaultProps = {
  tree: {},
  data: [],

  setSelectedElement: noop,
  setEventTypes: noop,
  setStatusTypes: noop,
  requestAppealsTable: noop,
  requestAlarmManagerFile: noop,
}

export default Appeals
