import React, { useMemo, useCallback , useEffect } from 'react'
import pt from 'prop-types'
import { Formik } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'

import PopupIcons from '@/components/icons/popup'
import {
  NORMAL,
  WARNING,
  ERROR,
  INFO,
  OK,
} from '@/constants/objectStatuses'
import {
  ASU,
  OBJECT_TYPES,
  GEOZONE,
  PROJECT
} from '@/constants/forms/globalFilterForm'
import Icons from '@/components/icons/users'
import {
  Title,
  TitleAndSelectWrapper,
  FormWrapper,
  StyledSelectField,
  StyledCheckBox,
  ButtonsWrapper,
  MultipleCheckBoxWrapper,
  StyledButton,
  DecorativeElement,
  DateWrapper,
  IconContainer
} from './styles'
import DatePickerField from '@/components/fields/DatePickerField'
import Loader from '@/components/blocks/Loader'


const EventFilterForm = ({
  onSetValues,
  onResetValues,
  globalFiltersValues,
  requestGetSearchFieldsOptions,
  searchFieldsOptions,
  requestGetAllConnectedIntegrations,
  onClose,
  disabled,
  withoutAlerTypes,
  searchFieldsOptionsLoading
}) => {

  useEffect(() => {
    requestGetSearchFieldsOptions()
    requestGetAllConnectedIntegrations()
  }, [requestGetSearchFieldsOptions, requestGetAllConnectedIntegrations])

  const defaultOptionsByFields = useMemo(() => ({
    [ASU]: searchFieldsOptions.integration,
    [GEOZONE]: searchFieldsOptions.geoZone,
    [PROJECT]: searchFieldsOptions.project,
    [OBJECT_TYPES]: searchFieldsOptions.equipmentType,
  }), [searchFieldsOptions])
 
  const handleResetFilters = useCallback((resetForm) => (event) => {
    event.preventDefault()
    onResetValues()
    resetForm()
  }, [onResetValues])

  return (
    <Formik
      enableReinitialize
      onSubmit={onSetValues}
      initialValues={globalFiltersValues}
      render={({
        values, handleSubmit, resetForm, setFieldValue,
      }) => (
        <FormWrapper>
          {
            searchFieldsOptionsLoading || !Object.keys(searchFieldsOptions).length
              ? <Loader center />
              :
              <>
                <TitleAndSelectWrapper isMobile>
                  <Title>
                    Фильтр событий:
                  </Title>
                  <IconContainer>
                    <Icons.CrossIcon onClick={onClose}/>
                  </IconContainer>
                </TitleAndSelectWrapper>
                <TitleAndSelectWrapper>
                  <Title>
                    Период:
                  </Title>
                  <DateWrapper>
                    <DatePickerField 
                      name={'dateFirst'} 
                    />
                      <DecorativeElement />
                    <DatePickerField
                      name={'dateSecond'}
                    />
                  </DateWrapper>
                </TitleAndSelectWrapper>
                <TitleAndSelectWrapper>
                  <Title>
                    Геозона:
                  </Title>
                  <StyledSelectField
                    multiselect
                    withSearch
                    name={GEOZONE}
                    placeholder={'Все геозоны'}
                    options={defaultOptionsByFields[GEOZONE]}
                    disabled={disabled}
                    isMobile
                  />
                </TitleAndSelectWrapper>
                <TitleAndSelectWrapper>
                  <Title>
                  Проект:
                  </Title>
                  <StyledSelectField
                    multiselect
                    withSearch
                    name={PROJECT}
                    placeholder={'Все проекты'}
                    options={defaultOptionsByFields[PROJECT]}
                    isMobile
                  />
                </TitleAndSelectWrapper>
                <TitleAndSelectWrapper>
                  <Title>
                    <Lang id="globalFilter.formTitles.objectTypes" />
                  </Title>
                  <StyledSelectField
                    multiselect
                    withSearch
                    name={OBJECT_TYPES}
                    placeholder={<Lang id="globalFilter.selectPlaceholders.objectTypes" />}
                    options={defaultOptionsByFields[OBJECT_TYPES]}
                    isMobile
                  />
                </TitleAndSelectWrapper>
                {!withoutAlerTypes && 
                  <TitleAndSelectWrapper isMobile={true}>
                    <Title><Lang id="globalFilter.formTitles.objectState" /></Title>
                    <MultipleCheckBoxWrapper>
                      <StyledCheckBox type={OK} name={NORMAL} />
                      <StyledCheckBox type={WARNING} name={WARNING} />
                      <StyledCheckBox type={ERROR} name={ERROR} />
                      <StyledCheckBox type={INFO} name={INFO} />
                    </MultipleCheckBoxWrapper>
                  </TitleAndSelectWrapper>
                }
                <ButtonsWrapper>
                  <StyledButton usage="reset" onClick={handleResetFilters(resetForm)}>
                    <PopupIcons.ResetFilterIcon color="#3D3D3D" />
                    <Lang id="globalFilter.buttons.reset" />
                  </StyledButton>
                  <StyledButton usage="accept"onClick={handleSubmit} >
                    <PopupIcons.DoneFilterIcon color="#FFFFFF" />
                    <Lang id="globalFilter.buttons.accept" />
                  </StyledButton>
                </ButtonsWrapper>
              </>
          }  
        </FormWrapper>
      )}
    />
  )
}

EventFilterForm.propTypes = {
  cityProcesses: pt.arrayOf(pt.shape({
    name: pt.string,
    id: pt.oneOfType([pt.string, pt.number]),
  })),
  integrationSystems: pt.shape({}),
  onSetValues: pt.func.isRequired,
  onResetValues: pt.func.isRequired,
}

EventFilterForm.defaultProps = {
  cityProcesses: [],
  integrationSystems: {},
  globalFiltersValues: {},
  searchFieldsOptions: [],
}

export default EventFilterForm
