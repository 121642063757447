import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export default [
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.NAME" />,
        showInDashboard: true,
        selector: 'name',
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.IDENTIFIER" />,
        showInDashboard: true,
        selector: 'IDENTIFIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.EQUIPMENT_TYPE" />,
        showInDashboard: true,
        selector: 'EQUIPMENT_TYPE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.DEVICE_SERIAL_NUMBER" />,
        showInDashboard: true,
        selector: 'DEVICE_SERIAL_NUMBER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.METER_SN" />,
        showInDashboard: true,
        selector: 'METER_SN',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.MODEL_MARK" />,
        showInDashboard: true,
        selector: 'MODEL_MARK',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PROCEDURE_SUPPLIER" />,
        showInDashboard: true,
        selector: 'PROCEDURE_SUPPLIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PARENT_IDENTIFIER" />,
        showInDashboard: true,
        selector: 'PARENT_IDENTIFIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PARENT_TYPE" />,
        showInDashboard: true,
        selector: 'PARENT_TYPE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.CONN_TYPE" />,
        showInDashboard: true,
        selector: 'CONN_TYPE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.SWVER" />,
        showInDashboard: true,
        selector: 'SWVER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.ICCID" />,
        showInDashboard: true,
        selector: 'ICCID',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.ICCID1" />,
        showInDashboard: true,
        selector: 'ICCID1',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.ICCID2" />,
        showInDashboard: true,
        selector: 'ICCID2',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.TIME_ZONE" />,
        showInDashboard: true,
        selector: 'TIME_ZONE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.REGISTRY_NUMBER" />,
        showInDashboard: true,
        selector: 'REGISTRY_NUMBER',
        isCard: true
    },


    {
        passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.POWER_CONSUMPTION" />,
        showInDashboard: true,
        selector: 'POWER_CONSUMPTION',
        isCard: true
    },


    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.START_DATE" />,
        showInDashboard: true,
        selector: 'START_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.EXPLOITATION_END_DATE" />,
        showInDashboard: true,
        selector: 'EXPLOITATION_END_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.ORGANIZATION" />,
        showInDashboard: true,
        selector: 'ORGANIZATION',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.OWNER" />,
        showInDashboard: true,
        selector: 'OWNER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.GUARANTEE_PERIOD" />,
        showInDashboard: true,
        selector: 'GUARANTEE_PERIOD',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.LIFE_TIME" />,
        showInDashboard: true,
        selector: 'LIFE_TIME',
        isCard: true
    },


    {
        passportParameter: <Lang id="passport.fields.LOCATION.PROJECT" />,
        showInDashboard: true,
        selector: 'PROJECT',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.LATITUDE" />,
        showInDashboard: true,
        selector: 'latitude',
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.LONGITUDE" />,
        showInDashboard: true,
        selector: 'longitude',
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.REGION" />,
        showInDashboard: true,
        selector: 'REGION',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.CITY" />,
        showInDashboard: true,
        selector: 'CITY',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.STREET" />,
        showInDashboard: true,
        selector: 'STREET',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.HOME_NUMBER" />,
        showInDashboard: true,
        selector: 'HOME_NUMBER',
        isCard: true
    },


    {
        passportParameter: <Lang id="passport.fields.SERVICING.PLAN_PPR_DATE" />,
        showInDashboard: true,
        selector: 'PLAN_PPR_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.FACT_PPR_DATE" />,
        showInDashboard: true,
        selector: 'FACT_PPR_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.BYPASS_DATE" />,
        showInDashboard: true,
        selector: 'BYPASS_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.MAINTENANCE_DATE" />,
        showInDashboard: true,
        selector: 'MAINTENANCE_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.REPAIR_DATE" />,
        showInDashboard: true,
        selector: 'REPAIR_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.GUARANTEE_EXPIRATION_DATE" />,
        showInDashboard: true,
        selector: 'GUARANTEE_EXPIRATION_DATE',
        isCard: true
    },


    {
        passportParameter: <Lang id="passport.fields.ATTACHMENT.PHOTO_TITLE" />,
        showInDashboard: true,
        selector: 'PHOTO_TITLE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.ATTACHMENT.DOCUMENT_TITLE" />,
        showInDashboard: true,
        selector: 'DOCUMENT_TITLE',
        isCard: true
    },
]
