import {
  takeLatest, put, call,
} from 'redux-saga/effects'
import noop from 'lodash/noop'
import SockJS from 'sockjs-client'
import Stomp from 'stompjs'
import cookie from 'react-cookies'
import socketLogger from '@/helpers/loggers'
import {
  SOCKET_CONNECT,
  setSocketConnection,
} from '@/store/actions/socket'
import { requestGetIntegrations } from '@/store/actions/integrations/getIntegrations'
import { SLS24_ACCESS_TOKEN } from '@/constants/names'
import { requestUserLogout } from '@/store/actions/user/logout';
import createNotifications from '@/helpers/notification';
import { selectSystem, setMode } from '@/store/actions/integrations';

const connect = (client, token) => () => new Promise((resolve, reject) => {
  client.connect(
    { access_token: token },
    resolve,
    reject,
  )
})

function* connectToSocket({ payload }) {
  try {
    const { url, token } = payload
    const accessToken = token || cookie.load(SLS24_ACCESS_TOKEN)

    if (!accessToken) {
      yield put(requestUserLogout())
    }

    const socket = new SockJS(`${url}?access_token=${accessToken}`)
    const client = Stomp.over(socket)

    if (process.env.REACT_APP_SOCKET_DEBUG === 'true') {
      client.debug = socketLogger
    } else {
      client.debug = noop
    }

    const connectAnswer = yield call(connect(client, accessToken))

    yield put(setSocketConnection(client))

    if (connectAnswer && accessToken) {
      yield put(requestGetIntegrations())
    }
  } catch (error) {
    const toast = createNotifications()
    console.log('function*connectToSocket -> error', error);
    toast({
      title: 'Ошибка подключения',
      description: 'Соединение прервано.\nНевозможно получить данные.\nПовторите попытку позже.',
      type: 'error',
    })
    yield put(selectSystem(null))
    yield put(setMode('CONNECTED_INTEGRATION'))
  }
}

export default function* root() {
  yield takeLatest(SOCKET_CONNECT, connectToSocket)
}
