import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import {
  getRegions,
  getLoading,
} from '@/store/selectors/maps/socketEvents'
import {
  getGlobalFilters,
  getMapLayer,
  getPinnedNode,
  getSelectedNode,
  getUserApplications,
  getIsGlobalFilterOpen,
  getToggleRussianBorders,
} from '@/store/selectors/appSettings'
import { getImage } from '@/store/selectors/maps/objectImage'

import { setSelectedNode, setSearchTree} from '@/store/actions/appSettings'
import { requestObjectImage } from '@/store/actions/maps/getObjectImage'
import { refreshWidget } from '@/store/actions/maps/widgetSettings'
import Maps from './component'
import { requestGetCard } from '@/store/actions/installation/getCard';
import { requestGetObjectTelemetry } from '@/store/actions/installation/getObjectTelemetry';
import { requestGetNodeStatistic } from '@/store/actions/installation/getNodeStatistic';
import {
  getCard,
  getDisplayElementAndTelemetryRequestStatus,
  getDisplayElements,
  getRoot,
  getStreamUrl,
} from '@/store/selectors/installation'
import {
  getZones,
} from '@/store/selectors/passportization'
import {
  requestGetPinsAndZonesAndTelemetry,
  successGetPinsAndZonesAndTelemetry
} from '@/store/actions/installation/getZonesAndPinsAndTelemetry'
import { setDisplayElementAndTelemetryRequestStatus } from '@/store/actions/installation'
import { requestGetConnectedLinesCoordinates } from '@/store/actions/installation/getConnectedLinesCoordinates'

const mapStateToProps = (state) => ({
  roots: getRoot(state),
  regions: getRegions(state),
  loading: getLoading(state),
  selectedNode: getSelectedNode(state),
  zones: getZones(state),
  pinnedNode: getPinnedNode(state),
  objectImage: getImage(state),
  userApplications: getUserApplications(state),
  card: getCard(state),
  displayElements: getDisplayElements(state),
  displayElementAndTelemetryRequestStatus: getDisplayElementAndTelemetryRequestStatus(state),
  globalFilters: getGlobalFilters(state),
  mapLayer: getMapLayer(state),
  isGlobalFilterOpen: getIsGlobalFilterOpen(state),
  streamUrl: getStreamUrl(state),
  isRussianBorders: getToggleRussianBorders(state),
})

const mapDispatchToProps = (dispatch) => ({
  requestGetObjectTelemetry: (payload) => dispatch(requestGetObjectTelemetry(payload)),
  requestObjectImage: (payload) => dispatch(requestObjectImage(payload)),
  refreshIntegration: (payload) => dispatch(refreshWidget(payload)),
  setNode: (payload, withoutPinned) => dispatch(setSelectedNode(payload, withoutPinned)),
  requestGetCard: (payload) => dispatch(requestGetCard(payload)),
  requestGetNodeStatistic: (payload) => dispatch(requestGetNodeStatistic(payload)),
  requestGetPinsAndZonesAndTelemetry: (payload) => dispatch(requestGetPinsAndZonesAndTelemetry(payload)),
  setDisplayElementAndTelemetryRequestStatus: (payload) => dispatch(setDisplayElementAndTelemetryRequestStatus(payload)),
  successGetPinsAndZonesAndTelemetry: (payload) => dispatch(successGetPinsAndZonesAndTelemetry(payload)),
  requestGetConnectedLinesCoordinates: (payload) => dispatch(requestGetConnectedLinesCoordinates(payload)),
  setSearchTree: (payload) => dispatch(setSearchTree(payload)),
})

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(injectIntl(Maps))
