import React from 'react'
import memoWithName from '@/hocs/memoWithName'
import { childrenType } from '@/propTypes/react'
import AppHeaderContainer from '@/containers/regions/AppHeaderContainer'
import MenuSidebarContainer from '@/containers/regions/MenuSidebarContainer'
import UserSidebarContainer from '@/containers/regions/UserSidebarContainer'
import SettingsSidebarContainer from '@/containers/regions/SettingsSidebarContainer'
import AppFooter from '@/components/regions/AppFooter'
import {
    BaseLayoutStyled,
    LayoutContentWrapper,
    ContentWithoutSidebars,
    ErrorLayout,
    StyledText,
} from './styles'
import { ErrorBoundary, useErrorBoundary } from 'react-error-boundary'
import Button from '@/components/blocks/Button';
import useGetIsMobile from '@/hooks/useGetIsMobile'

function ErrorFallback() {
    const { resetBoundary } = useErrorBoundary()
    return (
        <ErrorLayout>
            <StyledText>Упс, что-то пошло не так...</StyledText>
            <Button styleType='info' onClick={resetBoundary}>Попробуйте снова</Button>
        </ErrorLayout>
    );
}


const BaseLayout = ({ children }) => {
  const isMobile = useGetIsMobile()

  return (
  <BaseLayoutStyled>
    {!isMobile && <AppHeaderContainer />}
    <LayoutContentWrapper>
      {!isMobile && <MenuSidebarContainer />}
        <ErrorBoundary
            FallbackComponent={ErrorFallback}>
            <ContentWithoutSidebars>
                {children}
            </ContentWithoutSidebars>
        </ErrorBoundary>
      <UserSidebarContainer />
      <SettingsSidebarContainer />
    </LayoutContentWrapper>
    {isMobile && <AppFooter/>}
  </BaseLayoutStyled>
)}

BaseLayout.propTypes = {
  children: childrenType.isRequired,
}

export default memoWithName(BaseLayout)
