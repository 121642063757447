import { connect } from 'react-redux'
import { injectIntl } from "react-intl";
import { requestAppealsTable } from '@/store/actions/serviceDeskMyCity/getTable'
import { requestGetInfoServiceDeskCard } from '@/store/actions/serviceDeskMyCity/getInfoCard'
import { resetServiceDeskTable } from '@/store/actions/serviceDeskMyCity'
import {
  getLoading,
  getTableData,
  getTypeOfObjects,
  getCard,
  getLoadingCard,
  getInfoCards
} from '@/store/selectors/serviceDeskMyCity'
import { requestGetInfoCards } from '@/store/actions/serviceDeskMyCity/getInfoCards'
import {
  getIntegrationStatus,
  getUserApplications,
  getUserData,
} from '@/store/selectors/appSettings'
import Appeals from './component'
import { getGlobalLoading } from '@/store/selectors/appSettings'

const mapStateToProps = (state) => ({
  loading: getLoading(state),
  data: getTableData(state),
  typeOfObjects: getTypeOfObjects(state),
  card: getCard(state),
  loadingCard: getLoadingCard(state),
  integrationStatus: getIntegrationStatus(state),
  userApplications: getUserApplications(state),
  currentUser: getUserData(state),
  infoCards: getInfoCards(state),
  globalLoading: getGlobalLoading(state),
})

const mapDispatchToProps = (dispatch) => ({
  requestAppealsTable: (payload) => dispatch(requestAppealsTable(payload)),
  requestGetInfoServiceDeskCard: (payload) => dispatch(requestGetInfoServiceDeskCard(payload)),
  resetServiceDeskTable: () => dispatch(resetServiceDeskTable()),
  getInfoCards: (payload) => dispatch(requestGetInfoCards(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Appeals))
