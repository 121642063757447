import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import useSvgColors from '@/hooks/useSvgColors'

const ReceivedIcon = ({ color, onClick }) => {
  const {
    fill,
  } = useSvgColors(color)

  return (
    <svg onClick={onClick} width="100%" height="100%" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.05">
        <path fillRule="evenodd" clipRule="evenodd" d="M14.3308 30.9642L95.9498 1.25727L97.5021 5.52203L95.9621 1.25284C101.086 -0.59591 106.729 -0.355983 111.657 1.9202C116.584 4.19639 120.397 8.3238 122.261 13.4007L122.266 13.4128L140.893 64.5899L140.897 64.6021C142.732 69.6896 142.465 75.302 140.153 80.2132C137.841 85.1243 133.673 88.935 128.559 90.8127L128.547 90.8172L84.9844 106.673L67.8194 135.949C66.9248 137.474 65.2256 138.352 63.4605 138.2C61.6953 138.047 60.1829 136.893 59.5805 135.238L53.3716 118.179L46.9279 120.524L46.9157 120.528C41.7913 122.377 36.1489 122.137 31.2211 119.861C26.2933 117.585 22.4808 113.458 20.6165 108.381L20.612 108.368L1.98068 57.1793C0.145475 52.0918 0.413006 46.4793 2.7248 41.5682C5.03659 36.657 9.20474 32.8463 14.3186 30.9686L14.3308 30.9642ZM99.0477 9.7892L17.4419 39.4913C14.5984 40.537 12.2809 42.6568 10.9951 45.3883C9.70933 48.1198 9.5598 51.241 10.579 54.0709L29.2011 105.235C30.2394 108.058 32.3603 110.353 35.101 111.619C37.8418 112.885 40.9799 113.019 43.8304 111.992L54.5628 108.086C56.9353 107.222 59.5535 108.432 60.4108 110.787L64.8568 123.002L77.5126 101.417C78.3219 100.036 79.5936 98.9751 81.1024 98.4261L125.436 82.2902C128.279 81.2445 130.597 79.1246 131.883 76.393C133.168 73.6615 133.318 70.5401 132.299 67.7101L113.677 16.5467C112.638 13.7236 110.518 11.4286 107.777 10.1626C105.036 8.89667 101.898 8.76249 99.0477 9.7892Z" fill={fill} className="svgFill" />
        <path fillRule="evenodd" clipRule="evenodd" d="M62.6075 32.7118C64.98 31.8483 67.5982 33.0577 68.4555 35.413L80.2305 67.7646L86.521 54.401C87.5924 52.1249 90.3123 51.1349 92.5961 52.1898C94.8799 53.2447 95.8627 55.945 94.7913 58.2211L83.8173 81.5342C82.7459 83.8103 80.026 84.8002 77.7422 83.7453L54.3503 72.9404C52.0665 71.8855 51.0837 69.1852 52.1551 66.9091C53.2265 64.633 55.9464 63.6431 58.2302 64.698L71.639 70.8916L59.864 38.5401C59.0067 36.1847 60.235 33.5753 62.6075 32.7118Z" fill={fill} className="svgFill" />
      </g>
    </svg>
  )
}

ReceivedIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ReceivedIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ReceivedIcon)
