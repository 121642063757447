import IntegrationItemIcon from '@/components/icons/core/IntegrationItemIcon'
import IntegrationSystemIcon from '@/components/icons/core/IntegrationSystemIcon'
import viewTreeIcons from '@/components/icons/maps'
import usersIcons from '@/components/icons/users'
import syncModuleIcons from '@/components/icons/sync'
import LoaderIcon from '@/components/blocks/Loader'
import LightBulbIcon from '@/components/icons/maps/LightBulbIcon'
import LampModuleIcon from '@/components/icons/maps/LampModuleIcon'
import ControllerIcon from '@/components/icons/maps/ControllerIcon'
import TrashpinIcon from '@/components/icons/maps/TrashpinIcon'
import ContractorIcon from '@/components/icons/maps/ContractorIcon'
import LEPIcon from '@/components/icons/maps/LEPIcon'
import PoleIcon from '@/components/icons/maps/PoleIcon'
import ControllerChipIcon from '@/components/icons/maps/ControllerChipIcon'
import SencorIcon from '@/components/icons/maps/SencorIcon'
import CheckBox from '@/components/controls/CheckBox'
import {
  ELECTRIC_METER as ELECTRIC_METER_SERVER,
  MULTIRATE_METER as MULTIRATE_METER_SERVER,
  CONTROLLER as CONTROLLER_SERVER,
  CONTACTOR as CONTACTOR_SERVER,
  BRANCH_CIRCUIT as BRANCH_CIRCUIT_SERVER,
  LIGHT_SENSOR as LIGHT_SENSOR_SERVER,
  PHOTO_SENSOR as PHOTO_SENSOR_SERVER,
  VOLUME_SENSOR as VOLUME_SENSOR_SERVER,
  FIRE_SENSOR as FIRE_SENSOR_SERVER,
  TRANSFER_SWITCH as TRANSFER_SWITCH_SERVER,
  DMX_DEVICE as DMX_DEVICE_SERVER,
  CONTROLLER_FUSE as CONTROLLER_FUSE_SERVER,
  FUSE as FUSE_SERVER,
  CHECKPOINT as CHECKPOINT_SERVER,
  CURRENT_SENSOR as CURRENT_SENSOR_SERVER,
  SHUO as SHUO_SERVER,
  CONTROL_CUPBOARD as CONTROL_CUPBOARD_SERVER,
  MULTI_RATE_METER as MULTI_RATE_METER_SERVER,
  METEO as METEO_SERVER,
  ROAD_TEMPERATURE_SENSOR as ROAD_TEMPERATURE_SENSOR_SERVER,
  DOOR_OPEN_SENSOR as DOOR_OPEN_SENSOR_SERVER,
  SMOKE_SENSOR as SMOKE_SENSOR_SERVER,
  BASE_STATION as BASE_STATION_SERVER,
  LIGHT_FIXTURE_GROUP as LIGHT_FIXTURE_GROUP_SERVER,
  UTILITY_POLE as UTILITY_POLE_SERVER,
  LIGHT_MODULE as LIGHT_MODULE_SERVER,
  MODULE as MODULE_SERVER,
  LINE as LINE_SERVER,
  DEVICE as DEVICE_SERVER,
  WATHER_SUPPLY as WATHER_SUPPLY_SERVER,
  CENTRAL_HEATING as CENTRAL_HEATING_SERVER,
  SWITCHING_GROUP as SWITCHING_GROUP_SERVER,
  STREETLIGHT_CONTROL_CABINET as STREETLIGHT_CONTROL_CABINET_SERVER,
  STREETLIGHT_GROUP as STREETLIGHT_GROUP_SERVER,
  STREETLIGHT as STREETLIGHT_SERVER,
  WASTE_CONTAINER as WASTE_CONTAINER_SERVER,
  SENSOR as SENSOR_SERVER,
  LAMP_MODULE as LAMP_MODULE_SERVER,
  PHASE_CIRCUIT_BREAKER as PHASE_CIRCUIT_BREAKER_SERVER,
  GROUP as GROUP_SERVER,
} from '@/constants/instalationPassport/types'
import {
  COUNTRY,
  REGION,
  CITY,
  CONTROLLER,
  LIGHT,
  GROUP_OBJECT,
  HEATING_DEVICE,
  COLD_WATER_DEVICE,
  SHUO,
  LIGHT_SENSOR,
  TRASH,
  GEOZONE,
  PROJECT,
  ROOT,
  INTEGRATION,
  OBJECT_ELEMENT,

  ELECTRIC_METER,
  CONTACTORS,
  CONTACTOR,
  FUSE,
  PHASE,
  CURRENT_SENSOR,
  BRANCH_CIRCUIT,
  CHECK_POINT,
  UNICERSE_METER,
  DMX_DEVICE,
  CENTRAL_HEATING_DEVICE,
} from '@/constants/objectTypes'
import {
  LOADING,
} from '@/constants/semanticNames'
import {
  KULON,
  BRIZ,
  ATM,
  MESH,
  UNILIGHT,
} from '@/constants/integrations'
import {
  SYCN,
} from '@/constants/maps'

export const CHAR_BY_DEPTH = {
  1: 25,
  2: 20,
  3: 16,
  4: 14,
  5: 10,
  6: 7,
}

export const CHAR_BY_DEPTH_SMALL = {
  1: 20,
  2: 15,
  3: 11,
  4: 9,
  5: 5,
  6: 2,
}

export const CHAR_BY_DEPTH_DEFAULT = 15
export const CHAR_FOR_DASHBORD = 13
export const CHAR_BY_FOR_SYNC = 12

export const CHAR_LIMIT_VIEW_TREE = 20
export const CHAR_LIMIT_INTEGRATION_TREE = 20
export const CHAR_LIMIT_DEFAULT = 20

export const COMPANY = 'company'
export const DEV_DEPARTMENT = 'devDepartment'
export const SAIL_DEPARTMENT = 'sailDepartment'
export const GROUP = 'group'
export const GROUPS = 'groups'
export const USER = 'user'
export const USER_EDIT = 'user_edit'
export const ADMIN = 'admin'
export const ADMIN_EDIT = 'admin_edit'
export const TENANT = 'tenant'
export const TENANT_EDIT = 'tenant_edit'
export const GROUPS_EDIT = 'groups_edit'
export const USER_EDIT_FROM_ADMIN = 'user_edit_from_admin'
export const USERS_GROUP = 'UserGroup'

export const INTEGRATION_SYSTEM = 'integrationSystem'
export const INTEGRATION_ZONE = 'integrationZone'

export const CHECK_BOX = 'checkBox'

export const INTEGRATION_TREE_IDENTIFIRES = [
  INTEGRATION_SYSTEM,
  INTEGRATION_ZONE,
]
export const MAP_OBJECT_TREE_IDENTIFIRES = [
  COUNTRY,
  REGION,
  CITY,
  CONTROLLER,
  LIGHT,
  GROUP_OBJECT,
  HEATING_DEVICE,
  CENTRAL_HEATING_DEVICE,
  COLD_WATER_DEVICE,
  SHUO,
]

export default {
  [REGION]: viewTreeIcons.ZoneFolderIcon,
  [CITY]: viewTreeIcons.ZoneFolderIcon,
  [COUNTRY]: viewTreeIcons.CountrieFolderIcon,
  [CONTROLLER]: viewTreeIcons.ControllerIcon,
  CONTROLLER: viewTreeIcons.ControllerIcon,
  COMPANY_OBJECT: viewTreeIcons.ControllerIcon,
  SHUO: viewTreeIcons.ControllerIcon,
  [OBJECT_ELEMENT]: viewTreeIcons.ControllerIcon,
  [SHUO]: viewTreeIcons.ControllerIcon,
  [SHUO]: viewTreeIcons.ControllerIcon,
  [LIGHT]: viewTreeIcons.LightBulbIcon,
  LIGHT_POINT: viewTreeIcons.LightBulbIcon,
  LIGHT_FIXTURE: viewTreeIcons.LightBulbIcon,
  LAMP: viewTreeIcons.LightBulbIcon,
  [GROUP_OBJECT]: viewTreeIcons.ConstrollIcon,
  [GROUP_SERVER]: viewTreeIcons.GroupObjectIcon,
  [HEATING_DEVICE]: viewTreeIcons.HeatIcon,
  [CENTRAL_HEATING_DEVICE]: viewTreeIcons.HeatIcon,
  CENTRAL_HEATING_DEVICE: viewTreeIcons.HeatIcon,
  [COLD_WATER_DEVICE]: viewTreeIcons.WaterIcon,
  COLD_WATER_DEVICE: viewTreeIcons.WaterIcon,
  [COMPANY]: usersIcons.TenantFolderIcon,
  [DEV_DEPARTMENT]: usersIcons.DepartmentIcon,
  [SAIL_DEPARTMENT]: usersIcons.DepartmentIcon,
  [ADMIN]: usersIcons.AdminIcon,
  [GROUP]: usersIcons.UserGroupIcon,
  [USER]: usersIcons.UserIcon,
  [INTEGRATION_SYSTEM]: IntegrationSystemIcon,
  [INTEGRATION_ZONE]: IntegrationItemIcon,
  [KULON]: viewTreeIcons.LightBulbIcon,
  [UNILIGHT]: viewTreeIcons.LightBulbIcon,
  [MESH]: viewTreeIcons.LightBulbIcon,
  [BRIZ]: syncModuleIcons.BrizIcon,
  [ATM]: syncModuleIcons.SimIcon,
  [SYCN]: viewTreeIcons.ToogleIcon,
  [USERS_GROUP]: usersIcons.UsersGroupIcon,
  [LIGHT_SENSOR]: viewTreeIcons.SencorIcon,
  [TRASH]: viewTreeIcons.TrashpinIcon,
  [GEOZONE]: viewTreeIcons.ZoneFolderIcon,
  [PROJECT]: viewTreeIcons.ConstrollIcon,
  [ROOT]: viewTreeIcons.CountrieFolderIcon,
  [INTEGRATION]: viewTreeIcons.CountrieFolderIcon,
  [LOADING]: LoaderIcon,
  [CHECK_BOX]: CheckBox,
  arrow: viewTreeIcons.ArrowIcon,
  [ELECTRIC_METER]: viewTreeIcons.SencorIcon,
  ELECTRIC_METER: viewTreeIcons.SencorIcon,
  SENSOR: viewTreeIcons.SencorIcon,
  [CONTACTORS]: viewTreeIcons.SencorIcon,
  [CONTACTOR]: viewTreeIcons.SencorIcon,
  [FUSE]: viewTreeIcons.SencorIcon,
  [PHASE]: viewTreeIcons.SencorIcon,
  [CURRENT_SENSOR]: viewTreeIcons.SencorIcon,
  [BRANCH_CIRCUIT]: viewTreeIcons.SencorIcon,
  [PHASE_CIRCUIT_BREAKER_SERVER]: ContractorIcon,
  [CHECK_POINT]: viewTreeIcons.SencorIcon,
  [UNICERSE_METER]: viewTreeIcons.SencorIcon,
  [DMX_DEVICE]: viewTreeIcons.SencorIcon,
  SWITCHING_GROUP: viewTreeIcons.ControllerIcon,
  [SENSOR_SERVER]: SencorIcon,
  [CONTROLLER_SERVER]: ControllerIcon,
  [SHUO_SERVER]: ControllerIcon,
  [BASE_STATION_SERVER]: ControllerIcon,
  BASE_STATION: ControllerIcon,
  [WATHER_SUPPLY_SERVER]: syncModuleIcons.SimIcon,
  [CENTRAL_HEATING_SERVER]: syncModuleIcons.BrizIcon,
  [ELECTRIC_METER_SERVER]: ContractorIcon,
  [MULTIRATE_METER_SERVER]: ContractorIcon,
  [CONTACTOR_SERVER]: ContractorIcon,
  [BRANCH_CIRCUIT_SERVER]: ContractorIcon,
  [LIGHT_SENSOR_SERVER]: SencorIcon,
  [PHOTO_SENSOR_SERVER]: SencorIcon,
  [VOLUME_SENSOR_SERVER]: SencorIcon,
  [FIRE_SENSOR_SERVER]: SencorIcon,
  [TRANSFER_SWITCH_SERVER]: ControllerChipIcon,
  [DMX_DEVICE_SERVER]: ControllerChipIcon,
  [CONTROLLER_FUSE_SERVER]: ControllerIcon,
  [FUSE_SERVER]: ControllerIcon,
  [CHECKPOINT_SERVER]: ContractorIcon,
  [CURRENT_SENSOR_SERVER]: SencorIcon,
  [CONTROL_CUPBOARD_SERVER]: ContractorIcon,
  [MULTI_RATE_METER_SERVER]: ContractorIcon,
  [METEO_SERVER]: SencorIcon,
  [ROAD_TEMPERATURE_SENSOR_SERVER]: SencorIcon,
  [DOOR_OPEN_SENSOR_SERVER]: SencorIcon,
  [SMOKE_SENSOR_SERVER]: SencorIcon,
  [LIGHT_FIXTURE_GROUP_SERVER]: PoleIcon,
  [LIGHT_MODULE_SERVER]: LightBulbIcon,
  [MODULE_SERVER]: LightBulbIcon,
  [DEVICE_SERVER]: ControllerChipIcon,
  [SWITCHING_GROUP_SERVER]: PoleIcon,
  [STREETLIGHT_CONTROL_CABINET_SERVER]: ControllerChipIcon,
  [STREETLIGHT_GROUP_SERVER]: PoleIcon,
  [STREETLIGHT_SERVER]: LightBulbIcon,
  [WASTE_CONTAINER_SERVER]: TrashpinIcon,
  [UTILITY_POLE_SERVER]: PoleIcon,
  [LINE_SERVER]: LEPIcon,
  [LAMP_MODULE_SERVER]: LampModuleIcon,
}

export const getKeyByType = (node) => {
  switch (node.type) {
    case COMPANY:
      return `${node.id}-${node.usersAmount}`
    case GROUP:
      return `${node.id}-${node.tenantId}-${node.usersAmount}`
    default:
      return `${node.id}-${node.usersAmount}`
  }
}
