export default {
  required: 'Required field',
  number: 'Number',
  latitudeMax: 'No more than 90°',
  latitudeMin: 'Not less than -90°',
  float6Digits: 'Fractional part - 6 characters',
  longitudeMax: 'No more than 180°',
  longitudeMin: 'Not less than -180°',
  radiusMin: 'At least 3 km',
  integer: 'Integer',
  integerMoreThan0: 'Integer greater than 0',
  integerLessThan60: 'Integer up to 60',
  integerLessThan24: 'Integer up to 24',
  minLength6: 'Minimum length - 6 characters',
  maxLength15: 'Maximum length - 15 characters',
  maxLength25: 'Maximum length - 25 characters',
  maxLength30: 'Maximum length - 30 characters',
  maxLength35: 'Maximum length - 35 characters',
  maxLength50: 'Maximum length - 50 characters',
  maxLength64: 'Maximum length - 64 characters',
  maxLength128: 'Maximum length - 128 characters',
  maxLength150: 'Maximum length - 150 characters',
  maxLength300: 'Maximum length - 300 characters',
  valueFrom1To65535: 'Value from 1 to 65535',
  urlFormat: 'Does not match the format. Example: http://example.com/api',
  clientIdExist: 'This ClientID already exists in the system',
  secretIdExist: 'This SecretID already exists in the system',
  emailIncorrect: 'Enter a correct email',
  noLimitSet: 'No limit value set!',
  numbersOnly: 'Numbers only',
  minLevelMoreThanMax: 'The minimum threshold is greater than the maximum',
  phoneNumberFormat: 'Phone number format 7-111-111-11-11',
  fileLessThan1Mb: 'The file must be up to 1 MB',
  fileFormat: 'The file format must be .png, .jpg, .jpeg',
  fileProportion: 'Aspect ratio 1:1 (square), minimum resolution 100x100',
  dateFormat: 'Date does not meet requirements',
  dateStartMoreThanEnd: 'Start date is greater than end date',
  enterPassword: 'Enter password',
  passwordMinLength: 'Password length is at least 8 characters',
  passwordNotValid: 'Password does not meet requirements',
  passwordsNotMatch: 'Password mismatch',
  errorTryLater: 'Error. Please try again later',
  passwordTimesLimit: 'The password entry limit has been exceeded. Please try again later',
  passwordExpired: 'The password is out of date. Check your email to set a new password',
  incorrectCredentials: 'Wrong login or password',
  tenantBlocked: 'User organization is blocked',
  usersGroupBlocked: 'User group is blocked',
  accessDenied: 'Access denied! Contact your workgroup Administrator or try again later.',
  objectsMin: 'You must select at least one object',
}