import React from 'react'
import { toast } from 'react-toastify'
import Notifications from '@/components/blocks/Notifications'

const createNotifications = () => ({
  type,
  title,
  description,
  autoClose,
  update,
  toastId
}, options) => {
    if (!update || !toast.isActive(toastId)) {
        return (
            toast(
                <Notifications
                    title={title}
                    type={type}
                    description={description}
                />,
                {
                    autoClose,
                    toastId,
                    ...options
                }
            )
        )
    } else if (toast.isActive(toastId)) {
        toast.update(toastId,{
            render: () => (
                <Notifications
                title={title}
                type={type}
                description={description}
            />),
            autoClose,
            ...options
        })
    }

}

export default createNotifications
