import React, { useState, useMemo, useCallback } from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import pt from 'prop-types'
import noop from 'lodash/noop'
import CoreIcons from '@/components/icons/core'
import {
  TextFieldStyled,
  Label,
  FileInput,
  FieldStyled,
  Error,
  ControlsContainer,
  FieldStyledContainer,
  TextInfo,
  IconContainer,
} from './styles'

const FileControll = ({
  dark,
  className,
  icon,
  label,
  type,
  format,
  onChange,
  onBlur,
  name,
  disabled,
  fieldIcon,
  placeholder,
  value,
  error,
  pattern,
  mask,
  customError,
  controls: Controls,
  handleChange: onHandleChange,
  setImage,
  onlyRead,
  gray,
  download,
  styleType,
  labelIcon: LabelIcon,
  textInfo,
  lowerCasePlaceholder,
  onClick,
  disableDefaultColor,
  doNotDisplayTrashIcon,
  ...ownProps
}) => {
  const inputFile = React.createRef()
  const [focused, setFocused] = useState(false)
  // const [filled, setFilled] = useState(!!value)
  const isError = useMemo(() => !!error, [error])
  const toBase64 = (file) => new Promise((resolve, reject) => {
    if (!file) {
      return null
    }
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (loadError) => reject(loadError)
  })

  const handlePhotoUpload = async (fileValue) => {
    const base64 = await toBase64(fileValue)
    setImage(base64)
  }
  const handleChange = (event) => {
    if (event.target.files[0]) {
      const fileValue = event.target.files[0]
      if (type === 'photo') {
        handlePhotoUpload(fileValue)
      }
      onChange(fileValue)
    }
  }

  const handleFocus = () => {
    setFocused(true)
  }

  const handleBlur = (event) => {
    setFocused(false)
    onBlur(event)
  }

  const handlePlaceholder = useMemo(() => {
    if (value && (value.title || value.name)) {
      return value.title || value.name || ''
    }
    return type === 'photo' ? 'загрузите изображение' : 'загрузите файл'
  }, [type, value])

  const handleClearFile = useCallback((event) => {
    event.stopPropagation()
    if (inputFile.current) {
      inputFile.current.value = null
    }
    onChange(undefined)
  }, [inputFile, onChange])

  const renderPlaceHolder = useMemo(() => {
    if (value && typeof value !== 'string') {
      return label || handlePlaceholder
    }
    if (value && typeof value === 'string') {
      return value
    }
    return placeholder
  }, [handlePlaceholder, label, placeholder, value])

  return (
    <TextFieldStyled
      dark={dark}
      className={className}
      fieldIcon={fieldIcon || !!icon}
      focused={focused}
      filled={!!value}
      disabled={disabled}
      isError={isError}
      gray={gray}
      styleType={styleType}
      onClick={onClick}
      disableDefaultColor={disableDefaultColor}
    >
      <Label htmlFor={name} styleType={styleType}>
        {icon && !value && icon}
      </Label>
      {Controls
        && (
          <ControlsContainer>
            {Controls}
          </ControlsContainer>
        )}
      <FieldStyledContainer>
        <FieldStyled
          name={name}
          {...ownProps}
          type="text"
          isError={isError}
          disabled={disabled}
          placeholderCenter={!(value && (value.title || value.name)) && typeof value !== 'string'}
          upperCase={!label && !lowerCasePlaceholder}
          placeholder={renderPlaceHolder}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={onHandleChange}
          gray
          styleType={styleType}
          currentValue={value}
          tabindex="-1"
          controls={Controls}
          icon={icon && !value}
          action={!!onClick}
        />
        {!disabled && (
          <FileInput
            {...ownProps}
            id={name}
            ref={inputFile}
            mask={mask}
            type="file"
            onBlur={handleBlur}
            onFocus={handleFocus}
            onChange={handleChange}
            disabled={onlyRead || download || typeof value === 'string'}
            tabindex="-1"
          />
        )}
        {styleType === 'secondary' && !value && (
          <TextInfo>{textInfo}</TextInfo>
        )}
        {!doNotDisplayTrashIcon && styleType === 'secondary' && value && typeof value !== 'string' && (
          <IconContainer>
            <CoreIcons.TrashIcon onClick={handleClearFile} />
          </IconContainer>
        )}
      </FieldStyledContainer>
      {customError
        ? null
        : !!error && (
          <Error>
            <Lang id={error} />
          </Error>
        )}
    </TextFieldStyled>
  )
}

FileControll.propTypes = {
  className: pt.string,
  error: pt.string,
  pattern: pt.string,
  mask: pt.string,
  icon: pt.oneOfType([pt.element, pt.object, pt.string]),
  label: pt.oneOfType([pt.string, pt.element]),
  placeholder: pt.oneOfType([pt.string, pt.element]),
  value: pt.oneOfType([pt.string, pt.number]),
  type: pt.string,
  format: pt.string,
  name: pt.string.isRequired,
  disabled: pt.bool,
  fieldIcon: pt.bool,
  customError: pt.bool,
  lowerCasePlaceholder: pt.bool,
  onlyRead: pt.bool,
  dark: pt.bool,
  doNotDisplayTrashIcon: pt.bool,
  onChange: pt.func,
  handleChange: pt.func,
  onBlur: pt.func,
  controls: pt.element,
}
FileControll.defaultProps = {
  className: '',
  pattern: '',
  mask: null,
  icon: null,
  label: null,
  placeholder: null,
  value: undefined,
  type: 'text',
  format: 'text',
  disabled: false,
  onlyRead: false,
  fieldIcon: false,
  customError: false,
  lowerCasePlaceholder: false,
  doNotDisplayTrashIcon: false,
  dark: false,
  error: null,
  onBlur: noop,
  onChange: noop,
  handleChange: noop,
  controls: null,
}

export default React.memo(FileControll)
