import {
    put, takeLatest,
} from 'redux-saga/effects'
import request from "@/helpers/axios";
import { INFRASTRUCTURE_COUNT_DASHBOARD } from '@/constants/apiRoutes';
import { GET_INFRASTRUCTURE_COUNT } from '@/store/actions/widgetDashboard';
import {
    getInfrastructureCountError,
    getInfrastructureCountSuccess
} from '@/store/actions/widgetDashboard/infrastructureCount';

function* getInfrastructureCount({ payload }) {
    const { id } = payload
    try {
        const response = yield request({
            url: INFRASTRUCTURE_COUNT_DASHBOARD({
                params: {
                    parentId: id
                }
            }),
            method: 'get',
        })
        yield put(getInfrastructureCountSuccess(response.data))
    } catch (error) {
        yield put(getInfrastructureCountError(error))
    }
    return null
}

export default function* () {
    yield takeLatest(GET_INFRASTRUCTURE_COUNT.REQUEST, getInfrastructureCount)
}