import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import moment from 'moment'
import request from '@/helpers/axios'
import {
  GET_REPORT_MANAGER_TABLE,
  REPORT_MANAGER_TABLE,
} from '@/store/actions/reportManager'
import { REPORT_MANAGER_TABLE_URL } from '@/constants/apiRoutes'
import {
  successReportManagerTable,
  errorReportManagerTable,
} from '@/store/actions/reportManager/getTable'
import {
  getTableParameters,
} from '@/store/selectors/reportManager'
import getReportManagerSchedule from '@/helpers/getReportManagerSchedule'
import { getSelectedNode } from '@/store/selectors/appSettings'
import createNotifications from '@/helpers/notification'
import {
  GEOZONE,
  ROOT,
} from '@/constants/objectTypes'
import { setGlobalLoading } from '@/store/actions/appSettings'

export const TIMEZONE_CONFIG = {
  3: '(MSK)',
  2: '(MSK–1)',
  4: '(MSK+1)',
  5: '(MSK+2)',
  6: '(MSK+3)',
  7: '(MSK+4)',
  8: '(MSK+5)',
  9: '(MSK+6)',
  10: '(MSK+7)',
  11: '(MSK+8)',
  12: '(MSK+9)',
  1: '(MSK–2)',
  0: '(MSK–3)',
  '-1': '(MSK–4)',
  '-2': '(MSK–5)',
  '-3': '(MSK–6)',
  '-4': '(MSK–7)',
  '-5': '(MSK–8)',
  '-6': '(MSK–9)',
  '-7': '(MSK–10)',
  '-8': '(MSK–11)',
  '-9': '(MSK–12)',
  '-10': '(MSK–13)',
  '-11': '(MSK–14)',
  '-12': '(MSK–15)',
}

function* getReportManagerTableSaga() {
  const tableParameters = yield select(getTableParameters)
  const node = yield select(getSelectedNode)
  const toast = createNotifications()
  const getGeoZoneId = () => {
    if (node.type === ROOT) {
      return null
    }
    return node.type === GEOZONE ? node.id : node.path[1]
  }
  try {
    yield put(setGlobalLoading(true))
    const response = yield request({
      url: REPORT_MANAGER_TABLE_URL({
        params: {
          geoZoneIds: getGeoZoneId(),
          filterBy: tableParameters.requestQuery || '',
          sortOrder: tableParameters.sort.direction,
          sortBy: tableParameters.sort.field,
          page: tableParameters.page - 1,
          pageSize: tableParameters.perPage,
        },
      }),
      method: 'get',
    })

    const newResponse = response.data.items.map((tableElement) => ({
      ...tableElement,
      geoZoneName: {
        id: tableElement.geoZoneId,
        title: tableElement.geoZoneName,
      },
      schedule: getReportManagerSchedule({
        type: tableElement.schedule.type,
        time: tableElement.schedule.start,
        value: tableElement.schedule.timeValue,
        timeZone: (tableElement.schedule.timeZone || 0) * 60,
        timeZoneConfig: TIMEZONE_CONFIG,
      }),
      externalRecipients: (tableElement.users[0] || []).firstName > (tableElement.externalRecipients[0] || []).name
        ? [...tableElement.externalRecipients, ...tableElement.users]
        : [...tableElement.users, ...tableElement.externalRecipients],
      lastSendDate: tableElement.lastSendDate
        ? `${moment(tableElement.lastSendDate).utcOffset((tableElement.schedule.timeZone || 0) * 60).format('DD.MM.YYYY HH:mm:ss')} ${TIMEZONE_CONFIG[tableElement.schedule.timeZone]}`
        : '',
      nextSendDate: tableElement.nextSendDate
        ? `${moment(tableElement.nextSendDate).utcOffset((tableElement.schedule.timeZone || 0) * 60).format('DD.MM.YYYY HH:mm:ss')} ${TIMEZONE_CONFIG[tableElement.schedule.timeZone]}`
        : '',
    }))
    yield put(setGlobalLoading(false))
    yield put(successReportManagerTable({
      data: newResponse,
      totalElements: response.data.itemsAmount,
    }))
  } catch (error) {
    yield put(setGlobalLoading(false))
    yield put(errorReportManagerTable(error))
    const { details } = error.response.data
    if (details.length === 0) {
      toast({
        title: 'Ошибка операции!',
        type: 'error',
        description: 'Не удалось получить список отчетов.\nПовторите попытку позже.',
      })
    }
  }
  return null
}

export default function* root() {
  yield takeLatest(GET_REPORT_MANAGER_TABLE.REQUEST, getReportManagerTableSaga)
  yield takeLatest(REPORT_MANAGER_TABLE.SORT, getReportManagerTableSaga)
  yield takeLatest(REPORT_MANAGER_TABLE.PER_PAGE, getReportManagerTableSaga)
  yield takeLatest(REPORT_MANAGER_TABLE.PAGE, getReportManagerTableSaga)
  yield takeLatest(REPORT_MANAGER_TABLE.REQUEST_QUERY, getReportManagerTableSaga)
}
