import {
  put, takeLatest, select, call
} from 'redux-saga/effects'
import { treeHandlers } from 'react-hyper-tree'
import request from '@/helpers/axios'
import { EDIT_INTEGRATION } from '@/store/actions/installation'
import {
  INSTALLATIONS_INTEGRATION_ENDPOINT,
} from '@/constants/apiRoutes'
import { successEditIntegration } from '@/store/actions/installation/editIntegration'
import updatePath from '@/helpers/updatePath'
import createNotifications from '@/helpers/notification'
import { getCard } from '@/store/selectors/installation'
import { INTEGRATION } from '@/constants/objectTypes'
import { setGlobalLoading } from '@/store/actions/appSettings'

function* createIntegrationSaga({ payload }) {
  const {
    formValues, values, setUrlFormValues,
  } = payload
  const {
    name, parentId, integrationType,
  } = values
  const toast = createNotifications()
  try {
    yield put(setGlobalLoading(true))
    const cardCopy = yield select(getCard)
    const body = {
      id: formValues.id,
      parentId,
      parentName: cardCopy.data.parentName,
      point: cardCopy.data.point,
      name,
      integrationType,
    }
    const { data } = yield request({
      url: INSTALLATIONS_INTEGRATION_ENDPOINT,
      method: 'post',
      body,
    })
    const {
      rootId, FILTER, path,
    } = formValues
    const newPath = updatePath(path, parentId, `${data.parentId}/${data.id}`)
    const tree = treeHandlers.trees['installations-tree']
    const parent = tree.instance.getNodeById(data.parentId)
    const parentChildren = yield call(parent.data.getChildren, parent)
    tree.handlers.setRawChildren(parent, parentChildren, 'first', true)
    const node = tree.instance.getNodeById(data.id)
    tree.handlers.setOpen(node, true)
    tree.handlers.setSelected(node, true)
    tree.handlers.rerender()
    setUrlFormValues({
      id: data.id,
      type: INTEGRATION,
      integrationType: data.integrationType,
      parentGeoZoneId: data.parentId,
      name: data.name,
      rootId,
      parentTreeId: data.parentId,
      path: newPath,
      FILTER,
    })
    yield put(setGlobalLoading(false))
    toast({
      title: 'Редактирование интеграции',
      type: 'success',
      description: 'Изменения сохранены.',
    })
    yield put(successEditIntegration())
  } catch (error) {
    yield put(setGlobalLoading(false))
    const { FILTER } = formValues
    setUrlFormValues({ FILTER })
    console.log('function*editIntegrationSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(EDIT_INTEGRATION.REQUEST, createIntegrationSaga)
}
