import { handleActions } from 'redux-actions'
import { REQUEST_STATUSES } from '@/constants/requests'
import {
// TENANTS
    GET_ALL_TENANTS,
    CREATE_TENANT,
    GET_TENANT,
    UPDATE_TENANT,
    DELETE_TENANT,
    // ADMINS
    GET_TENANT_ADMINS,
    CREATE_TENANT_ADMIN,
    GET_TENANT_ADMIN,
    DELETE_TENANT_ADMIN,
    // GROUP
    CREATE_GROUP,
    GET_GROUP,
    UPDATE_GROUP,
    DELETE_GROUP,
    GET_TENANT_GROUPS,
    // USERS
    CREATE_USER,
    GET_GROUP_USERS,
    UPDATE_USER,
    DELETE_USER,
    MULTIPLE_EDITING,
    MULTIPLE_DELETE,
    UPDATE_USER_FROM_ADMIN,
    // COMMON
    UPDATE_SELECTED_USER_TYPE,
    UPDATE_SELECTED_TENANT,
    USERS_SET_ENTITY,
    USERS_SORT,
    GET_ALL_SORTED_USERS,
    IS_FORM_MODIFYING,
    GET_ONLINE_USERS,
} from '@/store/actions/users'
import { USER_LOGOUT } from '@/store/actions/user'

const initialState = {
  type: null,
  tenants: [],
  admins: {},
  users: {},
  groups: {},
  selectedTenant: {},
  edit: false,
  selectedEntity: {},
  userSortBy: null,
  userSortOrder: null,
  status: REQUEST_STATUSES.NOT_REQUESTED,
  sortedUsers: [],
  isFormModifying: false,
  onlineUsers: [],
}

export const reducer = handleActions(
  {
    // COMMON
    [IS_FORM_MODIFYING]: (state, { payload }) => ({
      ...state,
      isFormModifying: payload,
    }),
    [UPDATE_SELECTED_USER_TYPE]: (state, { payload }) => ({
      ...state,
      type: payload,
    }),
    [USERS_SET_ENTITY]: (state, { payload }) => ({
      ...state,
      selectedEntity: payload,
    }),
    [UPDATE_SELECTED_TENANT]: (state, { payload }) => ({
      ...state,
      selectedTenant: payload,
    }),
    [USERS_SORT]: (state, { payload: { sortType, sortValue } }) => ({
      ...state,
      userSortBy: sortType,
      userSortOrder: sortValue,
    }),
    [GET_ALL_SORTED_USERS]: (state, { payload }) => ({
      ...state,
      sortedUsers: payload,
    }),
    [USER_LOGOUT.REQUEST]: () => ({
      ...initialState,
    }),
    // TENANTS
    [GET_ALL_TENANTS.SUCCESS]: (state, { payload }) => ({
      ...state,
      tenants: payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [CREATE_TENANT.REQUEST]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.PENDING,
    }),
    [CREATE_TENANT.SUCCESS]: (state, { payload }) => ({
      ...state,
      tenants: payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [CREATE_TENANT.ERROR]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.IDLE,
    }),
    [GET_TENANT.SUCCESS]: (state, { payload }) => ({
      ...state,
      selectedEntity: payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [UPDATE_TENANT.REQUEST]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.PENDING,
    }),
    [UPDATE_TENANT.SUCCESS]: (state, { payload }) => ({
      ...state,
      tenants: payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [UPDATE_TENANT.ERROR]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.IDLE,
    }),
    [DELETE_TENANT.REQUEST]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.PENDING,
    }),
    [DELETE_TENANT.SUCCESS]: (state, { payload }) => ({
      ...state,
      tenants: payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [DELETE_TENANT.ERROR]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.IDLE,
    }),
    // ADMINS
    [GET_TENANT_ADMINS.SUCCESS]: (state, { payload }) => ({
      ...state,
      admins: payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [GET_TENANT_ADMINS.ERROR]: (state, { payload }) => ({
      ...state,
      tenants: payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [CREATE_TENANT_ADMIN.REQUEST]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.PENDING,
    }),
    [CREATE_TENANT_ADMIN.SUCCESS]: (state, { payload }) => ({
      ...state,
      admins: payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [CREATE_TENANT_ADMIN.ERROR]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.IDLE,
    }),
    [GET_TENANT_ADMIN.SUCCESS]: (state, { payload }) => ({
      ...state,
      selectedEntity: payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [DELETE_TENANT_ADMIN.REQUEST]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.PENDING,
    }),
    [DELETE_TENANT_ADMIN.SUCCESS]: (state, { payload }) => ({
      ...state,
      admins: payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [DELETE_TENANT_ADMIN.ERROR]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.IDLE,
    }),
    // GROUP
    [GET_TENANT_GROUPS.SUCCESS]: (state, { payload }) => ({
      ...state,
      groups: payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [GET_TENANT_GROUPS.ERROR]: (state, { payload }) => ({
      ...state,
      tenants: payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [CREATE_GROUP.REQUEST]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.PENDING,
    }),
    [CREATE_GROUP.SUCCESS]: (state, { payload }) => ({
      ...state,
      groups: payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [CREATE_GROUP.ERROR]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.IDLE,
    }),
    [GET_GROUP.SUCCESS]: (state, { payload }) => ({
      ...state,
      selectedEntity: payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [UPDATE_GROUP.REQUEST]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.PENDING,
    }),
    [UPDATE_GROUP.SUCCESS]: (state, { payload }) => ({
      ...state,
      groups: payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [UPDATE_GROUP.ERROR]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.IDLE,
    }),
    [DELETE_GROUP.REQUEST]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.PENDING,
    }),
    [DELETE_GROUP.SUCCESS]: (state, { payload }) => ({
      ...state,
      groups: payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [DELETE_GROUP.ERROR]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.IDLE,
    }),
    // USERS
    [UPDATE_USER.REQUEST]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.PENDING,
    }),
    [UPDATE_USER.SUCCESS]: (state, { payload }) => ({
      ...state,
      admins: payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [UPDATE_USER.ERROR]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.IDLE,
    }),
    [CREATE_USER.REQUEST]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.PENDING,
    }),
    [CREATE_USER.SUCCESS]: (state, { payload }) => ({
      ...state,
      admins: payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [CREATE_USER.ERROR]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.IDLE,
    }),
    [DELETE_USER.REQUEST]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.PENDING,
    }),
    [DELETE_USER.SUCCESS]: (state, { payload }) => ({
      ...state,
      admins: payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [DELETE_USER.ERROR]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.IDLE,
    }),
    [MULTIPLE_EDITING.REQUEST]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.PENDING,
    }),
    [MULTIPLE_EDITING.SUCCESS]: (state, { payload }) => ({
      ...state,
      admins: payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [MULTIPLE_EDITING.ERROR]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.IDLE,
    }),
    [MULTIPLE_DELETE.REQUEST]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.PENDING,
    }),
    [MULTIPLE_DELETE.SUCCESS]: (state, { payload }) => ({
      ...state,
      status: REQUEST_STATUSES.IDLE,
    }),
    [MULTIPLE_DELETE.ERROR]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.IDLE,
    }),
    [UPDATE_USER.REQUEST]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.PENDING,
    }),
    [UPDATE_USER.SUCCESS]: (state, { payload }) => ({
      ...state,
      admins: payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [GET_GROUP_USERS.SUCCESS]: (state, { payload }) => ({
      ...state,
      users: payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [UPDATE_USER_FROM_ADMIN.REQUEST]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.PENDING,
    }),
    [UPDATE_USER_FROM_ADMIN.ERROR]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.IDLE,
    }),
    [UPDATE_USER_FROM_ADMIN.SUCCESS]: (state) => ({
      ...state,
      status: REQUEST_STATUSES.IDLE,
    }),
    [GET_ONLINE_USERS.SUCCESS]: (state, { payload }) => ({
      ...state,
      onlineUsers: payload,
    }),
  },
  initialState,
)

export default reducer
