import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const SortAlphabeticalIcon = ({ color, onClick }) => {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.7502 18.9985L12.5002 22.2485L9.25016 18.9985H15.7502ZM8.89016 14.2985H6.00016L5.28016 16.9985H2.91016L6.00016 6.99854H9.00016L12.1302 16.9985H9.67016L8.89016 14.2985ZM6.33016 12.6785H8.56016L7.93016 10.5585L7.67016 9.58854L7.42016 8.62854H7.39016L7.17016 9.59854L6.93016 10.5785L6.33016 12.6785ZM13.0502 16.9985V15.7385L17.8002 8.96854V8.90854H13.5002V6.99854H20.7302V8.33854L16.0902 14.9985V15.0785H20.8002V16.9985H13.0502Z" fill="#838D99"/>
    </svg>
  )
}

SortAlphabeticalIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

SortAlphabeticalIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(SortAlphabeticalIcon)
