export const sortOptionsByTitle = (items) => {
  return items.filter(item => item.title).sort((a, b) => {
    const titleA = a.title.toUpperCase()
    const titleB = b.title.toUpperCase()
      if (titleA < titleB) {
        return -1
      }

      if (titleA > titleB) {
        return 1
      }

      return 0
    })
}