import LiveTime from '@/components/icons/livetime'

export const exportOptions = [
  {
   name: 'EXCEL',
   icon: LiveTime.EXELIcon,
  },
]

export default {
  exportOptions,
}