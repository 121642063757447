import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export default [
  {
    passportParameter: <Lang id="passportization.fields.generalInfo.name" />,
    showInDashboard: true,
    selector: 'name',
  },
  {
    passportParameter: <Lang id="passportization.fields.generalInfo.identifier" />,
    showInDashboard: true,
    selector: 'objectIdOuter',
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.REGISTRY_NUMBER" />,
    showInDashboard: true,
    selector: 'REGISTRY_NUMBER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passportization.fields.generalInfo.atmosphereTemperature" />,
    showInDashboard: true,
    selector: 'atmosphereTemperature',
  },
  {
    passportParameter: <Lang id="passportization.fields.generalInfo.atmospherePressure" />,
    showInDashboard: true,
    selector: 'atmospherePressure',
  },
  {
    passportParameter: <Lang id="passport.fields.PLAN_PPR_DATE" />,
    showInDashboard: true,
    selector: 'PLAN_PPR_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.FACT_PPR_DATE" />,
    showInDashboard: true,
    selector: 'FACT_PPR_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.START_DATE" />,
    showInDashboard: true,
    selector: 'START_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.EXPLOITATION_END_DATE" />,
    showInDashboard: true,
    selector: 'EXPLOITATION_END_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.ORGANIZATION" />,
    showInDashboard: false,
    selector: 'ORGANIZATION',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.LOCATION.PROJECT" />,
    showInDashboard: true,
    selector: 'PROJECT',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.LOCATION.REGION" />,
    showInDashboard: true,
    selector: 'REGION',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.LOCATION.CITY" />,
    showInDashboard: true,
    selector: 'CITY',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.LOCATION.STREET" />,
    showInDashboard: true,
    selector: 'STREET',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.LOCATION.HOME_NUMBER" />,
    showInDashboard: true,
    selector: 'HOME_NUMBER',
    isCard: true
  },
]
