import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_ALL_APPLICATIONS_URL } from '@/constants/apiRoutes'
import {
  GET_ALL_APPLICATIONS,
} from '@/store/actions/dictionaries'
import {
  successGetApplications,
  errorGetApplications,
} from '@/store/actions/dictionaries/applications'
import createNotifications from "@/helpers/notification";

function* getDictionary() {
  try {
    const response = yield request({
      url: GET_ALL_APPLICATIONS_URL,
      method: 'get',
    })

    yield put(successGetApplications(response.data))
  } catch (error) {
    yield put(errorGetApplications(error))
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* () {
  yield takeLatest(GET_ALL_APPLICATIONS.REQUEST, getDictionary)
}
