import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const WarningIcon = ({ color, onClick }) => {
  return (
    <svg onClick={onClick} width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" fill={color || "#FF9900"} clipRule="evenodd" d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM8.26845 11.1004H10.1663L10.4652 3H7.96952L8.26845 11.1004ZM8.26845 12.6391C8.02282 12.8942 7.9 13.2225 7.9 13.6242C7.9 14.0258 8.02282 14.3569 8.26845 14.6174C8.51872 14.8725 8.82923 15 9.2 15C9.57077 15 9.87897 14.8725 10.1246 14.6174C10.3749 14.3569 10.5 14.0258 10.5 13.6242C10.5 13.2225 10.3749 12.8942 10.1246 12.6391C9.87897 12.3786 9.57077 12.2483 9.2 12.2483C8.82923 12.2483 8.51872 12.3786 8.26845 12.6391Z" />
    </svg>
)}

WarningIcon.propTypes = {
  onClick: pt.func,
}

WarningIcon.defaultProps = {
  onClick: noop,
}

export default memoWithName(WarningIcon)
