import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import CheckBox from '@/components/controls/CheckBox'

import {
  Container,
  CheckBoxWrapper,
  ImageContainer,
  Title,
} from './styles'

const UserWithCheckBoxAndPicture = ({
  row,
}) => {
  const {
    photoUri,
    firstName,
    lastName,
    middleName,
    enabled,
    blocked,
    onCheckBoxToggle,
    isChecked,
    withCheckBox,
  } = row.original

  const status = () => {
    if (blocked) {
      return 'error'
    }
    if (!blocked && enabled) {
      return 'ok'
    }
    return null
  }

  const handleOnCheckBoxToggle = (value, event) => {
    event.stopPropagation()
    onCheckBoxToggle()
  }

  return (
    <Container>
      {withCheckBox && (
        <CheckBoxWrapper>
          <CheckBox
            value={isChecked}
            onChange={handleOnCheckBoxToggle}
          />
        </CheckBoxWrapper>
      )}
      <ImageContainer url={photoUri} status={status()} />
      <Title>
      {`${lastName || ''} ${firstName || ''} ${middleName || ''}`}
      </Title>
    </Container>
  )
}

UserWithCheckBoxAndPicture.propTypes = {
  row: pt.shape({
    original: pt.shape({
      photoUri: pt.string,
      firstName: pt.string,
      lastName: pt.string,
      onCheckBoxToggle: pt.func,
      blocked: pt.bool,
      isOnline: pt.bool,
      isChecked: pt.bool,
      withCheckBox: pt.bool,
    }),
  }),
}

UserWithCheckBoxAndPicture.defaultProps = {
  row: {
    original: {
      photoUri: '',
      firstName: '',
      lastName: '',
      onCheckBoxToggle: noop,
      blocked: false,
      isOnline: false,
      isChecked: false,
      withCheckBox: false,
    },
  },
}

export default UserWithCheckBoxAndPicture
