import {
  TEXT,
  ARIA,
} from '@/constants/semanticNames'
import setSixDigitsAfterDot from '@/helpers/setSixDigitsAfterDot'
import CREATE_REGION_NAMES from '@/constants/forms/createGeoZone'

export const rootGeoZoneConfig = [
  {
    title: 'Название геозоны*',
    type: TEXT,
    selector: CREATE_REGION_NAMES.NAME,
  },
  {
    title: 'Географическая широта*',
    type: TEXT,
    selector: CREATE_REGION_NAMES.LATITUDE,
    formatter: setSixDigitsAfterDot,
    pattern: '[0-9.]',
  },
  {
    title: 'Географическая долгота*',
    type: TEXT,
    selector: CREATE_REGION_NAMES.LONGITUDE,
    formatter: setSixDigitsAfterDot,
    pattern: '[0-9.]',
  },
  {
    title: 'Максимальный радиус внутренних геозон, км*',
    type: TEXT,
    selector: CREATE_REGION_NAMES.MAX_RADIUS,
    pattern: '[0-9]',
  },
  {
    title: 'Регион',
    type: TEXT,
    selector: CREATE_REGION_NAMES.REGION,
  },
  {
    title: 'Город',
    type: TEXT,
    selector: CREATE_REGION_NAMES.CITY,
  },
  {
    title: 'Заметка',
    type: ARIA,
    selector: CREATE_REGION_NAMES.MARK,
    charLimit: 300,
  },
]
export const geoZoneConfig = [
  {
    title: 'Название геозоны*',
    type: TEXT,
    selector: CREATE_REGION_NAMES.NAME,
  },
  // {
  //   title: 'Родительская геозона*',
  //   type: SELECT,
  //   selector: CREATE_REGION_NAMES.PARENT,
  // },
  {
    title: 'Географическая широта*',
    type: TEXT,
    selector: CREATE_REGION_NAMES.LATITUDE,
    formatter: setSixDigitsAfterDot,
    pattern: '[0-9.]',
  },
  {
    title: 'Географическая долгота*',
    type: TEXT,
    selector: CREATE_REGION_NAMES.LONGITUDE,
    formatter: setSixDigitsAfterDot,
    pattern: '[0-9.]',
  },
  {
    title: 'Радиус, км*',
    type: TEXT,
    selector: CREATE_REGION_NAMES.RADIUS,
    pattern: '[0-9.]',
  },
  {
    title: 'Регион',
    type: TEXT,
    selector: CREATE_REGION_NAMES.REGION,
  },
  {
    title: 'Город',
    type: TEXT,
    selector: CREATE_REGION_NAMES.CITY,
  },
  {
    title: 'Заметка',
    type: ARIA,
    selector: CREATE_REGION_NAMES.MARK,
    charLimit: 300,
  },
]

export default geoZoneConfig
