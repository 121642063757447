import {
  put, takeLatest, select, call
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { successEditProject } from '@/store/actions/installation/editProject'
import { getCard } from '@/store/selectors/installation'
import { EDIT_PROJECT } from '@/store/actions/installation'
import { INSTALLATIONS_PROJECT_ENDPOINT } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { treeHandlers } from 'react-hyper-tree'
import { PROJECT } from '@/constants/objectTypes'
import { setGlobalLoading } from '@/store/actions/appSettings'

function* editProjectSaga({ payload }) {
  const {
    formValues, values, setUrlFormValues,
  } = payload
  const {
    customFields = [], mark, name, parentId,
  } = values
  const toast = createNotifications()
  try {
    yield put(setGlobalLoading(true))
    const filterFields = (customFields || []).filter((element) => !!element.remove === false)
    const formattedCustomFields = filterFields.map((element) => ({
      ...element,
      nameField: element.nameField,
      value: element.value,
      projectId: element.parentId
    }))
    const cardCopy = yield select(getCard)
    const body = {
      id: formValues.id,
      name,
      note: mark,
      parentId,
      parentName: cardCopy.data.parentName,
      point: cardCopy.data.point,
      customFields: formattedCustomFields,
    }
    const { data } = yield request({
      url: INSTALLATIONS_PROJECT_ENDPOINT,
      method: 'post',
      body,
    })
    const {
      rootId, parentGeoZoneId, path, FILTER,
    } = formValues
    const newPath = path.replace(parentGeoZoneId, data.parentId)
    const tree = treeHandlers.trees['installations-tree']
    const parent = tree.instance.getNodeById(data.parentId)
    const parentChildren = yield call(parent.data.getChildren, parent)
    tree.handlers.setRawChildren(parent, parentChildren, 'first', true)
    const node = tree.instance.getNodeById(data.id)
    tree.handlers.setOpen(node, true)
    tree.handlers.setSelected(node, true)
    tree.handlers.rerender()
    setUrlFormValues({
      id: data.id,
      type: PROJECT,
      parentGeoZoneId: data.parentId,
      rootId,
      name,
      path: newPath,
      parentTreeId: data.parentId,
      FILTER,
    })
    yield put(setGlobalLoading(false))
    toast({
      title: 'Редактирование проекта',
      type: 'success',
      description: 'Изменения сохранены.',
    })
    yield put(successEditProject())
  } catch (error) {
    yield put(setGlobalLoading(false))
    const { FILTER } = formValues
    setUrlFormValues({ FILTER })
    console.log('function*editProjectSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось изменить проект. \nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(EDIT_PROJECT.REQUEST, editProjectSaga)
}
