export default {
  formTitles: {
    equipmentType: 'Equipment type:',
    geozone: 'Geozone:',
    project: 'Project:',
    integration: 'Integration:',
    region: 'Region:',
    city: 'City:',
  },
  selectPlaceholders: {
    name: 'Search objects...',
    equipmentType: 'All equipment types',
    geozone: 'All geozones',
    project: 'All projects',
    integration: 'All integrations',
    region: 'All regions',
    city: 'All cities',
  },
  buttons: {
    reset: 'Reset results',
    accept: 'Find',
    cancel: 'Cancel',
    continue: 'Continue',
  },
  file: {
    text: 'The advanced search result exceeds 5000 lines.\nA document containing the first 5000 rows of the table will be uploaded.\nNarrow down your search results using filters.'
  }
}