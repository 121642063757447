import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const NotificationIcon = ({ color, onClick }) => {

  return (
    <svg width="100%" height="86" viewBox="0 0 343 86"  fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_229_6719)">
      <path d="M0 12C0 5.37258 5.37258 0 12 0H331C337.627 0 343 5.37258 343 12V74C343 80.6274 337.627 86 331 86H12C5.37258 86 0 80.6274 0 74V12Z" fill="url(#paint0_linear_229_6719)"/>
      <g opacity="0.82" clip-path="url(#clip1_229_6719)">
        <g clip-path="url(#clip2_229_6719)">
          <path d="M286.066 12.2188C284.315 11.7496 282.45 11.9952 280.88 12.9015C279.31 13.8078 278.165 15.3006 277.696 17.0514C277.587 17.3639 277.502 17.6838 277.439 18.0086C267.181 18.2671 257.813 25.2381 255.001 35.7345L249.694 55.5391L241.324 60.3717L240.44 63.6725L299.853 79.5923L300.738 76.2915L295.905 67.9212L301.212 48.1166C304.024 37.6202 299.397 26.8992 290.642 21.5464C290.751 21.2339 290.837 20.914 290.899 20.5891C291.368 18.8383 291.122 16.9728 290.216 15.4031C289.31 13.8333 287.817 12.6879 286.066 12.2188ZM282.528 25.4218C286.906 26.5946 290.637 29.4582 292.903 33.3826C295.169 37.307 295.783 41.9707 294.61 46.3477L288.419 69.453L255.411 60.6087L261.602 37.5034C262.775 33.1263 265.639 29.3944 269.563 27.1287C273.488 24.8629 278.151 24.249 282.528 25.4218ZM311.351 36.6825L306.044 56.487L312.646 58.2559L317.952 38.4513L311.351 36.6825ZM304.275 63.0885L302.506 69.69L309.108 71.4589L310.877 64.8574L304.275 63.0885ZM262.66 73.1643C262.191 74.9151 262.437 76.7806 263.343 78.3503C264.249 79.9201 265.742 81.0655 267.493 81.5347C269.244 82.0038 271.109 81.7582 272.679 80.8519C274.249 79.9456 275.394 78.4528 275.863 76.702L262.66 73.1643Z" fill="url(#paint1_linear_229_6719)"/>
        </g>
      </g>
      {/* <g clip-path="url(#clip3_229_6719)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M322.286 20.714C324.89 23.3175 329.111 23.3175 331.714 20.714C334.318 18.1105 334.318 13.8894 331.714 11.2859C329.111 8.68244 324.89 8.68244 322.286 11.2859C319.683 13.8894 319.683 18.1105 322.286 20.714ZM329.75 14.0358L327.786 16L329.75 17.9642L328.964 18.7498L327 16.7857L325.036 18.7498L324.25 17.9642L326.214 16L324.25 14.0358L325.036 13.2501L327 15.2143L328.964 13.2501L329.75 14.0358Z" fill="#FAFBFB"/>
      </g> */}
      </g>
      <defs>
      <linearGradient id="paint0_linear_229_6719" x1="1.56171e-06" y1="39.5" x2="343" y2="39.5" gradientUnits="userSpaceOnUse">
      <stop stop-color="#1061DF"/>
      <stop offset="1" stop-color="#5694F3"/>
      </linearGradient>
      <linearGradient id="paint1_linear_229_6719" x1="270.794" y1="-1.49999" x2="270.794" y2="82.4191" gradientUnits="userSpaceOnUse">
      <stop stop-color="#FAFBFB" stop-opacity="0.73"/>
      <stop offset="1" stop-color="#FAFBFB" stop-opacity="0"/>
      </linearGradient>
      <clipPath id="clip0_229_6719">
      <path d="M0 12C0 5.37258 5.37258 0 12 0H331C337.627 0 343 5.37258 343 12V74C343 80.6274 337.627 86 331 86H12C5.37258 86 0 80.6274 0 74V12Z" fill="white"/>
      </clipPath>
      <clipPath id="clip1_229_6719">
      <rect width="82.0127" height="82.0127" fill="white" transform="translate(248.227 -5) rotate(15)"/>
      </clipPath>
      <clipPath id="clip2_229_6719">
      <rect width="82.0127" height="82.0127" fill="white" transform="translate(248.227 -5) rotate(15)"/>
      </clipPath>
      <clipPath id="clip3_229_6719">
      <rect width="16" height="16" fill="white" transform="translate(319 8)"/>
      </clipPath>
      </defs>
    </svg>
    
    
  )
}

NotificationIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

NotificationIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(NotificationIcon)
