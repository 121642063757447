import React, {
  useMemo, useContext,
} from 'react'
import { ThemeContext } from 'styled-components'
import pt from 'prop-types'
import isEmpty from 'lodash/isEmpty'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Brush,
  LabelList,
  CartesianGrid,
} from 'recharts'
import ChartToolTip from '@/components/charts/ChartToolTip'
import CustomLegend from '@/components/charts/CustomLegend'
import numberWithSpaces from '@/helpers/numberWithSpaces'
import { graphOfDifferenceConsumptionFormatter } from '@/helpers/formaters/graphFormaters'
import {
  BarLabel,
  ChartWrapper,
} from './styles'

const BarChartGraph = ({
  isStaked,
  data,
  mainAxisName,
  seriesNames,
  unit,
  tickFormatter,
  type,
  legend,
  brush,
  withoutTooltip,
  graphLegend,
  customGraphMargin,
  decimalNumbers,
  customDomain,
  ticks,
  customTickPosition,
  removeVerticalGrid,
  ReferenceLineConfig,
  CartesianGridconfig,
  barSize,
  barCategoryGap,
  toolTip,
  mainAxisType
}) => {
  const theme = useContext(ThemeContext)
  const formattedData = useMemo(() => {
    if (isStaked) {
      return graphOfDifferenceConsumptionFormatter(data)
    }
    return data
  }, [data, isStaked])

  const renderCustomizedLabel = ({ x, y, width, value }) => {
    const letterWidth = 3
    const localGap = 10
    const localPortion = 0.7

    const formatValue = numberWithSpaces(Number.parseFloat(value || 0, 10))
    return (
      <g>
        <BarLabel
          x={x + width * localPortion}
          y={y + formatValue.length * letterWidth + localGap}
          textAnchor="middle"
          dominantBaseline="baseline"
        >
          {formatValue}
        </BarLabel>
      </g>
    )
  }

  return (
    <ChartWrapper customTickPosition={customTickPosition} removeVerticalGrid={removeVerticalGrid}>
      <ResponsiveContainer>
        <BarChart
          barSize={barSize || 25}
          data={formattedData}
          barGap={0}
          barCategoryGap={barCategoryGap}
          margin={customGraphMargin || {
            top: 20, right: 30, left: 20, bottom: 5,
          }}
        >
          {!isEmpty(graphLegend) && (
            graphLegend
          )}
          <XAxis
            tickLine={false}
            dataKey={mainAxisName}
            stroke={theme.colors.colors.default}
          />
          <YAxis
            domain={[0, customDomain]}
            ticks={ticks}
            tickFormatter={tickFormatter}
            stroke={theme.colors.colors.default}
            unit={unit}
          />
          {!isEmpty(CartesianGridconfig) && (
            <CartesianGrid
              strokeDasharray={CartesianGridconfig.strokeDasharray}
              horizontalPoints={CartesianGridconfig.horizontalPoints}
            />
          )}
          {!isEmpty(ReferenceLineConfig) && (
            <ReferenceLine
              y={ReferenceLineConfig.y}
              stroke={ReferenceLineConfig.stroke}
              strokeDasharray={ReferenceLineConfig.strokeDasharray}
              alwaysShow={ReferenceLineConfig.alwaysShow}
            />
          )}
          {!withoutTooltip && (
            <Tooltip
              active={false}
              cursor={false}
              content={toolTip ? toolTip : (<ChartToolTip unit={unit} objectType={type} mainAxisType={mainAxisType} />)}
            />
          )}
          {formattedData.length > 0 && !isEmpty(brush) && (
            <Brush {...brush} />
          )}
          {seriesNames.map((element) => (
            <Bar
              dataKey={element.name}
              stackId={isStaked && element.stackGroupId}
              fill={element.fill}
              name={element.title}
            >
              {withoutTooltip && (
                <LabelList dataKey={element.name} content={renderCustomizedLabel} />
              )}
            </Bar>
          ))}
        </BarChart>
      </ResponsiveContainer>
      {!isEmpty(legend) && (
        <CustomLegend
          formattedData={formattedData}
          title={legend.title}
          align={legend.align}
          seriesNames={seriesNames}
          {...legend}
        />
      )}
    </ChartWrapper>
  )
}

BarChartGraph.propTypes = {
  data: pt.arrayOf(pt.object),
  isStaked: pt.bool,
  withoutTooltip: pt.bool,
  removeVerticalGrid: pt.bool,
  mainAxisName: pt.string,
  unit: pt.string,
  customDomain: pt.func,
  seriesNames: pt.arrayOf(pt.object),
  legend: pt.objectOf(pt.object),
  brush: pt.objectOf(pt.object),
  ticks: pt.arrayOf(pt.numbers),
  customTickPosition: pt.number,
  ReferenceLineConfig: pt.objectOf(pt.any),
  CartesianGridconfig: pt.objectOf(pt.any),
}
BarChartGraph.defaultProps = {
  removeVerticalGrid: false,
  data: [],
  isStaked: false,
  withoutTooltip: false,
  mainAxisName: '',
  seriesNames: [],
  ticks: [],
  unit: '',
  legend: {},
  brush: {},
  customDomain: (number) => number * 1.2,
  customTickPosition: null,
  ReferenceLineConfig: {},
  CartesianGridconfig: {},
}

export default BarChartGraph
