import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import ScheduleManager from '@/containers/pages/ScheduleManger/component'
import {
  getGlobalLoading,
  getGlobalFilters
} from '@/store/selectors/appSettings'
import {
  getTreeStatus,
  getTreeData,
  getSchedule,
  getScheduleSelectedNode
} from '@/store/selectors/scheduleManager'
import {
  getFailureDates,
  getErrorStatus,
  getFailureLoadingTable,
  getFailureLogTableData,
} from '@/store/selectors/scheduleManager/failureLog'
import { requestScheduleManagerTree } from '@/store/actions/scheduleManager/getTree'
import { requestCreateSchedule } from '@/store/actions/scheduleManager/createSchedule'
import { requestEditSchedule } from '@/store/actions/scheduleManager/editSchedule'
import { requestGetSchedule } from '@/store/actions/scheduleManager/getSchedule'
import { requestDeleteSchedule } from '@/store/actions/scheduleManager/deleteSchedule'
import {
  setAction,
  setActivityLogDatesRange,
  setErrorStatus,
  setFailureLogDatesRange,
  setScheduleTreeNode
} from '@/store/actions/scheduleManager'
import { requestScheduleManagerFailureLogTable } from '@/store/actions/scheduleManager/getFailureLogTable'
import {
  getAction,
  getActivityDates,
  getActivityLoadingTable,
  getActivityLogTableData
} from '@/store/selectors/scheduleManager/activityLog'
import { requestScheduleManagerActivityLogTable } from '@/store/actions/scheduleManager/getActivityLogTable'

const mapStateToProps = (state) => ({
  globalLoading: getGlobalLoading(state),
  treeData: getTreeData(state),
  treeStatus: getTreeStatus(state),
  card: getSchedule(state),
  scheduleSelectedNode: getScheduleSelectedNode(state),
  globalFilters: getGlobalFilters(state),

  failureTableData: getFailureLogTableData(state),
  loadingFailureTable: getFailureLoadingTable(state),
  failureDates: getFailureDates(state),
  errorStatus: getErrorStatus(state),

  activityTableData: getActivityLogTableData(state),
  loadingActivityTable: getActivityLoadingTable(state),
  activityDates: getActivityDates(state),
  action: getAction(state),
})

const mapDispatchToProps = (dispatch) => ({
  requestGetTree: () => dispatch(requestScheduleManagerTree()),
  setScheduleSelectedNode: (payload) => dispatch(setScheduleTreeNode(payload)),

  requestGetSchedule: (payload) => dispatch(requestGetSchedule(payload)),
  requestCreateSchedule: (payload) => dispatch(requestCreateSchedule(payload)),
  requestEditSchedule: (payload) => dispatch(requestEditSchedule(payload)),
  requestDeleteSchedule: (payload) => dispatch(requestDeleteSchedule(payload)),

  requestGetFailureLogTable: (payload) => dispatch(requestScheduleManagerFailureLogTable(payload)),
  setFailureLogDatesRange: (payload) => dispatch(setFailureLogDatesRange(payload)),
  setErrorStatus: (payload) => dispatch(setErrorStatus(payload)),

  requestGetActivityLogTable: (payload) => dispatch(requestScheduleManagerActivityLogTable(payload)),
  setActivityLogDatesRange: (payload) => dispatch(setActivityLogDatesRange(payload)),
  setAction: (payload) => dispatch(setAction(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(ScheduleManager))