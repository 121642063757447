export default {
  formTitles: {
    equipmentType: 'Тип оборудования:',
    geozone: 'Геозона:',
    project: 'Проект:',
    integration: 'Интеграция:',
    region: 'Регион:',
    city: 'Город:',
    },
  selectPlaceholders: {
    name: 'Поиск объектов...',
    equipmentType: 'Все типы оборудования',
    geozone: 'Все геозоны',
    project: 'Все проекты',
    integration: 'Все интеграции',
    region: 'Все регионы',
    city: 'Все города',
  },
  buttons: {
    reset: 'Сбросить результаты',
    accept: 'Найти',
    cancel: 'Отмена',
    continue: 'Продолжить',
  },
  file: {
    text: 'Результат расширенного поиска превышает 5000 строк.\nБудет выгружен документ, содержащий первые 5000 строк таблицы.\nКонкретизируйте результаты поиска, используя фильтры.'
  }
}