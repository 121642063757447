import {
  FILE,
  PHOTO,
  TEXT,
} from '@/constants/semanticNames'

export default [
  {
    selector: 'GENERAL_INFORMATION.NAME',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'GENERAL_INFORMATION',
  },
  {
    selector: 'GENERAL_INFORMATION.IDENTIFIER',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'GENERAL_INFORMATION',
    immutable: true,
  },
  {
    selector: 'GENERAL_INFORMATION.EQUIPMENT_TYPE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'GENERAL_INFORMATION',
    immutable: true,
  },
  {
    selector: 'GENERAL_INFORMATION.ROOT_OBJECT',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'GENERAL_INFORMATION',
  },
  {
    selector: 'GENERAL_INFORMATION.PARENT_TYPE',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'GENERAL_INFORMATION',
  },
  {
    selector: 'GENERAL_INFORMATION.PARENT_IDENTIFIER',
    title: 'someTitle',
    type: TEXT,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'GENERAL_INFORMATION',
  },


  {
    selector: 'ATTACHMENT.PHOTO_TITLE',
    title: 'someTitle',
    type: PHOTO,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'ATTACHMENT',
  },
  {
    selector: 'ATTACHMENT.DOCUMENT_TITLE',
    title: 'someTitle',
    type: FILE,
    scheme: null,
    placeholder: 'Пример текста',
    catalogSelector: 'ATTACHMENT',
  },
]
