import {
  put, takeLatest, select,
} from 'redux-saga/effects'

import {
  START_REMOVE_WIDGET_FROM_GENERAL_PAGE,
  updateWidgetsList,
  updateApplicationsList,
} from '@/store/actions/appSettings'
import {
  WIDGET_SHOP_APP,
  WIDGET_SHOP_WIDGET,
} from '@/constants/names'
import { getWidgets, getApplications, getIntegrationStatus } from '@/store/selectors/appSettings'
import { successDeleteIntegration } from '@/store/actions/serviceDeskMyCity/deleteIntegration'
import createNotifications from "@/helpers/notification";

function* deleteWidget({ payload: { id, widgetType, integrationId } }) {
  try {
    if (widgetType === WIDGET_SHOP_APP) {
      const widgets = yield select(getApplications)
      const newWidgets = widgets.filter(({ id: widgetId }) => widgetId !== id)
      yield put(updateApplicationsList(newWidgets))
      return null
    }
    if (widgetType === WIDGET_SHOP_WIDGET) {
      const integrationStatus = yield select(getIntegrationStatus)
      const widgets = yield select(getWidgets)
      const newWidgets = widgets.filter(({ id: widgetId }) => widgetId !== id)
      const newIntegrationStatus = integrationStatus.filter(
        (status) => integrationId !== status.id,
      )
      yield put(updateWidgetsList(newWidgets))
      yield put(successDeleteIntegration(newIntegrationStatus))
      return null
    }
  } catch (error) {
    console.log('TCL: function*addWidgetToGeneralPage -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
  return null
}

export default function* () {
  yield takeLatest(START_REMOVE_WIDGET_FROM_GENERAL_PAGE, deleteWidget)
}
