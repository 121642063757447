import widgets from '../widgets'

const {
    ELECTRIC_METER_STATE,
    ALARM_STATE,
    STATUS,
} = widgets

export default [
    STATUS({}),
    ELECTRIC_METER_STATE({}),
    ALARM_STATE({}),
]
