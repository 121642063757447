import React, {
  useEffect, useState, useCallback, useMemo,
} from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import useHtmlTitle from '@/hooks/useHtmlTitle'
import Loader from '@/components/blocks/Loader'
import DutyTable from '@/components/blocks/DutyTable'
import { REQUEST_STATUSES } from '@/constants/requests'
import {
  DEMONSTRATION_TABLE,
  DIFFERENCE_ANALSIS,
  COMPARATIVE_ANALSIS,
  COMPARATIVE_PLANE_ANALSIS,
  HEAT_SUPPLY,
  OUTDOOR_LIGHTING,
} from '@/constants/names'
import {
  consumptionAnalysisFieldsByType,
  consumptionAnalysisExportOptions,
} from '@/constants/tablesConfig/consumptionAnalysis'
import useReduxTable from '@/hooks/useReduxTable'
import { CONSUMPTION_ANALYSIS_TABLE } from '@/store/actions/consumptionAnalysis'
import { getTableParameters } from '@/store/selectors/consumptionAnalysis'
import AnalysisSettings from './components/AnalysisSettings'
import AnalysisGraph from './components/AnalysisGraph'

import {
  Main,
  Content,
  Title,
  LoaderContainer,
} from './styles'
import { KULON, ONE_SIM, UNILIGHT } from '@/constants/instalationPassport/types'
import { GEOZONE, INTEGRATION, OBJECT_ELEMENT, PROJECT } from '@/constants/objectTypes'
import TreeSidebar from '@/containers/pages/Installation/components/TreeSidebar'

const ConsumptionAnalysis = ({
  data,
  graphType,
  consumptionForm,
  savings,
  selectedNode,
  statusTable,
  statusGraph,
  calculatorValue,

  getHeatTableData,
  getHeatGraphData,
  getTableData,
  getGraphData,
  getPlanGraphData,
  getReportFile,
  getHeatFile,
  globalLoading,

  setNode,
  setFormValues,
  fileStatus,
  setClearData,
  integrationType,
  intl,
}) => {
  const [isSettingsOpen, setIsSettingsOpen] = useState(true)
  const [integrationTypes, setIntegrationTypes] = useState([])

  useHtmlTitle(intl.messages['menu.analyticExpenses'])
  useEffect(() => {
    setClearData()
    setFormValues({})
    if (selectedNode && selectedNode.id) {
      setNodeIntegrationTypes(selectedNode)
    }
  }, [setClearData, setFormValues, selectedNode])

  const tableProps = useReduxTable(CONSUMPTION_ANALYSIS_TABLE, getTableParameters)

  const setNodeIntegrationTypes = (node) => {
    switch (node.treeNodeType) {
      case GEOZONE: {
        setIntegrationTypes([HEAT_SUPPLY, OUTDOOR_LIGHTING])
        break
      }
      case PROJECT: {
        setIntegrationTypes([HEAT_SUPPLY, OUTDOOR_LIGHTING])
        break
      }
      case INTEGRATION: {
        if (node.integrationType === UNILIGHT || KULON) {
          setIntegrationTypes([OUTDOOR_LIGHTING])
        }
        if (node.integrationType === ONE_SIM) {
          setIntegrationTypes([HEAT_SUPPLY])
        }
        break
      }
      case OBJECT_ELEMENT: {
        if (node.integrationType === UNILIGHT || KULON) {
          setIntegrationTypes([OUTDOOR_LIGHTING])
        }
        if (node.integrationType === ONE_SIM) {
          setIntegrationTypes([HEAT_SUPPLY])
        }
        break
      }
      default:
        setIntegrationTypes([])
    }
  }

  const onSelectNode = useCallback((node, event) => {
    if (event === 'click') {
      setNodeIntegrationTypes(node)
      setClearData()
      setFormValues({})
      setNode(node)
    }
  },
  [setNode, setClearData, setFormValues])

  const handleUpdateTable = useCallback(() => {
    if (integrationType === OUTDOOR_LIGHTING) {
      return getTableData()
    }
    if (integrationType === HEAT_SUPPLY) {
      return getHeatTableData()
    }
    return noop
  }, [
    integrationType,
    getTableData,
    getHeatTableData,
  ])

  const handleDownloadFile = useCallback((...parameters) => {
    if (integrationType === OUTDOOR_LIGHTING) {
      return getReportFile(...parameters)
    }
    if (integrationType === HEAT_SUPPLY) {
      return getHeatFile(...parameters)
    }
    return noop
  }, [
    integrationType,
    getReportFile,
    getHeatFile,
  ])

  const renderContent = useMemo(() => {
    if (statusTable === REQUEST_STATUSES.PENDING || statusGraph === REQUEST_STATUSES.PENDING) {
      return (
        <Content>
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        </Content>
      )
    }
    switch (graphType) {
      case DEMONSTRATION_TABLE: {
        return (
          <DutyTable
            exportOptions={consumptionAnalysisExportOptions}
            fields={consumptionAnalysisFieldsByType[integrationType] || []}
            data={data}
            {...tableProps}
            onUpdateData={handleUpdateTable}
            onFileSelect={handleDownloadFile}
            fileStatus={fileStatus}
            isDataLoading={globalLoading}
          />
        )
      }
      case DIFFERENCE_ANALSIS:
      case COMPARATIVE_PLANE_ANALSIS:
      case COMPARATIVE_ANALSIS: {
        return (
          <AnalysisGraph
            graphType={graphType}
            graphData={data}
            objectType={integrationType}
          />
        )
      }
      default: {
        return (
          <Content>
            <Title>
              <Lang id="consumptionAnalysis.messages.default" />
            </Title>
          </Content>
        )
      }
    }
  },
  [
    statusTable,
    statusGraph,
    graphType,
    integrationType,
    data,
    tableProps,
    handleUpdateTable,
    handleDownloadFile,
    fileStatus,
    globalLoading
  ])

  return (
    <Main>
      <TreeSidebar
          title={intl.messages['menu.analyticExpenses']}
          onSelect={onSelectNode}
          hidden={false}
          selectedNode={selectedNode}
      />
      {selectedNode
        && selectedNode.id
        && (
          <AnalysisSettings
            data={data}
            savings={savings}
            consumptionForm={consumptionForm}
            name={selectedNode.name}
            node={selectedNode}
            isSettingsOpen={isSettingsOpen}
            setIsSettingsOpen={setIsSettingsOpen}
            getHeatTableData={getHeatTableData}
            getHeatGraphData={getHeatGraphData}
            getTableData={getTableData}
            getGraphData={getGraphData}
            getPlanGraphData={getPlanGraphData}
            setFormValues={setFormValues}
            statusTable={statusTable}
            statusGraph={statusGraph}
            calculatorValue={calculatorValue}
            integrationTypes={integrationTypes}
          />
        )}
      {renderContent}
    </Main>
  )
}

ConsumptionAnalysis.propTypes = {
  tree: pt.arrayOf(pt.object),
  data: pt.arrayOf(pt.object),
  consumptionForm: pt.objectOf(pt.object),
  setSort: pt.func,
  setResultPerPage: pt.func,
  setQuery: pt.func,
  setPage: pt.func,
  getHeatTableData: pt.func,
  getHeatGraphData: pt.func,
  getHeatFile: pt.func,
  getTableData: pt.func,
  getGraphData: pt.func,
  getPlanGraphData: pt.func,
  sortSettings: pt.shape({
    field: pt.string,
    value: pt.string,
  }),
  integrationType: pt.string,
  page: pt.number,
  resPerPage: pt.number,
  query: pt.string,
  totalResults: pt.number,
  savings: pt.shape({
    min: pt.number,
    max: pt.number,
    average: pt.number,
  }),
  calculatorValue: pt.number,
  activeFields: pt.arrayOf(pt.string),
  selectedNode: pt.shape({
    id: pt.number,
    name: pt.string,
  }),
  statusTable: pt.string,
  statusGraph: pt.string,
  fileStatus: pt.string,
  graphType: pt.string,
  setNode: pt.func,
  setFormValues: pt.func,
  setSelectedFields: pt.func,
  getReportFile: pt.func,
  setClearData: pt.func,
}

ConsumptionAnalysis.defaultProps = {
  tree: [],
  data: [],
  consumptionForm: null,
  sortSettings: {},
  savings: {},
  page: 1,
  resPerPage: 20,
  query: '',
  totalResults: null,
  setSort: noop,
  setResultPerPage: noop,
  setQuery: noop,
  setPage: noop,
  getHeatTableData: noop,
  getHeatGraphData: noop,
  getHeatFile: noop,
  getTableData: noop,
  getGraphData: noop,
  getPlanGraphData: noop,
  calculatorValue: null,
  activeFields: [],
  selectedNode: {},
  statusTable: '',
  statusGraph: '',
  integrationType: '',
  fileStatus: '',
  graphType: '',
  setNode: noop,
  setFormValues: noop,
  setSelectedFields: noop,
  getReportFile: noop,
  setClearData: noop,
}


export default ConsumptionAnalysis
