import widgets from '../widgets'

const {
  CONNECT_STATUS,
  LIGHT_STATUS,
  NETWORK_LEVEL,
  ILLUMINATION,
  TEMPERATURE,
  LIGHT_VOLTAGE,
  LIGHT_AMPERAGE,
  LIGHT_POWER,
  LIGHT_REACTIVE_POWER,
  LIGHT_FULL_POWER,
  LIGHT_POWER_COEFFICIENT,
  LIGHT_FREQUENCY,
  ENERGY,
  VOLTAGE_PEAKS,
  AMPERAGE_PEAKS,
  LIGHT_LEVEL_MULTI
} = widgets

export default [
  LIGHT_STATUS({}),
  CONNECT_STATUS({}),
  NETWORK_LEVEL({}),
  LIGHT_LEVEL_MULTI({}),
  ILLUMINATION({}),
  TEMPERATURE({}),
  // DATE({}),
  LIGHT_VOLTAGE({}),
  LIGHT_AMPERAGE({}),
  LIGHT_POWER({}),
  LIGHT_REACTIVE_POWER({}),
  LIGHT_FULL_POWER({}),
  LIGHT_POWER_COEFFICIENT({}),
  LIGHT_FREQUENCY({}),
  ENERGY({}),
  VOLTAGE_PEAKS({}),
  AMPERAGE_PEAKS({}),
]
