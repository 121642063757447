export const ACCEPT = 'accept'
export const RESET = 'reset'
export const ASU = 'asu'
export const INTEGRATION = 'integrations'
export const OBJECT_TYPES = 'objectsTypes'
export const PROCESSES = 'processes'
export const EXPLOITATION_ORGANIZATIONS = 'exploitationOrganizations'
export const OWNERS = 'owners'
export const INSTALLATION_TYPES = 'installationTypes'
export const GEOZONE = 'geoZone'
export const PROJECT = 'project'
