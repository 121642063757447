import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getUsers = createSelector(
  getState,
  (state) => get(state, 'users', {}),
)

export const getTenants = createSelector(
  getUsers,
  (tenant) => get(tenant, 'tenants'),
)

export const getAllUsers = createSelector(
  getUsers,
  (tenant) => get(tenant, 'users'),
)

export const getAdmins = createSelector(
  getUsers,
  (tenant) => get(tenant, 'admins'),
)

export const getGroups = createSelector(
  getUsers,
  (tenant) => get(tenant, 'groups'),
)

export const getSelectedTenant = createSelector(
  getUsers,
  (tenant) => get(tenant, 'selectedTenant'),
)

export const getSelectedEntity = createSelector(
  getUsers,
  (tenant) => get(tenant, 'selectedEntity'),
)

export const getType = createSelector(
  getUsers,
  (tenant) => get(tenant, 'type'),
)

export const getEdit = createSelector(
  getUsers,
  (tenant) => get(tenant, 'edit'),
)

export const getUserSortBy = createSelector(
  getUsers,
  (tenant) => get(tenant, 'userSortBy'),
)

export const getUserSortOrder = createSelector(
  getUsers,
  (tenant) => get(tenant, 'userSortOrder'),
)

export const getStatus = createSelector(
  getUsers,
  (tenant) => get(tenant, 'status', false),
)

export const getSortedUsers = createSelector(
  getUsers,
  (tenant) => get(tenant, 'sortedUsers'),
)

export const getIsFormModifying = createSelector(
  getUsers,
  (tenant) => get(tenant, 'isFormModifying'),
)

export const getOnlineUsers = createSelector(
  getUsers,
  (tenant) => get(tenant, 'onlineUsers'),
)
