import styled from 'styled-components'

export const Main = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: hidden;
  overflow-x: hidden;
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
`

export const NavContainer = styled.div`
  height: 100%;
  display: flex;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  ${({ br, theme }) => br && `border-right: 1px solid  ${theme.colors.borders.default};`}
  ${({ hidden }) => hidden && 'display: none;'}
`

export const YandexMapContainer = styled.div`
  position: relative;
  width: 100%;
  min-width: 100%;
  height: 100%;
  align-self: stretch;
`