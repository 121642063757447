import {
  put, takeLatest,
} from 'redux-saga/effects'
import { treeHandlers } from 'react-hyper-tree'
import request from '@/helpers/axios'
import { SCHEDULE_MANAGER_SCHEDULE_ENDPOINT } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { DELETE_SCHEDULE, setScheduleTreeNode } from '@/store/actions/scheduleManager'
import {
  errorDeleteSchedule,
  successDeleteSchedule
} from '@/store/actions/scheduleManager/deleteSchedule'
import { setGlobalLoading } from '@/store/actions/appSettings'
import { requestScheduleManagerTree } from '@/store/actions/scheduleManager/getTree'
import qs from 'query-string'

function* deleteScheduleSaga({ payload }) {
  const { id, geoZoneId, justificationComment } = payload
  const toast = createNotifications()
  const queryParams = qs.stringify({
    justificationComment: justificationComment,
  })
  try {
    yield put(setGlobalLoading(true))
    yield request({
      url: `${SCHEDULE_MANAGER_SCHEDULE_ENDPOINT(id)}?${queryParams}`,
      method: 'delete',
    })
    yield put(requestScheduleManagerTree())
    const tree = treeHandlers.trees['schedule-manager-tree']
    const parent = tree.instance.getNodeById(geoZoneId)
    yield put(setScheduleTreeNode({...parent.data, original: { options: parent.options }}))
    yield put(successDeleteSchedule())
    yield put(setGlobalLoading(false))
    toast({
      title: 'Удаление расписания',
      type: 'success',
      description: 'Расписание успешно удалено.',
    })
  } catch (error) {
      yield put(setGlobalLoading(false))
      yield put(errorDeleteSchedule())
      console.log('function*deleteScheduleSaga -> error', error)
      toast({
        title: 'Ошибка операции!',
        description: 'Не удалось удалить расписание. \nПовторите попытку позже.',
        type: 'error',
      })
  }
}

export default function* root() {
  yield takeLatest(DELETE_SCHEDULE.REQUEST, deleteScheduleSaga)
}
