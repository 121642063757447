import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getTyumenOurHome = createSelector(
  getState,
  (state) => get(state, 'tyumenOurHome', {}),
)

export const getStatus = createSelector(
  getTyumenOurHome,
  (state) => get(state, 'status', ''),
)

export const getDates = createSelector(
  getTyumenOurHome,
  (state) => get(state, 'dates', {}),
)

export const getData = createSelector(
  getTyumenOurHome,
  (state) => get(state, 'data', {}),
)

export const getDataMobile = createSelector(
  getTyumenOurHome,
  (state) => get(state, 'dataMobile', {}),
)

export const getQuery = createSelector(
  getTyumenOurHome,
  (state) => get(state, 'query', ''),
)

export const getStatuses = createSelector(
  getTyumenOurHome,
  (state) => get(state, 'statuses', ''),
)

export const getSelectedMessageId = createSelector(
  getTyumenOurHome,
  (state) => get(state, 'selectedMessageId', null),
)

export const getMessageData = createSelector(
  getTyumenOurHome,
  (state) => get(state, 'messageData', {}),
)

export const getType = createSelector(
  getTyumenOurHome,
  (state) => get(state, 'type', null),
)

export const getGraphs = createSelector(
  getTyumenOurHome,
  (state) => get(state, 'graphs', null),
)

export const getDateGraph = createSelector(
  getTyumenOurHome,
  (state) => get(state, 'graphDate', null),
)
