import React from 'react'
import memoWithName from '@/hocs/memoWithName'

const ActiveSearchIcon = () => (
    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0)">
            <path fillRule="evenodd" clipRule="evenodd" d="M11.0099 9.58098C11.8913 8.34107 12.2747 6.8153 12.0841 5.30604C11.8936 3.79678 11.143 2.41418 9.98116 1.43222C8.8193 0.450264 7.33093 -0.0593814 5.81103 0.00428196C4.29112 0.0679453 2.85059 0.700271 1.77491 1.77595C0.699233 2.85163 0.0669077 4.29216 0.00324436 5.81207C-0.060419 7.33197 0.449226 8.82033 1.43118 9.9822C2.41314 11.1441 3.79574 11.8946 5.305 12.0852C6.81426 12.2757 8.34003 11.8923 9.57994 11.011L10.5459 11.976C10.454 12.3189 10.454 12.6799 10.546 13.0228C10.638 13.3656 10.8187 13.6782 11.0699 13.929L14.5489 17.408C14.9282 17.7871 15.4425 18 15.9788 18C16.5151 17.9999 17.0293 17.7867 17.4084 17.4075C17.7876 17.0282 18.0005 16.5139 18.0004 15.9776C18.0003 15.4414 17.7872 14.9271 17.4079 14.548L13.9289 11.07C13.6782 10.8187 13.3656 10.638 13.0227 10.546C12.6799 10.454 12.3188 10.454 11.9759 10.546L11.0109 9.58098H11.0099ZM8.92494 3.20698C9.30054 3.58235 9.59849 4.02804 9.80178 4.5186C10.0051 5.00916 10.1097 5.53497 10.1097 6.06598C10.1097 6.59699 10.0051 7.1228 9.80178 7.61336C9.59849 8.10392 9.30054 8.54961 8.92494 8.92498C8.54971 9.30082 8.10406 9.59898 7.61348 9.80241C7.1229 10.0058 6.59703 10.1106 6.06594 10.1106C5.53486 10.1106 5.00898 10.0058 4.51841 9.80241C4.02783 9.59898 3.58218 9.30082 3.20694 8.92498C2.83135 8.54961 2.53339 8.10392 2.33011 7.61336C2.12683 7.1228 2.02219 6.59699 2.02219 6.06598C2.02219 5.53497 2.12683 5.00916 2.33011 4.5186C2.53339 4.02804 2.83135 3.58235 3.20694 3.20698C3.58224 2.83127 4.02792 2.53321 4.51849 2.32985C5.00905 2.12649 5.53489 2.02182 6.06594 2.02182C6.59699 2.02182 7.12283 2.12649 7.6134 2.32985C8.10397 2.53321 8.54964 2.83127 8.92494 3.20698Z" fill="url(#paint0_linear)" />
        </g>
        <linearGradient id="paint0_linear" x1="9" y1="0" x2="9" y2="18" gradientUnits="userSpaceOnUse">
            <stop stopColor="#FBBA58" />
            <stop offset="1" stopColor="#FF9A02" />
        </linearGradient>
    </svg>
)

export default memoWithName(ActiveSearchIcon)