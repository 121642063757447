import styled from 'styled-components'
import {Options} from "@/components/controls/SelectControl/styles";

export const Main = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
`

export const Content = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  overflow-y: hidden;
`

export const BarContainer = styled.div`
  width: 100%;
  ${({ empty }) => empty && `
    min-height: 48px;
  `}
`

export const HeaderContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  ${Options} {
    left: -45px;
    width: 155px;
  }
`

export const NavContainer = styled.div`
  height: 100%;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  ${({ br, theme }) => br && `border-right: 1px solid  ${theme.colors.borders.default};`}
  ${({ hidden }) => hidden && 'display: none;'}
`