import {
    takeLatest, put, all
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_MAP_STATISTIC } from '@/store/actions/maps'
import {
    INSTALLATION_ROOT_ELEMENT,
    generateGetGeoZoneInstalledCardStatistic,
} from '@/constants/apiRoutes'
import { COUNTRY } from '@/constants/objectTypes'
import createNotifications from '@/helpers/notification'
import { errorGetMapStatistic, successGetMapStatistic } from '@/store/actions/maps/getMapStatistic'

function* getMapStatistic() {
    try {
        let roots
        try {
            const { data } = yield request({
                url: INSTALLATION_ROOT_ELEMENT,
                method: 'get',
            })
            roots = yield all(data.map((element) => {
                return {
                    ...element,
                    type: COUNTRY,
                    name: element.name,
                }
            }))
        } catch (error) {}

        let statistic
        try {
            const { data } = yield request({
                url: generateGetGeoZoneInstalledCardStatistic(roots[0].id),
                method: 'get',
            })
            statistic = data
        } catch(error) {}

        yield put(successGetMapStatistic({
            countOfPins: statistic?.objectElementCount,
            countOfProjects: statistic?.projectCount,
        }))

    } catch (error) {
        console.log('error', error)
        yield put(errorGetMapStatistic())
        const toast = createNotifications()
        toast({
            title: 'Ошибка операции!',
            description: 'Не удалось получить статистику Центра мониторинга.\nПовторите попытку позже.',
            type: 'error',
        })
    }
}

export default function* root() {
    yield takeLatest(GET_MAP_STATISTIC.REQUEST, getMapStatistic)
}
