import { GET_INFRASTRUCTURE_COUNT } from './index'

export const getInfrastructureCountRequest = (payload) => ({
    type: GET_INFRASTRUCTURE_COUNT.REQUEST,
    payload,
})

export const getInfrastructureCountSuccess = (payload) => ({
    type: GET_INFRASTRUCTURE_COUNT.SUCCESS,
    payload,
})

export const getInfrastructureCountError = (payload) => ({
    type: GET_INFRASTRUCTURE_COUNT.ERROR,
    payload,
})
