import createAction from '@/helpers/redux/createAction'
import createTableActions from '@/helpers/redux/createTableActions'

export const GET_SEARCH_TABLE = createAction('@/GET_SEARCH_TABLE')
export const GET_SEARCH_NODE = createAction('@/GET_SEARCH_NODE')
export const GET_SEARCH_REPORT_FILE = createAction('@/GET_SEARCH_REPORT_FILE')

export const SEARCH_TABLE = createTableActions('@/SEARCH_TABLE')

export const SET_SEARCH_VALUES = '@/SET_SEARCH_VALUES'
export const RESET_SEARCH_VALUES = '@/RESET_SEARCH_VALUES'
export const RESET_SEARCH_TABLE_PARAMETERS = '@/RESET_SEARCH_TABLE_PARAMETERS'

export const setSearchValues = (searchValues) => ({
    type: SET_SEARCH_VALUES,
    payload: searchValues,
})

export const resetSearchValues = () => ({
    type: RESET_SEARCH_VALUES,
})

export const resetSearchTableParameters = () => ({
    type: RESET_SEARCH_TABLE_PARAMETERS,
})