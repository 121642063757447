import COMPANY_OBJECT from './COMPANY_OBJECT'
import DEVICE from './DEVICE'
import COLD_WATER_DEVICE from './COLD_WATER_DEVICE'
import CENTRAL_HEATING_DEVICE from './CENTRAL_HEATING_DEVICE'

export default {
  COMPANY_OBJECT,
  DEVICE,
  COLD_WATER_DEVICE,
  CENTRAL_HEATING_DEVICE
}
