import React from 'react'
import * as mapTypes from '@/propTypes/maps'
import {
  PinContainer,
  OverLay,
} from './styles'
import CheckIconHover from "@/components/icons/maps/CheckIconHover";
import ToolTip from "@/components/blocks/YandexMap/components/ToolTip";

export const CheckIconPinHover = ({ name, type, id, theme, intl, }) => {
  const Icon = CheckIconHover
    return (
      <PinContainer theme={theme}>
        <OverLay theme={theme}>
          <Icon />
        </OverLay>
        <ToolTip name={name} id={id} theme={theme} type={type} height={35} intl={intl} />
      </PinContainer>
    )
}

CheckIconPinHover.propTypes = {
  theme: mapTypes.theme.isRequired,
  color: mapTypes.markStatus,
}

export default CheckIconPinHover
