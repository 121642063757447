import styled from 'styled-components'
import { Form } from 'formik'
import CheckBoxField from '@/components/fields/CheckBoxField'
import DatePickerField from '@/components/fields/DatePickerField'
import Button from '@/components/blocks/Button'
import {
  WHITE,
  GRAY,
  MINE_SHAFT,
} from '@/constants/styles/defaultTheme'
import { ACCEPT, RESET } from '@/constants/forms/globalFilterForm'

export const CustomDatePicker = styled(DatePickerField)`
  height: 28px;
`

export const FormContainer = styled(Form)`
display: flex;
flex-direction: column;
gap: 10px;
padding: 20px 25px;
min-height: 400px;
background: ${({ theme }) => theme.colors.backgrounds.dark}; 
border-radius: 4px;
width: fit-content;
color: ${({ theme }) => theme.colors.colors.default};
${({ shadow, theme }) => shadow && `
   box-shadow: 2px 3px 15px ${theme.colors.shadows.widget};
`};
transition: 0.4s;
width: 100vw;
border-top-left-radius: 20px;
border-top-right-radius: 20px;
${({close}) => close &&`
 display: none;
`}
`

export const CustomCheckBoxField = styled(CheckBoxField)`
  margin-right: 10px;
`

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
  align-items: center;
  cursor: pointer;
  ${({ datePicker }) => datePicker && `
    width: 135px;
    .react-datepicker-popper {
      width: 300px;
      z-index: 4;
      top: -30px !important;
    }
  `};
  ${({ dropDownObject }) => dropDownObject && `
    min-width: 138px;
    margin-left: 10px;
    margin-right: 10px;
  `};
  ${({ status }) => status && `
    min-width: 97px;
    margin-right: 10px;
  `};
  ${({ checkBox }) => checkBox && `
    margin-right: 16px;
  `};
  ${({ buttonDatePicker }) => buttonDatePicker && `
    margin-left: 10px;
  `};
`

export const Text = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
  ${({ reset }) => reset && `
    cursor: pointer;
  `};
  ${({ datePicker }) => datePicker && `
    margin-right: 16px;
    margin-left: 16px;
  `};
`

export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  color: ${({ theme }) => theme.colors.colors.default};
`

export const TitleAndSelectWrapper = styled.div`
  ${({ isMobile }) => isMobile && `
    @media (max-width: 910px) {
      padding-top: 6px;
      display: flex;
      justify-content: space-between
      position: relative;
    }
  `}
`

export const IconContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  .svgFill {
    fill: ${({ theme }) => theme.colors.colors.default};
    width: 14px;
    height: 14px;
  }
  cursor: pointer;

`

export const DateWrapper = styled.div`
  display: flex;
  position: relative;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 500px;
  gap: 20px;
  margin: auto;
  margin-top: 70px;
`

export const StyledButton = styled(Button)`
  height: 38px;
  box-shadow: ${({ theme }) => theme.colors.shadows.field};
  border-radius: 4px;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  text-transform: none;
  display: flex;
  align-items: center;
  ${({ usage }) => usage === ACCEPT && `
    background: linear-gradient(180deg, #FBBA58 0%, #FF9A02 100%);
    color: ${WHITE};
  `}
  ${({ usage }) => usage === RESET && `
    background: ${GRAY};
    color: ${MINE_SHAFT};
  `}
  &:first-child {
    margin-right: 5px;
  }

  @media (max-width: 910px) {
  ${({ usage }) => usage === ACCEPT && `
    background: #1061DF;
  `}
  width: 150px;
  }
`

export const Switch = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between
  gap: 10px;
  align-items: center;
  background-color: #FAFBFB;
  border-radius: 10px;
  padding: 20px 10px 20px 10px;
  background-color: #F5F5F5;
  border-radius: 10px;
`
  
