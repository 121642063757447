import React from 'react'
import ServiceDeskCell from '@/components/blocks/DutyTable/customCells/ServiceDeskCell'
import LiveTime from '@/components/icons/livetime'
import { FormattedMessage as Lang } from 'react-intl'

export const PLANNED_EXECUTION_DATE = 'plannedExecutionDate'
export const ESSENCE_OF_APPEAL = 'essenceOfAppeal'
export const OBJECT = 'object'
export const OBJECT_TYPE = 'objectType'
export const REGISTRATION_DATE = 'registrationDate'
export const STATUS = 'status'
export const ACTUAL_DATE_OF_EXECUTION = 'actualDateOfExecution'

export const fields = [
  {
    id: ESSENCE_OF_APPEAL,
    name: <Lang id="tableFields.serviceDeskMyCity.essenceOfAppeal" />,
    value: 'ASC',
    customCell: (props) => <ServiceDeskCell withError {...props} />,
  },
  {
    id: OBJECT,
    name: <Lang id="tableFields.serviceDeskMyCity.object" />,
    value: 'ASC',
    customCell: (props) => <ServiceDeskCell {...props} />,
  },
  {
    id: OBJECT_TYPE,
    name: <Lang id="tableFields.serviceDeskMyCity.objectType" />,
    value: 'ASC',
    customCell: (props) => <ServiceDeskCell {...props} />,
  },
  {
    id: REGISTRATION_DATE,
    name: <Lang id="tableFields.serviceDeskMyCity.registrationDate" />,
    value: 'ASC',
    customCell: (props) => <ServiceDeskCell date {...props} />,
  },
  {
    id: STATUS,
    name: <Lang id="tableFields.serviceDeskMyCity.status" />,
    value: 'ASC',
    customCell: (props) => <ServiceDeskCell {...props} />,
  },
  {
    id: PLANNED_EXECUTION_DATE,
    name: <Lang id="tableFields.serviceDeskMyCity.plannedExecutionDate" />,
    value: 'ASC',
    customCell: (props) => <ServiceDeskCell withError date {...props} />,
  },
  {
    id: ACTUAL_DATE_OF_EXECUTION,
    name: <Lang id="tableFields.serviceDeskMyCity.actualDateOfExecution" />,
    value: 'ASC',
    customCell: (props) => <ServiceDeskCell date {...props} />,
  },
]

export const exportOptions = [
  {
    name: 'EXCEL',
    icon: LiveTime.EXELIcon,
  },
  {
    name: 'PDF',
    icon: LiveTime.PDFIcon,
  },
]

export const generateTableData = (rows = 20) => {
  const data = []

  for (let i = 0; i < rows; i += 1) {
    data.push({
      id: i + 1,
      essenceOfAppeal: 'Плановые осмотры',
      object: 'QUL-868-N-PWM-0',
      objectType: 'РМ',
      registrationDate: '30.01.2020 10:37:59',
      status: 'Исполнено',
      plannedExecutionDate: '15.01.2020 00:00:00',
      actualDateOfExecution: '11.03.2020 17:25:42',
    })
  }

  return data
}

export const data = generateTableData()
export const activeFields = [
  'essenceOfAppeal',
  'object',
  'objectType',
  'registrationDate',
  'status',
  'plannedExecutionDate',
  'actualDateOfExecution',
]

export default {
  fields,
  exportOptions,
  activeFields,
}
