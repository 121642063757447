import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_SEARCH_FIELDS_OPTIONS_URL } from '@/constants/apiRoutes'
import { GET_SEARCH_FIELDS_OPTIONS } from '@/store/actions/dictionaries'
import createNotifications from '@/helpers/notification'
import {
  errorGetSearchFieldsOptions,
  responseGetSearchFieldsOptions
} from '@/store/actions/dictionaries/searchFieldsOptions'
import {
    ASU_TYPE_TO_NAME,
    TYPE_TO_NAME,
    PROCESS_TO_NAME
} from '@/constants/maps'
import { sortOptionsByTitle } from '@/helpers/sortOptionsByTitle'

function* getDictionary({ payload }) {
    try {
        const response = yield request({
            url: GET_SEARCH_FIELDS_OPTIONS_URL({
                cityProcessTypes: payload?.processes,
                exploitationOrganizations: payload?.exploitationOrganizations,
                installationTypes: payload?.objectsTypes,
                integrationIds: payload?.integrations,
                integrationTypes: payload?.asu,
                owners: payload?.owners
            }),
            method: 'get',
        })
        if (response && response.data) {
            const equipmentTypes = response.data.OBJECT_TYPES.map(item => {
                return {
                    title: TYPE_TO_NAME[item.value],
                    value: item.value,
                }
            })
            const sortedEquipmentTypes = sortOptionsByTitle(equipmentTypes)

            const geoZones = response.data.GEOZONES.map(item => {
                return {
                    title: item.value,
                    value: item.id
                }
            })
            const sortedGeoZones = sortOptionsByTitle(geoZones)

            const projects = response.data.PROJECTS.map(item => {
                return {
                    title: item.value,
                    value: item.id
                }
            })
            const sortedProjects = sortOptionsByTitle(projects)

            const integrations = response.data.INTEGRATIONS.map(item => {
                return {
                    title: item.value,
                    value: item.id
                }
            })
            const sortedIntegrations = sortOptionsByTitle(integrations)

            const installationTypes = response.data.INSTALLATION_TYPES.map(item => {
                return {
                    title: TYPE_TO_NAME[item.value],
                    value: item.value,
                }
            })
            const sortedInstallationTypes = sortOptionsByTitle(installationTypes)

            const cities = response.data.CITIES.map(item => {
                return {
                    title: item.value,
                    value: item.value
                }
            })
            const sortedCities = sortOptionsByTitle(cities)
            const regions = response.data.REGIONS.map(item => {
                return {
                    title: item.value,
                    value: item.value
                }
            })
            const sortedRegions = sortOptionsByTitle(regions)

            const owners = response.data.OWNERS.map(item => {
                return {
                    title: item.value,
                    value: item.value
                }
            })
            const sortedOwners = sortOptionsByTitle(owners)

            const exploitationOrganizations = response.data.EXPLOAITATION_ORGANIZATIONS.map(item => {
                return {
                    title: item.value,
                    value: item.value
                }
            })
            const sortedExploitationOrganizations = sortOptionsByTitle(exploitationOrganizations)

            const cityProcesses = response.data.CITY_PROCESS_TYPES.map(item => {
                return {
                    title: PROCESS_TO_NAME[item.value],
                    value: item.value
                }
            })
            const sortedCityProcesses = sortOptionsByTitle(cityProcesses)

            const integrationTypes = response.data.INTEGRATION_TYPES.map(item => {
                return {
                    title: ASU_TYPE_TO_NAME[item.value],
                    value: item.value
                }
            })
            const sortedIntegrationTypes = sortOptionsByTitle(integrationTypes)

            const formattedData = {
                equipmentType: sortedEquipmentTypes,
                geoZone: sortedGeoZones,
                project: sortedProjects,
                integration: sortedIntegrations,
                city: sortedCities,
                region: sortedRegions,
                owner: sortedOwners,
                integrationType: sortedIntegrationTypes,
                installationType: sortedInstallationTypes,
                exploitationOrganization: sortedExploitationOrganizations,
                cityProcess: sortedCityProcesses,
            }
            yield put(responseGetSearchFieldsOptions(formattedData))
        }
    } catch (error) {
        yield put(errorGetSearchFieldsOptions(error))
        const toast = createNotifications()
        toast({
            title: 'Ошибка операции!',
            description: 'Не удалось получить данные для формы расширенного поиска.\nПовторите попытку позже.',
            type: 'error',
        })
    }
}

export default function* () {
    yield takeLatest(GET_SEARCH_FIELDS_OPTIONS.REQUEST, getDictionary)
}