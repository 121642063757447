import widgets from '../widgets'

const {
  DATA_TIME,
  LIGHT_STATUS,
  LIGHT_LEVEL_KULON,
  VOLTAGE_ARRAY,
  AMPERAGE,
  ENERGY_CONSUMED_ARRAY,
  POWER_ARRAY,
} = widgets

export default [
  LIGHT_STATUS({}),
  DATA_TIME({}),
  LIGHT_LEVEL_KULON({}),
  ENERGY_CONSUMED_ARRAY({ count: 4, number: 1 }),
  VOLTAGE_ARRAY({ count: 1, number: 1, selector: 'voltage' }),
  AMPERAGE({}),
  POWER_ARRAY({ count: 1, number: 1, selector: 'totalPower' }),
]
