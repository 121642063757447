import generateDefaultTableState from '@/helpers/table/generateDefaultTableState'
import { handleActions } from 'redux-actions'
import generateDefaultTableReducer, { generateTableParameter } from '@/helpers/table/generateDefaultTableReducer'
import {
  GET_SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE,
  SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE,
  SET_ACTION,
  SET_ACTIVITY_LOG_DATES_RANGE,
} from '@/store/actions/scheduleManager'
import {
  activityLogFields
} from '@/constants/tablesConfig/scheduleManager'

const initialState = {
  data: [],
  table: generateDefaultTableState(activityLogFields, {direction: "DESC", field: "createDateTime"}),
  loadingTable: false,
  dates: {
    start: null,
    end: null,
  },
  action: null
}

export const reducer = handleActions(
    {
  [GET_SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE.REQUEST]: (state) => ({
    ...state,
    loadingTable: true,
  }),
  [GET_SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE.SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload.data,
    ...generateTableParameter(state, 'resultCount', payload.totalElements),
    loadingTable: false,
  }),
  [GET_SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE.ERROR]: (state) => ({
    ...state,
    loadingTable: false,
  }),
  [SET_ACTIVITY_LOG_DATES_RANGE]: (state, { payload }) => ({
    ...state,
    dates: payload,
    ...generateTableParameter(state, 'page', 1),
  }),
  [SET_ACTION]: (state, { payload }) => ({
    ...state,
    action: payload,
    ...generateTableParameter(state, 'page', 1),
  }),
  ...generateDefaultTableReducer(SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE),
  },
  initialState,
)

export default reducer