export const getIsReadByStatus = (statuses = []) => {
  if (statuses.length <= 2 && statuses.includes('new')) {
    return false
  }
  if (statuses.length <= 2 && statuses.includes('old')) {
    return true
  }
  if (statuses.includes('new') && statuses.includes('old')) {
    return null
  }

  return null
}

export default getIsReadByStatus
