import React, { useCallback, useMemo } from 'react'
import get from 'lodash/get'
import ImagesSlider from '@/components/blocks/ImagesSlider'
import * as mapObjectsPassports from '@/constants/mapObjectsPasports'
// import placeholderImage from '@/assets/images/placeholder.jpg'
import placeholderVideo from '@/assets/images/placeholderVideo.png'
import { defaultImage } from '@/assets/images/objectElement'
import {
  Container,
  DataWrapper,
  DataRow,
} from './styles'

const ObjectInformation = (props) => {
  const selectType = useCallback(() => {
    if (props.installationType) {
      return (mapObjectsPassports.default[props.installationType] || []).filter(
        (item) => item.showInDashboard === true,
      )
    }
    return []
  }, [props])

  const renderParameters = useCallback(() => {
    if (!props) {
      return null
    }
    const newTelemetry = {
      ...props,
      ...props.telemetry,
      latitude: props.point ? props.point.latitude : null,
      longitude: props.point ? props.point.longitude : null,
      card: props.card ? props.card.reduce((accumulator, item) => { return {...accumulator, [item.fieldName]: item.value}}, {}) : []
    }
    return (
      <>
        {selectType(props).map((row) => (
          <DataRow key={row.selector}>
            <b>
              {row.passportParameter}
              {': '}
            </b>
            {get(row.isCard ? newTelemetry.card : newTelemetry, row.selector, '-')}
          </DataRow>
        ))}
      </>
    )
  }, [props, selectType])

  const dataForQr = useMemo(() => (
    selectType()
      .map((row) => `${row.passportParameter}: ${get(props, row.selector, '...')}`)
      .join('\n')
  ), [props, selectType])

  return (
    <Container>
        { props.installationType &&
            <>
                <ImagesSlider
                    images={[
                    {
                        type: 'image',
                        url: props.image || defaultImage[props.type],
                    },
                    {
                        type: 'qrCode',
                        url: dataForQr,
                    },
                    {
                        type: 'video',
                        url: placeholderVideo,
                        // title: 'IP-234-DR-17',
                        // online: false,
                    },
                ]}
                setStreamUrl={props.setStreamUrl}
                id={props.id}
                streamUrl={props.streamUrl}
                getStreamUrl={props.getStreamUrl}
                />
                <DataWrapper>
                    {renderParameters(props)}
                </DataWrapper>
            </>
        }
    </Container>
  )
}

export default ObjectInformation
