import EMailIcon from './EMailIcon'
import IcLockIcon from './IcLockIcon'
import IcUserIcon from './IcUserIcon'
import LoaderIcon from './LoaderIcon'
import LogoIcon from './LogoIcon'
import PhoneIcon from './PhoneIcon'
import ResetIcon from './ResetIcon'
import SaveIcon from './SaveIcon'
import SelectIcon from './SelectIcon'
import SelectedCountIcon from './SelectedCountIcon'
import SelectedIcon from './SelectedIcon'
import UserIcon from './UserIcon'
import UserGroupIcon from './UserGroupIcon'
import UserSettingsIcon from './UserSettingsIcon'
import ThemeIcon from './ThemeIcon'
import NotificationsIcon from './NotificationsIcon'
import SecurityIcon from './SecurityIcon'
import SyncIcon from './SyncIcon'
import SynchronizationIcon from './SynchronizationIcon'
import IntegrationItemIcon from './IntegrationItemIcon'
import IntegrationSystemIcon from './IntegrationSystemIcon'
import PlusIcon from './PlusIcon'
import ImageIcon from './ImageIcon'
import QRCodeIcon from './QRCodeIcon'
import PrintIcon from './PrintIcon'
import VideoIcon from './VideoIcon'
import DoneIcon from './DoneIcon'
import MarketIcon from './MarketIcon'
import CalendarIcon from './CalendarIcon'
import DiagramIcon from './DiagramIcon'
import GraphIcon from './GraphIcon'
import TableIcon from './TableIcon'
import PlayIcon from './PlayIcon'
import NoImageIcon from './NoImageIcon'
import GearIcon from './GearIcon'
import LinearChartIcon from './LinearChartIcon'
import HistogramChartIcon from './HistogramChartIcon'
import ClientIDIcon from './ClientIDIcon'
import SecretIDIcon from './SecretIDIcon'
import CoreIcon from './CoreIcon'
import EditIcon from './EditIcon'
import EraserIcon from './EraserIcon'
import BLGLogoIcon from './BLGLogoIcon'
import PopupGeoZoneIcon from './PopupGeoZoneIcon'
import InvertCrossIcon from './InvertCrossIcon'
import ThermometerIcon from './ThermometerIcon'
import TrashCanIcon from './TrashCanIcon'
import LightOnIcon from './LightOnIcon'
import LightOffIcon from './LightOffIcon'
import BigLightIcon from './BigLightIcon'
import ResetInputIcon from './ResetInputIcon'
import ScrewdriverIcon from './ScrewdriverIcon'
import UncknowIcon from './UncknowIcon'
import ICIcon from './ICIcon'
import SettingsIcon from './SettingsIcon'
import XMLIcon from './XMLIcon'
import FileUploadIcon from './FileUploadIcon'
import TrashIcon from './TrashIcon'
import HomeIcon from './HomeIcon'
import ApplicationsIcon from './ApplicationsIcon'
import SettingsUserIcon from './SettingsUserIcon'
import AlertIcon from './AlertIcon'
import FileExport from './FileExport'
import SortIcon from './SortIcon'
import InstallIcon from './InstallIcon'

export default {
  EMailIcon,
  IcLockIcon,
  IcUserIcon,
  LoaderIcon,
  LogoIcon,
  PhoneIcon,
  ResetIcon,
  SaveIcon,
  SelectIcon,
  SelectedCountIcon,
  SelectedIcon,
  UserGroupIcon,
  UserSettingsIcon,
  ThemeIcon,
  NotificationsIcon,
  SecurityIcon,
  SyncIcon,
  SynchronizationIcon,
  IntegrationSystemIcon,
  IntegrationItemIcon,
  PlusIcon,
  ImageIcon,
  QRCodeIcon,
  PrintIcon,
  VideoIcon,
  DoneIcon,
  MarketIcon,
  CalendarIcon,
  DiagramIcon,
  GraphIcon,
  TableIcon,
  PlayIcon,
  NoImageIcon,
  GearIcon,
  LinearChartIcon,
  HistogramChartIcon,
  ClientIDIcon,
  SecretIDIcon,
  CoreIcon,
  EditIcon,
  EraserIcon,
  BLGLogoIcon,
  PopupGeoZoneIcon,
  InvertCrossIcon,
  ThermometerIcon,
  TrashCanIcon,
  LightOnIcon,
  LightOffIcon,
  BigLightIcon,
  ResetInputIcon,
  ScrewdriverIcon,
  UncknowIcon,
  XMLIcon,
  ICIcon,
  SettingsIcon,
  FileUploadIcon,
  TrashIcon,
  HomeIcon,
  ApplicationsIcon,
  SettingsUserIcon,
  AlertIcon,
  FileExport,
  SortIcon,
  InstallIcon,
  UserIcon
}
