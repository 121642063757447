import React  from 'react'
import { GET_APPLICATIONS_FILE_URL } from '@/constants/apiRoutes';

import {
    Container,
    OptionTitle,
    OptionContainer,
} from './styles'

const HelpDropdown = ({ data }) => {
    return (
        <Container>
            <OptionContainer>
                {(data || []).map((file) => {
                    return (
                        <OptionTitle href={GET_APPLICATIONS_FILE_URL(file.fileId)} target="_blank">{file.originalContentType}</OptionTitle>
                    )
                })}
            </OptionContainer>
        </Container>
    )
}

HelpDropdown.propTypes = {

}

HelpDropdown.defaultProps = {

}

export default HelpDropdown
