import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const InstallIcon = ({ color, onClick }) => {

  return (
    <svg width="48" height="48" viewBox="0 0 112 112" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M103.295 26H32V86H103.295C93.3601 101.629 75.8911 112 56 112C25.0721 112 0 86.9279 0 56C0 25.0721 25.0721 0 56 0C75.8911 0 93.3601 10.3707 103.295 26Z" fill="url(#paint0_radial_2408_45613)"/>
      <path fill-rule="evenodd" clip-rule="evenodd" d="M34 26C17.4315 26 4 39.4315 4 56C4 72.5685 17.4315 86 34 86H62C45.4315 86 32 72.5685 32 56C32 39.4315 45.4315 26 62 26H34Z" fill="url(#paint1_linear_2408_45613)"/>
      <ellipse cx="81" cy="56" rx="31" ry="30" fill="url(#paint2_radial_2408_45613)"/>
      <defs>
      <radialGradient id="paint0_radial_2408_45613" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(83.5 8) rotate(104.811) scale(107.574 107.574)">
      <stop stop-color="#EFEFEF"/>
      <stop offset="1" stop-color="#9EA09F"/>
      </radialGradient>
      <linearGradient id="paint1_linear_2408_45613" x1="36" y1="26" x2="36" y2="86" gradientUnits="userSpaceOnUse">
      <stop stop-color="#3C3C3A"/>
      <stop offset="1" stop-color="#8D8D8D"/>
      </linearGradient>
      <radialGradient id="paint2_radial_2408_45613" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(89.5 49.5) rotate(114.692) scale(47.8774 49.4734)">
      <stop stop-color="#F5F7FC"/>
      <stop offset="0.69" stop-color="#0185CE"/>
      <stop offset="1" stop-color="#0083CB"/>
      </radialGradient>
      </defs>
    </svg>
  )
}

InstallIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

InstallIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(InstallIcon)
