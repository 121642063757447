import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { Formik } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'
import TextField from '@/components/fields/TextField'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import CheckBoxField from '@/components/fields/CheckBoxField'
import {
  userGroupCreate,
} from '@/constants/validationFields'
import {
  NAME,
  BLOCK_ORGANIZATION,
} from '@/constants/forms/group'
import {
  FormWrapper,
  FieldsWrapper,
  InputAndLabelContainer,
  Label,
  Text,
} from './styles'

const GroupOfUsersForm = ({
  saveFormProps,
  initialValues,
  edit,
  disabled,
  isFormModifying,
}) => (
  <Formik
    initialValues={initialValues}
    isInitialValid={!!edit}
    validationSchema={userGroupCreate}
    render={(formProps) => {
      const {
        touched, errors, handleSubmit, isSubmitting, values,
      } = formProps
      saveFormProps(formProps)

      return (
        <FormWrapper onSubmit={handleSubmit}>
          <FieldsWrapper>
            <InputAndLabelContainer>
              <LabelWithIcon
                isError={(touched[NAME] && errors[NAME])}
                title={<Lang id="usersPage.groupOfUsers.name" />}
                userForm
              />
              {isFormModifying
                ? (
                  <TextField
                    error={(touched[NAME] && errors[NAME])}
                    name={NAME}
                    fieldProps={{
                      autoComplete: 'off',
                      disabled: isSubmitting || disabled,
                    }}
                    resetButton
                  />
                )
                : (
                  <Text>
                    {values[NAME]}
                  </Text>
                )}
            </InputAndLabelContainer>
            {!disabled && isFormModifying && (
              <LabelWithIcon
                title={<Lang id="usersPage.groupOfUsers.requiredFields" />}
                userForm
              />
            )}
            {edit && !disabled && isFormModifying && (
              <InputAndLabelContainer row>
                <CheckBoxField name={BLOCK_ORGANIZATION} marginRight />
                <Label>
                  <Lang id="usersPage.groupOfUsers.blockGroup" />
                </Label>
              </InputAndLabelContainer>
            )}
          </FieldsWrapper>
        </FormWrapper>
      )
    }}
  />
)

GroupOfUsersForm.propTypes = {
  initialValues: pt.shape({
    [NAME]: pt.string,
    [BLOCK_ORGANIZATION]: pt.bool,
  }),
  saveFormProps: pt.func,
  onNameChange: pt.func,
  onSubmitForm: pt.func,
  edit: pt.bool,
  disabled: pt.bool,
}
GroupOfUsersForm.defaultProps = {
  initialValues: {},
  saveFormProps: noop,
  onSubmitForm: noop,
  onNameChange: noop,
  edit: false,
  disabled: false,
}

export default GroupOfUsersForm
