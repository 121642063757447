import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const DeleteIcon = ({ color, onClick }) => {

  return (
    <svg onClick={onClick} width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1069_2226)">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5002 25.6667C20.9435 25.6667 26.1668 20.4434 26.1668 14C26.1668 7.55666 20.9435 2.33334 14.5002 2.33334C8.05682 2.33334 2.8335 7.55666 2.8335 14C2.8335 20.4434 8.05682 25.6667 14.5002 25.6667ZM20.3335 13.0278H8.66683V14.9722H20.3335V13.0278Z" fill="#EE2747"/>
      </g>
      <defs>
      <clipPath id="clip0_1069_2226">
      <rect width="28" height="28" fill="white" transform="translate(0.5)"/>
      </clipPath>
      </defs>
    </svg>
  )
}

DeleteIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

DeleteIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(DeleteIcon)