import widgets from '../widgets'

const {
  ACTIVE_CONTROLLER,
  GSM_LEVEL,
  GSM_TYPE,
  HAS_CONNECTION,
  ALARM_STATE,
  STATUS
} = widgets

export default [
  STATUS({}),
  ACTIVE_CONTROLLER({}),
  GSM_LEVEL({}),
  GSM_TYPE({}),
  HAS_CONNECTION({}),
  ALARM_STATE({}),
]
