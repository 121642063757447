import {
  put, takeLatest, select
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { OBJECTS_GROUPS_CREATE_ENDPOINT } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { setGlobalLoading } from '@/store/actions/appSettings'
import { requestGetObjectsGroupsTree } from '@/store/actions/objectsGroups/getTree'
import { errorEditObjectsGroup, successEditObjectsGroup } from '@/store/actions/objectsGroups/editObjectsGroup'
import { EDIT_OBJECTS_GROUP } from '@/store/actions/objectsGroups'
import { getControlCupboardOriginalDictionary } from '@/store/selectors/objectsGroups'
import uniqBy from 'lodash/uniqBy'
import getObjectsGroupCreateRequestTree from '@/helpers/getObjectsGroupCreateRequestTree'

function* editObjectsGroupSaga({ payload }) {
  const { values, initialValues, filters, groupId, onCancel } = payload

  const controlCupboardOriginalDictionary = yield select(getControlCupboardOriginalDictionary)
  const controlCupboardIds = filters.controlCupboard.map((filter) => controlCupboardOriginalDictionary.filter(item => {
    const value = controlCupboardOriginalDictionary.filter(cupboard => cupboard.id === filter)[0].value
    return item.value === value
  })).flat().map(el => el.id)

  const getObjectIds = () => {
    const objectsTree = getObjectsGroupCreateRequestTree(values.objects)
    return uniqBy(objectsTree, 'id')
  }

  try {
    yield put(setGlobalLoading(true))
    const body = {
      id: groupId,
      name: values.name,
      color: values.color,
      objectFilter: Object.values(filters).some(item => item.length > 0) ? {
        balanceSheetHolders: filters.networkCompany,
        controlCupboardIds: controlCupboardIds,
        installationTypes: filters.objectType,
        lampTypes: filters.lightFixtureType,
        locationTypes: filters.pedestrianCrossingSign,
        registerNumbers: filters.registryNumber,
        streets: filters.street,
        utilityPoleTypes: filters.utilityPoleType,
        vols: filters.vols,
        parentId: values.objects[0].parentId,
      } : null,
      objects: getObjectIds(),
      initialObjectIds: initialValues.objects.map(item => item.id)
    }
    const { data } = yield request({
      url: OBJECTS_GROUPS_CREATE_ENDPOINT,
      method: 'put',
      body,
    })

    if (data) {
      const toast = createNotifications()
      yield put(requestGetObjectsGroupsTree())
      yield put(setGlobalLoading(false))
      onCancel()
      toast({
        title: 'Редактирование группы',
        type: 'success',
        description: 'Изменения сохранены',
      })
      yield put(successEditObjectsGroup())
    }
  } catch (error) {
    const toast = createNotifications()
    if (error.response.data.errorMessage === 'Group.name.already.exists') {
      toast({
        title: 'Ошибка операции!',
        description: 'Данное название уже существует',
        type: 'error',
      })
      yield put(setGlobalLoading(false))
      return
    }
    if (error.response.data.errorMessage === 'Group.color.already.exists') {
      toast({
        title: 'Ошибка операции!',
        description: 'Данный цвет уже занят',
        type: 'error',
      })
      yield put(setGlobalLoading(false))
      return
    }
    if (error.response.data.errorMessage === 'Incorrect.object.types') {
      toast({
        title: 'Ошибка операции!',
        description: 'Невозможно изменить состав группы объектов, так как добавленные объекты принадлежат другому типу, чем уже существующие в группе объекты',
        type: 'error',
      })
      yield put(setGlobalLoading(false))
      return
    }
    if (error.response.data.errorMessage === 'Unlink.schedule.before.add.object.of.different.type') {
      toast({
        title: 'Ошибка операции!',
        description: 'Невозможно изменить состав группы объектов, так как добавленные объекты принадлежат другому типу, чем уже существующие в группе объекты',
        type: 'error',
      })
      yield put(setGlobalLoading(false))
      return
    }
    if (error.response.data.errorMessage === 'Incorrect.verification.state') {
      toast({
        title: 'Ошибка операции!',
        description: 'Невозможно изменить состав группы объектов, так как добавленные объекты еще не прошли процедуру верификации на платформе.',
        type: 'error',
      })
      yield put(setGlobalLoading(false))
      return
    }
    yield put(errorEditObjectsGroup())
    yield put(setGlobalLoading(false))
    onCancel()
    console.log('function*editObjectsGroupSaga -> error', error)
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось изменить группу объектов. \nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(EDIT_OBJECTS_GROUP.REQUEST, editObjectsGroupSaga)
}
