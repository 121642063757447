import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'
import get from 'lodash/get'

export const getScheduleManager = createSelector(
  getState,
  (state) => get(state, 'scheduleManager', {}),
)

export const getTreeData = createSelector(
  getScheduleManager,
  (scheduleManagerState) => get(scheduleManagerState, 'tree', []),
)

export const getSchedule = createSelector(
  getScheduleManager,
  (scheduleManagerState) => get(scheduleManagerState, 'card', {}),
)

export const getTreeStatus = createSelector(
  getScheduleManager,
  (scheduleManagerState) => get(scheduleManagerState, 'treeStatus', []),
)

export const getScheduleSelectedNode = createSelector(
  getScheduleManager,
  (scheduleManagerState) => get(scheduleManagerState, 'selectedNode', {}),
)