import React, { useState } from 'react'

import { withRouter } from 'react-router-dom'
import pt from 'prop-types'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import { WIDGETS_CONFIG_MOBILE } from '@/constants/widgets'
import { isUserHasPermission } from '@/helpers/userPermissions'
import WidgetsDashboardIcon from '@/components/icons/widgetsDashboard'
import SettingsApplications from './SettingsApplications'
import { ID_BY_APPLICATIONS } from '@/constants/widgets'

import {
  ColumnsContainer,
  Row,
  Icon,
  Title,
  TitlePage,
  Header,
  StyledLink
} from './styles'
import { FormattedMessage as Lang } from 'react-intl'

const ApplicationMobile = ({
  items,
  user,
  isSideBarMenuPinned,
  isMenuOpen,
}) => {

  const [openSettingsApplications, setOpenSettingsApplications] = useState(false)

  const onOpenSettingsApplications = () => {
    setOpenSettingsApplications(true)
  }

  const onCloseSettingsApplications= () => {
    setOpenSettingsApplications(false)
  }

  if(openSettingsApplications) {
    return <SettingsApplications onCloseSettingsApplications={onCloseSettingsApplications} items={items}/>
  } else {
    return (
      <>
        <Header>
          <TitlePage>
            <Lang id="settingsSidebar.widgetShopApp" />
          </TitlePage>
          <WidgetsDashboardIcon.DotsIcon  onClick={onOpenSettingsApplications}/>
        </Header>
        <ColumnsContainer>
            {items.map((item) => {
              if (
                (item.applicationId)
                && !isUserHasPermission(user, item.applicationId)
              ) {
                return null
              }
              const MenuIcon = item.menuIcon
              return (
                <StyledLink key={item.name} to={(item.applicationId === ID_BY_APPLICATIONS.MONITORING_CENTER || item.applicationId === ID_BY_APPLICATIONS.PASSPORTIZATION) ? item.path : '#'}>
                  <PortalTooltip
                    title={!(isSideBarMenuPinned || isMenuOpen) ? item.title : null}
                    renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                      <Row
                        ref={wrapperRef}
                        onMouseEnter={onMouseEnterHandler}
                        onMouseLeave={onMouseLeaveHandler}
                      >
                        <Icon><MenuIcon color={'#1061DF'}/></Icon>
                        <Title>{item.title}</Title>
                      </Row>
                    )}
                  />
                </StyledLink>
              )
            })}
        </ColumnsContainer>
      </>
    )
  }

  
}

ApplicationMobile.defaultProps = {
  items: WIDGETS_CONFIG_MOBILE,
}

ApplicationMobile.propTypes = {
  items: pt.arrayOf(pt.object),
  pinnedSideBarMenu: pt.func.isRequired,
  isSideBarMenuPinned: pt.bool.isRequired,
  isMenuOpen: pt.bool.isRequired,
  onToggleMenu: pt.func.isRequired,
  user: pt.shape({
    modules: pt.arrayOf(pt.number),
    roles: pt.shape({
      isSuperAdmin: pt.bool,
    }),
  }).isRequired,
}


export default React.memo(withRouter(ApplicationMobile))
