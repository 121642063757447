import { GET_ONLINE_USERS } from './index'

export const requestGetOnlineUsers = () => ({
  type: GET_ONLINE_USERS.REQUEST,
})

export const eventsGetOnlineUsers = (data) => ({
  type: GET_ONLINE_USERS.EVENTS,
  payload: data,
})

export const successGetOnlineUsers = (payload) => ({
  type: GET_ONLINE_USERS.SUCCESS,
  payload,
})

export const errorGetOnlineUsers = (payload) => ({
  type: GET_ONLINE_USERS.ERROR,
  payload,
})