import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  successGetObjectTypes,
  errorGetObjectTypes,
} from '@/store/actions/installation/getObjectTypes'

import { generateGetTypes } from '@/constants/apiRoutes'
import { GET_OBJECT_TYPES } from '@/store/actions/installation'
import createNotifications from '@/helpers/notification'
import { TYPE_TO_NAME } from '@/constants/maps'
import { sortOptionsByTitle } from '@/helpers/sortOptionsByTitle'

function* getObjectTypesSaga({ payload: { integrationType, type } }) {
  try {
    const { data } = yield request({
      url: generateGetTypes(integrationType, type),
      method: 'get',
    })
    const formattedData = data.map((name) => ({
      value: name,
      title: TYPE_TO_NAME[name],
    }))
    const sortedObjectTypes = sortOptionsByTitle(formattedData)
    yield put(successGetObjectTypes(sortedObjectTypes))
  } catch (error) {
    console.log('function*getObjectTypesSaga -> error', error)
    yield put(errorGetObjectTypes())
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_OBJECT_TYPES.REQUEST, getObjectTypesSaga)
}
