export default {
  catalogs: {
      // controller passport
      GENERAL_INFORMATION: 'general information',
      TECHNICAL_CONSTRAINTS: 'technical parameters',
      EXPLOITATION: 'exploitation',
      ATTACHMENT: 'attachments',
    // controller card
    LOCATION: 'location',
    OBJECT_CHARACTERISTIC: 'object characteristics',
    SURVEY_DATA: 'survey data',
    SERVICING: 'object service',
  },
  fields: {
    'LOCATION.PROJECT': 'Project',
    'PROJECT': 'Project',
    'LOCATION.LATITUDE': 'Latitude',
    'LATITUDE': 'Latitude',
    'LOCATION.LONGITUDE': 'Longitude',
    'LONGITUDE': 'Longitude',
    'LOCATION.REGION': 'Region',
    'REGION': 'Region',
    'LOCATION.CITY': 'City',
    'CITY': 'City',
    'LOCATION.STREET': 'Street',
    'STREET': 'Street',
    'LOCATION.HOME_NUMBER': 'House',
    'HOME_NUMBER': 'House',


    'OBJECT_CHARACTERISTIC.EQUIPMENT_TYPE': 'Equipment type',
    'EQUIPMENT_TYPE': 'Equipment type',
    'OBJECT_CHARACTERISTIC.lampType': 'Lamp type',
    'lampType': 'Lamp type',
    'OBJECT_CHARACTERISTIC.LIGHTING_CATEGORY': 'Lighting object category',
    'LIGHTING_CATEGORY': 'Lighting object category',
    'OBJECT_CHARACTERISTIC.IMPORTANCE': 'Object importance',
    'IMPORTANCE': 'Object importance',
    'OBJECT_CHARACTERISTIC.WORK_MODE': 'Operating mode',
    'WORK_MODE': 'Operating mode',
    'OBJECT_CHARACTERISTIC.INSTALLATION_TYPE': 'Installation type',
    'INSTALLATION_TYPE': 'Installation type',
    'OBJECT_CHARACTERISTIC.CONTROL_TYPE': 'Control Type',
    'CONTROL_TYPE': 'Control Type',
    'OBJECT_CHARACTERISTIC.LIGHTING_TYPE': 'Lighting object type',
    'LIGHTING_TYPE': 'Lighting object type',
    'OBJECT_CHARACTERISTIC.OUTGOING_LINES_NUMBER': 'Number of outgoing lines',
    'OUTGOING_LINES_NUMBER': 'Number of outgoing lines',
    'OBJECT_CHARACTERISTIC.SETUP': 'Setup',
    'SETUP': 'Setup',
    'OBJECT_CHARACTERISTIC.INDICATORS': 'Indicators',
    'INDICATORS': 'Indicators',
    'OBJECT_CHARACTERISTIC.CONNECTED_FIXTURES_NUMBER': 'Number of connected fixtures',
    'CONNECTED_FIXTURES_NUMBER': 'Number of connected fixtures',
    'OBJECT_CHARACTERISTIC.LIGHT_FUNCTIONALITY': 'Light functionality',
    'LIGHT_FUNCTIONALITY': 'Light functionality',
    'OBJECT_CHARACTERISTIC.LOCATION_TYPE': 'Pedestrian Space Feature',
    'OBJECT_CHARACTERISTIC.CONNECTED_LAMP_NUMBER': 'Number of connected lights',


    'SURVEY_DATA.CONNECTED_LAMP_NUMBER': 'Number of connected lights',
    'CONNECTED_LAMP_NUMBER': 'Number of connected lights',
    'SURVEY_DATA.LOCATION_TYPE': 'Pedestrian Space Feature',
    'LOCATION_TYPE': 'Pedestrian Space Feature',
    'SURVEY_DATA.LAMP_INSTALLATION_HEIGHT': 'Lamp installation height in meters',
    'LAMP_INSTALLATION_HEIGHT': 'Lamp installation height in meters',
    'SURVEY_DATA.ROADWAY_WIDTH': 'Width of the roadway in meters',
    'ROADWAY_WIDTH': 'Width of the roadway in meters',
    'SURVEY_DATA.INSTALLATION_SCHEME': 'Support Installation Scheme',
    'INSTALLATION_SCHEME': 'Support Installation Scheme',
    'SURVEY_DATA.STATE': 'Support state',
    'STATE': 'Support state',
    'SURVEY_DATA.POWER_SUPPLY': 'Power',
    'POWER_SUPPLY': 'Power',
    'SURVEY_DATA.POWER_SUPPLY_TYPE': 'Power type',
    'POWER_SUPPLY_TYPE': 'Power type',
    'SURVEY_DATA.OVERHANG_HEIGHT': 'Overhang of light point in meters',
    'OVERHANG_HEIGHT': 'Overhang of light point in meters',
    'SURVEY_DATA.LAMP_ANGLE': 'Inclination of light point in degrees',
    'LAMP_ANGLE': 'Inclination of light point in degrees',
    'SURVEY_DATA.OVERHANG_LENGTH': 'Console length in meters',
    'OVERHANG_LENGTH': 'Console length in meters',
    'SURVEY_DATA.UTILITY_POLE_LAMP_POSITION': 'Position of the lamp in the plan on the support in degrees',
    'UTILITY_POLE_LAMP_POSITION': 'Position of the lamp in the plan on the support in degrees',
    'SURVEY_DATA.CITY_NUMERIC': 'City numeric',
    'CITY_NUMERIC': 'City numeric',
    'SURVEY_DATA.UTILITY_POLE_TYPE': 'Pole type',
    'UTILITY_POLE_TYPE': 'Pole type',
    'SURVEY_DATA.UTILITY_POLE_KIND': 'Pole type kind',
    'UTILITY_POLE_KIND': 'Pole type kind',
    'SURVEY_DATA.VOLS': 'VOLS',
    'VOLS': 'VOLS',
    'SURVEY_DATA.OBJECT_ATTRIBUTE': 'Object attribute',
    'OBJECT_ATTRIBUTE': 'Object attribute',
    'SURVEY_DATA.BELONGING': 'Belonging',
    'BELONGING': 'Belonging',
    'SURVEY_DATA.CONNECTED_FIXTURES_NUMBER': 'Connected fixtures number',
    'SURVEY_DATA.LAMP_TYPE': 'Lamp type',
    'LAMP_TYPE': 'Lamp type',
    'SURVEY_DATA.LIGHT_FIXTURE_STATE': 'Lamp state',
    'LIGHT_FIXTURE_STATE': 'Lamp state',
    'SURVEY_DATA.CONSOLE': 'Console',
    'CONSOLE': 'Console',
    'SURVEY_DATA.NETWORK_COMPANY': 'Network company',
    'NETWORK_COMPANY': 'Network company',


    'SERVICING.INSPECTION_DATE': 'Inspection date',
    'INSPECTION_DATE': 'Inspection date',
    'SERVICING.BYPASS_DATE': 'Date bypass',
    'BYPASS_DATE': 'Date bypass',
    'SERVICING.MAINTENANCE_DATE': 'Maintenance date',
    'MAINTENANCE_DATE': 'Maintenance date',
    'SERVICING.REPAIR_DATE': 'Repair Date',
    'REPAIR_DATE': 'Repair Date',
    'SERVICING.CAPITAL_REPAIR_DATE': 'Date of overhaul',
    'CAPITAL_REPAIR_DATE': 'Date of overhaul',
    'SERVICING.GUARANTEE_EXPIRATION_DATE': 'Warranty expiration date',
    'GUARANTEE_EXPIRATION_DATE': 'Warranty expiration date',
    'SERVICING.CHECKING_DATE': 'Date of verification',
    'CHECKING_DATE': 'Date of verification',
    'SERVICING.PLAN_PPR_DATE': 'Date plan SPM',
    'PLAN_PPR_DATE': 'Date plan SPM',
    'SERVICING.FACT_PPR_DATE': 'Date fact SPM',
    'FACT_PPR_DATE': 'Date fact SPM',


     'GENERAL_INFORMATION.IDENTIFIER': 'Identifier',
     'IDENTIFIER': 'Identifier',
     'GENERAL_INFORMATION.NAME': 'Name',
     'NAME': 'Name',
     'GENERAL_INFORMATION.INSTALLATION': 'Installation',
     'INSTALLATION': 'Installation',
     'GENERAL_INFORMATION.EQUIPMENT_TYPE': 'Equipment type',
     'GENERAL_INFORMATION.ROOT_OBJECT': 'Root object',
     'ROOT_OBJECT': 'Root object',
     'GENERAL_INFORMATION.DEVICE_SERIAL_NUMBER': 'Serial number',
     'DEVICE_SERIAL_NUMBER': 'Serial number',
     'GENERAL_INFORMATION.METER_SN': 'Counter №',
     'METER_SN': 'Counter №',
     'GENERAL_INFORMATION.TIME_ZONE': 'Device timezone',
     'TIME_ZONE': 'Device timezone',
     'GENERAL_INFORMATION.CONN_TYPE': 'Connection type',
     'CONN_TYPE': 'Connection type',
     'GENERAL_INFORMATION.SWVER': 'Software version',
     'SWVER': 'Software version',
     'GENERAL_INFORMATION.ICCID': 'Sim-card id',
     'ICCID': 'Sim-card id',
     'GENERAL_INFORMATION.ICCID1': 'Slot 1 Sim-card id',
     'ICCID1': 'Slot 1 Sim-card id',
     'GENERAL_INFORMATION.ICCID2': 'Slot 2 Sim-card id',
     'ICCID2': 'Slot 2 Sim-card id',
     'GENERAL_INFORMATION.VERSION_API': 'Software version (API)',
     'VERSION_API': 'Software version (API)',
     'GENERAL_INFORMATION.UTILITY_POLE_TYPE': 'Prop type',
     'GENERAL_INFORMATION.MODEL_MARK': 'Model/Make',
     'MODEL_MARK': 'Model/Make',
     'GENERAL_INFORMATION.LAMP_TYPE': 'Lamp type',
     'GENERAL_INFORMATION.PROCEDURE_SUPPLIER': 'Manufacturer',
     'PROCEDURE_SUPPLIER': 'Manufacturer',
     'GENERAL_INFORMATION.LIGHTING_CONTROL_BOX_IDENTIFIER': 'LCB ID',
     'LIGHTING_CONTROL_BOX_IDENTIFIER': 'LCB ID',
     'GENERAL_INFORMATION.AUTOMATED_CONTROL_SYSTEM_IDENTIFIER': 'ACS ID',
     'AUTOMATED_CONTROL_SYSTEM_IDENTIFIER': 'ACS ID',
     'GENERAL_INFORMATION.LAMP_IDENTIFIER': 'Lamp ID',
     'LAMP_IDENTIFIER': 'Lamp ID',
     'GENERAL_INFORMATION.LINE_IDENTIFIER': 'Line ID',
     'LINE_IDENTIFIER': 'Line ID',
     'GENERAL_INFORMATION.BASE_STATION_IDENTIFIER': 'Base Station ID',
     'BASE_STATION_IDENTIFIER': 'Base Station ID',
     'GENERAL_INFORMATION.UTILITY_POLE_IDENTIFIER': 'Pole ID',
     'UTILITY_POLE_IDENTIFIER': 'Pole ID',
     'GENERAL_INFORMATION.REGISTRY_NUMBER': 'Registry number',
     'REGISTRY_NUMBER': 'Registry number',
     'GENERAL_INFORMATION.REGISTRY_NUMBER_ASUNO': 'Registry number ASUNO',
     'REGISTRY_NUMBER_ASUNO': 'Registry number ASUNO',
     'GENERAL_INFORMATION.PARENT_IDENTIFIER': 'Parent identifier',
     'PARENT_IDENTIFIER': 'Parent identifier',
     'GENERAL_INFORMATION.PARENT_TYPE': 'Parent type',
     'PARENT_TYPE': 'Parent type',
     'GENERAL_INFORMATION.PREVIOUS_LINKED_IDENTIFIER': 'Object connection',
     'PREVIOUS_LINKED_IDENTIFIER': 'Object connection',
     'GENERAL_INFORMATION.CONTROL_CUPBOARD_TYPE': 'Type of SHUO',
     'CONTROL_CUPBOARD_TYPE': 'Type of SHUO',
     'GENERAL_INFORMATION.CUPBOARD_SERIAL_NUMBER': 'Cabinet serial number',
     'CUPBOARD_SERIAL_NUMBER': 'Cabinet serial number',
     'GENERAL_INFORMATION.CONTROLLER_MODEL': 'Controller model',
     'CONTROLLER_MODEL': 'Controller model',
     'GENERAL_INFORMATION.CONTROLLER_SERIAL_NUMBER': 'Controller serial number',
     'CONTROLLER_SERIAL_NUMBER': 'Controller serial number',
     'GENERAL_INFORMATION.MODEL_CONTROL_CUPBOARD_COUNTER': 'Model of the counter SHUO',
     'MODEL_CONTROL_CUPBOARD_COUNTER': 'Model of the counter SHUO',
     'GENERAL_INFORMATION.SERIAL_NUMBER_CONTROL_CUPBOARD_COUNTER': 'Serial number of the SHUO meter',
     'SERIAL_NUMBER_CONTROL_CUPBOARD_COUNTER': 'Serial number of the SHUO meter',
     'GENERAL_INFORMATION.COUNTER_MODEL_DEK': 'Model of the DEK meter',
     'COUNTER_MODEL_DEK': 'Model of the DEK meter',
     'GENERAL_INFORMATION.SERIAL_NUMBER_DEK_COUNTER': 'Serial number of the DEK meter',
     'SERIAL_NUMBER_DEK_COUNTER': 'Serial number of the DEK meter',
     'GENERAL_INFORMATION.BASE_STATION': 'Base station',
     'BASE_STATION': 'Base station',
     'GENERAL_INFORMATION.CONNECTOR': 'Connector',
     'CONNECTOR': 'Connector',
     'GENERAL_INFORMATION.MODEL': 'Model',
     'MODEL': 'Model',


     'TECHNICAL_CONSTRAINTS.WORKING_TEMPERATURE_RANGE': 'Working temperature range',
     'WORKING_TEMPERATURE_RANGE': 'Working temperature range',
     'TECHNICAL_CONSTRAINTS.POWER_CONSUMPTION': 'Power Consumption, W',
     'POWER_CONSUMPTION': 'Power Consumption, W',
     'TECHNICAL_CONSTRAINTS.WORKING_VOLTAGE_RANGE': 'Operating voltage range',
     'WORKING_VOLTAGE_RANGE': 'Operating voltage range',
     'TECHNICAL_CONSTRAINTS.LIGHT_OUTPUT': 'Light output',
     'LIGHT_OUTPUT': 'Light output',
     'TECHNICAL_CONSTRAINTS.CALIBRATION_INTERVAL': 'Calibration interval',
     'CALIBRATION_INTERVAL': 'Calibration interval',
     'TECHNICAL_CONSTRAINTS.COLOUR_TEMPERATURE': 'Color temperature',
     'COLOUR_TEMPERATURE': 'Color temperature',
     'TECHNICAL_CONSTRAINTS.COLOUR_RENDERING_INDEX': 'Color rendering index',
     'COLOUR_RENDERING_INDEX': 'Color rendering index',
     'TECHNICAL_CONSTRAINTS.LAMP_EFFICIENCY': 'Lamp Efficiency',
     'LAMP_EFFICIENCY': 'Lamp Efficiency',
     'TECHNICAL_CONSTRAINTS.SCATTERING_ANGLE': 'Scattering angle',
     'SCATTERING_ANGLE': 'Scattering angle',
     'TECHNICAL_CONSTRAINTS.LIGHT_INTENSITY_DISTRIBUTION_CURVE_TYPE': 'KCC type',
     'LIGHT_INTENSITY_DISTRIBUTION_CURVE_TYPE': 'KCC type',
     'TECHNICAL_CONSTRAINTS.FLICKER_PERCENT': 'Ripple factor',
     'FLICKER_PERCENT': 'Ripple factor',
     'TECHNICAL_CONSTRAINTS.RATED_POWER': 'Authorized Power',
     'RATED_POWER': 'Authorized Power',
     'TECHNICAL_CONSTRAINTS.ELECTRICITY_SUPPLIER': 'Electrical Power Supplier',
     'ELECTRICITY_SUPPLIER': 'Electrical Power Supplier',
     'TECHNICAL_CONSTRAINTS.ACTUAL_CONSUMPTION_POWER': 'Actually Connected Power',
     'ACTUAL_CONSUMPTION_POWER': 'Actually Connected Power',
     'TECHNICAL_CONSTRAINTS.NUMBER_MEASUREMENT_CHANNELS': 'Number of measurement channels',
     'NUMBER_MEASUREMENT_CHANNELS': 'Number of measurement channels',
     'TECHNICAL_CONSTRAINTS.NUMBER_CONTROLLED_HEATING_SYSTEM ': 'Number of controlled heating systems',
     'NUMBER_CONTROLLED_HEATING_SYSTEM ': 'Number of controlled heating systems',
     'TECHNICAL_CONSTRAINTS.MEASURING_RANGE_AVERAGE_VOLUMETRIC_FLOW': 'Average volume flow measuring range',
     'MEASURING_RANGE_AVERAGE_VOLUMETRIC_FLOW': 'Average volume flow measuring range',
     'TECHNICAL_CONSTRAINTS.TEMPERATURE_MEASUREMENT_RANGE': 'Temperature measurement range',
     'TEMPERATURE_MEASUREMENT_RANGE': 'Temperature measurement range',
     'TECHNICAL_CONSTRAINTS.MEASUREMENT_RANGE_TEMPERATURE_DIFFERENCE_SUPPLY_AND_RETURN_PIPELIN': 'Measurement range of temperature difference in supply and return pipeline',
     'MEASUREMENT_RANGE_TEMPERATURE_DIFFERENCE_SUPPLY_AND_RETURN_PIPELIN': 'Measurement range of temperature difference in supply and return pipeline',
     'TECHNICAL_CONSTRAINTS.PRESSURE_MEASUREMENT_RANGE': 'Pressure measurement range',
     'PRESSURE_MEASUREMENT_RANGE': 'Pressure measurement range',
     'TECHNICAL_CONSTRAINTS.DC_POWER_SUPPLY_VOLTAGE': 'DC Power Supply Voltage',
     'DC_POWER_SUPPLY_VOLTAGE': 'DC Power Supply Voltage',
     'TECHNICAL_CONSTRAINTS.LIMITS_PERMISSIBLE_RELATIVE_ERROR_TEMPERATURE_MEASUREMENT': 'Relative error limits for temperature measurement',
     'LIMITS_PERMISSIBLE_RELATIVE_ERROR_TEMPERATURE_MEASUREMENT': 'Relative error limits for temperature measurement',
     'TECHNICAL_CONSTRAINTS.LAMP_TYPE': 'Lamp type',
     'TECHNICAL_CONSTRAINTS.LIGHT_FIXTURE_STATE': 'Lamp state',
     'TECHNICAL_CONSTRAINTS.CONSOLE': 'Console',
     'TECHNICAL_CONSTRAINTS.UTILITY_POLE_TYPE': 'Utility pole type',
      'TECHNICAL_CONSTRAINTS.COLOR_LAR': 'Color (RAL)',
      'COLOR_LAR': 'Color (RAL)',
      'TECHNICAL_CONSTRAINTS.SIGN_PAINTING': 'Sign painting',
      'SIGN_PAINTING': 'Sign painting',


     'EXPLOITATION.START_DATE': 'Exploitation start date',
     'START_DATE': 'Exploitation start date',
     'EXPLOITATION.EXPLOITATION_END_DATE': 'Exploitation end date',
     'EXPLOITATION_END_DATE': 'Exploitation end date',
     'EXPLOITATION.ORGANIZATION': 'Operating organization',
     'ORGANIZATION': 'Operating organization',
     'EXPLOITATION.OWNER': 'Owner',
     'OWNER': 'Owner',
     'EXPLOITATION.GUARANTEE_PERIOD': 'Guarantee period',
     'GUARANTEE_PERIOD': 'Guarantee period',
     'EXPLOITATION.LIFE_TIME': 'Lifetime',
     'LIFE_TIME': 'Lifetime',
     'EXPLOITATION.LIGHT_FIXTURE_WORK_RESOURCE': 'Lamp operating life',
     'LIGHT_FIXTURE_WORK_RESOURCE': 'Lamp operating life',
     'EXPLOITATION.MODERNIZATION': 'Modernization',
     'MODERNIZATION': 'Modernization',


     'ATTACHMENT.PHOTO_TITLE': 'Photo',
     'PHOTO_TITLE': 'Photo',
     'ATTACHMENT.DOCUMENT_TITLE': 'Product Passport File',
     'DOCUMENT_TITLE': 'Product Passport File',

    'OBJECT_CHARACTERISTIC.TARIFF_ONE': 'Tariff1',
    'TARIFF_ONE': 'Tariff1',
    'OBJECT_CHARACTERISTIC.TARIFF_TWO': 'Tariff2',
    'TARIFF_TWO': 'Tariff2',
    'OBJECT_CHARACTERISTIC.TARIFF_THREE': 'Tariff3',
    'TARIFF_THREE': 'Tariff3',
    'OBJECT_CHARACTERISTIC.TARIFF_FOUR': 'Tariff4',
    'TARIFF_FOUR': 'Tariff4',

  },
  title: 'Passportization',
  changeTitle: 'Save changes?',
  passportSidebar: {
    equipment: 'installation equipment',
    discardAll: 'Mark All',
    passport: 'Passport',
    card: 'Card',
    view: 'View:',
    noImage: 'no image',
    passportExport: 'passport export',
    fieldSettings: 'Field display settings:',
    yes: 'Yes',
    no: 'No',
  },
}
