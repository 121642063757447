import React, { useMemo } from 'react'
import pt from 'prop-types'
import get from 'lodash/get'
import getGraphCurrentValue from '@/helpers/getGraphCurrentValue'
import { FormattedMessage as Lang } from 'react-intl'
import {
  AMARANTH,
  ATLANTIS,
} from '@/constants/styles/defaultTheme'
import {
  HEAT_SUPPLY,
} from '@/constants/names'
import {
  Container,
  Row,
  Title,
} from './styles'

const ChartToolTip = ({
  payload, label, type, unit, objectType, mainAxisType = 'date', title
}) => {
  const difference = useMemo(() => (Math.ceil(get(payload, '[0].payload.difference', 0) * 100) / 100), [payload])

  const isOverpay = useMemo(() => {
    if (objectType === HEAT_SUPPLY) {
      return difference > 0 ? ATLANTIS : AMARANTH
    }
    return difference < 0 ? ATLANTIS : AMARANTH
  }, [objectType, difference])

  const differenceTitle = useMemo(() => {
    if (objectType === HEAT_SUPPLY) {
      return difference > 0
        ? <Lang id="analyticExpenses.form.heat.saving" />
        : <Lang id="analyticExpenses.form.heat.lose" />
    }
    return difference > 0
      ? <Lang id="analyticExpenses.form.light.lose" />
      : <Lang id="analyticExpenses.form.light.saving" />
  }, [difference, objectType])

  return (
    <Container>
      <Row center>
        <Lang id={`consumptionAnalysis.messages.${mainAxisType}`} />
        {' '}
        {label}
      </Row>
      {payload && payload.length > 0 && payload.map((element) => {
        const {
          name, color, value, dataKey, payload: elementPayload,
        } = element
        const newValue = dataKey === 'base'
          ? getGraphCurrentValue(elementPayload)
          : value
        return (
          <Row>
            <Title color={element.color} main>
              {`${title || name} :`}
            </Title>
            <Title color={color}>
              {newValue}
              {' '}
              {unit}
            </Title>
          </Row>
        )
      })}
      {type === 'difference' && (
        <Row>
          <Title main color={isOverpay}>
            {differenceTitle}
          </Title>
          <Title color={isOverpay}>
            {Math.abs(difference)}
            {' '}
            {unit}
          </Title>
        </Row>
      )}
    </Container>
  )
}

ChartToolTip.propTypes = {
  objectType: pt.string,
  payload: pt.arrayOf(pt.object),
  label: pt.string,
  type: pt.string,
  unit: pt.string,
}
ChartToolTip.defaultProps = {
  objectType: null,
  payload: [],
  label: null,
  type: null,
  unit: '',
}

export default ChartToolTip
