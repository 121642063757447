import styled from 'styled-components'
import {
  WHITE,
  GRAY,
  MINE_SHAFT,
} from '@/constants/styles/defaultTheme'
import { ACCEPT, RESET } from '@/constants/forms/search'
import SelectField from '@/components/fields/SelectField'
import Button from '@/components/blocks/Button'
import TextField from '@/components/fields/TextField'

export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.colors.default};
`

export const TitleAndSelectWrapper = styled.div`
  margin-bottom: 15px; 
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 15px;
`

export const StyledButton = styled(Button)`
  height: 38px;
  box-shadow: ${({ theme }) => theme.colors.shadows.field};
  border-radius: 4px;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  text-transform: none;
  display: flex;
  align-items: center;
  ${({ usage }) => usage === ACCEPT && `
    background: linear-gradient(180deg, #FBBA58 0%, #FF9A02 100%);
    color: ${WHITE};
  `}
  ${({ usage }) => usage === RESET && `
    background: ${GRAY};
    color: ${MINE_SHAFT};
  `}
  &:first-child {
    margin-right: 5px;
    width: 100%;
  }
`

export const StyledSelectField = styled(SelectField)`
  width: 100%;
`

export const StyledTextField = styled(TextField)`
  width: 100%;
`

export const FormWrapper = styled.form`
  display: flex;
  flex-direction: column;
`

export const TextControlWrapper = styled.div`
  margin-bottom: 15px;
`