import React, { useState, useMemo, useEffect } from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import pt from 'prop-types'
import noop from 'lodash/noop'
import TextControl from '@/components/controls/TextControl'
import CheckBox from '@/components/controls/CheckBox'
import MapIcons from '@/components/icons/maps'
import viewTreeIcons from '@/constants/viewTree'
import UsersIcons from '@/components/icons/users'
import PortalTooltip from '@/components/blocks/PortalTooltip'

import {
  HeaderRow, HeaderContentWrapper, SelectAllWrapper, IconWrapper,
  Item, List, CheckBoxWrapper, ItemContainer, TextAndIconWrapper,
} from './styles'

const SearchAndFilter = ({
  click, cityProcesses, integrations, intl
}) => {
  const [isAllSelected, setIsAllSelected] = useState(true)
  const [isFilterMode, setIsFilterMode] = useState(true)
  const [excludedTypes, setExcludedTypes] = useState([])
  const [searchQuery, setSearchQuery] = useState('')

  const updatedIntegrations = Object.values(integrations).filter((integration) => integration.id !== 'WITHOUT_ASU').map((item) => {
    return {
      id: item.id,
      name: item.name,
      originalName: item.originalName,
    }
  })

  useEffect(() => {
    if (excludedTypes.length === 0) {
      setIsAllSelected(true)
    } else {
      setIsAllSelected(false)
    }
  }, [excludedTypes, cityProcesses])

  const filteredItemList = useMemo(() => {
    const excludedTypesTags = [...new Set(excludedTypes.map((element) => element.integrationTypes).flat(1))]
    if (!searchQuery && excludedTypes.length === 0) {
      return updatedIntegrations
    }
    if (searchQuery && excludedTypes.length === 0) {
      const filteredArrayByQuery = updatedIntegrations.filter(
        (element) => intl.messages[`globalNames.integrationType.${element.id}`].toLowerCase().indexOf(searchQuery) !== -1,
      )
      return filteredArrayByQuery
    }
    const filteredByTagsAndQuery = updatedIntegrations.filter((element) => {
      const response = excludedTypesTags.indexOf(element.id) === -1
      if (searchQuery && intl.messages[`globalNames.integrationType.${element.id}`].toLowerCase().indexOf(searchQuery) !== -1) {
        if (response) {
          return true
        }
      }
      if (!searchQuery) {
        return response
      }
      return false
    })
    return filteredByTagsAndQuery
  },
  [excludedTypes, updatedIntegrations, searchQuery, intl])

  const updateSearchQuery = (value) => {
    setSearchQuery((value || '').toLowerCase())
  }

  const toggleFilterMode = () => {
    setIsFilterMode(!isFilterMode)
  }

  const toggleIsSelectAll = () => {
    if (excludedTypes.length && updatedIntegrations.length) {
      setIsAllSelected(true)
      setExcludedTypes([])
      return null
    }
    setIsAllSelected(false)
    setExcludedTypes(cityProcesses)
  }

  const toggleExcludedTypes = (item) => () => {
    if ((excludedTypes.filter((element) => item.id === element.id)[0] || {}).id !== item.id) {
      setExcludedTypes([...excludedTypes, { ...item || {} }])
    } else {
      const newExcludedTypes = excludedTypes.filter((element) => element.id !== item.id)
      setExcludedTypes(newExcludedTypes)
    }
  }

  const typeInExcluded = (item) => {
    if ((excludedTypes.filter((element) => item.id === element.id)[0] || {}).id === item.id) {
      return false
    }
    return true
  }

  const renderFilterItem = (item) => {
    return (
      <Item key={item.id}>
        <CheckBoxWrapper>
          <CheckBox
            value={isAllSelected ? true : typeInExcluded(item)}
            onChange={toggleExcludedTypes(item)}
          />
        </CheckBoxWrapper>
        <TextAndIconWrapper>
          {item.name}
        </TextAndIconWrapper>
      </Item>
    )
  }

  const renderItem = (item) => {
    if (item && item.id) {
      const Icon = viewTreeIcons[item.id]
      return (
          <ItemContainer key={item.id} onClick={click(item)}>
            <Icon />
            {item.name}
          </ItemContainer>
      )
    }
  }

  return (
    <>
      <HeaderRow>
        <HeaderContentWrapper>
          {isFilterMode
            ? (
              <Lang id="mapsPage.titles.search">
                {(placeholder) => (
                  <TextControl
                    dark
                    placeholder={placeholder}
                    name="search"
                    icon={UsersIcons.MagnifierIcon}
                    onChange={updateSearchQuery}
                    value={searchQuery}
                  />
                )}
              </Lang>
            )
            : (
              <SelectAllWrapper>
                <CheckBox
                  value={isAllSelected}
                  onChange={toggleIsSelectAll}
                />
                <span>
                  <Lang id={`mapsPage.mapsControl.${isAllSelected ? 'reset' : 'select'}`} />
                </span>
              </SelectAllWrapper>
            )}
        </HeaderContentWrapper>
        <PortalTooltip
          title={isFilterMode
            ? (<Lang id="tooltip.closeList" />)
            : (<Lang id="tooltip.openFilter" />)}
          renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
            <IconWrapper
              ref={wrapperRef}
              onMouseEnter={onMouseEnterHandler}
              onMouseLeave={onMouseLeaveHandler}
              onClick={toggleFilterMode}
            >
              {isFilterMode
                ? (<MapIcons.FilterIcon />)
                : (<MapIcons.ListIcon />)}
            </IconWrapper>
          )}
        />
      </HeaderRow>
      <List>
        {isFilterMode
          ? filteredItemList.map((element) => (
            renderItem(element)
          ))
          : cityProcesses.map((element) => (
            renderFilterItem(element)
          ))}
      </List>
    </>
  )
}

SearchAndFilter.defaultProps = {
  integrationTypes: [],
  integrationOptions: [],
  click: noop,
}
SearchAndFilter.propTypes = {
  integrationTypes: pt.arrayOf(pt.shape({
    id: pt.number,
    tag: pt.string,
    name: pt.string,
    type: pt.string,
  })),
  integrationOptions: pt.arrayOf(pt.shape({
    id: pt.number,
    tags: pt.arrayOf(pt.string),
    name: pt.string,
    type: pt.string,
  })),
  click: pt.func,
}

export default SearchAndFilter
