import {
    put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_OBJECT_TYPES_URL } from '@/constants/apiRoutes'
import {
    GET_ALL_OBJECT_TYPES,
} from '@/store/actions/dictionaries'
import createNotifications from '@/helpers/notification'
import {
    errorGetAllObjectTypes,
    responseGetAllObjectTypes
} from '@/store/actions/dictionaries/objectTypes'

function* getDictionary() {
    try {
        const response = yield request({
            url: GET_OBJECT_TYPES_URL,
            method: 'get',
        })
        if (response && response.data){
            yield put(responseGetAllObjectTypes(response.data))
        }
    } catch (error) {
        yield put(errorGetAllObjectTypes(error))
        const toast = createNotifications()
        toast({
            title: 'Ошибка операции!',
            description: 'Не удалось получить данные всех типов объектов.\nПовторите попытку позже.',
            type: 'error',
        })
    }
}

export default function* () {
    yield takeLatest(GET_ALL_OBJECT_TYPES.REQUEST, getDictionary)
}