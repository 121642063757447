import React, {
  useState, useMemo, useCallback, useEffect
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { FormattedMessage as Lang } from 'react-intl'
import SidebarTree from '@/components/blocks/SidebarTree'
import ArrowIcon from '@/components/icons/maps/ArrowIcon'
import MapIcons from '@/components/icons/maps'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import Loader from '@/components/blocks/Loader'
import Icons from '@/components/icons/users'
import { globalFiltersDefaultValues } from '@/constants/filters'
import isEqual from 'lodash/isEqual'
import Button from '@/components/blocks/Button';
import {
  PageSidebarStyled,
  Title,
  HeaderRow,
  IconWrapper,
  HeaderContentWrapper,
  SidebarWrapper,
  TreeContainer,
  IconCloseWrapper,
  HeaderRowWrapper,
  ButtonScrollToRoot,
  ErrorButtonWraper,
  HeaderIconContainer,
} from './styles'
import { MESH } from '@/constants/instalationPassport/types'
import { ROLE_SUPER_ADMIN } from '@/constants/role'
import { treeHandlers } from 'react-hyper-tree'
import CheckBox from '@/components/controls/CheckBox'
import { MAP_LAYERS } from '@/constants/maps'
import { RUSSIA_BORDERS }  from '@/constants/instalationPassport/types'
import { OptionContainer, OptionTitle } from '@/components/blocks/IconDropdown/styles'
import IconDropdown from '@/components/blocks/IconDropdown'
import REQUEST_STATUSES from '@/constants/requests'
import EmptyDataState from 'components/blocks/EmptyDataState'

const PageSidebar = ({
  treeId,
  onCollapse,
  title,
  headerContent,
  onIconClick,
  icon,
  content,
  treeData,
  searchQuery,
  onSelect,
  headerIcon,
  headerIconDescription,
  onHeaderIconClick,
  rightPadding,
  userTree,
  selectedNode,
  isMonitoring,
  isChildren,
  iconDropdown,
  mapLayer,
  setMapLayer,
  rootStatus,
  searchTree,
  setSearchTree,
  isMashMode,
  isMobile,
  onCloseTree,
  user,
  scheduleContent,
  globalFilters,
  searchLoading,
  withoutTitleRow,
  checkTree,
  onCheckNode,
  toggleRussianBorders,
  isRussianBorders,
  requestGetRootElements,
  onToggleTree,
  objectsGroupsFilters
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false)
  const [isMapLayersSettingsOpen, setIsMapLayersSettingsOpen] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const isActiveGlobalFilter = useMemo(() => !isEqual(globalFiltersDefaultValues, globalFilters), [globalFilters])
  const isSuperAdmin = useMemo(() => user?.role === ROLE_SUPER_ADMIN, [user])
  const mapLayers = useMemo(() => {
    if (isSuperAdmin) {
      return MAP_LAYERS
    }
    return MAP_LAYERS.filter(({id}) => id !== MESH )

  }, [isSuperAdmin])

  const handleScrollToRoot = () => {
    const treeWrapper = document.getElementById("treeWrapper")
    treeWrapper.scroll({
      top: 0,
      behavior: 'smooth'
    });
  }

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target
    const position = Math.ceil((scrollTop / (scrollHeight - clientHeight)) * 100)
    setScrollPosition(position)
  }

  const switchCollapseMode = useCallback(() => {
    const newState = !isCollapsed
    setIsCollapsed(newState)
    onCollapse(newState)
    if (onToggleTree) {
      onToggleTree(newState)
    }
  }, [isCollapsed, setIsCollapsed, onCollapse, onToggleTree])

  useEffect(() => {
    if (onToggleTree) {
      onToggleTree(isCollapsed)
    }
  }, [onToggleTree, isCollapsed])

  useEffect(() => {
    if (treeData.length && selectedNode && selectedNode.id && isChildren && !isCollapsed) {
      const tree = treeHandlers.trees[treeId]
      const node = selectedNode

      setTimeout(() => {
        const selectedNodePath = `${node.path.join('/')}/${node.id}`
        tree.handlers.setSelectedByPath(selectedNodePath)
      })
    }
  }, [selectedNode, treeId, treeData, isChildren, isCollapsed, checkTree])

  const toggleStatusSettings = () => {
    setIsMapLayersSettingsOpen(!isMapLayersSettingsOpen)
  }

  const handleLayerSelect = (id) => {
    setMapLayer(id)
    setIsMapLayersSettingsOpen(false)
  }

  const selectedLayerOptions = mapLayers.filter(layer => layer.id === mapLayer)

  if (!treeData.length && !userTree && rootStatus === REQUEST_STATUSES.PENDING && treeId !== 'objects-groups-tree')
    return (
        <PageSidebarStyled small={isCollapsed}>
          <HeaderRow gradient></HeaderRow>
          <HeaderRow></HeaderRow>
          <SidebarWrapper>
            <Loader center />
          </SidebarWrapper>
        </PageSidebarStyled>
    )

  if (!treeData.length && !userTree && rootStatus !== REQUEST_STATUSES.PENDING && treeId !== 'objects-groups-tree') {
    return (
        <PageSidebarStyled small={isCollapsed}>
          <HeaderRow gradient></HeaderRow>
          <HeaderRow></HeaderRow>
          <SidebarWrapper>
            {rootStatus === REQUEST_STATUSES.ERROR ? 
              <ErrorButtonWraper>
                 <Button styleType='info' onClick={() => requestGetRootElements()}>Повторить запрос</Button> 
              </ErrorButtonWraper>
              :            
              <EmptyDataState title={isActiveGlobalFilter ? <Lang id="viewTree.emptyStateAndFilterActive"/> : <Lang id="viewTree.emptyState"/>}/>
            }
          </SidebarWrapper>
        </PageSidebarStyled>
    )
  }

  return (
    <PageSidebarStyled small={isCollapsed}>
      {!withoutTitleRow &&
        <HeaderRow gradient>
            {!isMobile &&
                <PortalTooltip
                    title={isCollapsed
                        ? (<Lang id="tooltip.deCollapse" />)
                        : (<Lang id="tooltip.collapse" />)}
                    renderChildren={(
                        wrapperRef,
                        onMouseEnterHandler,
                        onMouseLeaveHandler,
                    ) => (
                        <IconWrapper
                            type="arrow"
                            onClick={switchCollapseMode}
                            ref={wrapperRef}
                            onMouseEnter={onMouseEnterHandler}
                            onMouseLeave={onMouseLeaveHandler}
                        >
                          <MapIcons.BackIcon />
                        </IconWrapper>
                    )}
                />
            }
            <HeaderContentWrapper withoutPadding={!isMobile}>
              <Title>{title}</Title>
            </HeaderContentWrapper>
            {iconDropdown && !isMobile &&
                <IconDropdown
                    icon={selectedLayerOptions[0].icon}
                    title={selectedLayerOptions[0].name}
                    content={
                      <>
                        {mapLayers.map(({ name, id }) => {
                          if (id === RUSSIA_BORDERS) {
                            return (
                              <OptionContainer checkBox>
                                <CheckBox value={isRussianBorders} onChange={toggleRussianBorders}/>
                              <OptionTitle >
                                {name}
                              </OptionTitle>
                            </OptionContainer>
                            )
                          }
                          return (
                            <OptionContainer onClick={() => handleLayerSelect(id)} key={id}>
                              <OptionTitle selected={mapLayer === id}>
                                {name}
                              </OptionTitle>
                            </OptionContainer>
                        )})
                        }
                      </>
                    }
                    isOpen={isMapLayersSettingsOpen}
                    onClick={toggleStatusSettings}
                />}
            {isMobile &&
                <IconCloseWrapper>
                  <Icons.CrossIcon onClick={onCloseTree}/>
                </IconCloseWrapper>
            }
            {headerIcon && !isCollapsed && (
                <HeaderIconContainer>
                  <PortalTooltip
                      title={headerIconDescription}
                      handleIconClick={onHeaderIconClick}
                      renderChildren={(
                          wrapperRef,
                          onMouseEnterHandler,
                          onMouseLeaveHandler,
                          onClickHandler
                      ) => (
                          <IconWrapper
                              onClick={onClickHandler}
                              ref={wrapperRef}
                              onMouseEnter={onMouseEnterHandler}
                              onMouseLeave={onMouseLeaveHandler}
                          >
                            {headerIcon}
                          </IconWrapper>
                      )}
                  />
                </HeaderIconContainer>
            )}
            {!isCollapsed && scheduleContent}
          </HeaderRow>
      }
      {!isCollapsed && (
        <HeaderRowWrapper>
          <HeaderContentWrapper>{headerContent}</HeaderContentWrapper>
          {!!icon && <IconWrapper onClick={onIconClick}>{icon}</IconWrapper>}
          {!searchLoading && !!scrollPosition && (
             <PortalTooltip
                title={<Lang id="tooltip.onTop" />}
                renderChildren={(
                  wrapperRef,
                  onMouseEnterHandler,
                  onMouseLeaveHandler,
                ) => (
                  <ButtonScrollToRoot 
                    onClick={handleScrollToRoot} 
                    ref={wrapperRef}
                    onMouseEnter={onMouseEnterHandler}
                    onMouseLeave={onMouseLeaveHandler}
                  >
                    <ArrowIcon color={'#FAFBFB'}/>
                  </ButtonScrollToRoot>
                )}
              />
            )
          }
        </HeaderRowWrapper>
      )}
      {!isCollapsed && (
        <SidebarWrapper id="treeWrapper" onScroll={handleScroll}>
          <TreeContainer rightPadding={rightPadding} userTree={userTree}>
            {searchLoading &&
              <PageSidebarStyled small={isCollapsed}>
                <SidebarWrapper searchLoading>
                  <Title>{<Lang id="viewTree.loading" />}</Title>
                </SidebarWrapper>
              </PageSidebarStyled>
            }
            {!searchLoading && !!treeData && (
              <SidebarTree
                withSort
                isSchedule={!!scheduleContent}
                id={treeId}
                data={treeData}
                searchQuery={searchQuery}
                onSelectNode={onSelect}
                isMonitoring={isMonitoring}
                selectedNode={selectedNode}
                searchTree={searchTree}
                setSearchTree={setSearchTree}
                isMashMode={isMashMode}
                checkTree={checkTree}
                onCheckNode={onCheckNode}
              />
            )}
            {content}
          </TreeContainer>
        </SidebarWrapper>
      )}
    </PageSidebarStyled>
  )
}

PageSidebar.propTypes = {
  onCollapse: pt.func,
  onIconClick: pt.func,
  title: pt.oneOfType([pt.string, pt.number, pt.element]),
  content: pt.oneOfType([pt.string, pt.number, pt.element]),
  headerContent: pt.oneOfType([pt.string, pt.number, pt.element]),
  icon: pt.element,
  isFilterMode: pt.bool,
  typesOfRegionPins: pt.arrayOf(pt.object),
  excludedTypes: pt.arrayOf(pt.string),
  treeData: pt.arrayOf(pt.object),
  searchQuery: pt.string,
  onSelect: pt.func,
  renderFilterItem: pt.func,
  headerIcon: pt.element,
  headerIconDescription: pt.oneOfType([pt.string, pt.number, pt.element]),
  onHeaderIconClick: pt.func,
  withoutRequest: pt.bool,
}
PageSidebar.defaultProps = {
  onCollapse: noop,
  onIconClick: noop,
  title: null,
  content: null,
  headerContent: null,
  icon: null,
  isFilterMode: false,
  typesOfRegionPins: [],
  excludedTypes: [],
  treeData: [],
  searchQuery: '',
  onSelect: noop,
  renderFilterItem: noop,
  headerIcon: null,
  headerIconDescription: null,
  onHeaderIconClick: noop,
  withoutRequest: false,
  isChildren: true,
}

export default React.memo(PageSidebar)
