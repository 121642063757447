import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import WidgetsDashboardIcon from '@/components/icons/widgetsDashboard'

export default {
  preview: [
    {
      name: <Lang id="widgets.alarmsTotal" />,
      selector: 'alarmCount',
    },
    {
      name: <Lang id="widgets.averageTimeTaken" />,
      selector: 'averageDecisionExecutionTime',
    },
  ],
  main: [
    [
      [
        {
          name: <Lang id="widgets.critical" />,
          valueSelector: 'critNotExist',
          totalSelector: 'alarmCount',
          type: 'progressBar',
          message: 'crit',
        },
      ],
      [
        {
          name: <Lang id="widgets.fixed" />,
          valueSelector: 'serviceableCount',
          totalSelector: 'alarmCount',
          type: 'progressBar',
          message: 'fix',
        },
      ],
      [
        {
          name: <Lang id="widgets.averageTimeTaken" />,
          selector: 'averageDecisionExecutionTime',
        },
      ],
    ],
    [
      [
        {
          name: <Lang id="widgets.alarmsTotal" />,
          selector: 'alarmCount',
        },
      ],
      [
        {
          name: <Lang id="widgetsDashboard.outdoorLighting" />,
          selector: 'outdoorLighting',
        },
        {
          name: <Lang id="widgetsDashboard.heatSupply" />,
          selector: 'heatSupply',
          disabled: true,
        },
        {
          name: <Lang id="widgetsDashboard.roadObjects" />,
          selector: 'roadObjects',
          disabled: true,
        },
        {
          name: <Lang id="widgetsDashboard.stopsTotalTransport" />,
          selector: 'publicTransport',
          disabled: true,
        },
      ],
    ],
  ],
  view: {
    title: <Lang id="widgetsDashboard.alarmRegistry" />,
    icon: WidgetsDashboardIcon.AutomaticFaultReportingIcon,
  },
}
