import widgets from '../widgets'

const {
  CONTROLLER_STATUS,
  PHASE_VOLTAGE_UA,
  PHASE_VOLTAGE_UB,
  PHASE_VOLTAGE_UC,
  PHASE_CURRENTS_IA,
  PHASE_CURRENTS_IB,
  PHASE_CURRENTS_IC,
  PHASE_POWER_FACTOR_CA,
  PHASE_POWER_FACTOR_CB,
  PHASE_POWER_FACTOR_CC,
  REACTIVE_POWER_PER_PHASE_QA,
  REACTIVE_POWER_PER_PHASE_QB,
  REACTIVE_POWER_PER_PHASE_QC,
  ACTIVE_POWER_PER_PHASE_PA,
  ACTIVE_POWER_PER_PHASE_PB,
  ACTIVE_POWER_PER_PHASE_PC,
  ACTIVE_ENERGY_ARRAY,
  REACTIVE_ENERGY_ARRAY
} = widgets

export default [
  CONTROLLER_STATUS({}),
  ACTIVE_ENERGY_ARRAY({ count: 4, number: 1 }),
  REACTIVE_ENERGY_ARRAY({ count: 4, number: 1 }),
  PHASE_VOLTAGE_UA({}),
  PHASE_VOLTAGE_UB({}),
  PHASE_VOLTAGE_UC({}),
  PHASE_CURRENTS_IA({}),
  PHASE_CURRENTS_IB({}),
  PHASE_CURRENTS_IC({}),
  PHASE_POWER_FACTOR_CA({}),
  PHASE_POWER_FACTOR_CB({}),
  PHASE_POWER_FACTOR_CC({}),
  REACTIVE_POWER_PER_PHASE_QA({}),
  REACTIVE_POWER_PER_PHASE_QB({}),
  REACTIVE_POWER_PER_PHASE_QC({}),
  ACTIVE_POWER_PER_PHASE_PA({}),
  ACTIVE_POWER_PER_PHASE_PB({}),
  ACTIVE_POWER_PER_PHASE_PC({}),
]
