import React from 'react'
import CollapsedInfoBlock from '@/components/blocks/CollapsedInfoBlock'
import {
    FormWrapper,
} from './styles'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import pt from 'prop-types'
import SearchForm from '@/forms/SearchForm'
import Loader from "@/components/blocks/Loader";

const SearchFormSidebar = ({
  isOpen,
  toggleIsOpen,
  handleGetTableData,
  searchFieldsOptions,
  searchFieldsOptionsLoading,
  searchValues,
}) => {

 const toggleIsOpenHandler = () => {
   toggleIsOpen(!isOpen)
 }

 const setValues = (values) => {
   handleGetTableData(values)
 }

 const renderForm = () => (
   searchFieldsOptionsLoading
     ? <Loader center />
     : (
       <FormWrapper>
         <SearchForm
             searchFieldsOptions={searchFieldsOptions}
             searchValues={searchValues}
             onSetValues={setValues}
         />
       </FormWrapper>)
 )

 return (
   <CollapsedInfoBlock
     name={<Lang id='widgets.search' />}
     isSettingsOpen={isOpen}
     setIsSettingsOpen={toggleIsOpenHandler}
     content={renderForm()}
   />
 )
}

SearchFormSidebar.defaultProps = {
  isOpen: true,
  toggleIsOpen: noop,
  handleGetTableData: noop,
  requestGetSearchFieldsOptions: noop,
  searchFieldsOptions: {},
  searchValues: {},
}

SearchFormSidebar.propTypes = {
  isOpen: pt.bool,
  toggleIsOpen: pt.func,
  handleGetTableData: pt.func,
  requestGetSearchFieldsOptions: pt.func,
  searchFieldsOptions: pt.object,
  searchValues: pt.shape({}),
}

export default SearchFormSidebar