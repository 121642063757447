import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import {
  getTreeDataOld,
} from '@/store/selectors/maps/socketEvents'
import {
  getDataRequestStatus,
} from '@/store/selectors/objectControllStatistic'
import {
  requestAlarmManagerTable,
} from '@/store/actions/objectControllStatistic/getTable'
import data from './config'
import Maps from './component'

const mapStateToProps = (state) => ({
  treeData: getTreeDataOld(state),
  data,
  dataRequestStatus: getDataRequestStatus(state),
})

const mapDispatchToProps = (dispatch) => ({
  requestGetTableData: (data) => dispatch(requestAlarmManagerTable(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Maps))
