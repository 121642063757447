import React from 'react'
import pt from 'prop-types'
import { Field } from 'formik'
import SelectControlMobile from '@/components/controls/SelectControlMobile'

const SelectFieldMobile = ({ label, onAfterChange, ...ownProps }) => (
  <Field {...ownProps}>
    {({ field, form }) => {
      const onChange = (value) => {
        form.setFieldValue(field.name, value)
        if (onAfterChange) {
          onAfterChange(field.name, value)
        }
      }

      return (
        <SelectControlMobile
          {...field}
          {...ownProps}
          onChange={onChange}
          disabled={ownProps.disabled}
        />
      )
    }}
  </Field>
)

SelectFieldMobile.defaultProps = {
  label: null,
  onAfterChange: null,
}

SelectFieldMobile.propTypes = {
  label: pt.oneOfType([pt.string, pt.element]),
  onAfterChange: pt.func,
}

export default React.memo(SelectFieldMobile)
