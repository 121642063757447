import { handleActions } from 'redux-actions'
import {
    GET_MAP_OBJECTS,
    SET_ZONES,
    GET_MAP_STATISTIC,
} from '@/store/actions/maps'
import { regions } from '@/data/mapData'
import {
  USER_LOGIN,
  USER_LOGOUT,
} from '@/store/actions/user'
import { getDefaultTree } from '@/data/mapTree'

const treeData = getDefaultTree()

const initialState = {
  tree: [],
  treeOld: treeData,
  regions,
  pins: [],
  zones: {},
  countOfPins: 0,
  countOfProjects: 0,
  loading: false,
}

export const reducer = handleActions(
  {
    // [UPDATE_MAP_DATA]: (state, { payload }) => ({
    //   ...state,
    //   telemetry: {
    //     ...state.telemetry,
    //     ...payload,
    //   },
    // }),
    [GET_MAP_OBJECTS.BEGIN]: (state) => ({
      ...state,
    }),
    [SET_ZONES]: (state, { payload }) => ({
      ...state,
      zones: payload,
    }),
    [GET_MAP_OBJECTS.REQUEST]: (state) => ({
      ...state,
      loading: true,
    }),
    [GET_MAP_OBJECTS.SUCCESS]: (state, { payload }) => ({
      ...state,
      tree: payload.tree,
      treeOld: payload.treeOld,
      pins: payload.pins,
      countOfPins: payload.countOfPins,
      countOfProjects: payload.countOfProjects,
      zones: payload.zones,
      loading: false,
    }),
    [GET_MAP_OBJECTS.ERROR]: (state) => ({
      ...state,
      loading: false,
    }),
    [GET_MAP_STATISTIC.SUCCESS]: (state, { payload }) => ({
      ...state,
      countOfPins: payload.countOfPins,
      countOfProjects: payload.countOfProjects,
    }),
    [GET_MAP_STATISTIC.ERROR]: (state) => ({
      ...state,
      loading: false,
    }),
    [USER_LOGIN.SUCCESS]: (state) => ({
      ...state,
      tree: [],
      treeOld: treeData,
      pins: [],
      countOfPins: 0,
      countOfProjects: 0,
    }),
    [USER_LOGOUT.SUCCESS]: (state) => ({
      ...state,
      tree: [],
      treeOld: treeData,
      pins: [],
      countOfPins: 0,
      countOfProjects: 0,
    }),
  },
  initialState,
)

export default reducer
