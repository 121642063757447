import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import MenuIcons from '@/components/icons/menu'

export default {
  main: [
    {
      name: <Lang id="widgets.light" />,
      selector: 'outdoorLightningCount',
    },
    {
      name: <Lang id="widgets.roadRepairingCount" />,
      selector: 'roadRepairingCount',
      disabled: true,
    },
    {
      name: <Lang id="widgets.transportStationCount" />,
      selector: 'transportStationCount',
      disabled: true,
    },
    {
      name: <Lang id="widgets.notAvailable1" />,
      selector: 'notAvailable1',
      disabled: true,
    },
    {
      name: <Lang id="widgets.notAvailable2" />,
      selector: 'notAvailable2',
      disabled: true,
    },
  ],
  view: {
    title: <Lang id="widgetsDashboard.serviceDesk" />,
    icon: MenuIcons.SupportIcon,
  },
  total: {
    name: <Lang id="widgets.totalCalls" />,
    selector: 'totalCalls',
  },
  solved: {
    name: <Lang id="widgets.decisionsWasMade" />,
    selector: 'decisionWasMade',
  },
  graph: {
    totalSelector: 'totalCalls',
    solvedSelector: 'decisionWasMade',
  },
}
