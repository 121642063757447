import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import moment from 'moment/moment'
import {
  GET_PPR_TABLE,
  PPR_TABLE,
  SET_STATUS,
  SET_DATES_RANGE,
} from '@/store/actions/Ppr'
import { PPR_TABLE_ENDPOINT } from '@/constants/apiRoutes'
import  { getSortField }  from '@/helpers/getSortField'
import {
  successPprTable
} from '@/store/actions/Ppr/getTable'
import { getSelectedNode } from '@/store/selectors/appSettings'
import {
  getTableParameters,
  getYear,
  getStatus,
  getDatesRange
} from '@/store/selectors/ppr'
import { setGlobalLoading } from '@/store/actions/appSettings'

function* getPPRTableSaga() {
  try {
    const { start, end } = yield select(getDatesRange)
    const node = yield select(getSelectedNode)
    const year = yield select(getYear)
    if (!(start || end) || !node.id ) {
      yield put(successPprTable({data: []}))
      return
    }
    const tableParameters = yield select(getTableParameters)
    const status = yield select(getStatus)
    const sortField = getSortField(tableParameters.sort.field)
    yield put(setGlobalLoading(true))
    const response = yield request({
      url: PPR_TABLE_ENDPOINT({
        params: {
          pageNumber: tableParameters.page - 1,
          pageSize: tableParameters.perPage,
          parentId: node.id,
          sort: [`${sortField}: ${tableParameters.sort.direction}`],
          search: tableParameters.requestQuery || '',
          year,
          status,
          fromDate: start && moment(start).format('DD.MM.YYYY'),
          toDate: end && moment(end).format('DD.MM.YYYY')
        },
      }),
      method: 'get',
    })

    if (response.data) {
      const {
        content,
        pageable,
      } = response.data
      yield put(setGlobalLoading(false))
      yield put(successPprTable({
        data: content,
        totalElements: pageable.totalElements,
        totalPages: pageable.totalPages,
      }))
    }

  } catch (error) {

  }

  return null
}

export default function* root() {
  yield takeLatest(GET_PPR_TABLE.REQUEST, getPPRTableSaga)
  yield takeLatest(PPR_TABLE.SORT, getPPRTableSaga)
  yield takeLatest(PPR_TABLE.PER_PAGE, getPPRTableSaga)
  yield takeLatest(PPR_TABLE.PAGE, getPPRTableSaga)
  yield takeLatest(PPR_TABLE.REQUEST_QUERY, getPPRTableSaga)
  yield takeLatest(SET_STATUS, getPPRTableSaga)
  yield takeLatest(SET_DATES_RANGE, getPPRTableSaga)
}
