import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import LiveTime from '@/components/icons/livetime'
import AlarmsIcons from '@/components/icons/alarms'
import CustomEventCell from '@/components/blocks/DutyTable/customCells/CustomEventCell'
import CustomObjectCell from '@/components/blocks/DutyTable/customCells/CustomObjectCell'
import {
  AlarmEventContainer,
} from '@/components/blocks/DutyTable/customCells/CustomEventCell/styles'
import {
  OK,
  WARNING,
  ERROR,
  UNKNOWN,
} from '@/constants/alarms'

const EventError = () => (
  <AlarmEventContainer type={ERROR} sm>
    <AlarmsIcons.ErrorIcon />
  </AlarmEventContainer>
)
const EventWarning = () => (
  <AlarmEventContainer type={WARNING} sm>
    <AlarmsIcons.WarningIcon />
  </AlarmEventContainer>
)
const EventCorrected = () => (
  <AlarmEventContainer type={OK} sm>
    <AlarmsIcons.OkIcon />
  </AlarmEventContainer>
)

const EventUnknown = () => (
  <AlarmEventContainer type={UNKNOWN} sm>
    <AlarmsIcons.UnknownIcon />
  </AlarmEventContainer>
)

export const eventsOptions = [
  {
    name: <Lang id="alarmManager.options.faulty" />,
    icon: EventError,
    id: ERROR,
  },
  {
    name: <Lang id="alarmManager.options.warning" />,
    icon: EventWarning,
    id: WARNING,
  },
  {
    name: <Lang id="alarmManager.options.correct" />,
    icon: EventCorrected,
    id: OK,
  },
  {
    name: <Lang id="alarmManager.options.undefined" />,
    icon: EventUnknown,
    id: UNKNOWN,
  },
]

export const fields = [
  {
    id: 'objectName',
    name: <Lang id="tableFields.alarmManager.objectName" />,
    value: 'ASC',
    customCell: CustomObjectCell,
    withSelectAll: true,
  },
  {
    id: 'geoZoneName',
    name: <Lang id="tableFields.alarmManager.geoZoneName" />,
    value: 'ASC',
  },
  {
    id: 'address',
    name: <Lang id="tableFields.alarmManager.address" />,
    value: 'ASC',
  },
  {
    id: 'alertState',
    name: <Lang id="tableFields.alarmManager.alertState" />,
    value: 'ASC',
    customCell: CustomEventCell,
  },
  {
    id: 'description',
    name: <Lang id="tableFields.alarmManager.description" />,
    value: 'ASC',
  },
  {
    id: 'occurrenceDate',
    name: <Lang id="tableFields.alarmManager.occurrenceDate" />,
    value: 'ASC',
  },
  {
    id: 'decisionDate',
    name: <Lang id="tableFields.alarmManager.decisionDate" />,
    value: 'ASC',
  },
  {
    id: 'statePeriod',
    name: <Lang id="tableFields.alarmManager.statePeriod" />,
    value: 'ASC',
  },
]

export const exportOptions = [
  {
    name: 'EXCEL',
    value: 'xlsx',
    icon: LiveTime.EXELIcon,
  },
  {
    name: 'PDF',
    value: 'pdf',
    icon: LiveTime.PDFIcon,
  },
]
export const settingsOptions = [
  {
    name: <Lang id="menu.statuses.all" />,
    id: 'all',
  },
  {
    name: <Lang id="menu.statuses.new" />,
    id: 'new',
  },
  {
    name: <Lang id="menu.statuses.old" />,
    id: 'old',
  },
]

export const activeFields = [
  'objectName',
  'geoZoneName',
  'address',
  'alertState',
  'description',
  'occurrenceDate',
  'decisionDate',
  'statePeriod'
]

export default {
  fields,
  exportOptions,
  eventsOptions,
  settingsOptions,
}
