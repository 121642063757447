import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { SET_CONTROL_DATA_URL } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { SET_CONTROL_DATA } from '@/store/actions/objectsGroups'
import {
  errorSetControlData,
  successSetControlData
} from '@/store/actions/objectsGroups/setControlData'

function* setControlData({ payload }) {
  try {
   const { groupId } = payload
   const body = {}
   const { data } = yield request({
     url: SET_CONTROL_DATA_URL(groupId),
     method: 'post',
     body
   })
   if (data) {
     yield put(successSetControlData())
   }
  } catch (error) {
    yield put(errorSetControlData(error))
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось сохранить данные управления. \nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* () {
  yield takeLatest(SET_CONTROL_DATA.REQUEST, setControlData)
}
