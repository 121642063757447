import styled from 'styled-components'

export const TitlePage = styled.h1`
  font-size: 24px;
  color: #1061DF;
  padding-left: 10px;
  @media (max-width: 345px) {
    font-size: 1.8em;
    margin-bottom: 5px;
  }
`
export const Header = styled.div`
  width: 100vw;
  display: flex;
  height: 50px;
  padding: 0 5px 10px 0;
  align-items: flex-end
  justify-content: space-between;
  margin-bottom: 5px;
  padding-bottom: 10px;
  position: absolute;
  left: 0;
  top: 0;
  background-color: #FAFBFB;
  z-index: 100;

  & > svg {
    cursor: pointer;
  }
`
export const ControlsContainer = styled.div`
  display: flex;
  height: 100%;
  padding-bottom: 3px;
`
