import { GET_CREATE_OBJECTS_GROUP_MAP_OBJECTS } from './index'

export const requestGetCreateObjectsGroupMapObjects = (payload) => ({
  type: GET_CREATE_OBJECTS_GROUP_MAP_OBJECTS.REQUEST,
  payload,
})

export const successGetCreateObjectsGroupMapObjects = (payload) => ({
  type: GET_CREATE_OBJECTS_GROUP_MAP_OBJECTS.SUCCESS,
  payload,
})

export const errorGetCreateObjectsGroupMapObjects = (payload) => ({
  type: GET_CREATE_OBJECTS_GROUP_MAP_OBJECTS.ERROR,
  payload,
})