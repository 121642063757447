import { takeLatest, put } from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  GET_APPLICATIONS_VERSION,
  successApplicationsVersion
} from '@/store/actions/appSettings'
import createNotifications from "@/helpers/notification";
import { GET_APPLICATIONS_VERSION_URL } from '@/constants/apiRoutes';

function* getApplicationsVersion() {
  try {
    const { data } = yield request({
      url: GET_APPLICATIONS_VERSION_URL,
      method: 'get',
    })
    yield put(successApplicationsVersion(data))
  } catch (error) {
    console.log('TCL: function*addWidgetToGeneralPage -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* () {
  yield takeLatest(GET_APPLICATIONS_VERSION.REQUEST, getApplicationsVersion)
}
