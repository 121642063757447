import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
  useRef,
} from 'react'
import { Formik } from 'formik'
import pt from 'prop-types'
import noop from 'lodash/noop'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import shortId from 'shortid'
import { FormattedMessage as Lang } from 'react-intl'
import SwitchButton from '@/components/controls/SwitchButton'
import CheckBox from '@/components/controls/CheckBox'
import CoreIcons from '@/components/icons/core'
import TextField from '@/components/fields/TextField'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import FileControllField from '@/components/fields/FileControllField'
import CheckBoxField from '@/components/fields/CheckBoxField'
import Button from '@/components/blocks/Button'
import { validationSchemaManySDSettings } from '@/constants/validationFields'
import {
  SERVICE_DESK,
  APPLICATION_CONFIG,
  ALL,
  TENANT,
} from '@/constants/applications'
import {
  CLIENT_ID,
  SECRET_ID,
  SD_NAME,
  URL,
  FILE,
} from '@/constants/forms/integration'
import {
  Container,
  FormWrapper,
  ApplicationContainer,
  ApplicationModelContainer,
  ApplicationContent,
  ApplicationLabel,
  Text,
  ApplicationMain,
  ApplicationHeader,
  ModelsContainer,
  ModelsCheckBoxContainer,
  ApplicationField,
  CustomButton,
  ServiceDeskButton,
  ServiceDeskContainer,
  CheckBoxContainer,
  IconContainer,
  LabelAndIcon,
  RemoveTitle,
  RemoveElementContainer,
} from './styles'

const UsersApplicationForm = ({
  userRole,
  applications,
  applicationsModels,
  applicationAccess,
  setApplicationAccess,
  applicationModelsAccess,
  setApplicationModelsAccess,
  selectedTenant,
  serviceDeskStatus,
  addSDIntegration,
  requestDeleteSDIntegration,
  editMode,
  tenantServiceDeskStatus,
  setTenantServiceDesk,
  serviceDeskIds,
  setServiceDeskIds,
}) => {
  const formicForm = useRef(null)
  const [selectedModel, setSelectedModel] = useState({})

  const handleSetSelectedModel = useCallback((model) => (event) => {
    event.stopPropagation()
    setSelectedModel(model)
  }, [])

  useEffect(() => () => {
    setApplicationAccess([])
  }, [setApplicationAccess])

  useEffect(() => {
    if (editMode && tenantServiceDeskStatus) {
      const statusIsArray = tenantServiceDeskStatus instanceof Array
      const tenantServiceDeskIds = (statusIsArray ? tenantServiceDeskStatus : [])
        .map((serviceDesk) => ({
          id: serviceDesk.temporaryId,
          remove: false,
        }))
      setServiceDeskIds(tenantServiceDeskIds)
      setTenantServiceDesk(tenantServiceDeskStatus)
    }
  }, [editMode, setServiceDeskIds, setTenantServiceDesk, tenantServiceDeskStatus])

  const toggleServesDeskAsRemove = useCallback((element) => () => {
    const formattedServesDeskIds = serviceDeskIds.map((elementWithId) => {
      if (elementWithId.id === element.id) {
        return {
          ...elementWithId,
          remove: !elementWithId.remove,
        }
      }
      return elementWithId
    })
    setServiceDeskIds([...formattedServesDeskIds])
  }, [serviceDeskIds, setServiceDeskIds])

  useEffect(() => {
    if (selectedTenant.permissions) {
      const initialApplications = selectedTenant.permissions.applications.filter((application) => (
        application.defaultType !== ALL && application.defaultType !== TENANT
      )).map((newApplications) => newApplications.id)
      setApplicationAccess(initialApplications)
      const initialApplicationsModels = selectedTenant.permissions.applicationModules.filter(
        (application) => (
          application.defaultType !== ALL && application.defaultType !== TENANT
        ),
      ).map((newApplications) => newApplications.id)
      setApplicationModelsAccess(initialApplicationsModels)
    }
  }, [
    selectedTenant,
    setApplicationAccess,
    setApplicationModelsAccess,
  ])

  const handleInitialValues = useMemo(() => {
    if (editMode && userRole.isSuperAdmin && tenantServiceDeskStatus) {
      const statusIsArray = tenantServiceDeskStatus instanceof Array
      return (statusIsArray ? tenantServiceDeskStatus : []).reduce((accumulator, currentValue) => ({
        ...accumulator,
        [`${URL}-${currentValue.temporaryId}`]: currentValue[URL],
        [`${CLIENT_ID}-${currentValue.temporaryId}`]: currentValue.clientId,
        [`${SECRET_ID}-${currentValue.temporaryId}`]: currentValue.secretId,
        [`${SD_NAME}-${currentValue.temporaryId}`]: currentValue[SD_NAME],
        [`${FILE}-${currentValue.temporaryId}`]: currentValue.fileName,
      }), {})
    }
    if (!userRole.isSuperAdmin && editMode) {
      const statusIsArray = tenantServiceDeskStatus instanceof Array
      return (statusIsArray ? tenantServiceDeskStatus : []).reduce((accumulator, currentValue) => ({
        ...accumulator,
        [currentValue.id]: true,
      }), {})
    }
  }, [editMode, tenantServiceDeskStatus, userRole.isSuperAdmin])

  const sortedApplications = useMemo(() => {
    if (userRole.isSuperAdmin) {
      return applications.filter((application) => (
        application.defaultType !== ALL && application.defaultType !== TENANT
      )).map((newApplications) => ({
        ...newApplications,
        icon: APPLICATION_CONFIG[newApplications.code]
          ? APPLICATION_CONFIG[newApplications.code].icon
          : null,
      }))
    }
    return applications.filter((application) => (
      application.defaultType !== ALL
    )).map((newApplications) => ({
      ...newApplications,
      icon: (APPLICATION_CONFIG[newApplications.code] || {}).icon,
    }))
  }, [applications, userRole.isSuperAdmin])

  const sortedApplicationModels = useMemo(() => {
    if (userRole.isSuperAdmin) {
      return applicationAccess.map((applicationId) => ({
        id: applicationId,
        models: applicationsModels.filter((applicationModel) => (
          applicationId === applicationModel.applicationId
          && applicationModel.defaultType !== ALL
          && applicationModel.defaultType !== TENANT
        )),
        title: applications.find((application) => application.id === applicationId).name,
      })).filter((fullApplication) => !isEmpty(fullApplication.models))
    }
    return applicationAccess.map((applicationId) => ({
      id: applicationId,
      models: applicationsModels.filter((applicationModel) => (
        applicationId === applicationModel.applicationId
        && applicationModel.defaultType !== ALL
        && applicationModel.availability !== TENANT
      )),
      title: applications.find((application) => application.id === applicationId).name,
    })).filter((fullApplication) => !isEmpty(fullApplication.models))
  }, [applicationAccess, applications, applicationsModels, userRole])

  const sortedModels = useMemo(() => {
    const arrayModels = sortedApplicationModels.map((application) => (
      application.models
    ))
    const newArrayModels = [].concat(...arrayModels).map((element) => element.id)
    if (newArrayModels.sort().length < applicationModelsAccess.sort().length) {
      setApplicationModelsAccess(newArrayModels)
    }
    return [].concat(...arrayModels)
  }, [setApplicationModelsAccess, sortedApplicationModels, applicationModelsAccess])

  const toggleValue = useCallback(({
    elementId, oldElement, setElement, isApplications,
  }) => () => {
    const oldApplications = [...oldElement]
    if (isApplications && elementId === selectedModel.id) {
      setSelectedModel({})
    }
    if (oldApplications.includes(elementId)) {
      setElement(oldApplications.filter((id) => id !== elementId))
      return null
    }
    oldApplications.push(elementId)
    setElement(oldApplications)
  }, [selectedModel.id])

  const toggleAllValues = useCallback(({
    elements, rootElements, setElements, isApplications,
  }) => () => {
    if (elements.length > 0) {
      if (isApplications) {
        setSelectedModel({})
      }
      setElements([])
    } else {
      const allIdIntegrations = rootElements.map((application) => application.id)
      setElements(allIdIntegrations)
    }
  }, [])

  const serviceDeskSubmit = useCallback((values, id) => () => addSDIntegration({
    values: {
      [CLIENT_ID]: values[`${CLIENT_ID}-${id}`],
      [SECRET_ID]: values[`${SECRET_ID}-${id}`],
      [SD_NAME]: values[`${SD_NAME}-${id}`],
      [URL]: values[`${URL}-${id}`],
      [FILE]: values[`${FILE}-${id}`],
      isUserIntegration: true,
    },
    id,
    formicForm,
  }), [addSDIntegration])

  const serviceDeskDeleteIntegration = useCallback((widgetId) => () => {
    if (editMode && formicForm) {
      const newIntegrationStatus = serviceDeskStatus.filter(
        (status) => widgetId !== status.temporaryId,
      )
      setTenantServiceDesk(newIntegrationStatus)
      formicForm.current.setValues({
        ...get(formicForm, 'current.state.values', {}),
        [`${URL}-${widgetId}`]: '',
        [`${CLIENT_ID}-${widgetId}`]: '',
        [`${SECRET_ID}-${widgetId}`]: '',
        [`${SD_NAME}-${widgetId}`]: '',
        [`${FILE}-${widgetId}`]: undefined,
      })
    } else {
      requestDeleteSDIntegration({ widgetId, isUserIntegration: true })
    }
  }, [editMode, requestDeleteSDIntegration, serviceDeskStatus, setTenantServiceDesk])

  const matchingCustomValues = useCallback((errors, id) => (
    Object.keys(errors).some((error) => error.indexOf(`${id}`) > -1)
  ), [])

  const renderButton = useCallback((values, id, serviceDeskConnected, errors) => {
    if (serviceDeskConnected) {
      return (
        <CustomButton type="button" disconnect onClick={serviceDeskDeleteIntegration(id)}>
          <Lang id="widgets.disconnect" />
        </CustomButton>
      )
    }
    if (!matchingCustomValues(errors, id) && matchingCustomValues(values, id)) {
      return (
        <CustomButton type="button" onClick={serviceDeskSubmit(values, id)}>
          <Lang id="widgets.connect" />
        </CustomButton>
      )
    }
    return null
  }, [matchingCustomValues, serviceDeskDeleteIntegration, serviceDeskSubmit])

  const modelsInApplications = useCallback((id) => (
    sortedApplicationModels.find((models) => models.id === id)
  ), [sortedApplicationModels])

  const renderApplications = useCallback(() => (
    <>
      <ApplicationHeader>
        <SwitchButton
          value={applicationAccess.length > 0}
          customLabel={(
            <ApplicationLabel>
              <Text header>
                <Lang id="usersPage.titles.applications" />
              </Text>
            </ApplicationLabel>
            )}
          onChange={toggleAllValues({
            elements: applicationAccess,
            rootElements: sortedApplications,
            setElements: setApplicationAccess,
            isApplications: true,
          })}
        />
      </ApplicationHeader>
      <ApplicationMain>
        {sortedApplications.map((application) => {
          const ApplicationIcon = application.icon || React.Fragment
          const currentValue = (applicationAccess || []).includes(application.id)
          const currentModel = modelsInApplications(application.id)
          return (
            <ApplicationContent
              key={application.code}
            >
              <SwitchButton
                customLabel={(
                  <>
                    <ApplicationLabel>
                      <ApplicationIcon />
                      <Text application>
                        <Lang id={`usersPage.applications.${application.code}`} />
                      </Text>
                    </ApplicationLabel>
                    {((currentValue
                        && currentModel)
                        || (
                          application.code === SERVICE_DESK
                          && currentValue
                        ))
                        && (
                          <IconContainer>
                            <CoreIcons.SettingsIcon
                              onClick={handleSetSelectedModel(
                                currentModel || (application.code === SERVICE_DESK && application),
                              )}
                            />
                          </IconContainer>
                        )}
                  </>
                  )}
                value={currentValue}
                onChange={toggleValue({
                  elementId: application.id,
                  oldElement: applicationAccess,
                  setElement: setApplicationAccess,
                  isApplications: true,
                })}
              />
            </ApplicationContent>
          )
        })}
      </ApplicationMain>
    </>
  ),
  [
    applicationAccess,
    setApplicationAccess,
    sortedApplications,
    toggleAllValues,
    toggleValue,
    modelsInApplications,
    handleSetSelectedModel,
  ])

  const addServiceDesk = useCallback(() => {
    if (serviceDeskIds) {
      const newValue = [
        ...serviceDeskIds,
        {
          id: shortId.generate(),
        },
      ]
      setServiceDeskIds(newValue)
    } else {
      setServiceDeskIds([{
        id: shortId.generate(),
      }])
    }
  }, [serviceDeskIds, setServiceDeskIds])

  const handleServiceDeskCheckBox = useCallback((element) => (name, value) => {
    if (value) {
      setTenantServiceDesk([...serviceDeskStatus, element])
    } else {
      const newTenantServiceDesk = serviceDeskStatus.filter(
        (status) => name !== status.id,
      )
      setTenantServiceDesk(newTenantServiceDesk)
    }
  }, [serviceDeskStatus, setTenantServiceDesk])

  const renderField = useCallback((touched, element, errors, serviceDeskConnected, name) => (
    <TextField
      error={(touched[`${name}-${element.id}`] && errors[`${name}-${element.id}`])}
      name={`${name}-${element.id}`}
      resetButton
      fieldProps={{
        autoComplete: 'off',
        disabled: serviceDeskConnected,
      }}
    />
  ), [])

  const renderApplicationsModels = useCallback((values, touched, errors) => {
    if (selectedModel && selectedModel.code === SERVICE_DESK && !userRole.isSuperAdmin) {
      const statusIsArray = tenantServiceDeskStatus instanceof Array
      return (
        <>
          <ApplicationHeader checkBox>
            <ApplicationLabel header>
              <Text header>
                <Lang id="tooltip.settings" />:
                <Text> Сервис Деск «Мой город»</Text>
              </Text>
            </ApplicationLabel>
          </ApplicationHeader>
          <ApplicationMain userServiceDesk>
            <Text serviceDeskCheckBoxContainer>
              Доступные экземпляры
            </Text>
            {(statusIsArray ? tenantServiceDeskStatus : []).map((element) => (
              <CheckBoxContainer key={element.id}>
                <CheckBoxField
                  name={element.id}
                  onAfterChange={handleServiceDeskCheckBox(element)}
                />
                <Text serviceDeskCheckBox>
                  {element.name}
                </Text>
              </CheckBoxContainer>
            ))}
          </ApplicationMain>
        </>
      )
    }
    if (selectedModel && selectedModel.code === SERVICE_DESK && userRole.isSuperAdmin) {
      return (
        <>
          <ApplicationHeader checkBox>
            <ApplicationLabel header>
              <Text header>
                <Lang id="tooltip.settings" />: Сервис Деск «Мой город»
              </Text>
            </ApplicationLabel>
          </ApplicationHeader>
          <ApplicationMain serviceDesk>
            {serviceDeskIds.map((element) => {
              const serviceDeskConnected = serviceDeskStatus.some((serviceDesk) => (
                serviceDesk.temporaryId === element.id
              ))
              return (
                <ServiceDeskContainer key={element.id} remove={element.remove}>
                  {element.remove
                    ? (
                      <RemoveElementContainer>
                        <RemoveTitle>
                          Экземпляр удален
                        </RemoveTitle>
                        <Button styleType="colorScheme" onClick={toggleServesDeskAsRemove(element)}>
                          Отменить
                        </Button>
                      </RemoveElementContainer>
                    )
                    : (
                      <>
                        <ApplicationField>
                          <LabelAndIcon>
                            <LabelWithIcon
                              isError={(touched[`${SD_NAME}-${element.id}`] && errors[`${SD_NAME}-${element.id}`])}
                              title={<Lang id="widgets.name" />}
                            />
                            {!serviceDeskConnected && (
                              <IconContainer onClick={toggleServesDeskAsRemove(element)} remove>
                                <CoreIcons.TrashIcon />
                              </IconContainer>
                            )}
                          </LabelAndIcon>
                          {renderField(touched, element, errors, serviceDeskConnected, SD_NAME)}
                        </ApplicationField>
                        <ApplicationField>
                          <LabelWithIcon
                            isError={(touched[`${FILE}-${element.id}`] && errors[`${FILE}-${element.id}`])}
                            title={<Lang id="widgets.fileWithSettings" />}
                          />
                          <FileControllField
                            error={(touched[`${FILE}-${element.id}`] && errors[`${FILE}-${element.id}`])}
                            name={`${FILE}-${element.id}`}
                            type="file"
                            accept="application/json"
                            disabled={serviceDeskConnected}
                            styleType="secondary"
                            doNotDisplayTrashIcon={serviceDeskConnected}
                            placeholder="Загрузить"
                            icon={(<CoreIcons.FileUploadIcon />)}
                            textInfo="Тип файла: JSON Макс. размер: 1 мб."
                          />
                        </ApplicationField>
                        <ApplicationField>
                          <LabelWithIcon
                            isError={(touched[`${URL}-${element.id}`] && errors[`${URL}-${element.id}`])}
                            title={<Lang id="widgets.url" />}
                          />
                          {renderField(touched, element, errors, serviceDeskConnected, URL)}
                        </ApplicationField>
                        <ApplicationField>
                          <LabelWithIcon
                            isError={(touched[`${CLIENT_ID}-${element.id}`] && errors[`${CLIENT_ID}-${element.id}`])}
                            title={<Lang id="widgets.clientID" />}
                          />
                          {renderField(touched, element, errors, serviceDeskConnected, CLIENT_ID)}
                        </ApplicationField>
                        <ApplicationField>
                          <LabelWithIcon
                            isError={(touched[`${SECRET_ID}-${element.id}`] && errors[`${SECRET_ID}-${element.id}`])}
                            title={<Lang id="widgets.secretID" />}
                          />
                          {renderField(touched, element, errors, serviceDeskConnected, SECRET_ID)}
                        </ApplicationField>
                        {renderButton(values, element.id, serviceDeskConnected, errors)}
                      </>
                    )}
                </ServiceDeskContainer>
              )
            })}
            <ServiceDeskButton type="button" onClick={addServiceDesk}>
              <IconContainer nc>
                <CoreIcons.PlusIcon />
              </IconContainer>
              Добавить экземпляр
            </ServiceDeskButton>
          </ApplicationMain>
        </>
      )
    }
    if (!isEmpty(selectedModel) && applicationAccess.includes(selectedModel.id)) {
      return (
        <>
          <ApplicationHeader checkBox>
            {selectedModel.models.length > 0 && (
              <CheckBox
                value={applicationModelsAccess.length > 0}
                onChange={toggleAllValues({
                  elements: applicationModelsAccess,
                  rootElements: sortedModels,
                  setElements: setApplicationModelsAccess,
                })}
              />
            )}
            <ApplicationLabel header>
              <Text header>
                Доступная функциональность
              </Text>
            </ApplicationLabel>
          </ApplicationHeader>
          <ApplicationMain>
            <ModelsContainer>
              <Text title>{selectedModel.title}</Text>
              {selectedModel.models.map((model) => (
                <ModelsCheckBoxContainer key={model.id}>
                  <CheckBox
                    value={(applicationModelsAccess || []).includes(model.id)}
                    onChange={toggleValue({
                      elementId: model.id,
                      oldElement: applicationModelsAccess,
                      setElement: setApplicationModelsAccess,
                    })}
                  />
                  <Text checkBox>{model.name}</Text>
                </ModelsCheckBoxContainer>
              ))}
            </ModelsContainer>
          </ApplicationMain>
        </>
      )
    }
    return null
  },
  [
    serviceDeskIds,
    selectedModel,
    userRole.isSuperAdmin,
    applicationAccess,
    tenantServiceDeskStatus,
    handleServiceDeskCheckBox,
    addServiceDesk,
    serviceDeskStatus,
    renderField,
    toggleServesDeskAsRemove,
    renderButton,
    applicationModelsAccess,
    toggleAllValues,
    sortedModels,
    setApplicationModelsAccess,
    toggleValue,
  ])

  return (
    <Container>
      <Formik
        ref={formicForm}
        initialValues={handleInitialValues}
        enableReinitialize
        validateOnChange
        validationSchema={validationSchemaManySDSettings(serviceDeskIds, serviceDeskStatus)}
        onSubmit={noop}
        render={(formProps) => {
          const {
            values, touched, errors, setValues,
          } = formProps
          return (
            <FormWrapper>
              <ApplicationContainer>
                {renderApplications(touched, errors, values, setValues)}
              </ApplicationContainer>
              <ApplicationModelContainer>
                {renderApplicationsModels(values, touched, errors)}
              </ApplicationModelContainer>
            </FormWrapper>
          )
        }}
      />
    </Container>
  )
}

UsersApplicationForm.defaultProps = {
  setServiceDeskIds: noop,
  setApplicationAccess: noop,
  setApplicationModelsAccess: noop,
  requestDeleteSDIntegration: noop,
  setTenantServiceDesk: noop,
}

UsersApplicationForm.propTypes = {
  setServiceDeskIds: pt.func,
  setApplicationAccess: pt.func,
  setApplicationModelsAccess: pt.func,
  requestDeleteSDIntegration: pt.func,
  setTenantServiceDesk: pt.func,
}

export default UsersApplicationForm
