import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const CheckIconHover = ({ onClick }) => {
  return (
    <svg onClick={onClick} enableBackground="new 0 0 32 32" height="32px" id="Слой_1" version="1.1" viewBox="0 0 32 32" width="32px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2861_81398)">
        <rect width="32" height="32" rx="16" fill="#3E3C3E"/>
          <g clipPath="url(#clip1_2861_81398)">
            <path d="M16.0003 26.6664C13.1713 26.6664 10.4582 25.5426 8.45785 23.5422C6.45747 21.5419 5.33366 18.8288 5.33366 15.9998C5.33366 13.1708 6.45747 10.4577 8.45785 8.4573C10.4582 6.45692 13.1713 5.33311 16.0003 5.33311C18.8293 5.33311 21.5424 6.45692 23.5428 8.4573C25.5432 10.4577 26.667 13.1708 26.667 15.9998C26.667 18.8288 25.5432 21.5419 23.5428 23.5422C21.5424 25.5426 18.8293 26.6664 16.0003 26.6664ZM16.0003 2.66644C14.2494 2.66644 12.5156 3.01132 10.8979 3.68138C9.2802 4.35145 7.81035 5.33357 6.57223 6.57169C4.07175 9.07217 2.66699 12.4636 2.66699 15.9998C2.66699 19.536 4.07175 22.9274 6.57223 25.4279C7.81035 26.666 9.2802 27.6481 10.8979 28.3182C12.5156 28.9882 14.2494 29.3331 16.0003 29.3331C19.5365 29.3331 22.9279 27.9284 25.4284 25.4279C27.9289 22.9274 29.3337 19.536 29.3337 15.9998C29.3337 14.2488 28.9888 12.515 28.3187 10.8973C27.6487 9.27965 26.6665 7.8098 25.4284 6.57169C24.1903 5.33357 22.7204 4.35145 21.1028 3.68138C19.4851 3.01132 17.7513 2.66644 16.0003 2.66644Z" fill="#FAFBFB"/>
          </g>
      </g>
      <defs>
        <clipPath id="clip0_2861_81398">
          <rect width="32" height="32" rx="16" fill="white"/>
        </clipPath>
        <clipPath id="clip1_2861_81398">
          <rect width="32" height="32" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )}

CheckIconHover.propTypes = {
 onClick: pt.func,
}

CheckIconHover.defaultProps = {
 onClick: noop,
}

export default memoWithName(CheckIconHover)
