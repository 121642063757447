import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import MenuIcons from '@/components/icons/menu'

export default {
  preview: [
    {
      name: <Lang id="widgets.callsTotal" />,
      selector: 'totalCalls',
    },
    {
      name: <Lang id="widgets.averageDecisionExecutionTime" />,
      selector: 'averageDecisionExecutionTime',
    },
    {
      name: <Lang id="widgets.averageDecisionTime" />,
      selector: 'averageDecisionTime',
    },
  ],
  main: [
    [
      [
        {
          name: <Lang id="widgets.inWork" />,
          valueSelector: 'inWork',
          totalSelector: 'totalCalls',
          type: 'progressBar',
          message: 'warning',
        },
      ],
      [
        {
          name: <Lang id="widgets.decisionWasMade" />,
          valueSelector: 'decisionWasMade',
          totalSelector: 'totalCalls',
          type: 'progressBar',
          message: 'fix',
        },
      ],
      [
        {
          name: <Lang id="widgets.averageDecisionExecutionTime" />,
          selector: 'averageDecisionExecutionTime',
        },
      ],
      [
        {
          name: <Lang id="widgets.averageDecisionTime" />,
          selector: 'averageDecisionTime',
        },
      ],
    ],
    [
      [
        {
          name: <Lang id="widgets.totalCalls" />,
          selector: 'totalCalls',
        },
      ],
      [
        {
          name: <Lang id="widgets.light" />,
          selector: 'outdoorLightningCount',
        },
        {
          name: <Lang id="widgets.roadRepairingCount" />,
          selector: 'roadRepairingCount',
          disabled: true,
        },
        {
          name: <Lang id="widgets.transportStationCount" />,
          selector: 'transportStationCount',
          disabled: true,
        },
        {
          name: <Lang id="widgets.notAvailable1" />,
          selector: 'notAvailable1',
          disabled: true,
        },
        {
          name: <Lang id="widgets.notAvailable2" />,
          selector: 'notAvailable2',
          disabled: true,
        },
      ],
    ],
  ],
  view: {
    title: <Lang id="widgetsDashboard.serviceDesk" />,
    icon: MenuIcons.SupportIcon,
  },
}
