import styled from 'styled-components'
import { Form } from 'formik'
import CheckBoxField from '@/components/fields/CheckBoxField'
import DatePickerField from '@/components/fields/DatePickerField'
import Button from '@/components/blocks/Button'

export const CustomDatePicker = styled(DatePickerField)`
  height: 28px;
`

export const StyledButton = styled(Button)`
  height: 28px;
`

export const FormContainer = styled(Form)`
  position: relative;
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  input {
    width: 100%
  }
`

export const CustomCheckBoxField = styled(CheckBoxField)`
  margin-right: 10px;
`

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
  align-items: center;
  cursor: pointer;
  ${({ datePicker }) => datePicker && `
    width: 135px;
    .react-datepicker-popper {
      width: 300px;
      z-index: 4;
      top: -30px !important;
    }
  `};
  ${({ dropDownObject }) => dropDownObject && `
    min-width: 138px;
    margin-left: 10px;
    margin-right: 10px;
  `};
  ${({ status }) => status && `
    min-width: 97px;
    margin-right: 10px;
  `};
  ${({ checkBox }) => checkBox && `
    margin-right: 16px;
  `};
  ${({ buttonDatePicker }) => buttonDatePicker && `
    margin-left: 10px;
  `};
`

export const Text = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
  ${({ reset }) => reset && `
    cursor: pointer;
  `};
  ${({ datePicker }) => datePicker && `
    margin-right: 16px;
    margin-left: 16px;
  `};
`
