
export default {
  desktop: 'Desktop',
  users: 'Users',
  passportization: 'Passportization',
  alertManager: 'Notifications',
  alarms: 'Fault log',
  individualControl: 'Control',
  groupControl: 'Group control',
  scheduleManager: 'Schedule manager',
  objectsGroups: 'Objects groups',
  installation: 'Installations',
  reportManager: 'Report manager',
  energyConsumption: 'Energy consumption',
  life: 'Lifetime',
  ppr: 'SPM',
  pprMenuSidbar: 'SPM',
  advancedSearch: 'Advanced search',
  objects: 'Monitoring center',
  analyticsAlarms: 'Analytics Alarms',
  searchWidget: 'Advanced search',
  controlCenter: 'Control cnter',
  equipmentReference: 'References',
  operationAndTechnicalSupport: 'Support',
  settings: 'System settings',
  changeLog: 'Change log',
  foldMenu: 'Collapse description',
  menuTitle: 'Navigation menu',
  inventory: 'Inventory',
  lightingObjects: 'Lighting objects',
  analyticExpenses: 'Resource consumption',
  activityLog: 'Activity log',
  carElectricStations: 'Car charging stations',
  waterSupply: 'Water supply',
  heatSupply: 'Heat supply',
  smartRecycleBin: 'Waste containers monitoring',
  controlObject: 'Control Objects',
  mainScreenSettings: 'Main screen settings',
  upperBlock: 'Upper block',
  mainApps: 'Main apps',
  dragMessage: 'Swap elements by dragging them using the icon on the right.\nElements can be added or removed from the screen by clicking on the icon on the left.',
  usedElements: 'Elements used',
  unusedElements: 'Unused elements',
  activateMessage: 'To activate unavailable items, contact your organization administrator',
  pin: 'Pin',
  unpin: 'Unpin',
  pinNode: 'Pin node',
  pinNodeMessage: 'This action will pin this object and unpin the previously pinned one.',
  emptyPinNode: 'No pinned objects',
  unpinNode: 'Unpin node',
  unpinNodeMessage: 'This action will unpin this object.',
  statuses: {
    new: 'New',
    old: 'Viewed',
    all: 'All statuses',
  },
  communicationStatus: 'Communication status',
  on: 'On',
  onLong: 'On',
  off: 'Off',
  offLong: 'Off',
  online: 'Online',
  offline: 'Offline',
  refreshed: 'Refreshed',
  unknown: 'Unknown',
  height: 'Height',
  builtInRelay: 'Built-in relay',
  driver: 'Driver 1-10',
  driverDali: 'Driver via DALI protocol',
  min: 'Min',
  max: 'Max',
  working: 'Works',
  notWorking: 'Doesn`t work',
  onPhase: 'on phase',
  globalPopup: {
    accept: 'Accept',
    save: 'Save',
    cancel: 'Cancel',
    verify: 'Verify',
    yes: 'yes',
    no: 'no',
  },
  selected: 'Selected',
  multipleSelected: 'Multiple selected',
  multipleSelectedMessage: 'You cannot select more than 20 values in the filter',
  onlineUser: 'Online',
  getMore: 'Get more',
}
