import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export const UnitPressure = (
  <>
    <Lang id="widgets.unitPressure" />,
    <sup>2</sup>
  </>
)

export const UnitvolumetricFlow = (
  <>
    <Lang id="widgets.m" />,
    <sup>3</sup>
    <Lang id="widgets.h" />,
  </>
)

export const UnitVolume = (
  <>
    <Lang id="widgets.m" />,
    <sup>3</sup>
  </>
)
