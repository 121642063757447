import {
  put, takeLatest
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { OBJECTS_GROUPS_TREE_ENDPOINT } from '@/constants/apiRoutes'
import get from 'lodash/get'
import { INSTALLATION_SERVER_TO_LOCAL } from '@/constants/passportization/types'
import createNotifications from '@/helpers/notification'
import {
  ROOT,
  GROUP,
} from '@/constants/objectTypes'
import {
  errorGetObjectsGroupsMapObjects,
  successGetObjectsGroupsMapObjects,
} from '@/store/actions/objectsGroups/getMapObjects'
import { GET_OBJECTS_GROUPS_MAP_OBJECTS } from '@/store/actions/objectsGroups'

function* getMapObjectsSaga({ payload }) {
  const { id } = payload
  try {
    const data = yield request( {
      url: OBJECTS_GROUPS_TREE_ENDPOINT({
        params: {
          parentId: id,
          includeAll: true,
          pageSize: 999999,
        }
      }),
      method: 'get',
    })
    const formattedRequestData = data.data.content
    const selectType = (element) => {
      if (!element.parentId) {
        return 'country'
      } else if (element.installationType === GROUP || !element.installationType || !INSTALLATION_SERVER_TO_LOCAL[element.treeNodeType]) {
         return 'UNKNOWN'
      } else {
         return element.installationType || INSTALLATION_SERVER_TO_LOCAL[element.treeNodeType]
      }
    }
    const formattedData = formattedRequestData?.map(element => (
      {
       ...element,
       location: [
         get(element, 'latitude', null),
         get(element, 'longitude', null),
       ],
       type: selectType(element),
       elementType: element.parentId ? element.treeNodeType : ROOT,
       parentGeoZoneId: element.parentId,
       parentTreeId: element.parentId,
       checked: true,
      })
    )
    yield put(successGetObjectsGroupsMapObjects({
      displayElements: formattedData,
    }))
    } catch (error) {
      yield put(errorGetObjectsGroupsMapObjects())
      console.log('function*getMapObjectsSaga -> error', error)
      const toast = createNotifications()
      toast({
       title: 'Ошибка операции!',
       description: 'Не удалось получить объекты для карты.\nПовторите попытку позже.',
       type: 'error',
      })
    }
}

export default function* root() {
  yield takeLatest(GET_OBJECTS_GROUPS_MAP_OBJECTS.REQUEST, getMapObjectsSaga)
}
