import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import Search from '@/containers/pages/Search/component'
import { requestSearchTable } from '@/store/actions/search/getTable'
import {
  getTableData,
  getLoadingTable,
  getSearchValues,
  getFileExportStatus
} from '@/store/selectors/search'
import { requestSearchNode } from '@/store/actions/search/getNode'
import {
  getGlobalFilters,
  getGlobalLoading,
  getSelectedNode
} from '@/store/selectors/appSettings'
import {
  getSearchFieldsOptionsData,
  getSearchFieldsOptionsLoading
} from '@/store/selectors/dictionaries/searchFieldsOptions'
import { resetGlobalFilterValues } from '@/store/actions/appSettings'
import {
  resetSearchTableParameters,
  resetSearchValues,
  setSearchValues
} from '@/store/actions/search'
import { requestSearchReportFile } from '@/store/actions/search/getFile'

const mapStateToProps = (state) => ({
  data: getTableData(state),
  loadingTable: getLoadingTable(state),
  selectedNode: getSelectedNode(state),
  searchFieldsOptions: getSearchFieldsOptionsData(state),
  searchFieldsOptionsLoading: getSearchFieldsOptionsLoading(state),
  globalFilters: getGlobalFilters(state),
  searchValues: getSearchValues(state),
  globalLoading: getGlobalLoading(state),
  fileStatus: getFileExportStatus(state),
})

const mapDispatchToProps = (dispatch) => ({
  requestGetSearchTable: () => dispatch(requestSearchTable()),
  requestGetSearchNode: (payload) => dispatch(requestSearchNode(payload)),
  resetGlobalFilterValues: () => dispatch(resetGlobalFilterValues()),
  setSearchValues: (values) => dispatch(setSearchValues(values)),
  resetSearchValues: () => dispatch(resetSearchValues()),
  resetSearchTableParameters: () => dispatch(resetSearchTableParameters()),
  requestGetSearchReportFile: (payload) => dispatch(requestSearchReportFile(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Search))