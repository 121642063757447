import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const ChevronUp = ({ color, onClick }) => {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.40951 15.4085L11.9995 10.8285L16.5895 15.4085L17.9995 13.9985L11.9995 7.99847L5.99951 13.9985L7.40951 15.4085Z" fill="#30333A"/>
    </svg>
  )
}

ChevronUp.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ChevronUp.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ChevronUp)
