import { handleActions } from 'redux-actions'
import generateDefaultTableReducer, {
  generateTableParameter,
} from '@/helpers/table/generateDefaultTableReducer'
import generateDefaultTableState from '@/helpers/table/generateDefaultTableState'
import {
  PPR_TABLE,
  GET_PPR_TABLE,
  SET_YEAR,
  SET_STATUS,
  SET_DATES_RANGE,
  GET_PPR_INSTALLATION_OBJECT,
  SET_GO_TO_PASSPORTIZATION,
} from '@/store/actions/Ppr'
import { fields } from '@/constants/tablesConfig/Ppr'

const initialState = {
  data: [],
  table: generateDefaultTableState(fields),
  loading: false,
  year: null,
  status: null,
  dates: {
    start: null,
    end: null,
  },
  installationObject: [],
  installationObjectLoading: false,
  goToPassportization: false,
}

export const reducer = handleActions(
  {
    [GET_PPR_TABLE.SUCCESS]: (state, { payload: { data, totalElements } }) => ({
      ...state,
      data,
      loading: false,
      ...generateTableParameter(state, 'resultCount', totalElements),
    }),
    [GET_PPR_TABLE.REQUEST]: (state) => ({
      ...state,
      loading: true,
    }),
    [GET_PPR_TABLE.ERROR]: (state, { payload }) => ({
      ...state,
      ...payload,
      loading: false,
    }),
    [GET_PPR_INSTALLATION_OBJECT.REQUEST]: (state) => ({
      ...state,
      installationObjectLoading: true,
    }),
    [GET_PPR_INSTALLATION_OBJECT.SUCCESS]: (state, { payload }) => ({
      ...state,
      installationObject: payload,
      installationObjectLoading: false,
    }),
    [GET_PPR_INSTALLATION_OBJECT.ERROR]: (state) => ({
      ...state,
      installationObjectLoading: false,
    }),
    [SET_YEAR]: (state, { payload }) => ({
      ...state,
      year: payload,
      ...generateTableParameter(state, 'page', 1),
    }),
    [SET_STATUS]: (state, { payload }) => ({
      ...state,
      status: payload,
      ...generateTableParameter(state, 'page', 1),
    }),
    [SET_GO_TO_PASSPORTIZATION]: (state, { payload }) => ({
      ...state,
      goToPassportization: payload,
    }),
    [SET_DATES_RANGE]: (state, { payload }) => ({
      ...state,
      dates: payload,
      ...generateTableParameter(state, 'page', 1),
    }),
    ...generateDefaultTableReducer(PPR_TABLE),
  },
  initialState,
)

export default reducer
