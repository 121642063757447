import { GET_ALL_OWNERS } from '@/store/actions/dictionaries/index'

export const requestGetAllOwners = () => ({
    type: GET_ALL_OWNERS.REQUEST,
})

export const responseGetAllOwners = (data) => ({
    type: GET_ALL_OWNERS.SUCCESS,
    payload: data,
})

export const errorGetAllOwners = (error) => ({
    type: GET_ALL_OWNERS.ERROR,
    payload: error,
})