import React, { useEffect, useState } from 'react';
import {
  getCount,
  getSettings,
  getTaskSettingsById,
  getTaskSettingsByName,
  getTaskTimes
} from '@/containers/pages/Scheduler/requests';
import Pagination from '@/containers/pages/Scheduler/conponents/Pagination';
import {
  ActiveStatus,
  LoaderContainer,
  SettingsContainer,
  Table,
  TableCell,
  TableHeader,
} from '@/containers/pages/Scheduler/styles';
import TaskTime from '@/containers/pages/Scheduler/conponents/TaskTime';
import SchedulerFilter from '@/containers/pages/Scheduler/conponents/SchedulerFilter';
import Loader from '@/components/blocks/Loader';

const Scheduler = () => {
  const [settings, setSettings] = useState([])
  const [taskTimes, setTaskTimes] = useState([])
  const [taskSettingId, setTaskSettingId] = useState(0)
  const [pageCount, setPageCount] = useState(0)
  const [currentPage, setCurrentPage] = useState(0)
  const [currentTaskTimePage, setCurrentTaskTimePage] = useState(0)
  const [expandedRowId, setExpandedRowId] = useState(null)
  const [filter, setFilter] = useState('default')
  const [filterValue, setFilterValue] = useState('')
  const [loading, setLoading] = useState(false)
  const defaultItemsSize = 14
  const defaultColumnSpan = 7

  useEffect(() => {
    if (filter === 'default') {
      handleGetSettings()
    } else if (filter === "name") {
      handleGetSettingsByName()
    } else {
      handleGetSettingsById()
    }
  }, [currentPage])

  useEffect(() => {
    handleGetTaskTimes(taskSettingId, currentTaskTimePage, defaultItemsSize)
  }, [currentTaskTimePage, taskSettingId])

  useEffect(() => {
    handlePageCount()
  }, [])

  const handleGetSettings = async () => {
    const data = await getSettings(currentPage, defaultItemsSize, setLoading)
    setSettings(data)
  }

  const handleGetSettingsById = async () => {
    const data = await getTaskSettingsById(filter, currentPage, defaultItemsSize, setLoading)
    setSettings(data)
  }

  const handleGetSettingsByName = async () => {
    const settings = await getTaskSettingsByName(filterValue, currentPage, defaultItemsSize, setLoading)
    setSettings(settings)
  }

  const clearFilter = () => {
    setCurrentPage(0)
    setFilter('default')
    setSettings([])
    handleGetSettings()
    handlePageCount()
  }

  const handleGetTaskTimes = async (id, page, size) => {
    const data = await getTaskTimes(id, page, size)
    setTaskTimes(data)
  }

  const handlePageCount = async () => {
    const data = await getCount(setLoading)
    setPageCount(Math.ceil(data / defaultItemsSize))
  }

  const handlePageChange = (page) => {
    setCurrentPage(page)
  }

  const handleTaskTimePageChange = (page) => {
    setCurrentTaskTimePage(page)
  }

  const handleRowClick = (id, integrationId) => {
    setExpandedRowId(expandedRowId === id ? null : id)
    setTaskSettingId(integrationId)
    setCurrentTaskTimePage(0)
  }

  return (
    <SettingsContainer>
      <div>
        <SchedulerFilter
          onSettingsChange={setSettings}
          onPageCountChange={setPageCount}
          defaultSize={defaultItemsSize}
          filter={filter}
          setFilter={setFilter}
          setFilterValue={setFilterValue}
          onClear={clearFilter}
          onPageChange={handlePageChange}
          setLoading={setLoading}
        />
        <Table>
          <thead>
          <tr>
            <TableHeader width="100px">Integration ID</TableHeader>
            <TableHeader>Integration Alias</TableHeader>
            <TableHeader>Задача</TableHeader>
            <TableHeader>Последнее обновление</TableHeader>
            <TableHeader>Интервал обновления</TableHeader>
            <TableHeader>Задача активна</TableHeader>
            <TableHeader>Количество запусков</TableHeader>
          </tr>
          </thead>
          {loading ?
            <tr>
              <td colSpan={defaultColumnSpan}>
                <LoaderContainer>
                  <Loader center/>
                </LoaderContainer>
              </td>
            </tr>
            : <tbody>
          {settings.map((setting) => (
            <React.Fragment key={setting.id}>
              <tr onClick={() => handleRowClick(setting.id, setting.integrationId)}>
                <TableCell isBold={true}>{setting.integrationId}</TableCell>
                <TableCell>{setting.integrationAlias}</TableCell>
                <TableCell>{setting.taskName}</TableCell>
                <TableCell>{new Date(setting.lastUpdated).toLocaleString()}</TableCell>
                <TableCell>{setting.refreshDelay} cекунд</TableCell>
                <TableCell>
                  <ActiveStatus isActive={setting.isActive}>
                    {setting.isActive ? 'Да' : 'Нет'}
                  </ActiveStatus>
                </TableCell>
                <TableCell>{setting.taskTimes}</TableCell>
              </tr>
              {expandedRowId === setting.id && setting.taskTimes > 0 && (
                <tr>
                  <td colSpan={defaultColumnSpan}>
                    <TaskTime
                      taskTimes={taskTimes}
                      currentPage={currentTaskTimePage}
                      pageCount={Math.ceil(setting.taskTimes / defaultItemsSize)}
                      onPageChange={handleTaskTimePageChange}
                    />
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
          </tbody>}
        </Table>
      </div>
      <Pagination
        currentPage={currentPage}
        pageCount={pageCount}
        onPageChange={handlePageChange}
      />
    </SettingsContainer>
  )
}

export default Scheduler