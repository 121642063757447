export default {
  required: 'Обязательное поле',
  number: 'Число',
  latitudeMax: 'Не более 90°',
  latitudeMin: 'Не менее -90°',
  float6Digits: 'Дробная часть - 6 символов',
  longitudeMax: 'Не более 180°',
  longitudeMin: 'Не менее -180°',
  radiusMin: 'Не менее 3 км',
  integer: 'Целое число',
  integerMoreThan0: 'Целое число больше 0',
  integerLessThan60: 'Целое число до 60',
  integerLessThan24: 'Целое число до 24',
  minLength6: 'Минимальная длина - 6 символов',
  maxLength15: 'Максимальная длина - 15 символов',
  maxLength25: 'Максимальная длина - 25 символов',
  maxLength30: 'Максимальная длина - 30 символов',
  maxLength35: 'Максимальная длина - 35 символов',
  maxLength50: 'Максимальная длина - 50 символов',
  maxLength64: 'Максимальная длина - 64 символа',
  maxLength128: 'Максимальная длина - 128 символов',
  maxLength150: 'Максимальная длина - 150 символов',
  maxLength300: 'Максимальная длина - 300 символов',
  valueFrom1To65535: 'Значение от 1 до 65535',
  urlFormat: 'Не соответсвует формату. Пример: http://example.com/api',
  clientIdExist: 'Такой ClientID уже есть в системе',
  secretIdExist: 'Такой SecretID уже есть в системе',
  emailIncorrect: 'Введите коректный email',
  noLimitSet: 'Не установлено предельное значение!',
  numbersOnly: 'В поле должны быть числовые значения',
  minLevelMoreThanMax: 'Минимальный порог больше максимального',
  phoneNumberFormat: 'Формат телефона 7-111-111-11-11',
  fileLessThan1Mb: 'Файл должен быть до 1 Mб',
  fileFormat: 'Формат файла должен быть .png, .jpg, .jpeg',
  fileProportion: 'Пропорция 1:1 (квадрат), минимальное разрешение 100х100',
  dateFormat: 'Дата не соответствует требованиям',
  dateStartMoreThanEnd: 'Дата начала больше даты окончания',
  enterPassword: 'Введите пароль',
  passwordMinLength: 'Длина пароля не менее 8 символов',
  passwordNotValid: 'Пароль не соответствует требованиям',
  passwordsNotMatch: 'Пароли не совпадают',
  errorTryLater: 'Ошибка. Повторите попытку позже',
  passwordTimesLimit: 'Превышен лимит ввода пароля. Повторите попытку позже',
  passwordExpired: 'Пароль устарел. Проверьте почту для установки нового пароля',
  incorrectCredentials: 'Неверный логин или пароль',
  tenantBlocked: 'Организация пользователя заблокирована',
  usersGroupBlocked: 'Группа пользователя заблокирована',
  accessDenied: 'Отказано в доступе! Обратитесь к Администратору Вашей рабочей группы или повторите попытку позже.',
  objectsMin: 'Необходимо выбрать хотя бы один объект',
}