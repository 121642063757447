import MULTIRATE_METER from './MULTIRATE_METER'
import LIGHT_FIXTURE from './LIGHT_FIXTURE'
import CONTROLLER from './CONTROLLER'
import SENSOR from './SENSOR'
import CONTROL_CUPBOARD from './CONTROL_CUPBOARD'
import UTILITY_POLE from './UTILITY_POLE'
import LINE from './LINE'
import LAMP_MODULE from './LAMP_MODULE'

export default {
  MULTIRATE_METER,
  LIGHT_FIXTURE,
  CONTROLLER,
  SENSOR,
  CONTROL_CUPBOARD,
  LINE,
  UTILITY_POLE,
  LAMP_MODULE,
}
