import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const Draggable = ({ color, onClick }) => {

  return (
    <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M3.5 6H21.5V8H3.5V6Z" fill="#838D99"/>
      <path d="M3.5 11H21.5V13H3.5V11Z" fill="#838D99"/>
      <path d="M21.5 16H3.5V18H21.5V16Z" fill="#838D99"/>
    </svg>
  )
}

Draggable.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

Draggable.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(Draggable)