import {
  takeEvery, put
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { INSTALLATION_FILE_SCHEME_DOWNLOAD } from '@/constants/apiRoutes'
import {
  DOWNLOAD_MULTIPLE_OBJECT_SCHEME,
} from '@/store/actions/installation'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'
import createNotifications from '@/helpers/notification'
import { setGlobalLoading } from '@/store/actions/appSettings'

function* uploadSchemeSaga({ payload }) {
  try {
    yield put(setGlobalLoading(true))
    const response = yield request({
      url: INSTALLATION_FILE_SCHEME_DOWNLOAD,
      method: 'get',
      options: {
        responseType: 'blob',
      },
    })
    downloadFileFromBlob({
      blob: response.data,
      fileName: 'SLS24_OBJECT_SCHEME.xlsx',
    })
    yield put(setGlobalLoading(false))
  } catch (error) {
    yield put(setGlobalLoading(false))
    console.log('function*uploadSchemeSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeEvery(DOWNLOAD_MULTIPLE_OBJECT_SCHEME.REQUEST, uploadSchemeSaga)
}
