import { GET_OBJECTS_GROUP } from './index'

export const requestGetObjectsGroup = (payload) => ({
  type: GET_OBJECTS_GROUP.REQUEST,
  payload,
})

export const successGetObjectsGroup = (payload) => ({
  type: GET_OBJECTS_GROUP.SUCCESS,
  payload,
})

export const errorGetObjectsGroup = (payload) => ({
  type: GET_OBJECTS_GROUP.ERROR,
  payload,
})