import styled from 'styled-components'
import RadioButton from '@/components/controls/RadioButton'


export const Title = styled.div`
  display: flex;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.colors.charts};
  text-align: start;
  width: 300px;
  ${({ main, theme }) => main && `
    text-transform: uppercase;
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.colors.colors.title};
    margin-left: 15px;
  `};
  ${({ graphTitle, theme }) => graphTitle && `
    position: absolute;
    color: ${theme.colors.white};
    width: auto;
    left: 38px;
    z-index: 1;
  `};
`

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 15px;
  background-color: #FAFBFB;
  border-radius: 10px;
`

export const RadioButtonStyled = styled(RadioButton)`
  margin-right: 10px;
  padding: 14px 0 14px 20px;
`
