export default {
    errors: {
        requiredField: 'Обязательное поле'
    },
    graph: {
        receivedAppealsAmount: 'Поступившие',
        solvedAppealsAmount: 'Решенные',
        actualConsumption: 'фактическое потребление',
        plannedConsumption: 'плановое потребление',
        avgDays: 'Среднее время принятия и рассмотрения решений',
        previousExpense: 'Предыдущий',
        currentExpense: 'Текущий',
        planExpense: 'Плановое потребление',
        actualExpense: 'Фактическое потребление',
        base: 'Текущий период',
        plus: 'Перерасход',
        minus: 'Экономия',
        beforePeriod: 'Предыдущий период',
        electricity: 'кВт*ч',
        energy: 'ГКал',
        inLimit: 'В пределах нормы',
        lower: 'Ниже нормы',
    },
    installationPassport: {
        outDoorLightning: 'Наружное освещение',
        water: 'Водоснабжение',
        heat: 'Теплоснабжение',
        garbage: 'Раздельный сбор мусора',
        charging: 'Зарядные автостанции',
        heatAndWater: 'Теплоснабжение и Водоснабжение',
    },
    changeLog: 'Список изменений',
    versionControl: 'Контроль Версий',
    version: 'Версия',
}