import React, { useCallback, useEffect, useState } from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang, injectIntl } from 'react-intl'
import noop from 'lodash/noop'
import GlobalPopup from '@/components/blocks/GlobalPopup'
import GlobalPopupContent from '@/components/blocks/GlobalPopupContent'
import MenuIcon from '@/components/icons/menu'
import { ACTION_TYPE, PUT } from '@/constants/semanticNames'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import {
  Container,
  ContentContainer,
  Title,
  Header,
  IconContainer,
  IconWrapper,
  TabHeader,
  TabButton,
  Text
} from './styles'
import CoreIcons from '@/components/icons/core'
import SyncIcon from '@/components/icons/sync'
import {
  TABS_CONFIG,
  CONTROL,
  PASSPORTIZATION,
  SCHEDULE,
  VERIFICATION,
} from './config'
import { GROUP } from '@/constants/instalationPassport/types'
import ScheduleTab from './components/ScheduleTab'
import VerificationTab from './components/VerificationTab'
import ControlTab from './components/ControlTab'
import PassportizationTab from './components/PassportizationTab'
import { ID_BY_APPLICATIONS } from '@/constants/widgets'

const ObjectsGroupDetails = ({
  userData,
  selectedObjectsGroup,
  formValues,
  card,

  requestDeleteObjectsGroup,
  requestGetObjectsGroup,
  setFormValues,
  setNode,
  setCheckedNodes,
  setSelectedColor,
  setPassportTabOpen
}) => {
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false)
  const [activeTab, setActiveTab] = useState(SCHEDULE)

  const handleSetActiveTab = useCallback((tab) => () => {
    setActiveTab(tab)
    setPassportTabOpen(tab === PASSPORTIZATION)
  }, [setPassportTabOpen])

  const handleSetDeletePopup = useCallback((value) => () => {
    setIsDeletePopupOpen(value)
  }, [])

  useEffect(() => {
    requestGetObjectsGroup(selectedObjectsGroup)
  }, [requestGetObjectsGroup, selectedObjectsGroup])

  const onEdit = useCallback(() => {
    setFormValues({
      ...formValues,
      [ACTION_TYPE]: PUT
    })
    setCheckedNodes(card.objects)
  }, [formValues, setFormValues, card, setCheckedNodes])

  const onDelete = useCallback(() => {
    setIsDeletePopupOpen(false)
    setNode({})
    setFormValues({})
    setCheckedNodes([])
    setSelectedColor('')
    requestDeleteObjectsGroup(selectedObjectsGroup)
  }, [requestDeleteObjectsGroup, selectedObjectsGroup, setFormValues, setNode, setCheckedNodes, setSelectedColor])

  const returnName = (name) => {
    const limit = 22

    return (
      <>
        {((name || '').length > limit)
          ? (
             <PortalTooltip
               title={name}
               renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                 <Title
                   ref={wrapperRef}
                   onMouseEnter={onMouseEnterHandler}
                   onMouseLeave={onMouseLeaveHandler}
                 >
                  {(name || '').substr(0, limit)}
                  ...
                 </Title>
               )}
             />
            )
          : (
             <Title>
               {name}
             </Title>
            )
        }
      </>
    )
  }

  const getIsTabDisabled = (tab) => {
    if (tab.id === PASSPORTIZATION) {
      return false
    }

    return !userData.permissions.applicationModules
      .filter((module) => module.applicationId === ID_BY_APPLICATIONS.OBJECTS_GROUPS)
      .some((section) => section.code === tab.code)
  }

  return (
    <>
      {selectedObjectsGroup.treeNodeType === GROUP &&
        <Container>
          <Header>
            <PortalTooltip
              title={<Lang id="scheduleManager.tooltip.edit" />}
              handleIconClick={() => {}}
              renderChildren={(
                wrapperRef,
                onMouseEnterHandler,
                onMouseLeaveHandler,
                onClickHandler
              ) => (
                <IconWrapper
                  onClick={onClickHandler}
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                >
                  <IconContainer pr={10} onClick={onEdit}>
                    <CoreIcons.EditIcon />
                  </IconContainer>
                </IconWrapper>
              )}
            />
            {returnName(selectedObjectsGroup.name)}
            <PortalTooltip
              title={<Lang id="scheduleManager.tooltip.delete" />}
              handleIconClick={() => {}}
              renderChildren={(
                wrapperRef,
                onMouseEnterHandler,
                onMouseLeaveHandler,
                onClickHandler
              ) => (
                <IconWrapper
                  onClick={onClickHandler}
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                >
                  <IconContainer onClick={handleSetDeletePopup(true)}>
                    <SyncIcon.TrashCanIcon />
                  </IconContainer>
                </IconWrapper>
              )}
            />
          </Header>
          <ContentContainer>
            <TabHeader>
              {TABS_CONFIG.map((element) => {
                return (
                  <TabButton
                    key={element.id}
                    onClick={handleSetActiveTab(element.id)}
                    active={activeTab === element.id}
                    disabled={getIsTabDisabled(element)}
                  >
                    <Text active={activeTab === element.id}>{element.name}</Text>
                  </TabButton>
                )
              })}
            </TabHeader>
            {activeTab === SCHEDULE &&
              <ContentContainer>
                <ScheduleTab
                  group={card}
                  userData={userData}
                  selectedObjectsGroup={selectedObjectsGroup}
                />
              </ContentContainer>
            }
            {activeTab === PASSPORTIZATION &&
              <ContentContainer>
                <PassportizationTab
                  groupId={card.id}
                  selectedObjectsGroup={selectedObjectsGroup}
                  userData={userData}
                />
              </ContentContainer>
            }
            {activeTab === CONTROL &&
              <ContentContainer>
                <ControlTab
                  groupId={card.id}
                />
              </ContentContainer>
            }
            {activeTab === VERIFICATION &&
              <ContentContainer>
                <VerificationTab
                  groupId={card.id}
                />
              </ContentContainer>
            }
          </ContentContainer>
        </Container>
      }
      {isDeletePopupOpen && (
        <GlobalPopup
          content={(
            <GlobalPopupContent
              type={'error'}
              onClose={handleSetDeletePopup(false)}
              title={<Lang id={'scheduleManager.popup.attention'}/>}
              message={`Вы действительно хотите удалить группу объектов из системы?`}
              config={{
                error: {
                  icon: MenuIcon.TreshIcon,
                buttons: [
                  {
                   statusType: 'flat',
                   onClickSelector: handleSetDeletePopup(false),
                   title: <Lang id="scheduleManager.popup.no" />,
                  },
                  {
                   statusType: 'error',
                   onClickSelector: onDelete,
                   title: <Lang id="scheduleManager.popup.yes" />,
                  },
                ],
                },
              }}
            />
          )}
        />
      )}
    </>
  )
}

ObjectsGroupDetails.propTypes = {
  selectedObjectsGroup: pt.objectOf(pt.any),
  formValues: pt.objectOf(pt.any),

  setFormValues: pt.func,
  requestDeleteObjectsGroup: pt.func,
}
ObjectsGroupDetails.defaultProps = {
  selectedObjectsGroup: {},
  formValues: {},

  setFormValues: noop,
  requestDeleteObjectsGroup: noop,
}

export default injectIntl(ObjectsGroupDetails)