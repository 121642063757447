import { GET_OBJECTS_GROUPS_MAP_OBJECTS } from './index'

export const requestGetObjectsGroupsMapObjects = (payload) => ({
  type: GET_OBJECTS_GROUPS_MAP_OBJECTS.REQUEST,
  payload,
})

export const successGetObjectsGroupsMapObjects = (payload) => ({
  type: GET_OBJECTS_GROUPS_MAP_OBJECTS.SUCCESS,
  payload,
})

export const errorGetObjectsGroupsMapObjects = (payload) => ({
  type: GET_OBJECTS_GROUPS_MAP_OBJECTS.ERROR,
  payload,
})