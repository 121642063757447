import React from 'react'
import LiveTime from '@/components/icons/livetime'
import ReportManagerCell from '@/components/blocks/DutyTable/customCells/ReportManagerCell'
import { FormattedMessage as Lang } from 'react-intl'

export const fields = [
  {
    id: 'title',
    name: <Lang id="tableFields.reportManager.title" />,
    value: 'ASC',
    customCell: (props) => <ReportManagerCell text {...props} />,
  },
  {
    id: 'geoZoneName',
    name: <Lang id="tableFields.reportManager.geoZoneName" />,
    value: 'ASC',
    customCell: (props) => <ReportManagerCell {...props} />,
  },
  {
    id: 'type',
    name: <Lang id="tableFields.reportManager.type" />,
    value: 'ASC',
    customCell: (props) => <ReportManagerCell {...props} />,
  },
  {
    id: 'objectTypes',
    name: <Lang id="tableFields.reportManager.objectTypes" />,
    value: 'ASC',
    customCell: (props) => <ReportManagerCell manyValues {...props} />,
  },
  {
    id: 'schedule',
    name: <Lang id="tableFields.reportManager.schedule" />,
    value: 'ASC',
    customCell: (props) => <ReportManagerCell {...props} />,
  },
  {
    id: 'externalRecipients',
    name: <Lang id="tableFields.reportManager.externalRecipients" />,
    value: 'ASC',
    customCell: (props) => <ReportManagerCell manyValues {...props} />,
  },
  {
    id: 'lastSendDate',
    name: <Lang id="tableFields.reportManager.lastSendDate" />,
    value: 'ASC',
    customCell: (props) => <ReportManagerCell date {...props} />,
  },
  {
    id: 'nextSendDate',
    name: <Lang id="tableFields.reportManager.nextSendDate" />,
    value: 'ASC',
    customCell: (props) => <ReportManagerCell date {...props} />,
  },
]

export const exportOptions = [
  {
    name: 'EXCEL',
    icon: LiveTime.EXELIcon,
  },
]

export const activeFields = [
  'title',
  'geoZoneId',
  'type',
  'objectTypes',
  'schedule',
  'externalRecipients',
  'lastSendDate',
  'nextSendDate',
]

export default {
  fields,
  exportOptions,
  activeFields,
}
