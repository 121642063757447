import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`

export const OptionTitle = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 3rem;
  text-align: left;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 100%;
  border-radius: 2px;
  padding: 5px;
  ${({ selected, theme }) => selected && `
    color: ${theme.colors.colors.light};
    background: ${theme.colors.backgrounds.redisign.blueRoyal};
  `}
  &:visited {
    color: inherit;
  }
  &:hover {
    background: ${({ theme }) => theme.colors.colors.default};
    color: ${({ theme }) => theme.colors.backgrounds.default};
  }
`

export const OptionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
   ${({ checkBox }) => checkBox && `
    border-top: 1px solid #BDC3C7;
    padding-top: 5px;
  `}
`

