export default {
  titles: {
    users: 'Users',
    search: 'Search',
    applications: 'Available applications'
  },
  applications: {
    USERS: 'Users',
    ACTIVITY_LOG: 'Activity log',
    PASSPORTIZATION: 'Passportization',
    INSTALLATION: 'Installation',
    INVENTORY: 'Inventory',
    MONITORING_CENTER: 'Monitoring center',
    REPORT_MANAGER: 'Report manager',
    SUPPORT: 'Support',
    SCHEDULE_MANAGER: 'Schedule manager',
    CONTROL_OBJECTS_STATISTICS: 'Control object statistics',
    ALARM_LOG: 'Fault log',
    LIFETIME: 'Lifetime',
    RESOURCE_ANALYTICS: 'Resource analytics',
    HEATING: 'Heat supply',
    WATER: 'Water supply',
    CHARGING_STATIONS: 'Charging stations',
    ART_LIGHTING: 'Architectural illumination',
    WASTE_COLLECTION: 'Waste container filling control',
    ROAD_MONITORING: 'Road infrastructure monitoring',
    TRAFFIC_LIGHTS: 'Traffic light control',
    INFO_BOARDS: 'Information board placement',
    DISABLED_PARKING_SPACES: 'Monitoring occupancy of places for disabled people',
    DASHBOARD: 'Dashboard',
    ALARM_ANALYTICS: 'Alarm analytics',
    TYUMEN_PORTAL: 'Portal "Tyumen-OUR HOME"',
    SERVICE_DESK: 'Service Desk "My City"',
    MAP_SERVICE: 'Map service',
    DASHBOARD2: 'Dashboard 2',
    OBJECTS_CONTROL: 'Objects control',
    ADVANCED_SEARCH: 'Advanced search',
    PPR: 'SPM',
  },
  access: {
    dataTitle: 'New Process',
    functionalTitle: 'New Process',
    processParameters: 'Process parameters',
    typeTitle: 'City Processes',
    accessTitle: 'Process functionality',
    all: 'Enable all',
    stateMonitoring: 'Object state monitoring',
    alarms: 'Alerts (alarms)',
    reportManager: 'Report Manager',
    analytic: 'Analytics and forecasting',
    passportization: 'Passportization',
    inventory: 'Inventory',
    installations: 'Installations',
    integration: 'Integrations',
    notSelected: 'Not selected',
    allUrbanProcesses: 'All urban processes',
  },
  group: {
    dataTitle: 'New organization',
    accessTitle: 'City processes and functions',
    name: 'Organization name*',
    numbers: 'Contact phone number*',
    email: 'Email of the contact person*',
    subscriptionEnd: 'Subscription end date (dd.mm.yyyy)*',
    requiredFields: '*Required fields',
    blockOrganization: 'Block Organization',
    cancelNewProcessAdding: 'Cancel adding a new process?',
    cancelNewCompanyAdding: 'Cancel adding a new company?',
    addNewProcess: 'Do you want to add a new process?',
    addNewCompany: 'Add an organization?',
    deleteCompany: 'Delete company?',
    deleteProcess: 'Delete process?',
    blockUsers: 'Block Users',
    selectFromList: 'Select from list',
    rebase: 'Move to group',
  },
  adminSortPanel: {
    multipleSelect: 'Select multiple',
    bySurname: 'By Surname',
    byDate: 'By registration date',
    sort: 'Sort',
  },
  groupOfUsers: {
    name: 'Group name*',
    requiredFields: '*Required fields',
    blockGroup: 'Block group',
    blockUsers: 'Block users',
    blockUser: 'Block user',
    unBlockUsers: 'Unblock users',
    unBlockUser: 'Unblock user',
    cancelNewProcessAdding: 'Cancel adding a new process?',
    cancelEditProcessAdding: 'Cancel editing?',
    cancelEditGroup: 'Cancel editing?',
    cancelNewGroupAdding: 'Cancel adding a new group?',
    addNewProcess: 'Do you want to add a new process?',
    addNewGroup: 'Do you want to add a new group?',
    update: 'Apply changes?',
    deleteCompany: 'Delete group?',
    deleteProcess: 'Delete process?',
    default: 'new group',
    cancelAdding: 'Cancel adding?',
  },
  user: {
    name: 'Username:',
    text: 'Accompanying text:',
    email: 'User email:',
    password: 'Temporary password:',
    sendButton: 'Send request',
  },
  list: {
    groupsTab: 'Groups',
    usersTab: 'Users',
    userDescription: 'Group:',
    groupDescription: 'Users:',
    selectAll: 'Select All',
    selected: 'Selected:',
    cancelSelect: 'Cancel selection',
    edit: 'Edit',
    delete: 'delete',
    usersTitle: 'Users',
    groupsTitle: 'Groups',
    addNewUser: 'Add new user',
    addNewGroup: 'Add New Group',
  },
  adminBoard: {
    view: 'Display',
    sort: 'Sort',
    order: 'Order',
  },
  formHeader: {
    save: 'Save',
    cancel: 'Cancel',
    creatingGroup: 'Adding a group',
    creatingTenant: 'Adding an organization',
    creatingAdmin: 'Adding an admin',
    creatingUser: 'Adding a user',
    changingGroup: 'Editing a group',
    changeTitle: 'Save changes?',
    changingTenant: 'Organization Editing',
    changingAdmin: 'Editing Admin',
    changingUser: 'Editing User',
    changingProfile: 'Edit User',
    changeGroup: 'Moving Users',
    addButton: 'Add',
    confirm: 'Add administrator?',
    confirmUser: 'Add user?',
    newAdmin: 'NEW ADMINISTRATOR',
    newUser: 'New User',
    admin: 'Administrator',
    user: 'User',
    myProfile: 'My Profile',
    addAdmin: 'Admin',
    addUser: 'User',
    addTenant: 'Organization',
    addGroup: 'Group',
    cancelAdmin: 'Cancel adding\nnew admin?',
    cancelUser: 'Cancel adding\nnew user?',
    changeForm: 'Apply changes?',
    cancelChangeForm: 'Cancel changes?',
    deleteAdmin: 'Delete administrator?',
    deleteUser: 'Delete user?',
    deleteMultiUser: 'Delete users?',
    changeAdmin: 'Apply changes?',
    yes: 'Yes',
    no: 'No',
  },
  popup: {
    attention: 'Attention',
    cancelTenant: 'Are you sure you want to cancel adding an organization?',
    cancelAdmin: 'Are you sure you want to cancel adding an admin?',
    cancelChangeAdmin: 'Are you sure you want to cancel admin editing?',
    cancelChangeUser: 'Are you sure you want to cancel user editing?',
    cancelUser: 'Are you sure you want to cancel adding a user?',
    cancelGroup: 'Are you sure you want to cancel adding the group?',
    saveTenant: 'To save the organization, you need to install all tenant',
    saveAdmin: 'All required parameters must be set to save the admin',
    saveUser: 'All required parameters must be set to save a user',
    saveGroup: 'All required parameters must be set to save the group',
    impossibleExecute: 'Unable to execute!',
    warningOrganization: 'You must select an organization to continue',
    warningGroup: 'You must select a group to continue',
    accept: 'Accept',
    yes: 'Yes',
    no: 'No',
    deleteTenant: 'Are you sure you want to remove the tenant from the system?',
    deleteGroup: 'Are you sure you want to delete the group from the system?',
    deleteAdmin: 'Are you sure you want to delete the administrator from the system?',
    deleteUser: 'Are you sure you want to delete the user from the system?',
    changeUserGroup: 'Are you sure you want to cancel changes?',
    cancelChangeTenant: 'Are you sure you want to cancel editing the organization?',
    cancelChangeGroup: 'Are you sure you want to cancel group editing?',
  },
  createAdmin: {
    fields: {
      surname: 'Last name*',
      name: 'First name*',
      patronymic: 'Middle name',
      email: 'Email*',
    },
    messages: {
      default: '*Required fields',
      info: 'An email with login details was\nsuccessfully sent to the specified email address.',
      confirm: 'User has confirmed registration.',
      notConfirm: 'User did not confirm registration.',
    },
  },
  editAdmin: {
    surname: 'Last name*',
    name: 'First name*',
    patronymic: 'Middle name',
    telephone: 'Phone*',
    email: 'Email*',
    position: 'Position',
    aboutMe: 'About me',
    block: 'Block user',
    group: 'Group',
  },
  editUser: {
    surname: 'Last name*',
    name: 'First name*',
    patronymic: 'Middle name',
    telephone: 'Phone*',
    email: 'Email*',
    position: 'Position',
    aboutMe: 'About me',
    block: 'Block user',
  },
  buttons: {
    download: 'UPLOAD PROFILE PHOTO',
    delete: 'DELETE PROFILE PHOTO',
    yes: 'Yes',
    no: 'No',
  },
  messages: {
    loading: 'Loading...',
    selectFunctionalModule: 'Select a functional module',
  },
}