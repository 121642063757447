import React from 'react'
import {
    put, takeLatest, select
} from 'redux-saga/effects'
import { FormattedMessage as Lang } from 'react-intl'
import request from '@/helpers/axios'
import { GET_CONNECTED_INTEGRATIONS_URL } from '@/constants/apiRoutes'
import { GET_ALL_CONNECTED_INTEGRATIONS } from '@/store/actions/dictionaries'
import createNotifications from '@/helpers/notification'
import {
    errorGetAllConnectedIntegrations,
    responseGetAllConnectedIntegrations
} from '@/store/actions/dictionaries/connectedIntegrations'
import { INTEGRATION_SYSTEM } from '@/constants/viewTree'
import { getIntegrationOptions } from '@/store/selectors/integrations'

function* getDictionary() {
    try {
        const response = yield request({
            url: GET_CONNECTED_INTEGRATIONS_URL,
            method: 'get',
        })
        if (response && response.data && response.data.content) {
            const integrationsSystems = yield select(getIntegrationOptions)
            const extendedIntegrations = response.data.content
                .map((integration) => ({
                    name: integration.name,
                    installationId: integration.id,
                    systemType: integration.integrationType,
                }))
            const connectedIntegrations = integrationsSystems.reduce((accumulator, system) => ({
                ...accumulator,
                [system.type]: {
                    ...system,
                    name: <Lang id={system.name} />,
                    type: INTEGRATION_SYSTEM,
                    children: extendedIntegrations
                        .filter((integration) => integration.systemType === system.type),
                },
            }), {})
            yield put(responseGetAllConnectedIntegrations(connectedIntegrations))
        }
    } catch (error) {
        yield put(errorGetAllConnectedIntegrations(error))
        const toast = createNotifications()
        toast({
            title: 'Ошибка операции!',
            description: 'Не удалось получить данные всех интеграций.\nПовторите попытку позже.',
            type: 'error',
        })
    }
}

export default function* () {
    yield takeLatest(GET_ALL_CONNECTED_INTEGRATIONS.REQUEST, getDictionary)
}