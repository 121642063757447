import React, { useEffect } from "react";

import { NotificationsContainer, NotificationsIconContainer, ContainerInfo, Text, CloseButtonWraper } from "./styles";
import MobileIcon from "@/components/icons/mobileIcons";


const NotifacationsDasboardMobile = ({newEventCount, onSwitchStateNotification, user, onGetAlarmsCount}) => {
  useEffect(() => {
    onGetAlarmsCount()
  }, [onGetAlarmsCount])

  return (
    <NotificationsContainer>
      <NotificationsIconContainer>
        <MobileIcon.NotificationIcon/>
      </NotificationsIconContainer>
      <ContainerInfo>
        <Text headerTitle>
          {`Здравствуйте, ${user.firstName  || 'Андрей'}`}
        </Text>
        <Text>
        {`у вас ${newEventCount} новых уведомлений`}
        </Text>
        <CloseButtonWraper>
          <MobileIcon.CloseNotification onClick={onSwitchStateNotification} />
      </CloseButtonWraper>
      </ContainerInfo>
    </NotificationsContainer>
  )
}

export default NotifacationsDasboardMobile