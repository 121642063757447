import { fork, takeLatest, all } from 'redux-saga/effects'

import {
  UPLOAD_FILE_NOTIFICATION,
  DOWNLOAD_MULTIPLE_OBJECT_PROGRESS
} from '@/store/actions/installation'
import installationUploadFileSaga from './installationUploadFile'
import installationUploadFileProgressSaga from './installationUploadFileProgress'

function* watchStateRequest() {
  yield takeLatest(UPLOAD_FILE_NOTIFICATION.EVENTS, installationUploadFileSaga)
  yield takeLatest(DOWNLOAD_MULTIPLE_OBJECT_PROGRESS.EVENTS, installationUploadFileProgressSaga)
}

const stateSaga = [fork(watchStateRequest)]

export default function* rootSaga() {
  yield all([...stateSaga])
}
