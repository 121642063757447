import { VERIFICATION_ALL } from './index'

export const requestVerificationAll = (payload) => ({
  type: VERIFICATION_ALL.REQUEST,
  payload,
})

export const successVerificationAll = (payload) => ({
  type: VERIFICATION_ALL.SUCCESS,
  payload,
})

export const errorVerificationAll = (payload) => ({
  type: VERIFICATION_ALL.ERROR,
  payload,
})