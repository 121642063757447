import { DELETE_OBJECTS_GROUP } from './index'

export const requestDeleteObjectsGroup = (payload) => ({
  type: DELETE_OBJECTS_GROUP.REQUEST,
  payload,
})

export const successDeleteObjectsGroup = (payload) => ({
  type: DELETE_OBJECTS_GROUP.SUCCESS,
  payload,
})

export const errorDeleteObjectsGroup = (payload) => ({
  type: DELETE_OBJECTS_GROUP.ERROR,
  payload,
})