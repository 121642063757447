import React from 'react'
import { Formik } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'
import pt from 'prop-types'
import noop from 'lodash/noop'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import SelectField from '@/components/fields/SelectField'
import CheckBoxField from '@/components/fields/CheckBoxField'

import {
  MultipleUserEditScheme,
  SELECT_GROUP,
  BLOCK_USERS,
} from '@/constants/validationFields'
import {
  FormWrapper,
  InputAndLabelContainer,
  StyledLabelWithIcon,
  Label
} from './styles'

const MultipleUserEditForm = React.forwardRef(({
  setValidForm,
  options,
  isAllBlock,
  getIsUserBlocked,
  getBlockTitle
}, ref) => {
    const isUserBlocked = getIsUserBlocked()
    const title = getBlockTitle()

    return (
        <Formik
            ref={ref}
            enableReinitialize
            initialValues={{}}
            validationSchema={ MultipleUserEditScheme }
            validate={(values) => {
              if (values[BLOCK_USERS] || values[SELECT_GROUP]) {
                 setValidForm(true)
              } else {
                 setValidForm(false)
              }
            }}
            render={({
              touched, errors, handleSubmit,
            }) => (
                <FormWrapper onSubmit={handleSubmit} id="MultipleUserEditForm" name="MultipleUserEditForm">
                    <InputAndLabelContainer>
                        <StyledLabelWithIcon>
                            <LabelWithIcon
                                isError={(touched[SELECT_GROUP] && errors[SELECT_GROUP])}
                                title={(<Lang id="usersPage.group.rebase" />)}
                            />
                        </StyledLabelWithIcon>
                        <SelectField
                            name={SELECT_GROUP}
                            options={options}
                            placeholder={(<Lang id="usersPage.group.selectFromList" />)}
                            userForm
                        />
                    </InputAndLabelContainer>
                    {
                        isUserBlocked !== undefined && (
                            <InputAndLabelContainer row>
                                <CheckBoxField name={BLOCK_USERS} marginRight/>
                                <Label>
                                  {title}
                                </Label>
                            </InputAndLabelContainer>
                        )
                    }
                </FormWrapper>
            )}
        />
    )
})

MultipleUserEditForm.propTypes = {
  setValidForm: pt.func,
  getIsUserBlocked: pt.func,
  getBlockTitle: pt.func,
  isAllBlock: pt.bool,
  options: pt.arrayOf(pt.object),
}
MultipleUserEditForm.defaultProps = {
  isAllBlock: false,
  setValidForm: noop,
  options: [],
  getIsUserBlocked: noop,
  getBlockTitle: noop,
}

export default MultipleUserEditForm
