import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import {
  getCountOfPins, getCountOfProjects,
} from '@/store/selectors/maps/socketEvents'
import {
  getEventsNewCount,
  getEventsCount,
} from '@/store/selectors/alarmManager'
import {
  getUserData,
  getWidgets,
  getApplications,
  getAlarmsWidgetOpenState,
  getIntegrationStatus,
  getUserApplications,
  getStatusNotification,
  getStatusSwiper,
} from '@/store/selectors/appSettings'
import { requestGetAlarmsCount } from '@/store/actions/alarmManager/getAlarmsCount'
import {
  toggleAlarmsWidgetSettings,
  startDeleteWidgetFromGeneralPage,
  updateWidgetsList,
  setSelectedNode,
  switchStateNotification,
  switchStateSwiper
} from '@/store/actions/appSettings'
import { requestAnalyticsAlarmsWidget } from '@/store/actions/analyticsAlarms/getWidgetInformation'
import { requestTyumenMessagesWidget } from '@/store/actions/tyumenOurHome/getMessagesWidget'
import generateUserAttributes from '@/helpers/generateUserAttributes'
import { getSystems } from '@/store/selectors/integrations'
import { getWidgetsData, getWidgetsStatus, getLoader } from '@/store/selectors/customWidgetsData'
import { requestGetInfoWidget } from '@/store/actions/serviceDeskMyCity/getInfoWidget'
import { requestGetCurrentUser } from '@/store/actions/users/getCurrentUser'
import Dashboard from './component'
import { requestGetWidgetSettings } from '@/store/actions/user/settings/getWidgetSettings'
import { getTreeForWidgets } from '@/store/selectors/widgetDashboard'
import { requestGetMapStatistic } from '@/store/actions/maps/getMapStatistic'
import { requestGetTreeForWidgets } from '@/store/actions/widgetDashboard/getTreeForWidgets'

const mapStateToProps = (state) => ({
  pins: getCountOfPins(state),
  countOfProjects: getCountOfProjects(state),
  eventCount: getEventsCount(state),
  newEventCount: getEventsNewCount(state),
  widgets: getWidgets(state),
  applications: getApplications(state),
  isWidgetSettingsOpen: getAlarmsWidgetOpenState(state),
  user: getUserData(state),
  userApplications: getUserApplications(state),
  projectCount: 8,
  serviceDeskStatus: getIntegrationStatus(state),
  widgetsData: getWidgetsData(state),
  ...generateUserAttributes(
    getUserData(state),
    getSystems(state),
  ),
  tree: getTreeForWidgets(state),
  isOpenNotification: getStatusNotification(state),
  widgetsDataStatus: getWidgetsStatus(state),
  loader: getLoader(state),
  isActiveSwiper: getStatusSwiper(state),
})

const mapDispatchToProps = (dispatch) => ({
  requestGetMapStatistic: () => dispatch(requestGetMapStatistic()),
  onGetAlarmsCount: (tenantId) => dispatch(requestGetAlarmsCount(tenantId)),
  onToggleAlarmsWidgetSettings: (isOpen) => dispatch(toggleAlarmsWidgetSettings(isOpen)),
  onGetAnalyticWidgetData: (payload) => dispatch(requestAnalyticsAlarmsWidget(payload)),
  onGetTyumenWidgetData: (payload) => dispatch(requestTyumenMessagesWidget(payload)),
  requestGetInfoWidget: (payload) => dispatch(requestGetInfoWidget(payload)),
  requestGetCurrentUser: (payload) => dispatch(requestGetCurrentUser(payload)),
  requestGetWidgetSettings: () => dispatch(requestGetWidgetSettings()),
  onUpdateWidgetsList: (widgetsList) => dispatch(updateWidgetsList(widgetsList)),
  onDeleteWidget: (id, widgetType, integrationId) => dispatch(
    startDeleteWidgetFromGeneralPage(id, widgetType, integrationId),
  ),
  setNode: (payload) => dispatch(setSelectedNode(payload)),
  switchStateNotification: () => dispatch(switchStateNotification()),
  switchStateSwiper: () => dispatch(switchStateSwiper()),
  requestGetTree: () => dispatch(requestGetTreeForWidgets()),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Dashboard))
