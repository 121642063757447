import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getPassportization = createSelector(
  getState,
  (state) => get(state, 'passport', {}),
)

export const getRequestStatus = createSelector(
  getPassportization,
  (router) => get(router, 'requestStatus', {}),
)
export const getRequestCardStatus = createSelector(
  getPassportization,
  (router) => get(router, 'requestCardStatus', {}),
)

export const getOperatedElement = createSelector(
  getPassportization,
  (router) => get(router, 'operatedElement', {}),
)

export const getSelectedElementEquipment = createSelector(
  getPassportization,
  (router) => get(router, 'selectedElementEquipment', {}),
)

export const getPassportData = createSelector(
  getPassportization,
  (router) => get(router, 'passportData', {}),
)

export const getCardData = createSelector(
  getPassportization,
  (router) => get(router, 'cardData', {}),
)

export const getActiveFieldsPassport = createSelector(
  getPassportization,
  (router) => get(router, 'activeFieldsPassport', {}),
)

export const getData = createSelector(
  getPassportization,
  (router) => get(router, 'data', {}),
)

export const getSelectedFields = createSelector(
  getPassportization,
  (router) => get(router, 'selectedFields', {}),
)

export const getPassportizationFiles = createSelector(
  getPassportization,
  (state) => get(state, 'passportFiles', {}),
)
export const getPassportPhoto = createSelector(
  getPassportizationFiles,
  (state) => get(state, 'photo', {}),
)
export const getPassportFile = createSelector(
  getPassportizationFiles,
  (state) => get(state, 'file', {}),
)

export const getTreeData = createSelector(
  getPassportization,
  (state) => get(state, 'tree', {}),
)

export const getPins = createSelector(
  getPassportization,
  (state) => get(state, 'pins', []),
)

export const getLoading = createSelector(
  getPassportization,
  (state) => get(state, 'loading', {}),
)

export const getZones = createSelector(
  getPassportization,
  (state) => get(state, 'zones', {}),
)
