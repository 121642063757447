import styled from 'styled-components'

export const ColorSelectStyled = styled.div`
  display: flex;
  width: 100%;
  padding: 0 0 10px 5px;
  border-radius: 4px;
  .react-colorful {
    width: 100%;
    height: auto;
    cursor: pointer;
    .react-colorful__saturation {
      visibility: hidden;
    }
    .react-colorful__last-control {
      border-radius: 5px;
      height: 8px;
    }
    .react-colorful__pointer {
      width: 20px;
      height: 20px;
    }
  }
  ${({ theme, isError }) => `
    {
      border: 1px solid
      ${isError ? theme.colors.messages.error : '#FAFBFB'};
    }
  `}
`

export const Error = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  color: ${({ theme }) => theme.colors.colors.disabled};
  font-size: 1.2rem;
  line-height: 1.4rem;
  width: 100%;
  margin-top: 5px;
  transition: color 100ms ease-in-out;
  text-align: left;
  word-break: break-word;
`