export default (coord) => {
  if (!coord) {
    return null
  }
  const splitValue = (coord || '').toString().split('.')
  let afterDot = (splitValue[1] || '')
  if (afterDot.length > 6) {
    afterDot = afterDot.slice(0,6)
  }
  const delta = (6 - afterDot.length) || 0
  const additionZeros ='0'.repeat(delta)
  return `${splitValue[0]}.${afterDot}${additionZeros}`
}