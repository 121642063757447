import {
    takeLatest, put, select
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import createNotifications from "@/helpers/notification";
import { generateGetGeoZoneStatistic } from '@/constants/apiRoutes';
import { GET_NODE_STATISTIC } from '@/store/actions/installation';
import { errorGetNodeStatistic, successGetNodeStatistic } from '@/store/actions/installation/getNodeStatistic';
import { getGlobalFilters } from '@/store/selectors/appSettings'
import { getRoot } from '@/store/selectors/installation'

function* getNodeStatistic({ payload }) {
    const { asu , objectsTypes, integrations, processes, owners, exploitationOrganizations, ...statuses} = yield select(getGlobalFilters)
    const statusesTrue = Object.keys(statuses).filter((status) => statuses[status]) 
    const telemetryStatuses = statusesTrue.length === Object.keys(statuses).length ? [] : statusesTrue
    const root = yield select (getRoot)
    const { id } = payload
    try {
        const { data } = yield request({
            url: generateGetGeoZoneStatistic({
                id: id || root[0].id,
                params: {
                    telemetryStatuses: telemetryStatuses,
                    integrationTypes: asu,
                    installationTypes: objectsTypes,
                    integrationIds: integrations,
                    cityProcessTypes: processes,
                    owners,
                    exploitationOrganizations
                }
            }),
            method: 'get',
        })
        const dataForSave = data.reduce((accumulator, system) => ({
            ...accumulator,
            [system.type]: {
                type: system.type,
                NO_PROBLEM: system.ok,
                WARNING: system.warning,
                ERROR: system.error,
                UNDEFINED: system.undefined
            },
        }), {})
        yield put(successGetNodeStatistic(dataForSave))
    } catch (error) {
        console.log('function*getNodeStatistic -> error', error)
        yield put(errorGetNodeStatistic())
        const toast = createNotifications()
        toast({
            title: 'Ошибка операции!',
            description: 'Не удалось получить статистику. Повторите попытку позже.',
            type: 'error',
        })
    }
}
export default function* root() {
    yield takeLatest(GET_NODE_STATISTIC.REQUEST, getNodeStatistic)
    // yield takeLatest(SET_GLOBAL_FILTER_VALUES, getNodeStatistic)
}