import styled from 'styled-components'

export const Unit = styled.div`
  height: 8px;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  text-align: center;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 500;
  font-size: 0.8rem;
  vertical-align: bottom;
  padding-left: 3px;
  margin-right: 20px;
`

export const Value = styled.div`
  display: flex;
  align-items: center;
  font-weight: ${({ theme }) => theme.fontWeights.light};
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.1rem;
  ${({ ws }) => ws && `
    white-space: pre;
  `}
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  ${({ right }) => right && `
    margin-left: auto;
  `}
  ${({ spaceBetween }) => spaceBetween && `
    width: 100%;
    justify-content: space-between;
    margin-bottom: 5px;
  `}
  ${Unit} {
    align-items: flex-end;
  }
  ${({ pv }) => pv && `
    padding: 8px 0px;
    `}
`

export const Units = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.light};
  font-size: 2rem;
  line-height: 2.3rem;
  text-align: center;
  margin-bottom: 5px;
`

export const IndicatorLine = styled.div`
  position: relative;
  width: 100%;
  min-height: 4px;
  border-radius: 2px;
  background: ${({ theme }) => theme.colors.borders.default};
  margin-top: auto;
  margin-bottom: 4px;
`

export const IndicatorLineActive = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 4px;
  border-radius: 2px;
  background: ${({ color, theme }) => color || theme.colors.backgrounds.chart.default};
  ${({ disabled, theme }) => disabled && `
    background: ${theme.gradients.alarm};
  `}
  ${({ status, theme }) => status === 'UNDEFINED' && `
    background: ${theme.colors.backgrounds.disabled};
  `}
  ${({ uniqueKey, widthValue }) => (widthValue !== 'withoutBorder'
    ? `
    animation: changeWidth-${uniqueKey} 1s ease-in-out 1 forwards;

    @keyframes changeWidth-${uniqueKey} {
      0% {
        width: 4px;
      }
      100% {
        width: ${(widthValue ? `${widthValue}%` : '4px')};
      }
    }
  `
    : null)}

  ${({ lineColor, theme }) => lineColor === 'dangerous' && `
    background: ${theme.colors.backgrounds.chart.critical};
  `}
  ${({ lineColor, theme }) => lineColor === 'warning' && `
    background: ${theme.colors.backgrounds.chart.middle};
  `}
`

export const Status = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ color, theme }) => color || theme.colors.colors.redisign.default};
  font-size: 2.4rem;
  line-height: 3.5rem;
  margin-left: auto;

  ${({ color, small, theme }) => (color || small) && `
    font-size: 1.4rem;
    margin-left: 5px;
    margin-right: auto;
    color: ${color || theme.colors.colors.redisign.default};
  `}
  ${({ time, theme }) => time && `
    font-size: 1.4rem;
    margin-left: 5px;
    margin-right: auto;
    color: ${theme.colors.colors.redisign.default};
  `}
  ${({ spaceBetween, theme }) => spaceBetween && `
      flex: 1;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      font-style: normal;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 2.1rem;
    ;
  `}
`

export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme, online }) => theme.colors.colors.redisign.default};
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: left;
  margin-bottom: auto;
  ${({ manyTitle, theme }) => manyTitle && `
    color: ${theme.colors.colors.redisign.default};
    width: 35%;
  `}

  ${({ disabled, theme }) => disabled && `
    color: ${theme.colors.colors.redisign.default};
    margin-top: 20px;
  `}
  ${({ status }) => status && `
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  `}
  ${({ nowrap }) => nowrap && `
    white-space: nowrap;
  `}
  ${({ name }) => name && `
    max-width: 50%;
    margin-bottom: 0;
  `}
    ${({ pb }) => pb && `
    padding-bottom: ${pb}px;
  `}
`

export const ValueBorder = styled.div`
  ${({ margin }) => margin && `
    margin-right: 24px;
  `}
`

export const ValueBorders = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.bold};
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  font-size: 1.4rem;
  line-height: 2.4rem;
  text-align: left;
`

export const DescriptionWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  ${({ center }) => center && `
    justify-content: center;
  `}
  ${({ row }) => row && `
    flex-direction: row;
    align-items: center;
  `}
  ${({ pb }) => pb && `
    padding-bottom: ${pb}px;
  `}
  ${({ pt }) => pt && `
    padding-top: ${pt}px;
  `}
`

export const ControlsRow = styled.div`
  display: flex;
  align-items: center;

  svg {
    width: 28px;
    height: 28px;
  }
`

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ mr }) => mr && `
    margin-right: 10px;
  `}
`

export const IndicatorWrapper = styled.div`
  min-height: 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.colors.backgrounds.default};
  color: ${({ theme }) => theme.colors.colors.redisign.default};
  box-shadow: -8px 12px 18px ${({ theme }) => theme.colors.shadows.widget};
  border-radius: 4px;
  padding: 10px 15px;

  ${({ cursor }) => `cursor: ${cursor || 'pointer'}`};

  ${({ column, small, mode, theme }) => column && `
    flex-direction: column;
    min-height: fit-content;

    ${ValueBorders} {
      display: flex;
      flex-direction: row;
      font-weight: ${theme.fontWeights.regular};
      font-size: 1.2rem;
      line-height: 3rem;
      width: 100%;
      justify-content: flex-end;

      ${ValueBorder} {
        margin-left: 5px;
      }
    }

    ${Title} {
      margin: auto 0;
      ${small && !mode ? 'margin-bottom: 20px;' : ''}
      ${small && mode ? 'margin-bottom: 10px;' : ''}
    }
  `}

  ${({ withoutSvgStyle }) => !withoutSvgStyle && `
    > svg {
      width: 100px;
      min-width: 100px;
      height: 100px;
      min-height: 100px;
    }
  `}
`
