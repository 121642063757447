import styled from 'styled-components'
import {
  LIGHT_THEME_NAME,
} from '@/constants/names'

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 60px 7.5px 7.5px 7.5px;
`
export const UserImage = styled.div`
  position: relative;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border: 2px solid #DDDDDD;
  border-radius: 50%;

  img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`

export const ContainerUserInfo = styled.div`
  display: flex;
  flex-direction: column;
`

export const Wrapper = styled.div`
  height: 48px;
  width: 100%;
  padding: 10px 10px 10px 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 15px;
`

export const WrapperIcon = styled.div`
  width: 24px;
  height: 24px;
`
export const WrapperUserProfile = styled.div`
  display: flex;
  border-radius: 10px;
  background: #FAFBFB;
  height: 80px;
  padding: 10px 10px 10px 20px;
  align-items: center;
  gap: 15px;
`

export const  WrapperSystem = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background: #FAFBFB;
`

export const Title = styled.div`
  font-family: 'Roboto', sans-serif;
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: start;
  ${({ isBigChildren }) => isBigChildren && `
    overflow: hidden;
  `}
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
        font-size: 1.6rem;
        font-width: 500;
      `
    }
    return `
      font-weight: ${theme.fontWeights.bold};
      font-size: 1.6rem;
      line-height: 2.4rem;
    `
  }}
`

export const Lable = styled.div`
  width: 100%;
  height: 20px;
  color:${({ theme, isError }) => (isError
    ? theme.colors.messages.error
    : theme.colors.colors.default)};
  font-style: bold;
  font-size: 1.6rem;
  line-height: 3rem;
  font-weight: 500;
  text-align: start;
  `

export const ContainerVersion = styled.div`
  width: 100%;
  height: 48px;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  font-family: 'Roboto', sans-serif;
  color: #838D99;
  font-size: 1.6rem;
  font-width: 500;
`

export const TitlePage = styled.h1`
  font-size: 24px;
  color: #1061DF;
  padding-left: 10px;
`
export const Header = styled.div`
width: 100vw;
display: flex;
height: 50px;
padding: 0 5px 10px 0;
align-items: flex-end
justify-content: space-between;
margin-bottom: 5px;
padding-bottom: 10px;
position: absolute;
left: 0;
top: 0;
background-color: #FAFBFB;
z-index: 100;

& > svg {
  cursor: pointer;
}
`
