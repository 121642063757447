import {
  takeLatest, put, call
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import updatePath from '@/helpers/updatePath'
import { successEditGeoZone } from '@/store/actions/installation/editGeoZone'
import { EDIT_GEOZONE } from '@/store/actions/installation'
import { INSTALLATIONS_GEOZONE_ENDPOINT } from '@/constants/apiRoutes'
import { requestGetRootElements } from '@/store/actions/installation/getRootElemenet'
import createNotifications from '@/helpers/notification'
import { treeHandlers } from 'react-hyper-tree'
import { GEOZONE, ROOT } from '@/constants/objectTypes'
import { setGlobalLoading, setTrueStatusGlobalFilter } from '@/store/actions/appSettings'

function* editGeoZoneSaga({ payload }) {
  const {
    formValues, values, setUrlFormValues, maxRadiusValue
  } = payload
  const {
    city, lat, lon, mark, name, parentId, region, radius, maxRadius
  } = values
  const toast = createNotifications()
  try {
    yield put(setGlobalLoading(true))
    const body = {
      id: formValues.id,
      parentId: formValues.parentTreeId,
      name,
      city,
      region,
      point: {
        latitude: lat,
        longitude: lon,
      },
      note: mark,
      radius,
      maxRadius: maxRadius ? maxRadius : maxRadiusValue
    }
    const { data } = yield request({
      url: INSTALLATIONS_GEOZONE_ENDPOINT,
      method: 'post',
      body,
    })
    const {
      id,
      rootId,
      path,
      FILTER,
      parentGeoZoneId
    } = formValues
    const newPath = updatePath(path, parentId, `${data.parentId}/${data.id}`)
    const tree = treeHandlers.trees['installations-tree']
    yield put(setTrueStatusGlobalFilter())
    if (parentGeoZoneId) {
      const parent = tree.instance.getNodeById(data.parentId)
      const parentChildren = yield call(parent.data.getChildren, parent)
      tree.handlers.setRawChildren(parent, parentChildren, 'first', true)
      const node = tree.instance.getNodeById(data.id)
      tree.handlers.setOpen(node, true)
      tree.handlers.setSelected(node, true)
      tree.handlers.rerender()
    } else (
        yield put(requestGetRootElements())
    )
    setUrlFormValues({
      id,
      rootId,
      parentGeoZoneId: data.parentId,
      parentTreeId: data.parentId,
      path: newPath,
      type: data.parentId ? GEOZONE : ROOT,
      FILTER,
    })
    yield put(setGlobalLoading(false))
    toast({
      title: 'Редактирование геозоны',
      type: 'success',
      description: 'Изменения сохранены.',
    })
    yield put(setTrueStatusGlobalFilter())
    yield put(successEditGeoZone())
  } catch (error) {
    yield put(setGlobalLoading(false))
    const { FILTER } = formValues
    setUrlFormValues({ FILTER })
    console.log('function*editGeoZoneSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Редактирование геозоны',
      type: 'error',
      description: formValues.id ? 'Не удалось изменить геозону, обратитесь к администратору.' : 'Не удалось изменить корневую геозону, обратитесь к разработчикам платформы',
    })
  }
}

export default function* root() {
  yield takeLatest(EDIT_GEOZONE.REQUEST, editGeoZoneSaga)
}
