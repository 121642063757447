import React from 'react'
import { injectIntl } from 'react-intl'
import { Route, Switch } from 'react-router-dom'
import {
  SERVICE_DESK_MY_CITY_APPEALS_URL,
  SERVICE_DESK_MY_CITY_URL,
} from '@/constants/routes'
import Header from './components/Header'
import Appeals from './components/Appeals'
import Statistic from './components/Statistic'
import useGetIsMobile from '@/hooks/useGetIsMobile'
import useHtmlTitle from '@/hooks/useHtmlTitle'
import {
  Container,
} from './styles'

const ServiceDeskMyCity = ({intl}) => {
  useHtmlTitle(intl.messages['serviceDeskMyCity.mainTitle'])
  const isMobile = useGetIsMobile()

  return (
    <Container isMobile={isMobile}>
      {!isMobile && <Header intl={intl}/> }
      <Switch>
        <Route
          exact
          path={SERVICE_DESK_MY_CITY_URL}
          component={Statistic}
        />
        <Route
          exact
          path={SERVICE_DESK_MY_CITY_APPEALS_URL}
          component={Appeals}
        />
      </Switch>
    </Container>
  )
}

ServiceDeskMyCity.propTypes = {
}
ServiceDeskMyCity.defaultProps = {
}

export default injectIntl(ServiceDeskMyCity)
