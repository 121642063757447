import {
  MESH,
  BRIZ,
  ATM,
  KULON,
  UNILIGHT,
  WITHOUT_ASU
} from '@/constants/integrations'
import briz from './briz'
import kulon from './kulon'
import atm from './atm'
import mesh from './mesh'
import unilight from './unilight'
import withoutAsu from './withoutAsu'

export default {
  [ATM]: atm,
  [KULON]: kulon,
  [BRIZ]: briz,
  [MESH]: mesh,
  [UNILIGHT]: unilight,
  [WITHOUT_ASU]: withoutAsu,
}
