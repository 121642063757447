import React from 'react';
import { PageButton, Pagination } from '@/containers/pages/Scheduler/conponents/styles';


const PaginationComponent = ({
  currentPage,
  pageCount,
  onPageChange
}) => {

  const getVisiblePages = () => {
    const totalVisiblePages = 5
    const pages = []
    const halfVisible = Math.floor(totalVisiblePages / 2)

    let startPage = Math.max(0, currentPage - halfVisible)
    let endPage = Math.min(pageCount - 1, startPage + totalVisiblePages - 1)

    if (endPage - startPage < totalVisiblePages - 1) {
      startPage = Math.max(0, endPage - totalVisiblePages + 1)
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(i)
    }

    return pages
  }

  const visiblePages = getVisiblePages()

  if (pageCount <= 1) {
    return null
  }

  return (
    <Pagination>
      <PageButton onClick={() => onPageChange(0)}>⟸</PageButton>
      <PageButton
        onClick={() => onPageChange(Math.max(0, currentPage - 1))}
        disabled={currentPage === 0}
      >
        ⇐
      </PageButton>
      {visiblePages.map((page) => (
        <PageButton
          key={page}
          onClick={() => onPageChange(page)}
          active={page === currentPage}
        >
          {page + 1}
        </PageButton>
      ))}
      <PageButton
        onClick={() => onPageChange(Math.min(pageCount - 1, currentPage + 1))}
        disabled={currentPage === pageCount - 1}
      >
        ⇒
      </PageButton>
      <PageButton onClick={() => onPageChange(pageCount - 1)}>⟹</PageButton>
    </Pagination>
  )
}

export default PaginationComponent
