import React, { useState, useCallback, useEffect, useMemo } from 'react'
import pt from 'prop-types'
import get from 'lodash/get'
import noop from 'lodash/noop'
import { FormattedMessage as Lang, injectIntl } from 'react-intl'
import MapsIcons from '@/components/icons/maps'
import ACYWidget from '@/components/widgets/ACYWidget'
import { isZone } from '@/helpers/maps/isDefiniteElement'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import ObjectState from '../ObjectState'
import ObjectInformation from '../ObjectInformation'
import BackIcon from '@/components/icons/maps/BackIcon'
import HeaderIcons from '@/components/icons/header'
import ControlsBlock from '@/components/blocks/HeaderPageMobile/ControlsBlock'
import TabsController from '@/components/blocks/TabsController'
import { formatDataForObject, handlePhotoConvertToBase64 } from '@/containers/pages/Installation/utils'
import {
  ObjectPassportizationStyled,
  ContentContainer,
  ObjectTitle,
  ObjectNameRow,
  CloseIcon,
  TabRow,
  ControlsContainer,
  HeaderPassport
} from './styles'
import {
  GEOZONE,
  GROUP,
  OBJECT_ELEMENT
} from '@/constants/objectTypes'
import GlobalPopupMobile from '@/components/blocks/GlobalPopupMobile/component'
import {TYPE_TO_NAME} from "@/constants/maps";

const INFORMATION_TAB = {
  title: 'Паспорт',
  icon: MapsIcons.InformationIcon,
  value: 'information',
  component: (props) => (<ObjectInformation {...props} />),
}
const MONITARING_TAB = {
  title: 'Телеметрия',
  icon: MapsIcons.CircleChartIcon,
  value: 'monitoring',
  component: (props) => (<ObjectState {...props} />),
}
// const STATISTICS_TAB = {
//   title: 'Отчет',
//   icon: MapsIcons.GraphicIcon,
//   component: () => (React.Fragment),
// }

const TABS = [
  MONITARING_TAB,
  INFORMATION_TAB,
  // STATISTICS_TAB,
]

const ObjectPassportization = ({
  node,
  updateACYWidget,
  ACYWidgetSettings,
  objectTelemetry,
  refreshIntegration,
  image,
  card,
  nodeStatistic,
  getCard,
  getNodeStatistic,
  roots,
  intl,
  isMobile,
  onClose,
  pinnedNode,
  onSetUnpinNode,
  onSetSelectedNode,
  getStreamUrl,
  setStreamUrl,
  streamUrl,
}) => {
  const [activeTab, setActiveTab] = useState(MONITARING_TAB)
  const [listView, setListView] = useState(false)
  const [isCardRequested, setIsCardRequested] = useState(false)
  const [isPopupOpen, setIsPopupOpen] = useState({})
  const [uploadImage, setUploadImage] = useState()

  const data = useMemo(() => {
    if (card.data.installationType !== OBJECT_ELEMENT && card.requestStatus !== 'IDLE') {
      return {}
    }
    return formatDataForObject(card.data, intl)
  }, [card, intl])

  useEffect(() => {
    if (node.id && roots.length) {
      getNodeStatistic(node)
    }
  }, [node, getNodeStatistic, roots])

  useEffect(() => {
    const photo = get(data, 'ATTACHMENT.PHOTO_TITLE', null)
    if (photo) {
      handlePhotoConvertToBase64(photo, setUploadImage)
    }
    if (!photo && uploadImage) {
      setUploadImage(null)
    }
  }, [data, uploadImage])

  useEffect(() => {
    if (node.id && !isCardRequested && getCard && getNodeStatistic && !card.data.length) {
      getCard(node)
      setIsCardRequested(true)
    }
  }, [node, getCard, isCardRequested, getNodeStatistic, card,])

  const handleClickButton = () => {
    setIsPopupOpen({value: false})
  }

  const handlePinIconClick = () => {
    setIsPopupOpen({value: true})
  }

  const handleUnpinNode = () => {
    onSetUnpinNode({})
    setIsPopupOpen({value: false})
  }

  const handleSetSelectedNode = () => {
    onSetSelectedNode(node)
    setIsPopupOpen({value: false})
  }

  const filters = [
    {
      icon: pinnedNode && pinnedNode.id
        ? <HeaderIcons.SelectedPinIcon />
        : <HeaderIcons.NoSelectedPinIcon />,
      name: 'HeaderIcons.SelectedPinIcon',
      onClick: handlePinIconClick,
    }
  ] 

  const handleChangeTab = useCallback((tab) => (value) => {
    if (tab.value) {
      setActiveTab(tab)
    }
    setListView(false)
  }, [setActiveTab, setListView])

  const handleChangeTabMobile = (value) => {
    if (value) {
      setActiveTab((TABS.filter((tab => tab.value === value)))[0])
    }
  }
  const handleListView = useCallback(() => {
    setListView(!listView)
  }, [listView, setListView])

  const renderACYWidget = useCallback(() => {
    const ACTWidgets = Object.values(nodeStatistic).map(connectedSystems => (
      <ACYWidget
        systemAlias={connectedSystems}
        onSubmit={updateACYWidget}
        settings={ACYWidgetSettings[connectedSystems.type]}
        intl={intl}
      />
    ))
    return (
      <>
        {ACTWidgets}
      </>
    )
  }, [ACYWidgetSettings, updateACYWidget, nodeStatistic, intl])

  const renderListView = useCallback(() => (
    TABS.map((tab) => {
      const Icon = tab.icon || React.Fragment
      return (
        <TabRow onClick={handleChangeTab(tab)}>
          <Icon />
          {tab.title}
        </TabRow>
      )
    })
  ), [handleChangeTab])
  const renderActiveTabView = useCallback((data) => {
    if (isZone(data)) {
      return (
        <>
          {renderACYWidget(data)}
        </>
      )
    }
    const objectFields = card.data && card.data.objectFields
    const updatedFields = objectFields?.map(field => {
      return field.fieldName === 'CONTROL_TYPE' ? {...field, value: TYPE_TO_NAME[field.value]} : field
    })
    const Component = activeTab.component
    return (
      <Component
        {...node}
        image={uploadImage}
        telemetry={objectTelemetry}
        refreshIntegration={refreshIntegration}
        card={updatedFields}
        setStreamUrl={setStreamUrl}
        streamUrl={streamUrl}
        getStreamUrl={getStreamUrl}
      />
    )
  }, [node, activeTab, renderACYWidget, refreshIntegration, card, objectTelemetry, uploadImage, streamUrl, setStreamUrl, getStreamUrl])

  return (
    (node.treeNodeType === GEOZONE || (node.treeNodeType === OBJECT_ELEMENT && node.installationType !== GROUP)) &&
    <>
    {isPopupOpen.value && 
      <GlobalPopupMobile 
        onClose={handleClickButton} 
        handleSetSelectedNode={handleSetSelectedNode} 
        handleUnpinNode={handleUnpinNode} 
        pinnedNode={pinnedNode}/>
    }
      <ObjectPassportizationStyled>
        {isMobile ?
        <HeaderPassport>
        <ObjectNameRow>
          <BackIcon color={'#1061DF'} onClick={onClose}/>
          <ObjectTitle>
            {node.name}
          </ObjectTitle>
          <ControlsContainer>
            <ControlsBlock
              controls={filters}
              spaced
            />
        </ControlsContainer>
        </ObjectNameRow>
        <TabsController
          nameStyles="fullWidth"
          activeTab={activeTab.value}
          tabs={TABS}
          onTabChange={handleChangeTabMobile}
          withUrlChange={false}
        /> 
      </HeaderPassport>
        :
        <ObjectNameRow>
          <ObjectTitle>
            {node.name}
          </ObjectTitle>
          <PortalTooltip
              title={(<Lang id="tooltip.options" />)}
              renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                  <CloseIcon
                      ref={wrapperRef}
                      onMouseEnter={onMouseEnterHandler}
                      onMouseLeave={onMouseLeaveHandler}
                      onClick={isZone(node) ? noop : handleListView}
                  >
                    <MapsIcons.DotsIcon />
                  </CloseIcon>
              )}
          />
        </ObjectNameRow>
        }
        <ContentContainer>
          {listView
              ? (renderListView())
              : (renderActiveTabView(node))}
        </ContentContainer>
      </ObjectPassportizationStyled>
      </>
  )
}

ObjectPassportization.propTypes = {
  node: pt.shape({
    id: pt.oneOfType([pt.string, pt.number]),
    brizApiId: pt.oneOfType([pt.string, pt.number]),
    aliasId: pt.oneOfType([pt.string, pt.number]),
    name: pt.oneOfType([pt.string, pt.element]),
    type: pt.oneOfType([pt.string, pt.element]),
    system: pt.string,
  }).isRequired,
  updateACYWidget: pt.func,
  refreshIntegration: pt.func,
  ACYWidgetSettings: pt.objectOf(pt.object),
  telemetry: pt.objectOf(pt.object).isRequired,
  image: pt.string,
  card: pt.objectOf(pt.object)
}
ObjectPassportization.defaultProps = {
  updateACYWidget: noop,
  refreshIntegration: noop,
  ACYWidgetSettings: {},
  image: null,
  card: {},
}

export default injectIntl(ObjectPassportization)
