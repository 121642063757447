import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import WidgetsIcons from '@/components/icons/widgets'
import HeaderIcons from '@/components/icons/header'
import TyumenIcons from '@/components/icons/tyumenOurHome'
import {
  OK,
  WARNING,
  ERROR,
  STATUS_COLORS,
} from '@/constants/alarms'
import {
  IN_PROGRESS_STATUSSES,
  ERROR_STATUSSES,
  DONE_STATUSSES,
} from '@/constants/tyumenOurHomeProccesses'
import {
  ColumnContainer,
  ColumnHeader,
  ColumnTitle,
  CardsContainer,
  ColumnSmallCard,
  SmallCardTitle,
  SmallCardAddress,
  SmallCardFooter,
  SmallCardNumber,
  ColumnLargeCard,
  LargeCardTitle,
  LargeCardDescription,
  LargeCardChat,
  LargeCardContainer,
  LargeCardMessage,
  CloseIconWrapper,
  Title,
  Value
} from './styles'

const TITLE_ICON_BY_TYPE = {
  [ERROR]: (<TyumenIcons.ToViewIcon />),
  [WARNING]: (<TyumenIcons.InProgressIcon />),
  [OK]: (<TyumenIcons.DoneIcon />),
}

const CARD_ICON_BY_TYPE = {
  [ERROR]: (<WidgetsIcons.BadIcon />),
  [WARNING]: (<WidgetsIcons.IndifferentIcon />),
  [OK]: (<WidgetsIcons.HappyIcon />),
}

const NotificationsColumn = ({
  title,
  data,
  type,
  large,
  onClose,
  onNotificationClick,
  isMobile
}) => {
  const handleNotificationClick = (id) => () => onNotificationClick(id)

  const getColorByStatus = (status) => {
    if (IN_PROGRESS_STATUSSES.includes(status)) {
      return STATUS_COLORS[WARNING]
    }
    if (ERROR_STATUSSES.includes(status)) {
      return STATUS_COLORS[ERROR]
    }
    if (DONE_STATUSSES.includes(status)) {
      return STATUS_COLORS[OK]
    }
  }

  const renderHeader = () => (
    <ColumnHeader>
      {!large ? (TITLE_ICON_BY_TYPE[type] || null) : null}
      <ColumnTitle>
        {large ? 'Просмотр обращения:' : title}
        {' '}
        {!large ? `(${data.length})` : `#${data.key}`}
      </ColumnTitle>
    </ColumnHeader>
  )

  const renderNotifications = () => data.map((card) => (
    <ColumnSmallCard onClick={handleNotificationClick(card.id)} >
      <SmallCardTitle>{card.message}</SmallCardTitle>
      <SmallCardAddress>{card.address}</SmallCardAddress>
      <SmallCardFooter>
        {CARD_ICON_BY_TYPE[card.type] || CARD_ICON_BY_TYPE[type] || null}
        <SmallCardNumber>{card.key}</SmallCardNumber>
      </SmallCardFooter>
    </ColumnSmallCard>
  ))

  const renderQuestionBlock = () => (
    <LargeCardContainer>
      <TyumenIcons.UserIcon />
      <LargeCardMessage position="left">
        <LargeCardTitle>
          {data.author}
          {', '}
          {data.date}
        </LargeCardTitle>
        <LargeCardDescription>{data.message}</LargeCardDescription>
      </LargeCardMessage>
    </LargeCardContainer>
  )
  const renderAnswerBlock = () => (
    <LargeCardContainer>
      <LargeCardMessage position="right">
        <LargeCardTitle>
          {data.appealResponse.title}
          {', '}
          {data.appealResponse.date}
        </LargeCardTitle>
        <LargeCardDescription>{data.appealResponse.sender}</LargeCardDescription>
        <LargeCardDescription>{data.appealResponse.date}</LargeCardDescription>
        <br />
        <LargeCardDescription>{data.appealResponse.message}</LargeCardDescription>
      </LargeCardMessage>
      <TyumenIcons.GovernmentIcon />
    </LargeCardContainer>
  )


  const renderNotificationBlock = () => (
    <ColumnLargeCard>
    {!isMobile && 
      <CloseIconWrapper onClick={onClose}>
        <HeaderIcons.MenuCloseIcon />
      </CloseIconWrapper>
    }
      <LargeCardTitle sub>
        <Title>
          Сообщение:
        </Title>
        <Value>
          {`#${data.key}`}
        </Value>
      </LargeCardTitle>
      <LargeCardTitle sub>
        <Title>
          Категория:
        </Title>
        <Value>
        {data.messageCategory}
        </Value>
      </LargeCardTitle>
      <LargeCardTitle sub>
      <Title>
          Адрес:
        </Title>
        <Value>
        {data.address}
        </Value>
      </LargeCardTitle>
      <LargeCardTitle sub>
        <Title>
          Статус:
        </Title>
        <LargeCardDescription color={getColorByStatus(data.status)}>
          {data.status}
        </LargeCardDescription>
      </LargeCardTitle>
      <LargeCardChat>
        {renderQuestionBlock()}
        {data.appealResponse && data.appealResponse.date && renderAnswerBlock()}
      </LargeCardChat>
    </ColumnLargeCard>
  )

  if (isMobile) {
    return (
      <ColumnContainer >
        <CardsContainer isMobile>
        {large ? renderNotificationBlock() : renderNotifications()}
        </CardsContainer>
      </ColumnContainer>
    )
  }

  return (
    <ColumnContainer large={large}>
      {renderHeader()}
      <CardsContainer>
        {large ? renderNotificationBlock() : renderNotifications()}
      </CardsContainer>
    </ColumnContainer>
  )
}

NotificationsColumn.propTypes = {
  large: pt.bool,
  title: pt.string,
  type: pt.string,
  onClose: pt.func,
  onNotificationClick: pt.func,
  data: pt.arrayOf(pt.shape({
    title: pt.string,
    number: pt.string,
  })),
}
NotificationsColumn.defaultProps = {
  large: false,
  title: null,
  type: null,
  onClose: noop,
  onNotificationClick: noop,
  data: [],
}

export default NotificationsColumn
