import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import generateWidget from './generateWidget'

export const ATMOSPHERE_PRESSURE = generateWidget({
  config: {
    id: 'ATMOSPHERE_PRESSURE',
    title: <Lang id="passportization.fields.generalInfo.atmospherePressure" />,
    units: <Lang id="widgets.pressureUnits" />,
  },
  selectorTemplate: 'atmospherePressure',
})

export const AIR_TEMPERATURE = generateWidget({
  config: {
    id: 'AIR_TEMPERATURE',
    title: <Lang id="passportization.fields.generalInfo.atmosphereTemperature" />,
    units: '°C',
  },
  selectorTemplate: 'atmosphereTemperature',
})
