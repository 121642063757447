import { SET_PASSPORTIZATION_DATA } from './index'

export const requestSetPassportizationData = (payload) => ({
  type: SET_PASSPORTIZATION_DATA.REQUEST,
  payload,
})

export const successSetPassportizationData = (payload) => ({
  type: SET_PASSPORTIZATION_DATA.SUCCESS,
  payload,
})

export const errorSetPassportizationData = (payload) => ({
  type: SET_PASSPORTIZATION_DATA.ERROR,
  payload,
})