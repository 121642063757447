import {
  ANNUALLY,
  MONTHLY,
  WEEKLY,
  DAILY,
} from './names'

export const BYTES = 'Bytes'
export const KB = 'KB'
export const MB = 'MB'
export const GB = 'GB'
export const TB = 'TB'
export const ZERO_BYTE = '0 Byte'

export const QRCODE_SIZE = 255

export const DEFAULT_COMMON_TOOLTIP_WIDTH = 100
export const DEFAULT_COMMON_TOOLTIP_HEIGHT = 25
export const DEFAULT_COMMON_TOOLTIP_DELTA = 20

export const DEFAULT_CELL_WIDTH = 130

export const SEC_TO_MILI_SEC = 1000
export const MIN_TO_MILI_SEC = 60 * SEC_TO_MILI_SEC
export const HOUR_TO_MILI_SEC = 60 * MIN_TO_MILI_SEC
export const DAY_TO_MILI_SEC = 24 * HOUR_TO_MILI_SEC

export const TYPE_TO_MILI_SEC = {
  [ANNUALLY]: null,
  [MONTHLY]: 366,
  [WEEKLY]: 92,
  [DAILY]: 31,
}

export const CHAR_LIMITS = {
  medium: 15,
}
