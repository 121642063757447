import {
  KULON,
  UNILIGHT,
  BRIZ,
  ONE_SIM,
  MESH,
  MULTIRATE_METER,
  ELECTRIC_METER,
  CONTROLLER,
  CONTACTOR,
  BRANCH_CIRCUIT,
  LAMP,
  PHASE,
  LIGHT_SENSOR,
  PHOTO_SENSOR,
  VOLUME_SENSOR,
  FIRE_SENSOR,
  TRANSFER_SWITCH,
  DMX_DEVICE,
  CONTROLLER_FUSE,
  FUSE,
  CHECKPOINT,
  CURRENT_SENSOR,
  GROUP,
  CONTROL_CUPBOARD,
  METEO,
  ROAD_TEMPERATURE_SENSOR,
  DOOR_OPEN_SENSOR,
  SMOKE_SENSOR,
  BASE_STATION,
  UTILITY_POLE,
  LINE,
  COMPANY_OBJECT,
  DEVICE,
  SWITCHING_GROUP,
  STREETLIGHT_CONTROL_CABINET,
  STREETLIGHT_GROUP,
  STREETLIGHT,
  WASTE_CONTAINER,
  WITHOUT_ASU,
  LIGHT_FIXTURE,
  LAMP_MODULE,
  PHASE_CIRCUIT_BREAKER,
  COLD_WATER_DEVICE,
  CENTRAL_HEATING_DEVICE,
  MODULE,
  SENSOR
} from '@/constants/instalationPassport/types'
import {
  ROOT,
  GEOZONE,
  PROJECT,
  INTEGRATION,
  OBJECT_ELEMENT
} from '@/constants/objectTypes'
import {
  ADMIN,
  CHECK_BOX,
  COMPANY,
  GROUP as VIEW_TREE_GROUP,
  GROUPS,
  INTEGRATION_SYSTEM,
  INTEGRATION_ZONE,
  TENANT,
  USER,
  USERS_GROUP
} from '@/constants/viewTree'

export default {
  integrationType: {
    [KULON]: 'АСУНО "КУЛОН"',
    [BRIZ]: 'АСУ "БРИЗ"',
    [ONE_SIM]: 'АСУ "1СИМ"',
    [MESH]: 'АСУ "МЕШ"',
    [WITHOUT_ASU]: 'Без АСУ',
    [UNILIGHT]: 'АСУ "ЮНИЛАЙТ"',
  },
  objectTypes: {
    [BASE_STATION]: 'Базовая станция',
    [TRANSFER_SWITCH]: 'АВР',
    [GROUP]: 'Группа',
    [STREETLIGHT_GROUP]: 'Группа светильников',
    [SWITCHING_GROUP]: 'Группа ШУНО',
    [SMOKE_SENSOR]: 'Датчик задымленности',
    [VOLUME_SENSOR]: 'Датчик объема',
    [LIGHT_SENSOR]: 'Датчик освещенности',
    [DOOR_OPEN_SENSOR]: 'Датчик открытия двери',
    [FIRE_SENSOR]: 'Датчик пожара',
    [CURRENT_SENSOR]: 'Датчик тока',
    [ROAD_TEMPERATURE_SENSOR]: 'Датчик температуры дорожного полотна',
    [CONTACTOR]: 'Контактор',
    [CONTROLLER]: 'Контроллер',
    [CHECKPOINT]: 'Контрольная точка',
    [LAMP]: 'Лампа',
    [LINE]: 'Линия',
    [METEO]: 'Метеодатчик',
    [MULTIRATE_METER]: 'Многофункциональный счетчик',
    [MODULE]: 'Модуль',
    [LAMP_MODULE]: 'Модуль светильника',
    [WASTE_CONTAINER]: 'Мусорный бак',
    [COMPANY_OBJECT]: 'Объект',
    [UTILITY_POLE]: 'Опора',
    [BRANCH_CIRCUIT]: 'Отходящая линия',
    [FUSE]: 'Предохранитель',
    [CONTROLLER_FUSE]: 'Предохранитель контроллера',
    [LIGHT_FIXTURE]: 'Светильник',
    [COLD_WATER_DEVICE]: 'Узел учета ХВС',
    [CENTRAL_HEATING_DEVICE]: 'Узел учета ЦО',
    [STREETLIGHT]: 'Уличный светильник',
    [DEVICE]: 'Устройство',
    [PHASE]: 'Фаза',
    [PHASE_CIRCUIT_BREAKER]: 'Фазовый выключатель',
    [PHOTO_SENSOR]: 'Фотодатчик',
    [STREETLIGHT_CONTROL_CABINET]: 'ШУУО',
    [CONTROL_CUPBOARD]: 'ШУНО',
    [ELECTRIC_METER]: 'Электрический счетчик',
    [DMX_DEVICE]: 'Устройство DMX',
    [SENSOR]: 'Датчик',
    [ROOT]: 'Корневая геозона',
    [GEOZONE]: 'Геозона',
    [PROJECT]: 'Проект',
    [INTEGRATION]: 'Интеграция',
    [OBJECT_ELEMENT]: 'Объект контроля',
    [COMPANY]: 'Организация',
    [USERS_GROUP]: 'Группа пользователей',
    [VIEW_TREE_GROUP]: 'Группа',
    [GROUPS]: 'Группы',
    [USER]: 'Пользователь',
    [ADMIN]: 'Администратор',
    [TENANT]: 'Тенант',
    [INTEGRATION_SYSTEM]: 'Система',
    [INTEGRATION_ZONE]: 'Интеграция',
    [CHECK_BOX]: 'Чекбокс',
  },
  integrationGroups: {
    COMMON: 'Универсальные',
    LIGHTNING: 'Освещение',
    HEAT_SUPPLY: 'Теплоснабжение',
    WATER_SUPPLY: 'Водоснабжение',
    POWER: 'Электроэнергия',
  },
  tree: {
    schedules: 'Расписаний',
    objects: 'Объектов',
    unknown: 'неизвестно'
  }
}
