import { INTEGRATION_SYSTEM, INTEGRATION_ZONE } from '@/constants/viewTree'

import {
  COMMON,
  LIGHTNING,
  HEAT_SUPPLY,
  WATER_SUPPLY,
  POWER,
  INTEGRATION_SCHEMA_ID,
  INTEGRATION_SCHEMA_CHILD_ID,
  CONNECTION_SIGNAL,
} from '@/constants/syncModule'

import {
  KULON,
  BRIZ,
  ATM,
  MESH,
  UNILIGHT,
  WITHOUT_ASU
} from '@/constants/integrations'


// const integrationTypes = [
//   {
//     id: 1000,
//     tag: COMMON,
//     name: 'globalNames.integrationGroups.COMMON',
//     type: LIGHT,
//     isSync: true,
//   },
//   {
//     id: 1001,
//     tag: LIGHTNING,
//     name: 'globalNames.integrationGroups.LIGHTNING',
//     type: LIGHT,
//     isSync: true,
//   },
//   {
//     id: 1002,
//     tag: HEAT_SUPPLY,
//     name: 'globalNames.integrationGroups.HEAT_SUPPLY',
//     type: ATM,
//     isSync: true,
//   },
//   {
//     id: 1003,
//     tag: WATER_SUPPLY,
//     name: 'globalNames.integrationGroups.WATER_SUPPLY',
//     type: BRIZ,
//     isSync: true,
//   },
//   {
//     id: 1004,
//     tag: POWER,
//     name: 'globalNames.integrationGroups.POWER',
//     type: LIGHT,
//     isSync: true,
//   },
// ]

const integrationScheme = {
  id: INTEGRATION_SCHEMA_ID,
  name: 'СИСТЕМА',
  type: INTEGRATION_SYSTEM,
  toggled: true,
  status: 'ok',
  isSync: true,
  children: [
    {
      id: INTEGRATION_SCHEMA_CHILD_ID,
      name: 'Новая интеграция',
      type: INTEGRATION_ZONE,
      status: 'ok',
      isSync: true,
      syncStatus: CONNECTION_SIGNAL,
    },
  ],
}

const integrationOptions = [
  {
    id: KULON,
    name: 'globalNames.integrationType.KULON',
    type: KULON,
    tags: [COMMON, LIGHTNING],
    toggled: true,
    isSync: true,
    isIntegrationOption: true,
  },
  {
    id: MESH,
    name: 'globalNames.integrationType.MESH',
    type: MESH,
    tags: [COMMON, LIGHTNING],
    toggled: true,
    isSync: true,
    isIntegrationOption: true,
  },
  {
    id: BRIZ,
    name: 'globalNames.integrationType.BRIZ',
    type: BRIZ,
    tags: [COMMON, LIGHTNING, POWER],
    toggled: true,
    isSync: true,
    isIntegrationOption: true,
  },
  {
    id: ATM,
    name: 'globalNames.integrationType.ONE_SIM',
    type: ATM,
    tags: [COMMON, WATER_SUPPLY, HEAT_SUPPLY],
    toggled: true,
    isSync: true,
    isIntegrationOption: true,
  },
  {
    id: UNILIGHT,
    name: 'globalNames.integrationType.UNILIGHT',
    type: UNILIGHT,
    tags: [COMMON, LIGHTNING],
    toggled: true,
    isSync: true,
    controlType: 'АСУ',
    isIntegrationOption: true,
  },
  {
    id: WITHOUT_ASU,
    name: 'globalNames.integrationType.WITHOUT_ASU',
    type: WITHOUT_ASU,
    tags: [COMMON, LIGHTNING, WATER_SUPPLY, HEAT_SUPPLY],
    toggled: true,
    isSync: true,
    isIntegrationOption: true,
  },
]

export default {
  integrationScheme,
  integrationOptions,
}
