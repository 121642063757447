import React from 'react'
import {
  ApplicationsContainer,
  // StyledLink,
  Slide,
  Title,
  Count
} from './styles'

import {
  OK,
  WARNING,
  ERROR,
} from '@/constants/alarms'


import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

// import PagesIcons from '@/components/icons/mobileIcons'


              
              
const SwiperCytiPortal = ({counts, onClick, status}) => {

  const countAll = counts[WARNING]?.length + counts[OK]?.length + counts[ERROR]?.length || 0

  return (
    <ApplicationsContainer>
    <Swiper
      breakpoints={
        {
          300: {slidesPerView: 2},
          375: {slidesPerView: 3},
        }
      } 
      spaceBetween= {10}
    >
      <SwiperSlide>
        <Slide active={!status}>
          <Title onClick={() => onClick(null)}>
            Все
          </Title>
          <Count active={!status}>
            {countAll}
          </Count>
        </Slide>
      </SwiperSlide>
      <SwiperSlide>
        <Slide active={status === WARNING}>
        <Title onClick={() => onClick(WARNING)}>
            В процессе
          </Title>
          <Count active={status === WARNING}>
            {counts[WARNING]?.length}
          </Count>
        </Slide>
      </SwiperSlide>
      <SwiperSlide>
        <Slide active={status === ERROR} >
        <Title onClick={() => onClick(ERROR)}>
            Отказ
          </Title>
          <Count active={status === ERROR}>
          {counts[ERROR]?.length}
          </Count>
        </Slide>
        </SwiperSlide>
        <SwiperSlide>
        <Slide active={status === OK}>
        <Title onClick={() => onClick(OK)}>
            Успешно
          </Title>
          <Count active={status === OK}>
          {counts[OK]?.length}
          </Count>
        </Slide>
      </SwiperSlide>
    </Swiper>
    </ApplicationsContainer>
  )
}

export default SwiperCytiPortal