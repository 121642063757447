import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import get from 'lodash/get'
import numberWithSpaces from '@/helpers/numberWithSpaces'
import getIsTyumen from '@/helpers/isTyumen'
import getPinCount from '@/helpers/viewTree/getPinCount'
import getDeclension from '@/helpers/getDeclension'
import UsersIcons from '@/components/icons/users'
import TextControl from '@/components/controls/TextControl'
import DashboardFilter from '@/forms/DashboardFilter'
import WidgetsDashboardIcon from '@/components/icons/widgetsDashboard'
import useUrlParseAndPush from '@/hooks/useUrlParseAndPush'
import {
  seriesNamesDashboardHeat,
  seriesNamesDashboardLightning,
  graphLegendOutdoorLighting,
  graphLegendHeatSupply,
} from '@/constants/graph/dashBoardGraphs'

// widget configs
import tyumenOurHomeSmall from '@/constants/widgetsConfigs/tyumenOurHomeSmall'
import tyumenOurHomeBig from '@/constants/widgetsConfigs/tyumenOurHomeBig'
import automaticFaultReportingSmall from '@/constants/widgetsConfigs/automaticFaultReportingSmall'
import automaticFaultReportingBig from '@/constants/widgetsConfigs/automaticFaultReportingBig'
import ServiceDeskSmall from '@/constants/widgetsConfigs/ServiceDeskSmall'
import ServiceDeskBig from '@/constants/widgetsConfigs/ServiceDeskBig'

import { SUPPORT } from '@/constants/widgetsShop/names'

import {
  HEAT_SUPPLY,
  OUTDOOR_LIGHTING,
} from '@/constants/names'
import WidgetContainer from './components/WidgetContainer'
import UrbanProcesses from './components/UrbanProcesses'
import TechnicalCondition from './components/TechnicalCondition'
import DepreciationEquipment from './components/DepreciationEquipment'
import GraphPerformance from './components/GraphPerformance'
import PresentaionWidgetSmall from './components/PresentaionWidgetSmall'
import ObjectsCount from './components/ObjectsCount'
import PresentationWidgetBig from './components/PresentationWidgetBig'

import {
  Container,
  Main,
  ContentContainer,
  Left,
  Right,
  CombinedWidgets,
  SubContainer,
} from './styles'
import { fakeTree } from '@/data/mapTree/zoneConfig'
import PageSidebar from '@/components/regions/sidebars/PageSidebar'

const PROCESSES_WIDGET = 'PROCESSES_WIDGET'
const COUNT_WIDGET = 'COUNT_WIDGET'
const STATE_WIDGET = 'STATE_WIDGET'
const DETERIORATION_WIDGET = 'DETERIORATION_WIDGET'
const ALARMS_WIDGET = 'ALARMS_WIDGET'
const TYUMEN_WIDGET = 'TYUMEN_WIDGET'
const SD_WIDGET = 'SD_WIDGET'
const LIGHT_WIDGET = 'LIGHT_WIDGET'
const HEAT_WIDGET = 'HEAT_WIDGET'

const INFRASTRUCTURE = [
  {
    type: PROCESSES_WIDGET,
    name: 'Городские процессы',
  },
  {
    type: COUNT_WIDGET,
    name: 'Количество объектов',
  },
]

const STATE = [
  {
    type: STATE_WIDGET,
    name: 'Состояние объектов',
  },
  {
    type: DETERIORATION_WIDGET,
    name: 'Износ оборудования',
  },
]

const ALARMS = [
  {
    type: ALARMS_WIDGET,
    name: 'Автоматическая регистрация неисправностей',
  },
  {
    type: TYUMEN_WIDGET,
    name: 'Портал “Тюмень-НАШ ДОМ”',
  },
  {
    type: SD_WIDGET,
    name: 'Сервис Деск “Мой город”',
  },
]

const PERFORMANCE = [
  {
    type: LIGHT_WIDGET,
    name: 'Наружное освещение',
  },
  {
    type: HEAT_WIDGET,
    name: 'Теплоснабжение',
  },
  {
    type: ALARMS_WIDGET,
    name: 'Автоматическая регистрация неисправностей',
  },
  {
    type: TYUMEN_WIDGET,
    name: 'Портал “Тюмень-НАШ ДОМ”',
  },
  {
    type: SD_WIDGET,
    name: 'Сервис Деск “Мой город”',
  },
]

const DashboardApp = ({
  objectState,
  graphData,
  getObjectDataRequest,
  getServiceDeskWidgetRequest,
  serviceDeskWidget,
  selectedWidgets,
  selectedNode,
  getGraphData,
  setFakeNode,
  getTyumenOurHome,
  tyumenOurHomeData,
  isMenuPenned,
  isMenuOpen,
  getDepreciationEquipmentRequest,
  depreciationEquipment,
  getAutomaticReportingRequest,
  automaticReporting,
  user,
  requestState,
  getInfrastructureCountRequest,
  infrastructureCount,
  globalFilters
}) => {
  const hasServiceDescWidget = selectedWidgets.some(({ type }) => type === SUPPORT)
  const [search, setSearch] = useState()
  const [formValues, setFormValues] = useUrlParseAndPush()
  const userCanSeeTyumenOurHome = useMemo(() => getIsTyumen(selectedNode), [selectedNode])

  useEffect(() => {
    if (selectedNode && selectedNode.id) {
      getAutomaticReportingRequest({ formValues, selectedNode })
      getInfrastructureCountRequest(selectedNode)
      getServiceDeskWidgetRequest(formValues)
      getTyumenOurHome(formValues)
    }
  }, [
    selectedNode,
    formValues,
    getTyumenOurHome,
    getServiceDeskWidgetRequest,
    getAutomaticReportingRequest,
    getInfrastructureCountRequest
  ])

  const setMaskToWidgets = useCallback((widgets) => (
    widgets.filter((widget) => {
      if (widget.type === TYUMEN_WIDGET && !userCanSeeTyumenOurHome) {
        return false
      }
      if (widget.type === SD_WIDGET && !hasServiceDescWidget) {
        return false
      }
      return true
    })
  ), [userCanSeeTyumenOurHome, hasServiceDescWidget])

  const renderCustomTitle = useCallback(() => (
    <>
      <Lang id="widgetsDashboard.infrastructure" />
      {numberWithSpaces(getPinCount(selectedNode))}
      {' '}
      {getDeclension(numberWithSpaces(getPinCount(selectedNode)), ['объект', 'объекта', 'объектов'])}
      <Lang id="widgetsDashboard.objectsConnected" />
    </>
  ), [selectedNode])

  const viewTreeOnSelect = (node, actionType) => {
    if (actionType === 'click') {
      setFakeNode(node)
    }
    return null
  }

  const handleSearchChange = (value) => {
    setSearch(value)
  }

  return (
    <Container>
      <PageSidebar
        title={(
          <>
            <Lang id="widgetsDashboard.title" />
            {selectedNode && selectedNode.id ? `: ${selectedNode.name}` : ''}
          </>
        )}
        treeId={'fake-tree'}
        treeData={fakeTree}
        onSelect={viewTreeOnSelect}
        searchQuery={search}
        globalFilters={globalFilters}
        headerContent={(
          <Lang id="mapsPage.titles.search">
            {(placeholder) => (
              <TextControl
                dark
                placeholder={placeholder}
                name="search"
                icon={UsersIcons.MagnifierIcon}
                onChange={handleSearchChange}
                value={search}
              />
            )}
          </Lang>
        )}
      />
      <Main>
        <DashboardFilter setFormValues={setFormValues} selectedNode={selectedNode} />
        <ContentContainer isMenuOpen={isMenuOpen || isMenuPenned}>
          <Left>
            <CombinedWidgets>
              <WidgetContainer
                fit
                row
                title={renderCustomTitle()}
                widgets={INFRASTRUCTURE}
              >
                {(enabledWidgets) => (
                  <>
                    {enabledWidgets.includes(PROCESSES_WIDGET) && (
                      <UrbanProcesses user={user} />
                    )}
                    {enabledWidgets.includes(COUNT_WIDGET) && (
                      <ObjectsCount infrastructureCount={infrastructureCount} />
                    )}
                  </>
                )}
              </WidgetContainer>
              <WidgetContainer
                fit
                title={<Lang id="widgetsDashboard.technicalCondition" />}
                widgets={STATE}
              >
                {(enabledWidgets) => (
                  <>
                    {enabledWidgets.includes(STATE_WIDGET) && (
                      <TechnicalCondition
                        data={objectState}
                        requestState={requestState.objectState}
                        getDataRequest={getObjectDataRequest}
                        selectedNode={selectedNode}
                        formValues={formValues}
                      />
                    )}
                    {enabledWidgets.includes(DETERIORATION_WIDGET) && (
                      <DepreciationEquipment
                        data={depreciationEquipment}
                        requestState={requestState.depreciationEquipment}
                        getDataRequest={getDepreciationEquipmentRequest}
                        selectedNode={selectedNode}
                        formValues={formValues}
                      />
                    )}
                  </>
                )}
              </WidgetContainer>
            </CombinedWidgets>
            <WidgetContainer
              title={<Lang id="widgetsDashboard.performanceIndicators" />}
              widgets={setMaskToWidgets(PERFORMANCE)}
            >
              {(enabledWidgets) => (
                <>
                  {enabledWidgets.includes(LIGHT_WIDGET) && (
                    <GraphPerformance
                      data={get(graphData, OUTDOOR_LIGHTING, [])}
                      seriesNames={seriesNamesDashboardLightning}
                      icon={<WidgetsDashboardIcon.OutdoorLightingIcon />}
                      title={<Lang id="widgetsDashboard.outdoorLighting" />}
                      graphLegendPayload={graphLegendOutdoorLighting}
                      requestState={requestState.graphData[OUTDOOR_LIGHTING]}
                      getDataRequest={getGraphData}
                      formValues={formValues}
                      selectedNode={selectedNode}
                      type={OUTDOOR_LIGHTING}
                    />
                  )}
                  {enabledWidgets.includes(HEAT_WIDGET) && (
                    <GraphPerformance
                      data={get(graphData, HEAT_SUPPLY, [])}
                      seriesNames={seriesNamesDashboardHeat}
                      icon={<WidgetsDashboardIcon.HeatSupplyIcon />}
                      title={<Lang id="widgetsDashboard.heatSupply" />}
                      graphLegendPayload={graphLegendHeatSupply}
                      requestState={requestState.graphData[HEAT_SUPPLY]}
                      getDataRequest={getGraphData}
                      formValues={formValues}
                      selectedNode={selectedNode}
                      type={HEAT_SUPPLY}
                    />
                  )}
                  <SubContainer>
                    {enabledWidgets.includes(ALARMS_WIDGET) && (
                      <PresentaionWidgetSmall
                        data={automaticReporting}
                        {...automaticFaultReportingSmall}
                        requestState={requestState.automaticReporting}
                      />
                    )}
                    {enabledWidgets.includes(TYUMEN_WIDGET)
                      && userCanSeeTyumenOurHome
                      && (
                        <PresentaionWidgetSmall
                          {...tyumenOurHomeSmall}
                          data={tyumenOurHomeData}
                          requestState={requestState.tyumenOurHome}
                        />
                      )}
                    {enabledWidgets.includes(SD_WIDGET) && (
                      <PresentaionWidgetSmall
                        data={serviceDeskWidget}
                        {...ServiceDeskSmall}
                        requestState={requestState.serviceDeskWidget}
                      />
                    )}
                  </SubContainer>
                </>
              )}
            </WidgetContainer>
          </Left>
          <Right>
            <WidgetContainer
              fit
              vertical
              title={<Lang id="widgetsDashboard.requestsAndAlarms" />}
              widgets={setMaskToWidgets(ALARMS)}
            >
              {(enabledWidgets) => (
                <>
                  {enabledWidgets.includes(ALARMS_WIDGET) && (
                    <PresentationWidgetBig
                      data={automaticReporting}
                      {...automaticFaultReportingBig}
                      requestState={requestState.automaticReporting}
                    />
                  )}
                  {enabledWidgets.includes(TYUMEN_WIDGET)
                    && userCanSeeTyumenOurHome
                    && (
                      <PresentationWidgetBig
                        data={tyumenOurHomeData}
                        {...tyumenOurHomeBig}
                        requestState={requestState.tyumenOurHome}
                      />
                    )}
                  {enabledWidgets.includes(SD_WIDGET) && (
                    <PresentationWidgetBig
                      data={serviceDeskWidget}
                      {...ServiceDeskBig}
                      requestState={requestState.serviceDeskWidget}
                    />
                  )}
                </>
              )}
            </WidgetContainer>
          </Right>

        </ContentContainer>
      </Main>
    </Container>
  )
}

DashboardApp.propTypes = {
  tree: pt.objectOf(pt.object),
  selectedNode: pt.objectOf(pt.object),
  objectState: pt.objectOf(pt.object),
  requestData: pt.func,
  setNode: pt.func,
  isMenuPenned: pt.bool,
  isMenuOpen: pt.bool,
  requestState: pt.shape({
    objectState: pt.string,
    serviceDeskWidget: pt.string,
    graphData: pt.string,
    tyumenOurHome: pt.string,
    depreciationEquipment: pt.string,
    automaticReporting: pt.string,
  }),
}
DashboardApp.defaultProps = {
  tree: {},
  requestData: noop,
  objectState: {},
  selectedNode: {},
  setNode: noop,
  isMenuPenned: false,
  isMenuOpen: false,
  requestState: {},
}

export default DashboardApp
