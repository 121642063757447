export default {
  titles: {
    add: 'Добавить группу объектов',
    create: 'Добавление\nгруппы',
    edit: 'Редактирование\nгруппы',
    filters: 'Фильтры',
    schedule: 'Расписание',
    passportization: 'Паспортизация',
    control: 'Управление',
    verification: 'Верификация',
  },
  popup: {
    yes: 'Да',
    no: 'Нет',
    accept: 'Принять',
    executeImpossible: 'Невозможно выполнить!',
    attention: 'Внимание!',
    createObjectsGroupValidationMessage: 'Для создания группы объектов необходимо установить все обязательные параметры',
    editObjectsGroupValidationMessage: 'Для сохранения изменений в группе объектов необходимо установить все обязательные параметры',
    cancelCreateObjectsGroupMessage: 'Вы действительно хотите отменить создание группы объектов?',
    cancelEditObjectsGroupMessage: 'Вы действительно хотите отменить внесенные изменения в группу объектов?',
    resetSchedule: 'Отключить расписание?',
    resetScheduleMessage: 'Введите комментарий-обоснование для отмены активного расписания для группы',
  },
  form: {
    name: 'Название группы',
    color: 'Цвет группы',
    objects: 'Список объектов для выбора',
    reset: 'Сбросить'
  },
  filters: {
    street: 'Улица',
    registryNumber: 'Реестровый номер',
    objectType: 'Тип объекта',
    vols: 'ВОЛС',
    utilityPoleType: 'Тип опоры',
    lightFixtureType: 'Тип светильника',
    pedestrianCrossingSign: 'Признак пешеходного перехода',
    controlCupboard: 'ШУНО',
    networkCompany: 'Балансодержатель',
    streetPlaceholder: 'Выберите улицу',
    registryNumberPlaceholder: 'Выберите реестровый номер',
    objectTypePlaceholder: 'Выберите тип объекта',
    volsPlaceholder: 'Выберите ВОЛС',
    utilityPoleTypePlaceholder: 'Выберите тип опоры',
    lightFixtureTypePlaceholder: 'Выберите тип светильника',
    pedestrianCrossingSignPlaceholder: 'Выберите признак пешеходного перехода',
    controlCupboardPlaceholder: 'Выберите ШУНО',
    networkCompanyPlaceholder: 'Выберите балансодержателя',
  },
  schedule: {
    label: 'Выбрать расписание:',
    placeholder: 'Не выбрано',
    warning: 'Данный функционал не доступен для выбранной группы объектов. Проверьте, что в группу входят только РМ или только ШУНО.',
    set: 'Установить',
    reset: 'Отключить расписание',
    emptyStateMessage: 'Нет доступных вариантов расписания',
    setScheduleErrorMessage1: 'Внимание! Нельзя установить данное расписание. Дополните состав группы',
    setScheduleErrorMessage2: 'новыми объектами, которые должны работать по выбранному расписанию.',
  },
  verification: {
    label: 'Статус:',
    placeholder: 'Не выбран',
    warning: 'Данный функционал не доступен для выбранной группы объектов. Проверьте, что в группу входят только объекты со статусом "Новый", "Не проверен", "Проверен"',
    verify: 'Верифицировать',
    checkAll: 'Проверить все'
  },
  verificationStatus: {
    INSTALLED: 'Установлен',
    VERIFIED: 'Проверен',
    NOT_EXIST: 'Не существует',
    NOT_INSTALLED: 'Не установлен',
    UNINSTALLED: 'Демонитован',
    NEW: 'Новый',
    NOT_VERIFIED: 'Не проверен',
  },
  control: {
    labelRM: 'Уровень диммирования:',
    labelSHUNO: 'Состояние:',
    labelPhase: 'Фаза:',
    placeholderState: 'Не выбрано',
    placeholderPhase: 'Не выбрана',
    warning: 'Данный функционал не доступен для выбранной группы объектов. Проверьте, что в группу входят только РМ или только ШУНО',
    apply: 'Применить для всех',
    cancel: 'Отмена команды',
    on: 'Включено',
    off: 'Выключено',
    status: {
      set: 'Поставлена...',
      sent: 'Отправлена...',
      received: 'Получена...',
      completed: 'Выполнена успешно',
      failed: 'Сбой выполнения',
      canceled: 'Отменена',
    }
  },
  passportization: {
    warning: 'Для внесения изменений в совпадающие поля паспорта выбранной группы объектов выберите поле паспорта и введите новое значение.',
    labelField: 'Поле паспорта:',
    labelValue: 'Значение:',
    placeholder: 'Не выбрано',
    apply: 'Применить для всех',
    change: 'Изменить поле',
    save: 'Сохранить',
  }
}