import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getDictionaries = createSelector(
    getState,
    (state) => get(state, 'dictionaries', {}),
)

export const getSearchFieldsOptions = createSelector(
    getDictionaries,
    (dictionaries) => get(dictionaries, 'searchFieldsOptions', []),
)

export const getSearchFieldsOptionsData = createSelector(
    getSearchFieldsOptions,
    (dictionaries) => get(dictionaries, 'data', []),
)

export const getSearchFieldsOptionsLoading = createSelector(
    getSearchFieldsOptions,
    (dictionaries) => get(dictionaries, 'isLoading', []),
)