import createAction from '@/helpers/redux/createAction'

export const CREATE_GEOZONE = createAction('@/INSTALLATION_PAGE_CREATE_GEOZONE')
export const EDIT_GEOZONE = createAction('@/INSTALLATION_PAGE_EDIT_GEOZONE')
export const DELETE_GEOZONE = createAction('@/INSTALLATION_PAGE_DELETE_GEOZONE')
export const GET_ROOT_ELEMENTS = createAction('@/INSTALLATION_PAGE_GET_ROOT_ELEMENTS')
export const GET_TREE_ELEMENTS = createAction('@/INSTALLATION_PAGE_GET_TREE_ELEMENTS')
export const GET_ROOT_CHILD = createAction('@/INSTALLATION_PAGE_GET_ROOT_CHILD')
export const GET_CARD = createAction('@/INSTALLATION_PAGE_GET_CARD')
export const GET_OBJECT_TELEMETRY = createAction('@/GET_OBJECT_TELEMETRY')
export const GET_NODE_STATISTIC = createAction('@/GET_NODE_STATISTIC')
export const CREATE_PROJECT = createAction('@/INSTALLATION_PAGE_CREATE_PROJECT')
export const EDIT_PROJECT = createAction('@/INSTALLATION_PAGE_EDIT_PROJECT')
export const DELETE_PROJECT = createAction('@/INSTALLATION_PAGE_DELETE_PROJECT')
export const DELETE_PROJECT_CUSTOM_FIELD = createAction('@/INSTALLATION_PAGE_DELETE_PROJECT_CUSTOM_FIELD')
export const CREATE_OBJECT = createAction('@/INSTALLATION_PAGE_CREATE_OBJECT')
export const EDIT_OBJECT = createAction('@/INSTALLATION_PAGE_EDIT_OBJECT')
export const DELETE_OBJECT = createAction('@/INSTALLATION_PAGE_DELETE_OBJECT')
export const CREATE_INTEGRATION = createAction('@/INSTALLATION_PAGE_CREATE_INTEGRATION')
export const EDIT_INTEGRATION = createAction('@/INSTALLATION_PAGE_EDIT_INTEGRATION')
export const DELETE_INTEGRATION = createAction('@/INSTALLATION_PAGE_DELETE_INTEGRATION')

export const GET_INTEGRATION_TYPES = createAction('@/INSTALLATION_PAGE_GET_INTEGRATION_TYPES')
export const GET_OBJECT_TYPES = createAction('@/INSTALLATION_PAGE_GET_OBJECT_TYPES')
export const GET_TENANT_PARENTS = createAction('@/INSTALLATION_PAGE_GET_TENANT_PARENTS')
export const GET_OBJECT_SCHEME = createAction('@/INSTALLATION_PAGE_GET_OBJECT_SCHEME')
export const DOWNLOAD_MULTIPLE_OBJECT = createAction('@/INSTALLATION_PAGE_DOWNLOAD_MULTIPLE_OBJECT')
export const DOWNLOAD_MULTIPLE_OBJECT_PROGRESS = createAction('@/INSTALLATION_PAGE_DOWNLOAD_MULTIPLE_OBJECT_PROGRESS')
export const GET_OBJECT_VALIDATION_SCHEME = createAction('@/INSTALLATION_PAGE_GET_OBJECT_VALIDATION_SCHEME')
export const UPLOAD_INSTALLATION_OBJECT_FILE = createAction('@/INSTALLATION_PAGE_UPLOAD_INSTALLATION_OBJECT_FILE')
export const DELETE_INSTALLATION_OBJECT_FILE = createAction('@/INSTALLATION_PAGE_DELETE_INSTALLATION_OBJECT_FILE')
export const GENERATE_INSTALLATION_OBJECT_FILE = createAction('@/INSTALLATION_PAGE_GENERATE_INSTALLATION_OBJECT_FILE')
export const GET_INSTALLATION_OBJECT_FILE = createAction('@/INSTALLATION_PAGE_GET_INSTALLATION_OBJECT_FILE')
export const GET_ALL_INTEGRATIONS = createAction('@/INSTALLATION_PAGE_GET_ALL_INTEGRATIONS')
export const VERIFY_OBJECT = createAction('@/INSTALLATION_PAGE_VERIFY_OBJECT')
export const DOWNLOAD_MULTIPLE_OBJECT_SCHEME = createAction('@/INSTALLATION_DOWNLOAD_MULTIPLE_OBJECT_SCHEME')
export const UPLOAD_FILE_NOTIFICATION = createAction('@/INSTALLATION_UPLOAD_FILE_NOTIFICATION')
export const GET_PINS_AND_ZONES_WITH_TELEMENTRY = createAction('@/INSTALLATION_GET_PINS_AND_ZONES_WITH_TELEMETRY')
export const GET_OBJECT_CONNECTED_IDENTIFIERS = createAction('@/INSTALLATION_GET_OBJECT_CONNECTED_IDENTIFIERS')
export const GET_CONNECTED_LINES_COORDINATES = createAction('@/INSTALLATION_GET_CONNECTED_LINES_COORDINATES')
export const SET_STREAM_URL = createAction('@/SET_STREAM_URL')
export const GET_STREAM_URL = createAction('@/GET_STREAM_URL')

export const SLECTED_ELEMENT_PARAMETRS = '@/INSTALLATION_SELECTED_ELEMENT_PARAMETERS'
export const SET_NEW_TREE = '@/INSTALLATION_PAGE_SET_NEW_TREE'
export const SET_NODE = '@/INSTALLATION_PAGE_SET_NODE'
export const SET_PINS = '@/INSTALLATION_PAGE_SET_PINS'
export const SET_CHILD = '@/INSTALLATION_PAGE_SET_CHILD'
export const SET_ROOT = '@/INSTALLATION_PAGE_SET_ROOT'
export const SET_CARD = '@/INSTALLATION_PAGE_SET_CARD'
export const SET_MAP_CENTER = '@/INSTALLATION_PAGE_SET_MAP_CENTER'
export const SET_MAP_ZOOM = '@/INSTALLATION_PAGE_SET_MAP_ZOOM'
export const SET_URL_PARAMS = '@/INSTALLATION_PAGE_SET_URL_PARAMS'
export const SET_REQUEST_STATUS = '@/INSTALLATION_PAGE_SET_REQUEST_STATUS'
export const SET_PINS_AND_ZONES_REQUEST_STATUS = '@/SET_PINS_AND_ZONES_REQUEST_STATUS'

export const setRequestStreamUrl = (payload) => ({
  type: SET_STREAM_URL.REQUEST,
  payload,
})

export const setSuccessStreamUrl = (payload) => ({
  type: SET_STREAM_URL.SUCCESS,
  payload,
})

export const getRequestStreamUrl = (payload) => ({
  type: GET_STREAM_URL.REQUEST,
  payload,
})

export const getSuccessStreamUrl = (payload) => ({
  type: GET_STREAM_URL.SUCCESS,
  payload,
})

export const setTree = (payload) => ({
  type: SET_NEW_TREE,
  payload,
})

export const setRequestStatus = (payload) => ({
  type: SET_REQUEST_STATUS,
  payload,
})

export const setNode = (payload) => ({
  type: SET_NODE,
  payload,
})

export const setPins = (payload) => ({
  type: SET_PINS,
  payload,
})

export const setChild = (payload) => ({
  type: SET_CHILD,
  payload,
})

export const setRoot = (payload) => ({
  type: SET_ROOT,
  payload,
})

export const setCard = (payload) => ({
  type: SET_CARD,
  payload,
})

export const setMapCenter = (payload) => ({
  type: SET_MAP_CENTER,
  payload,
})

export const setMapZoom = (payload) => ({
  type: SET_MAP_ZOOM,
  payload,
})

export const setSelectedElementParams = (payload) => ({
  type: SLECTED_ELEMENT_PARAMETRS,
  payload,
})

export const setDisplayElementAndTelemetryRequestStatus = (payload) => ({
  type: SET_PINS_AND_ZONES_REQUEST_STATUS,
  payload,
})
