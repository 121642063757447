import {
  put, takeLatest,call
} from 'redux-saga/effects'
import get from 'lodash/get'
import { GEOZONE } from '@/constants/objectTypes'
import { successDeleteProject } from '@/store/actions/installation/deleteProject'
import getNewPath from '@/helpers/getNewPath'
import request from '@/helpers/axios'
import { DELETE_PROJECT } from '@/store/actions/installation'
import { generateDeleteTreeElement } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { treeHandlers } from 'react-hyper-tree'
import updateNodeCount from '@/helpers/updateNodeCount'
import { setGlobalLoading } from '@/store/actions/appSettings'
import { requestGetPinsAndZonesAndTelemetry } from '@/store/actions/installation/getZonesAndPinsAndTelemetry'

function* deleteProjectSaga({ payload }) {
  const {
    setUrlFormValues, formValues, parentId
  } = payload
  const {
    id,
    path,
    rootId,
    FILTER
  } = formValues
  try {
    yield put(setGlobalLoading(true))
    yield request({
      url: generateDeleteTreeElement(id),
      method: 'delete',
    })
    const newPath = getNewPath(path)
    const toast = createNotifications()
    const tree = treeHandlers.trees['installations-tree']
    const parent = tree.instance.getNodeById(parentId)
    const parentChildren = yield call(parent.data.getChildren, parent)
    const node = tree.instance.getNodeById(id)
    tree.handlers.setRawChildren(parent, parentChildren, 'first', true)
    updateNodeCount(tree, newPath, 'remove', node.data.childrenCount + 1)
    const selectedNodePath = get(parent, 'options.path', '')
    tree.handlers.setSelectedByPath(selectedNodePath)
    setUrlFormValues({
      id: parentId,
      rootId,
      parentTreeId: parentId,
      path: newPath,
      type: GEOZONE,
      FILTER,
    })
    yield put(requestGetPinsAndZonesAndTelemetry({
      includeAll: true,
      parentTreeId: path.split('/')[2]
    }))
    yield put(setGlobalLoading(false))
    toast({
      title: 'Удаление проекта',
      type: 'success',
      description: 'Проект успешно удален.',
    })
    yield put(successDeleteProject())
  } catch (error) {
    yield put(setGlobalLoading(false))
    const toast = createNotifications()
    const errorMessage = get(error, 'response.data.errorMessage', null)
    if (errorMessage === 'Can not delete installation, because installation has attached asu') {
      toast({
        title: 'ОШИБКА ОПЕРАЦИИ!',
        type: 'error',
        description: 'Одна или несколько интеграций подключена к асу,\nперед удалением отключите их.',
      })
    }
    setUrlFormValues({ FILTER })
  }
}

export default function* root() {
  yield takeLatest(DELETE_PROJECT.REQUEST, deleteProjectSaga)
}
