import React from 'react'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { ConnectedRouter } from 'connected-react-router'
import { ToastContainer } from 'react-toastify'
import { store, persistor, history } from '@/store/store'
import ThemeProviderWrapper from '@/wrappers/ThemeProviderWrapper'
import IntlProviderWrapper from '@/wrappers/IntlProviderWrapper'
import { setupAxios } from '@/helpers/axios'

import 'react-toastify/dist/ReactToastify.css'

import PublicRoutes from './components/PublicRoutes'

setupAxios(store)

const AppContainer = () => {   
  return (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProviderWrapper>
        <div className="app-container" id="app-wrapper">
          <IntlProviderWrapper>
            <ConnectedRouter history={history}>
              <PublicRoutes />
            </ConnectedRouter>
          </IntlProviderWrapper>
        </div>
        <ToastContainer
          position="top-right"
          autoClose={10000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnVisibilityChange
          draggable
          pauseOnHover
          closeButton={false}
        />
      </ThemeProviderWrapper>
    </PersistGate>
  </Provider>
)}

export default AppContainer
