export default {
  collapse: 'Свернуть',
  openMenu: ' Открыть меню',
  deCollapse: 'Развернуть',
  open: 'Открыть',
  close: 'Закрыть',
  find: 'Найти',
  search: 'Поиск',
  filter: 'Фильтр',
  list: 'Список',
  help: 'Помощь',
  options: 'Опции',
  notification: 'Уведомления',
  settings: 'Настройки',
  profile: 'Профиль',
  first: 'Первая страница',
  prev: 'Предыдущая страница',
  next: 'Следующая страница',
  last: 'Последняя страница',
  refresh: 'Обновить',
  graph: 'График',
  table: 'Таблица',
  build: 'Построить',
  createGroup: 'Создать группу',
  createTenant: 'Создать тенанта',
  grid: 'Сетка',
  addProcess: 'Добавить процесс',
  remove: 'Удалить',
  fieldSettings: 'Настройка полей',
  passport: 'Паспорт',
  download: 'Скачать',
  warning: 'предупреждение',
  warningOn: 'Включить оповещение',
  warningOff: 'Отключить оповещение',
  tableRefresh: 'Обновить таблицу',
  graphRefresh: 'Обновить график',
  graphBuild: 'Построить график',
  tableBuild: 'Построить таблицу',
  closeFilter: 'Закрыть фильтр',
  closeList: 'Закрыть лист',
  openFilter: 'Открыть фильтр',
  openList: 'Открыть лист',
  createIntegration: 'Создать интеграцию',
  cancelIntegration: 'Отменить создание',
  cancel: 'Отменить',
  edit: 'Редактировать',
  erase: 'Очистить',
  move: 'Переместить',
  resetSearch: 'Сбросить результаты поиска',
  signOut: 'Выход из системы',
  online: 'На связи',
  offline: 'Не на связи',
  onTop: 'Наверх'
}
