import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_VERIFICATION_OPTIONS_URL } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { GET_VERIFICATION_OPTIONS } from '@/store/actions/objectsGroups'
import {
  errorGetVerificationOptions,
  successGetVerificationOptions
} from '@/store/actions/objectsGroups/getVerificationOptions'

function* getVerificationOptions({ payload }) {
  try {
    const { groupId, intl } = payload
    const response = yield request({
      url: GET_VERIFICATION_OPTIONS_URL(
          groupId
      ),
      method: 'get',
    })

    const formattedData = response.data.map(item => {
      return {
        title: intl.messages[`objectsGroups.verificationStatus.${item}`],
        value: item
      }
    })

    yield put(successGetVerificationOptions(formattedData))
  } catch (error) {
    console.log(error)
    yield put(errorGetVerificationOptions(error))
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось получить список доступных статусов. \nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* () {
  yield takeLatest(GET_VERIFICATION_OPTIONS.REQUEST, getVerificationOptions)
}
