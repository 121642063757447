import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import pt from 'prop-types'
import noop from 'lodash/noop'
import ACYWidgetForm from '@/forms/ACYWidgetForm'
import {
  Container,
  PrimaryTitle,
} from './styles'

const WidgetSetUpp = ({
  title,
  parametrName,
  onSubmit,
  onDenie,
  refreshTime,
}) => (
  <Container>
    <PrimaryTitle>
      {title}
    </PrimaryTitle>
    <ACYWidgetForm
      onDenie={onDenie}
      onSubmit={onSubmit}
      parametrName={parametrName}
      refreshTime={refreshTime}
    />
  </Container>
)

WidgetSetUpp.defaultProps = {
  title: <Lang id="widgets.widgetSettings" />,
  parametrName: <Lang id="widgets.updateFrequency" />,
  onSubmit: noop,
  onDenie: noop,
  refreshTime: 0,
}

WidgetSetUpp.propTypes = {
  title: pt.string,
  refreshTime: pt.number,
  parametrName: pt.string,
  onSubmit: pt.func,
  onDenie: pt.func,
}

export default React.memo(WidgetSetUpp)
