import createIntegrationSubscriptionChannel from '@/store/sagas/createIntegrationSubscriptionChannel'
import createSubscriptionChannel from '@/store/sagas/createSubscriptionChannel'

import {
  TOPIC_CREATION_NOTIFICATIONS,
  generateUpdateIntegrationTopic,
  generateRepeatIntegrationTopic,
  generateDeleteIntegrationTopic,
  generateVerifyIntegrationTopic,
  generateIntegrationVerificationProgressTopic,
} from '@/constants/socket/events'

export const createIntegrationsChannel = () => (
  createSubscriptionChannel(TOPIC_CREATION_NOTIFICATIONS)
)
export const updateIntegrationsChannel = () => (
  createIntegrationSubscriptionChannel(generateUpdateIntegrationTopic)
)
export const deleteIntegrationsChannel = () => (
  createIntegrationSubscriptionChannel(generateDeleteIntegrationTopic)
)
export const repeatIntegrationsChannel = () => (
  createIntegrationSubscriptionChannel(generateRepeatIntegrationTopic)
)
export const verifyIntegrationChannel = () => (
  createIntegrationSubscriptionChannel(generateVerifyIntegrationTopic)
)
export const integrationVerificationProgressChannel = () => (
  createIntegrationSubscriptionChannel(generateIntegrationVerificationProgressTopic)
)
