import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import generateWidget from './generateWidget'

export const LIGHT_LEVEL = generateWidget({
  config: {
    id: 'LIGHT_LEVEL',
    title: <Lang id="widgets.illuminanceLevel" />,
    minValue: 0,
    maxValue: 100,
    units: '%',
  },
  selectorTemplate: 'illuminanceLevel',
})

export const LIGHT_LEVEL_KULON = generateWidget({
  config: {
    id: 'LIGHT_LEVEL_KULON',
    title: <Lang id="widgets.illuminanceLevel" />,
    minValue: 0,
    maxValue: 100,
    units: '%',
  },
  selectorTemplate: 'dim',
})

export const LIGHT_LEVEL_MULTI = generateWidget({
  config: {
    id: 'LIGHT_LEVEL_MULTI',
    title: <Lang id="widgets.illuminanceLevel" />,
    minValue: 0,
    maxValue: 100,
    units: '%',
    type: 'multiDimming'
  },
  selectorTemplate: 'dim',
})

// export const ENERGY_CONSUMED = generateWidget({
//   config: {
//     id: 'ENERGY_CONSUMED',
//     minValue: 0,
//     maxValue: 0,
//     units: 'кВт⋅ч',
//     settingsControll: false,
//     chartControll: false,
//   },
//   selectorTemplate: 'accumulatedEnergyAmountRate{number}',
//   titleTemplate: 'Потребление энергии по тарифу T{number}',
// })

export const ENERGY_CONSUMED_ARRAY = generateWidget({
  config: {
    id: 'ENERGY_CONSUMED_ARRAY',
    title: <Lang id="widgets.energyConsumption" />,
    minValue: 0,
    maxValue: 0,
    units: <Lang id="constants.graph.electricity" />,
  },
  dataTemplate: {
    title: 'T{number}',
    selector: 'accumulatedEnergyAmountRate{number}',
  },
})

export const ACTIVE_ENERGY_ARRAY = generateWidget({
  config: {
    id: 'ACTIVE_ENERGY_ARRAY',
    title: <Lang id="widgets.activeEnergy" />,
    minValue: 0,
    maxValue: 0,
    units: <Lang id="widgets.energyUnitsKilo" />,
  },
  dataTemplate: {
    title: 'T{number}',
    selector: 'aA{number}',
  },
})

export const REACTIVE_ENERGY_ARRAY = generateWidget({
  config: {
    id: 'REACTIVE_ENERGY_ARRAY',
    title: <Lang id="widgets.reactiveEnergy" />,
    minValue: 0,
    maxValue: 0,
    units: <Lang id="widgets.energyUnitsKilo" />,
  },
  dataTemplate: {
    title: 'T{number}',
    selector: 'rA{number}',
  },
})

export const VOLTAGE = generateWidget({
  config: {
    id: 'VOLTAGE',
    title: <Lang id="widgets.voltage" />,
    minValue: 187,
    maxValue: 253,
    units: <Lang id="widgets.voltageUnits" />,
  },
  selectorTemplate: 'voltage',
})

export const VOLTAGE_ARRAY = generateWidget({
  config: {
    id: 'VOLTAGE_ARRAY',
    title: <Lang id="widgets.voltage" />,
    units: <Lang id="widgets.voltageUnits" />,
    minValue: 187,
    maxValue: 253,
  },
  dataTemplate: {
    selector: 'phase{number}Voltage',
    title: 'U{number}',
  },
})

export const AMPERAGE = generateWidget({
  config: {
    id: 'AMPERAGE',
    title: <Lang id="widgets.current" />,
    minValue: 0,
    maxValue: 1000,
    units: <Lang id="widgets.currentUnits" />,
  },
  selectorTemplate: 'i',
})

export const AMPERAGE_ARRAY = generateWidget({
  config: {
    id: 'AMPERAGE_ARRAY',
    title: <Lang id="widgets.current" />,
    units: <Lang id="widgets.currentUnits" />,
  },
  dataTemplate: {
    selector: 'phase{number}Amperage',
    title: 'I{number}',
  },
})

// export const POWER = generateWidget({
//   config: {
//     id: 'POWER',
//     minValue: 0,
//     maxValue: 0,
//     units: 'Вт',
//   },
//   selectorTemplate: 'phase{number}Power',
//   titleTemplate: 'Мощность P{number}',
// })

export const POWER_ARRAY = generateWidget({
  config: {
    id: 'POWER_ARRAY',
    title: <Lang id="widgets.power" />,
    units: <Lang id="widgets.powerUnits" />,
  },
  dataTemplate: {
    selector: 'phase{number}Power',
    title: 'P{number}',
  },
})

export const FREQUENCY = generateWidget({
  config: {
    id: 'FREQUENCY',
    title: <Lang id="widgets.frequency" />,
    minValue: 49.5,
    maxValue: 50.5,
    units: <Lang id="widgets.frequencyUnits" />,
  },
  selectorTemplate: 'frequency',
})

export const PHASE_VOLTAGE_UA = generateWidget({
  config: {
    id: 'PHASE_VOLTAGE_UA',
    title: <Lang id="widgets.ua" />,
    minValue: 187,
    maxValue: 253,
    units: <Lang id="widgets.voltageUnits" />,
  },
  selectorTemplate: 'ua'
})

export const PHASE_VOLTAGE_UB = generateWidget({
  config: {
    id: 'PHASE_VOLTAGE_UB',
    title: <Lang id="widgets.ub" />,
    minValue: 187,
    maxValue: 253,
    units: <Lang id="widgets.voltageUnits" />,
  },
  selectorTemplate: 'ub'
})

export const PHASE_VOLTAGE_UC = generateWidget({
  config: {
    id: 'PHASE_VOLTAGE_UC',
    title: <Lang id="widgets.uc" />,
    minValue: 187,
    maxValue: 253,
    units: <Lang id="widgets.voltageUnits" />,
  },
  selectorTemplate: 'uc'
})

export const PHASE_CURRENTS_IA = generateWidget({
  config: {
    id: 'PHASE_CURRENTS_IA',
    title: <Lang id="widgets.ia" />,
    minValue: 0,
    maxValue: 1,
    units: <Lang id="widgets.currentUnits" />,
  },
  selectorTemplate: 'ia'
})

export const PHASE_CURRENTS_IB = generateWidget({
  config: {
    id: 'PHASE_CURRENTS_IB',
    title: <Lang id="widgets.ib" />,
    minValue: 0,
    maxValue: 1,
    units: <Lang id="widgets.currentUnits" />,
  },
  selectorTemplate: 'ib'
})

export const PHASE_CURRENTS_IC = generateWidget({
  config: {
    id: 'PHASE_CURRENTS_IC',
    title: <Lang id="widgets.ic" />,
    minValue: 0,
    maxValue: 1,
    units: <Lang id="widgets.currentUnits" />,
  },
  selectorTemplate: 'ic'
})

export const PHASE_POWER_FACTOR_CA = generateWidget({
  config: {
    id: 'PHASE_POWER_FACTOR_CA',
    title: <Lang id="widgets.ca" />,
    minValue: 0,
    maxValue: 1,
  },
  selectorTemplate: 'ca'
})
export const PHASE_POWER_FACTOR_CB = generateWidget({
  config: {
    id: 'PHASE_POWER_FACTOR_CB',
    title: <Lang id="widgets.cb" />,
    minValue: 0,
    maxValue: 1,
  },
  selectorTemplate: 'cb'
})
export const PHASE_POWER_FACTOR_CC = generateWidget({
  config: {
    id: 'PHASE_POWER_FACTOR_CC',
    title: <Lang id="widgets.cc" />,
    minValue: 0,
    maxValue: 1,
  },
  selectorTemplate: 'cc'
})

export const REACTIVE_POWER_PER_PHASE_QA = generateWidget({
  config: {
    id: 'REACTIVE_POWER_PER_PHASE_QA',
    title: <Lang id="widgets.qa" />,
    minValue: 0,
    maxValue: 20,
    units: <Lang id="widgets.reactivePowerUnits" />,
  },
  selectorTemplate: 'qa'
})

export const REACTIVE_POWER_PER_PHASE_QB = generateWidget({
  config: {
    id: 'REACTIVE_POWER_PER_PHASE_QB',
    title: <Lang id="widgets.qb" />,
    minValue: 0,
    maxValue: 20,
    units: <Lang id="widgets.reactivePowerUnits" />,
  },
  selectorTemplate: 'qb'
})
export const REACTIVE_POWER_PER_PHASE_QC = generateWidget({
  config: {
    id: 'REACTIVE_POWER_PER_PHASE_QC',
    title: <Lang id="widgets.qc" />,
    minValue: 0,
    maxValue: 20,
    units: <Lang id="widgets.reactivePowerUnits" />,
  },
  selectorTemplate: 'qc'
})

export const ACTIVE_POWER_PER_PHASE_PA = generateWidget({
  config: {
    id: 'ACTIVE_POWER_PER_PHASE_PA',
    title: <Lang id="widgets.pa" />,
    minValue: 0,
    maxValue: 10,
    units: <Lang id="widgets.powerUnits" />,
  },
  selectorTemplate: 'pa'
})
export const ACTIVE_POWER_PER_PHASE_PB = generateWidget({
  config: {
    id: 'ACTIVE_POWER_PER_PHASE_PB',
    title: <Lang id="widgets.pb" />,
    minValue: 0,
    maxValue: 10,
    units: <Lang id="widgets.powerUnits" />,
  },
  selectorTemplate: 'pb'
})
export const ACTIVE_POWER_PER_PHASE_PC = generateWidget({
  config: {
    id: 'ACTIVE_POWER_PER_PHASE_PC',
    title: <Lang id="widgets.pc" />,
    minValue: 0,
    maxValue: 10,
    units: <Lang id="widgets.powerUnits" />,
  },
  selectorTemplate: 'pc'
})

// export const ELECTRICAL_RESISTANCE = generateWidget({
//   config: {
//     id: 'ELECTRICAL_RESISTANCE',
//     minValue: 0,
//     maxValue: 0,
//     units: 'Ом',
//   },
//   selectorTemplate: 'phase{number}resistance',
//   titleTemplate: 'Электрическое сопротивление R{number}',
// })

export const ELECTRICAL_RESISTANCE_ARRAY = generateWidget({
  config: {
    id: 'ELECTRICAL_RESISTANCE_ARRAY',
    title: <Lang id="widgets.electricalResistance" />,
    units: <Lang id="widgets.resistanceUnits" />,
  },
  dataTemplate: {
    selector: 'phase{number}resistance',
    title: 'R{number}',
  },
})

export const LIGHT_STATUS = generateWidget({
  config: {
    id: 'LIGHT_STATUS',
    title: <Lang id="widgets.objectStatus" />,
    type: 'status',
    timeSelector: 'timestamp',
  },
})

export const CONTROLLER_STATUS = generateWidget({
  config: {
    id: 'CONTROLLER_STATUS',
    firstTitle: <Lang id="widgets.objectStatus" />,
    secondTitle: <Lang id="widgets.counterCommunication" />,
    type: 'controllerStatus',
    timeSelector: 'timestamp',
    firstStatusSelector: 'isConnected',
    secondStatusSelector: 'meterStatus',
  },
})

export const CONNECT_STATUS = generateWidget({
  config: {
    id: 'CONNECT_STATUS',
    title: <Lang id="widgets.connectionStatus" />,
    type: 'connectionStatus',
    statusSelector: 'isConnected',
  },
})

export const NETWORK_LEVEL = generateWidget({
  config: {
    id: 'NETWORK_LEVEL',
    title: <Lang id="widgets.networkLevel" />,
    minValue: -100,
    maxValue: 100,
  },
  selectorTemplate: 'signal'
})
export const ILLUMINATION = generateWidget({
  config: {
    id: 'ILLUMINATION',
    title: <Lang id="widgets.illumination" />,
    minValue: 0,
    maxValue: 100,
    units: <Lang id="widgets.illuminationUnits" />,
  },
  selectorTemplate: 'lux'
})
export const TEMPERATURE = generateWidget({
  config: {
    id: 'TEMPERATURE',
    title: <Lang id="widgets.temperature" />,
    minValue: 0,
    maxValue: 100,
    units: '°C',
  },
  selectorTemplate: 'temperature'
})

export const DATE = generateWidget({
  config: {
    id: 'DATE',
    title: <Lang id="widgets.readingDate" />,
  },
  selectorTemplate: 'date',
})

export const LIGHT_VOLTAGE = generateWidget({
  config: {
    id: 'LIGHT_VOLTAGE',
    title: <Lang id="widgets.lightVoltage" />,
    minValue: 187,
    maxValue: 253,
    units: <Lang id="widgets.voltageUnits" />,
  },
  selectorTemplate: 'u'
})
export const LIGHT_AMPERAGE = generateWidget({
  config: {
    id: 'LIGHT_AMPERAGE',
    title: <Lang id="widgets.lightCurrent" />,
    minValue: 0,
    maxValue: 10,
    units: <Lang id="widgets.currentUnits" />,
  },
  selectorTemplate: 'i'
})

export const LIGHT_POWER = generateWidget({
  config: {
    id: 'LIGHT_POWER',
    title: <Lang id="widgets.lightPower" />,
    minValue: 0,
    maxValue: 100,
    units: <Lang id="widgets.powerUnits" />,
  },
  selectorTemplate: 'p'
})

export const LIGHT_REACTIVE_POWER = generateWidget({
  config: {
    id: 'LIGHT_REACTIVE_POWER',
    title: <Lang id="widgets.lightReactivePower" />,
    minValue: 0,
    maxValue: 100,
    units: <Lang id="widgets.reactivePowerUnits" />,
  },
  selectorTemplate: 'q'
})
export const LIGHT_FULL_POWER = generateWidget({
  config: {
    id: 'LIGHT_FULL_POWER',
    title: <Lang id="widgets.lightFullPower" />,
    minValue: 0,
    maxValue: 100,
    units: <Lang id="widgets.powerUnits" />,
  },
  selectorTemplate: 's'
})
export const LIGHT_POWER_COEFFICIENT = generateWidget({
  config: {
    id: 'LIGHT_POWER_COEFFICIENT',
    title: <Lang id="widgets.lightPowerCoefficient" />,
    minValue: 0,
    maxValue: 100,
  },
  selectorTemplate: 'cos'
})

export const LIGHT_FREQUENCY = generateWidget({
  config: {
    id: 'LIGHT_FREQUENCY',
    title: <Lang id="widgets.lightFrequency" />,
    minValue: 0,
    maxValue: 100,
    units: <Lang id="widgets.frequencyUnits" />,
  },
  selectorTemplate: 'f'
})

export const ENERGY = generateWidget({
  config: {
    id: 'ENERGY',
    title: <Lang id="widgets.energy" />,
    minValue: 0,
    maxValue: 100,
    units: <Lang id="widgets.energyUnits" />,
  },
  selectorTemplate: 'a'
})

export const VOLTAGE_PEAKS = generateWidget({
  config: {
    id: 'VOLTAGE_PEAKS',
    title: <Lang id="widgets.voltagePeaks" />,
    minValue: 0,
    maxValue: 250,
    units: <Lang id="widgets.voltageUnits" />,
  },
  selectorTemplate: 'um'
})

export const AMPERAGE_PEAKS = generateWidget({
  config: {
    id: 'AMPERAGE_PEAKS',
    title: <Lang id="widgets.currentPeaks" />,
    minValue: 0,
    maxValue: 10,
    units: <Lang id="widgets.currentUnits" />,
  },
  selectorTemplate: 'im'
})
