import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'
import get from 'lodash/get'

export const scheduleManagerActivityLog = createSelector(
  getState,
  (state) => get(state, 'scheduleManagerActivityLog', {}),
)

export const getActivityLogTableData = createSelector(
  scheduleManagerActivityLog,
  (scheduleManagerActivityLogState) => get(scheduleManagerActivityLogState, 'data', []),
)

export const getActivityLogTableParameters = createSelector(
  scheduleManagerActivityLog,
  (scheduleManagerActivityLogState) => get(scheduleManagerActivityLogState, 'table', {}),
)

export const getActivityLoadingTable = createSelector(
  scheduleManagerActivityLog,
  (scheduleManagerActivityLogState) => get(scheduleManagerActivityLogState, 'loadingTable'),
)

export const getActivityDates = createSelector(
  scheduleManagerActivityLog,
  (scheduleManagerActivityLogState) => get(scheduleManagerActivityLogState, 'dates', {}),
)

export const getActivityLogStartDate = createSelector(
  getActivityDates,
  (scheduleManagerActivityLogState) => get(scheduleManagerActivityLogState, 'start', null),
)

export const getActivityLogEndDate = createSelector(
  getActivityDates,
  (scheduleManagerActivityLogState) => get(scheduleManagerActivityLogState, 'end', null),
)

export const getAction = createSelector(
  scheduleManagerActivityLog,
  (scheduleManagerActivityLogState) => get(scheduleManagerActivityLogState, 'action', null),
)