import React, { useMemo } from 'react'
import useUrlParseAndPush from '@/hooks/useUrlParseAndPush'
import {
  ApplicationsContainer,
  // StyledLink,
  Slide,
  Title,
  Count,
} from './styles'

// import { OBJECTS_URL, PASPORTIZATION_URL } from '@/constants/routes';


import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'
import { FormattedMessage as Lang } from 'react-intl'

// import PagesIcons from '@/components/icons/mobileIcons'


              
              
const SwiperSD = ({ infoCards }) => {
  const [urlValues, setUrlValues] = useUrlParseAndPush()

  const typeActive = useMemo(() => {
    if (urlValues.overdue ) return 'overdue'
    if (urlValues.status) return urlValues.status
    return 'all'
  }, [urlValues])

  const onClick = (value) => {
    if (value === 'overdue') {
      setUrlValues({widgetId: urlValues.widgetId, overdue : true})
      return null
    }
    if (value === 'all') {
      setUrlValues({widgetId: urlValues.widgetId})
      return null
    } 
    setUrlValues({ status : value, widgetId: urlValues.widgetId})
  }

  return (
  <ApplicationsContainer>
    <Swiper
      slidesPerView={'auto'}
      spaceBetween= {10}
      setWrapperSize={true}
    >
      <SwiperSlide>
        <Slide onClick={() => onClick('all')} active={typeActive === 'all'}>
        <Title>
          <Lang id={"widgetsDashboard.received"}/>
          </Title>
          <Count active={typeActive === 'all'}>
            {infoCards?.find((card) => card.type === 'received')?.value || 0}
          </Count>
        </Slide>
      </SwiperSlide>
      <SwiperSlide>
        <Slide onClick={() => onClick('completed')} active={typeActive === 'completed'}>
          <Title>
            <Lang id={"widgetsDashboard.completed"}/>
            </Title>
            <Count active={typeActive === 'completed'}>
            {infoCards?.find((card) => card.type === 'done')?.value || 0}
            </Count>
        </Slide>
      </SwiperSlide>
      <SwiperSlide>
        <Slide onClick={() => onClick('inProgress')} active={typeActive === 'inProgress'}>
          <Title>
            <Lang id={"widgetsDashboard.progress"}/>
          </Title>
          <Count active={typeActive === 'inProgress'}>
          {infoCards?.find((card) => card.type === 'tasksPerformed')?.value || 0}
          </Count>
        </Slide>
      </SwiperSlide>
      <SwiperSlide>
        <Slide onClick={() => onClick('waitingControl')} active={typeActive === 'waitingControl'}>
          <Title>
            <Lang id={"widgetsDashboard.waiting"}/>
          </Title>
          <Count active={typeActive === 'waitingControl'}>
          {infoCards?.find((card) => card.type === 'waitingForControl')?.value || 0}
          </Count>
        </Slide>
      </SwiperSlide>
      <SwiperSlide>
        <Slide onClick={() => onClick('overdue')} active={typeActive === 'overdue'}>
          <Title>
            <Lang id={"widgetsDashboard.overdue"}/>
          </Title>
          <Count active={typeActive === 'overdueOnExecution'}>
          {infoCards?.find((card) => card.type === 'overdueOnExecution')?.value || 0}
          </Count>
        </Slide>
      </SwiperSlide>
    </Swiper>
  </ApplicationsContainer>
  )
}

export default SwiperSD