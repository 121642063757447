import {
  takeEvery, put, select,
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import cookie from 'react-cookies'
import qs from 'query-string'
import { getRouterLocation, getRouterUrl } from '@/store/selectors/router'
import { getClient } from '@/store/selectors/socket'
import { SIGN_IN_URL } from '@/constants/routes'
import { USER_REFRESH_TOKEN } from '@/store/actions/user'
import { setSocketSubscriptions } from '@/store/actions/socket'
import {
  successUserRefreshToken,
  errorUserRefreshToken,
} from '@/store/actions/user/refreshToken'
import { SLS24_ACCESS_TOKEN } from '@/constants/names'
import createNotifications from "@/helpers/notification";

export function* redirectToLoginPage() {
  try {
    const url = yield select(getRouterUrl)
    const { search } = yield select(getRouterLocation)
    const queryObject = qs.parse(search)
    const encodedUrl = encodeURIComponent(url)
    cookie.remove(SLS24_ACCESS_TOKEN, { path: '/' })
    if (!queryObject.redirect) {
      yield put(push({
        pathname: SIGN_IN_URL,
        search: qs.stringify({ redirect: encodedUrl }),
      }))
    }
  } catch (error) {
    console.log('TCL: function*redirectToLoginPage -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

function* validateRefresh({ payload: { redirect } }) {
  const client = yield select(getClient)
  const accessToken = cookie.load(SLS24_ACCESS_TOKEN)

  try {
    if (redirect || !accessToken) {
      client.disconnect()
      yield redirectToLoginPage()
      yield put(successUserRefreshToken())
      return null
    }
    if (client && client.disconnect) {
      client.disconnect()
      yield put(setSocketSubscriptions({}))
    }
    return null
  } catch (error) {
    yield redirectToLoginPage()
    yield put(errorUserRefreshToken(error))
    return null
  }
}

export default function* root() {
  yield takeEvery(USER_REFRESH_TOKEN.REQUEST, validateRefresh)
}
