import styled from 'styled-components'

import {
  LIGHT_THEME_NAME,
} from '@/constants/names'

const DraggableItemWrapper = styled.div`
  // display: flex;
  // flex-direction: row;
  // align-items: center;
  // justify-content: center;
  touch-action: none;
  @media (max-width: 910px) {
    width: 100%;
   }
`
export const MoveIconWrapper = styled.div`
  display: flex;
  cursor: pointer;
`

export const IconWrapper = styled.div`
  min-width: 28px;
  mit-height: 28px;
`

export const Title = styled.h3`
  font-family: 'Roboto', sans-serif;
  display: flex;
  cursor: pointer;
  height: 100%;
  align-items: center;
  align-self: center;
  justify-content: flex-start;
  // white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  flex-grow: 1;
  ${({ isBigChildren }) => isBigChildren && `
    overflow: hidden;
  `}
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
        font-size: 1.4rem;
        font-weight: 500;
      `
    }
    return `
      font-weight: ${theme.fontWeights.bold};
      font-size: 1.4rem;
      line-height: 2.4rem;
    `
  }}
`

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  background-color: #FAFBFB;
  border-radius: 10px;
  padding: 20px;
`

export default DraggableItemWrapper