import React from 'react'
import pt from 'prop-types'
import get from 'lodash/get'
import { Field } from 'formik'
import TextControl from '@/components/controls/TextControl'

const TextField = ({
  className,
  fieldProps,
  isError,
  error,
  customError,
  mask,
  beforeMaskedValueChange,
  controls,
  errorMessage,
  resetButton,
  ...ownProps
}) => (
  <Field {...ownProps}>
    {({ field, form }) => {

      const onChange = (value) => {
        if (!fieldProps.onlyRead) {
          form.setFieldValue(field.name, value)
        }
      }

      const resetField = () => {
        form.setFieldValue(field.name, '')
      }

      return (
        <TextControl
          {...field}
          {...fieldProps}
          mask={mask}
          beforeMaskedValueChange={beforeMaskedValueChange}
          customError={customError}
          errorMessage={errorMessage}
          error={get(form, `errors.${field.name}`, false) || isError}
          className={className}
          onChange={onChange}
          controls={controls}
          disabled={fieldProps.disabled}
          resetField={resetField}
          resetButton={resetButton}
        />
      )
    }}
  </Field>
)

TextField.defaultProps = {
  className: '',
  mask: null,
  fieldProps: {
    icon: null,
    label: null,
    type: 'text',
    placeholder: '',
    autocomplete: '',
    disabled: false,
    onlyRead: false,
  },
  error: pt.string,
  isError: false,
  customError: false,
  controls: null,
  errorMessage: '',
  resetButton: false,
}

TextField.propTypes = {
  className: pt.string,
  mask: pt.string,
  fieldProps: pt.shape({
    iconName: pt.string,
    label: pt.oneOfType([pt.string, pt.element]),
    type: pt.string,
    placeholder: pt.string,
    autocomplete: pt.string,
    disabled: pt.bool,
    onlyRead: pt.bool,
  }),
  errorMessage: pt.string,
  error: '',
  isError: pt.bool,
  customError: pt.bool,
  controls: pt.element,
  resetButton: pt.bool,
}

export default React.memo(TextField)
