import { GET_SCHEDULE_OPTIONS } from './index'

export const requestGetScheduleOptions = (payload) => ({
  type: GET_SCHEDULE_OPTIONS.REQUEST,
  payload,
})

export const successGetScheduleOptions = (payload) => ({
  type: GET_SCHEDULE_OPTIONS.SUCCESS,
  payload,
})

export const errorGetScheduleOptions = (payload) => ({
  type: GET_SCHEDULE_OPTIONS.ERROR,
  payload,
})