import { handleActions } from 'redux-actions'

import {
  GET_OBJECTS_GROUPS_FILTER_OPTIONS,
} from '@/store/actions/dictionaries'

const initialState = {
  data: {},
  isLoading: false
}

export const reducer = handleActions(
  {
   [GET_OBJECTS_GROUPS_FILTER_OPTIONS.REQUEST]: (state) => ({
     ...state,
     isLoading: true
   }),
   [GET_OBJECTS_GROUPS_FILTER_OPTIONS.SUCCESS]: (state, { payload }) => ({
     ...state,
     data: payload,
     isLoading: false
   }),
   [GET_OBJECTS_GROUPS_FILTER_OPTIONS.ERROR]: (state) => ({
     ...state,
     isLoading: false
   }),
  },
  initialState,
)

export default reducer