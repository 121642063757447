import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import LiveTime from '@/components/icons/livetime'
import {
  OK,
  WARNING,
  ERROR,
  UNKNOWN,
} from '@/constants/alarms'
import CustomEventCell from '@/components/blocks/DutyTable/customCells/CustomEventCell'

export const fields = [
  {
    id: 'objectName',
    name: <Lang id="tableFields.analyticsAlarms.objectName" />,
    value: 'ASC',
  },
  {
    id: 'address',
    name: <Lang id="tableFields.analyticsAlarms.address" />,
    value: 'ASC',
  },
  {
    id: 'containWarning',
    name: <Lang id="tableFields.analyticsAlarms.containWarning" />,
    value: 'ASC',
    customCell: (props) => {
      const { value } = props
      let valueIcon = value ? WARNING : OK
      if (value === null) {
        valueIcon = UNKNOWN
      }
      return (<CustomEventCell {...props} value={valueIcon} />)
    },
  },
  {
    id: 'containFault',
    name: <Lang id="tableFields.analyticsAlarms.containFault" />,
    value: 'ASC',
    customCell: (props) => {
      const { value } = props
      let valueIcon = value ? ERROR : OK
      if (value === null) {
        valueIcon = UNKNOWN
      }
      return (<CustomEventCell {...props} value={valueIcon} />)
    },
  },
  {
    id: 'descriptions',
    name: <Lang id="tableFields.analyticsAlarms.description" />,
    value: 'ASC',
    customCell: (props) => {
      const { value } = props
      return (
        <>
          {value.map((row) => (
            <div>
              {row}
            </div>
          ))}
        </>
      )
    },
  },
  {
    id: 'currentLifePercent',
    name: <Lang id="tableFields.analyticsAlarms.currentLifePercent" />,
    value: 'ASC',
  },
  {
    id: 'occurrenceDate',
    name: <Lang id="tableFields.analyticsAlarms.occurrenceDate" />,
    value: 'ASC',
  },
  {
    id: 'statePeriod',
    name: <Lang id="tableFields.analyticsAlarms.statePeriod" />,
    value: 'ASC',
  },
]

export const fieldsMobile =[
  {
    id: 'objectName',
    name: 'Объект',
    value: 'ASC',
  },
  {
    id: 'address',
    name: 'Адрес',
    value: 'ASC',
  },
  {
    id: 'state',
    name: 'Состояние',
    value: 'ASC',
  },
  {
    id: 'descriptions',
    name: 'Описание события',
    value: 'ASC',
  },
  {
    id: 'currentLifePercent',
    name: '% выработки ресурса',
    value: 'ASC',
  },
  {
    id: 'occurrenceDate',
    name: 'Дата возникновения',
    value: 'ASC',
  },
  {
    id: 'statePeriod',
    name: 'Период состояния',
    value: 'ASC',
  },
]

// TODO: move to one for all tables
export const exportOptions = [
  {
    name: 'EXCEL',
    value: 'xlsx',
    icon: LiveTime.EXELIcon,
  },
  {
    name: 'PDF',
    value: 'pdf',
    icon: LiveTime.PDFIcon,
  },
]

export default {
  fields,
  exportOptions,
}
