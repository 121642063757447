export default {
  buttons: {
    confirm: 'Да',
    reject: 'Нет',
    save: 'Сохранить',
    cancel: 'Отменить',
    connect: 'подключить интеграцию',
    delete: 'удалить Интеграцию',
    continue: 'продолжить',
    wait: 'пожалуйста, ожидайте',
    repeat: 'повторить\nсинхронизацию',
  },
  labels: {
    addNewIntegration: 'Добавление\nновой интеграции',
    rejectNewIntegration: 'Отменить добавление\nновой интеграции?',
    connectedIntegrations: 'Интеграция\nвнешних систем',
    integrationParameters: 'Параметры интеграции',
    integrationDiscardChanges: 'Отменить внесенные изменения?',
    cancel: 'Отменить',
  },
  messages: {
    noConnectedIntegrations: 'Внешние системы отсутствуют.\nДля добавления нажмите «+»',
    connectionToSystem: 'Идет подключение к внешней системе',
    connectionSuccess: 'Интеграция была успешно добавлена в систему. Синхронизация данных может занять некоторое время',
  },
  form: {
    installationIntegrationId: 'Существующая интеграция',
    api: 'API URL*',
    port: 'Порт',
    login: 'Пользователь*',
    notRequiredLogin: 'Пользователь',
    pass: 'Пароль*',
    notRequiredPass: 'Пароль',
    name: 'Название*',
    refresh: 'Интервал обновления',
    edit: 'Редактировать',

    contextUrl: 'Context url*',
    historyUrl: 'History url*',
    clientId: 'Client id*',
    clientSecret: 'Client secret*',
  },
  formMessages: {
    deletingInfo: 'Выполняется полное удаление\nинтеграции из системы.',
    deleting: 'Вы действительно хотите\nудалить интеграцию из\nсистемы?\nВсе данные будут потеряны!',
    editingInfo: 'Выполняется обработка\nпользовательского запроса....',
    editing: 'Применить изменение\nпараметров?',
    info: '*Поля обязательные для\nзаполнения',
    connection: 'Выполняется подклчение\nк внешней системе....',
    error: 'Здесь выводится текст ошибки\nкоторая произошла при\nподключении',
    timeOutError: 'Ошибка сетевого доступа',
    unknownError: 'Ошибка синхронизации',
    editingError: 'Изменение параметров не\nбыло выполнено.',
    sync: '( синхр.: в процессе )',
  },
}
