import createAction from '@/helpers/redux/createAction'
import createTableActions from '@/helpers/redux/createTableActions'

export const GET_LIVE_TIME_REPORT_BAR_DATA = createAction('@/GET_LIVE_TIME_REPORT_BAR_DATA')
export const GET_LIVE_TIME_REPORT_TABLE = createAction('@/GET_LIVE_TIME_REPORT_TABLE')
export const GET_LIVE_TIME_REPORT_FILE = createAction('@/GET_LIVE_TIME_REPORT_FILE')
export const GET_LIVE_TIME_REPORT_OBJECT_TYPES = createAction('@/GET_LIVE_TIME_REPORT_OBJECT_TYPES')
export const LIVE_TIME_REPORT_TABLE = createTableActions('@/LIVE_TIME_REPORT_TABLE')

export const SET_GRAPH_TYPE = '@/LIVE_TIME_REPORT_SET_GRAPH_TYPE'
export const SET_GRAPH_OPTIONS = '@/LIVE_TIME_REPORT_SET_GRAPH_OPTIONS'
export const SET_BAR_GRAPH_YEAR_OPTIONS = '@/LIVE_TIME_REPORT_SET_BAR_GRAPH_YEAR_OPTIONS'
export const SET_BAR_GRAPH_PERSENT_OPTIONS = '@/LIVE_TIME_REPORT_SET_BAR_GRAPH_PERSENT_OPTIONS'
export const SET_LIVE_TIME_OBJECT_TYPES = '@/SET_LIVE_TIME_OBJECT_TYPES'

export const setGraphType = (payload) => ({
  type: SET_GRAPH_TYPE,
  payload,
})

export const setGraphOptions = (payload) => ({
  type: SET_GRAPH_OPTIONS,
  payload,
})

export const setTableData = (payload) => ({
  type: SET_GRAPH_OPTIONS,
  payload,
})

export const setNewGraphYearOptions = (payload) => ({
  type: SET_BAR_GRAPH_YEAR_OPTIONS,
  payload,
})

export const setNewGraphPercentOptions = (payload) => ({
  type: SET_BAR_GRAPH_PERSENT_OPTIONS,
  payload,
})

export const setLiveTimeObjectTypes = (payload) => ({
  type: SET_LIVE_TIME_OBJECT_TYPES,
  payload,
})
