import { handleActions } from 'redux-actions'
import {
    CREATE_GEOZONE,
    DELETE_GEOZONE,
    EDIT_GEOZONE,
    GET_ROOT_ELEMENTS,
    GET_ROOT_CHILD,
    GET_CARD,
    GET_INTEGRATION_TYPES,
    GET_OBJECT_TYPES,
    GET_TENANT_PARENTS,
    GET_OBJECT_VALIDATION_SCHEME,
    SET_MAP_CENTER,
    SET_MAP_ZOOM,
    GET_ALL_INTEGRATIONS,
    SLECTED_ELEMENT_PARAMETRS,
    CREATE_INTEGRATION,
    DELETE_INTEGRATION,
    EDIT_INTEGRATION,
    CREATE_PROJECT,
    DELETE_PROJECT,
    EDIT_PROJECT,
    CREATE_OBJECT,
    DELETE_OBJECT,
    EDIT_OBJECT,
    VERIFY_OBJECT,
    SET_URL_PARAMS,
    SET_REQUEST_STATUS,
    GET_PINS_AND_ZONES_WITH_TELEMENTRY,
    DELETE_PROJECT_CUSTOM_FIELD,
    GET_OBJECT_TELEMETRY,
    GET_NODE_STATISTIC,
    SET_PINS_AND_ZONES_REQUEST_STATUS,
    GET_OBJECT_CONNECTED_IDENTIFIERS,
    GET_CONNECTED_LINES_COORDINATES,
    GET_TREE_ELEMENTS,
    SET_STREAM_URL,
    GET_STREAM_URL,
} from '@/store/actions/installation'
import { allFilterOption as INSTALLATION_WIDGET_FILTER_CONFIG } from '@/forms/InstalationForm/config'
import { REQUEST_STATUSES } from '@/constants/requests'

const initialState = {
  child: {},
  root: [],
  treeData: [],
  rootStatus: REQUEST_STATUSES.NOT_REQUESTED,
  integrationTypes: [],
  objectTypes: [],
  objectTypesRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
  parentElements: [],
  objectTelemetry: {},
  nodeStatistic: {},
  displayElements: [],
  integration: [],
  integrationRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
  validationScheme: {},
  mapCenter: [50, 80],
  mapZoom: 3,
  card: {
    data: {},
    requestStatus: REQUEST_STATUSES.NOT_REQUESTED,
  },
  selectedElementParams: {},
  params: {
    FILTER: INSTALLATION_WIDGET_FILTER_CONFIG,
  },
  displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
  objectConnectedIdentifiers: [],
  connectedLinesCoordinates: [],
  connectedLinesCoordinatesRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
  streamUrl: null,
}

export const reducer = handleActions(
  {
    [SET_REQUEST_STATUS]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
    [SET_URL_PARAMS]: (state, { payload }) => ({
      ...state,
      params: payload,
    }),
    [SET_MAP_CENTER]: (state, { payload }) => ({
      ...state,
      mapCenter: payload,
    }),
    [GET_STREAM_URL.REQUEST]: (state) => ({
      ...state,
      streamUrl: null,
    }),
    [SET_STREAM_URL.SUCCESS]: (state, { payload }) => ({
      ...state,
      streamUrl: payload,
    }),
    [SET_STREAM_URL.ERROR]: (state) => ({
      ...state,
      streamUrl: null,
    }),
    [GET_STREAM_URL.SUCCESS]: (state, { payload }) => ({
      ...state,
      streamUrl: payload,
    }),
    [SLECTED_ELEMENT_PARAMETRS]: (state, { payload }) => ({
      ...state,
      selectedElementParams: payload,
    }),
    [SET_MAP_ZOOM]: (state, { payload }) => ({
      ...state,
      mapZoom: payload,
    }),
    [GET_PINS_AND_ZONES_WITH_TELEMENTRY.REQUEST]: (state, { payload }) => ({
      ...state,
      // displayElements: [],
      displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.PENDING,
    }),
    [GET_PINS_AND_ZONES_WITH_TELEMENTRY.SUCCESS]: (state, { payload: { displayElements } }) => ({
      ...state,
      displayElements,
      displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.IDLE,
    }),
    [SET_PINS_AND_ZONES_REQUEST_STATUS]: (state, { payload }) => ({
      ...state,
      displayElementAndTelemetryRequestStatus: payload,
    }),
    [GET_INTEGRATION_TYPES.REQUEST]: (state) => ({
      ...state,
      integrationTypes: [],
    }),
    [GET_INTEGRATION_TYPES.SUCCESS]: (state, { payload }) => ({
      ...state,
      integrationTypes: payload,
    }),
    [GET_INTEGRATION_TYPES.ERROR]: (state) => ({
      ...state,
      integrationTypes: [],
    }),

    [GET_OBJECT_TYPES.REQUEST]: (state) => ({
      ...state,
      objectTypes: [],
      objectTypesRequestStatus: REQUEST_STATUSES.PENDING,
    }),
    [GET_OBJECT_TYPES.SUCCESS]: (state, { payload }) => ({
      ...state,
      objectTypes: payload,
      objectTypesRequestStatus: REQUEST_STATUSES.IDLE,
    }),
    [GET_OBJECT_TYPES.ERROR]: (state) => ({
      ...state,
      objectTypes: [],
      objectTypesRequestStatus: REQUEST_STATUSES.ERROR,
    }),

    [GET_OBJECT_VALIDATION_SCHEME.REQUEST]: (state) => ({
      ...state,
      validationScheme: {},
    }),
    [GET_OBJECT_VALIDATION_SCHEME.SUCCESS]: (state, { payload }) => ({
      ...state,
      validationScheme: payload,
    }),
    [GET_OBJECT_VALIDATION_SCHEME.ERROR]: (state) => ({
      ...state,
      validationScheme: {},
    }),
    [CREATE_GEOZONE.SUCCESS]: (state, { payload }) => ({
      ...state,
      tree: payload,
      displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
    }),
    [DELETE_GEOZONE.SUCCESS]: (state, { payload }) => ({
      ...state,
      tree: payload,
      displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
    }),
    [EDIT_GEOZONE.SUCCESS]: (state, { payload }) => ({
      ...state,
      tree: payload,
      displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
    }),
    [CREATE_INTEGRATION.SUCCESS]: (state, { payload }) => ({
      ...state,
      displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
    }),
    [EDIT_INTEGRATION.SUCCESS]: (state, { payload }) => ({
      ...state,
      displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
    }),
    [DELETE_INTEGRATION.SUCCESS]: (state, { payload }) => ({
      ...state,
      displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
    }),
    [CREATE_PROJECT.SUCCESS]: (state, { payload }) => ({
      ...state,
      displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
    }),
    [DELETE_PROJECT.SUCCESS]: (state, { payload }) => ({
      ...state,
      displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
    }),
    [EDIT_PROJECT.SUCCESS]: (state, { payload }) => ({
      ...state,
      displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
    }),
    [DELETE_PROJECT_CUSTOM_FIELD.SUCCESS]: (state, { payload }) => ({
        ...state,
        displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
    }),
    [CREATE_OBJECT.SUCCESS]: (state, { payload }) => ({
      ...state,
      displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
    }),
    [DELETE_OBJECT.SUCCESS]: (state, { payload }) => ({
      ...state,
      displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
    }),
    [EDIT_OBJECT.SUCCESS]: (state, { payload }) => ({
      ...state,
      displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
    }),
    [VERIFY_OBJECT.SUCCESS]: (state, { payload }) => ({
      ...state,
      displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
    }),
    [GET_ROOT_ELEMENTS.REQUEST]: (state) => ({
      ...state,
      rootStatus: REQUEST_STATUSES.PENDING,
    }),
    [GET_ROOT_ELEMENTS.SUCCESS]: (state, { payload }) => ({
      ...state,
      root: !payload ? [] : payload,
      rootStatus: !payload ? REQUEST_STATUSES.PENDING : REQUEST_STATUSES.IDLE,
      displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
    }),
    [GET_TREE_ELEMENTS.SUCCESS]: (state, { payload }) => ({
      ...state,
      treeData: payload,
    }),
    [GET_ROOT_ELEMENTS.ERROR]: (state) => ({
      ...state,
      rootStatus: REQUEST_STATUSES.ERROR,
    }),
    [GET_ROOT_CHILD.SUCCESS]: (state, { payload }) => ({
      ...state,
      child: {
        ...state.child,
        ...payload,
      },
    }),
    [GET_CARD.REQUEST]: (state) => ({
      ...state,
      card: {
        data: {},
        requestStatus: REQUEST_STATUSES.PENDING,
      },
      objectTypes: [],
      objectTypesRequestStatus: REQUEST_STATUSES.IDLE,
      displayElementAndTelemetryRequestStatus: REQUEST_STATUSES.NOT_REQUESTED,
    }),
    [GET_CARD.SUCCESS]: (state, { payload }) => ({
      ...state,
      card: {
        data: payload,
        requestStatus: REQUEST_STATUSES.IDLE,
      },
    }),
    [GET_CARD.ERROR]: (state) => ({
      ...state,
      card: {
        data: {},
        requestStatus: REQUEST_STATUSES.IDLE,
      },
    }),
    [GET_OBJECT_TELEMETRY.REQUEST]: (state) => ({
      ...state,
      objectTelemetry: {}
    }),
    [GET_OBJECT_TELEMETRY.SUCCESS]: (state, { payload }) => ({
      ...state,
      objectTelemetry: payload,
    }),
    [GET_OBJECT_TELEMETRY.ERROR]: (state) => ({
      ...state,
    }),
    [GET_NODE_STATISTIC.REQUEST]: (state) => ({
      ...state,
      nodeStatistic: {}
    }),
    [GET_NODE_STATISTIC.SUCCESS]: (state, { payload }) => ({
      ...state,
      nodeStatistic: payload,
    }),
    [GET_NODE_STATISTIC.ERROR]: (state) => ({
      ...state,
    }),
    [GET_TENANT_PARENTS.REQUEST]: (state) => ({
      ...state,
      parentElements: [],
    }),
    [GET_TENANT_PARENTS.SUCCESS]: (state, { payload }) => ({
      ...state,
      parentElements: payload,
    }),
    [GET_TENANT_PARENTS.ERROR]: (state) => ({
      ...state,
      parentElements: [],
    }),
    [GET_ALL_INTEGRATIONS.REQUEST]: (state) => ({
      ...state,
      integration: [],
      integrationRequestStatus: REQUEST_STATUSES.PENDING,
    }),
    [GET_ALL_INTEGRATIONS.SUCCESS]: (state, { payload }) => ({
      ...state,
      integration: payload,
      integrationRequestStatus: REQUEST_STATUSES.IDLE,
    }),
    [GET_ALL_INTEGRATIONS.ERROR]: (state) => ({
      ...state,
      integration: [],
      integrationRequestStatus: REQUEST_STATUSES.ERROR,
    }),

    [GET_OBJECT_CONNECTED_IDENTIFIERS.REQUEST]: (state) => ({
      ...state,
      objectConnectedIdentifiers: [],
    }),
    [GET_OBJECT_CONNECTED_IDENTIFIERS.SUCCESS]: (state, { payload }) => ({
      ...state,
      objectConnectedIdentifiers: payload,
    }),
    [GET_OBJECT_CONNECTED_IDENTIFIERS.ERROR]: (state) => ({
      ...state,
      objectConnectedIdentifiers: [],
    }),

    [GET_CONNECTED_LINES_COORDINATES.REQUEST]: (state) => ({
      ...state,
      connectedLinesCoordinatesRequestStatus: REQUEST_STATUSES.PENDING,
    }),
    [GET_CONNECTED_LINES_COORDINATES.SUCCESS]: (state, { payload }) => ({
      ...state,
      connectedLinesCoordinates: payload,
      connectedLinesCoordinatesRequestStatus: REQUEST_STATUSES.IDLE,
    }),
    [GET_CONNECTED_LINES_COORDINATES.ERROR]: (state) => ({
      ...state,
      connectedLinesCoordinates: [],
      connectedLinesCoordinatesRequestStatus: REQUEST_STATUSES.ERROR,
    }),
  },
  initialState,
)

export default reducer
