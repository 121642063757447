import styled from 'styled-components'


export const TextButton = styled.div`
  position: absolute;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
  width: 226px;
  height: 40px;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  color: #1061DF;
  top: calc(100vh - 130px);
  left: 0;
  right: 0;
  z-index: 80;
  border-radius: 20px;
  box-shadow: -8px 12px 18px 0px #1E212717;
  opacity: 80%;
  background-color: #FAFBFB;
  margin-left: auto;
  margin-right: auto;
`


