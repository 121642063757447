import {
    select, put, all, takeLatest
} from 'redux-saga/effects'
import { getSelectedNode, getGlobalFilters } from '@/store/selectors/appSettings'
import { CITY, COUNTRY, GROUP } from '@/constants/objectTypes'
import request from '@/helpers/axios'
import {
    generateGetParentNode,
    INSTALLATION_ELEMENT_CHILDREN,
} from '@/constants/apiRoutes'
import russiaBorders from '@/data/regionCoords/russia'
import getPinsByTree from '@/helpers/formaters/getPinsByTree'
import generateZoneBorder from '@/helpers/generateZoneBorder'
import get from 'lodash/get'
import { treeHandlers } from 'react-hyper-tree'
import createNotifications from '@/helpers/notification'
import {
    errorGetPassportizationData,
    successGetPassportizationData
} from '@/store/actions/passportization/getPassportizationData'
import { GET_PASSPORTIZATION_DATA } from '@/store/actions/passportization'

function* getPassportizationData() {
  try {
      let newZones = {}
      let geoZoneTree = {}
      let roots
      try {
        const { asu, objectsTypes, integrations, owners, exploitationOrganizations } = yield select(getGlobalFilters)
          const { data } = yield request({
            url: INSTALLATION_ELEMENT_CHILDREN({
                params: {
                    integrationTypes: asu,
                    installationTypes: objectsTypes,
                    integrationIds: integrations,
                    owners,
                    exploitationOrganizations
                },
            }),
            method: 'get',
          })
          roots = yield all(data.map((element) => {
              return {
                  ...element,
                  type: COUNTRY,
                  name: element.name,
                  location: [element.point.latitude, element.point.longitude],
                  border: russiaBorders,
              }
          }))
          newZones[roots[0].id] = roots[0]
          geoZoneTree = roots[0]
        } catch (error) {}

        let parentZoneSelector = []
        try {
            const { asu, objectsTypes, integrations, owners, exploitationOrganizations } = yield select(getGlobalFilters)
            const { data: geoZones } = yield request( {
                url: INSTALLATION_ELEMENT_CHILDREN({
                    params: {
                        parentId: roots[0].id,
                        integrationTypes: asu,
                        installationTypes: objectsTypes,
                        integrationIds: integrations,
                        owners,
                        exploitationOrganizations
                    },
                }),
                method: 'get',
            })
            geoZoneTree.children = geoZones.map(item => {
                newZones[item.id] = {
                    ...item,
                    location: [item.point.latitude, item.point.longitude],
                    type: CITY,
                    zoneSelector: [item.id]
                }
                parentZoneSelector.push(item.id)
                return {
                    ...item,
                    location: [item.point.latitude, item.point.longitude],
                    type: CITY,
                    zoneSelector: [item.id]
                }
            })
            geoZoneTree.zoneSelector = parentZoneSelector
        } catch (error) {}
        const getNodeChildren = (node, data, geoZoneId) => {
          const selectType = (element) => {
              if (!element.parentId) {
                  return 'country'
              } else if (element.installationType === GROUP || !element.installationType) {
                  return 'UNKNOWN'
              } else {
                  return element.installationType
              }
          }
            let children = data.filter(item => item.parentId === node.id)
            if (children.length) {
                children.forEach(item => {
                    if (item.point) {
                        item.location = [item.point.latitude, item.point.longitude]
                    }
                    if (!!node.integrationType || !!node.system) {
                        item.system = node.integrationType || node.system
                    }
                    item.geoZoneId = geoZoneId
                    item.type = selectType(item)
                    item.children = getNodeChildren(item, data, geoZoneId)
                })
            }
            return children
        }

        try {
            const { asu, objectsTypes, integrations, owners, exploitationOrganizations } = yield select(getGlobalFilters)
            const data = yield request( {
                url: generateGetParentNode({
                    params: {
                        pageSize: 999999,
                        integrationTypes: asu,
                        installationTypes: objectsTypes,
                        integrationIds: integrations,
                        owners,
                        exploitationOrganizations
                    }
                }),
                method: 'get',
            })
            const formattedRequestData = data.data.content
            geoZoneTree.children.forEach(item => {
                item.children = getNodeChildren(item, formattedRequestData, item.id)
            })
        } catch (error) {

        }
        const treeWithPaths = geoZoneTree
        const updatedPins = getPinsByTree(treeWithPaths).filter(item => item.installationType !== GROUP)
        const newZonesWithBorders = Object.keys(newZones).reduce((accumulator, zoneKey) => {
            const selectors = (newZones[zoneKey] || {}).zoneSelector || []
            const relatedPins = updatedPins.filter((pin) => selectors.includes(pin.id))
            if (newZones[zoneKey].name === 'Россия') {
                return {
                    ...accumulator,
                    [newZones[zoneKey].id]: {
                        ...(newZones[zoneKey] || {}),
                    },
                }
            }
            return {
                ...accumulator,
                [newZones[zoneKey].id]: {
                    ...(newZones[zoneKey] || {}),
                    border: generateZoneBorder(relatedPins),
                },
            }
        }, {})
        yield put(successGetPassportizationData({
            tree: treeWithPaths,
            pins: updatedPins,
            zones: newZonesWithBorders,
        }))

        const selectedNode = yield select(getSelectedNode)
        const selectedNodePath = get(selectedNode, 'original.options.path', null)

        setTimeout(() => {
            const tree = treeHandlers.trees['passportization-tree']
            if (selectedNodePath && tree && tree.handlers) {
                tree.handlers.setSelectedByPath(selectedNodePath)
            }
        })
    } catch (error) {
      console.log('error', error)
      yield put(errorGetPassportizationData())
      const toast = createNotifications()
      toast({
          title: 'Ошибка операции!',
          description: 'Не удалось получить данные паспортизации. Повторите попытку позже.',
          type: 'error',
        })
    }
}

export default function* root() {
    yield takeLatest(GET_PASSPORTIZATION_DATA.REQUEST, getPassportizationData)
}