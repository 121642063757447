import {
  takeLatest, put, all, select
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { CREATE_OBJECTS_GROUP_TREE_ENDPOINT } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { GET_CREATE_OBJECTS_GROUP_TREE } from '@/store/actions/objectsGroups'
import {
  errorGetCreateObjectsGroupTree,
  // successGetCreateObjectsGroupSearchTree,
  successGetCreateObjectsGroupTree,
} from '@/store/actions/objectsGroups/getCreateObjectsGroupTree'
import { GEOZONE, ROOT } from '@/constants/objectTypes'
import { getControlCupboardOriginalDictionary, getObjectsGroup } from '@/store/selectors/objectsGroups'
import { treeHandlers } from 'react-hyper-tree'

function* getCreateObjectsGroupTreeSaga({ payload }) {
  const toast = createNotifications()
  try {
    const { filters } = payload
    const card = yield select(getObjectsGroup)

    const controlCupboardOriginalDictionary = yield select(getControlCupboardOriginalDictionary)
    const controlCupboardIds = filters.controlCupboard.map((filter) => controlCupboardOriginalDictionary.filter(item => {
      const value = controlCupboardOriginalDictionary.filter(cupboard => cupboard.id === filter)[0].value
      return item.value === value
    })).flat().map(el => el.id)

    const { data } = yield request({
      url: CREATE_OBJECTS_GROUP_TREE_ENDPOINT({
        params: {
          installationTypes: filters.objectType,
          lampTypes: filters.lightFixtureType,
          locationTypes: filters.pedestrianCrossingSign,
          balanceSheetHolders: filters.networkCompany,
          registerNumbers: filters.registryNumber,
          controlCupboardIds: controlCupboardIds,
          streets: filters.street,
          utilityPoleTypes: filters.utilityPoleType,
          vols: filters.vols,
        }
      }),
      method: 'get',
    })

    const getChildren = ({ rootId, closestGeoZoneId, parentTreeId, installationTypes, lampTypes, locationTypes, balanceSheetHolders, registerNumbers, controlCupboardIds, streets, utilityPoleTypes, vols } ) => async (node) => {
      const { data } = await request({
        url: CREATE_OBJECTS_GROUP_TREE_ENDPOINT({
          params: {
            parentId: node.node.id,
            installationTypes,
            lampTypes,
            locationTypes,
            balanceSheetHolders,
            registerNumbers,
            controlCupboardIds,
            streets,
            utilityPoleTypes,
            vols
          },
        }),
        method: 'get',
      })
      if (!data.length) {
        const tree = treeHandlers.trees['objects-groups-form-tree']
        tree.handlers.setNodeData(node.node.id, {...node.node.data, color: '', checked: true})
        return []
      }

      const getIsNodeChecked = (element) => {
        if (node.node.data.childrenChecked) {
          return true
        } else if (payload?.edit) {
          return card.objects?.some(item => item.id === element.id)
        }
        return false
      }

      const getIsChildrenCheck = (element) => {
        if (node.node.data.childrenChecked) {
          return true
        } else {
          return payload?.edit && card.objects.filter(item => item.parentId === element.id).length === element.countChildElements
        }
      }

      return await Promise.all(data.sort((a, b) => a.name.trim() > b.name.trim() ? 1 : -1).map(async (element) => {
        const parentGeoZoneId = element.treeNodeType === GEOZONE
          ? element.id
          : closestGeoZoneId

        return {
          ...element,
          type: element.installationType || element.treeNodeType,
          nodeType: element.treeNodeType,
          treeNodeType: null,
          name: element.name,
          parentGeoZoneId: closestGeoZoneId,
          parentTreeId,
          rootGeoZone: rootId,
          childrenCount: element.countChildElements,
          childrenChecked:  getIsChildrenCheck(element),
          checked: getIsNodeChecked(element),
          getChildren: getChildren({
            rootId,
            parentGeoZoneId,
            parentTreeId: element.id,
            installationTypes,
            lampTypes,
            locationTypes,
            balanceSheetHolders,
            registerNumbers,
            controlCupboardIds,
            streets,
            utilityPoleTypes,
            vols
          }),
        }
      }))
    }

    // const response = yield request( {
    //   url: CREATE_OBJECTS_GROUP_TREE_ENDPOINT({
    //     params: {
    //       includeAll: true,
    //       installationTypes: filters.objectType,
    //       lampTypes: filters.lightFixtureType,
    //       locationTypes: filters.pedestrianCrossingSign,
    //       balanceSheetHolders: filters.networkCompany,
    //       registerNumbers: filters.registryNumber,
    //       controlCupboardIds: filters.controlCupboard,
    //       streets: filters.street,
    //       utilityPoleTypes: filters.utilityPoleType,
    //       vols: filters.vols,
    //     }
    //   }),
    //   method: 'get',
    // })

    if (data.length) {
      let formattedRequestData
      formattedRequestData = yield all(data.sort((a, b) => a.name.trim() > b.name.trim() ? 1 : -1).map((element) => {
        return {
          ...element,
          type: ROOT,
          name: element.name,
          rootGeoZone: element.id,
          childrenCount: element.countChildElements,
          point: element.point,
          getChildren: getChildren({
            rootId: element.id,
            closestGeoZoneId: element.id,
            parentTreeId: element.id,
            installationTypes: filters.objectType,
            lampTypes: filters.lightFixtureType,
            locationTypes: filters.pedestrianCrossingSign,
            balanceSheetHolders: filters.networkCompany,
            registerNumbers: filters.registryNumber,
            controlCupboardIds: controlCupboardIds,
            streets: filters.street,
            utilityPoleTypes: filters.utilityPoleType,
            vols: filters.vols,
          }),
        }
      }))
      yield put(successGetCreateObjectsGroupTree(formattedRequestData))

      // let searchTree = [{...data[0], type: ROOT}]
      // searchTree[0].children = response.data.filter(item => item.parentId === searchTree[0].id)

      // const getNodeChildren = (id, data) => {
      //   let children = data.filter(item => item.parentId === id).map(child => {
      //     return {
      //       ...child,
      //       checked: payload?.edit ? card.objects?.some(item => item.id === child.id) : false,
      //       type: child.installationType || child.treeNodeType,
      //       nodeType: child.treeNodeType,
      //       treeNodeType: null,
      //     }})
      //   const updatedData = data.filter(item => item.path.includes(id))
      //   if (children.length) {
      //     children.forEach(item => {
      //       item.children = getNodeChildren(item.id, updatedData)
      //     })
      //   }
      //   return children
      // }

      // searchTree[0].children.forEach(item => {
      //   item.children = getNodeChildren(item.id, response.data.filter(node => node.path.includes(item.id)))
      // })
      // yield put(successGetCreateObjectsGroupSearchTree(searchTree))
    } else {
      toast({
        title: 'Внимание!',
        description: 'Объектов, удовлетворяющих результатам поиска, не существует. \nПожалуйста, измените настроенные ранее фильтры.',
        type: 'warning',
      })
      yield put(successGetCreateObjectsGroupTree([{}]))
    }
  } catch (error) {
    yield put(errorGetCreateObjectsGroupTree(error))
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось получить данные дерева для создания групп объектов.\nПовторите попытку позже.',
      type: 'error',
    })
    }
}

export default function* root() {
  yield takeLatest(GET_CREATE_OBJECTS_GROUP_TREE.REQUEST, getCreateObjectsGroupTreeSaga)
}