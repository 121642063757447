import { handleActions } from 'redux-actions'
import {
  GET_SCHEDULE,
  GET_SCHEDULE_MANAGER_REPORT_FILE,
  GET_SCHEDULE_MANAGER_TREE,
  SET_SCHEDULE_TREE_NODE
} from '@/store/actions/scheduleManager'
import REQUEST_STATUSES from '@/constants/requests'

const initialState = {
  fileExportStatus: REQUEST_STATUSES.NOT_REQUESTED,
  tree: [],
  treeStatus: REQUEST_STATUSES.NOT_REQUESTED,
  card: {},
  selectedNode: {},
}

export const reducer = handleActions(
    {
  [GET_SCHEDULE_MANAGER_TREE.REQUEST]: (state) => ({
    ...state,
    tree: [],
    treeStatus: REQUEST_STATUSES.PENDING
  }),
  [GET_SCHEDULE_MANAGER_TREE.SUCCESS]: (state, { payload }) => ({
    ...state,
    tree: payload,
    treeStatus: REQUEST_STATUSES.IDLE
  }),
  [GET_SCHEDULE_MANAGER_TREE.ERROR]: (state) => ({
    ...state,
    treeStatus: REQUEST_STATUSES.ERROR
  }),
  [GET_SCHEDULE.REQUEST]: (state) => ({
    ...state,
    card: {}
  }),
  [GET_SCHEDULE.SUCCESS]: (state, { payload }) => ({
    ...state,
    card: payload,
  }),
  [GET_SCHEDULE.ERROR]: (state) => ({
    ...state,
    card: {},
  }),
  [GET_SCHEDULE_MANAGER_REPORT_FILE.REQUEST]: (state) => ({
    ...state,
    fileExportStatus: REQUEST_STATUSES.PENDING,
  }),
  [GET_SCHEDULE_MANAGER_REPORT_FILE.SUCCESS]: (state) => ({
    ...state,
    fileExportStatus: REQUEST_STATUSES.IDLE,
  }),
  [GET_SCHEDULE_MANAGER_REPORT_FILE.ERROR]: (state) => ({
    ...state,
    fileExportStatus: REQUEST_STATUSES.ERROR,
  }),
  [SET_SCHEDULE_TREE_NODE]: (state, { payload }) => ({
    ...state,
    selectedNode: payload,
  }),
  },
  initialState,
)

export default reducer