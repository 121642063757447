import generateDefaultTableState from '@/helpers/table/generateDefaultTableState'
import { handleActions } from 'redux-actions'
import generateDefaultTableReducer, { generateTableParameter } from '@/helpers/table/generateDefaultTableReducer'
import {
  GET_SCHEDULE_MANAGER_FAILURE_LOG_TABLE,
  SCHEDULE_MANAGER_FAILURE_LOG_TABLE,
  SET_ERROR_STATUS,
  SET_FAILURE_LOG_DATES_RANGE,
} from '@/store/actions/scheduleManager'
import {
  failureLogFields
} from '@/constants/tablesConfig/scheduleManager'

const initialState = {
  data: [],
  table: generateDefaultTableState(failureLogFields),
  loadingTable: false,
  dates: {
    start: null,
    end: null,
  },
  errorStatus: null
}

export const reducer = handleActions(
    {
  [GET_SCHEDULE_MANAGER_FAILURE_LOG_TABLE.REQUEST]: (state) => ({
    ...state,
    loadingTable: true,
  }),
  [GET_SCHEDULE_MANAGER_FAILURE_LOG_TABLE.SUCCESS]: (state, { payload }) => ({
    ...state,
    data: payload.data,
    ...generateTableParameter(state, 'resultCount', payload.totalElements),
    loadingTable: false,
  }),
  [GET_SCHEDULE_MANAGER_FAILURE_LOG_TABLE.ERROR]: (state) => ({
    ...state,
    loadingTable: false,
  }),
  [SET_FAILURE_LOG_DATES_RANGE]: (state, { payload }) => ({
    ...state,
    dates: payload,
    ...generateTableParameter(state, 'page', 1),
  }),
  [SET_ERROR_STATUS]: (state, { payload }) => ({
    ...state,
    errorStatus: payload,
    ...generateTableParameter(state, 'page', 1),
  }),
  ...generateDefaultTableReducer(SCHEDULE_MANAGER_FAILURE_LOG_TABLE),
  },
  initialState,
)

export default reducer