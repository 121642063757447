import React, { useState, useCallback } from 'react'

import WidgetsDashboardIcon from '@/components/icons/widgetsDashboard'
import DoublePieChart from '@/components/charts/DoublePieChart'
import numberWithSpaces from '@/helpers/numberWithSpaces'
import {
  SAFFRON,
  TURMERIC,
  PICTON_BLUE,
  DANUBE,
  ATLANTIS,
  SUSHI,
} from '@/constants/styles/defaultTheme'
import {
  LIGHTS,
  SOCIALS,
  ROADS,
} from './config'

import {
  Container,
  Content,
  TitlesWrapper,
  Title,
  RowContainer,
  Row,
  RowTitle,
  Indicator,
  ChartContainer,
} from './styles'

const ObjectsCount = ({ infrastructureCount }) => {
  const { lightSummaryCount, socialSummaryCount, transportSummaryCount } = infrastructureCount
  const [isGraphView, setIsGraphView] = useState(false)

  const handleToggleView = useCallback(() => {
    setIsGraphView(!isGraphView)
  }, [isGraphView])

  const renderGeneralTitle = useCallback(() => {
    if (isGraphView) {
      return (
        <Content header>
          <WidgetsDashboardIcon.GeoPinIcon />
          <TitlesWrapper>
            <Title header>Тип объектов в системе</Title>
          </TitlesWrapper>
          <WidgetsDashboardIcon.ListIcon onClick={handleToggleView} />
        </Content>
      )
    }
    return (
      <Content header>
        <WidgetsDashboardIcon.LightsIcon />
        <TitlesWrapper>
          <Title header>Объекты освещения:</Title>
          <Title description>
            Всего:
            {' '}
            {numberWithSpaces(lightSummaryCount)}
          </Title>
        </TitlesWrapper>
        <WidgetsDashboardIcon.ChartIcon onClick={handleToggleView} />
      </Content>
    )
  }, [isGraphView, handleToggleView, lightSummaryCount])

  const renderRows = useCallback((rows) => (
    <RowContainer>
      {rows.map((widget) => {
        const value = infrastructureCount[widget.selector]
        return (
          <Row key={widget.id} disabled={widget.disabled}>
            <RowTitle>
              <Title rowTitle>
                {widget.title}
                {value ? ':' : ''}
              </Title>
            </RowTitle>
            <Title>{numberWithSpaces(value || '')}</Title>
          </Row>
        )
      })}
    </RowContainer>
  ), [infrastructureCount])

  const renderDefaultContent = useCallback(() => (
    <>
      {renderRows(LIGHTS)}
      <Content header>
        <WidgetsDashboardIcon.HighBuildingIcon />
        <TitlesWrapper>
          <Title header>Социальные объекты:</Title>
          <Title description>
            Всего:
            {' '}
            {numberWithSpaces(socialSummaryCount)}
          </Title>
        </TitlesWrapper>
      </Content>
      {renderRows(SOCIALS)}
      <Content header>
        <WidgetsDashboardIcon.RoadIcon />
        <TitlesWrapper>
          <Title header>Дорожная инфраструктура:</Title>
          <Title description>
            Всего:
            {' '}
            {numberWithSpaces(transportSummaryCount)}
          </Title>
        </TitlesWrapper>
      </Content>
      {renderRows(ROADS)}
    </>
  ), [renderRows, socialSummaryCount, transportSummaryCount])

  const renderGraphView = useCallback(() => (
    <>
      <ChartContainer>
        <DoublePieChart
          data={[
            {
              id: 'light',
              name: 'Объекты освещения',
              value: lightSummaryCount,
            },
            {
              id: 'social',
              name: 'Соц. объекты',
              value: socialSummaryCount,
            },
            {
              id: 'roads',
              name: 'Дор. инфраструктура',
              value: transportSummaryCount,
            },
          ]}
          seriesNames={{
            light: {
              fill: SAFFRON,
              fill2: TURMERIC,
            },
            social: {
              fill: PICTON_BLUE,
              fill2: DANUBE,
            },
            roads: {
              fill: ATLANTIS,
              fill2: SUSHI,
            },
          }}
          elementNames={['объект', 'объекта', 'объектов']}
          mainAxisName="dateStep"
          withoutLabel
          withHover
        />
      </ChartContainer>
      <RowContainer last>
        <Row key={1}>
          <RowTitle>
            <Indicator color="#F8D53E" />
            <Title rowTitle>
              Объекты освещения:
            </Title>
          </RowTitle>
          <Title>{numberWithSpaces(lightSummaryCount)}</Title>
        </Row>
        <Row key={2}>
          <RowTitle>
            <Indicator color="#5FB9EC" />
            <Title rowTitle>
              Соц. объекты:
            </Title>
          </RowTitle>
          <Title>{numberWithSpaces(socialSummaryCount)}</Title>
        </Row>
        <Row key={3}>
          <RowTitle>
            <Indicator color="#91C838" />
            <Title rowTitle>
              Дор. инфраструктура:
            </Title>
          </RowTitle>
          <Title>{numberWithSpaces(transportSummaryCount)}</Title>
        </Row>
      </RowContainer>
    </>
  ), [lightSummaryCount, socialSummaryCount, transportSummaryCount])

  return (
    <Container>
      {renderGeneralTitle()}
      {isGraphView ? renderGraphView() : renderDefaultContent()}
    </Container>
  )
}

ObjectsCount.propTypes = {
}
ObjectsCount.defaultProps = {
}

export default ObjectsCount
