import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const ThemIcon = ({ color, onClick }) => {

  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M13.6001 0.10022C14.1524 0.10022 14.6001 0.547935 14.6001 1.10022V1.90022L15.4001 1.90022C15.9524 1.90022 16.4001 2.34793 16.4001 2.90022C16.4001 3.4525 15.9524 3.90022 15.4001 3.90022H14.6001V4.70022C14.6001 5.2525 14.1524 5.70022 13.6001 5.70022C13.0478 5.70022 12.6001 5.2525 12.6001 4.70022V3.90022H11.8001C11.2478 3.90022 10.8001 3.4525 10.8001 2.90022C10.8001 2.34793 11.2478 1.90022 11.8001 1.90022L12.6001 1.90022V1.10022C12.6001 0.547935 13.0478 0.10022 13.6001 0.10022ZM9.2661 0.993068C9.52699 1.25393 9.62306 1.63724 9.51604 1.99031C9.27084 2.79922 9.13863 3.65839 9.13863 4.55025C9.13863 9.41597 13.0831 13.3604 17.9488 13.3604C18.841 13.3604 19.7006 13.2281 20.5098 12.9827C20.8628 12.8756 21.2462 12.9716 21.5071 13.2325C21.768 13.4933 21.864 13.8767 21.757 14.2297C20.4118 18.6676 16.2898 21.8998 11.4103 21.8998C5.43997 21.8998 0.600098 17.0599 0.600098 11.0896C0.600098 6.21057 3.83163 2.08891 8.26883 0.743258C8.62188 0.636188 9.00521 0.732209 9.2661 0.993068ZM7.20509 3.34558C4.46132 4.83873 2.6001 7.74774 2.6001 11.0896C2.6001 15.9553 6.54454 19.8998 11.4103 19.8998C14.7525 19.8998 17.6618 18.0381 19.1548 15.2938C18.7587 15.3378 18.3562 15.3604 17.9488 15.3604C11.9785 15.3604 7.13863 10.5205 7.13863 4.55025C7.13863 4.14327 7.16117 3.74131 7.20509 3.34558ZM18.4001 3.70022C18.9524 3.70022 19.4001 4.14793 19.4001 4.70022V6.70022L21.4001 6.70022C21.9524 6.70022 22.4001 7.14793 22.4001 7.70022C22.4001 8.2525 21.9524 8.70022 21.4001 8.70022L19.4001 8.70022V10.7002C19.4001 11.2525 18.9524 11.7002 18.4001 11.7002C17.8478 11.7002 17.4001 11.2525 17.4001 10.7002V8.70022H15.4001C14.8478 8.70022 14.4001 8.2525 14.4001 7.70022C14.4001 7.14793 14.8478 6.70022 15.4001 6.70022H17.4001V4.70022C17.4001 4.14793 17.8478 3.70022 18.4001 3.70022Z" fill="#838D99"/>
    </svg>
  )
}

ThemIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

ThemIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(ThemIcon)
