import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const PersonalDataIcon = ({ color, onClick }) => {

  return (
    <svg width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M0.899902 4.99999C0.899902 2.45948 2.95939 0.399994 5.4999 0.399994H17.4999C20.0404 0.399994 22.0999 2.45948 22.0999 4.99999V17C22.0999 19.5405 20.0404 21.6 17.4999 21.6H5.4999C2.95939 21.6 0.899902 19.5405 0.899902 17V4.99999ZM5.4999 2.39999C4.06396 2.39999 2.8999 3.56405 2.8999 4.99999V17C2.8999 17.3505 2.96926 17.6848 3.09499 17.9899C3.24246 17.8482 3.43887 17.6791 3.63427 17.5145C3.92625 17.2686 4.29057 16.9718 4.67558 16.6626C5.44652 16.0436 6.31694 15.3618 6.89314 14.913C7.4886 14.4492 8.21884 14.2002 8.97104 14.2002H14.0182C14.784 14.2002 15.5081 14.4609 16.0996 14.8966C16.715 15.35 17.3126 15.7531 17.9296 16.1693C18.0362 16.2413 18.1435 16.3137 18.2515 16.3867C18.8246 16.7744 19.4129 17.178 20.022 17.6341C20.0729 17.4312 20.0999 17.2187 20.0999 17V4.99999C20.0999 3.56405 18.9358 2.39999 17.4999 2.39999H5.4999ZM18.8258 19.237C18.257 18.811 17.6991 18.4277 17.1309 18.0433C17.0268 17.9729 16.922 17.9022 16.8167 17.8312C16.2006 17.4156 15.5649 16.9868 14.9133 16.5068C14.64 16.3054 14.3264 16.2002 14.0182 16.2002H8.97104C8.6615 16.2002 8.36433 16.3022 8.12217 16.4908C7.54847 16.9377 6.68719 17.6123 5.92781 18.2221C5.54767 18.5273 5.19718 18.813 4.92259 19.0443C4.78506 19.1601 4.67069 19.2588 4.58304 19.3374C4.55453 19.363 4.53006 19.3854 4.5094 19.4047C4.81468 19.5305 5.14918 19.6 5.4999 19.6H17.4999C17.9842 19.6 18.4376 19.4676 18.8258 19.237ZM3.39804 18.5307L4.21703 19.262C3.89477 19.0788 3.61548 18.8288 3.39804 18.5307ZM7.06135 8.27203C7.06135 5.85768 9.08631 3.94408 11.4999 3.94408C13.9135 3.94408 15.9385 5.85768 15.9385 8.27203C15.9385 10.6864 13.9135 12.6 11.4999 12.6C9.08631 12.6 7.06135 10.6864 7.06135 8.27203ZM11.4999 5.94408C10.1287 5.94408 9.06135 7.02337 9.06135 8.27203C9.06135 9.5207 10.1287 10.6 11.4999 10.6C12.8711 10.6 13.9385 9.5207 13.9385 8.27203C13.9385 7.02336 12.8711 5.94408 11.4999 5.94408Z" fill="#838D99"/>
    </svg>
  )
}

PersonalDataIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

PersonalDataIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(PersonalDataIcon)
