import React from 'react'
import {
  ApplicationsContainer,
  StyledLink
} from './styles'

import { OBJECTS_URL, PASPORTIZATION_URL } from '@/constants/routes';


import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

import PagesIcons from '@/components/icons/mobileIcons'


              
              
const SwiperDasboard = () => {

  return (
    <ApplicationsContainer>
    <Swiper
      slidesPerView={'auto'}
      spaceBetween={10}
    >
      <SwiperSlide>
        <StyledLink to={OBJECTS_URL}>
          <PagesIcons.MonitoringCenterIcon/>
        </StyledLink></SwiperSlide>
      <SwiperSlide>
        <StyledLink to={PASPORTIZATION_URL}>
          <PagesIcons.PasspotizationIcon/>
      </StyledLink></SwiperSlide>
      <SwiperSlide><PagesIcons.ActionsLogIcon/></SwiperSlide>
      <SwiperSlide><PagesIcons.ConsumptionAnalysisIcon/></SwiperSlide>
      <SwiperSlide><PagesIcons.AlarmsManagerIcon/></SwiperSlide>
      <SwiperSlide><PagesIcons.InstallationIcon/></SwiperSlide>
      <SwiperSlide><PagesIcons.ReportManagerIcon/></SwiperSlide>
      <SwiperSlide><PagesIcons.TimingManagerIcon/></SwiperSlide>
      <SwiperSlide><PagesIcons.LiveTimeIcon/></SwiperSlide>
    </Swiper>
    </ApplicationsContainer>
  )
}

export default SwiperDasboard