import { all } from 'redux-saga/effects'

import getFailureLogTable from './getFailureLogTable'
import getActivityLogTable from './getActivityLogTable'
import uploadSchedule from './uploadSchedule'
import getTree from './getTree'
import getSchedule from './getSchedule'
import createSchedule from './createSchedule'
import editSchedule from './editSchedule'
import deleteSchedule from './deleteSchedule'

export default function* root(){
  yield all([
    getFailureLogTable(),
    getActivityLogTable(),
    uploadSchedule(),
    getTree(),
    getSchedule(),
    createSchedule(),
    deleteSchedule(),
    editSchedule(),
  ])
}