import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export default [
    {
        passportParameter: <Lang id="passportization.fields.generalInfo.name" />,
        showInDashboard: true,
        selector: 'name',
    },
    {
        passportParameter: <Lang id="passportization.fields.generalInfo.identifier" />,
        showInDashboard: true,
        selector: 'objectIdOuter',
    },
    {
        passportParameter: <Lang id="passport.fields.OBJECT_CHARACTERISTIC.EQUIPMENT_TYPE" />,
        showInDashboard: true,
        selector: 'EQUIPMENT_TYPE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.MODEL" />,
        showInDashboard: true,
        selector: 'MODEL',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PRODUCER" />,
        showInDashboard: true,
        selector: 'PRODUCER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.SUPPLIER" />,
        showInDashboard: true,
        selector: 'SUPPLIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.ROOT_OBJECT" />,
        showInDashboard: true,
        selector: 'ROOT_OBJECT',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PARENT_TYPE" />,
        showInDashboard: true,
        selector: 'PARENT_TYPE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PARENT_IDENTIFIER" />,
        showInDashboard: true,
        selector: 'PARENT_IDENTIFIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.REGISTRY_NUMBER" />,
        showInDashboard: true,
        selector: 'REGISTRY_NUMBER',
        isCard: true
    },

    {
        passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.WORKING_TEMPERATURE_RANGE" />,
        showInDashboard: true,
        selector: 'WORKING_TEMPERATURE_RANGE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.WORKING_VOLTAGE_RANGE" />,
        showInDashboard: true,
        selector: 'WORKING_VOLTAGE_RANGE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.POWER_CONSUMPTION" />,
        showInDashboard: true,
        selector: 'POWER_CONSUMPTION',
        isCard: true
    },


    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.START_DATE" />,
        showInDashboard: true,
        selector: 'START_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.EXPLOITATION_END_DATE" />,
        showInDashboard: true,
        selector: 'EXPLOITATION_END_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.ORGANIZATION" />,
        showInDashboard: true,
        selector: 'ORGANIZATION',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.LIFE_TIME" />,
        showInDashboard: true,
        selector: 'LIFE_TIME',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.OWNER" />,
        showInDashboard: true,
        selector: 'OWNER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.BALANCE_SHEET_HOLDER" />,
        showInDashboard: true,
        selector: 'BALANCE_SHEET_HOLDER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.GUARANTEE_PERIOD" />,
        showInDashboard: true,
        selector: 'GUARANTEE_PERIOD',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.PRODUCER_WARRANTY_EXPIRATION_DATE" />,
        showInDashboard: true,
        selector: 'PRODUCER_WARRANTY_EXPIRATION_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.SUPPLIER_WARRANTY_EXPIRATION_DATE" />,
        showInDashboard: true,
        selector: 'SUPPLIER_WARRANTY_EXPIRATION_DATE',
        isCard: true
    },
    

    {
        passportParameter: <Lang id="passport.fields.LOCATION.PROJECT" />,
        showInDashboard: true,
        selector: 'PROJECT',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.LATITUDE" />,
        showInDashboard: true,
        selector: 'latitude',
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.LONGITUDE" />,
        showInDashboard: true,
        selector: 'longitude',
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.REGION" />,
        showInDashboard: true,
        selector: 'REGION',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.CITY" />,
        showInDashboard: true,
        selector: 'CITY',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.STREET" />,
        showInDashboard: true,
        selector: 'STREET',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.HOME_NUMBER" />,
        showInDashboard: true,
        selector: 'HOME_NUMBER',
        isCard: true
    },



    {
        passportParameter: <Lang id="passport.fields.MANAGEMENT_ORGANIZATION_SYSTEM.CONNECTOR" />,
        showInDashboard: true,
        selector: 'CONNECTOR',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.MANAGEMENT_ORGANIZATION_SYSTEM.DEVICE_SERIAL_NUMBER" />,
        showInDashboard: true,
        selector: 'DEVICE_SERIAL_NUMBER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.MANAGEMENT_ORGANIZATION_SYSTEM.CONN_TYPE" />,
        showInDashboard: true,
        selector: 'CONN_TYPE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.MANAGEMENT_ORGANIZATION_SYSTEM.SWVER" />,
        showInDashboard: true,
        selector: 'SWVER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.MANAGEMENT_ORGANIZATION_SYSTEM.ICCID" />,
        showInDashboard: true,
        selector: 'ICCID',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.MANAGEMENT_ORGANIZATION_SYSTEM.TIME_ZONE" />,
        showInDashboard: true,
        selector: 'TIME_ZONE',
        isCard: true
    },


    {
        passportParameter: <Lang id="passport.fields.SERVICING.PLAN_PPR_DATE" />,
        showInDashboard: true,
        selector: 'PLAN_PPR_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.FACT_PPR_DATE" />,
        showInDashboard: true,
        selector: 'FACT_PPR_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.MODERNIZATION" />,
        showInDashboard: true,
        selector: 'MODERNIZATION',
        isCard: true
    },
    
]
