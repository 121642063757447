import { connect } from 'react-redux'
import {
  getObjectState,
  getServiceDeskWidget,
  getTyumenOurHomeData,
  getGraphData,
  getDepreciationEquipment,
  getAutomaticReporting,
  getRequestState,
  getInfrastructureCount,
} from '@/store/selectors/widgetDashboard'
import {
  getGraphDataRequest,
} from '@/store/actions/widgetDashboard/graphData'
import {
  getObjectDataRequest,
} from '@/store/actions/widgetDashboard/objectState'
import {
  getTyumenOurHomeRequest,
} from '@/store/actions/widgetDashboard/tyumenOurHome'
import { getDepreciationEquipmentRequest } from '@/store/actions/widgetDashboard/depreciationEquipment'
import { getAutomaticReportingRequest } from '@/store/actions/widgetDashboard/automaticReporting'
import { setFakeNode } from '@/store/actions/appSettings'
import {
  getWidgets,
  getApplications,
  getSideBarMenuPinnedState,
  getIsMenuOpen,
  getUserData,
  getFakeNode,
  getGlobalFilters,
} from '@/store/selectors/appSettings'
import { getServiceDeskWidgetRequest } from '@/store/actions/widgetDashboard/serviceDeskWidget'
import Dashboard from './component'
import { getTreeData } from '@/store/selectors/passportization';
import { getInfrastructureCountRequest } from '@/store/actions/widgetDashboard/infrastructureCount';

const mapStateToProps = (state) => ({
  tree: getTreeData(state),
  selectedNode: getFakeNode(state),
  selectedWidgets: [
    ...getWidgets(state),
    ...getApplications(state),
  ],
  objectState: getObjectState(state),
  serviceDeskWidget: getServiceDeskWidget(state),
  graphData: getGraphData(state),
  tyumenOurHomeData: getTyumenOurHomeData(state),
  isMenuPenned: getSideBarMenuPinnedState(state),
  isMenuOpen: getIsMenuOpen(state),
  depreciationEquipment: getDepreciationEquipment(state),
  automaticReporting: getAutomaticReporting(state),
  user: getUserData(state),
  requestState: getRequestState(state),
  infrastructureCount: getInfrastructureCount(state),
  globalFilters: getGlobalFilters(state),
})

const mapDispatchToProps = (dispatch) => ({
  getObjectDataRequest: (payload) => dispatch(getObjectDataRequest(payload)),
  getServiceDeskWidgetRequest: (payload) => dispatch(getServiceDeskWidgetRequest(payload)),
  setFakeNode: (payload) => dispatch(setFakeNode(payload)),
  getGraphData: (payload) => dispatch(getGraphDataRequest(payload)),
  getTyumenOurHome: (payload) => dispatch(getTyumenOurHomeRequest(payload)),
  getDepreciationEquipmentRequest: (payload) => dispatch(getDepreciationEquipmentRequest(payload)),
  getAutomaticReportingRequest: (payload) => dispatch(getAutomaticReportingRequest(payload)),
  getInfrastructureCountRequest: (payload) => dispatch(getInfrastructureCountRequest(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
