import createActions from '@/helpers/redux/createAction'

export const MAP_OBJECTS_NOTIFICATIONS = createActions('@/MAP_OBJECTS_NOTIFICATIONS')
export const MAP_OBJECTS_ERRORS = createActions('@/MAP_OBJECTS_ERRORS')
export const GET_MAP_OBJECTS = createActions('@/GET_MAP_OBJECTS')
export const GET_MAP_STATISTIC = createActions('@/GET_MAP_STATISTIC')
export const REFRESH_WIDGET_MAP = createActions('@/REFRESH_WIDGET_MAP')
export const GET_OBJECT_IMAGE = createActions('@/GET_OBJECT_IMAGE')
export const CHANGE_OBJECT_STATE = createActions('@/CHANGE_OBJECT_STATE')

export const UPDATE_MAP_DATA = '@/UPDATE_MAP_DATA'
export const SET_ZONES = '@/SET_ZONES'

export const SET_WIDGET_SETTINGS = '@/SET_WIDGET_SETTINGS'
export const SET_ACY_WIDGET_SETTINGS = '@/SET_WIDGET_SETTINGS'
export const UPDATE_WIDGET_SETTINGS = '@/UPDATE_WIDGET_SETTINGS'
