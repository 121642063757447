import widgets from '../widgets'

const {
  CONTACTOR_ON,
  ACTIVE_COMMAND,
  MODE,
  CONTROL_TYPE,
  SWITCH_TIME,
  WILL_SWITCH,
  STATUS
} = widgets

export default [
  STATUS({}),
  CONTACTOR_ON({}),
  ACTIVE_COMMAND({}),
  MODE({}),
  CONTROL_TYPE({}),
  SWITCH_TIME({}),
  WILL_SWITCH({}),
]
