import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import generateWidget from './generateWidget'

const DATA_TIME = generateWidget({
  config: {
    id: 'DATA_TIME',
    title: <Lang id="widgets.currentMode" />,
    type: 'workTime',
    statusSelector: 'turnedOn',
    workTimeSelector: 'workTime',
  },
})

export default {
  DATA_TIME,
}
