import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import qs from 'query-string'
import {
  GET_APPEALS_TABLE,
  APPEALS_TABLE,
  SET_SELECTED_ELEMENT,
} from '@/store/actions/serviceDeskMyCity'
import moment from 'moment'
import { SERVICE_DESK_GET_APPEALS_URL } from '@/constants/apiRoutes'
import {
  successAppealsTable,
  errorAppealsTable,
} from '@/store/actions/serviceDeskMyCity/getTable'
import {
  getTableParameters,
} from '@/store/selectors/serviceDeskMyCity'
import {
  OBJECT,
  OBJECT_TYPE,
  TASK_STATUS,
  PLANNED_DUE_DATE,
  ACTUAL_DATE_OF_EXECUTION,
  EXECUTION_EXPIRED,
  TASK,
  APPEALS_BLOCK,
  OBJECT_TYPE_BLOCK,
  OBJECTS_BLOCK,
} from '@/constants/serviceDeskConfig/nameConfig'
import {
  getIntegrationStatus,
  getUserApplications,
  getUserData,
} from '@/store/selectors/appSettings'
import {
  getRouterLocation,
} from '@/store/selectors/router'
import {
  SERVICE_DESK,
} from '@/constants/applications'
import { setGlobalLoading } from '@/store/actions/appSettings'
import { statusSD } from '@/forms/ServiceDeskMyCityForm/config'

function* getServiceDeskTableSaga({ payload }) {
  yield put(setGlobalLoading(true))
  const { urlParse, serviceDeskId, serviceDeskConfig } = payload
  const integrationStatus = yield select(getIntegrationStatus)
  const userApplications = yield select(getUserApplications)
  const currentLocation = yield select(getRouterLocation)
  const userData = yield select(getUserData)
  const currentQuery = urlParse || qs.parse(currentLocation.search)
  const currentServiceDesk = integrationStatus.find(
    (element) => (
      userData.roles.isSuperAdmin
        ? currentQuery.widgetId === element.temporaryId
        : currentQuery.widgetId === element.widgetId
    ),
  )
  const currentServiceDeskConfig = serviceDeskConfig || currentServiceDesk.propertyConfigContent
  const fields = {
    essenceOfAppeal: 'DETAIL_TEXT',
    registrationDate: 'DATE_CREATE',
    search: '%25DETAIL_TEXT',
    object: `PROPERTY_${currentServiceDeskConfig[OBJECT].id}`,
    objectType: `PROPERTY_${currentServiceDeskConfig[OBJECT_TYPE].id}`,
    status: `PROPERTY_${currentServiceDeskConfig[TASK_STATUS].id}`,
    plannedExecutionDate: `PROPERTY_${currentServiceDeskConfig[PLANNED_DUE_DATE].id}`,
    actualDateOfExecution: `PROPERTY_${currentServiceDeskConfig[ACTUAL_DATE_OF_EXECUTION].id}`,
    startDate: '%3E%3DDATE_CREATE',
    endDate: '%3C%3DDATE_CREATE',
    overdue: `PROPERTY_${currentServiceDeskConfig[EXECUTION_EXPIRED].id}`,
  }
  const serviceDesk = userApplications.find((application) => application.code === SERVICE_DESK)
  const selectedServiceDeskId = serviceDeskId
    || (currentServiceDesk && currentServiceDesk.id)
    || (serviceDesk && JSON.parse(serviceDesk.settings).id)
  const urlKeys = currentQuery ? Object.keys(currentQuery).map((element) => fields[element]) : []
  const urlValues = currentQuery ? Object.values(currentQuery) : []
  const urlParams = urlKeys.map((element, index) => {
    if (element === fields.overdue && urlValues[index] === 'true') {
      return `FILTER[${element}]=1`
    }
    if (element === fields.overdue && urlValues[index] === 'false') {
      return 'false'
    }
    if (element === fields.startDate) {
      return `FILTER[${element}]=${moment(urlValues[index]).format('YYYY-MM-DD 00:00:00')}`
    }
    if (element === fields.endDate) {
      return `FILTER[${element}]=${moment(urlValues[index]).format('YYYY-MM-DD 23:59:59')}`
    }
    if (element) {
      return `FILTER[${element}]=${urlValues[index]}`
    }
    return ''
  }).join('&')
  try {
    const tableParameters = yield select(getTableParameters)
    const typeOfObjects = yield request({
      url: SERVICE_DESK_GET_APPEALS_URL,
      method: 'post',
      body: {
        integrationId: selectedServiceDeskId,
        parameters: `IBLOCK_TYPE_ID=lists_socnet&IBLOCK_ID=${currentServiceDeskConfig[OBJECT_TYPE_BLOCK].idInfoBlock}&start=-1`,
        restMethod: '/lists.element.get.json',
      },
    })
    const appealsTable = yield request({
      url: SERVICE_DESK_GET_APPEALS_URL,
      method: 'post',
      body: {
        integrationId: selectedServiceDeskId,
        parameters: `IBLOCK_TYPE_ID=lists_socnet&IBLOCK_ID=${currentServiceDeskConfig[APPEALS_BLOCK].idInfoBlock}&start=${(tableParameters.page - 1) * 50}&ELEMENT_ORDER[${fields[tableParameters.sort.field]}]=${tableParameters.sort.direction}${urlParams ? `&${urlParams}` : ''}`,
        restMethod: '/lists.element.get.json',
      },
    })

    const listOfObjects = appealsTable.data.result.map((element) => (
      element[`PROPERTY_${currentServiceDeskConfig[OBJECT].id}`] ? +Object.values(element[`PROPERTY_${currentServiceDeskConfig[OBJECT].id}`])[0] : ''
    ))
    const uniqueArray = listOfObjects.filter((item, index) => (
      typeof item === 'number' && listOfObjects.indexOf(item) === index
    ))
    const objectParams = uniqueArray.map((element, index) => (
      `FILTER[id][${index}]=${element}`
    ))
    const objects = yield request({
      url: SERVICE_DESK_GET_APPEALS_URL,
      method: 'post',
      body: {
        integrationId: selectedServiceDeskId,
        parameters: `IBLOCK_TYPE_ID=lists_socnet&IBLOCK_ID=${currentServiceDeskConfig[OBJECTS_BLOCK].idInfoBlock}&start=-1&${objectParams.join('&')}`,
        restMethod: '/lists.element.get.json',
      },
    })
    const content = appealsTable.data.result.map((element) => ({
      essenceOfAppeal: element.DETAIL_TEXT,
      registrationDate: element.DATE_CREATE,
      object: element[`PROPERTY_${currentServiceDeskConfig[OBJECT].id}`]
        ? objects.data.result.find((item) => item.ID === Object.values(element[`PROPERTY_${currentServiceDeskConfig[OBJECT].id}`])[0]).NAME
        : '',
      objectType: element[`PROPERTY_${currentServiceDeskConfig[OBJECT_TYPE].id}`]
        ? typeOfObjects.data.result.find((item) => item.ID === Object.values(element[`PROPERTY_${currentServiceDeskConfig[OBJECT_TYPE].id}`])[0]).NAME
        : '',
      status: element[`PROPERTY_${currentServiceDeskConfig[TASK_STATUS].id}`] ? statusSD[Object.values(element[`PROPERTY_${currentServiceDeskConfig[TASK_STATUS].id}`])[0]] : '',
      plannedExecutionDate: element[`PROPERTY_${currentServiceDeskConfig[PLANNED_DUE_DATE].id}`] ? Object.values(element[`PROPERTY_${currentServiceDeskConfig[PLANNED_DUE_DATE].id}`])[0] : '',
      actualDateOfExecution: element[`PROPERTY_${currentServiceDeskConfig[ACTUAL_DATE_OF_EXECUTION].id}`] ? Object.values(element[`PROPERTY_${currentServiceDeskConfig[ACTUAL_DATE_OF_EXECUTION].id}`])[0] : '',
      overdue: element[`PROPERTY_${currentServiceDeskConfig[EXECUTION_EXPIRED].id}`] ? +Object.values(element[`PROPERTY_${currentServiceDeskConfig[EXECUTION_EXPIRED].id}`])[0] : null,
      taskNumber: element[`PROPERTY_${currentServiceDeskConfig[TASK].id}`] ? Object.values(element[`PROPERTY_${currentServiceDeskConfig[TASK].id}`])[0] : '',
      id: element.ID,
    }))
    const newTypeOfObject = typeOfObjects.data.result.map((element) => ({
      value: element.ID,
      title: element.NAME,
    }))
    yield put(setGlobalLoading(false))
    yield put(successAppealsTable({
      data: content,
      totalElements: appealsTable.data.total,
      typeOfObjects: newTypeOfObject,
    }))
  } catch (error) {
    yield put(errorAppealsTable(error))
  }
}

export default function* root() {
  yield takeLatest(GET_APPEALS_TABLE.REQUEST, getServiceDeskTableSaga)
  yield takeLatest(APPEALS_TABLE.SORT, getServiceDeskTableSaga)
  yield takeLatest(APPEALS_TABLE.PER_PAGE, getServiceDeskTableSaga)
  yield takeLatest(APPEALS_TABLE.PAGE, getServiceDeskTableSaga)
  yield takeLatest(APPEALS_TABLE.SELECTED_FIELDS, getServiceDeskTableSaga)
  yield takeLatest(APPEALS_TABLE.REQUEST_QUERY, getServiceDeskTableSaga)
  yield takeLatest(SET_SELECTED_ELEMENT, getServiceDeskTableSaga)
}
