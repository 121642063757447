import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  successGetTenantParents,
  errorGetTenantParents,
} from '@/store/actions/installation/getTenantParrents'
import getGeoZoneFromTree from '@/helpers/getGeoZoneFromTree'
import { generateGetTreeWithStatus } from '@/constants/apiRoutes'
import { GET_TENANT_PARENTS } from '@/store/actions/installation'
import { PROJECT } from '@/constants/objectTypes';
import createNotifications from "@/helpers/notification";

function* getRootElementSaga({ payload: { id, type } }) {
  try {
    const { data } = yield request({
      url: generateGetTreeWithStatus(id),
      method: 'get',
    })
    const formattedData = getGeoZoneFromTree(data, type === PROJECT ? 'currentTreeElementId' : 'treeElementId')
    if (type) {
      const filterElements = formattedData.filter((element) => element.type === type)
      yield put(successGetTenantParents(filterElements))
    } else {
      yield put(successGetTenantParents(formattedData))
    }
  } catch (error) {
    yield put(errorGetTenantParents([]))
    console.log('function*getRootElementSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось получить данные. Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_TENANT_PARENTS.REQUEST, getRootElementSaga)
}
