import createSubscriptionChannel from '@/store/sagas/createSubscriptionChannel'
import {
    generateInstallationUploadProgressTopic,
    INSTALLATION_UPLOAD_NOTIFICATION
} from '@/constants/socket/events'

export const createInstallationUploadChannel = () => (
  createSubscriptionChannel(INSTALLATION_UPLOAD_NOTIFICATION)
)

export const createInstallationUploadProgressChannel = (id) => {
    return createSubscriptionChannel(generateInstallationUploadProgressTopic(id))
}

export default createInstallationUploadChannel
