import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import WidgetsDashboardIcon from '@/components/icons/widgetsDashboard'

export default {
  preview: [
    {
      name: <Lang id="widgets.callsTotal" />,
      selector: 'totalAppealsAmount',
    },
    {
      name: <Lang id="widgets.averageDecisionTime" />,
      selector: 'averageCompletionTime',
    },
  ],
  main: [
    [
      [
        {
          name: <Lang id="widgets.inWork" />,
          valueSelector: 'numberInProgress',
          totalSelector: 'totalAppealsAmount',
          type: 'progressBar',
          message: 'warning',
        },
      ],
      [
        {
          name: <Lang id="widgets.planned" />,
          valueSelector: 'numberPlanned',
          totalSelector: 'totalAppealsAmount',
          type: 'progressBar',
          message: 'fix',
        },
        {
          name: <Lang id="widgets.problemsSolved" />,
          selector: 'numberProblemSolved',
        },
        {
          name: <Lang id="widgets.refuse" />,
          selector: 'numberRefused',
        },
        {
          name: <Lang id="widgets.problemNotFound" />,
          selector: 'numberProblemNotFound',
        },
      ],
      [
        {
          name: <Lang id="widgets.averageDecisionTime" />,
          selector: 'averageCompletionTime',
        },
      ],
    ],
    [
      [
        {
          name: <Lang id="widgets.callsTotal" />,
          selector: 'totalAppealsAmount',
        },
      ],
      [
        {
          name: <Lang id="widgets.light" />,
          selector: 'outdoorLightningCount',
        },
        {
          name: <Lang id="widgets.roadRepairingCount" />,
          selector: 'roadRepairingCount',
        },
        {
          name: <Lang id="widgets.transportStationCount" />,
          selector: 'transportStationCount',
        },
        {
          name: <Lang id="widgets.notAvailable1" />,
          selector: 'notAvailable1',
          disabled: true,
        },
        {
          name: <Lang id="widgets.notAvailable2" />,
          selector: 'notAvailable2',
          disabled: true,
        },
      ],
    ],
  ],
  view: {
    title: <Lang id="widgetsDashboard.tyumenOurHomeBigWidget" />,
    icon: WidgetsDashboardIcon.TyumenOurHomeBigWidgetIcon,
  },
}
