import styled from 'styled-components'
import Button from '@/components/blocks/Button'
import LabelWithIcon from "@/components/blocks/LabelWithIcon";

export const MessageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding: 10px;
  border-radius: 4px;
  background: #D9E6F9;
  color: #0C4BAD;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 500;
  text-align: left;
  svg {
    width: 22px;
    margin: -1px 5px 0 0;
  }
`

export const FieldValueWrapper = styled.div`
  position: relative;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #BDC3C7;
  padding: 0 10px 10px 10px;
  margin-top: 15px;
`

export const InputAndLabelContainer = styled.div`
  padding: 5px 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  && > div {
    text-align: left;
  }
  input {
    width: 100%;
    height: 28px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
  ${({ row }) => row && `
    justify-content: space-between;
    flex-direction: row;
  `}
  ${LabelWithIcon} {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.4rem;
    color: ${({ theme }) => theme.colors.colors.redisign.default};
    margin-bottom: 5px;
  }
`

export const IconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${({ type, theme }) => type && `
    svg,
    .svgFill {
      height: 14px;
      width: 14px;
      margin-right: 10px;
      fill: ${theme.colors.colors.redisign.dark};
    }
  `}
`

export const CloseIconContainer = styled.div`
  position: absolute;
  right: 5px;
  top: 5px;
  cursor: pointer;
`

export const SubmitButton = styled(Button)`
  width: 100%;
  padding: 7px;
  margin-top: 15px;
  color: ${({ theme }) => theme.colors.colors.light};
  background: ${({ theme }) => theme.colors.scheme.backgrounds.fuelYellow};
  text-transform: inherit;
  :hover {
    background: ${({ theme }) => theme.colors.scheme.backgrounds.fuelYellow};
  }
`

export const ChangeButton = styled(Button)`
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 7px;
  color: #3E3C3E;
  margin-top: 15px;
  background: #DDDDDD;
  text-transform: inherit;
  :hover {
    background: #DDDDDD;
    color: #3E3C3E;
  }
  svg {
    width: 12px;
    margin-left: 5px;
  }
`

export const TableWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 990px;
  width: calc(100% - 990px);
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
  height: 100%;
  text-align: left;
  ${({small}) => small &&`
    left: 768px;
    width: calc(100% - 768px);
  `
  }}
   ${({isTreeOpen}) => isTreeOpen &&`
    left: 739px;
    width: calc(100% - 739px);
  `
  }}
  ${({small, isTreeOpen}) => small && isTreeOpen &&`
    left: 517px;
    width: calc(100% - 517px);
  `
  }}
`