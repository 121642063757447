import {
  takeLatest,
} from 'redux-saga/effects'
import { UPDATE_INTEGRATION } from '@/store/actions/integrations'
import request from '@/helpers/axios'
import { generateIntegrationTypeWithIdUrl } from '@/constants/apiRoutes'
import {
  PASSWORD,
  PORT,
  NAME,
  PERIOD,
} from '@/constants/forms/integration'
import createNotifications from '@/helpers/notification'

function* updateIntegrationSaga({ payload }) {
  try {
    const { values, type } = payload
    const valuesCopy = { ...values }
    delete valuesCopy.id
    delete valuesCopy.unit
    delete valuesCopy[NAME]
    delete valuesCopy[PERIOD]

    if (!valuesCopy[PORT]) {
      delete valuesCopy[PORT]
    }

    const dataJson = {
      aliasId: values.id,
      [NAME]: values[NAME],
      [PERIOD]: Number.parseInt(values[PERIOD], 10) || 12,
      integrationType: type,
      connectionSettings: valuesCopy,
    }

    if (!values[PORT]) {
      delete dataJson[PORT]
    }
    if (!values[PASSWORD]) {
      dataJson[PASSWORD] = null
    }
    yield request({
      url: generateIntegrationTypeWithIdUrl(type.toLowerCase(), values.id),
      method: 'put',
      body: dataJson,
    })
  } catch (error) {
    console.log('TCL: function*updateIntegrationSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(UPDATE_INTEGRATION.REQUEST, updateIntegrationSaga)
}
