import React from 'react';
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import DraggableItemWrapper, { MoveIconWrapper } from './styles'
import HeaderIcons from '@/components/icons/header'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import { FormattedMessage as Lang } from 'react-intl'
import { WidgetHeaderIcon } from '@/components/widgets/AnalyticsAlarmsWidget/styles'

const DraggableItem = ({ id, movable, children, noGroups }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition
  } = useSortable({ id: id })

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  return (
   <DraggableItemWrapper ref={setNodeRef} style={style} noGroups={noGroups}>
       {children}
       {movable &&
           <MoveIconWrapper {...listeners} {...attributes}>
              <PortalTooltip
                 title={(<Lang id="tooltip.move" />)}
                 renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                   <WidgetHeaderIcon
                      ref={wrapperRef}
                      onMouseEnter={onMouseEnterHandler}
                      onMouseLeave={onMouseLeaveHandler}
                   >
                    <HeaderIcons.MenuHamburgerIcon />
                   </WidgetHeaderIcon>
                )}
              />
           </MoveIconWrapper>
       }
   </DraggableItemWrapper>
  )
}

export default DraggableItem