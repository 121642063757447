import { GET_ROOT_ELEMENTS, GET_TREE_ELEMENTS } from './index'

export const requestGetRootElements = (payload) => ({
  type: GET_ROOT_ELEMENTS.REQUEST,
  payload,
})

export const successGetRootElements = (payload) => ({
  type: GET_ROOT_ELEMENTS.SUCCESS,
  payload,
})

export const errorGetRootElements = (payload) => ({
  type: GET_ROOT_ELEMENTS.ERROR,
  payload,
})

export const successGetTreeElements = (payload) => ({
  type: GET_TREE_ELEMENTS.SUCCESS,
  payload,
})

