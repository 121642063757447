import React from 'react'
import AlarmsIcons from '@/components/icons/alarms'
import {
  OK,
  WARNING,
  ERROR,
  UNKNOWN
} from '@/constants/alarms'
import {
  AlarmCell,
  AlarmEventContainer,
} from './styles'

const StatusToIcon = {
  [OK]: AlarmsIcons.OkIcon,
  [WARNING]: AlarmsIcons.WarningIcon,
  [ERROR]: AlarmsIcons.ErrorIcon,
  [UNKNOWN]: AlarmsIcons.UnknownIcon,
}
const CustomEventCell = (cell) => {
  const Icon = StatusToIcon[cell.value]
  return (
    <AlarmCell {...cell.getCellProps()}>
      <AlarmEventContainer type={cell.value}>
        {Icon && <Icon />}
      </AlarmEventContainer>
    </AlarmCell>
  )
}

export default CustomEventCell
