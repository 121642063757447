import React, { useEffect, useState, useMemo } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { isEmpty } from 'lodash'
import useReduxTable from '@/hooks/useReduxTable'
import { FAULT_ANALYTICS_TABLE } from '@/store/actions/faultAnalytics'
import { getTableParameters } from '@/store/selectors/faultAnalytics'
import useGetIsMobile from '@/hooks/useGetIsMobile'
import {
  fields,
  exportOptions,
} from '@/constants/tablesConfig/faultAnalytics'
import Loader from '@/components/blocks/Loader'
import DutyTable from '@/components/blocks/DutyTable'
import ObjectCard from '@/components/blocks/ObjectCard'
import REQUEST_STATUSES from '@/constants/requests'
import HeaderMobile from './HeaderMobile/component'
import CoreIcon from '@/components/icons/core'
import HeaderIcons from '@/components/icons/header'
import EventFilterForm from '@/forms/EventFilterForm'
import ExportStatisticShutter from '@/components/blocks/ExportStatisticShutter'
import { Main } from './styles'
import {treeHandlers} from "react-hyper-tree";


const FaultAnalytics = ({
  data,
  exportFileStatus,
  loading,
  setSelectedElement,
  requestSummaryAlarmAnalyticsTable,
  requestSummaryAlarmAnalyticsFile,
  requestState,
  globalLoading,
  eventFilterValues,
  setNode,
  tree,
  searchFieldsOptions,
  requestGetSearchFieldsOptions,
  connectedIntegrations,
  requestGetAllConnectedIntegrations,
  filterOptions,
  resetFilterValues,
  setFilterValues,
  searchFieldsOptionsLoading
}) => {
  useEffect(() => {
    if (requestState.tree === REQUEST_STATUSES.IDLE) {
      requestSummaryAlarmAnalyticsTable()
    }
  }, [requestSummaryAlarmAnalyticsTable, requestState])

  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [openTypePopup, setOpenTypePopup] = useState(null)

  const isMobile = useGetIsMobile()

  const tableProps = useReduxTable(FAULT_ANALYTICS_TABLE, getTableParameters)

  const toggleFilterPopup = () => {
    setIsOpenPopup(!isOpenPopup)
    setOpenTypePopup('filter')
  }

  const toggleExportPopup = () => {
    setIsOpenPopup(!isOpenPopup)
    setOpenTypePopup('sort')
  }

  const onSetValues = (form) => {
    setIsOpenPopup(false)
    setFilterValues(form)
  }

  const onResetValues = () => {
    setIsOpenPopup(false)
    resetFilterValues()
  }

  const onRowSelect = (row) => {
    const tree = treeHandlers.trees['analytics-alarms-tree']
    const geoZone = tree.instance.getNodeById(row.id).data
    const node = {...geoZone, original: {options: {path: `/${geoZone.parentId}/${geoZone.id}`}}}
    setNode(node)
  }

  const controls = [
    {
      icon: <CoreIcon.FileExport />,
      name: 'CoreIcon.FileExport',
      onClick: toggleExportPopup,
      onClose: toggleExportPopup,
    },
    {
      icon: isEmpty(filterOptions) 
      ? <HeaderIcons.GlobalFilterIcon /> 
      : <HeaderIcons.ActiveGlobalFilterIcon />,
      name: 'HeaderIcons.GlobalFilterIcon',
      onClick: toggleFilterPopup,
    }
  ]

    const contentPopup = useMemo(() => {
      if (openTypePopup === 'filter') {
        return (
          <EventFilterForm
            globalFiltersValues={filterOptions}
            onSetValues={onSetValues}
            onResetValues={onResetValues}
            onClose={toggleFilterPopup}
            requestGetSearchFieldsOptions={requestGetSearchFieldsOptions}
            searchFieldsOptions={searchFieldsOptions}
            connectedIntegrations={connectedIntegrations}
            requestGetAllConnectedIntegrations={requestGetAllConnectedIntegrations}
            withoutAlerTypes
            searchFieldsOptionsLoading={searchFieldsOptionsLoading}
          />
        )
      }
      if (openTypePopup === 'sort') {
        return (
          <ExportStatisticShutter 
            title={'Экспорт статистики:'} 
            onClose={toggleExportPopup} 
            withName 
            {...tableProps} 
            onFileSelect={requestSummaryAlarmAnalyticsFile} 
            allColumns={fields.map((field)=> field.id)}
            />
        )
      }
    }, [
        openTypePopup, 
        data, 
        eventFilterValues, 
        connectedIntegrations, 
        onResetValues, 
        onSetValues, 
        requestGetAllConnectedIntegrations, 
        requestGetSearchFieldsOptions, 
        searchFieldsOptions,
        requestSummaryAlarmAnalyticsFile,
        tableProps,
        filterOptions,
        searchFieldsOptionsLoading
      ])
  if (isMobile) {
    return (
      <Main>
        <HeaderMobile title={'Аналитика неисправностей'} controls={controls} content={contentPopup} openPopup={isOpenPopup} opacyti={true}/>
        <ObjectCard data={data} fields={fields} button setNode={setNode} tree={tree} maxWidth/>
      </Main>
    )
  }


  return loading
    ? <Loader center />
    : (
      <DutyTable
        {...tableProps}
        fields={fields}
        exportOptions={exportOptions}
        data={data}
        rowSelector={onRowSelect}
        onFileSelect={requestSummaryAlarmAnalyticsFile}
        onUpdateData={requestSummaryAlarmAnalyticsTable}
        fileStatus={exportFileStatus}
        isDataLoading={globalLoading}
      />
    )
}

FaultAnalytics.propTypes = {
  data: pt.arrayOf(pt.object),
  exportFileStatus: pt.string,
  loading: pt.bool,
  selectedNode: pt.objectOf(pt.object),
  updateTableSorting: pt.func,
  setSelectedElement: pt.func,
  setEventTypes: pt.func,
  setStatusTypes: pt.func,
  requestSummaryAlarmAnalyticsTable: pt.func,
  requestSummaryAlarmAnalyticsFile: pt.func,
}
FaultAnalytics.defaultProps = {
  data: [],
  exportFileStatus: '',
  loading: false,
  selectedNode: {},
  setSelectedElement: noop,
  updateTableSorting: noop,
  setEventTypes: noop,
  setStatusTypes: noop,
  requestSummaryAlarmAnalyticsTable: noop,
  requestSummaryAlarmAnalyticsFile: noop,
}

export default FaultAnalytics
