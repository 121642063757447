import {
  HOURLY,
  DAILY,
  WEEKLY,
  MONTHLY,
  HOURLY_TIME,
  REPLAY_WEEKLY,
  REPLAY_MONTHLY,
} from '@/constants/forms/reportManager'

export const REPEAT_VALUE = {
  [HOURLY]: HOURLY_TIME,
  [WEEKLY]: REPLAY_WEEKLY,
  [MONTHLY]: REPLAY_MONTHLY,
}

export const REPEAT_CONFIG = [
  {
    value: HOURLY,
    title: 'Каждые',
  },
  {
    value: DAILY,
    title: 'Ежедневно',
  },
  {
    value: WEEKLY,
    title: 'Еженедельно',
  },
  {
    value: MONTHLY,
    title: 'Ежемесячно',
  },
]

const setArrayOfNumbers = (value) => {
  const arrayHours = []
  for (let index = 1; index <= value; index += 1) {
    arrayHours.push({
      value: index,
      title: index,
    })
  }
  return arrayHours
}

export const REPEAT_HOURS_CONFIG = setArrayOfNumbers(23)
export const REPEAT_MINUTES_CONFIG = setArrayOfNumbers(60)
export const REPEAT_MONTHLY_CONFIG = setArrayOfNumbers(31)

export const REPEAT_WEEKLY_CONFIG = [
  {
    value: '1',
    title: 'Пн',
  },
  {
    value: '2',
    title: 'Вт',
  },
  {
    value: '3',
    title: 'Ср',
  },
  {
    value: '4',
    title: 'Чт',
  },
  {
    value: '5',
    title: 'Пт',
  },
  {
    value: '6',
    title: 'Сб',
  },
  {
    value: '7',
    title: 'Вс',
  },
]

export const TIMEZONE_CONFIG = [
  {
    value: 60 * 3,
    title: 'Московское время MSK (UTC+3)',
  },
  {
    value: 60 * 2,
    title: 'Калининградское время MSK–1 (UTC+2)',
  },
  {
    value: 60 * 4,
    title: 'Самарское время MSK+1 (UTC+4)',
  },
  {
    value: 60 * 5,
    title: 'Екатеринбургское время MSK+2 (UTC+5)',
  },
  {
    value: 60 * 6,
    title: 'Омское время MSK+3 (UTC+6)',
  },
  {
    value: 60 * 7,
    title: 'Красноярское время MSK+4 (UTC+7)',
  },
  {
    value: 60 * 8,
    title: 'Иркутское время MSK+5 (UTC+8)',
  },
  {
    value: 60 * 9,
    title: 'Якутское время MSK+6 (UTC+9)',
  },
  {
    value: 60 * 10,
    title: 'Владивостокское время MSK+7 (UTC+10)',
  },
  {
    value: 60 * 11,
    title: 'Магаданское время MSK+8 (UTC+11)',
  },
  {
    value: 60 * 12,
    title: 'Камчатское время MSK+9 (UTC+12)',
  },
  {
    value: 60 * 1,
    title: 'MSK–2 (UTC+1)',
  },
  {
    value: 0,
    title: 'MSK–3 (UTC+0)',
  },
  {
    value: 60 * -1,
    title: 'MSK–4 (UTC−1)',
  },
  {
    value: 60 * -2,
    title: 'MSK–5 (UTC−2)',
  },
  {
    value: 60 * -3,
    title: 'MSK–6 (UTC−3)',
  },
  {
    value: 60 * -4,
    title: 'MSK–7 (UTC−4)',
  },
  {
    value: 60 * -5,
    title: 'MSK–8 (UTC−5)',
  },
  {
    value: 60 * -6,
    title: 'MSK–9 (UTC−6)',
  },
  {
    value: 60 * -7,
    title: 'MSK–10 (UTC−7)',
  },
  {
    value: 60 * -8,
    title: 'MSK–11 (UTC−8)',
  },
  {
    value: 60 * -9,
    title: 'MSK–12 (UTC−9)',
  },
  {
    value: 60 * -10,
    title: 'MSK–13 (UTC−10)',
  },
  {
    value: 60 * -11,
    title: 'MSK–14 (UTC−11)',
  },
  {
    value: 60 * -12,
    title: 'MSK–15 (UTC−12)',
  },
]
