import { connect } from 'react-redux'
import {
  setACYWidgetSettings,
} from '@/store/actions/maps/widgetSettings'
import { getRequestStreamUrl, setRequestStreamUrl } from '@/store/actions/installation'
import {
  getACYWidgetsSettings,
} from '@/store/selectors/maps/widgetSettings'

import ObjectPassportization from './component'
import {
    getCard,
    getNodeStatistic,
    getObjectTelemetry,
    getRoot,
    getStreamUrl,
} from '@/store/selectors/installation';

import { setUnpinNode, setSelectedNode } from '@/store/actions/appSettings'

export default connect(
  (state) => ({
    ACYWidgetSettings: getACYWidgetsSettings(state),
    card: getCard(state),
    objectTelemetry: getObjectTelemetry(state),
    nodeStatistic: getNodeStatistic(state),
    roots: getRoot(state),
    streamUrl: getStreamUrl(state),
  }),
  (dispatch) => ({
    updateACYWidget: (id, value) => dispatch(setACYWidgetSettings(id, value)),
    onSetUnpinNode: (node) => dispatch(setUnpinNode(node)),
    onSetSelectedNode: (node) => dispatch(setSelectedNode(node)),
    getStreamUrl: (payload) => dispatch(getRequestStreamUrl(payload)),
    setStreamUrl: (payload) => dispatch(setRequestStreamUrl(payload)),
  }),
)(ObjectPassportization)
