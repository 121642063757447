import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import {
  getExportFileStatus,
  getTableData,
  getSelector,
  getEventTypes,
  getEventStatus,
  getSelectedElement,
  getEventData,
  getEventsCount,
  getTableStatus,
  getDates,
  getLoadingStatistic,
  getLoadingTable,
  getIsAlarms,
} from '@/store/selectors/alarmManager'
import { setSelectedNode } from '@/store/actions/appSettings'
import {
  getGlobalLoading,
  getRefreshRateState,
  getSelectedNode
} from '@/store/selectors/appSettings'
import {
  setEventTypes,
  setStatusTypes,
  setSelectedElement,
  setDatesRange,
  setSelectedAllElements,
} from '@/store/actions/alarmManager'
import {
  requestAlarmManagerTable,
} from '@/store/actions/alarmManager/getTable'
import {
  requestAlarmManagerFile,
} from '@/store/actions/alarmManager/getFile'
import AlarmsManager from './component'
import { getCard } from '@/store/selectors/installation'
import { requestTableStatistics } from '@/store/actions/alarmManager/getAvarage'
import { requestGetAlertFieldsOptions } from '@/store/actions/dictionaries/alertFildsOptions'
import { getAlertFieldsOptionsData } from '@/store/selectors/dictionaries/alertFildsOptions'
import { getAlarmsFilters, getStatus } from '@/store/selectors/appSettings/index'
import { setAlarmsFilterValues } from '@/store/actions/appSettings/index'


const mapStateToProps = (state) => ({
  node: getSelectedNode(state),
  data: getTableData(state),
  tableStatus: getTableStatus(state),
  exportFileStatus: getExportFileStatus(state),
  selector: getSelector(state),
  eventTypes: getEventTypes(state),
  eventStatus: getEventStatus(state),
  selectedElement: getSelectedElement(state),
  eventsCount: getEventsCount(state),
  eventData: getEventData(state),
  dates: getDates(state),
  loadingStatistic: getLoadingStatistic(state),
  loadingTable: getLoadingTable(state),
  isAlarms: getIsAlarms(state),
  card: getCard(state),
  globalLoading: getGlobalLoading(state),
  fieldOptions: getAlertFieldsOptionsData(state),
  filterValues: getAlarmsFilters(state),
  status: getStatus(state),
  refreshRate: getRefreshRateState(state),
})
const mapDispatchToProps = (dispatch) => ({
  setEventTypes: (payload) => dispatch(setEventTypes(payload)),
  setStatusTypes: (payload) => dispatch(setStatusTypes(payload)),
  setSelectedElement: (payload) => dispatch(setSelectedElement(payload)),
  setDatesRange: (payload) => dispatch(setDatesRange(payload)),
  setNode: (payload) => dispatch(setSelectedNode(payload)),
  requestAlarmManagerTable: (payload) => dispatch(requestAlarmManagerTable(payload)),
  requestAlarmManagerFile: (payload) => dispatch(requestAlarmManagerFile(payload)),
  setSelectedAllElements: (payload) => dispatch(setSelectedAllElements(payload)),
  requestTableStatistics: () => dispatch(requestTableStatistics()),
  requestGetAlertFieldsOptions: (payload) => dispatch(requestGetAlertFieldsOptions(payload)),
  setAlarmsFilterValues: (payload) => dispatch(setAlarmsFilterValues(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AlarmsManager))
