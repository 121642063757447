import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import moment from 'moment'
import request from '@/helpers/axios'
import {
  GET_TABLE_STATISTICS,
} from '@/store/actions/alarmManager'
import {
  successTableStatistics,
  errorTableStatistics,
} from '@/store/actions/alarmManager/getAvarage'
import {
  OK,
  WARNING,
  ERROR,
  UNKNOWN,
} from '@/constants/alarms'
import {
  ALARM_MANAGER_AVERAGE_ENDPOINT
} from '@/constants/apiRoutes'
import { getSelectedNode } from '@/store/selectors/appSettings'
import {
  getEventTypes,
  getEndDate,
  getTableParameters,
} from '@/store/selectors/alarmManager'
import createNotifications from "@/helpers/notification";
import { INSTALLED, UNINSTALLED } from '@/constants/objectStatuses'

function* getAverageDataSaga() {
  try {
    const node = yield select(getSelectedNode)

    if (node && !node.id) {
      yield put(successTableStatistics({
        [ERROR]: 0,
        [WARNING]: 0,
        [OK]: 0,
        [UNKNOWN]: 0,
      }))
      return null
    }

    const tableParameters = yield select(getTableParameters)
    const eventTypes = yield select(getEventTypes)
    const endDate = yield select(getEndDate)

    const response = yield request({
      url: ALARM_MANAGER_AVERAGE_ENDPOINT({
        params: {
          objectStates: [INSTALLED, UNINSTALLED],
          alertTypes: eventTypes,
          toDate: endDate ? moment(endDate).format('DD.MM.YYYY') : null,
          parentId: node.parentId,
          search: tableParameters.requestQuery || '',
        },
        id: node.id
      }),
      method: 'get',
    })
    if (response) {
      const { error, data } = response
      if (error) {
        yield put(errorTableStatistics())
      } else {
        yield put(successTableStatistics({
          [ERROR]: data.countError ? data.countError.toLocaleString() : 0,
          [WARNING]: data.countWarning ? data.countWarning.toLocaleString() : 0,
          [OK]: data.countNormal ? data.countNormal.toLocaleString() : 0,
          [UNKNOWN]: data.countUndefined ? data.countUndefined.toLocaleString() : 0,
        }))
      }
    } else {
      yield put(errorTableStatistics())
    }
  } catch (error) {
    yield put(errorTableStatistics(error))
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
  return null
}

export default function* root() {
  yield takeLatest(GET_TABLE_STATISTICS.REQUEST, getAverageDataSaga)
}
