import { takeLatest, put } from 'redux-saga/effects'
import request from '@/helpers/axios'

import { SET_STREAM_URL } from '@/store/actions/installation'
import { setSuccessStreamUrl } from '@/store/actions/installation'
import createNotifications from "@/helpers/notification";
import {
  SET_STREAM_ENDPOINT
} from '@/constants/apiRoutes'

function* setStreamUrl( {payload} ) {
  try {
    const body = {
      "elementId": payload.id,
      "url": payload.streamUrl
    }
    const { data } = yield request({
      url: SET_STREAM_ENDPOINT,
      method: 'post',
      body: JSON.stringify(body),
    })

    yield put(setSuccessStreamUrl(data.url))

  } catch (error) {
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(SET_STREAM_URL.REQUEST, setStreamUrl)
}
