import {
  takeLatest, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  OBJECTS_GROUPS_TREE_ENDPOINT,
  OBJECTS_GROUP_CHILDREN_ENDPOINT
} from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { GET_OBJECTS_GROUPS_TREE } from '@/store/actions/objectsGroups'
import {
  errorGetObjectsGroupsTree,
  successGetObjectsGroupsTree
} from '@/store/actions/objectsGroups/getTree'
import { GROUP } from '@/constants/instalationPassport/types'

function* getObjectsGroupsTreeSaga({ payload }) {
  const searchQuery  = payload?.searchQuery || ''
  const toast = createNotifications()
  try {
    const response = yield request({
      url: OBJECTS_GROUPS_TREE_ENDPOINT({
        params: {
          search: !!searchQuery ? searchQuery : null,
          includeElements: !!searchQuery,
        }
      }),
      method: 'get',
    })

    const getChildren = ({ parentTreeId, color }) => async () => {
      const { data } = await request({
        url: OBJECTS_GROUP_CHILDREN_ENDPOINT(parentTreeId),
        method: 'get',
      })
      return await Promise.all(data.sort((a, b) => a.name.trim() > b.name.trim() ? 1 : -1).map(async (element) => {
        return {
          ...element,
          type: element.installationType || element.treeNodeType,
          name: element.name,
          childrenCount: element.countChildElements,
          color: color,
          treeNodeType: null,
        }
      }))
    }

    if (response.data.length) {
      const formattedData = response.data.sort((a, b) => a.name.trim() > b.name.trim() ? 1 : -1).map((item, index) => {
        return {
          ...item,
          childrenCount: item.count,
          treeNodeType: GROUP,
          color: item.color,
          getChildren: !searchQuery.length ? getChildren({ parentTreeId: item.id, color: item.color }) : () => {},
          isOpened: !!searchQuery,
          isRoot: !index,
          children: !searchQuery.length ? [] : item.elements.map(child => {
            return {
              ...child,
              color: item.color,
              type: child.installationType,
              treeNodeType: null,
            }
          }),
        }
      })
      yield put(successGetObjectsGroupsTree(formattedData))
    } else {
      yield put(successGetObjectsGroupsTree([{}]))
    }
  } catch (error) {
    yield put(errorGetObjectsGroupsTree(error))
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось получить данные дерева групп объектов.\nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_OBJECTS_GROUPS_TREE.REQUEST, getObjectsGroupsTreeSaga)
}