export default (href) => {
  const applicationsActive = href.includes('applications') || href.includes('maps') || href.includes('passportization')
  const settingsActive = href.includes('settings')
  const dashboardActive = !applicationsActive && !settingsActive

  return {
    applicationsActive,
    settingsActive,
    dashboardActive
  }
}