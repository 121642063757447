export default {
  mainTitle: 'Service Desk "My City"',
  statistics: 'Statistics',
  appeals: 'Appeals',
  overdue: 'Overdue',
  resetFilter: 'Reset filter',
  find: 'Find',
  build: 'Build',
  objectType: 'Object type',
  status: 'Status',
  statusSD: {
    notViewed: 'New task (not viewed)',
    new: 'New task',
    registered: 'Registered',
    contractorAppointed: 'Executor appointed',
    inProgress: 'Task in progress',
    waitingControl: 'Waiting for control',
    completed: 'Completed',
    rejected: 'Task rejected by responsible',
    almostExpired: 'Task is almost overdue',
    expired: 'Task is overdue',
    resolved: 'Resolved',
    delayed: 'Delayed',
    inWork: 'In work',
    executionTime: 'Average execution time',
  }
}