import React from 'react'
// import {
//   ALARMS_ANALYTICS_URL,
// } from '@/constants/routes'

import isArray from 'lodash/isArray'


import { 
  Container, 
  Card, 
  CardRow, 
  Title, 
  Value,
  StateWraper,
  StyledButton,
  WarepperValue,
  // StyledLink
} from './styles'

const ObjectCard = ({data, fields, button, setNode, tree, maxWidth}) => {

const handleButton = (id) => {
  setNode(tree[0].children.find(geoZone => geoZone.id === id))
}

const renderValue = (value, id) => {
  if (isArray(value)) {
    return value.map((item) => {
      return (
        <Value type={id}>
          {item || '-'}
         </Value>
      )
    })
  }
  return (
    <Value type={id} >
      {value || '-'}
    </Value>
  )
}

  return (
    <Container>
      {data.filter((zone) => zone.name !== 'Россия').map((item) => {
        return (
          <Card maxWidth={maxWidth}>
            {fields.map((field) => {
              if (field.id === 'state') {
                return (
                  <CardRow>
                  <Title>{field.name}</Title>
                  <StateWraper>
                    {item.containWarning && 
                      <Value type={'normalState'} >
                        Предупреждение
                      </Value>
                    }
                    {item.containFault && 
                      <Value type={'errorState'}>
                        Отказ
                      </Value>
                    }
                    
                  </StateWraper>
                </CardRow>
                )
              }
              return (
                <CardRow>
                  <Title>{field.name}:</Title>
                  <WarepperValue>
                    {renderValue(item[field.id], field.id)}
                  </WarepperValue>
                </CardRow>
              )
            })}
              {button && 
              <StyledButton onClick={() => handleButton(item.id)} usage="accept" > Просмотр неисправностей </StyledButton>}
          </Card>      
          )
        })}
    </Container>
  )
}

export default ObjectCard