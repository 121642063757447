import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import moment from 'moment'
import request from '@/helpers/axios'
import { TENANT_ENDPOINT } from '@/constants/apiRoutes'
import {
  NAME,
  PHONE_NUMBER,
  EMAIL,
  SUB_END_DATA,
  BLOCK_ORGANIZATION,
} from '@/constants/forms/group'
import {
  getSelectedTenant,
} from '@/store/selectors/users'
import {
  ERROR,
} from '@/constants/objectStatuses'
import {
  setSelectedTenant,
  GET_TENANT,
  updateSelectedUserType,
  setEntity,
} from '@/store/actions/users'
import createNotifications from "@/helpers/notification";

function* getTenantSaga({ payload }) {
  const { id, setTenant = false, onlyUpdateSelectedTenant = false } = payload
  try {
    const response = yield request({
      url: `${TENANT_ENDPOINT}/${id}`,
      method: 'get',
    })

    const responseData = {
      id,
      name: response.data.blocked ? `${response.data[NAME]} (заблок.)` : response.data[NAME],
      type: 'company',
      [BLOCK_ORGANIZATION]: response.data[BLOCK_ORGANIZATION],
      [NAME]: response.data[NAME],
      [EMAIL]: response.data[EMAIL],
      [PHONE_NUMBER]: response.data[PHONE_NUMBER].replace(/-/g, ''),
      [SUB_END_DATA]: moment(response.data[SUB_END_DATA]).utcOffset(0, true).format('DD.MM.YYYY'),
      status: response.data.blocked ? ERROR : null,
      permissions: response.data.permissions,
      usersAmount: response.data.usersAmount,
      children: [{}],
    }
    const selectedTenant = yield select(getSelectedTenant)
    if (onlyUpdateSelectedTenant) {
      const newSelectedTenant = {
        ...selectedTenant,
        ...responseData,
        children: responseData.usersAmount > 0
          ? [{}]
          : [],
      }
      yield put(setSelectedTenant(newSelectedTenant))
    } else {
      yield put(setEntity(responseData))
      yield put(updateSelectedUserType('tenant_edit'))
      if (setTenant) {
        const newSelectedTenant = {
          ...selectedTenant,
          ...responseData,
          children: responseData.usersAmount > 0
            ? [{}]
            : [],
        }
        yield put(setSelectedTenant(newSelectedTenant))
      }
    }
  } catch (error) {
    console.log('function*getTenantSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_TENANT.REQUEST, getTenantSaga)
}
