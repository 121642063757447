import {
  COUNTRY,
  REGION,
  CITY,
  GEOZONE,
  ROOT
} from '@/constants/objectTypes'

export const isZone = (node) => node.type === COUNTRY
  || node.type === REGION
  || node.type === CITY
  || node.type === GEOZONE
  || node.type === ROOT

export default isZone
