import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  GET_LIVE_TIME_REPORT_BAR_DATA,
  LIVE_TIME_REPORT_TABLE, SET_GRAPH_TYPE
} from '@/store/actions/lifeTimeReport'
import { GET_LIVE_TIME_REPORT_BAR_DATA_URL } from '@/constants/apiRoutes'
import {
  successLiveTimeReportBarData,
  errorLiveTimeReportBarData,
} from '@/store/actions/lifeTimeReport/getBar'
import { getSelectedNode } from '@/store/selectors/appSettings'
import formatLiveTimeReportBar from '@/helpers/formaters/formatLiveTimeReportBar'
import {
  getGraphType,
  getLiveTimeObjectTypes,
  getTableParameters
} from '@/store/selectors/lifeTimeReport'
import { GRAPH_PERCENT } from '@/components/charts/Graph/config'

function* getLiveTimeReportBarDataSaga() {
  try {
    const node = yield select(getSelectedNode)
    const type = yield select(getGraphType)
    const objectTypes = yield select(getLiveTimeObjectTypes)
    const tableParameters = yield select(getTableParameters)

    const response = yield request({
      url: GET_LIVE_TIME_REPORT_BAR_DATA_URL({
        params: {
          type: type === GRAPH_PERCENT ? 'PERCENT' : 'YEAR',
          search: tableParameters.requestQuery,
          objectTypes
        },
        id: node.id
      }),
      method: 'get',
    })

    if (type === GRAPH_PERCENT) {
      const graphPercent = response.data
      yield put(successLiveTimeReportBarData(formatLiveTimeReportBar(
          graphPercent,
          {},
      )))
    } else {
      const graphYear = response.data
      yield put(successLiveTimeReportBarData(formatLiveTimeReportBar(
          {},
          graphYear,
      )))
    }
  } catch (error) {
    yield put(errorLiveTimeReportBarData(error))
  }
}

export default function* root() {
  yield takeLatest(GET_LIVE_TIME_REPORT_BAR_DATA.REQUEST, getLiveTimeReportBarDataSaga)
  yield takeLatest(SET_GRAPH_TYPE, getLiveTimeReportBarDataSaga)
  yield takeLatest(LIVE_TIME_REPORT_TABLE.REQUEST_QUERY, getLiveTimeReportBarDataSaga)
}
