import { GET_PPR_TABLE } from './index'

export const requestPprTable = (payload) => ({
  type: GET_PPR_TABLE.REQUEST,
  payload,
})

export const successPprTable = (payload) => ({
  type: GET_PPR_TABLE.SUCCESS,
  payload,
})

export const errorPprTable = (payload) => ({
  type: GET_PPR_TABLE.ERROR,
  payload,
})