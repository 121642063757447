import React, { useState } from 'react'
import {
  ApplicationsContainer,
  // StyledLink,
  Slide,
  Title,
  Count,
} from './styles'

// import { OBJECTS_URL, PASPORTIZATION_URL } from '@/constants/routes';


import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.min.css'
import 'swiper/swiper.min.css'

// import PagesIcons from '@/components/icons/mobileIcons'


              
              
const SearchSwiper = ({data, onChange}) => {

  const [typeActive, setTypeActive ] = useState('all')
  const onClick = (value) => {
    setTypeActive(value)
    onChange(value)
  }

  return (
  <ApplicationsContainer>
    <Swiper
       breakpoints={
        {
          300: {slidesPerView: 2},
          375: {slidesPerView: 3},
        }
      } 
      spaceBetween= {10}
    >
      <SwiperSlide>
        <Slide onClick={() => onClick('all')} active={typeActive === 'all'}>
          <Title>
            Все
          </Title>
          <Count active={typeActive === 'all'}>
            {data[0]?.objectCount}
          </Count>
        </Slide>
      </SwiperSlide>
      <SwiperSlide>
        <Slide onClick={() => onClick('NORMAL')} active={typeActive === 'NORMAL'}>
        <Title>
            Исправно
          </Title>
          <Count active={typeActive === 'NORMAL'}>
            {data[0]?.normalState}
          </Count>
        </Slide>
      </SwiperSlide>
      <SwiperSlide>
        <Slide onClick={() => onClick('WARNING')} active={typeActive === 'WARNING'}>
          <Title>
              Предупреждение
            </Title>
            <Count active={typeActive === 'WARNING'}>
            {data[0]?.warningState}
            </Count>
        </Slide>
      </SwiperSlide>
      <SwiperSlide>
        <Slide onClick={() => onClick('ERROR')} active={typeActive === 'ERROR'}>
          <Title>
            Неисправно
          </Title>
          <Count active={typeActive === 'ERROR'}>
            {data[0]?.errorState}
          </Count>
        </Slide>
      </SwiperSlide>
    </Swiper>
  </ApplicationsContainer>
  )
}

export default SearchSwiper