export default {
  fieldsSHUNO: [
    {
      selector: 'name',
    },
    {
      selector: 'timezone',
    },
    {
      selector: 'deviceType',
    },
    {
      selector: 'phase'
    },
    {
      selector: 'comment',
    },
    {
      selector: 'scheduleWorkingHours',
    },
  ],
  fieldsRM: [
    {
      selector: 'name',
    },
    {
      selector: 'timezone',
    },
    {
      selector: 'deviceType',
    },
    {
      selector: 'comment',
    },
    {
      selector: 'scheduleWorkingHours',
    },
  ],
  taskFields: [
    {
      selector: 'startDate'
    },
    {
      selector: 'endDate'
    },
    {
      selector: 'workingHours'
    },
    {
      selector: 'dailyWorkingHours'
    },
  ],
  actionFields: [
    {
      selector: 'hours'
    },
    {
      selector: 'minutes'
    },
    {
      selector: 'percent'
    },
  ],
}
