import React from 'react'
import memoWithName from '@/hocs/memoWithName'

const ObjectsGroupsIcon = () => {
  return (
    <svg width="16" height="16" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M13 5V8H15V15H7V12H5V5H13ZM6 6H12V11H6V6ZM13 12V9H14V14H8V12H13Z M0 0H4V2H16V0H20V4H18V16H20V20H16V18H4V20H0V16H2V4H0V0ZM1 1H3V3H1V1ZM17 16V4H16V3H4V4H3V16H4V17H16V16H17ZM3 17V19H1V17H3ZM19 1H17V3H19V1ZM17 17H19V19H17V17Z M3 1H1V3H3V1Z M17 1H19V3H17V1Z M19 17H17V19H19V17Z M3 19V17H1V19H3Z" fill="#3D3D3D"/>
    </svg>
  )
}

export default memoWithName(ObjectsGroupsIcon)
