export default {  
    usersOne: 'User',
    usersTwo: 'Users',
    usersSome: 'Users',
    users: 'Users',
    userProfile: 'User Profile',
    passportization: 'Passportization',
    installation: 'Installation',
    inventorisation: 'Inventorisation',
    alarmsOne: 'Problem',
    alarmsTwo: 'Problem',
    alarmsSome: 'Problems',
    alarms: 'Trouble Log',
    individualControl: 'Individual Control',
    groupControl: 'Group control',
    operationAndTechnicalSupport: 'Operation and support',
    search: 'Advanced search',
    installObjectsOne: 'Installation',
    installObjectsTwo: 'Installations',
    installObjectsSome: 'Installations',
    reportManager: 'Report Manager',
    life: 'Lifetime',
    scheduleManager: 'Schedule Manager',
    objectsGroups: 'Objects groups',
    controlCenter: 'Installation Manager',
    objectsOne: 'Object',
    objectsTwo: 'Objects',
    objectsSome: 'Objects',
    energyConsumption: 'Energy consumption',
    alertManager: 'AlertManager',
    activityLog: 'ActivityLog',
    changeLog: 'ChangeLog',
    equipmentReference: 'Equipment directory',
    carElectricStationsIcon: 'Charging stations',
    waterSupply: 'Water supply',
    heatSupply: 'Heat supply',
    smartRecycleBin: 'Waste container filling control',
    ecoStations: 'EcoStations',
    settings: 'System Parameters',
    statisticsOnObjectsControl: 'Control object statistics',
    analyticExpenses: 'Analytical Expenses',
    analyticConsumption: 'Analysis of resource consumption',
    eventsCount: 'Events',
    eventsNewCount: 'New',
    mountOne: 'Installation',
    mountTwo: 'Installations',
    mountSome: 'Installations',
    projectOne: 'Project',
    projectTwo: 'Project',
    projectSome: 'Projects',
    intergationWithService: 'Integration with service:',
    connect: 'Connect',
    disconnect: 'Disconnect',
    chargingStation: 'Charging stations',
    architectureIllumination: 'Architectural illumination',
    garbageCollection: 'Waste container filling control',
    roadInfrastructure: 'Road Infrastructure Monitoring',
    trafficLight: 'Traffic light management',
    infoFrame: 'Placement of information boards',
    handicappedPlaces: 'Monitoring occupancy of places\nfor people with disabilities',
    support: 'Desk service "My City"',
    widgetsDashboard: 'Dashboard',
    widgetsDashboardGov: 'Dashboard 2',
    apiUrl: 'API URL',
    port: 'Port',
    login: 'Login',
    password: 'Password',
    myDescSettingsTitle: 'To use the application\nyou must configure it',
    setup: 'Configure widget',
    receiveMessages: 'Received requests',
    execution: 'Executed',
    clientID: 'ClientID*',
    secretID: 'SecretID*',
    light: 'Outdoor Lights',
    tyumenOurHome: 'Portal Tyumen - OUR HOME',
    name: 'Name*',
    fileWithSettings: 'File with settings*',
    url: 'URL*',
    day: 'd',
    hour: 'h',
    minutes: 'min',
    sec: 'sec',
    solving: 'solving',
    failOrNoProblem: 'no failure or problems found',
    resolved: 'successfully resolved',
    decisionMade: 'Decision was made for',
    cityProcess: 'City process',
    doWidgetSettings: 'Set up widget',
    doWidgetSettingsMessage: 'To use the widget you need to configure it',
    widgetSettings: 'Widget settings',
    updateFrequency: 'Update frequency',
    objectStatus: 'Object status',
    currentMode: 'Current mode',
    controllerAvailability: 'Controller availability',
    gsmLevel: 'GSM level',
    gsmType: 'GSM type',
    hasConnection: 'Connection status',
    online: 'online',
    offline: 'offline',
    alarmState: 'Alarm status',
    absent: 'absent',
    notActivelyAcknowledged: 'not actively acknowledged',
    notActivelyNotAcknowledged: 'not active not acknowledged',
    activelyAcknowledged: 'actively acknowledged',
    phaseState: 'Phase state',
    itActive: 'active',
    itNotActive: 'not active',
    heActive: 'active',
    heNotActive: 'not active',
    counterStatus: 'Counter status',
    fireSensorStatus: 'Fire sensor status',
    norm: 'norm',
    sensorBreak: 'sensor break',
    heatDetector: 'heat detector triggered',
    smokeDetector: 'smoke detector went off',
    shortCircuit: 'short circuit',
    contactorIsOn: 'Contactor off/on',
    on: 'on',
    off: 'off',
    itOn: 'on',
    itOff: 'off',
    isActiveCommandOn: 'Command has been sent to turn on the contactor',
    commandOn: 'command is active',
    commandOff: 'command is inactive',
    mode: 'Control mode',
    local: 'local',
    remote: 'remote',
    controlType: 'Control type',
    manual: 'manual',
    autonomic: 'autonomic',
    cascade: 'cascade',
    contactorSwitchTime: 'Date and time of scheduled contactor activation',
    willContactorSwitchOn: 'The contactor will be switched on as planned',
    yes: 'yes',
    no: 'no',
    circuitState: 'Insertion/automatic inclusion state',
    undefined: 'undefined',
    fault: 'fault',
    checkPointState: 'Checkpoint status',
    currentSensorValue: 'Current sensor value',
    dmxId: 'DMX module ID',
    isActive: 'Is the script active',
    activationTime: 'Scenario activation time',
    scenarioNumber: 'Current scenario number',
    deferredScenarioNumber: 'Deferred scenario number',
    dmxErrorCode: 'DMX module fault code',
    pressureUnits: 'mmHg Art.',
    pressureInPipe: 'Supply line pressure',
    pressureInReversePipe: 'Return pressure',
    massFlowUnits: 't/h',
    illuminanceLevel: 'Dimming level',
    activeEnergy: 'Energy active',
    reactiveEnergy: 'Reactive energy',
    voltage: 'Voltage',
    voltageUnits: 'V',
    current: 'Current',
    currentUnits: 'Amp',
    power: 'Power',
    powerUnits: 'W',
    reactivePowerUnits: 'VAR',
    frequency: 'Frequency',
    frequencyUnits: 'Hz',
    ua: 'Phase voltage UA',
    ub: 'Phase voltage UB',
    uc: 'Phase voltage UC',
    ia: 'Phase current IA',
    ib: 'Phase current IB',
    ic: 'Phase current IC',
    ca: 'Phase power factor CA',
    cb: 'Phase power factor CB',
    cc: 'Phase power factor CC',
    qa: 'Reactive power per phase QA',
    qb: 'Reactive power per phase QB',
    qc: 'Reactive power per phase QC',
    pa: 'Active power per phase PA',
    pb: 'Active power per phase PB',
    pc: 'Active power per phase PC',
    electricalResistance: 'Electrical resistance',
    resistanceUnits: 'Ohm',
    counterCommunication: 'Communication with the meter',
    connectionStatus: 'Connection status',
    networkLevel: 'Network level',
    illumination: 'Illumination',
    illuminationUnits: 'Lux',
    temperature: 'Temperature',
    readingDate: 'Date and time of reading',
    lightVoltage: 'Lamp voltage',
    lightCurrent: 'Current strength at the lamp',
    lightPower: 'Lamp power',
    lightReactivePower: 'Reactive power of the light',
    lightFullPower: 'Full light power',
    lightPowerCoefficient: 'Light power factor',
    lightFrequency: 'Frequency on the lamp',
    energy: 'Accumulated energy',
    energyUnits: 'Wh',
    energyUnitsKilo: 'kWh',
    voltagePeaks: 'Amplitude voltage values',
    currentPeaks: 'Amplitude current values',
    lastDevastation: 'Last devastation',
    wasteType: 'Type of waste',
    category: 'Category',
    lastCleaning: 'Last cleaning',
    nextCleaning: 'Next cleaning',
    lastOn: 'Last power on',
    lastOff: 'Last shutdown',
    availableModes: 'Available modes',
    semiautomatic: 'Semi-automatic',
    automatic: 'Automatic',
    lastUpdate: 'Last update',
    locationCategory: 'Location category',
    sidewalk: 'sidewalk',
    powerConsumption: 'Power consumption',
    lightPowerConsumption: 'Lamp power consumption',
    unitPressure: 'kgf/cm',
    m: 'm',
    h: 'h',
    massFlow: 'Mass flow',
    volumeFlow: 'Volume flow',
    averageTimeTaken: 'Avg. fix time',
    critical: 'Critical',
    fixed: 'Fixed',
    alarmsTotal: 'Total alarms',
    callsTotal: 'Total requests',
    averageDecisionExecutionTime: 'Avg. hiring time',
    averageDecisionTime: 'Avg. decision time',
    inWork: 'In progress',
    decisionWasMade: 'Decision was made',
    decisionsWasMade: 'Decisions was made',
    roadRepairingCount: 'Road repair',
    transportStationCount: 'General stops transport',
    notAvailable1: 'Breakthrough of heating mains',
    notAvailable2: 'Flooding',
    planned: 'Planned',
    problemsSolved: 'Successfully resolved',
    refuse: 'Reasoned refusal',
    problemNotFound: 'Problem not found',
    searchDescription: 'Customizable search for objects in the system',
    heatSupplyDescription: 'Connecting heat supply facilities to the system',
    waterSupplyDescription: 'Connection to the water supply system',
    chargingStationDescription: 'Connecting charging stations to the system',
    architectureIlluminationDescription: 'Connecting architectural and artistic lighting objects to the system',
    garbageCollectionDescription: 'Connecting separate waste collection facilities to the system',
    roadInfrastructureDescription: 'Connecting road infrastructure monitoring objects to the system',
    trafficLightDescription: 'Connecting traffic light control objects to the system',
    infoFrameDescription: 'Connection to the information board placement system',
    handicappedPlacesDescription: 'Connection to the system for monitoring the occupancy of places for disabled people',
    widgetsDashboardDescription: 'United center for collecting and monitoring statistical data from all data sources connected to the system',
    widgetsDashboardGovDescription: 'United center for collecting and monitoring statistical data from all data sources connected to the system',
    alarmAnalytics: 'Alarm analytics',
    alarmAnalyticsDescription: 'Module for collecting and analyzing statistics of equipment malfunctions in the selected geofence',
    tyumenOurHomeDescription: 'Viewing citizens\' requests on various problems of the urban environment to obtain up-to-date information about problems in the city',
    roadRepairing: 'Road repair and reconstruction',
    publicTransport: 'Public transport stops',
    supportDescription: 'Widget for viewing the history of requests and generating new requests to resolve emergency situations',
    objectsGroupsDescription: 'An application for grouping objects with the subsequent ability to perform selected actions on the formed group',
}
