import React, { useState, useMemo, useCallback } from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import debounce from 'lodash/debounce'
import isEmpty from 'lodash/isEmpty'
import CreateAdminForm from '@/forms/CreateAdminForm'
import { DEBOUNCE_DELAY_SMALL } from '@/constants/time'
import {
  ADMIN,
  USER,
  USERS_GROUP,
} from '@/constants/viewTree'
import Loader from '@/components/blocks/Loader'
import UserFormHeader from '@/components/blocks/Header'
import {
  LoaderContainer,
  MainContainer,
} from '../../styles'
import {
  Container,
} from './styles'

const CreateAdmin = ({
  type,
  requestCreateAdminTenant,
  selectedTenant,
  selectedAdmin,
  requestCreateUser,
  requestDeleteAdminTenant,
  closeForm,
  isConfirm,
  requestGetTenant,
  requestDeleteUser,
  requestGetTenantGroup,
  userRole,
  actionsDisabled,
  isFormModifying,
  setFormIsModifying,
  updateAdmin,
  isCreateAdmin,
  setType
}) => {
  const createAdminForm = React.useRef()
  const [isValidForm, setValidForm] = useState(false)

  const confirmAdminCreationHandler = () => {
    if(type === ADMIN && !isCreateAdmin){
      updateAdmin({
        forms: createAdminForm.current,
        tenantId: selectedAdmin.tenantId,
        id: selectedAdmin.id,
        isMe: selectedAdmin.isMe,
      })
      return null
    }
    if (type === ADMIN) {
      requestCreateAdminTenant({
        forms: createAdminForm.current,
        tenantId: selectedTenant.id,
      })
    }
    if (type === USER) {
      requestCreateUser({
        forms: createAdminForm.current,
        tenantId: selectedTenant.id,
        groupId: selectedAdmin.id,
      })
    }
  }

  const submitDebounceHandler = debounce(() => {
    confirmAdminCreationHandler()
  }, DEBOUNCE_DELAY_SMALL)

  const deleteAdminHandler = () => {
    if (type === ADMIN) {
      requestDeleteAdminTenant({
        selectedAdmin,
        id: selectedAdmin.id,
        tenantId: selectedAdmin.tenantId,
      })
    }
    if (type === USER) {
      requestDeleteUser({
        selectedUser: selectedAdmin,
        id: selectedAdmin.id,
        tenantId: selectedAdmin.tenantId,
        groupId: selectedAdmin.group.id,
      })
    }
  }

  const deleteAdminDebounceHandler = debounce(() => {
    deleteAdminHandler()
  }, DEBOUNCE_DELAY_SMALL)

  const renderErrorText = useMemo(() => {
    if (!isFormModifying) {
      return (
        <>
          {type === ADMIN ? <Lang id="usersPage.popup.deleteAdmin" /> : <Lang id="usersPage.popup.deleteUser" />}
          {`${selectedAdmin.name}?`}
        </>
      )
    }
    return type === ADMIN ? <Lang id="usersPage.popup.cancelAdmin" /> : <Lang id="usersPage.popup.cancelUser" />
  }, [isFormModifying, selectedAdmin.name, type])

  const handleCloseForm = useCallback(() => {
    if (type === ADMIN) {
      closeForm()
      requestGetTenant({ id: selectedTenant.id, setTenant: true })
    }
    if (type === USER) {
      closeForm()
    }
  }, [
    closeForm,
    requestGetTenant,
    selectedTenant.id,
     type,
  ])

  return (
    <Container>
      {actionsDisabled && (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      )}
      <MainContainer actionsDisabled={actionsDisabled}>
        <UserFormHeader
          editTitle={type === ADMIN ? <Lang id="usersPage.formHeader.creatingAdmin" /> : <Lang id="usersPage.formHeader.creatingUser" />}
          editMode={isFormModifying}
          deleteForm={deleteAdminDebounceHandler}
          saveForm={submitDebounceHandler}
          closeForm={handleCloseForm}
          setType={setType}
          type={type}
          selectedAdmin={selectedAdmin}
          title={type === ADMIN ? <Lang id="usersPage.formHeader.admin" /> : <Lang id="usersPage.formHeader.user" />}
          setFormIsModifying={setFormIsModifying}
          withoutEditing={type === ADMIN ? !userRole.isSuperAdmin : !userRole.isAdmin}
          withoutDeleting={type === ADMIN ? !userRole.isSuperAdmin : !userRole.isAdmin}
          userRole={userRole.isSuperAdmin}
          popupSettings={{
            error: {
              title: <Lang id="usersPage.popup.attention" />,
              message: renderErrorText,
            },
            warning: {
              title: <Lang id="usersPage.popup.impossibleExecute" />,
              message: type === ADMIN ? <Lang id="usersPage.popup.saveAdmin" /> : <Lang id="usersPage.popup.saveUser" />,
            },
          }}
          conditionsForAdding={isValidForm}
        />
        <CreateAdminForm
          formData={selectedAdmin}
          ref={createAdminForm}
          onChange={setValidForm}
          blockForm={type === ADMIN
            ? !isEmpty(selectedAdmin)
            : selectedAdmin.type !== USERS_GROUP}
          setValidForm={setValidForm}
          selectedAdminTenant={selectedAdmin}
          isConfirm={isConfirm}
          isFormModifying={isFormModifying}
          userRole={userRole}
          isCreateAdmin={isCreateAdmin}
        />
      </MainContainer>
    </Container>
  )
}

CreateAdmin.propTypes = {
  editMode: pt.bool,
  seUserImageUrl: pt.func,
  selectedTenant: pt.objectOf(pt.object),
  requestCreateAdminTenant: pt.func,
  setSelectedTenant: pt.func,
  requestDeleteAdminTenant: pt.func,
  closeForm: pt.func,
}
CreateAdmin.defaultProps = {
  editMode: false,
  seUserImageUrl: noop,
  selectedTenant: {},
  requestCreateAdminTenant: noop,
  setSelectedTenant: noop,
  requestDeleteAdminTenant: noop,
  closeForm: noop,
}

export default CreateAdmin
