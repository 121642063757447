import {
  OK, INFO, ERROR, WARNING, OFF,
} from '@/constants/objectStatuses'

import {
  GROUP_OBJECT,
  COLD_WATER_DEVICE,
  HEATING_DEVICE,
} from '@/constants/objectTypes'

import {
  LIGHT_FIXTURE,
  TRASH,
  CONTROLLER,
  LIGHT_SENSOR, SWITCHING_GROUP
} from "@/constants/instalationPassport/types";

const MAP_CONTROLLER_TELEMETRY = {
  OK,
  ON: 'NO_PROBLEM',
  WARNING,
  OFF,
  UNDEFINED: INFO,
  NO_CONNECTION: ERROR,
}

export const mapLightTelemetry = (eventData) => {
  const {
    address: id,
    aliasId,
  } = eventData.header.objectIdentifier
  const telemetry = {
    ...eventData.payload,
    timestamp: eventData.header.timestamp,
  }

  const statusValue = telemetry.state
  const enableState = telemetry.turnedOn

  const statusEnabled = enableState ? OK : INFO

  telemetry.status = statusValue === OK ? statusEnabled : statusValue

  return { [`${aliasId}-${id}`]: telemetry }
}

export const mapUnilightModuleTelemetry = (eventData) => {
  const {
    objectId: id,
    aliasId,
  } = eventData.header.objectIdentifier
  const telemetry = {
    ...eventData.payload,
    timestamp: eventData.header.timestamp,
  }

  const statusValue = telemetry.state
  const enableState = telemetry.isConnected

  const statusEnabled = enableState ? OK : OFF

  telemetry.status = statusValue === OK ? statusEnabled : statusValue

  return { [`${aliasId}-${id}`]: telemetry }
}

export const mapControllerTelemetry = (eventData) => {
  // const typeByLocalType = {
  //   [CONTROL_CUPBOARD_SERVER]: SHUO,
  //   [BASE_STATION_SERVER]: CONTROLLER,
  // }
  const {
    objectId: id,
    aliasId,
  } = eventData.header.objectIdentifier
  const telemetry = {
    ...eventData.payload,
    timestamp: eventData.header.timestamp,
  }

  telemetry.status = MAP_CONTROLLER_TELEMETRY[telemetry.state]

  return {
    [`${aliasId}-${id}`]: telemetry,
  }
}

export const mapUnilightControllerTelemetry = (eventData) => {
  const {
    objectId,
    aliasId,
  } = eventData.header.objectIdentifier
  const telemetry = {
    ...eventData.payload,
    timestamp: eventData.header.timestamp,
  }
  telemetry.status = MAP_CONTROLLER_TELEMETRY[telemetry.state]

  return {
    [`${aliasId}-${objectId}`]: telemetry
  }
}

export const mapATMTelemetry = (eventData, type, idSelector) => {
  const {
    [idSelector]: id,
    aliasId,
  } = eventData.header.objectIdentifier
  const telemetry = {
    ...eventData.payload,
    timestamp: eventData.header.timestamp,
  }

  const status = Object.keys(telemetry).length > 1 ? OK : INFO
  telemetry.status = status

  return {
    [`${aliasId}-${id}`]: telemetry,
  }
}

export const mapMeshControllerTelemetry = (eventData) => {
  const data = {
    ...eventData,
    status: MAP_CONTROLLER_TELEMETRY[eventData.status] || INFO,
    state: MAP_CONTROLLER_TELEMETRY[eventData.status] || INFO,
    type: CONTROLLER,
    timestamp: eventData.dateModified,
  }
  return {
    [`${eventData.integrationId}-${eventData.objectId}`]: data,
  }
}

export const mapMeshLightTelemetry = (eventData) => {
  const statusValue = MAP_CONTROLLER_TELEMETRY[eventData.status] || INFO
  const turnedOn = eventData.powerState === 'ON' || false
  const data = {
    ...eventData,
    status: MAP_CONTROLLER_TELEMETRY[eventData.status] || INFO,
    turnedOn,
    state: statusValue,
    totalPower: eventData.activePower,
    timestamp: eventData.dateModified,
    type: LIGHT_FIXTURE,
  }
  return {
    [`${eventData.integrationId}-${eventData.objectId}`]: data,
  }
}

export const mapMeshTrashTelemetry = (eventData) => {
  const data = {
    ...eventData,
    status: MAP_CONTROLLER_TELEMETRY[eventData.status] || INFO,
    state: MAP_CONTROLLER_TELEMETRY[eventData.status] || INFO,
    timestamp: eventData.dateModified,
    type: TRASH,
  }
  return {
    [`${eventData.integrationId}-${eventData.objectId}`]: data,
  }
}

export const mapMeshLightSensorTelemetry = (eventData) => eventData.ambientLightSensors
  .reduce((accumulator, element) => {
    const data = {
      ...element,
      status: MAP_CONTROLLER_TELEMETRY[element.status] || INFO,
      state: MAP_CONTROLLER_TELEMETRY[element.status] || INFO,
      timestamp: element.dateModified,
      type: LIGHT_SENSOR,
    }
    return {
      ...accumulator,
      [`${eventData.id}-${element.id}`]: data,
    }
  }, {})
export const mapMeshSwitchingGroupTelemetry = (eventData) => {
  const data = {
    ...eventData,
    status: MAP_CONTROLLER_TELEMETRY[eventData.powerstate] || INFO,
    state: MAP_CONTROLLER_TELEMETRY[eventData.powerstate] || INFO,
    timestamp: eventData.dateModified,
    type: SWITCHING_GROUP,
  }
  return {
    [`${eventData.integrationId}-${eventData.objectId}`]: data,
  }
}

export const mapMeshTelemetry = (eventData) => {
  switch (eventData.type) {
    case 'StreetlightControlCabinet': {
      return mapMeshControllerTelemetry(eventData)
    }
    case 'Streetlight': {
      return mapMeshLightTelemetry(eventData)
    }
    case 'WasteContainer': {
      return mapMeshTrashTelemetry(eventData)
    }
    case 'LIGHT_SENSOR': {
      return mapMeshLightSensorTelemetry(eventData)
    }
    case 'SwitchingGroup': {
      return mapMeshSwitchingGroupTelemetry(eventData)
    }
    default: {
      return {}
    }
  }
}

export const mapColdWaterTelemetry = (eventData) => mapATMTelemetry(
  eventData,
  COLD_WATER_DEVICE,
  'deviceId',
)
export const mapHeatTelemetry = (eventData) => mapATMTelemetry(
  eventData,
  HEATING_DEVICE,
  'deviceId',
)
export const mapGroupObjectTelemetry = (eventData) => mapATMTelemetry(
  eventData,
  GROUP_OBJECT,
  'objectId',
)

const getTelemetryWithStatus = (item) => ({
  ...item,
  timestamp: item.timestamp,
  status: (item.active || item.isOn) ? OK : INFO,
})

export const mapBrizTelemetry = (eventData) => {
  const { payload, header: { timestamp, objectIdentifier: { aliasId, objectId, type, } } } = eventData
  const telemetry = {
    ...payload,
    timestamp,
    type
  }

  return {
    [`${aliasId}-${objectId}`]: getTelemetryWithStatus(telemetry),
  }
}