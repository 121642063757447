import { GET_SCHEDULE_MANAGER_TREE } from './index'

export const requestScheduleManagerTree = (payload) => ({
    type: GET_SCHEDULE_MANAGER_TREE.REQUEST,
    payload,
})

export const successScheduleManagerTree = (payload) => ({
    type: GET_SCHEDULE_MANAGER_TREE.SUCCESS,
    payload,
})

export const errorScheduleManagerTree = (payload) => ({
    type: GET_SCHEDULE_MANAGER_TREE.ERROR,
    payload,
})