import createAction from '@/helpers/redux/createAction'

export const GET_OBJECT_STATE = createAction('@/DASHBOARD_APP_GET_OBJECT_STATE')
export const GET_GRAPH_DATA = createAction('@/DASHBOARD_APP_GET_GRAPH_DATA')
export const GET_TUMEN_OUR_HOME_DATA = createAction('@/DASHBOARD_APP_GET_TUMEN_OUR_HOME_DATA')
export const GET_SERVICE_DESK_WIDGET = createAction('@/GET_SERVICE_DESK_WIDGET')
export const GET_DEPRECIATION_EQUIPMENT = createAction('@/GET_DEPRECIATION_EQUIPMENT')
export const GET_AUTOMATIC_REPORTING = createAction('@/GET_AUTOMATIC_REPORTING')
export const GET_INFRASTRUCTURE_COUNT = createAction('@/GET_INFRASTRUCTURE_COUNT')
export const GET_TREE_FOR_WIDGETS = createAction('@/GET_TREE_FOR_WIDGETS')
