import {
  take, call, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import createNotifications from '@/helpers/notification'
import {
  errorGetOnlineUsers,
  successGetOnlineUsers
} from '@/store/actions/users/getOnlineUsers'
import createOnlineUsersChannel from './channels'
import { GET_ONLINE_USERS_URL } from '@/constants/apiRoutes'


function* onlineUsersSaga() {
  try {
    const channel = yield call(createOnlineUsersChannel)
    while (true) {
      const eventData = yield take(channel)
      console.log('function*onlineUsersSaga', eventData)
      if (eventData) {
        const response = yield request({
          url: GET_ONLINE_USERS_URL,
          method: 'get',
        })
        yield put(successGetOnlineUsers(response.data))
      }
    }
  } catch (error) {
    yield put(errorGetOnlineUsers(error))
    console.log('TCL: function*onlineUsersSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось получить список онлайн пользователей.',
      type: 'error',
    })
  }
}

export default onlineUsersSaga
