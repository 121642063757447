import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import createNotifications from '@/helpers/notification'
import { VERIFICATION_ALL } from '@/store/actions/objectsGroups'
import { VERIFICATION_ALL_URL } from '@/constants/apiRoutes'
import { requestGetVerificationOptions } from '@/store/actions/objectsGroups/getVerificationOptions'
import {
  successVerificationAll,
  errorVerificationAll
} from '@/store/actions/objectsGroups/verificationAll'

function* verificationAll({ payload }) {
  try {
    const { groupId, status, intl } = payload
    const body = {
      groupId : groupId,
      objectState: status,
    }
    const { data } = yield request({
      url: VERIFICATION_ALL_URL,
      method: 'post',
      body,
    })
    if (data) {
      yield put(successVerificationAll())
      yield put(requestGetVerificationOptions({groupId, intl}))
    }
  } catch (error) {
    yield put(errorVerificationAll(error))
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось верифицировать группу объектов. \nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* () {
  yield takeLatest(VERIFICATION_ALL.REQUEST, verificationAll)
}
