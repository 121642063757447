import {
  takeLatest,
} from 'redux-saga/effects'
import {
  CREATE_INTEGRATION,
} from '@/store/actions/integrations'
import request from '@/helpers/axios'
import { generateIntegrationTypeUrl } from '@/constants/apiRoutes'
import {
  PASSWORD,
  PORT,
  NAME,
  PERIOD,
  INTEGRATION_ID,
} from '@/constants/forms/integration'
import createNotifications from "@/helpers/notification";

function* createIntegrationSaga({ payload }) {
  try {
    const { values, type } = payload
    const valuesCopy = { ...values }
    delete valuesCopy.id
    delete valuesCopy.unit
    delete valuesCopy[NAME]
    delete valuesCopy[PERIOD]
    delete valuesCopy[INTEGRATION_ID]

    if (!valuesCopy[PORT]) {
      delete valuesCopy[PORT]
    }

    const dataJson = {
      [NAME]: values[NAME],
      [PERIOD]: Number.parseInt(values[PERIOD], 10) || 12,
      installationIntegrationId: values[INTEGRATION_ID],
      integrationType: type,
      connectionSettings: valuesCopy,

    }

    if (!values[PORT]) {
      delete dataJson[PORT]
    }
    if (!values[PASSWORD]) {
      dataJson[PASSWORD] = null
    }

    yield request({
      url: generateIntegrationTypeUrl(type.toLowerCase()),
      method: 'post',
      body: dataJson,
    })
  } catch (error) {
    console.log('TCL: function*createIntegrationSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(CREATE_INTEGRATION.REQUEST, createIntegrationSaga)
}
