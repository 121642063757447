import React, { useRef } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import { childrenType } from '@/propTypes/react'
import memoWithName from '@/hocs/memoWithName'
import useOutsideClickHandler from '@/hooks/useOutsideClickHandler'
import Icons from '@/components/icons/users'
import {
  Container,
  PopupContainer,
  PopupWrapper,
  IconContainer,
} from './styles'

const Popup = ({
  popupContent, opened, children, onClick, onClose, isLayer, versionPopup
}) => {
  const ref = useRef(null)
  useOutsideClickHandler({
    ref,
    callback: onClick,
    opened,
  })

  return (
    <PopupWrapper ref={ref}>
      <Container opened={opened}>
        {children}
      </Container>
      <PopupContainer opened={opened} isLayer={isLayer} versionPopup={versionPopup}>
        {onClose && 
        <IconContainer onClick={onClose}>
          <Icons.CrossIcon/>
        </IconContainer>
        }
        {opened ? popupContent : null}
      </PopupContainer>
    </PopupWrapper>
  )
}

Popup.propTypes = {
  children: childrenType.isRequired,
  popupContent: childrenType.isRequired,
  opened: pt.bool.isRequired,
  onClick: pt.func,
}

Popup.defaultProps = {
  onClick: noop,
}

export default memoWithName(Popup)
