import { handleActions } from 'redux-actions'
import moment from 'moment'
import { REQUEST_STATUSES } from '@/constants/requests'
import {
  GET_TYUMEN_MESSAGES_LIST,
  GET_TYUMEN_MESSAGE,

  GET_TYUMEN_GRAPHS,
  GET_TYUMEN_DATE_GRAPH,

  TYUMEN_MESSAGES_SET_SELECTED_ELEMENT,
  TYUMEN_MESSAGES_SET_SEARCH,
  TYUMEN_MESSAGES_SET_STATUS,
  TYUMEN_MESSAGES_SET_DATES,
} from '@/store/actions/tyumenOurHome'
import statusesOptions from '@/containers/pages/TyumenOurHome/config'

const initialState = {
  status: REQUEST_STATUSES.NOT_REQUESTED,
  type: null,
  data: {},
  dataMobile: {},
  dates: {
    type: 'all',
    startDate: '2000-01-01',
    endDate: moment().format('YYYY-MM-DD'),
  },
  query: '',
  statuses: statusesOptions.map((option) => option.value),
  selectedMessageId: null,
  messageData: {},
  graphs: [],
  graphDate: {},
}

export const reducer = handleActions(
  {
    [GET_TYUMEN_DATE_GRAPH.SUCCESS]: (state, { payload }) => ({
      ...state,
      graphDate: payload,
    }),
    [GET_TYUMEN_GRAPHS.SUCCESS]: (state, { payload }) => ({
      ...state,
      graphs: payload,
    }),
    [GET_TYUMEN_MESSAGE.REQUEST]: (state, { payload }) => ({
      ...state,
      ...payload,
      messageData: {},
      status: REQUEST_STATUSES.PENDING,
    }),
    [GET_TYUMEN_MESSAGE.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [GET_TYUMEN_MESSAGE.ERROR]: (state, { payload }) => ({
      ...state,
      ...payload,
      status: REQUEST_STATUSES.ERROR,
    }),

    [GET_TYUMEN_MESSAGES_LIST.REQUEST]: (state, { payload }) => ({
      ...state,
      ...payload,
      data: {},
      selectedMessageId: null,
      status: REQUEST_STATUSES.PENDING,
    }),
    [GET_TYUMEN_MESSAGES_LIST.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
      status: REQUEST_STATUSES.IDLE,
    }),
    [GET_TYUMEN_MESSAGES_LIST.ERROR]: (state, { payload }) => ({
      ...state,
      ...payload,
      status: REQUEST_STATUSES.ERROR,
    }),

    [TYUMEN_MESSAGES_SET_SELECTED_ELEMENT]: (state, { payload }) => ({
      ...state,
      selectedMessageId: payload,
      messageData: {},
    }),
    [TYUMEN_MESSAGES_SET_DATES]: (state, { payload }) => ({
      ...state,
      dates: payload,
    }),
    [TYUMEN_MESSAGES_SET_SEARCH]: (state, { payload }) => ({
      ...state,
      query: payload,
    }),
    [TYUMEN_MESSAGES_SET_STATUS]: (state, { payload }) => ({
      ...state,
      statuses: payload,
    }),
  },
  initialState,
)

export default reducer
