import { GET_OBJECT_TELEMETRY } from './index';

export const requestGetObjectTelemetry = (payload) => ({
    type: GET_OBJECT_TELEMETRY.REQUEST,
    payload
})

export const successGetObjectTelemetry = (payload) => ({
    type: GET_OBJECT_TELEMETRY.SUCCESS,
    payload
})

export const errorGetObjectTelemetry = (payload) => ({
    type: GET_OBJECT_TELEMETRY.ERROR,
    payload
})