import React, { useMemo, useState } from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import pt from 'prop-types'
import noop from 'lodash/noop'
import CheckBox from '@/components/controls/CheckBox'
import Button from '@/components/blocks/Button'

import {
  Container,
  CheckBoxAndLabelContainer,
  Label,
  MainTitle,
  ButtonWrapper,
  Content,
} from './styles'

const FieldsSettings = ({
  passportConfig,
  cardConfig,
  selectedFields,
  setActiveFieldsAll,
  setAciveFieldsObject,
}) => {
  const [localSelectedFields, setLocalSelectedFields] = useState(selectedFields)

  const formatFieldOptions = useMemo(() => {
    const newOptions = passportConfig.concat(cardConfig)
    return newOptions
  }, [cardConfig, passportConfig])

  const onCheckBoxChangeHandler = (selector) => () => {
    if (localSelectedFields.includes(selector)) {
      const filteredArray = localSelectedFields.filter((element) => element !== selector)
      setLocalSelectedFields(filteredArray)
    } else {
      const newArray = [...localSelectedFields]
      newArray.push(selector)
      setLocalSelectedFields(newArray)
    }
    return null
  }

  const isAllSelected = useMemo(() => {
    const count = passportConfig.length + cardConfig.length
    return count === localSelectedFields.length
  },
  [
    cardConfig.length,
    passportConfig.length,
    localSelectedFields.length,
  ])

  const ToggleSelectAll = () => {
    const count = passportConfig.length + cardConfig.length
    if (count === localSelectedFields.length) {
      setLocalSelectedFields([])
      return null
    }
    const options = passportConfig.concat(cardConfig)
    const selectAllArray = options.map((element) => element.selector)
    setLocalSelectedFields(selectAllArray)
    return null
  }

  const handleSubmitFieldsObject = () => {
    setAciveFieldsObject(localSelectedFields)
  }

  const handleSubmitFieldsAll = () => {
    setActiveFieldsAll(localSelectedFields)
  }


  return (
    <Container>
      <Content>
        <MainTitle>
          <Lang id="passportization.passportSidebar.fieldSettings" />
        </MainTitle>
        {formatFieldOptions.length > 0 && (
          <CheckBoxAndLabelContainer>
            <CheckBox
              key="select-all"
              value={isAllSelected}
              onChange={ToggleSelectAll}
            />
            <Label>
              <Lang id="passportization.passportSidebar.discardAll" />
            </Label>
          </CheckBoxAndLabelContainer>
        )}
        {formatFieldOptions.length > 0 && formatFieldOptions.map(
          (element) => {
            const { value, selector, catalogSelector } = element
            return (
              <CheckBoxAndLabelContainer>
                <CheckBox
                  key={`${catalogSelector}-${selector}-${value}`}
                  value={localSelectedFields.includes(selector)}
                  onChange={onCheckBoxChangeHandler(selector)}
                />
                <Label>
                  <Lang id={`passport.fields.${selector}`} />
                </Label>
              </CheckBoxAndLabelContainer>
            )
          },
        )}
      </Content>
      <ButtonWrapper>
        <Button onClick={handleSubmitFieldsObject}>
          <Lang id={`buttons.applyForOne`} />
        </Button>
        <Button onClick={handleSubmitFieldsAll}>
          <Lang id={`buttons.applyForAll`} />
        </Button>
      </ButtonWrapper>
    </Container>
  )
}

FieldsSettings.propTypes = {
  passportConfig: pt.arrayOf(pt.object),
  cardConfig: pt.arrayOf(pt.object),
  selectedFields: pt.arrayOf(pt.string),
  setSelectedFields: pt.func,
}

FieldsSettings.defaultProps = {
  passportConfig: [],
  cardConfig: [],
  selectedFields: [],
  setSelectedFields: noop,
}
export default FieldsSettings
