import {
  CHARGING_STATIONS,
  GARBAGE_COLLECTION,
  HEAT_AND_WATTER_SUPPLY,
  HEAT_SUPPLY,
  OUTDOOR_LIGHTING,
  WATER_SUPPLY,
  ROAD_REPAIRING,
  TRANSPORT_STATION,
  OUTDOOR_LIGHTNING
} from '@/constants/objectTypes'

export default {
  title: 'Portal "Tyumen - OUR HOME"',
  graphs: 'Graphs',
  find: 'Find',
  appeals: 'Appeals',
  buildChart: 'Build',
  noData: 'No data',
  interval: 'Interval',
  periodOptions: {
    all: 'During all this time',
    year: 'Year',
    quarter: 'Quarter',
    month: 'Month',
    week: 'Week',
    day: 'Day',
  },
  chartTypes: {
    all: 'All types of charts',
    topics: 'Subjects of appeals',
    statuses: 'Statuses of decisions on appeals',
    statistic: 'Statistics on requests',
    time: 'Average time for making and reviewing decisions',
  },
  processes: {
    [OUTDOOR_LIGHTING]: 'Outdoor Lighting',
    [OUTDOOR_LIGHTNING]: 'Outdoor Lighting',
    [WATER_SUPPLY]: 'Water supply',
    [HEAT_SUPPLY]: 'Heat supply',
    [GARBAGE_COLLECTION]: 'Separate waste collection',
    [CHARGING_STATIONS]: 'Charging stations',
    [HEAT_AND_WATTER_SUPPLY]: 'Heat and water supply',
    [ROAD_REPAIRING]: 'Road repair and reconstruction',
    [TRANSPORT_STATION]: 'Public transport stops',
  }
}