import React, { useCallback } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'
import SidebarTree from '@/components/blocks/SidebarTree'
import Loader from '@/components/blocks/Loader/component'

const AnalyticsAlarms = ({
  tree, onSelectSettings,
}) => {
  const handleSelect = useCallback((node, event) => {
    if (event === 'click' && node.id) {
      onSelectSettings({
        installationId: node.id,
        name: node.name,
      })
    }
  }, [onSelectSettings])
  return (
    !tree.length
      ? <Loader center/>
      : <SidebarTree
          data={tree}
          onSelectNode={handleSelect}
        />
  )
}

AnalyticsAlarms.propTypes = {
  onSelectSettings: pt.func,
  tree: pt.objectOf(pt.object),
}
AnalyticsAlarms.defaultProps = {
  onSelectSettings: noop,
  tree: {},
}

export default memoWithName(AnalyticsAlarms)
