import React  from 'react'
import pt from 'prop-types'
import { useHistory } from 'react-router-dom'
import {
  Container,
  OptionTitle,
  OptionContainer,
} from './styles'
import { USERS_URL } from '@/constants/routes'

const OnlineUsers = ({ data, toggleUsersPopup, requestGetUser, setEntity }) => {
  const { href} = window.location
  const history = useHistory()

  const handleUserClick = (user) => {
    if (!href.includes('users')) {
      history.push(USERS_URL)
    }
    toggleUsersPopup()
    requestGetUser(
    {
      id: user.id,
      tenantId: user.tenantId,
    })
    setEntity({})
  }

  return (
    <Container>
      <OptionContainer>
        {data.map(user => {
          return (
            <OptionTitle onClick={() => handleUserClick(user)}>{user.name}</OptionTitle>
          )
        })}
      </OptionContainer>
    </Container>
  )
}

OnlineUsers.propTypes = {
  data: pt.array,
  toggleUsersPopup: pt.func,
  requestGetUser: pt.func,
  setEntity: pt.func,
}

OnlineUsers.defaultProps = {
  data: [],
}

export default OnlineUsers
