import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import moment from 'moment'
import {
  GET_FAULT_ANALYTICS_TABLE,
  FAULT_ANALYTICS_TABLE,
  SET_SELECTED_ELEMENT,
} from '@/store/actions/faultAnalytics'
import {
  SET_FILTER_VALUES,
  RESET_FILTER_VALUES
} from '@/store/actions/analyticsAlarms'
import { ALARMS_ANALYTICS_SUMMARY_TABLE_ENDPOINT } from '@/constants/apiRoutes'
import {
  successSummaryAlarmAnalyticsTable,
  errorSummaryAlarmAnalyticsTable,
} from '@/store/actions/faultAnalytics/getTable'
import { getSelectedNode } from '@/store/selectors/appSettings'
import {
  getTableParameters,
} from '@/store/selectors/faultAnalytics'
import get from 'lodash/get'
import { treeHandlers } from 'react-hyper-tree'
import { setGlobalLoading } from '@/store/actions/appSettings'
import { getFilterOptions } from '@/store/selectors/analyticsAlarms'

function* getAlarmManagerTableSaga() {
  try {
    yield put(setGlobalLoading(true))
    const node = yield select(getSelectedNode)

    const tableParameters = yield select(getTableParameters)
    const filterValues = yield select(getFilterOptions)

    const response = yield request({
      url: ALARMS_ANALYTICS_SUMMARY_TABLE_ENDPOINT({
        params: {
          pageNumber: tableParameters.page - 1,
          pageSize: tableParameters.perPage,
          sort: [`${tableParameters.sort.field}: ${tableParameters.sort.direction}`],
          search: tableParameters.requestQuery || '',
          projectIds: filterValues.project,
          installationTypes: filterValues.objectsTypes,
          fromDate: filterValues.dateFirst && moment(filterValues.dateFirst).format('DD.MM.YYYY'),
          toDate: filterValues.dateSecond && moment(filterValues.dateSecond).format('DD.MM.YYYY'),
          id: filterValues.geoZone,
        },
      }),
      method: 'get',
    })

    const {
      content,
      pageable,
    } = response.data
    yield put(setGlobalLoading(false))
    yield put(successSummaryAlarmAnalyticsTable({
      data: content,
      totalElements: pageable.totalElements,
      totalPages: pageable.totalPages,
    }))

    const selectedNodePath = get(node, 'original.options.path', null)

    setTimeout(() => {
      const tree = treeHandlers.trees['analytics-alarms-tree']
      if (selectedNodePath && tree && tree.handlers) {
        tree.handlers.setSelectedByPath(selectedNodePath)
      }
    })
  } catch (error) {
    yield put(setGlobalLoading(false))
    yield put(errorSummaryAlarmAnalyticsTable(error))
  }
}

export default function* root() {
  yield takeLatest(GET_FAULT_ANALYTICS_TABLE.REQUEST, getAlarmManagerTableSaga)
  yield takeLatest(FAULT_ANALYTICS_TABLE.SORT, getAlarmManagerTableSaga)
  yield takeLatest(FAULT_ANALYTICS_TABLE.PER_PAGE, getAlarmManagerTableSaga)
  yield takeLatest(FAULT_ANALYTICS_TABLE.PAGE, getAlarmManagerTableSaga)
  // yield takeLatest(FAULT_ANALYTICS_TABLE.SELECTED_FIELDS, getAlarmManagerTableSaga)
  yield takeLatest(FAULT_ANALYTICS_TABLE.REQUEST_QUERY, getAlarmManagerTableSaga)
  yield takeLatest(SET_SELECTED_ELEMENT, getAlarmManagerTableSaga)
  yield takeLatest(SET_FILTER_VALUES, getAlarmManagerTableSaga)
  yield takeLatest(RESET_FILTER_VALUES, getAlarmManagerTableSaga)
}
