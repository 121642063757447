import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getDictionaries = createSelector(
    getState,
    (state) => get(state, 'dictionaries', {}),
)

export const getConnectedIntegrations = createSelector(
    getDictionaries,
    (dictionaries) => get(dictionaries, 'connectedIntegrations', []),
)

export const getConnectedIntegrationsOptions = createSelector(
    getConnectedIntegrations,
    (dictionaries) => get(dictionaries, 'data', []),
)