import { takeLatest, put } from 'redux-saga/effects'
import get from 'lodash/get'
import cookie from 'react-cookies'
import request from '@/helpers/axios'
import { USER_SETTINGS_ENDPOINT } from '@/constants/apiRoutes'
import { LOCATION_CHANGE } from '@/store/actions/appSettings'
import {
  SKY_SCHEME_NAME,
  LIGHT_THEME_NAME,
  SLS24_ACCESS_TOKEN,
  REFRESH_30
} from '@/constants/names'
import {
  successGetSettings,
  errorGetSettings,
} from '@/store/actions/user/settings/getSettings'
import createNotifications from "@/helpers/notification";
import { OBJECTS } from '@/constants/instalationPassport/types'
import { successGetRootElements } from '@/store/actions/installation/getRootElemenet'
import {
  OK,
  WARNING,
  ERROR,
  UNKNOWN,
} from '@/constants/alarms'
import { activeFields } from '@/constants/tablesConfig/alarmManager'


function* getSettings() {
  try {
    yield put(successGetRootElements(null))
    const token = cookie.load(SLS24_ACCESS_TOKEN)
    const response = token
      ? yield request({
        url: USER_SETTINGS_ENDPOINT,
        method: 'get',
      })
      : null

    if (response) {
      const settings = {
        appSettings: {
          scheme: get(response, 'data.appSettings.scheme', SKY_SCHEME_NAME),
          theme: get(response, 'data.appSettings.theme', LIGHT_THEME_NAME),
          largeFont: get(response, 'data.appSettings.largeFont', false),
          isMenuSideBarPinned: get(response, 'data.appSettings.isMenuSideBarPinned', false),
          mapLayer: get(response, 'data.appSettings.mapLayer', OBJECTS),
          russianBorders: get(response, 'data.appSettings.russianBorders', false),
          refreshRate: get(response, 'data.appSettings.refreshRate', REFRESH_30),
        },
        generalPage: {
          applications: get(response, 'data.generalPage.applications', []),
        },
        globalFilterValues: get(response, 'data.globalFilterValues', {}),
        alarmsManagerFilterValues: get(response, 'data.alarmsManagerFilterValues', {
          eventTypes: [OK, WARNING, ERROR, UNKNOWN],
          eventStatus: ['all', 'new', 'old'],
          dates: {
            start: null,
            end: null,
          },
          activeFields,
        }),
        pinnedNode: get(response, 'data.pinnedNode', {}),
      }
      window.localStorage.setItem('pinnedNode', JSON.stringify(response.data.pinnedNode))
      yield put(successGetSettings(settings))
    }
  } catch (error) {
    yield put(errorGetSettings(error))
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось получить данные настроек пользователя.\nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(LOCATION_CHANGE, getSettings)
}
