import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import ServiceIcons from '@/components/icons/serviceDeskMyCity'
import {
  RECEIVED,
  DONE,
  TASKS_PERFORMED,
  WAITING_FOR_CONTROL,
  OVERDUE_ON_EXECUTION,
  AVERAGE_LEAD_TIME,
} from '@/constants/serviceDeskMyCity'

export default [
  {
    id: RECEIVED,
    count: 0,
    text: <Lang id={"serviceDeskMyCity.statusSD.registered"}/>,
    icon: ServiceIcons.ReceivedIcon,
  },
  {
    id: TASKS_PERFORMED,
    count: 0,
    text: <Lang id={"serviceDeskMyCity.statusSD.inWork"}/>,
    icon: ServiceIcons.TasksPerformedIcon,
  },
  {
    id: DONE,
    count: 0,
    text: <Lang id={"serviceDeskMyCity.statusSD.resolved"}/>,
    icon: ServiceIcons.DoneIcon,
  },
  {
    id: OVERDUE_ON_EXECUTION,
    count: 0,
    text: <Lang id={"serviceDeskMyCity.overdue"}/>,
    icon: ServiceIcons.OverdueOnExecutionIcon,
  },
  {
    id: WAITING_FOR_CONTROL,
    count: 0,
    text: <Lang id={"serviceDeskMyCity.statusSD.delayed"}/>,
    icon: ServiceIcons.WaitingForControlIcon,
  },
  {
    id: AVERAGE_LEAD_TIME,
    count: 0,
    text: <Lang id={"widgetsDashboard.executionTime"}/>,
    icon: ServiceIcons.AverageLeadTimeIcon,
  },
]
