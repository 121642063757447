import {
  takeLatest, select, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { generateManagementData } from '@/constants/apiRoutes'
import {
  MULTIPLE_EDITING,
  setUsersSort,
  setFormIsModifying,
} from '@/store/actions/users'
import {
  requestGetAllUsers, responseGetAllUsers,
} from '@/store/actions/users/user/getAllUsers'
import {
  getUserSortOrder,
  getUserSortBy,
  getAllUsers,
} from '@/store/selectors/users'
import { errorUsersMultipleEditing } from '@/store/actions/users/user/multipleEditing'
import { requestGetAllAdminTenants } from '@/store/actions/users/administrator/getAllAdminTenants'
import { requestGetTenantGroup } from '@/store/actions/users/group/getGroup'
import { getUserData } from '@/store/selectors/appSettings'
import { requestGetTenantGroups } from '@/store/actions/users/group/getTenantGroup'
import { requestGetAllTenants } from '@/store/actions/users/tenant/getAllTenants'
import createNotifications from '@/helpers/notification'
import toastErrorHelper from '@/helpers/toastHelper'
import { SELECT_GROUP, BLOCK_USERS } from '@/constants/validationFields'
import { ROLE_TENANT_ADMIN } from '@/constants/role'

const ERRORS = {
  'validation_failed.email:not_unique': 'Пользователь с таким адресом электронной почты уже существует',
  'validation_failed.email:invalid': 'Неверный адрес электронной почты',
}

function* multipleEditingSaga({ payload }) {
  const currentUser = yield select(getUserData)
  const {
    form, selectedIds, tenantId, groupId, isBlocked
  } = payload
  const formValues = form.state.values
  const toast = createNotifications()
  const usersIds = selectedIds.join(',')
  const dataJson = {
    groupId: formValues[SELECT_GROUP],
    blocked: formValues[BLOCK_USERS] ? !isBlocked : isBlocked,
  }

  const isOnlyBlock = !dataJson.groupId && !isBlocked
  const isOnlyUnBlock = !dataJson.groupId && isBlocked
  try {
    if (Object.keys(form).length > 0) {
      form.setSubmitting(true)
    }
    yield request({
      url: generateManagementData(usersIds),
      method: 'patch',
      body: dataJson,
    })

    let description = selectedIds.length > 1 ? 'Пользователи успешно перемещены.' : 'Пользователь успешно перемещен.'

    if (isOnlyBlock) {
      description = selectedIds.length > 1 ? 'Пользователи успешно заблокированы.' : 'Пользователь успешно заблокирован.'
    }
    if (isOnlyUnBlock) {
      description = selectedIds.length > 1 ? 'Пользователи успешно разблокированы.' : 'Пользователь успешно разблокирован.'
    }

    toast({
      title: 'Успешная операция!',
      description,
    })
    yield put(setFormIsModifying(false))
    yield put(requestGetTenantGroups(tenantId))
    yield put(requestGetTenantGroup({ tenantId, groupId }))
    yield put(requestGetAllTenants())
    if (currentUser.role === ROLE_TENANT_ADMIN) {
      yield put(requestGetAllAdminTenants({ id: tenantId }))
      yield put(requestGetAllUsers({ tenantId, groupId }))
      if (dataJson.groupId) {
        yield put(requestGetTenantGroup({ tenantId, groupId }))
        yield put(requestGetAllUsers({ tenantId, groupId: formValues[SELECT_GROUP] || null }))
        const users = yield select(getAllUsers)
        const usersWithId = {
          ...users,
          [`${tenantId}-${groupId}`]: [],
        }
        yield put(responseGetAllUsers(usersWithId))
      }
    }
    const sortValue = yield select(getUserSortOrder)
    const sortType = yield select(getUserSortBy)
    yield put(setUsersSort({
      sortValue,
      sortType,
    }))
  } catch (error) {
    yield put(errorUsersMultipleEditing())
    toast({
      title: 'Ошибка операции!',
      description: `Ошибка при перемещает выбранных пользователей! Повторите попытку позже. Код ошибки: ${toastErrorHelper(error)}`,
      type: 'error',
    })
    if (Object.keys(form).length > 0) {
      form.setErrors({
        [error.response.data.subject]: ERRORS[error.response.data.error],
      })
    }
  }
}

export default function* root() {
  yield takeLatest(MULTIPLE_EDITING.REQUEST, multipleEditingSaga)
}
