import {
  put, takeLatest, call,
} from 'redux-saga/effects'
import { treeHandlers } from 'react-hyper-tree'
import updateNodeCount from '@/helpers/updateNodeCount'
import { successDeleteObject } from '@/store/actions/installation/deleteObject'
import getNewPath from '@/helpers/getNewPath'
import request from '@/helpers/axios'
import { DELETE_OBJECT } from '@/store/actions/installation'
import {
  generateDeleteTreeElement,
  generateGetTreeElement,
} from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { requestGetPinsAndZonesAndTelemetry } from '@/store/actions/installation/getZonesAndPinsAndTelemetry'
import { setGlobalLoading } from '@/store/actions/appSettings'
import get from 'lodash/get'
import { requestGetConnectedLinesCoordinates } from '@/store/actions/installation/getConnectedLinesCoordinates'
import { CONNECTED_LINES_OBJECT_TYPES } from '@/constants/instalationPassport/types'

function* deleteObjectSaga({ payload }) {
  const {
    setUrlFormValues, formValues
  } = payload
  const {
    id,
    parentGeoZoneId: formParentGeoZoneId,
    parentTreeId: parentId,
    path,
    rootId,
    FILTER,
  } = formValues
  try {
    yield put(setGlobalLoading(true))
    const { data: parentData } = yield request({
      url: generateGetTreeElement(parentId),
      method: 'get',
    })

    yield request({
      url: generateDeleteTreeElement(id),
      method: 'delete',
    })
    const newPath = getNewPath(path)
    const toast = createNotifications()

    const tree = treeHandlers.trees['installations-tree']
    const parent = tree.instance.getNodeById(parentId)
    const node = tree.instance.getNodeById(id)
    const parentChildren = yield call(parent.data.getChildren, parent)
    tree.handlers.setRawChildren(parent, parentChildren, 'first', true)
    updateNodeCount(tree, newPath, 'remove', node.data.childrenCount + 1)
    const selectedNodePath = get(parent, 'options.path', '')
    tree.handlers.setSelectedByPath(selectedNodePath)
    setUrlFormValues({
      id: parentData.id,
      rootId,
      parentGeoZoneId: formParentGeoZoneId,
      parentTreeId: parentData.parentId,
      path: newPath,
      type: parentData.treeNodeType,
      FILTER,
    })
    yield put(requestGetPinsAndZonesAndTelemetry({
      includeAll: true,
      parentTreeId: path.split('/')[2]
    }))
    if (CONNECTED_LINES_OBJECT_TYPES.some(type => type === parent.data.type)) {
      yield put(requestGetConnectedLinesCoordinates({
        id: parentId
      }))
    }
    yield put(setGlobalLoading(false))
    toast({
      title: 'Удаление объекта',
      type: 'success',
      description: 'Объект успешно удален.',
    })
    yield put(successDeleteObject())
  } catch (error) {
    yield put(setGlobalLoading(false))
    setUrlFormValues({ FILTER })
    console.log('function*deleteObjectSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(DELETE_OBJECT.REQUEST, deleteObjectSaga)
}
