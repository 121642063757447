import { GET_TREE_FOR_WIDGETS } from './index'

export const requestGetTreeForWidgets = (payload) => ({
    type: GET_TREE_FOR_WIDGETS.REQUEST,
    payload,
})

export const successGetTreeForWidgets = (payload) => ({
    type: GET_TREE_FOR_WIDGETS.SUCCESS,
    payload,
})

export const errorGetTreeForWidgets = (payload) => ({
    type: GET_TREE_FOR_WIDGETS.ERROR,
    payload,
})