import generateDefaultTableState from '@/helpers/table/generateDefaultTableState'
import { searchFields } from '@/constants/tablesConfig/search'
import { handleActions } from 'redux-actions'
import generateDefaultTableReducer, { generateTableParameter } from '@/helpers/table/generateDefaultTableReducer'
import {
    GET_SEARCH_TABLE,
    RESET_SEARCH_TABLE_PARAMETERS,
    SEARCH_TABLE,
    SET_SEARCH_VALUES,
    RESET_SEARCH_VALUES,
    GET_SEARCH_REPORT_FILE
} from '@/store/actions/search'
import REQUEST_STATUSES from '@/constants/requests'

const initialState = {
  data: [],
  table: generateDefaultTableState(searchFields),
  loadingTable: false,
  searchValues: {
      name: ''
  },
  fileExportStatus: REQUEST_STATUSES.NOT_REQUESTED,
}

export const reducer = handleActions(
 {
    [GET_SEARCH_TABLE.REQUEST]: (state) => ({
      ...state,
      loadingTable: true,
    }),
    [GET_SEARCH_TABLE.SUCCESS]: (state, { payload }) => ({
      ...state,
      ...payload,
      ...generateTableParameter(state, 'resultCount', payload.totalElements),
      loadingTable: false,
    }),
    [GET_SEARCH_TABLE.ERROR]: (state) => ({
      ...state,
      loadingTable: false,
    }),
    [SET_SEARCH_VALUES]: (state, { payload }) => ({
      ...state,
      searchValues: payload,
    }),
    [RESET_SEARCH_VALUES]: () => ({
      ...initialState,
    }),
    [RESET_SEARCH_TABLE_PARAMETERS]: (state) => ({
      ...state,
      table: generateDefaultTableState(searchFields),
    }),
    [GET_SEARCH_REPORT_FILE.REQUEST]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.PENDING,
     }),
    [GET_SEARCH_REPORT_FILE.SUCCESS]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.IDLE,
    }),
    [GET_SEARCH_REPORT_FILE.ERROR]: (state) => ({
      ...state,
      fileExportStatus: REQUEST_STATUSES.ERROR,
    }),
   ...generateDefaultTableReducer(SEARCH_TABLE),
 },
 initialState,
)

export default reducer