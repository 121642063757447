import {
  FUEL_YELLOW,
  REGENT_GRAY,
  MALACHITE,
  RED,
} from '@/constants/styles/defaultTheme'

export const OK = 'NORMAL'
export const WARNING = 'WARNING'
export const ERROR = 'ERROR'
export const UNKNOWN = 'UNDEFINED'

export const STATUS_COLORS = {
  [OK]: MALACHITE,
  [WARNING]: FUEL_YELLOW,
  [ERROR]: RED,
  [UNKNOWN]: REGENT_GRAY,
}
