import { GET_SCHEDULE_DATA } from './index'

export const requestGetScheduleData = (payload) => ({
  type: GET_SCHEDULE_DATA.REQUEST,
  payload,
})

export const successGetScheduleData = (payload) => ({
  type: GET_SCHEDULE_DATA.SUCCESS,
  payload,
})

export const errorGetScheduleData = (payload) => ({
  type: GET_SCHEDULE_DATA.ERROR,
  payload,
})