import {
  takeLatest, put,
} from 'redux-saga/effects'
import moment from 'moment'
import request from '@/helpers/axios'
import { TENANT_ENDPOINT } from '@/constants/apiRoutes'
import { GET_ALL_TENANTS } from '@/store/actions/users'
import {
  NAME,
  SUB_END_DATA,
} from '@/constants/forms/group'
import { COMPANY } from '@/constants/viewTree'
import {
  ERROR,
} from '@/constants/objectStatuses'
import {
  responseGetAllTenants,
  errorGetAllTenants,
} from '@/store/actions/users/tenant/getAllTenants'
import createNotifications from "@/helpers/notification";

function* getAllTenantsSaga() {
  try {
    const response = yield request({
      url: TENANT_ENDPOINT,
      method: 'get',
    })

    const responseData = (response.data || []).map((tenant) => ({
      ...tenant,
      name: tenant.blocked ? `${tenant[NAME]} (заблок.)` : tenant[NAME],
      [SUB_END_DATA]: moment(tenant[SUB_END_DATA]).format('DD.MM.YYYY'),
      status: tenant.blocked ? ERROR : null,
      type: COMPANY,
      loading: true,
      children: tenant.usersAmount > 0 ? [{}] : [],
    }))
    yield put(responseGetAllTenants(responseData))
  } catch (error) {
    yield put(errorGetAllTenants(error))
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_ALL_TENANTS.REQUEST, getAllTenantsSaga)
}
