import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  successGetIntegrationTypes,
  errorGetIntegrationTypes,
} from '@/store/actions/installation/getAvalibleIntegrationTypes'

import { GET_TYPES } from '@/constants/apiRoutes'
import { GET_INTEGRATION_TYPES } from '@/store/actions/installation'
import createNotifications from '@/helpers/notification'
import { ASU_TYPE_TO_NAME } from '@/constants/maps'
import { sortOptionsByTitle } from '@/helpers/sortOptionsByTitle'

function* getIntegrationTypesSaga() {
  try {
    const { data } = yield request({
      url: GET_TYPES,
      method: 'get',
    })
    const formattedData = data.map((type) => ({
      value: type,
      title: ASU_TYPE_TO_NAME[type],
    }))
    const sortedIntegrationTypes = sortOptionsByTitle(formattedData)
    yield put(successGetIntegrationTypes(sortedIntegrationTypes))
  } catch (error) {
    console.log('function*getIntegrationTypesSaga -> error', error)
    yield put(errorGetIntegrationTypes())
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_INTEGRATION_TYPES.REQUEST, getIntegrationTypesSaga)
}
