import {
  GET_CREATE_OBJECTS_GROUP_SEARCH_TREE,
  GET_CREATE_OBJECTS_GROUP_TREE,
} from './index'

export const requestGetCreateObjectsGroupTree = (payload) => ({
  type: GET_CREATE_OBJECTS_GROUP_TREE.REQUEST,
  payload,
})

export const successGetCreateObjectsGroupTree = (payload) => ({
  type: GET_CREATE_OBJECTS_GROUP_TREE.SUCCESS,
  payload,
})

export const errorGetCreateObjectsGroupTree = (payload) => ({
  type: GET_CREATE_OBJECTS_GROUP_TREE.ERROR,
  payload,
})

export const successGetCreateObjectsGroupSearchTree = (payload) => ({
  type: GET_CREATE_OBJECTS_GROUP_SEARCH_TREE.SUCCESS,
  payload,
})