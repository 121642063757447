import React from 'react'
import { Route } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  BASE_URL,
  SIGN_IN_URL,
  SIGN_UP_URL,
  DASHBOARD_URL,
  USER_AGREEMENT,
  FORGOT_PASSWORD_URL,
} from '@/constants/routes'
import SignInPage from '@/containers/pages/SignIn'
import SignUpPage from '@/containers/pages/SignUp'
import ForgotPasswordPage from '@/containers/pages/ForgotPassword'
import { getIsMobile } from '@/store/selectors/appSettings'
import { setMobile } from '@/store/actions/appSettings'

import PrivateRoutes from '../PrivateRoutes'

const PublicRoutes = ({setMobile}) => { 
  
  window.addEventListener("DOMContentLoaded", () => {
    setMobile(window.matchMedia('(display-mode: standalone)').matches)
    if (window.matchMedia('(display-mode: standalone)').matches) {
      const viewport = document.querySelector("meta[name=viewport]");
      viewport.setAttribute('content', 'width=device-width');
    }
  });

  return (
  <>
    <Route
      exact
      path={BASE_URL}
      component={SignInPage}
    />
    <Route
      exact
      path={SIGN_IN_URL}
      component={SignInPage}
    />
    <Route
      exact
      path={SIGN_UP_URL}
      component={SignUpPage}
    />
    <Route
      exact
      path={FORGOT_PASSWORD_URL}
      component={ForgotPasswordPage}
    />
    <Route
      exact
      path={USER_AGREEMENT}
      // component={SignUpPage}
    />
    <Route
      path={DASHBOARD_URL}
      component={PrivateRoutes}
    />
  </>
)
}

// export default connect(() => ({
//   isLoggedIn: true,
// }))(PublicRoutes)

const mapStateToProps = (state) => ({
  isLoggedIn: true,
  isMobile: getIsMobile(state),
})

const mapDispatchToProps = (dispatch) => ({
  setMobile: (payload) => dispatch(setMobile(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(PublicRoutes)

