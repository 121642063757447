import styled from 'styled-components'
import { Form } from 'formik'
import {
  Label as FieldLabel,
  TextFieldStyled,
} from '@/components/controls/TextControl/styles'
import {
  Label as AreaLabel,
  TextFieldStyled as AreaTextFieldStyled,
} from '@/components/controls/TextAreaControl/styles'

export const FormWrapper = styled(Form)`
  overflow: auto;
  padding: 0 15px;
`
export const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-top: 15px;

  ${FieldLabel}, ${AreaLabel} {
    color: ${({ theme }) => theme.colors.colors.default};
  }

  ${TextFieldStyled}, ${AreaTextFieldStyled} {
    margin-bottom: 10px;
  }
`

export const InputAndLabelContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  input {
    width: 100%;
  }
  ${({ row }) => row && `
    justify-content: space-between;
    flex-direction: row;
  `}
`
export const Label = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.colors.option};
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 3rem;
`

export const MessageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.backgrounds.redisign.green};
  width: 100%;
  display: flex;
  align-items: center;
  padding: 5px 10px;
  border-radius: 4px;
  .svgFill {
    fill: ${({ theme }) => theme.colors.colors.light};
  }
`

export const MessageText = styled.div`
  color: ${({ theme }) => theme.colors.colors.light};
  font-style: normal;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
  letter-spacing: 0.01em;
  width: 242px;
  margin-left: 10px;
  text-align: start;
`

export const Text = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 1.4rem;
  line-height: 1.6rem;
  letter-spacing: 0.01em;
  margin-bottom: 20px;
  margin-top: 5px;
`
