export default [[
  [69.6765, 30.9250],
  [68.1583, 28.7277],
  [66.0370, 29.7824],
  [62.8923, 31.1887],
  [59.9307, 27.1457],
  [56.1600, 28.6399],
  [55.6912, 31.0129],
  [53.2872, 32.7268],
  [53.0098, 31.4963],
  [52.1667, 31.9577],
  [52.3151, 34.0232],
  [51.2384, 34.3967],
  [51.1694, 35.2536],
  [50.4152, 36.3399],
  [49.4299, 40.1645],
  [47.8617, 39.7901],
  [47.6914, 38.7135],
  [47.6023, 38.3619],
  [47.1545, 38.2740],
  [47.2594, 39.2079],
  [47.0120, 39.3617],
  [46.6122, 37.8566],
  [45.7191, 37.8895],
  [45.3562, 37.5709],
  [45.3407, 35.3846],
  [45.9418, 34.6705],
  [45.9112, 33.6708],
  [45.3872, 32.5062],
  [45.1078, 33.4730],
  [44.8661, 33.6378],
  [44.5685, 33.5060],
  [44.4112, 34.0443],
  [45.0300, 35.4286],
  [44.9832, 37.2523],
  [43.4481, 40.0194],
  [43.5441, 40.7116],
  [43.1793, 42.1068],
  [43.2316, 42.4309],
  [43.1592, 42.6891],
  [42.7193, 43.8207],
  [42.7193, 43.8207],
  [42.6342, 44.2437],
  [42.7436, 44.5568],
  [42.6950, 45.1555],
  [42.5018, 45.7577],
  [42.2452, 45.6148],
  [41.7246, 46.9470],
  [41.3407, 47.2656],
  [41.3407, 47.2656],
  [41.8192, 48.6279],
  [43.0400, 47.4853],
  [43.9239, 47.7765],
  [43.8524, 47.5293],
  [44.2288, 47.2382],
  [44.2288, 47.1338],
  [44.3709, 47.0294],
  [44.3984, 46.7163],
  [44.6970, 46.7712],
  [45.5784, 47.5846],
  [45.9437, 48.6118],
  [46.3408, 49.1337],
  [46.5686, 48.5569],
  [46.6972, 48.5239],
  [46.7426, 49.0183],
  [47.7119, 48.1107],
  [47.7082, 47.3142],
  [48.2357, 47.1164],
  [48.3889, 46.6329],
  [49.8890, 46.9202],
  [50.4149, 47.6014],
  [49.8322, 48.4309],
  [50.0095, 48.9033],
  [50.6180, 48.5682],
  [51.6287, 50.8134],
  [51.4712, 53.5655],
  [50.8594, 54.4218],
  [50.5454, 54.4657],
  [50.5489, 54.7349],
  [51.0190, 54.5701],
  [50.5629, 55.7346],
  [51.0814, 57.2507],
  [50.9080, 57.5034],
  [50.9184, 57.7561],
  [51.1194, 57.8220],
  [51.0645, 58.5515],
  [50.5389, 59.5458],
  [50.8007, 60.0237],
  [50.7902, 60.2599],
  [50.6665, 60.3341],
  [50.6612, 60.8202],
  [50.8181, 61.4629],
  [51.4093, 61.5041],
  [51.4883, 60.9521],
  [51.9363, 60.0354],
  [52.3587, 61.1120],
  [52.7638, 60.7605],
  [52.9765, 62.1337],
  [53.1288, 62.0568],
  [53.3200, 61.2274],
  [54.0151, 61.3764],
  [54.9654, 68.1957],
  [55.1923, 68.2067],
  [55.2594, 70.8668],
  [54.2384, 71.2448],
  [54.0189, 73.6707],
  [53.5603, 73.4345],
  [54.2930, 76.4832],
  [50.8946, 79.8024],
  [50.8737, 83.4495],
  [49.2041, 87.2508],
  [50.8042, 92.5242],
  [50.0179, 98.0394],
  [52.1622, 98.8084],
  [53.545497896909716,123.63190724725217],
  [53.493936817333434,123.68546559686156],
  [53.49966893998393,123.73627736443966],
  [53.48738486660633,123.78296925897094],
  [53.488203916065295,123.85575368279905],
  [53.345450005732616,124.12079884881474],
  [53.38077306962249,124.2265422570179],
  [53.33312108375087,124.31443288201791],
  [53.25660180716055,124.36799123162724],
  [53.2475420294256,124.40644338006479],
  [53.21458112998894,124.42910268182264],
  [53.20303879080585,124.49296071404922],
  [53.20716141344561,124.58222463006486],
  [53.20510015193933,124.67560841912736],
  [53.15188515566743,124.70719411248673],
  [53.13619677013847,124.75319936151017],
  [53.145693059780726,124.82323720330709],
  [53.09447069630553,124.8575694786977],
  [53.095710671047264,124.88709523553362],
  [53.11802407665027,124.90014150018206],
  [53.16013988795678,124.87473561639301],
  [53.18901889281524,124.95575978631491],
  [53.20633692080042,125.15008046502581],
  [53.1527107006706,125.28328969354148],
  [53.09510232770223,125.49650977605687],
  [53.05333616996765,125.49513648504124],
  [53.04837114829535,125.51710914129126],
  [53.08394110373474,125.59881995672093],
  [53.02063921233375,125.65649817937714],
  [52.989574738033454,125.71898292058805],
  [52.94272850146247,125.72928260320535],
  [52.91700357914207,125.65718482488504],
  [52.874648498194084,125.65512488836157],
  [52.85387100870991,125.68327735418191],
  [52.90413535626567,125.84051917547094],
  [52.872155727361935,125.8432657575022],
  [52.753583216088494,125.97235511297104],
  [52.79980273297543,126.03964637273666],
  [52.759415422876344,126.11449073308822],
  [52.74233317251083,126.03621314519762],
  [52.678110438581946,126.06573890203354],
  [52.663918589358005,126.05200599187731],
  [52.67727575215064,125.99089454168197],
  [52.659326116896665,125.9661753034007],
  [52.62173312518211,125.96274207586163],
  [52.56946676029181,126.02591346258038],
  [52.575742032755514,126.04307960027569],
  [52.605014705636556,126.05681251043191],
  [52.59456243546135,126.09183143133038],
  [52.53095867683328,126.20444129461164],
  [52.480679696881666,126.18109534734606],
  [52.46348779598827,126.19002173894758],
  [52.47145561361617,126.2168009137523],
  [52.473971465408916,126.2600595807445],
  [52.38919161702704,126.34863685125238],
  [52.35178609156211,126.34177039617423],
  [52.343375974307406,126.31361793035393],
  [52.32192289653379,126.31293128484609],
  [52.30003840200231,126.33421729558832],
  [52.301722210723575,126.42142127508049],
  [52.28319677542445,126.43584083074455],
  [52.273509925937624,126.42554114812737],
  [52.28193339399585,126.40700171941643],
  [52.26676999292337,126.35756324285391],
  [52.234741245267735,126.30057166570549],
  [52.20732979628193,126.29301856511954],
  [52.18792064823216,126.34932349676018],
  [52.16132473028886,126.49077247136957],
  [52.1342902888017,126.55531714910394],
  [52.11823078296198,126.55806373113519],
  [52.091592994989576,126.53128455633046],
  [52.079325780689686,126.54089759343988],
  [52.05266463520065,126.51068519109613],
  [52.045467616822016,126.5196115826977],
  [52.035305136807196,126.50725196355704],
  [52.04038666709097,126.47085975164302],
  [52.0153968861776,126.43755744451413],
  [51.98721361074175,126.45712684148678],
  [51.98657769206099,126.45747016424079],
  [51.9407676934516,126.4588434552564],
  [51.93143025724116,126.49042914861577],
  [51.888962745557215,126.53712104314701],
  [51.837097708256906,126.56321357244389],
  [51.77069089344064,126.635997996272],
  [51.744271132504274,126.64973090642827],
  [51.73062904732446,126.6634638165845],
  [51.73062904732446,126.70878242010015],
  [51.71186442040076,126.7293817853345],
  [51.69565230643538,126.71290229314702],
  [51.65466937324976,126.73624824041266],
  [51.62647197985189,126.72114203924076],
  [51.60338827457443,126.67033027166266],
  [51.57601452598359,126.68955634588139],
  [51.53320984602713,126.83924506658452],
  [51.4406135055827,126.7719538068189],
  [51.41485874734329,126.81040595525639],
  [51.41056486886191,126.89554999822515],
  [51.362445729328165,126.92438910955329],
  [51.34008740814327,126.89966987127202],
  [51.342667774181145,126.82139228338137],
  [51.309972346801075,126.80353950017827],
  [51.26174689323826,126.822765574397],
  [51.240201234964964,126.88593696111575],
  [51.25571513187849,126.91340278142825],
  [51.27553078895177,126.90516303533451],
  [51.3159969494247,126.86396430486577],
  [51.334065986150605,126.94086860174076],
  [51.32804376905978,126.97382758611576],
  [51.29619884018379,126.97245429510014],
  [51.254853386735284,126.93125556463144],
  [51.21174542497811,126.89280341619396],
  [51.09860996198906,126.9161493634596],
  [51.05795138343403,126.91202949041273],
  [51.03024839132115,126.9765741681471],
  [50.968722893766035,127.03425239080336],
  [50.93055324469859,127.11802314275646],
  [50.769722275976385,127.23475287908458],
  [50.757528587290345,127.28144477361586],
  [50.739232063588865,127.30479072088146],
  [50.71569739710508,127.27869819158462],
  [50.682554394719155,127.28007148260023],
  [50.63978259953363,127.30891059392837],
  [50.62842840585274,127.32813666814708],
  [50.58298405365184,127.36384223455333],
  [50.54362158788055,127.3556024884596],
  [50.53224405407747,127.31715034002205],
  [50.47356223068703,127.28281806463143],
  [50.44901675964967,127.30616401189707],
  [50.44901675964967,127.34186957830332],
  [50.436739220892385,127.36384223455333],
  [50.398131820046345,127.36109565252204],
  [50.34631314258078,127.32401679510016],
  [50.30675216466396,127.34873603338143],
  [50.28211973134227,127.39268134588141],
  [50.23986286580685,127.59180854314707],
  [50.201094289058624,127.6000482892408],
  [50.0305, 127.4776],
  [49.913644888955446, 127.54355536126927],
  [49.796445670712004, 127.5160895409567 ],
  [49.764432631654294, 127.6808844628318],
  [49.6433024738006, 127.70285711908177],
  [49.58262347768805, 127.81821356439428],
  [49.57905175901083, 127.91709051751927],
  [49.61475711527018, 127.98300848626927],
  [49.53617062539136, 128.12583075189428],
  [49.493251621269835, 128.29611883783176],
  [49.564762255031866, 128.35654364251928],
  [49.60761814711356, 128.56528387689428],
  [49.557615925400434, 128.78501043939428],
  [49.46819804283684, 128.77951727533178],
  [49.44313157266182, 128.98825750970676],
  [49.364267288601745, 129.0871344628318],
  [49.392959949422504, 129.22995672845678],
  [49.35709149092888, 129.34531317376928],
  [49.432384852822814, 129.38101874017536],
  [49.432384852822814, 129.4771491112691],
  [49.40371535407328, 129.5430670800191],
  [49.27629119337453, 129.55954657220664],
  [49.29785097471394, 129.6968756737691],
  [49.25112613075688, 129.75455389642536],
  [49.1935575137139, 129.7490607323629,],
  [49.16474790914605, 129.81223211908164,],
  [49.17915481971783, 129.84793768548786,],
  [49.105274825212874, 129.8506842675191],
  [49.096257490926746,129.9220954003316],
  [49.02947799644301, 129.9166022362691],
  [49.01683388453382, 130.01273260736286],
  [48.86304033908081, 130.2159796776754],
  [48.90289144735239, 130.42746649408159],
  [48.85941592724114, 130.5043707909566],
  [48.86485244602238, 130.59088812494096],
  [48.88749821438912, 130.63895331048775],
  [48.858509783051716, 130.69113836908153],
  [48.80138943171389, 130.62247381830022],
  [48.61415756310373, 130.520850283144],
  [48.58409897935275, 130.60736761712838],
  [48.49245209594997, 130.61048776037973],
  [48.484236230791545, 130.6420734537391],
  [48.517091658285786, 130.7272174967078],
  [48.48697500127995, 130.7684162271766],
  [48.43765436889076, 130.72447091467657],
  [48.35534617051588, 130.7697895181921],
  [48.337037267466, 130.8164814127234],
  [48.30039961106664, 130.8357074869421],
  [48.25639948165438, 130.795882047489],
  [48.251813942430665, 130.7642963541296],
  [48.21419691546178, 130.7505634439734],
  [48.183899588203246, 130.7505634439734],
  [48.10945699237067, 130.65168649084836],
  [48.068053054816744, 130.67915231116086],
  [48.040431799053096, 130.68601876623896],
  [48.02762281335161, 130.73231039674295],
  [48.000901416354594, 130.7419234338523],
  [47.92896094012057, 130.8682662072898],
  [47.820861188893026, 130.95341025025854],
  [47.715314949668155, 130.94654379518045],
  [47.679157550680465, 131.07288656861795],
[47.70048419667196, 131.19236288697732],
[47.739405868335886, 131.25004110963354],
[47.72736181031593, 131.37501059205542],
[47.7514471238565, 131.46015463502417],
[47.65874993980495, 131.57825766236792],
[47.657822130372885, 131.63181601197732],
[47.7088270176535, 131.6881209436179],
[47.70604622659126, 131.72794638307107],
[47.66617181810294, 131.81583700807107],
[47.681939784697434, 131.92158041627417],
[47.66060550896437, 131.9408064904928],
[47.675447673158786, 131.99024496705536],
[47.71160765949997, 131.99573813111786],
[47.69584871569156, 132.13444052369596],
[47.75792971612277, 132.3335677209616],
[47.714388152038076, 132.52170859010224],
[47.9630991176375, 132.70023642213334],
[47.93542125412329, 132.7963667932271],
[48.12701445522029, 133.13144980103962],
[48.0773427792722, 133.49399862916457],
[48.38562682866414, 134.26578817994584],
[48.39660242698966, 134.83707724244587],
[48.39111492551531,134.8238123874361],
[48.27390637862199,134.77437391087363],
[48.28857226940394,134.77162732884238],
[48.25006527174514,134.6700037936861],
[48.103104490432465,134.65077771946738],
[48.02762281346255,134.56014051243613],
[47.8984942377722,134.59309949681113],
[47.896647190941124,134.65901746556102],
[47.807910976276446,134.68373670384227],
[47.7357003029509,134.78261365696727],
[47.57053397092813,134.65352430149852],
[47.43839508347013,134.48323621556105],
[47.42348521399213,134.30196180149855],
[47.33206951771748,134.18935193821727],
[47.242364804688286,134.1399134616548],
[47.18996635856919,134.21681775852977],
[47.1018946872376,134.20857801243605],
[47.096268105898965,134.12618055149852],
[46.97985145828687,134.06026258274852],
[46.81983251337455,134.02181043431105],
[46.75569035742156,134.04378309056105],
[46.63285154074946,134.00533094212352],
[46.57416783565784,133.88997449681105],
[46.44901522879073,133.84053602024852],
[46.43572445501557,133.90645398899852],
[46.38442947506931,133.9476527194673],
[46.344499813449595,133.86250867649855],
[46.25883734034324,133.90920057102977],
[46.20165408188197,133.84602918431102],
[46.22834706407312,133.8048304538423],
[46.17303996718447,133.76363172337355],
[46.15204675524443,133.68123426243605],
[46.056521447489416,133.74165906712355],
[45.95316698184233,133.6729945163423],
[45.95314625223448,133.6729945163421],
[45.93889585539093,133.66612806126398],
  [45.94560238378913,133.6417521457366],
  [45.93985398086807,133.60638990208426],
  [45.92372280494812,133.5973386796748],
  [45.89879883301771,133.6117582353388],
  [45.86810771000559,133.58635235154972],
  [45.898319415459845,133.53142071092478],
  [45.887291661734864,133.501208308581],
  [45.85131628377886,133.47511577928412],
  [45.82683963969134,133.45726299608097],
  [45.797069123400895,133.46000957811222],
  [45.78602114909616,133.49914837205756],
  [45.77593194307552,133.4950284990107],
  [45.77881476088177,133.45932293260444],
  [45.75142193490087,133.4579496415888],
  [45.745172552234266,133.4826688798701],
  [45.735556742185715,133.47992229783878],
  [45.73651839820075,133.4586362870966],
  [45.6927461739165,133.42018413865912],
  [45.68937765031734,133.43185711229194],
  [45.70525604515316,133.43941021287787],
  [45.68745268800413,133.45932293260444],
  [45.69370857177529,133.47511577928407],
  [45.65857025162973,133.48335552537782],
  [45.647494672985545,133.4366636308466],
  [45.634490093530005,133.44627666795594],
  [45.62292791665571,133.47236919725285],
  [45.612327145585326,133.46687603319035],
  [45.62919106046235,133.42567730272157],
  [45.609917598099806,133.41881084764344],
  [45.61955516285623,133.4037046464716],
  [45.58678064059459,133.41125774705753],
  [45.58051272865343,133.41812420213566],
  [45.567010218147004,133.4037046464716],
  [45.57713740738205,133.37555218065125],
  [45.5120019736577,133.19496441209657],
  [45.415848989822415,133.1324796708856],
  [45.241969512763866,133.08716106736992],
  [45.13077404252122,133.12492657029955],
  [45.0690124251626,133.0274229081902],
  [45.05149296063354,133.03085613572924],
  [45.02422971844837,132.94227886522143],
  [45.06995079572354,132.84126998019036],
  [45.25127210423809,132.0027041537742],
  [45.26933592215064,131.9807314975242],
  [45.27752547610511,131.9563177548134],
  [45.27764667263112,131.93674835784074],
  [45.33881762553693,131.9041326962196],
  [45.33784909875958,131.87186035735238],
  [45.30636290018091,131.82036194426647],
  [45.29134020170157,131.8244818173133],
  [45.23363521247684,131.7729834042274],
  [45.20888642834186,131.78534302336806],
  [45.212769307714794,131.74757752043834],
  [45.23412037434731,131.71049866301647],
  [45.213739985862716,131.69127258879772],
  [45.21519597181318,131.67273316008678],
  [45.15449809748824,131.64526733977428],
  [45.149153565674084,131.67822632414928],
  [45.12436778029008,131.68715271575084],
  [45.069898189675875,131.62123474700084],
  [44.98955751742925,131.47360596282118],
  [44.97688742094897,131.49351868254772],
  [44.96372702935916,131.485278936454],
  [44.960802085053174,131.45712647063365],
  [44.96421450548141,131.41867432219615],
  [44.984684737005324,131.39532837493053],
  [44.97688742094897,131.36305603606337],
  [44.98907025815222,131.34588989836806],
  [44.960802085053174,131.30194458586803],
  [44.92032495305932,131.2731054745399],
  [44.92813000889919,131.24289307219615],
  [44.91251882964957,131.19963440520394],
  [44.93593399717229,131.1893347225868],
  [44.93495855702059,131.15912232024306],
  [44.94666273791732,131.14332947356337],
  [44.92813000889919,131.11586365325087],
  [44.93642171099288,131.0966375790321],
  [44.91690990542888,131.07672485930553],
  [44.894463080371864,131.093204351493],
  [44.8676129023677,131.03758606536022],
  [44.87981909257771,131.01973328215712],
  [44.86468302888265,130.9977606259071],
  [44.86419470203919,130.9696081600868],
  [44.81973950244643,130.95862183196178],
  [44.82071691095751,130.99638733489147],
  [44.79138739924546,131.01218018157115],
  [44.783563660552694,131.00668701750868],
  [44.77573885424824,131.04033264739147],
  [44.783563660552694,131.05681213957897],
  [44.75274731149845,131.0629919491493],
  [44.740514014141404,131.08565125090712],
  [44.72289348685817,131.07947144133678],
  [44.70624691498159,131.09251770598522],
  [44.707715924091616,131.10350403411022],
  [44.04377385274397,131.30259353679853],
  [44.040059093611646,131.2837107853336],
  [44.02965652023414,131.26808960003092],
  [44.03089501824784,131.25470001262852],
  [44.010456468653196,131.24131042522617],
  [44.00029648981988,131.24852020305823],
  [43.98654055641942,131.24422866863438],
  [43.98421967787627,131.23468258981714],
  [43.9658727167372,131.23330929880152],[
    43.936604944113654,131.25871518259058],
    [43.891931234561255,131.24635556344995],
    [43.86362041868945,131.22369626169217],
    [43.8482176217859,131.2271294892312],
    [43.823365958926026,131.20172360544214],
    [43.81839437569824,131.21545651559836],
    [43.79701181748974,131.216829806614],
    [43.797509173829,131.20515683298117],
    [43.78258667026733,131.20584347848902],
    [43.73879234969961,131.2223229706765],
    [43.72385508942716,131.20721676950464],
    [43.710906429260916,131.21957638864524],
    [43.69618413718304,131.2090849586737],
    [43.65731164129463,131.2310576149237],
    [43.627891241334204,131.21183154070496],
    [43.609433757709866,131.20977160418155],
    [43.59845633277673,131.23586413347843],
    [43.58048892263572,131.22556445086124],
    [43.59097056830452,131.2070250221503],
    [43.559020770591616,131.2015318580878],
    [43.52455577200899,131.19397875750187],
    [43.49007093247246,131.2585234352362],
    [43.47057072527521,131.28804919207215],
    [43.454065599430564,131.30178210222837],
    [43.39300725130691,131.30727526629087],
    [43.3764808122602,131.270196408869],
    [43.26341372104765,131.24279571196485],
    [43.23630993329735,131.20228362700385],
    [43.197640930659155,131.19473052641789],
    [43.178045909900185,131.21395660063666],
    [43.11972584011126,131.19473052641789],
    [43.09406738393947,131.15215850493354],
    [43.068398095829394,131.16177154204289],
    [43.06638436024766,131.11027312895695],
    [43.046746946589,131.11782622954286],
    [43.0205538760254,131.09379363676945],
    [42.963091207503304,131.11576629301945],
    [42.963091207503304,131.14323211333195],
    [42.93534864014796,131.13567901274595],
    [42.91112665343918,131.09928680083192],
    [42.924248086895986,131.0216958584491],
    [42.91163137599507,131.00521636626158],
    [42.86113851414058,131.03680205962095],
    [42.85507657337202,130.97294402739442],
    [42.87477568925651,130.93586516997252],
    [42.866694766611495,130.90221954008973],
    [42.848508794461594,130.88230682036317],
    [42.86568457635307,130.86582732817564],
    [42.86113851414058,130.84797454497254],
    [42.88487534398601,130.82394195219908],
    [42.866189673563596,130.77862334868348],
    [42.8389084675882,130.7745034756366],
    [42.83739248914339,130.7573373379413],
    [42.84244560494238,130.74085784575377],
    [42.83031742777227,130.71407867094905],
    [42.848508794486605,130.67631316801936],
    [42.83486577517965,130.63648772856624],
    [42.808581781201525,130.5746896328631],
    [42.81616485755545,130.5630166592303],
    [42.78329809668253,130.52044463774595],
    [42.77014646876289,130.46345306059746],
    [42.7428225599494,130.41813445708186],
    [42.72814360051451,130.4195077480975],
    [42.725106137478555,130.38998199126158],
    [42.70232038966298,130.4195077480975],
    [42.70839741299737,130.42912078520686],
    [42.70080104020423,130.4483468594256],
    [42.681552705617264,130.46070647856624],
    [42.701307494184896,130.52525115630058],
    [42.66533892153591,130.58636260649592],
    [42.61971481188585,130.61451507231624],
    [42.588772643858974,130.62481475493342],
    [42.55172314564734,130.6021554531756],
    [42.560353091019444,130.57880950591],
    [42.55375382917901,130.55889678618342],
    [42.53699869970564,130.56988311430842],
    [42.50499905641509,130.55889678618342],
    [42.483656790028746,130.59185577055842],
    [42.444001689341064,130.59734893462092],
    [42.42263846343696,130.63717437407402],
    [42.38199840736745,130.6621638699596],
    [42.33514659284185,130.6470576687877],
    [42.2922856982859,130.69714831787067],
    [42.37430957350435,130.7548265405269],
    [42.455717215732314,130.78709887939408],
    [42.48164352773905,130.85301684814408],
    [42.56646378361787,130.84340381103468],
    [42.5786439874596,130.77473926025343],
    [42.63342525222651,130.81456469970655],
    [42.59690980055134,130.92992114501905],
    [42.64761988117354,130.99034594970655],
    [42.635453256064565,131.0796098657222],
    [42.54818898536687,131.19221972900343],
    [42.55428118377012,131.22792529540965],
    [42.64052297442818,131.22517871337843],
    [42.78736380529819,131.28011035400343],
    [42.763083285296204,131.35838794189408],
    [42.78331771785651,131.40370654540968],
    [42.85946058008409,132.27539705551538],
    [42.689495044950654,132.31110262192163],
    [42.66112177990123,133.17902254379663],
  [42.717593000739065,133.02480115696713],
  [42.83090384314941,133.99159803196713],
  [43.47438648818235,135.20009412571716],
  [44.411060405002885,135.90321912571713],
  [44.47402573383482,136.32069959446716],
  [45.33287034002897,137.08974256321713],
  [45.65738568486346,137.50722303196716],
  [45.85733293226451,137.90273084446713],
  [46.25506454529668,138.27626600071713],
  [46.55905061571889,138.32021131321716],
  [45.8880300442491,141.96767225071716],
  [46.025955877481266,142.29726209446713],
  [46.634780792747044,142.53896131321713],
  [46.52872872097912,143.06630506321713],
  [46.08714534381921,143.2420863132171],
  [45.82661875420811,143.2420863132171],
  [44.45829080729224,145.81288709446713],
  [43.80948226172642,145.1756800632171],
  [43.58629420818165,145.46132459446716],
  [44.03183363080922,146.38417615696713],
  [46.25506454529668,151.7235316257171],
  [50.608785142139226,156.86513318821716],
  [52.14866864157505,158.71083631321716],
  [52.95228170314311,158.9745081882172],
  [52.978814459430374,160.42470350071716],
  [53.767209512768595,160.07314100071716],
  [54.54100601134517,161.03993787571716],
  [54.46427951588754,162.22646131321716],
  [54.92247162878272,162.6219691257172],
  [55.225052363598856,162.00673475071716],
  [56.0699390202997,162.3143519382172],
  [55.89748656466607,163.5008753757172],
  [56.192646987667125,163.98427381321716],
  [57.54030682467144,163.23720350071716],
  [62.240308098152454,179.36373418798837],
  [62.271390044758554,179.27918011270796],
  [62.67814456157254,179.85046917520796],
  [63.15895653562122,179.71863323770793],
  [63.534017009885126,179.05945355020796],
  [64.36489268157509,178.57605511270796],
  [64.72420755869427,179.27918011270796],
  [65.07880138792456,-179.44640582479204],
  [65.41042195143072,-178.12804644979207],
  [65.3187233361619,-176.15050738729204],
  [64.81797744272544,-175.97472613729204],
  [64.26954533114248,-173.86535113729204],
  [64.28864127076751,-172.01964801229204],
  [64.66778875292738,-172.06359332479204],
  [65.33708872830299,-171.84386676229204],
  [65.44701165592012,-170.48156207479204],
  [65.86412801630482,-170.12999957479204],
  [66.09692746056092,-169.11925738729204],
  [67.0242650396128,-171.27257769979204],
  [67.21241488505456,-174.21691363729204],
  [68.14779792672007,-176.10656207479204],
  [68.9361481940789,-179.00695269979204],
  [69.55946933557372,178.70789105020796],
  [69.94002546550958,176.24695355020796],
  [70.0949, 170.648],
  [70.0275, 168.209],
  [69.5027, 167.066],
  [69.6561, 160.562],
  [70.6998, 159.705],
  [70.8154, 152.628],
  [72.4561, 148.629],
  [74.7140, 145.465],
  [74.3860, 149.771],
  [75.2829, 152.057],
  [76.0871, 137.291],
  [74.8064, 137.818],
  [71.5876, 139.225],
  [71.3078, 129.820],
  [73.4108, 128.414],
  [74.0028, 114.977],
  [76.8377142697629,111.62297702246084],
  [77.46327475145345,106.43743014746086],
  [78.02310716906408,108.54680514746087],
  [78.6797120313152,106.70110202246083],
  [79.62229143030045,102.74602389746087],
  [79.11971856667721,100.37297702246089],
  [80.04125557589757,100.19719577246086],
  [80.37021424498268,97.82414889746087],
  [80.6026718836725,98.70305514746086],
  [80.84356445852515,98.70305514746086],
  [81.38647651475361,96.15422702246084],
  [81.29382860016322,93.69328952246084],
  [80.47260754925128,91.14446139746087],
  [79.91889496353095,90.00188327246086],
  [79.28441381596745,91.58391452246087],
  [79.31705586602082,93.60539889746089],
  [78.91877898383774,94.22063327246084],
  [78.96935504075566,96.06633639746083],
  [78.5582756610675,99.66985202246086],
  [77.9865640971511,98.70305514746086],
  [77.30968339493343,101.07610202246086],
  [76.15694366329488,92.23804667753797],
  [75.31219993741175,87.75562480253795],
  [74.5815629820528,84.50367167753797],
  [73.79094151511937,83.18531230253792],
  [73.56854647170904,79.23023417753797],
  [72.3847069231484,76.15406230253795],
  [73.49375671785177,75.09937480253794],
  [72.96087762305062,71.84742167753795],
  [73.69246355283776,71.58374980253792],
  [73.54365327817425,69.47437480253797],
  [71.92658909750239,67.89234355253795],
  [71.42895949241858,66.57398417753797],
  [70.71580246567922,65.87085917753794],
  [69.51959937222821,66.39820292753794],
  [69.76440254195597,63.14624980253796],
  [70.09642554233209,60.597421677537945],
  [70.80275574791484,58.04859355253793],
  [70.85330388419641,57.27955058378791],
  [71.18229875075794,56.356699021287895],
  [71.73461816052111,55.653574021287916],
  [72.19744908777871,55.653574021287916],
  [72.95443418483728,56.4445896462879],
  [74.1850826265351,58.79566386503788],
  [74.91693275668386,60.88306620878788],
  [75.23396597875339,61.67408183378787],
  [75.67558429858138,64.46460917753784],
  [76.05666274875524,67.34302714628782],
  [76.27217448668982,68.61744120878784],
  [76.55614780696848,69.14478495878784],
  [76.83935422798052,69.05689433378782],
  [77.03804579973605,68.35376933378781],
  [77.02324681939282,67.43091777128784],
  [76.70860222658551,65.89283183378785],
  [76.61733464154058,65.71705058378784],
  [76.49981851981956,65.25562480253782],
  [76.33982235887936,64.13501933378782],
  [76.23561262012484,62.904550583787845],
  [76.31384276080584,61.608163865037824],
  [76.17271267106511,60.004159958787845],
  [75.77315092328264,58.224374802537845],
  [75.35116462653052,56.81812480253785],
  [75.18345752532436,56.26880839628788],
  [75.20031243226875,55.82935527128789],
  [75.0081868132803,55.60962870878789],
  [74.853880799042,55.87330058378787],
  [74.49365054987928,55.389902146287895],
  [73.94365450452426,54.401132615037874],
  [73.79707711606419,53.500253708787874],
  [73.50624464506828,54.15943339628787],
  [73.28637277516161,53.829843552537895],
  [73.12119660668634,53.08277324003789],
  [72.92863675388645,52.81910136503787],
  [72.63579057352823,52.094003708787874],
  [72.53709607797535,52.77515605253788],
  [72.14359351653214,52.22583964628787],
  [72.27793748305577,51.43482402128788],
  [71.51280775143938,51.36890605253789],
  [70.78105291269893,53.14869120878789],
  [70.49675636916315,55.06031230253787],
  [70.40109432005126,57.01587870878789],
  [70.1860650698598,58.070566208787845],
  [69.63470244405809,59.49878886503785],
  [69.12356776111066,60.55347636503781],
  [68.76805084683177,60.28980449003783],
  [68.92676726152288,59.43287089628781],
  [69.3574069583578,59.43287089628781],
  [69.46567141135769,58.40015605253782],
  [69.02932394921126,59.081308396287824],
  [68.75211652160267,57.16968730253784],
  [68.97416057921676,55.60962870878784],
  [69.05292299046496,54.48902324003784],
  [67.4344, 47.8738],
  [67.8858, 39.4803],
  [69.4222, 33.7234],
]]
