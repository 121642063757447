import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import shortId from 'shortid'
import {
  GET_REPORT_MANAGER,
} from '@/store/actions/reportManager'
import { GET_REPORT_URL } from '@/constants/apiRoutes'
import {
  successGetReport,
  errorGetReport,
} from '@/store/actions/reportManager/getReport'
import { getZones } from '@/store/selectors/maps/socketEvents'
import getReportManagerSchedule from '@/helpers/getReportManagerSchedule'
import getFullName from '@/helpers/fullName'

function* getReportSaga({ payload }) {
  const zones = yield select(getZones)
  try {
    const response = yield request({
      url: GET_REPORT_URL({ id: payload.id }),
      method: 'get',
    })
    const newResponse = {
      ...response.data,
      geoZoneId: {
        id: response.data.geoZoneId,
        title: (zones[response.data.geoZoneId] || {}).name || '',
      },
      scheduleView: getReportManagerSchedule({
        type: response.data.schedule.type,
        time: response.data.schedule.start,
        value: response.data.schedule.timeValue,
        timeZone: (response.data.schedule.timeZone || 0) * 60,
      }),
      timeZone: (response.data.schedule.timeZone || 0) * 60,
      users: response.data.users.map((user) => ({
        value: user.id,
        title: getFullName(user.lastName, user.firstName, user.middleName),
        ...user,
      })),
      usersId: response.data.users.map((user) => user.id),
      externalRecipients: response.data.externalRecipients.map((externalRecipient) => ({
        ...externalRecipient,
        id: shortId.generate(),
      })),
    }
    yield put(successGetReport(newResponse))
  } catch (error) {
    yield put(errorGetReport(error))
  }
  return null
}

export default function* root() {
  yield takeLatest(GET_REPORT_MANAGER.REQUEST, getReportSaga)
}
