import React from 'react';
import { Table, TableHeader, TableCell } from '@/containers/pages/Scheduler/styles';
import Pagination from '@/containers/pages/Scheduler/conponents/Pagination';

const TaskTime = ({ taskTimes, currentPage, pageCount, onPageChange }) => {

  const STATUSES = {
    "SENT_TO_EXECUTE": "#007bff",
    "IN_PROGRESS": "#ffc107",
    "ERROR": "#dc3545",
    "SUCCESS": "#28a745"
  }

  return (
    <React.Fragment>
      <Table>
        <thead>
        <tr>
          <TableHeader width="50px">ID</TableHeader>
          <TableHeader width="200px">Статус</TableHeader>
          <TableHeader>Начало</TableHeader>
          <TableHeader>Конец</TableHeader>
          <TableHeader>Описание</TableHeader>
        </tr>
        </thead>
        <tbody>
        {taskTimes.map((taskTime) => (
          <tr key={taskTime.id}>
            <TableCell>{taskTime.id}</TableCell>
            <TableCell status={STATUSES[taskTime.status] || ''} isStatus={true}>{taskTime.status}</TableCell>
            <TableCell>{new Date(taskTime.taskStart).toLocaleString()}</TableCell>
            <TableCell>{taskTime.taskEnd ? new Date(taskTime.taskEnd).toLocaleString() : 'Не завершено'}</TableCell>
            <TableCell>{taskTime.description || 'Нет описания'}</TableCell>
          </tr>
        ))}
        </tbody>
      </Table>
      <Pagination
        currentPage={currentPage}
        pageCount={pageCount}
        onPageChange={onPageChange}
      />
    </React.Fragment>
   )
}

export default TaskTime