import React, { useCallback } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import CollapsedInfoBlock from '@/components/blocks/CollapsedInfoBlock'
import EventsIcons from '@/components/blocks/EventsIcons'
import AlarmForm from '@/forms/AlarmForm'
import Users from '@/components/icons/users'
import { fields } from '@/constants/tablesConfig/alarmManager'
import {
  FormWrapper,
  EventPreview,
  EventPreviewHeader,
  CloseIconContainer,
  Title,
  AlarmElementPropertyContainer,
  AlarmElementTitleContainer,
  EventPreviewContainer,
  HeaderTitle, ButtonWrapper,
} from './styles'
import Button from '@/components/blocks/Button';
import { useHistory } from 'react-router-dom';
import { OBJECTS_URL } from '@/constants/routes';
import { OBJECT_ELEMENT } from '@/constants/objectTypes';

const AlarmSideBar = ({
  selectedNodeName,
  selectedElement,
  setSelectedElement,
  aliasId,
  isOpen,
  toggleIsOpen,
  eventsCount,
  eventData,
  isTableLoaded,
  dates,
  eventTypes,
  loadingStatistic,
  eventsOptions,
  setEventTypes,
  setNode,
  card,
}) => {
  const history = useHistory()
  const closeSelectedElementHandler = () => {
    setSelectedElement(null)
  }

  const toggleIsOpenHandler = () => {
    toggleIsOpen(!isOpen)
  }

  const renderValue = useCallback(({ id, value }) => {
    switch (id) {
      case 'alertState': return (<EventsIcons type={value} description />)
      case 'decisionDate': return (<>{value || 'Не решено'}</>)
      default: return (<>{value}</>)
    }
  }, [])

  const goToMaps = useCallback(() => {
    if (selectedElement.installationId === card.id) {
      const cardCopy = card
      cardCopy.original = {}
      cardCopy.original.options = {}
      cardCopy.original.options.path = `/${card.path.join('/')}/${card.id}`
      cardCopy.type = card.installationType
      cardCopy.system = card.integrationType
      cardCopy.treeNodeType = OBJECT_ELEMENT
      setNode(cardCopy)
      setTimeout(() => {
        history.push(OBJECTS_URL)
      }, )
    }
  }, [selectedElement, history, setNode, card])

  const selectedEventElementOptions = () => {
    const selectedElementKeys = Object.keys(selectedElement)
    if (selectedElementKeys.length > 0 && typeof selectedElement.id !== 'number') {
      closeSelectedElementHandler(null)
      return null
    }
    const formattedOptions = selectedElementKeys.reduce((accumulator, key) => {
      const operatedElement = fields.filter((element) => element.id === key)[0]
      if (!operatedElement) {
        return accumulator
      }
      return [
        ...accumulator,
        {
          ...operatedElement,
          value: selectedElement[key],
        },
      ]
    }, [])
    return (
      <>
        {formattedOptions.map((element) => {
          const { id, name } = element
          return (
            <AlarmElementPropertyContainer key={id}>
              <AlarmElementTitleContainer>
                <Title>
                  {name}
                </Title>
              </AlarmElementTitleContainer>
              <Title>
                {renderValue(element)}
              </Title>
            </AlarmElementPropertyContainer>
          )
        })}
        <ButtonWrapper>
          <Button onClick={goToMaps}>
            Посмотреть телеметрию
          </Button>
        </ButtonWrapper>
      </>
    )
  }

  const renderForm = () => (
    <FormWrapper>
      <AlarmForm
        aliasId={aliasId}
        eventCount={eventsCount}
        eventData={eventData}
        isTableLoaded={isTableLoaded}
        initialValues={dates}
        loadingStatistic={loadingStatistic}
        eventsOptions={eventsOptions}
        eventTypes={eventTypes}
        setEventTypes={setEventTypes}
      />
    </FormWrapper>
  )

  return (
    <>
      {!selectedElement
        ? (
          <CollapsedInfoBlock
            name={selectedNodeName}
            isSettingsOpen={isOpen}
            setIsSettingsOpen={toggleIsOpenHandler}
            content={renderForm()}
          />
        )
        : (
          <EventPreview>
            <EventPreviewHeader>
              <HeaderTitle>
                {selectedElement.object}
              </HeaderTitle>
              <CloseIconContainer onClick={closeSelectedElementHandler}>
                <Users.CrossIcon />
              </CloseIconContainer>
            </EventPreviewHeader>
            <EventPreviewContainer>
              {selectedEventElementOptions()}
            </EventPreviewContainer>
          </EventPreview>
        )}
    </>

  )
}

AlarmSideBar.defaultProps = {
  eventsCount: 0,
  eventData: [],
  selectedElement: null,
  selectedNodeName: '',
  isOpen: true,
  aliasId: null,
  toggleIsOpen: noop,
  requestAlarmManagerTable: noop,
  setSelectedElement: noop,
  isTableLoaded: false,
  dates: {},
  card: {},
}

AlarmSideBar.propTypes = {
  eventsCount: pt.number,
  eventData: pt.arrayOf(pt.object),
  selectedElement: pt.objectOf(pt.object),
  selectedNodeName: pt.string,
  aliasId: pt.string,
  isOpen: pt.bool,
  toggleIsOpen: pt.func,
  requestAlarmManagerTable: pt.func,
  setSelectedElement: pt.func,
  setNode: pt.func,
  isTableLoaded: pt.bool,
  dates: pt.objectOf(pt.string),
  card: pt.objectOf(pt.string),
}

export default AlarmSideBar
