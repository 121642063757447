import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 48px;
  border-top: 1px solid ${({ theme }) => theme.colors.borders.default};
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: ${({ theme }) => theme.colors.backgrounds.redisign.default};
`

export const ItemContainer = styled.div`
  min-width: 45px;
  height: 28px;
  border-radius: 4px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.colors.default};
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  ${({ isActive, theme }) => isActive && `
    color: ${theme.colors.backgrounds.default};
    background: ${theme.colors.scheme.backgrounds.defaultGradient};
    box-shadow: ${theme.colors.scheme.shadows.default};
    .svgFill {
      fill: ${theme.colors.backgrounds.default};
    }
  `}
  ${({ disabled }) => disabled && 'pointer-events: none;'}
`
export const SyncContainer = styled.div`
  width: 48px;
  height: 48px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.colors.default};
  display: flex;
  align-items: center;
  justify-content: center;
`
export const Info = styled.div`
  width: 48px;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
  padding-right: 15px;
`

export const SelectContainer = styled.div`
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 15px;
  padding-right: 15px;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;
`
export const Title = styled.p`
  ${({ selectControl }) => selectControl && `
    margin-right: 16px;
  `}
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2rem;

`
