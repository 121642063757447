import {
  takeLatest, select, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { generateSortedUsers } from '@/constants/apiRoutes'
import {
  getSelectedEntity,
  getUserSortBy,
  getUserSortOrder,
} from '@/store/selectors/users'
import { getAllSortedUsers, USERS_SORT } from '@/store/actions/users'
import { getUserData } from '@/store/selectors/appSettings'
import createNotifications from "@/helpers/notification";

function* getAllSortedUsersSaga({ payload }) {
  const { id, role } = payload
  const selectedEntity = yield select(getSelectedEntity)
  const currentUser = yield select(getUserData)
  const sortBy = yield select(getUserSortBy)
  const sortOrder = yield select(getUserSortOrder)
  const newId = id || selectedEntity.id
  try {
    const response = yield request({
      url: generateSortedUsers({
        currentRole: currentUser.role,
        entityId: newId,
        type: selectedEntity.type,
        params: {
          role,
          sortBy,
          sortOrder,
        },
      }),
      method: 'get',
    })
    yield put(getAllSortedUsers(response.data))
  } catch (error) {
    console.log('TCL: function*getAllSortedUsersSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось получить список пользователей для таблицы. \nПовторите попытку позже.',
      type: 'error',
    })
  }
  return null
}

export default function* root() {
  yield takeLatest(USERS_SORT, getAllSortedUsersSaga)
}
