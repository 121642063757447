import passportSHUO from './Паспорт ШУНО Unilight.pdf'
import passportLampModule from './Паспорт Модуль Unilight NEMA.pdf'
import passportGaleonS from './Паспорт на Галеон серии S.pdf'
import passportGaleonL from './Паспорт на Галеон серии L.pdf'

import { LIGHT_FIXTURE_GALEON_S, LIGHT_FIXTURE_GALEON_L, CONTROL_CUPBOARD, LAMP_MODULE } from '@/constants/instalationPassport/types'

export default  {
  [CONTROL_CUPBOARD]: passportSHUO,
  [LAMP_MODULE]: passportLampModule,
  [LIGHT_FIXTURE_GALEON_S]: passportGaleonS,
  [LIGHT_FIXTURE_GALEON_L]: passportGaleonL,
}