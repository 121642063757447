import React, {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import { treeHandlers } from 'react-hyper-tree'
import pt from 'prop-types'
import noop from 'lodash/noop'
import useHtmlTitle from '@/hooks/useHtmlTitle'
import {
  Main,
  Content,
  NavContainer,
  YandexMapContainer,
} from '@/containers/pages/ObjectsGroups/styles'
import GlobalPopup from '@/components/blocks/GlobalPopup'
import Loader from '@/components/blocks/Loader'
import {
  ACTION_TYPE,
  CREATE,
  PUT,
} from '@/constants/semanticNames'
import TextControl from '@/components/controls/TextControl'
import UsersIcons from '@/components/icons/users'
import PageSidebar from '@/components/regions/sidebars/PageSidebar'
import GlobalPopupContent from '@/components/blocks/GlobalPopupContent'
import MenuIcon from '@/components/icons/menu'
import CreateEditObjectsGroupForm from '@/forms/ObjectsGroups/CreateEditObjectsGroupForm'
import YandexMap from '@/components/blocks/YandexMap'
import { DEFAULT_CENTER } from '@/containers/pages/Installation/component'
import { MAP_SERVICE } from '@/constants/applications'
import ObjectsGroupDetails from '@/containers/pages/ObjectsGroups/components/ObjectsGroupDetails'
import REQUEST_STATUSES from '@/constants/requests'
import isEqual from 'lodash/isEqual'
import { GROUP } from '@/constants/instalationPassport/types'
import CREATE_OBJECTS_GROUP_NAMES from '@/constants/forms/createObjectsGroup'
import { PROJECT } from '@/constants/objectTypes'
import debounce from 'lodash/debounce'
import { DEBOUNCE_DELAY_MEDIUM } from '@/constants/time'

export const FILTERS_DEFAULT_VALUES = {
  street: [],
  registryNumber: [],
  objectType: [],
  vols: [],
  utilityPoleType: [],
  lightFixtureType: [],
  pedestrianCrossingSign: [],
  controlCupboard: [],
  networkCompany: [],
}

const ObjectsGroups = ({
  intl,
  globalLoading,
  globalFilters,
  treeData,
  treeStatus,
  createTreeData,
  createTreeStatus,
  selectedObjectsGroup,
  card,
  objectsGroupsFilterOptions,
  objectsGroupsFilterOptionsLoading,
  displayElements,
  displayElementsStatus,
  createDisplayElements,
  createDisplayElementsStatus,
  userApplications,
  userData,
  searchTreeData,

  requestGetTree,
  setNode,
  requestGetObjectsGroup,
  requestCreateObjectsGroup,
  requestEditObjectsGroup,
  requestDeleteObjectsGroup,
  requestGetObjectsGroupsFilterOptions,
  requestGetCreateMapObjects,
  setMapCenter,
  requestGetCreateTree,
  onToggleTree,
}) => {
  useHtmlTitle(intl.messages['menu.objectsGroups'])
  const yandexKey = userApplications.filter(item => item.code === MAP_SERVICE)[0]?.settings
  const [mapZoom, setZoom] = useState(4)
  const formRef = useRef(null)
  const checkedNodesRef = useRef([])
  const [formValues, setFormValues] = useState({})
  const [search, setSearch] = useState('')
  const [searchQuery, setSearchQuery] = useState('')
  const [selectedColor, setSelectedColor] = useState('')
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [checkedNodes, setCheckedNodes] = useState([])
  const [selectedNodes, setSelectedNodes] = useState([])
  const [filters, setFilters] = useState(FILTERS_DEFAULT_VALUES)
  const [passportTabOpen, setPassportTabOpen] = useState(false)

  const onChangeSearch = useCallback(debounce((value) => {
    setSearchQuery(value)
  }, DEBOUNCE_DELAY_MEDIUM), [])

  const handleSearchChange = useCallback((value) => {
    setSearch(value)
    onChangeSearch(value)
  }, [onChangeSearch])

  const resetSearch = useCallback(() => {
    handleSearchChange('')
  }, [handleSearchChange])

  const handleGetColor = (value) => {
    setSelectedColor(value)
  }

  const handleFiltersChange = useCallback((value, name) => {
    setFilters({
      ...filters,
      [name]: value
    })
  }, [filters])

  const handleFiltersReset = useCallback(() => {
    setFilters(FILTERS_DEFAULT_VALUES)
  }, [])

  const handleSetPopup = useCallback((value) => () => {
    setIsPopupOpen(value)
  }, [])

  const openTreeSelectedNode = useCallback(() => {
    if (treeData.length && selectedObjectsGroup && selectedObjectsGroup.id) {
      const tree = treeHandlers.trees['objects-groups-tree']
      setTimeout(() => {
        tree.handlers.setSelected(selectedObjectsGroup)
      })
    }
  }, [treeData, selectedObjectsGroup])

  useEffect(() => {
    if (!searchQuery.length || searchQuery.length >= 2) {
      requestGetTree({ searchQuery })
    }
  }, [requestGetTree, searchQuery])

  useEffect(() => {
    if (!!formValues[ACTION_TYPE]) {
      requestGetCreateTree({ filters, edit: formValues[ACTION_TYPE] === PUT })
      requestGetCreateMapObjects({ filters, edit: formValues[ACTION_TYPE] === PUT })
      if (isEqual(filters, FILTERS_DEFAULT_VALUES)) {
        requestGetObjectsGroupsFilterOptions()
      }
    }
  }, [requestGetCreateMapObjects, requestGetCreateTree, requestGetObjectsGroupsFilterOptions, filters, formValues])

  useEffect(() => {
    openTreeSelectedNode()
  }, [openTreeSelectedNode])

  const onTreeNodeSelect = useCallback((node, actionType) => {
    if (node.treeNodeType === GROUP && node.id !== selectedObjectsGroup.id && actionType === 'click') {
      setNode(node)
      setZoom(4)
      setMapCenter(DEFAULT_CENTER)
      setSelectedColor(node.color)
    }
  }, [setNode, selectedObjectsGroup, setMapCenter])

  const handleAddGroup = useCallback(() => {
    setFormValues({
      ...formValues,
      [ACTION_TYPE]: CREATE
    })
    setNode({})
    setSelectedColor('#b02020')
    setCheckedNodes([])
    setSelectedNodes([])
  }, [setFormValues, formValues, setNode])

  const handleCheckNode = useCallback((nodes, fromTree) => {
    const tree = treeHandlers.trees['objects-groups-form-tree']
    if (!nodes.length) {
      return
    }
    const parentNodes = []

    const checkParent = (node) => {
      const parentNode = tree.instance.getNodeById(node.data.parentId)
      if (parentNode.data.type === PROJECT) {
        return
      }
      if (parentNode.children.length) {
        const updatedNode = {
          ...parentNode.data,
          childrenChecked: parentNode.children.every(child => child.data.checked),
          checkedSupport: true,
        }
        tree.handlers.setNodeData(parentNode.data.id, updatedNode)
        parentNodes.push(updatedNode)
        checkParent(parentNode)
      }
    }

    if (!fromTree) {
      const node = tree.instance.getNodeById(nodes[0].id)
      if (!node) {
        setTimeout(() => {
          nodes.forEach(item => {
            tree.handlers.setNodeData(item.id, {
              ...item,
              checked: item.checked,
              childrenChecked: false,
              color: ''
            })
          })

          const parent = tree.instance.getNodeById(nodes[0].parentId)
          if (parent) {
            checkParent(parent)

            const updatedNodes = [...nodes, ...parentNodes, parent.data]

            const formattedNodes = [
              ...updatedNodes.filter(item => item.checked || item.checkedSupport),
              ...checkedNodesRef.current.filter(node => !updatedNodes.some(item => item.id === node.id)),
            ]
            formRef.current.setFieldValue([CREATE_OBJECTS_GROUP_NAMES.OBJECTS], formattedNodes)
            checkedNodesRef.current = formattedNodes
          }
        }, 2000)

        return
      } else {
        nodes.forEach(item => {
          tree.handlers.setNodeData(item.id, {
            ...item,
            checked: item.checked,
            childrenChecked: node && node.children.length ? node.children.every(child => child.data.checked) : false,
            color: ''
          })
        })
        const parent = tree.instance.getNodeById(nodes[0].parentId)
        tree.handlers.setNodeData(parent.data.id, {
          ...parent.data,
          childrenChecked: parent.children.every(child => child.data.checked),
          checkedSupport: true,
        })

        checkParent(parent)

        const updatedNodes = [...nodes, ...parentNodes, parent.data]

        const formattedNodes = [
          ...updatedNodes.filter(item => item.checked || item.checkedSupport),
          ...checkedNodesRef.current.filter(node => !updatedNodes.some(item => item.id === node.id)),
        ]
        formRef.current.setFieldValue([CREATE_OBJECTS_GROUP_NAMES.OBJECTS], formattedNodes)
        checkedNodesRef.current = formattedNodes
        setSelectedNodes([...selectedNodes, ...nodes])
      }

      return
    }

    const parent = tree.instance.getNodeById(nodes[0].parentId)
    tree.handlers.setNodeData(parent.data.id, {
      ...parent.data,
      childrenChecked: parent.children.every(child => child.data.checked),
      checkedSupport: true,
    })

    checkParent(parent)

    const updatedNodes = [...nodes, ...parentNodes, parent.data]

    const formattedNodes = [
      ...updatedNodes.filter(item => item.checked || item.checkedSupport),
      ...checkedNodesRef.current.filter(node => !updatedNodes.some(item => item.id === node.id)),
    ]
    const filteredFormattedNodes = formattedNodes.every(item => item.checkedSupport) ? [] : formattedNodes
    formRef.current.setFieldValue([CREATE_OBJECTS_GROUP_NAMES.OBJECTS], filteredFormattedNodes)
    checkedNodesRef.current = filteredFormattedNodes
    setSelectedNodes(nodes)
  }, [])

  const renderCreateEditObjectsGroupsForm = useCallback(() => {
    let onSubmitLocal = noop
    let onSubmitErrorLocal = noop
    let localSelectedObjectsGroup = {}
    let title = ''
    let edit = false
    let onCancel = noop
    if (formValues[ACTION_TYPE] === CREATE) {
      localSelectedObjectsGroup = {}
      onSubmitErrorLocal = handleSetPopup(true)
      onSubmitLocal = requestCreateObjectsGroup
      onCancel = () => {
        setFormValues({})
        setZoom(4)
        setMapCenter(DEFAULT_CENTER)
        setCheckedNodes([])
        handleFiltersReset()
        checkedNodesRef.current = []
      }
      title = <Lang id={'objectsGroups.titles.create'}/>
    }
    if (formValues[ACTION_TYPE] === PUT) {
      edit = true
      localSelectedObjectsGroup = card
      onSubmitErrorLocal = handleSetPopup(true)
      onSubmitLocal = requestEditObjectsGroup
      onCancel = () => {
        edit = false
        setFormValues({})
        setNode()
        setZoom(4)
        setMapCenter(DEFAULT_CENTER)
        setCheckedNodes([])
        handleFiltersReset()
        checkedNodesRef.current = []
      }
      title = <Lang id={'objectsGroups.titles.edit'}/>
    }
    return (
        <NavContainer br>
          <CreateEditObjectsGroupForm
            ref={formRef}
            checkedNodesRef={checkedNodesRef}
            selectedObjectsGroup={localSelectedObjectsGroup}
            submitError={onSubmitErrorLocal}
            setFormValues={setFormValues}
            onCancel={onCancel}
            submit={onSubmitLocal}
            title={title}
            formValues={formValues}
            edit={edit}
            intl={intl}
            objectsGroupsFilterOptions={objectsGroupsFilterOptions}
            objectsGroupsFilterOptionsLoading={objectsGroupsFilterOptionsLoading}
            handleGetColor={handleGetColor}
            checkedNodes={checkedNodes}
            handleCheckNode={handleCheckNode}
            handleFiltersChange={handleFiltersChange}
            handleFiltersReset={handleFiltersReset}
            filters={filters}
            createTreeData={createTreeData}
            createTreeStatus={createTreeStatus}
            setNode={setNode}
            setFilters={setFilters}
            searchTreeData={searchTreeData}
          />
          {isPopupOpen && (
            <GlobalPopup
              content={(
                <GlobalPopupContent
                  type={'warning'}
                  onClose={handleSetPopup(false)}
                  title={<Lang id={'objectsGroups.popup.executeImpossible'}/>}
                  message={edit ? <Lang id={'objectsGroups.popup.createObjectsGroupValidationMessage'}/> : <Lang id={'objectsGroups.popup.createObjectsGroupValidationMessage'}/>}
                  config={{
                    warning: {
                      icon: MenuIcon.AttentionIcon,
                      buttons: [
                        {
                         statusType: 'warning',
                         onClickSelector: handleSetPopup(false),
                         title: <Lang id={'objectsGroups.popup.accept'}/>,
                        },
                      ],
                    },
                  }}
                />
              )}
            />
          )}
        </NavContainer>
    )
  }, [
    formValues,
    setFormValues,
    isPopupOpen,
    handleSetPopup,
    requestCreateObjectsGroup,
    requestEditObjectsGroup,
    card,
    intl,
    objectsGroupsFilterOptions,
    objectsGroupsFilterOptionsLoading,
    checkedNodes,
    handleCheckNode,
    createTreeData,
    createTreeStatus,
    filters,
    handleFiltersReset,
    handleFiltersChange,
    setNode,
    setMapCenter,
    searchTreeData
  ])

  let renderTree
  renderTree = useCallback(() => {
    return (
      <>
        <PageSidebar
          title={<Lang id="menu.objectsGroups"/>}
          treeId={'objects-groups-tree'}
          treeData={treeData}
          onSelect={onTreeNodeSelect}
          rootStatus={treeStatus}
          searchQuery={search}
          globalFilters={globalFilters}
          headerIcon={<UsersIcons.PlusIcon/>}
          headerIconDescription={<Lang id="objectsGroups.titles.add"/>}
          onHeaderIconClick={handleAddGroup}
          onToggleTree={onToggleTree}
          headerContent={(
            <Lang id="mapsPage.titles.search">
              {(placeholder) => (
                <TextControl
                  dark
                  placeholder={placeholder}
                  name="search"
                  icon={UsersIcons.MagnifierIcon}
                  onChange={handleSearchChange}
                  value={search}
                  resetButton
                  resetField={resetSearch}
                />
              )}
            </Lang>
          )}
        />
        {selectedObjectsGroup.id &&
          <ObjectsGroupDetails
            userData={userData}
            formValues={formValues}
            setFormValues={setFormValues}
            card={card}
            selectedObjectsGroup={selectedObjectsGroup}
            requestDeleteObjectsGroup={requestDeleteObjectsGroup}
            requestGetObjectsGroup={requestGetObjectsGroup}
            setNode={setNode}
            setCheckedNodes={setCheckedNodes}
            setSelectedColor={setSelectedColor}
            setPassportTabOpen={setPassportTabOpen}
          />
        }
      </>
    )
  }, [
    globalFilters,
    handleAddGroup,
    onTreeNodeSelect,
    search,
    treeStatus,
    selectedObjectsGroup,
    formValues,
    requestDeleteObjectsGroup,
    treeData,
    card,
    requestGetObjectsGroup,
    setNode,
    userData,
    handleSearchChange,
    onToggleTree,
    resetSearch
  ])

  const renderLeftPart = useCallback(() => (!!formValues[ACTION_TYPE]
    ? renderCreateEditObjectsGroupsForm()
    : renderTree()),
  [formValues, renderCreateEditObjectsGroupsForm, renderTree])

  const getPins = useCallback(() => {
    if (!!formValues[ACTION_TYPE]) {
      return createDisplayElements
    }
    if (selectedObjectsGroup.id) {
      return displayElements
    }
    return []
  }, [formValues, selectedObjectsGroup, displayElements, createDisplayElements])

  return (
    <Main>
      {globalLoading && (
        <GlobalPopup content={<Loader center />} />
      )}
      {renderLeftPart()}
      <Content>
        {displayElementsStatus === REQUEST_STATUSES.PENDING || createDisplayElementsStatus === REQUEST_STATUSES.PENDING
          ? <Loader center />
          : !passportTabOpen &&
            <YandexMapContainer>
             <YandexMap
               objectsGroups
               groupCreateEditMode={!!formValues[ACTION_TYPE]}
               pins={getPins()}
               mapCenter={DEFAULT_CENTER}
               updateMapCenter={setMapCenter}
               mapZoom={mapZoom}
               updateMapZoom={setZoom}
               mash={[]}
               yandexKey={yandexKey}
               color={selectedColor}
               selectedNodes={selectedNodes}
               checkedNodes={checkedNodesRef.current}
               handleCheckNode={handleCheckNode}
               isPassportization
             />
            </YandexMapContainer>
        }
      </Content>
    </Main>
  )
}

ObjectsGroups.propTypes = {
  data: pt.arrayOf(pt.object),
  globalLoading: pt.bool,
  globalFilters: pt.object,
  treeData: pt.array,
  treeStatus: pt.string,
  selectedObjectsGroup: pt.object,
  card: pt.object,

  requestGetTree: pt.func,
  setNode: pt.func,
  requestGetObjectsGroup: pt.func,
  requestCreateObjectsGroup: pt.func,
  requestEditObjectsGroup: pt.func,
  requestDeleteObjectsGroup: pt.func,
}

ObjectsGroups.defaultProps = {
  data: {},
  globalFilters: {},
  treeData: [],
  treeStatus: '',
  selectedObjectsGroup: {},
  card: {},

  requestGetTree: noop,
  setNode: noop,
  requestGetObjectsGroup: noop,
  requestCreateObjectsGroup: noop,
  requestEditObjectsGroup: noop,
  requestDeleteObjectsGroup: noop,
}

export default ObjectsGroups