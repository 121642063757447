import {
  put, takeLatest, call
} from 'redux-saga/effects'
import get from 'lodash/get'
import { successDeleteGeoZone } from '@/store/actions/installation/deleteGeoZone'
import {
  GEOZONE,
  ROOT,
} from '@/constants/objectTypes'
import getNewPath from '@/helpers/getNewPath'
import request from '@/helpers/axios'
import { DELETE_GEOZONE } from '@/store/actions/installation'
import { generateDeleteTreeElement } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { treeHandlers } from 'react-hyper-tree'
import { requestGetRootElements } from '@/store/actions/installation/getRootElemenet'
import {
  setGlobalLoading,
  setTrueStatusGlobalFilter
} from '@/store/actions/appSettings'

function* deleteGeoZoneSaga({ payload }) {
  const {
    setUrlFormValues, formValues, id, parentId
  } = payload
  const {
    path,
    rootId,
    FILTER,
  } = formValues

  try {
    yield put(setGlobalLoading(true))
    yield request({
      url: generateDeleteTreeElement(id),
      method: 'delete',
    })
    const newPath = getNewPath(path)
    const toast = createNotifications()

    const tree = treeHandlers.trees['installations-tree']
    const parent = tree.instance.getNodeById(parentId)
    if (parent) {
      const parentChildren = yield call(parent.data.getChildren, parent)
      tree.handlers.setRawChildren(parent, parentChildren, 'first', true)
      const selectedNodePath = get(parent, 'options.path', '')
      tree.handlers.setSelectedByPath(selectedNodePath)
      setUrlFormValues({
        id: parentId,
        rootId,
        parentGeoZoneId: parentId,
        parentTreeId: parentId,
        path: newPath,
        type: rootId === parentId ? ROOT : GEOZONE,
        FILTER,
      })
    } else {
      yield put(requestGetRootElements())
      setUrlFormValues({})
    }
    yield put(setGlobalLoading(false))
    toast({
      title: 'Удаление геозоны',
      type: 'success',
      description: 'Геозона успешно удалена.',
    })
    yield put(setTrueStatusGlobalFilter())
    yield put(successDeleteGeoZone())
  } catch (error) {
    yield put(setGlobalLoading(false))
    const toast = createNotifications()
    const errorMessage = get(error, 'response.data.errorMessage', null)
    if (errorMessage === 'Can not delete installation, because installation has attached asu') {
      toast({
        title: 'ОШИБКА ОПЕРАЦИИ!',
        type: 'error',
        description: 'Одна или несколько интеграций подключена к асу,\nперед удалением отключите их.',
      })
    } else {
      toast({
        title: 'Удаление геозоны',
        type: 'error',
        description: parentId ? 'Не удалось удалить геозону, обратитесь к администратору.' : 'Не удалось удалить корневую геозону, обратитесь к разработчикам платформы',
      })
    }
    setUrlFormValues({ FILTER })
    console.log('function*deleteGeoZoneSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(DELETE_GEOZONE.REQUEST, deleteGeoZoneSaga)
}
