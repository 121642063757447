import styled from 'styled-components'

export const IconContainer = styled.div`
  display: flex;
  position: relative;
  ${({ thermometer, theme, height, value }) => thermometer && `
    margin-right: 40px;
    &::after {
      content: '';
      position: absolute;
      bottom: 7px;
      left: 6px;
      width: 18px;
      height: 18px;
      border-radius: 18px;
      background: ${ value > 0 ? theme.colors.backgrounds.redisign.error : theme.colors.backgrounds.redisign.blueRoyal};
    }
    &::before {
      content: '';
      position: absolute;
      bottom: 23px;
      left: 12px;
      width: 6px;
      height: ${Math.abs(height)}px;
      min-height: 0px;
      max-height: 105px;
      border-radius: 100px;
      background: ${ value > 0 ? theme.colors.backgrounds.redisign.error : theme.colors.backgrounds.redisign.blueRoyal};
    }
  `}
  ${({ trash, theme, height, value, background }) => trash && `
    &::after {
      content: '';
      position: absolute;
      height: ${height}px;
      min-height: 0px;
      max-height: 107px;
      background: ${background};
      bottom: 6px;
      left: 12.5px;
      width: 80px;
      border-radius: 0 0 3px 3px;
    }
    &::before {
      content: '${value}%';
      position: absolute;
      bottom: 54px;
      left: 37px;
      font-weight: 500;
      color: ${theme.colors.colors.light};
      font-size: 1.8rem;
      z-index: 1;
      line-height: 2.1rem;
      letter-spacing: -0.003em;
    }
  `}
`

export const Text = styled.div`
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 2.1rem;
  display: flex;
  align-items: center;
  letter-spacing: -0.003em;
  margin-right: 10px;
`
