import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import {
  OUTDOOR_LIGHTNING,
  ROAD_REPAIRING,
  TRANSPORT_STATION
} from '@/constants/objectTypes'

const widgetConfig = [
  {
    id: OUTDOOR_LIGHTNING,
    title: <Lang id="widgets.light" />,
  },
  {
    id: ROAD_REPAIRING,
    title: <Lang id="widgets.roadRepairing" />,
  },
  {
    id: TRANSPORT_STATION,
    title: <Lang id="widgets.publicTransport" />,
  },
]

export const IN_PROGRESS_STATUSSES = ['Готовится ответ']
export const ERROR_STATUSSES = ['Мотивированный отказ', 'Проблема не обнаружена']
export const DONE_STATUSSES = ['Проблема решена', 'Запланировано']

export default widgetConfig
