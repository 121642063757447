import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'


import { HeaderPage, Title } from './styles'


const HeaderMobile = () => {
  return (
    <HeaderPage>
      <Title>
        <Lang id="serviceDeskMyCity.mainTitle" />
      </Title>
    </HeaderPage>

  )
}

HeaderMobile.propTypes = {
}
HeaderMobile.defaultProps = {
}

export default HeaderMobile
