import * as yup from 'yup'
import {
  PASSWORD,
  PASSWORD_CONFIRMATION,
} from '@/constants/forms/signUp'

export const PASSWORD_CHECK = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[.|,:;?!*+%-<>@[\]{}/_$#]).{8,}/

export const signUpValidation = yup
  .object()
  .shape({
    [PASSWORD]: yup
      .string()
      .required('validation.enterPassword')
      .min(8, 'validation.passwordMinLength')
      .matches(
        PASSWORD_CHECK,
        'validation.passwordNotValid',
      ),
    [PASSWORD_CONFIRMATION]: yup
      .string()
      .required('validation.enterPassword')
      .min(8, 'validation.passwordMinLength')
      .oneOf([yup.ref(PASSWORD), null], 'validation.passwordsNotMatch'),
  })
