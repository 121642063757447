import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const FileExport = ({ color, onClick }) => {

  return (
    <svg onClick={onClick} width="24" height="24" viewBox="0 0 24 24"  fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14 2.00098H6C4.9 2.00098 4 2.90098 4 4.00098V20.001C4 21.101 4.9 22.001 6 22.001H18C19.1 22.001 20 21.101 20 20.001V8.00098L14 2.00098ZM18 20.001H6V4.00098H13V9.00098H18V20.001ZM16 11.001V18.101L13.9 16.001L11.1 18.801L8.3 16.001L11.1 13.201L8.9 11.001H16Z" fill="#3E3C3E"/>
    </svg>
  )
}

FileExport.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

FileExport.defaultProps = {
  color: '#1E2127',
  onClick: noop,
}

export default memoWithName(FileExport)
