import {
  takeLatest, put
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  CHANGE_REPORT_MANAGER,
  setFormIsModifying,
} from '@/store/actions/reportManager'
import { GET_REPORT_URL } from '@/constants/apiRoutes'
import {
  errorChangeReport,
  successChangeReport,
} from '@/store/actions/reportManager/changeReport'
import {
  requestReportManagerTable,
} from '@/store/actions/reportManager/getTable'
import createNotifications from '@/helpers/notification'

function* changeReportSaga({ payload }) {
  const toast = createNotifications()
  try {
    const dataJson = {
      externalRecipients: JSON.stringify(payload.externalRecipients),
      schedule: JSON.stringify(payload.schedule),
      ...payload,
    }
    const response = yield request({
      url: GET_REPORT_URL({ id: payload.id }),
      method: 'put',
      body: dataJson,
    })
    if (response.data) {
      yield put(setFormIsModifying(false))
      yield put(requestReportManagerTable())
      yield put(successChangeReport())
      toast({
        title: 'Успешная операция!',
        description: `Операция выполнена. Отчет для рассылки “${payload.title}” успешно отредактирован`,
      })
    }
  } catch (error) {
    const { details } = error.response.data
    details.forEach((detail) => {
      const [inWhatError] = detail.subject.split(':')
      if (inWhatError === 'report_title') {
        toast({
          title: 'Ошибка операции!',
          description: 'Отчет с таким названием уже существует в системе',
          type: 'error',
        })
      } else {
        toast({
          title: 'Ошибка операции!',
          type: 'error',
          description: 'Невозможно изменить отчет!\nПовторите попытку позже.',
        })
      }
    }, {})
    if (details.length === 0) {
      toast({
        title: 'Ошибка операции!',
        type: 'error',
        description: 'Невозможно изменить отчет!\nПовторите попытку позже.',
      })
    }
    yield put(errorChangeReport(error))
  }
  return null
}

export default function* root() {
  yield takeLatest(CHANGE_REPORT_MANAGER.REQUEST, changeReportSaga)
}
