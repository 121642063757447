import React, { useCallback, useEffect, useMemo, useState } from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang, injectIntl } from 'react-intl'
import noop from 'lodash/noop'
import get from 'lodash/get'
import moment from 'moment/moment'
import CanvasJSReact from '@canvasjs/react-charts'
import CoreIcons from '@/components/icons/core'
import SyncIcon from '@/components/icons/sync'
import Loader from '@/components/blocks/Loader'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import config from './config'
import {
  Container,
  Header,
  IconContainer,
  InfoBlock,
  Title,
  Row,
  SecondaryTitle,
  LoaderContainer,
  ContentContainer,
  DownloadIconContainer,
  IconWrapper,
  TaskInfoBlock,
  ActionWrapper,
  ActionRow,
  ChartContainer
} from './styles'
import UsersIcons from '@/components/icons/users'
import {
  ACTION_TYPE,
  PUT
} from '@/constants/semanticNames'
import SelectControl from '@/components/controls/SelectControl'
import GlobalPopup from '@/components/blocks/GlobalPopup'
import GlobalPopupContent from '@/components/blocks/GlobalPopupContent'
import MenuIcon from '@/components/icons/menu'
import { DEVICE_TYPES, PHASES } from '@/forms/ScheduleManager/CreateEditScheduleForm/config'
import { TIMEZONE_CONFIG } from '@/forms/ReportManagerForm/config'
import { getScheduleChartOptions } from '@/constants/graph/scheduleManager'
import _ from 'lodash'
import { ChartTitle, ChartWrapper } from '@/forms/ScheduleManager/CreateEditTaskForm/styles'
import { GlobalPopupMessage } from '@/forms/ScheduleManager/CreateEditScheduleForm/styles'
import CREATE_SCHEDULE_NAMES from '@/constants/forms/createSchedule'
import TextAreaControl from '@/components/controls/TextAreaControl'

const ScheduleDetails = ({
  selectedSchedule,
  formValues,
  setFormValues,
  requestDeleteSchedule,
  intl
}) => {
  const { fieldsSHUNO, fieldsRM, taskFields, actionFields } = config
  const isLight = selectedSchedule.deviceType === 'RM'
  const fields = isLight ? fieldsRM : fieldsSHUNO
  const [selectedTask, setSelectedTask] = useState([])
  const [isDeletePopupOpen, setIsDeletePopupOpen] = useState(false)
  const [isJustificationPopupOpen, setIsJustificationPopupOpen] = useState(false)
  const [firstChartData, setFirstChartData] = useState([])
  const [secondChartData, setSecondChartData] = useState([])
  const [justificationComment, setJustificationComment] = useState('')

  const handleSetDeletePopup = useCallback((value) => () => {
    setIsDeletePopupOpen(value)
  }, [])

  const handleSetJustificationPopup = useCallback((value) => () => {
    setIsJustificationPopupOpen(value)
  }, [])

  const handleJustificationCommentChange = useCallback((value) => {
    setJustificationComment(value)
  }, [])

  const onDelete = useCallback(() => {
    handleSetJustificationPopup(false)
    requestDeleteSchedule({...selectedSchedule, justificationComment: justificationComment})
  }, [requestDeleteSchedule, selectedSchedule, handleSetJustificationPopup, justificationComment])

  const onDownload = useCallback(() => {
    console.log('download')
  }, [])

  const onSelectTask = useCallback((value) => {
    setSelectedTask(value)
    const task = selectedSchedule.tasks.filter(task => task.value === value)[0]
    const chartData = task.actions.map(action => {
      if (action.hours === '00' && action.minutes === '00' && action.canDelete) {
        return {
          time: '24:00',
          percent: Number(action.percent),
        }
      }
      return {
        time: `${action.hours}:${action.minutes}`,
        percent: Number(action.percent)
      }
    })
    setFirstChartData(chartData)
    setSecondChartData(chartData)
  }, [setSelectedTask, selectedSchedule])

  useEffect(() => {
    if (selectedSchedule && selectedSchedule.tasks) {
      onSelectTask(selectedSchedule.tasks[0].title)
    } else {
      setSelectedTask([])
      setFirstChartData([])
      setSecondChartData([])
    }
  }, [selectedSchedule, onSelectTask])

  const onEdit = useCallback(() => {
    setFormValues({
      ...formValues,
      [ACTION_TYPE]: PUT,
      editMode: true
    })
  }, [formValues, setFormValues])

  const returnName = (name) => {
    const limit = 22

    return (
      <>
        {((name || '').length > limit)
          ? (
             <PortalTooltip
               title={name}
               renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                 <Title
                   ref={wrapperRef}
                   onMouseEnter={onMouseEnterHandler}
                   onMouseLeave={onMouseLeaveHandler}
                 >
                  {(name || '').substr(0, limit)}
                    ...
                 </Title>
               )}
             />
            )
          : (
             <Title>
               {name}
             </Title>
            )}
        </>
      )
    }

  const isDisplayLoader = useMemo(() => !selectedSchedule.name, [selectedSchedule.name])

  const selectedTaskDetails = useMemo(() => {
    if (!selectedSchedule.tasks) {
      return
    }

    return selectedSchedule.tasks.filter(task => task.value === selectedTask)[0]
  }
  , [selectedSchedule, selectedTask])

  const CanvasJSChart = CanvasJSReact.CanvasJSChart
  const firstChartOptions= getScheduleChartOptions(isLight, firstChartData, intl)
  const secondChartOptions= getScheduleChartOptions(isLight, secondChartData, intl, true)
  const areEqual = _.isEqual(firstChartOptions.data, secondChartOptions.data)
  const isEndDateLaterThanStartDate = new Date(selectedTaskDetails?.toDate) > new Date (selectedTaskDetails?.fromDate)

  return (
    <>
      <Container>
        {isDisplayLoader
          ? (
             <LoaderContainer>
               <Loader />
             </LoaderContainer>
            )
            : (
                <>
                  <Header>
                    <PortalTooltip
                      title={<Lang id="scheduleManager.tooltip.edit" />}
                      handleIconClick={() => {}}
                      renderChildren={(
                        wrapperRef,
                        onMouseEnterHandler,
                        onMouseLeaveHandler,
                        onClickHandler
                      ) => (
                        <IconWrapper
                          onClick={onClickHandler}
                          ref={wrapperRef}
                          onMouseEnter={onMouseEnterHandler}
                          onMouseLeave={onMouseLeaveHandler}
                        >
                          <IconContainer pr={10} onClick={onEdit}>
                            <CoreIcons.EditIcon />
                          </IconContainer>
                        </IconWrapper>
                      )}
                    />
                    {returnName(selectedSchedule.name)}
                    <PortalTooltip
                      title={<Lang id="scheduleManager.tooltip.download" />}
                      handleIconClick={() => {}}
                      renderChildren={(
                        wrapperRef,
                        onMouseEnterHandler,
                        onMouseLeaveHandler,
                        onClickHandler
                      ) => (
                        <IconWrapper
                          onClick={onClickHandler}
                          ref={wrapperRef}
                          onMouseEnter={onMouseEnterHandler}
                          onMouseLeave={onMouseLeaveHandler}
                        >
                          <DownloadIconContainer onClick={onDownload} disabled>
                            <UsersIcons.UploadIcon/>
                          </DownloadIconContainer>
                        </IconWrapper>
                        )}
                    />
                    <PortalTooltip
                      title={<Lang id="scheduleManager.tooltip.delete" />}
                      handleIconClick={() => {}}
                      renderChildren={(
                        wrapperRef,
                        onMouseEnterHandler,
                        onMouseLeaveHandler,
                        onClickHandler
                      ) => (
                        <IconWrapper
                          onClick={onClickHandler}
                          ref={wrapperRef}
                          onMouseEnter={onMouseEnterHandler}
                          onMouseLeave={onMouseLeaveHandler}
                        >
                          <IconContainer onClick={handleSetDeletePopup(true)}>
                            <SyncIcon.TrashCanIcon />
                          </IconContainer>
                        </IconWrapper>
                      )}
                    />
                  </Header>
                  <ContentContainer>
                    {fields.length !== 0 && (
                      <InfoBlock>
                        {fields.map(({ selector }) => {
                          const fieldValue = get(selectedSchedule, selector, null)
                          const getValue = (selector) => {
                            switch(selector) {
                              case 'deviceType': {
                                return DEVICE_TYPES.filter(item => item.value === fieldValue)[0].title
                              }
                              case 'phase': {
                                return PHASES.filter(phase => fieldValue?.includes(phase.value)).map(item => item.title)
                              }
                              case 'timezone': {
                                return TIMEZONE_CONFIG.filter(item => item.value === fieldValue)[0].title
                              }
                              case 'scheduleWorkingHours': {
                                return `${Math.floor(moment.duration(fieldValue).asHours()) || 0} ${intl.messages[`widgets.hour`]} ${moment.duration(fieldValue).minutes() || 0} ${intl.messages[`widgets.minutes`]}`
                              }
                              default: {
                                return fieldValue
                              }
                            }
                          }
                          const value = getValue(selector)
                          return (
                            <Row stack key={`${selector}-${value}`}>
                              <SecondaryTitle>
                                <Lang id={`scheduleManager.form.${selector}`} />
                                :
                              </SecondaryTitle>
                              <SecondaryTitle>
                                {value || '...'}
                              </SecondaryTitle>
                            </Row>
                          )
                        })}
                      </InfoBlock>
                    )}
                    <TaskInfoBlock>
                      <Row stack>
                        <SecondaryTitle>
                          <Lang id={`scheduleManager.form.task`} />:
                        </SecondaryTitle>
                        <SecondaryTitle>
                          <SelectControl
                            onChange={onSelectTask}
                            value={selectedTask}
                            options={selectedSchedule.tasks}
                          />
                        </SecondaryTitle>
                      </Row>
                      {taskFields.map(({ selector }) => {
                        const fieldValue = get(selectedTaskDetails, selector, null)
                        const getValue = (selector) => {
                          switch(selector) {
                            case 'workingHours': {
                              return `${Math.floor(moment.duration(fieldValue).asHours()) || 0} ${intl.messages[`widgets.hour`]} ${moment.duration(fieldValue).minutes() || 0} ${intl.messages[`widgets.minutes`]}`
                            }
                            case 'dailyWorkingHours': {
                              return `${Math.floor(moment.duration(fieldValue).asHours()) || 0} ${intl.messages[`widgets.hour`]} ${moment.duration(fieldValue).minutes() || 0} ${intl.messages[`widgets.minutes`]}`
                            }
                            default: {
                              return fieldValue
                            }
                          }
                        }
                        const value = getValue(selector)
                        return (
                          <Row stack key={`${selector}-${value}`}>
                           <SecondaryTitle>
                             <Lang id={`scheduleManager.form.${selector}`} />:
                           </SecondaryTitle>
                           <SecondaryTitle>
                             {value || '...'}
                           </SecondaryTitle>
                          </Row>
                        )
                      })}
                      <Row stack>
                        <SecondaryTitle>
                          <Lang id={`scheduleManager.form.actions`} />:
                        </SecondaryTitle>
                      </Row>
                      {selectedTaskDetails?.actions.map((action) => {
                        return (
                          <ActionWrapper>
                            {actionFields.map(({ selector }) => {
                              const value = get(action, selector, null)
                              return (
                                <ActionRow key={`${selector}-${value}`}>
                                  {selector === 'percent'
                                    ? selectedSchedule.deviceType === 'SHUNO'
                                      ? <>
                                          <SecondaryTitle>
                                            <Lang id={`scheduleManager.form.condition`} />
                                          </SecondaryTitle>
                                          <SecondaryTitle>
                                            {value ? <Lang id={`scheduleManager.form.on`} /> : <Lang id={`scheduleManager.form.off`} />}
                                          </SecondaryTitle>
                                        </>
                                      : <>
                                          <SecondaryTitle>
                                            <Lang id={`scheduleManager.form.${selector}`} />
                                          </SecondaryTitle>
                                          <SecondaryTitle>
                                            {value}
                                          </SecondaryTitle>
                                        </>
                                    : <>
                                        <SecondaryTitle>
                                          <Lang id={`scheduleManager.form.${selector}`} />
                                        </SecondaryTitle>
                                        <SecondaryTitle>
                                          {value}
                                        </SecondaryTitle>
                                      </>
                                  }
                                </ActionRow>
                              )
                            })}
                          </ActionWrapper>
                        )})}
                    </TaskInfoBlock>
                  </ContentContainer>
                  {isDeletePopupOpen && (
                    <GlobalPopup
                      content={(
                        <GlobalPopupContent
                          type={'error'}
                          onClose={handleSetDeletePopup(false)}
                          title={<Lang id={'scheduleManager.popup.attention'}/>}
                          message={`Вы действительно хотите удалить расписание "${selectedSchedule.name}" из системы?`}
                          config={{
                            error: {
                              icon: MenuIcon.TreshIcon,
                              buttons: [
                                {
                                  statusType: 'flat',
                                  onClickSelector: handleSetDeletePopup(false),
                                  title: <Lang id="scheduleManager.popup.no" />,
                                },
                                {
                                  statusType: 'error',
                                  onClickSelector: () => {
                                    setIsDeletePopupOpen(false)
                                    setIsJustificationPopupOpen(true)
                                  },
                                  title: <Lang id="scheduleManager.popup.yes" />,
                                },
                              ],
                            },
                          }}
                        />
                      )}
                    />
                  )}
                  {isJustificationPopupOpen && (
                      <GlobalPopup
                          content={(
                              <GlobalPopupContent
                                  type={'warning'}
                                  onClose={handleSetJustificationPopup(false)}
                                  title={<Lang id={'scheduleManager.popup.deleteSchedule'}/>}
                                  message={
                                    <GlobalPopupMessage>
                                      <Lang id={'scheduleManager.popup.deleteScheduleMessage'}/>
                                      <TextAreaControl
                                        name={CREATE_SCHEDULE_NAMES.JUSTIFICATION_COMMENT}
                                        rows="3"
                                        charLimit={100}
                                        onAfterChange={(value) => handleJustificationCommentChange(value)}
                                        placeholder={intl.messages['scheduleManager.form.commentText']}
                                      />
                                    </GlobalPopupMessage>
                                  }
                                  config={{
                                    warning: {
                                      icon: MenuIcon.AttentionIcon,
                                      buttons: [
                                        {
                                          statusType: 'flat',
                                          onClickSelector: handleSetJustificationPopup(false),
                                          title: <Lang id="scheduleManager.popup.cancel" />,
                                        },
                                        {
                                          statusType: 'warning',
                                          onClickSelector: onDelete,
                                          disabled: !justificationComment.length,
                                          title: <Lang id="scheduleManager.popup.save" />,
                                        },
                                      ],
                                    },
                                  }}
                              />
                          )}
                      />
                  )}
                </>
            )
        }
      </Container>
      {!isDisplayLoader &&
        <ChartContainer>
          <ChartWrapper>
            <ChartTitle>
              {areEqual ? <Lang id={'scheduleManager.graphTitle'}/> : <Lang id={'scheduleManager.firstGraphTitle'}/>}
            </ChartTitle>
            <CanvasJSChart options={firstChartOptions} />
            </ChartWrapper>
            {!areEqual && isEndDateLaterThanStartDate &&
              <ChartWrapper>
                <ChartTitle>
                  <Lang id={'scheduleManager.secondGraphTitle'}/>
                </ChartTitle>
                <CanvasJSChart options={secondChartOptions} />
              </ChartWrapper>
            }
          </ChartContainer>
      }
    </>
  )
}

ScheduleDetails.propTypes = {
  selectedSchedule: pt.objectOf(pt.any),
  formValues: pt.objectOf(pt.any),
  loggedInUser: pt.objectOf(pt.any),

  setFormValues: pt.func,
  setChartData: pt.func,
  requestDeleteSchedule: pt.func,
  setSelectedTreeNode: pt.func,
}
ScheduleDetails.defaultProps = {
  selectedSchedule: {},
  formValues: {},
  loggedInUser: {},

  setFormValues: noop,
  setChartData: noop,
  requestDeleteSchedule: noop,
  setSelectedTreeNode: noop,
}

export default injectIntl(ScheduleDetails)
