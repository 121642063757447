import { takeLatest, select, put } from 'redux-saga/effects'
import request from '@/helpers/axios'
import { FIELDS_SETTINGS_ENDPOINT } from '@/constants/apiRoutes'
import {
 SET_FIELDS_PASSPORT,
 successActiveFieldsPassport
} from '@/store/actions/passportization'
import { getActiveFieldsPassport } from '@/store/selectors/passportization'

import createNotifications from '@/helpers/notification'

function* setSettingsFields() {
  const toast = createNotifications()
  try {
    const activeFieldsPassport = yield select(getActiveFieldsPassport)

    const response = yield request({
      url: FIELDS_SETTINGS_ENDPOINT,
      method: 'put',
      body: JSON.stringify(activeFieldsPassport),
    })

    yield put(successActiveFieldsPassport(response.data))
    toast({
      title: 'Операция выполнена успешно!',
      description: `Настройки полей сохранены.`,
    })
  } catch (error) {
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось сохранить настройки паспорта.\nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(SET_FIELDS_PASSPORT.REQUEST, setSettingsFields)
}
