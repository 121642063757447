import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { SCHEDULE_MANAGER_CREATE_ENDPOINT } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { setGlobalLoading } from '@/store/actions/appSettings'
import {
  errorCreateSchedule,
  successCreateSchedule
} from '@/store/actions/scheduleManager/createSchedule'
import { CREATE_SCHEDULE } from '@/store/actions/scheduleManager'
import moment from 'moment/moment'
import { requestScheduleManagerTree } from '@/store/actions/scheduleManager/getTree'
import qs from 'query-string'

function* createScheduleSaga({ payload }) {
  const { formValues, values, geoZoneId, onCancel, justificationComment } = payload
  try {
    yield put(setGlobalLoading(true))
    const body = {
      geoZoneId: geoZoneId,
      comment: values.comment,
      equipmentType: values.deviceType,
      name: values.name,
      shunoPhases: values.deviceType === 'SHUNO' && values?.phase?.length ? values.phase : null,
      timeZone: values.timezone / 60,
      scheduleWorkingHours: formValues.scheduleWorkingHours,
      actions: formValues.tasks.map(task => {
        return {
          fromDate: moment(task.startDate, 'DD.MM.YYYY').utcOffset('+0000', true),
          toDate: moment(task.endDate, 'DD.MM.YYYY').utcOffset('+0000', true).endOf('day'),
          name: task.name,
          workingHours: task.workingHours,
          dailyWorkingHours: task.dailyWorkingHours,
          showCheckbox: task.showCheckbox,
          subActions: task.actions.map(action => {
            return {
              rmDimmingLevel: values.deviceType === 'RM' ? action.percent : null,
              shunoStatus: values.deviceType === 'SHUNO' && action.percent ? 'ON' : 'OFF',
              canDelete: action.canDelete,
              disabled: action.disabled,
              time: `${action.hours}:${action.minutes}`,
            }
          })
        }
      })
    }
    const queryParams = qs.stringify({
      justificationComment: justificationComment,
    })
    const { data } = yield request({
      url: `${SCHEDULE_MANAGER_CREATE_ENDPOINT}?${queryParams}`,
      method: 'post',
      body,
    })

    if (data) {
      const toast = createNotifications()
      yield put(requestScheduleManagerTree())
      yield put(setGlobalLoading(false))
      onCancel()
      toast({
        title: 'Создание расписания',
        type: 'success',
        description: 'Расписание успешно создано.',
      })
      yield put(successCreateSchedule())
    }
  } catch (error) {
    const toast = createNotifications()
    if (error.response.data.errorMessage === 'Schedule.name.already.exists.in.this.geozone') {
      toast({
        title: 'Ошибка операции!',
        description: 'Расписание с указанным названием уже существует в системе. \nИзмените название расписания.',
        type: 'error',
      })
      yield put(errorCreateSchedule())
      yield put(setGlobalLoading(false))
      return
    }
      yield put(errorCreateSchedule())
      yield put(setGlobalLoading(false))
      onCancel()
      console.log('function*createScheduleSaga -> error', error)
      toast({
        title: 'Ошибка операции!',
        description: 'Не удалось создать расписание. \nПовторите попытку позже.',
        type: 'error',
      })
  }
}

export default function* root() {
  yield takeLatest(CREATE_SCHEDULE.REQUEST, createScheduleSaga)
}
