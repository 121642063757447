import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export const SCHEDULE = 'schedule'
export const PASSPORTIZATION = 'passportization'
export const CONTROL = 'control'
export const VERIFICATION = 'verification'

export const TABS_CONFIG = [
  {
   id: SCHEDULE,
   name: <Lang id="objectsGroups.titles.schedule" />,
   code: 'GROUP_ASSIGN_SCHEDULE_EDIT'
  },
  {
   id: PASSPORTIZATION,
   name: <Lang id="objectsGroups.titles.passportization" />,
   code: ''
  },
  // {
  //  id: CONTROL,
  //  name: <Lang id="objectsGroups.titles.control" />,
  //  code: 'GROUP_MANAGE_EDIT'
  // },
  {
   id: VERIFICATION,
   name: <Lang id="objectsGroups.titles.verification" />,
   code: 'GROUP_VERIFICATION_EDIT'
  },
]