/* eslint-disable import/no-named-as-default-member */
/* eslint-disable import/no-unresolved */
import React, {
  useMemo,
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import hash from 'hash-sum'
import { Formik, Form } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import TextField from '@/components/fields/TextField'
import TextAreaField from '@/components/fields/TextAreaField'
import CoreIcons from '@/components/icons/core'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import SelectField from '@/components/fields/SelectField'
import { createRegionValidator, createRootRegionValidator } from '@/constants/validationFields'
import CREATE_REGION_NAMES from '@/constants/forms/createGeoZone'
import { DEBOUNCE_DELAY_MEDIUM } from '@/constants/time'
import Loader from '@/components/blocks/Loader'
import {
  TEXT,
  ARIA,
  SELECT,
} from '@/constants/semanticNames'

import { geoZoneConfig, rootGeoZoneConfig } from './config'
import {
  InputAndLabelContainer,
  IconContainer,
  Container,
  Header,
  HeaderTitle,
  FieldsContainer,
  Button,
} from './styles'

const GeoZoneForm = React.forwardRef(({
  selectedNode,
  setUrlFormValues,
  submit,
  submitError,
  title,
  ghostMode,
  onCancel,

  formValues,
  getParentZoneOptions,
  parentElements,
  edit,
  setNewPointsHash,
}, ref) => {
  const localParentElements = useMemo(() => parentElements.filter(
    (element) => element.value !== selectedNode.treeElementId,
  ), [parentElements, selectedNode])

  const formattedConfig = useMemo(() => !!selectedNode.parentId && !!formValues.id ? geoZoneConfig.map((element) => {
    if (element.selector === CREATE_REGION_NAMES.PARENT) {
      return {
        ...element,
        options: localParentElements,
      }
    }
    return element
  }) : rootGeoZoneConfig, [localParentElements, selectedNode.parentId, formValues.id])

  const localInitialValues = useMemo(() => {
    const initialValues = selectedNode.parentId ? geoZoneConfig.reduce((accumulator, field) => {
      const fieldValue = field.formatter
          ? field.formatter(get(selectedNode, field.selector, ''))
          : get(selectedNode, field.selector, '')
      return {
        ...accumulator,
        [field.selector]: fieldValue,
      }
    }, {}) : rootGeoZoneConfig.reduce((accumulator, field) => {
      const fieldValue = field.formatter
          ? field.formatter(get(selectedNode, field.selector, ''))
          : get(selectedNode, field.selector, '')
      return {
        ...accumulator,
        [field.selector]: fieldValue,
      }
    }, {})
    initialValues.id = selectedNode.id
    return initialValues
  }, [selectedNode])

  const debounceSubmitHandler = (values, setSubmitting) => debounce(() => {
    setSubmitting(true)
    submit({
      formValues,
      values,
      setSubmitting,
      setUrlFormValues,
      maxRadiusValue: selectedNode.maxRadius
    })
  }, DEBOUNCE_DELAY_MEDIUM)

  const setFieldToNull = (selector, setFieldValue) => () => {
    setFieldValue(selector, '')
  }

  const renderOption = (errors, touched, isSubmitting, values, setFieldValue) => (element) => {
    const {
      selector,
      type,
      options,
      format,
      mask,
      immutable,
      charLimit,
      pattern,
    } = element
    return (
      <InputAndLabelContainer>
        {type === TEXT && !isSubmitting && (
          <>
            <LabelWithIcon
              isError={(touched[selector] && errors[selector])}
              title={(<Lang id={`installation.createGeoZoneForm.${selector}`} />)}
            />
            <TextField
              error={(touched[selector] && errors[selector])}
              name={selector}
              mask={mask}
              fieldProps={{
                autoComplete: 'off',
                disabled: isSubmitting || (immutable && !isSubmitting),
                format,
                onlyRead: isSubmitting,
                pattern,
              }}
              controls={(
                <>
                  {(get(values, selector, null)) && !(isSubmitting || isSubmitting || immutable)
                  && (
                    <PortalTooltip
                      title={(<Lang id="tooltip.erase" />)}
                      renderChildren={(
                        wrapperRef,
                        onMouseEnterHandler,
                        onMouseLeaveHandler,
                      ) => (
                        <IconContainer
                          type="cross"
                          onClick={setFieldToNull(selector, setFieldValue)}
                          ref={wrapperRef}
                          onMouseEnter={onMouseEnterHandler}
                          onMouseLeave={onMouseLeaveHandler}
                        >
                          <CoreIcons.EraserIcon />
                        </IconContainer>
                      )}
                    />
                  )}
                </>
              )}
            />
          </>
        )}
        {type === SELECT && !isSubmitting && (
          <>
            <LabelWithIcon
              isError={(touched[selector] && errors[selector])}
              title={(<Lang id={`installation.createGeoZoneForm.${selector}`} />)}
            />
            <SelectField
              error={(touched[selector] && errors[selector])}
              name={selector}
              mask={mask}
              withSearch
              placeholder="Выбрать"
              options={options || []}
              light
              fieldProps={{
                autoComplete: 'off',
                disabled: isSubmitting,
                format,
                onlyRead: isSubmitting,
              }}
            />
          </>
        )}
        {type === ARIA && !isSubmitting && (
          <>
            <LabelWithIcon
              isError={(touched[selector] && errors[selector])}
              title={(<Lang id={`installation.createGeoZoneForm.${selector}`} />)}
            />
            <TextAreaField
              error={(touched[selector] && errors[selector])}
              name={selector}
              mask={mask}
              rows="5"
              charLimit={charLimit}
              fieldProps={{
                autoComplete: 'off',
                disabled: isSubmitting || (immutable && !isSubmitting),
                format,
                onlyRead: isSubmitting,
              }}
              controls={(
                <>
                  {(get(values, selector, null)) && !(isSubmitting || isSubmitting || immutable)
                  && (
                    <PortalTooltip
                      title={(<Lang id="tooltip.erase" />)}
                      renderChildren={(
                        wrapperRef,
                        onMouseEnterHandler,
                        onMouseLeaveHandler,
                      ) => (
                        <IconContainer
                          type="cross"
                          onClick={setFieldToNull(selector, setFieldValue)}
                          ref={wrapperRef}
                          onMouseEnter={onMouseEnterHandler}
                          onMouseLeave={onMouseLeaveHandler}
                        >
                          <CoreIcons.EraserIcon />
                        </IconContainer>
                      )}
                    />
                  )}
                </>
              )}
            />
          </>
        )}
      </InputAndLabelContainer>
    )
  }

  return (
    <Formik
      ref={ref}
      key={JSON.stringify(localInitialValues)}
      enableReinitialize
      initialValues={localInitialValues}
      validationSchema={selectedNode.parentId ? () => createRegionValidator({ maxRadius: selectedNode.maxRadius }) : createRootRegionValidator}
      validateOnMount
      validateOnChange
      isInitialValid={edit && localInitialValues[CREATE_REGION_NAMES.NAME]}
      render={({
        touched, errors, isSubmitting, values, setFieldValue, handleChange, setSubmitting, isValid, ...ownProps
      }) => {
        setNewPointsHash(hash(values))
        return (
          <Form id="GeoZoneForm" name="GeoZoneForm">
            <Container>
              <Header>
                <HeaderTitle>
                  {title}
                </HeaderTitle>
                {!ghostMode && (
                  <>
                    <Button type="green" onClick={isValid ? debounceSubmitHandler(values, setSubmitting, setFieldValue) : submitError} isSubmitting={isSubmitting}>
                      <Lang id="installation.createGeoZoneForm.save" />
                    </Button>
                    <Button type="red" onClick={onCancel}>
                      <Lang id="installation.createGeoZoneForm.cancel" />
                    </Button>
                  </>
                )}
              </Header>
              {(edit && !localInitialValues[CREATE_REGION_NAMES.NAME]) || isSubmitting
                ? (
                  <>
                    <Loader />
                  </>
                )
                : (
                  <FieldsContainer ghostMode={ghostMode}>
                    { formattedConfig.map(renderOption(touched, errors, isSubmitting, values, setFieldValue, handleChange))}
                  </FieldsContainer>
                )}
            </Container>
          </Form>
        )
      }}
    />
  )
})

GeoZoneForm.defaultProps = {
  selectedNode: {},
  setUrlFormValues: noop,
  submit: noop,
  submitError: noop,
  onCancel: noop,
  ghostMode: true,
  title: false,
  formValues: {},
  getParentZoneOptions: noop,
  parentElements: [],
  setNewPointsHash: noop,
  edit: false,
}
GeoZoneForm.propTypes = {
  selectedNode: pt.objectOf(pt.object),
  setUrlFormValues: pt.func,
  submit: pt.func,
  submitError: pt.func,
  onCancel: pt.func,
  ghostMode: pt.bool,
  title: pt.string,
  formValues: pt.objectOf(pt.string),
  getParentZoneOptions: pt.func,
  parentElements: pt.arrayOf(pt.object),
  setNewPointsHash: pt.func,
  edit: pt.bool,
}

export default GeoZoneForm
