import { GET_PASSPORTIZATION_TABLE } from './index'

export const requestGetPassportizationTable = (payload) => ({
  type: GET_PASSPORTIZATION_TABLE.REQUEST,
  payload,
})

export const successGetPassportizationTable = (payload) => ({
  type: GET_PASSPORTIZATION_TABLE.SUCCESS,
  payload,
})

export const errorGetPassportizationTable = (payload) => ({
  type: GET_PASSPORTIZATION_TABLE.ERROR,
  payload,
})