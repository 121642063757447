import {
  NOT_ELEMENTS_TYPES,
  REGIONS_TYPES,
  STATUS_TYPES,
  ZOOM_BY_TYPES,
  STATUS_TYPE_INSTALLATIONS,
  YMSP_Z_INDEX,
  mapZoomBreakPoints
} from '@/constants/maps'
import {
  OK,
  WARNING,
  ERROR,
  INFO,
  NEW,
  NOT_VERIFIED,
  VERIFIED,
  NOT_EXIST,
  NOT_INSTALLED,
  INSTALLED,
  UNINSTALLED
} from '@/constants/objectStatuses'
import isController from '@/components/blocks/YandexMap/utlis'

export const getIsElementary = (elementType) => NOT_ELEMENTS_TYPES
  .every((type) => elementType !== type)

export const getIsNotRegion = (elementType) => REGIONS_TYPES
  .every((type) => elementType !== type)

export const getZoomByType = (type) => {
  if (ZOOM_BY_TYPES[type]) {
    return ZOOM_BY_TYPES[type]
  }

  return mapZoomBreakPoints.max - 1
}

export const getCountOfStatus = (data) => {
  const colorsCount = data.reduce((accumulator, element) => {
    const isStatusType = STATUS_TYPES[element]
    if (isStatusType) {
      return {
        ...accumulator,
        [element]: accumulator[element] + 1,
      }
    }
    return accumulator
  }, {
    [OK]: 0,
    [WARNING]: 0,
    [INFO]: 0,
    [ERROR]: 0,
  })
  return {
    data: colorsCount,
    length: data.length,
  }
}

export const getCountOfStatusCluster = (data) => {
  const colorsCount = data.reduce((accumulator, element) => {
    const isStatusType = STATUS_TYPE_INSTALLATIONS[element]
    if (isStatusType) {
      return {
        ...accumulator,
        [element]: accumulator[element] + 1,
      }
    }
    return accumulator
  }, {
    [NOT_VERIFIED]: 0,
    [VERIFIED]: 0,
    [NEW]: 0,
    [NOT_EXIST]:0,
    [NOT_INSTALLED]:0,
    [INSTALLED]:0,
    [UNINSTALLED]:0,
  })
  return {
    data: colorsCount,
    length: data.length,
  }
}

export const getStatusOfObjects = (data) => {
  const length = Object.keys(data)
  return length.map((el) => (data[el].objectData && data[el].objectData.status) || INFO)
}

export const getZIndexByType = (node, pinnedNode) => {
  if (node.id === pinnedNode.id) {
    return YMSP_Z_INDEX.geoObject.large
  }

  if (isController(node.type)) {
    return YMSP_Z_INDEX.geoObject.big
  }

  return YMSP_Z_INDEX.geoObject.medium
}
