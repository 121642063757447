import { takeLatest, put } from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_STREAM_URL } from '@/store/actions/installation'
import { getSuccessStreamUrl } from '@/store/actions/installation'
import {
  GET_STREAM_ENDPOINT
} from '@/constants/apiRoutes'

function* getStreamUrl({payload: id}) {
  try {
    const { data } = yield request({
      url: GET_STREAM_ENDPOINT(id),
      method: 'get',
    })
    yield put(getSuccessStreamUrl(data.url))
  } catch (error) {
  }
}

export default function* root() {
  yield takeLatest(GET_STREAM_URL.REQUEST, getStreamUrl)
}
