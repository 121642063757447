import { GET_CONNECTED_LINES_COORDINATES } from './index'

export const requestGetConnectedLinesCoordinates = (payload) => ({
    type: GET_CONNECTED_LINES_COORDINATES.REQUEST,
    payload,
})

export const successGetConnectedLinesCoordinates = (payload) => ({
    type: GET_CONNECTED_LINES_COORDINATES.SUCCESS,
    payload,
})

export const errorGetConnectedLinesCoordinates = (payload) => ({
    type: GET_CONNECTED_LINES_COORDINATES.ERROR,
    payload,
})