import {
  take, call,
} from 'redux-saga/effects'
// import { updateMapData } from '@/store/actions/maps/socketEvents'
import {
  mapLightTelemetry,
  mapControllerTelemetry,
  mapColdWaterTelemetry,
  mapHeatTelemetry,
  mapGroupObjectTelemetry,

  mapBrizTelemetry,

  mapMeshTelemetry, mapUnilightControllerTelemetry, mapUnilightModuleTelemetry,
} from '@/helpers/maps/telemetryMappers'

import { createMonitoringNotificationsChannel } from './channels'
import createNotifications from "@/helpers/notification";

export const mapperByType = {
  LIGHT_STATE: mapLightTelemetry,
  MODULE_STATE: mapUnilightModuleTelemetry,
  OBJECT_STATE: mapControllerTelemetry,
  CONTROL_CUPBOARD_STATE: mapUnilightControllerTelemetry,
  COLD_WATER_DEVICE_STATUS: mapColdWaterTelemetry,
  CENTRAL_HEATING_DEVICE_STATUS: mapHeatTelemetry,
  GROUP_DEVICE_STATUS: mapGroupObjectTelemetry,

  EQUIPMENT_STATE: mapBrizTelemetry,
  CONTROLLER_STATE: mapBrizTelemetry,
  LAMP_STATE: mapBrizTelemetry,
  CONTACTOR_STATE: mapBrizTelemetry,
  FIRE_SENSOR_STATE: mapBrizTelemetry,
  ELECTRIC_METER_STATE: mapBrizTelemetry,
  DMX_DEVICE_STATE: mapBrizTelemetry,
  CURRENT_SENSOR_STATE: mapBrizTelemetry,
  CHECK_POINT_STATE: mapBrizTelemetry,
  BRANCH_CIRCUIT_STATE: mapBrizTelemetry,
  PHASE_STATE: mapBrizTelemetry,
  PHASE_CIRCUIT_STATE: mapBrizTelemetry,
  CIRCUIT_BREAKER_STATE: mapBrizTelemetry,
}

function* monitoringNotifications() {
  try {
    let actions = {}
    const firstActionDate = new Date()
    let firstActionTime = firstActionDate.getTime()

    const channel = yield call(createMonitoringNotificationsChannel)

    while (true) {
      const eventData = yield take(channel)
      const { eventType } = (eventData || {}).header || {}
      let mapTelemetryFunction = mapperByType[eventType]

      if (!eventType && eventData.id && eventData.type) {
        mapTelemetryFunction = mapMeshTelemetry
      }

      if (mapTelemetryFunction) {
        actions = {
          ...actions,
          ...mapTelemetryFunction(eventData),
        }

        const actionDate = new Date()
        const actionTime = actionDate.getTime()
        const actionsDiff = actionTime - firstActionTime
        const TIME_DIFF = 1 * 60 * 1000
        if (actionsDiff >= TIME_DIFF) {
          // yield put(updateMapData(actions))
          actions = {}
          firstActionTime = new Date().getTime()
        }
      }
    }
  } catch (error) {
    console.log('TCL: function*monitoringNotifications -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default monitoringNotifications
