import styled from 'styled-components'
import { Form } from 'formik'
import CheckBoxField from '@/components/fields/CheckBoxField'
import { LIGHT_THEME_NAME } from '@/constants/names'
import DatePickerField from '@/components/fields/DatePickerField'
import { ControlsContainer } from '@/components/controls/FileControll/styles'

export const CustomDatePicker = styled(DatePickerField)`
  height: 28px;
`
export const TemplateLink = styled.a``

export const HiddenInput = styled.div``

export const FormContainer = styled(Form)`
  padding-top: 1px;
  height: 100%;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
          background: ${theme.colors.backgrounds.redisign.default};
        `
    }
  }}
  background: ${({ theme }) => theme.colors.backgrounds.redisign.topDownGradient};
  flex-wrap: wrap;
  && > div {
    padding: 6px;
  }
`

export const CustomCheckBoxField = styled(CheckBoxField)`
  margin-right: 10px;
`

export const HiddenInputContainer = styled.input`
  visibility: hidden;
  width: 0;
  height: 0;
`

export const ContentContainer = styled.div`
  margin: 3px 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  white-space: nowrap;
  flex-wrap: wrap;
  position: relative;
  cursor: pointer;
  margin-right: 5px;
  ${({ interval }) => interval && `
    margin-left: 5px;
  `};
  ${({ datePicker }) => datePicker && `
    margin-right: 0;
    width: 135px;
    .react-datepicker-popper {
      width: 300px;
      z-index: 4;
      top: -30px !important;
    }
  `};
  ${({ file }) => file && `
    input {
      padding-right: 35px;
      width: 320px;
    }
  `}
  ${ControlsContainer} {
    margin-top: 4px;
  }
  ${({ theme, gray }) => gray && `
    ${FilterContentWrapper} {
      z-index: 2;
      display: flex;
      align-items: center;
      background: pink;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 1.8rem;
      background: ${theme.colors.backgrounds.selectInput};
      border-color: ${theme.colors.borders.default};
      color: ${theme.colors.colors.default};
      -webkit-text-fill-color: ${theme.colors.colors.redisign.default};
    }
  `}
`
export const FileControllContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ onClick }) => onClick && `
      cursor: pointer;
  `}
`

export const IconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  top: -4px;
  right: 0;
  ${({ type }) => type && `
    svg, .svgFill {
      height: 14px;
      width: 14px;
      margin-right: 10px;
    }
  `}
`
export const DownloadIconContainer = styled.div`
  height: 100%;
  position: absolute;
  top: 4px;
  right: 5px;
  ${({ type }) => type && `
    svg, .svgFill {
      height: 14px;
      width: 14px;
      margin-right: 10px;
    }
  `}
`

export const FilterContentWrapper = styled.div`
  outline: none;
  background: ${({ theme }) => theme.colors.backgrounds.textField};
  border: 1px solid ${({ theme, isError }) => (isError
    ? theme.colors.messages.error
    : theme.colors.borders.textField)};
  -webkit-text-fill-color: ${({ theme }) => theme.colors.colors.textField};
  color: ${({ theme }) => theme.colors.colors.textField};
  width: 100%;
  border-radius: 4px;
  height: 28px;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 0 25px 0 10px;
  transition: background 100ms ease-in-out;
  ${({ action }) => action && `
    cursor: pointer;
    && > * {
      cursor: pointer;
    }
  `}

  ::placeholder {
    color: ${({ theme }) => theme.colors.colors.textField};
    ${({ placeholderCenter }) => (placeholderCenter) && `
      text-align: center;
  `}
    ${({ upperCase }) => upperCase && `
      text-transform: uppercase;
  `}
  }
  
  ${({ styleType, theme, isError }) => styleType === 'secondary' && `
    border: 1px solid
      ${isError ? theme.colors.messages.error : theme.colors.borders.redisign.lightBlack};
    border-radius: 4px;
    height: 44px;
  `}
  ${({ styleType, currentValue }) => styleType === 'secondary' && currentValue && `
    border: none;
    background: none;
    height: 44px;
  `}
  text-overflow: ellipsis;
  ${({ controls }) => controls && `
    padding-right: 60px;
  `}
  ${({ icon }) => icon && `
    padding-left: 30px;
  `}
`
