import React, { useState, useMemo, useCallback } from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import GlobalPopup from '@/components/blocks/GlobalPopup'
import GlobalPopupContent from '@/components/blocks/GlobalPopupContent'
import MenuIcon from '@/components/icons/menu'
import MapIcons from '@/components/icons/maps'
import {
  USER,
  USER_EDIT_FROM_ADMIN,
} from '@/constants/viewTree'
import {
  Container,
  Text,
  CustomButton,
  GroupedContainer,
  IconContainer,
} from './styles'

const UserFormHeader = ({
  title,
  editTitle,
  saveForm,
  conditionsForAdding,
  closeForm,
  popupSettings,
  editMode,
  deleteForm,
  setFormIsModifying,
  withoutEditing,
  withoutDeleting,
  multipleEdit,
  userRole,
  setType,
  type,
  selectedAdmin
}) => {

  const [isPopupOpen, setIsPopupOpen] = useState({})
  const handleSetPopup = useCallback((value) => () => {
    setIsPopupOpen(value)
  }, [])

  const handleSetFormIsModifying = useCallback(() => {
    if (type === USER && selectedAdmin.enabled) {
      setType(USER_EDIT_FROM_ADMIN)
    }
    setFormIsModifying(true)
  }, [setFormIsModifying, setType, type, selectedAdmin])

  const handleSaveForm = useCallback(() => {
    if (conditionsForAdding) {
      saveForm()
    } else {
      setIsPopupOpen({ value: true, type: 'warning' })
    }
  }, [conditionsForAdding, saveForm])

  const renderContent = useMemo(() => {
    if (editMode) {
      return (
        <>
          <Text title>
            {editTitle}
          </Text>
          <GroupedContainer>
            <CustomButton type="green" onClick={handleSaveForm}>
              <Lang id="usersPage.formHeader.save" />
            </CustomButton>
            <CustomButton type="red" onClick={handleSetPopup({ value: true, type: 'error' })}>
              <Lang id="usersPage.formHeader.cancel" />
            </CustomButton>
          </GroupedContainer>
        </>
      )
    }
    return (
      <>
        <GroupedContainer>
          <IconContainer onClick={closeForm}>
            <MapIcons.BackIcon />
          </IconContainer>
          {!withoutEditing && !multipleEdit && (
            <IconContainer editIcon onClick={handleSetFormIsModifying}>
              <MenuIcon.EditIcon />
            </IconContainer>
          )}
          {multipleEdit && (
            <IconContainer editIcon/>
          )}
          <Text withoutEditing={withoutEditing} editTitle>
            {title}
          </Text>
        </GroupedContainer>
          {!withoutDeleting && !withoutEditing && (
          <GroupedContainer>
            <IconContainer deleteIcon onClick={handleSetPopup({ value: true, type: 'error' })}>
              <MapIcons.DeleteIcon />
            </IconContainer>
          </GroupedContainer>
          )}
          {!withoutDeleting && userRole && withoutEditing && (
            <GroupedContainer>
              <IconContainer editIcon onClick={handleSetFormIsModifying}>
              <MenuIcon.EditIcon />
            </IconContainer>
            <IconContainer deleteIcon onClick={handleSetPopup({ value: true, type: 'error' })}>
              <MapIcons.DeleteIcon />
            </IconContainer>
            </GroupedContainer>
          )}
      </>
    )
  },
  [
    editMode,
    closeForm,
    handleSetFormIsModifying,
    title,
    handleSetPopup,
    editTitle,
    handleSaveForm,
    withoutEditing,
    withoutDeleting,
    multipleEdit,
    userRole
  ])

  return (
    <Container>
      {isPopupOpen.value && (
        <GlobalPopup
          content={(
            <GlobalPopupContent
              type={isPopupOpen.type}
              onClose={handleSetPopup({ value: false, type: isPopupOpen.type })}
              title={isPopupOpen.type === 'error' ? popupSettings.error.title : popupSettings.warning.title}
              message={isPopupOpen.type === 'error' ? popupSettings.error.message : popupSettings.warning.message}
              config={
                isPopupOpen.type === 'error'
                  ? {
                    error: {
                      icon: MenuIcon.TreshIcon,
                      buttons: [
                        {
                          statusType: 'flat',
                          onClickSelector: handleSetPopup({ value: false, type: isPopupOpen.type }),
                          title: <Lang id="usersPage.popup.no" />,
                        },
                        {
                          statusType: 'error',
                          onClickSelector: editMode ? closeForm : deleteForm,
                          title: <Lang id="usersPage.popup.yes" />,
                        },
                      ],
                    },
                  }
                  : {
                    warning: {
                      icon: MenuIcon.AttentionIcon,
                      buttons: [
                        {
                          statusType: 'warning',
                          onClickSelector: handleSetPopup({ value: false, type: isPopupOpen.type }),
                          title: <Lang id="usersPage.popup.accept" />,
                        },
                      ],
                    },
                  }
              }
            />
            )}
        />
      )}
      {renderContent}
    </Container>
  )
}

UserFormHeader.propTypes = {
  userRole: pt.bool
}
UserFormHeader.defaultProps = {
}

export default UserFormHeader
