import React from 'react'
import pt from 'prop-types'
import {CellContainer, Row} from './styles'

const PprCell = ({value, installationType, factDate}) => {

  const renderDate = (value, factDate) => {
    if (factDate) {
      if (value.date === 'Invalid date') {
        return '-'
      }
      return value.date
    }
    if (value === 'Invalid date') {
      return '-'
    }
    return value
  }
  return (
    <CellContainer>
      {value.map((item) => {
        return (
        <Row installationType={installationType} expired={item?.expired}>
          {renderDate(item, factDate)}
        </Row>
        )
      })}
    </CellContainer>
  )
}

PprCell.propTypes = {
  value: pt.string,
  manyValues: pt.bool,
  text: pt.bool,
  date: pt.bool,
}
PprCell.defaultProps = {
  value: '',
  manyValues: false,
  text: pt.bool,
  date: false,
}

export default PprCell
