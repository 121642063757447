import React, { useState, useMemo, useCallback } from 'react'

import {
  INTEGRATION,
  ASU,
  OBJECT_TYPES,
  PROCESSES,
} from '@/constants/forms/globalFilterForm'

import {
  OK,
  WARNING,
  ERROR,
  INFO,
} from '@/constants/objectStatuses'
import GlobalFilterForm from '@/forms/GlobalFilterForm'


import {Header, TitlePage, ControlsContainer} from './styles'
import HeaderIcons from '@/components/icons/header'
import ControlsBlock from './ControlsBlock'
import GlobalPopup from '@/components/blocks/GlobalPopup'
import GlobalPopupContent from '@/components/blocks/GlobalPopupContent'
import MapLayerMobile from '../MapLayerMobile'


const HeaderPageMobile = ({
  title, 
  pinnedNode,  
  globalFiltersValues,
  moveToPinnedNode,
  mapLayer,
  handleMapLayer,

  requestGetSearchFieldsOptions,
  searchFieldsOptions,
  searchFieldsOptionsLoading,
  connectedIntegrations,
  setMapLayer,

  requestGetAllConnectedIntegrations,
  onSetGlobalFilterValues,
  onResetGlobalFilterValues,
  onSetIsGlobalFilterOpen,
  onSetUnpinNode
}) => {
  const [isPopupOpen, setIsPopupOpen] = useState({})
  const [isGlobalFilterOpen, setIsGlobalFilterOpen] = useState(false)
  const [isMapLayerOpen, setIsMapLayerOpen] = useState(false)

  const toggleGlobalFilterPopup = useCallback(() => {
    setIsGlobalFilterOpen(!isGlobalFilterOpen)
    onSetIsGlobalFilterOpen()
  }, [isGlobalFilterOpen, onSetIsGlobalFilterOpen])

  const configAlert = pinnedNode.name ? 
    {
      message: `Текущий закрепленный объект:\n«${pinnedNode.name}»`,
      title: 'Закрепленный объект',
      type: 'info'
    }
  :
    {
      message: 'Чтобы предоставить быстрый доступ к нужному объекту, установите закрепление в его карточке.',
      title: 'Нет Закрепленного объекта!',
      type: 'warning'
    }
  

  const isGlobalFilterActive = useMemo(() => {
    const isHasIntegration = !!globalFiltersValues[INTEGRATION]
    const isHasASU = !!globalFiltersValues[ASU]
    const isHasType = !!globalFiltersValues[OBJECT_TYPES]
    const isHasProcess = !!globalFiltersValues[PROCESSES]

    const valuesSelected = isHasIntegration || isHasASU || isHasType || isHasProcess
    const isTypeDisabled = !globalFiltersValues[OK] || !globalFiltersValues[WARNING]
      || !globalFiltersValues[ERROR] || !globalFiltersValues[INFO]

    return valuesSelected || isTypeDisabled
  }, [globalFiltersValues])

  const handlePinIconClick = () => {
    setIsPopupOpen({value: true})
  }

  const onMoveToPinnedNode = () => {
    moveToPinnedNode(pinnedNode)
    setIsPopupOpen({value: false})
  }

  const handleClickButton = () => {
    setIsPopupOpen({value: false})
  }

  const handleSaveFilters = useCallback((values) => {
    onSetGlobalFilterValues(values)
    toggleGlobalFilterPopup()
  },
  [
    onSetGlobalFilterValues,
    toggleGlobalFilterPopup,
  ])

  const handleOnSetUnpinNode = () => {
    setIsPopupOpen({value: false})
    onSetUnpinNode({})
  }

  const handleLayerClick = () => {
    setIsMapLayerOpen(!isMapLayerOpen)
    handleMapLayer(!isMapLayerOpen)
  }

  const handleCloseLayer = () => {
    setIsMapLayerOpen(false)
    handleMapLayer(false)
  }

  const filters = [
      {
        icon: pinnedNode && pinnedNode.id
          ? <HeaderIcons.SelectedPinIcon />
          : <HeaderIcons.NoSelectedPinIcon />,
        name: 'HeaderIcons.SelectedPinIcon',
        onClick: handlePinIconClick,
      },
      {
        icon: isGlobalFilterActive
          ? <HeaderIcons.ActiveGlobalFilterIcon />
          : <HeaderIcons.GlobalFilterIcon />,
        name: 'HeaderIcons.GlobalFilterIcon',
        onClick: toggleGlobalFilterPopup,
        onClose: toggleGlobalFilterPopup,
        popupContent: (
          <GlobalFilterForm
              onSetValues={handleSaveFilters}
              onResetValues={onResetGlobalFilterValues}
              globalFiltersValues={globalFiltersValues}
              requestGetSearchFieldsOptions={requestGetSearchFieldsOptions}
              searchFieldsOptions={searchFieldsOptions}
              searchFieldsOptionsLoading={searchFieldsOptionsLoading}
              requestGetAllConnectedIntegrations={requestGetAllConnectedIntegrations}
              connectedIntegrations={connectedIntegrations}
          />
        ),
        opened: isGlobalFilterOpen,
      },
      {
        icon: <HeaderIcons.LayersIcon />,
        name: 'HeaderIcons.LayersIcon',
        onClick: handleLayerClick,
        onClose: handleCloseLayer,
        isLayer: true,
        popupContent: (
          <MapLayerMobile mapLayer={mapLayer} setMapLayer={setMapLayer}/>
        ),
        opened: isMapLayerOpen
      }
  ]

  return (
    <>
      {isPopupOpen.value && 
        <GlobalPopup 
          content={
            <GlobalPopupContent 
              {...configAlert}
              isMobile 
              onClose={handleClickButton}
              handleOnSetUnpinNode={handleOnSetUnpinNode}
              isSelectedPin={pinnedNode && pinnedNode.id}
              config={
                   {
                    warning: {
                      buttons: [
                        {
                          statusType: 'info',
                          onClickSelector: handleClickButton,
                          title: 'Понятно',
                        },
                      ],
                    },
                    info: {
                      buttons: [
                        {
                          statusType: 'flat',
                          onClickSelector: handleClickButton,
                          title: 'Отмена',
                        },
                        {
                          statusType: 'info',
                          onClickSelector: onMoveToPinnedNode,
                          title: 'К объекту',
                        },
                      ]
                    }
                  }}
            />} 
        />}
      <Header>
        <TitlePage>{title}</TitlePage> 
        <ControlsContainer>
            <ControlsBlock
              controls={filters}
              spaced
            />
        </ControlsContainer>
      </Header>
    </>
  )
}

  

HeaderPageMobile.propTypes = {
}
HeaderPageMobile.defaultProps = {
}

export default HeaderPageMobile
