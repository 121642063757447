import {
    take, call
} from 'redux-saga/effects'
import createNotifications from '@/helpers/notification'
import { createInstallationUploadProgressChannel } from './channels'

function* installationUploadFileProgressSaga({payload}) {
    const toast = createNotifications()
    const channel = yield call(createInstallationUploadProgressChannel, payload.id)
    while (true) {
        const eventData = yield take(channel)
        console.log('function*installationUploadFileProgressSaga -> eventData', eventData)
        const percent = Math.round(eventData.processedElements / eventData.totalElements * 100)
        toast({
            title: 'Статус загрузки',
            type: 'info',
            description: `Процесс загрузки файла инсталляции в интеграцию \n"${eventData.integrationName}" \nзавершен на ${percent}%`,
            autoClose: false,
            update: percent > 0,
            toastId: eventData.integrationName
        })
    }
}

export default installationUploadFileProgressSaga