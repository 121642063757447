import React from 'react'
import pt from 'prop-types'
import { Field } from 'formik'
import ColorSelect from '@/components/controls/ColorSelect'
import get from 'lodash/get'

const ColorField = ({ error, onValueChange, ...ownProps }) => (
  <Field {...ownProps}>
    {({ field, form }) => {

      const onChange = (value) => {
        form.setFieldValue(field.name, value)
        onValueChange(value)
      }

      return (
        <ColorSelect
          error={get(form, `errors.${field.name}`, false)}
          value={get(form, `values.${field.name}`)}
          onChange={onChange}
        />
      )
    }}
  </Field>
)

ColorField.defaultProps = {
  error: '',
}

ColorField.propTypes = {
  error: pt.string,
}

export default React.memo(ColorField)