import {
  take, call,
} from 'redux-saga/effects'

import { createMonitoringErrorsChannel } from './channels'
import createNotifications from "@/helpers/notification";

function* monitoringErrors() {
  try {
    const channel = yield call(createMonitoringErrorsChannel)

    while (true) {
      yield take(channel)
    }
  } catch (error) {
    console.log('TCL: function*monitoringErrors -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default monitoringErrors
