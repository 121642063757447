import styled from 'styled-components'
import { LIGHT_THEME_NAME } from '@/constants/names'
import { WHITE_SUN, WHITE_SUN_GRADIENT } from '@/constants/styles/defaultTheme'

export const WidgetContainer = styled.div`
  height: 100%;
  padding: 10px;
  box-shadow: -8px 12px 18px ${({ theme }) => theme.colors.shadows.widget};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
        background: ${WHITE_SUN_GRADIENT};
        border: 1px solid ${WHITE_SUN};
      `
    }
    return `
      background: ${theme.colors.backgrounds.header};
    `
  }}
  @media (max-width: 910px) {
    width: 100%;
  }
`

export const WrapperLoader = styled.div`
  margin: auto;
`

export const WidgetHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const TooltipWrapper = styled.div`
  display: none;
  ${WidgetContainer}:hover && {
    display: block
  }
`

export const WidgetTitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: auto;
`

export const WidgetHeaderIcon = styled.div`
  cursor: pointer;
`

export const WidgetTitle = styled.div`
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.colors.colors.default};

  ${({ small }) => small && `
    font-size: 1.2rem;
    line-height: 1.7rem;
  `}
`

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 5px;
`

export const TextPart = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
`

export const DescriptionsRow = styled.div`
  cursor: pointer;
  display: flex;
  margin-bottom: 10px;
  text-align: left;
`

export const DescriptionsRowCount = styled.div`
  font-weight: bold;
  font-size: 2.0rem;
  line-height: 2.3rem;

  ${({ small }) => small && `
    font-size: 1.2rem;
    line-height: 1.4rem;
  `}
  ${({ color }) => color && `
    color ${color};
  `}
`

export const DescriptionsRowTitle = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
`

export const DescriptionIcon = styled.div`
  margin-right: 10px;
  margin-top: 3px;
  width: 18px;
  display: flex;
  justify-content: flex-end;
`

export const DescriptionContent = styled.div``

export const LineContainer = styled.div`
  width: 5px;
  height: 130px;
  display: flex;
  flex-direction: column;
`

export const LinePart = styled.div`
  position: relative;
  width: 100%;

  ${({ color }) => color && `
    background: ${color};
  `}
  ${({ size }) => {
    if (!size) {
      return ''
    }
    let columnHeight = size
    if (size < 7) {
      columnHeight = 7
    }
    if (size === 100) {
      columnHeight = 100
    }
    if (size > 93) {
      columnHeight = 93
    }
    return `
      height: ${columnHeight}%;

      &::before {
        content: "${size}%";
        position: absolute;
        right: 10px;
        font-weight: 500;
        font-size: 1.0rem;
        line-height: 1.2rem;
      }
    `
  }}
  ${({ first }) => first && `
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  `}
  ${({ last }) => last && `
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  `}
`
