export default {
  createGeoZoneForm: {
    name: 'Название геозоны*',
    parentId: 'Родительская геозона*',
    parentGeoZoneName: 'Родительская геозона',
    parentName: 'Родительский проект',
    lat: 'Географическая широта*',
    lon: 'Географическая долгота*',
    'location[0]': 'Географическая широта',
    'location[1]': 'Географическая долгота',
    'geoLocationDto.latitude': 'Географическая широта',
    'geoLocationDto.longitude': 'Географическая долгота',
    'point.latitude': 'Географическая широта',
    'point.longitude': 'Географическая долгота',
    region: 'Регион',
    city: 'Город',
    mark: 'Заметка',
    note: 'Заметка',
    type: 'Тип*',
    geoZoneInstallation: 'Инсталляция геозоны',
    geoZoneInfo: 'Информация о геозоне:',
    projectInfo: 'Информация о проекте:',
    integrationInfo: 'Информация о интеграции:',
    infoStructure: 'Инфраструктура:',
    save: 'Сохранить',
    cancel: 'Отмена',
    projectId: 'Проект*',
    integrationType: 'Тип интеграции*',
    radius: 'Радиус, км*',
    maxRadius: 'Максимальный радиус внутренних геозон, км*'
  },
  projectForm: {
    name: 'Название проекта*',
    parentId: 'Родительская геозона*',
    parentGeoZoneName: 'Родительская геозона',
    parentProjectName: 'Родительский проект',
    lat: 'Географическая широта*',
    lon: 'Географическая долгота*',
    'location[0]': 'Географическая широта',
    'location[1]': 'Географическая долгота',
    'geoLocationDto.latitude': 'Географическая широта',
    'geoLocationDto.longitude': 'Географическая долгота',
    'point.latitude': 'Географическая широта',
    'point.longitude': 'Географическая долгота',
    region: 'Регион',
    city: 'Город',
    mark: 'Заметка',
    note: 'Заметка',
    type: 'Тип*',
    geoZoneInstallation: 'Инсталляция геозоны',
    geoZoneInfo: 'Информация о геозоне:',
    projectInfo: 'Информация о проекте:',
    integrationInfo: 'Информация о интеграции:',
    infoStructure: 'Инфраструктура:',
    save: 'Сохранить',
    cancel: 'Отмена',
    projectId: 'Проект*',
    integrationType: 'Тип интеграции*',
    customField: 'Добавить произвольное поле',
    customFieldTitle: 'Название:',
    customFieldValue: 'Содержимое:',
    newField: 'Новое поле',
    deleted: 'удалено',
    objectType: 'Тип оборудования*',
    selectObject: 'Выберите оборудование',
  },
  integrationForm: {
    name: 'Название интеграции*',
    parentId: 'Родительская геозона*',
    parentGeoZoneName: 'Родительская геозона',
    parentProjectName: 'Родительский проект',
    lat: 'Географическая широта*',
    lon: 'Географическая долгота*',
    'location[0]': 'Географическая широта',
    'location[1]': 'Географическая долгота',
    'geoLocationDto.latitude': 'Географическая широта',
    'geoLocationDto.longitude': 'Географическая долгота',
    'point.latitude': 'Географическая широта',
    'point.longitude': 'Географическая долгота',
    region: 'Регион',
    city: 'Город',
    mark: 'Заметка',
    note: 'Заметка',
    type: 'Тип*',
    geoZoneInstallation: 'Инсталляция геозоны',
    geoZoneInfo: 'Информация о геозоне:',
    projectInfo: 'Информация о проекте:',
    integrationInfo: 'Информация о интеграции:',
    infoStructure: 'Инфраструктура:',
    save: 'Сохранить',
    cancel: 'Отмена',
    projectId: 'Проект*',
    integrationType: 'Тип интеграции*',
  },
  title: 'Инсталляция',
  addGeoZoneTitle: 'Инсталляция геозоны',
  editGeoZoneTitle: 'Редактирование геозоны',
  addProjectTitle: 'Добавление проекта',
  editProjectTitle: 'Редактирование проекта',
  addIntegrationTitle: 'Создание интеграции',
  editIntegrationTitle: 'Редактирование интеграции',
  addObjectTitle: 'Инсталляция oбъекта',
  editObjectTitle: 'Редактирование объекта',
  cancelAdd: 'Отменить добавление',
  cancel: 'Отменить',
  add: 'Добавить',
  filterObjects: 'Фильтрация объектов',
  allObjects: 'Все объекты',
  uploadFile: 'Загрузить файл инсталляции',
  downloadFile: 'Скачать шаблон файла инсталляции',
  popup: {
    attention: 'Внимание!',
    impossible: 'Невозможно выполнить!',
    editObject: 'Редактирование объекта',
    editObjectMessage: 'Сохранить текущие настройки?',
    syncObject: 'Синхронизация объекта',
    syncObjectMessage: 'Пройти верификацию\nили сохранить текущие настройки?',
    deleteObject: 'Вы действительно хотите\nудалить объект из системы?',
    deleteMessage: 'Вы действительно хотите удалить',
    cancelMessage: 'Вы действительно хотите\nотменить',
    installation: 'инсталляцию',
    edit: 'редактирование',
    edit1: 'редактирования',
    add: 'добавление',
    add1: 'добавления',
    continueMessage: 'Для продолжения необходимо\nвыбрать',
    geoZoneContinueMessage: 'Для продолжения необходимо\nвыбрать геозону',
    deleteGeoZoneMessage: 'Будут удалены все геозоны, проекты, интеграции и объекты контроля, входящие в эту геозону.',
    deleteProjectMessage: 'Будут удалены все интеграции и объекты контроля, входящие в этот проект.',
    deleteIntegrationMessage: 'Будут удалены все объекты контроля, входящие в эту интеграцию.',
    for: 'Для',
    requiredMessage: 'необходимо\nустановить все обязательные параметры',
    fromSystem: 'из системы',
    geozone: 'геозоны',
    geozone1: 'геозону',
    project: 'проект',
    project1: 'проекта',
    object: 'объект',
    object1: 'объекта',
    integration: 'интеграции',
    integration1: 'интеграцию',
    objectOrIntegration: 'объект или интеграцию',
  },
  card: {
    geoZones: 'Геозоны',
    projects: 'Проекты',
    integrations: 'Интеграции',
    objects: 'Объекты',
  }
}
