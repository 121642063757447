import { connect } from 'react-redux'
import { getControlData } from '@/store/selectors/objectsGroups'
import ControlTab from './component'
import { requestGetControlData } from '@/store/actions/objectsGroups/getControlData'
import { requestSetControlData } from '@/store/actions/objectsGroups/setControlData'

const mapStateToProps = (state) => ({
  controlData: getControlData(state)
})

const mapDispatchToProps = (dispatch) => ({
  requestGetControlData: (payload) => dispatch(requestGetControlData(payload)),
  requestSetControlData: (payload) => dispatch(requestSetControlData(payload)),
})

export default connect(mapStateToProps, mapDispatchToProps)(ControlTab)
