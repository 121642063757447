import {
  DOWNLOAD_MULTIPLE_OBJECT,
  DOWNLOAD_MULTIPLE_OBJECT_PROGRESS
} from './index'

export const requestDownloadMultipleObject = (payload) => ({
  type: DOWNLOAD_MULTIPLE_OBJECT.REQUEST,
  payload,
})

export const successDownloadMultipleObject = (payload) => ({
  type: DOWNLOAD_MULTIPLE_OBJECT.SUCCESS,
  payload,
})

export const errorDownloadMultipleObject = (payload) => ({
  type: DOWNLOAD_MULTIPLE_OBJECT.ERROR,
  payload,
})

export const eventsDownloadMultipleObjectProgress = (payload) => ({
  type: DOWNLOAD_MULTIPLE_OBJECT_PROGRESS.EVENTS,
  payload
})
