import {
    TEXT,
} from '@/constants/semanticNames'

export default [
    {
        selector: 'GENERAL_INFORMATION.NAME',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'GENERAL_INFORMATION',
    },
    {
        selector: 'GENERAL_INFORMATION.IDENTIFIER',
        title: 'someTitle',
        type: TEXT,
        scheme: null,
        placeholder: 'Пример текста',
        catalogSelector: 'GENERAL_INFORMATION',
        immutable: true,
    },
]
