import {
  takeLatest, put
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_LIVE_TIME_REPORT_OBJECT_TYPES } from '@/store/actions/lifeTimeReport'
import { GET_LIVE_TIME_OBJECT_TYPES_URL } from '@/constants/apiRoutes'
import {
  errorLiveTimeReportObjectTypes,
  successLiveTimeReportObjectTypes
} from '@/store/actions/lifeTimeReport/getObjectTypes'
import { TYPE_TO_NAME } from '@/constants/maps'
import { sortOptionsByTitle } from '@/helpers/sortOptionsByTitle'
import createNotifications from '@/helpers/notification'

function* getLiveTimeReportObjectTypesSaga() {
  try {
    const response = yield request({
      url: GET_LIVE_TIME_OBJECT_TYPES_URL,
      method: 'get',
    })

    const objectTypes = response.data.map(item => {
      return {
        title: TYPE_TO_NAME[item.objectType],
        value: item.objectType,
      }
    })
    const sortedObjectTypes = sortOptionsByTitle(objectTypes)

    yield put(successLiveTimeReportObjectTypes(sortedObjectTypes))
  } catch (error) {
    yield put(errorLiveTimeReportObjectTypes(error))

    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось получить данные для фильтра.\nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_LIVE_TIME_REPORT_OBJECT_TYPES.REQUEST, getLiveTimeReportObjectTypesSaga)
}
