import {
    TEXT
  } from '@/constants/semanticNames'
  
  export default [
    {
      selector: 'LOCATION.PROJECT',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'LOCATION',
    },
    {
      selector: 'LOCATION.LATITUDE',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'LOCATION',
      pattern: '[0-9.+-]',
    },
    {
      selector: 'LOCATION.LONGITUDE',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'LOCATION',
      pattern: '[0-9.+-]',
    },
    {
      selector: 'LOCATION.REGION',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'LOCATION',
    },
    {
      selector: 'LOCATION.CITY',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'LOCATION',
    },
    {
      selector: 'LOCATION.STREET',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'LOCATION',
    },
    {
      selector: 'LOCATION.HOME_NUMBER',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'LOCATION',
      pattern: '[0-9]',
    },
  
  
  
    {
      selector: 'OBJECT_CHARACTERISTIC.INSTALLATION_TYPE',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'OBJECT_CHARACTERISTIC',
    },
    {
      selector: 'OBJECT_CHARACTERISTIC.LOCATION_TYPE',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'OBJECT_CHARACTERISTIC',
    },
    {
      selector: 'OBJECT_CHARACTERISTIC.OUTGOING_LINES_NUMBER',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'OBJECT_CHARACTERISTIC',
    },
    {
      selector: 'OBJECT_CHARACTERISTIC.CONNECTED_FIXTURES_NUMBER',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'OBJECT_CHARACTERISTIC',
    },
  
  
    {
      selector: 'MANAGEMENT_ORGANIZATION_SYSTEM.PRESENCE_BASE_STATION',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'MANAGEMENT_ORGANIZATION_SYSTEM',
    },
    {
      selector: 'MANAGEMENT_ORGANIZATION_SYSTEM.CONTROLLER_MODEL',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'MANAGEMENT_ORGANIZATION_SYSTEM',
    },
    {
      selector: 'MANAGEMENT_ORGANIZATION_SYSTEM.CONTROLLER_SERIAL_NUMBER',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'MANAGEMENT_ORGANIZATION_SYSTEM',
    },
    {
      selector: 'MANAGEMENT_ORGANIZATION_SYSTEM.SWVER',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'MANAGEMENT_ORGANIZATION_SYSTEM',
    },
    {
      selector: 'MANAGEMENT_ORGANIZATION_SYSTEM.ICCID1',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'MANAGEMENT_ORGANIZATION_SYSTEM',
    },
    {
      selector: 'MANAGEMENT_ORGANIZATION_SYSTEM.ICCID2',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'MANAGEMENT_ORGANIZATION_SYSTEM',
    },
    {
      selector: 'MANAGEMENT_ORGANIZATION_SYSTEM.TIME_ZONE',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'MANAGEMENT_ORGANIZATION_SYSTEM',
    },
  
  
    {
      selector: 'SERVICING.PLAN_PPR_DATE',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'SERVICING',
      mask: '99.99.9999',
    },
    {
      selector: 'SERVICING.FACT_PPR_DATE',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'SERVICING',
      mask: '99.99.9999',
    },
    {
      selector: 'SERVICING.MODERNIZATION',
      title: 'someTitle',
      type: TEXT,
      scheme: null,
      placeholder: 'Пример текста',
      catalogSelector: 'SERVICING',
    },
  ]
  