import React, { useEffect, useState, useMemo, useCallback } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import useReduxTable from '@/hooks/useReduxTable'
import {
  fieldsMobile,
  fields,
  exportOptions,
} from '@/constants/tablesConfig/analyticsAlarms'
import debounce from 'lodash/debounce'
import {
  DEBOUNCE_DELAY_LARGE,
} from '@/constants/time'
import Loader from '@/components/blocks/Loader'
import DutyTable from '@/components/blocks/DutyTable'
import { ANALYTICS_ALARMS_TABLE } from '@/store/actions/analyticsAlarms'
import { getTableParameters } from '@/store/selectors/analyticsAlarms'
import useGetIsMobile from '@/hooks/useGetIsMobile'
import HeaderMobile from '../SummaryAlarmsAnalytics/HeaderMobile/component'
import ObjectCard from '@/components/blocks/ObjectCard'
import CoreIcon from '@/components/icons/core'
import HeaderIcons from '@/components/icons/header'
import EventFilterForm from '@/forms/EventFilterForm'
import SortShutter from '@/components/blocks/SortShutter'
import { Main } from '../SummaryAlarmsAnalytics/styles'
import EmptyDataState from "components/blocks/EmptyDataState";



import AlarmSideBar from './components/AlarmSideBar'
import REQUEST_STATUSES from "@/constants/requests";
import { isEmpty } from 'lodash'

const AlarmsAnalytics = ({
  data,
  exportFileStatus,
  selectedElement,
  checkBoxOption,
  selectedNode,
  loading,
  requestState,
  globalLoading,
  dataSummary,
  eventFilterValues,
  filterOptions,

  setEventTypes,
  setStatusTypes,
  setSelectedElement,
  requestAnalyticsAlarmsTable,
  requestAlarmManagerFile,
  setSideBarFilter,
  requestSummaryAlarmAnalyticsTable,
  searchFieldsOptions,
  requestGetSearchFieldsOptions,
  connectedIntegrations,
  requestGetAllConnectedIntegrations,
  setFilterValues,
  resetFilterValues,
  searchFieldsOptionsLoading,
}) => {
  const [isOpen, setIsOpen] = useState(true)
  const isMobile = useGetIsMobile()
  const [isOpenPopup, setIsOpenPopup] = useState(false)
  const [openTypePopup, setOpenTypePopup] = useState(null)
  const [typeSort, setTypeSort] = useState('name')

  useEffect(() => {
    if (requestState.tree === REQUEST_STATUSES.IDLE) {
      requestAnalyticsAlarmsTable()
      requestSummaryAlarmAnalyticsTable()
    }
  }, [requestAnalyticsAlarmsTable, requestState, requestSummaryAlarmAnalyticsTable])

  const tableProps = useReduxTable(ANALYTICS_ALARMS_TABLE, getTableParameters)

  const {actions, values} = tableProps

  const onChangeSearch = useCallback(debounce((value) => {
    actions.setRequestQuery(value)
  }, DEBOUNCE_DELAY_LARGE), [])

  const onChangeInput = (value) => {
    actions.setQuery(value)
    onChangeSearch(value)
  }

  const resetSearch = () => {
    actions.setQuery('')
    actions.setRequestQuery('')
  }

  const toggleFilterPopup = () => {
    setIsOpenPopup(!isOpenPopup)
    setOpenTypePopup('filter')
  }

  const toggleSortPopup = () => {
    setIsOpenPopup(!isOpenPopup)
    setOpenTypePopup('sort')
  }

  const onClose = () => {
    // clearTable()
  }

  const onSetValues = (form) => {
    setIsOpenPopup(false)
    setFilterValues(form)
  }

  const onResetValues = () => {
    setIsOpenPopup(false)
    resetFilterValues()
  }

  const onChange = (value) => {
    if (value === 'all') {
      setSideBarFilter(
        { ERROR: true, NORMAL: true, WARNING: true, UNDEFINED: true }
      )
    } else setSideBarFilter(
      {...{ ERROR: false, NORMAL: false, WARNING: false, UNDEFINED: false }, [value]: true}
    )
  }

  const controls = [
    {
      icon: <CoreIcon.SortIcon />,
      name: 'CoreIcon.SortIcon',
      onClick: toggleSortPopup,
      onClose: toggleSortPopup,
    },
    {
      icon: isEmpty(filterOptions) 
      ? <HeaderIcons.GlobalFilterIcon /> 
      : <HeaderIcons.ActiveGlobalFilterIcon />,
      name: 'HeaderIcons.GlobalFilterIcon',
      onClick: toggleFilterPopup,
    }
  ]

    const contentPopup = useMemo(() => {
      if (openTypePopup === 'filter') {
        return (
          <EventFilterForm
            globalFiltersValues={filterOptions}
            onSetValues={onSetValues}
            onResetValues={onResetValues}
            onClose={toggleFilterPopup}
            requestGetSearchFieldsOptions={requestGetSearchFieldsOptions}
            searchFieldsOptions={searchFieldsOptions}
            connectedIntegrations={connectedIntegrations}
            requestGetAllConnectedIntegrations={requestGetAllConnectedIntegrations}
            disabled
            withoutAlerTypes
            searchFieldsOptionsLoading={searchFieldsOptionsLoading}
          />)
      }
      if (openTypePopup === 'sort') {
        return (
          <SortShutter 
            type={'name'} 
            title={'Сортировка событий:'} 
            onClose={toggleSortPopup} 
            withName 
            {...tableProps} 
            setIsOpenPopup={setIsOpenPopup}
            typeSort={typeSort}
            setTypeSort={setTypeSort}
            />
        )
      }
    }, [
        openTypePopup, 
        eventFilterValues, 
        connectedIntegrations, 
        onResetValues, 
        onSetValues, 
        requestGetAllConnectedIntegrations, 
        requestGetSearchFieldsOptions, 
        searchFieldsOptions,
        filterOptions,
        tableProps,
        typeSort,
        searchFieldsOptionsLoading
      ])

  if (isMobile) {
    return (
      <Main>
        <HeaderMobile 
          title={selectedNode.name} 
          checkBoxOption={checkBoxOption} 
          controls={controls} 
          onChange={onChange}
          onChangeInput={onChangeInput}
          resetSearch={resetSearch}
          onClose={onClose} 
          content={contentPopup} 
          openPopup={isOpenPopup} 
          opacyti={true} 
          data={dataSummary.filter((zone) => zone.name === selectedNode.name)} 
          buttonBack
          searchActive
          value={values.query}
          {...tableProps}
        />
        {requestState.tree === REQUEST_STATUSES.PENDING || globalLoading 
        ? 
          <Loader center />
        : 
        (!data.length) ? <EmptyDataState /> : <ObjectCard data={data} fields={fieldsMobile}/>
        }
      </Main>
    )
  }

  return (
    <>
      <AlarmSideBar
        isOpen={isOpen}
        toggleIsOpen={setIsOpen}
        selectedNodeName={selectedNode.name}
        selectedElement={selectedElement}
        setSelectedElement={setSelectedElement}
        setSideBarFilter={setSideBarFilter}
        checkBoxOption={checkBoxOption}
      />
      {loading
        ? <Loader center />
        : (
          <DutyTable
            {...tableProps}
            fields={fields}
            exportOptions={exportOptions}
            data={data}
            rowSelector={setSelectedElement}
            setEventTypes={setEventTypes}
            setStatusTypes={setStatusTypes}
            onUpdateData={requestAnalyticsAlarmsTable}
            onFileSelect={requestAlarmManagerFile}
            fileStatus={exportFileStatus}
            isDataLoading={globalLoading}
          />
        )}
    </>
  )
}

AlarmsAnalytics.propTypes = {
  data: pt.arrayOf(pt.object),
  checkBoxOption: pt.arrayOf(pt.object),
  selectedNode: pt.objectOf(pt.object),
  exportFileStatus: pt.string,
  selectedElement: pt.objectOf(pt.object),
  loading: pt.bool,

  setSelectedElement: pt.func,
  setSideBarFilter: pt.func,
  setEventTypes: pt.func,
  setStatusTypes: pt.func,
  requestAnalyticsAlarmsTable: pt.func,
  requestAlarmManagerFile: pt.func,
}
AlarmsAnalytics.defaultProps = {
  selectedNode: {},
  data: [],
  loading: false,
  checkBoxOption: [],
  selectedElement: null,
  exportFileStatus: '',

  setSelectedElement: noop,
  setSideBarFilter: noop,
  setEventTypes: noop,
  setStatusTypes: noop,
  requestAnalyticsAlarmsTable: noop,
  requestAlarmManagerFile: noop,
}

export default AlarmsAnalytics
