import { all } from 'redux-saga/effects'

import getTable from './getTable'
import getNode from './getNode'
import getFile from './getFile'

export default function* root(){
    yield all([
        getTable(),
        getNode(),
        getFile(),
    ])
}