import React from 'react'
import { Field } from 'formik'
import DatePicker from '@/components/controls/DatePicker'
import get from 'lodash/get'
import moment from 'moment'

const DatePickerField = ({ onAfterChange, error, errorMessage, ...ownProps}) => (
  <Field {...ownProps}>
    {({ field, form }) => {
      const onChange = (value) => {
        const dateString = moment(value).format('DD.MM.YYYY')
        if (value && !/^(0[1-9]|[12][0-9]|3[01])[.](0[1-9]|1[012])[.]([2-9][0-9][2-9])\d$/.test(dateString)) {
          form.setFieldValue(field.name, null)
          return
        }
        form.setFieldValue(field.name, value)
        if (onAfterChange) {
          onAfterChange(field.name, value)
        }
      }
      return (
        <DatePicker
          {...ownProps}
          value={field.value}
          name={field.name}
          errorMessage={errorMessage}
          error={get(form, `errors.${field.name}`, false)}
          onChange={onChange}
        />
      )
    }}
  </Field>
)

export default React.memo(DatePickerField)
