import styled from 'styled-components'

export default styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
  align-items: center;
  color:${({ theme, isError }) => (isError
    ? theme.colors.messages.error
    : theme.colors.colors.default)};
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 3rem;
  ${({ userForm }) => userForm && `
    font-weight: 500;
    font-size: 1.2rem;
    line-height: 1.4rem;
    margin-bottom: 5px;
  `}
  svg {
    height: 18px;
    width: 18px;
    margin-right: 12px;
  }
`
