import React, { useMemo, } from 'react'
import {
    DescriptionWrapper,
    IndicatorWrapper,
    Status,
    Title
} from '@/components/widgets/StateIndicator/styles'
import pt from 'prop-types'
import memoWithName from '@/hocs/memoWithName'
import { FormattedMessage as Lang } from 'react-intl'

const ConnectedStatusIndicator = ({
  color,
  online,
  className,
}) => {
    const renderIconInfo = useMemo(() => {
        return (
            <>
                <Title online>
                    <Lang id="menu.communicationStatus" />:
                </Title>
                <Status spaceBetween color={color}>{online
                    ? <Lang id="menu.on" />
                    : <Lang id="menu.off" />
                }</Status>
            </>
        )
    }, [color, online])
    return (
        <IndicatorWrapper
            column
            className={className}
        >
            <DescriptionWrapper row>
                {renderIconInfo}
            </DescriptionWrapper>
        </IndicatorWrapper>
    )
}

ConnectedStatusIndicator.propTypes = {
    online: pt.bool,
    status: pt.bool,
    className: pt.string,
    color: pt.string,
}
ConnectedStatusIndicator.defaultProps = {
    online: false,
    status: false,
    workTime: 0,
    className: '',
    color: '',
}

export default memoWithName(ConnectedStatusIndicator)