import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getAlarmManager = createSelector(
  getState,
  (state) => get(state, 'customWidgetsData', {}),
)

export const getWidgetsData = createSelector(
  getAlarmManager,
  (state) => get(state, 'data', {}),
)

export const getWidgetsStatus = createSelector(
  getAlarmManager,
  (state) => get(state, 'status', ''),
)

export const getLoader = createSelector(
  getAlarmManager,
  (state) => get(state, 'loader', ''),
)
