import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export default [
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.NAME" />,
        showInDashboard: true,
        selector: 'name',
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.IDENTIFIER" />,
        showInDashboard: true,
        selector: 'IDENTIFIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.EQUIPMENT_TYPE" />,
        showInDashboard: true,
        selector: 'EQUIPMENT_TYPE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.LAMP_TYPE" />,
        showInDashboard: true,
        selector: 'LAMP_TYPE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.MODEL_MARK" />,
        showInDashboard: true,
        selector: 'MODEL_MARK',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PROCEDURE_SUPPLIER" />,
        showInDashboard: true,
        selector: 'PROCEDURE_SUPPLIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.LIGHTING_CONTROL_BOX_IDENTIFIER" />,
        showInDashboard: true,
        selector: 'LIGHTING_CONTROL_BOX_IDENTIFIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.BASE_STATION_IDENTIFIER" />,
        showInDashboard: true,
        selector: 'BASE_STATION_IDENTIFIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.UTILITY_POLE_IDENTIFIER" />,
        showInDashboard: true,
        selector: 'UTILITY_POLE_IDENTIFIER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.REGISTRY_NUMBER" />,
        showInDashboard: true,
        selector: 'UTILITY_POLE_IDENTIFIER',
        isCard: true
    },


    {
        passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.WORKING_TEMPERATURE_RANGE" />,
        showInDashboard: true,
        selector: 'WORKING_TEMPERATURE_RANGE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.POWER_CONSUMPTION" />,
        showInDashboard: true,
        selector: 'POWER_CONSUMPTION',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.WORKING_VOLTAGE_RANGE" />,
        showInDashboard: true,
        selector: 'WORKING_VOLTAGE_RANGE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.LIGHT_OUTPUT" />,
        showInDashboard: true,
        selector: 'LIGHT_OUTPUT',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.COLOUR_TEMPERATURE" />,
        showInDashboard: true,
        selector: 'COLOUR_TEMPERATURE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.COLOUR_RENDERING_INDEX" />,
        showInDashboard: true,
        selector: 'COLOUR_RENDERING_INDEX',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.LAMP_EFFICIENCY" />,
        showInDashboard: true,
        selector: 'LAMP_EFFICIENCY',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.SCATTERING_ANGLE" />,
        showInDashboard: true,
        selector: 'SCATTERING_ANGLE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.LIGHT_INTENSITY_DISTRIBUTION_CURVE_TYPE" />,
        showInDashboard: true,
        selector: 'LIGHT_INTENSITY_DISTRIBUTION_CURVE_TYPE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.FLICKER_PERCENT" />,
        showInDashboard: true,
        selector: 'FLICKER_PERCENT',
        isCard: true
    },


    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.START_DATE" />,
        showInDashboard: true,
        selector: 'START_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.EXPLOITATION_END_DATE" />,
        showInDashboard: true,
        selector: 'EXPLOITATION_END_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.ORGANIZATION" />,
        showInDashboard: true,
        selector: 'ORGANIZATION',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.OWNER" />,
        showInDashboard: true,
        selector: 'OWNER',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.GUARANTEE_PERIOD" />,
        showInDashboard: true,
        selector: 'GUARANTEE_PERIOD',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.EXPLOITATION.LIFE_TIME" />,
        showInDashboard: true,
        selector: 'LIFE_TIME',
        isCard: true
    },


    {
        passportParameter: <Lang id="passport.fields.LOCATION.PROJECT" />,
        showInDashboard: true,
        selector: 'PROJECT',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.LATITUDE" />,
        showInDashboard: true,
        selector: 'latitude',
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.LONGITUDE" />,
        showInDashboard: true,
        selector: 'longitude',
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.REGION" />,
        showInDashboard: true,
        selector: 'REGION',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.LOCATION.CITY" />,
        showInDashboard: true,
        selector: 'CITY',
        isCard: true
    },


    {
        passportParameter: <Lang id="passport.fields.OBJECT_CHARACTERISTIC.LIGHTING_CATEGORY" />,
        showInDashboard: true,
        selector: 'LIGHTING_CATEGORY',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.OBJECT_CHARACTERISTIC.IMPORTANCE" />,
        showInDashboard: true,
        selector: 'IMPORTANCE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.OBJECT_CHARACTERISTIC.WORK_MODE" />,
        showInDashboard: true,
        selector: 'WORK_MODE',
        isCard: true
    },


    {
        passportParameter: <Lang id="passport.fields.SURVEY_DATA.LAMP_INSTALLATION_HEIGHT" />,
        showInDashboard: true,
        selector: 'LAMP_INSTALLATION_HEIGHT',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SURVEY_DATA.OVERHANG_HEIGHT" />,
        showInDashboard: true,
        selector: 'OVERHANG_HEIGHT',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SURVEY_DATA.OVERHANG_LENGTH" />,
        showInDashboard: true,
        selector: 'OVERHANG_LENGTH',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SURVEY_DATA.UTILITY_POLE_LAMP_POSITION" />,
        showInDashboard: true,
        selector: 'UTILITY_POLE_LAMP_POSITION',
        isCard: true
    },


    {
        passportParameter: <Lang id="passport.fields.SERVICING.PLAN_PPR_DATE" />,
        showInDashboard: true,
        selector: 'PLAN_PPR_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.FACT_PPR_DATE" />,
        showInDashboard: true,
        selector: 'FACT_PPR_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.INSPECTION_DATE" />,
        showInDashboard: true,
        selector: 'INSPECTION_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.BYPASS_DATE" />,
        showInDashboard: true,
        selector: 'BYPASS_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.MAINTENANCE_DATE" />,
        showInDashboard: true,
        selector: 'MAINTENANCE_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.REPAIR_DATE" />,
        showInDashboard: true,
        selector: 'REPAIR_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.CAPITAL_REPAIR_DATE" />,
        showInDashboard: true,
        selector: 'CAPITAL_REPAIR_DATE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.SERVICING.GUARANTEE_EXPIRATION_DATE" />,
        showInDashboard: true,
        selector: 'GUARANTEE_EXPIRATION_DATE',
        isCard: true
    },


    {
        passportParameter: <Lang id="passport.fields.ATTACHMENT.PHOTO_TITLE" />,
        showInDashboard: true,
        selector: 'PHOTO_TITLE',
        isCard: true
    },
    {
        passportParameter: <Lang id="passport.fields.ATTACHMENT.DOCUMENT_TITLE" />,
        showInDashboard: true,
        selector: 'DOCUMENT_TITLE',
        isCard: true
    },
]