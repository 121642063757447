import React, { useCallback } from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import pt from 'prop-types'
import noop from 'lodash/noop'
import DatePicker from 'react-datepicker'
import ru from 'date-fns/locale/ru'
import enGB from 'date-fns/locale/en-GB'
import getYear from 'date-fns/getYear'
import getMonth from 'date-fns/getMonth'
import { DATE_FORMAT } from '@/constants/forms/analyticExpenses'
import SelectControl from '@/components/controls/SelectControl'
import MapIcons from '@/components/icons/maps'
import 'react-datepicker/dist/react-datepicker.css'
import { injectIntl } from 'react-intl'

import {
  yearsOptions,
  monthsOptions,
} from './config'
import {
  FieldContainer,
  HeaderContainer,
  IconContainer,
  SelectContainer,
  Error
} from './styles'

const CustomDatePicker = ({
  format,
  value,
  onChange,
  maxDate,
  minDate,
  excludeDates,
  disabled,
  error,
  errorMessage,
  isClearable,
  intl,
  showTimeSelect,
  formatWithTime,
  ...props
}) => {
  const renderCustomHeader = useCallback(({
    date,
    changeYear,
    changeMonth,
    decreaseMonth,
    increaseMonth,
  }) => (
    <HeaderContainer>
      <IconContainer>
        <MapIcons.ArrowIcon onClick={decreaseMonth} />
      </IconContainer>
      <SelectContainer>
        <SelectControl
          value={getYear(date)}
          onChange={(year) => changeYear(year)}
          options={yearsOptions}
        />
      </SelectContainer>
      <SelectContainer>
        <SelectControl
          value={monthsOptions[getMonth(date)].title}
          onChange={(month) => changeMonth(month)}
          options={monthsOptions}
        />
      </SelectContainer>
      <IconContainer rotate>
        <MapIcons.ArrowIcon onClick={increaseMonth} />
      </IconContainer>
    </HeaderContainer>
  ), [])
  return (
    <>
      <FieldContainer {...props} isError={!!error || errorMessage}>
        <label>
          <DatePicker
              renderCustomHeader={renderCustomHeader}
              showMonthDropdown
              showYearDropdown
              showTimeSelect={showTimeSelect}
              dateFormat={format}
              locale={intl.locale === 'ru' ? ru : enGB}
              selected={value}
              onChange={onChange}
              maxDate={maxDate}
              minDate={minDate}
              excludeDates={excludeDates}
              disabled={disabled}
              isClearable={true}
              placeholderText={intl.messages[`datePicker.labels.${formatWithTime ? 'formatWithTime' : 'format' }`]}
              showIcon
          />
        </label>
      </FieldContainer>
      {error && (<Error><Lang id={error} /></Error>)}
    </>
  )
}

CustomDatePicker.propTypes = {
  format: pt.string,
  value: pt.string,
  onChange: pt.func,
}
CustomDatePicker.defaultProps = {
  format: DATE_FORMAT,
  value: null,
  onChange: noop,
}

export default injectIntl(CustomDatePicker)
