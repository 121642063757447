import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export default [
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.NAME" />,
    showInDashboard: true,
    selector: 'name',
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.IDENTIFIER" />,
    showInDashboard: true,
    selector: 'IDENTIFIER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.EQUIPMENT_TYPE" />,
    showInDashboard: true,
    selector: 'EQUIPMENT_TYPE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.MODEL_MARK" />,
    showInDashboard: true,
    selector: 'MODEL_MARK',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PRODUCER" />,
    showInDashboard: true,
    selector: 'PRODUCER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.SUPPLIER" />,
    showInDashboard: true,
    selector: 'SUPPLIER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.ROOT_OBJECT" />,
    showInDashboard: true,
    selector: 'ROOT_OBJECT',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PARENT_TYPE" />,
    showInDashboard: true,
    selector: 'PARENT_TYPE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.PARENT_IDENTIFIER" />,
    showInDashboard: true,
    selector: 'PARENT_IDENTIFIER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.REGISTRY_NUMBER" />,
    showInDashboard: true,
    selector: 'REGISTRY_NUMBER',
    isCard: true
  },



  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.LAMP_TYPE" />,
    showInDashboard: true,
    selector: 'LAMP_TYPE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.HOUSING_MATERIAL" />,
    showInDashboard: true,
    selector: 'HOUSING_MATERIAL',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.STANDARD_COLOR_RAL" />,
    showInDashboard: true,
    selector: 'STANDARD_COLOR_RAL',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.WORKING_TEMPERATURE_RANGE" />,
    showInDashboard: true,
    selector: 'WORKING_TEMPERATURE_RANGE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.WORKING_VOLTAGE_RANGE" />,
    showInDashboard: true,
    selector: 'WORKING_VOLTAGE_RANGE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.POWER_CONSUMPTION" />,
    showInDashboard: true,
    selector: 'POWER_CONSUMPTION',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.LIGHT_OUTPUT" />,
    showInDashboard: true,
    selector: 'LIGHT_OUTPUT',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.COLOUR_TEMPERATURE" />,
    showInDashboard: true,
    selector: 'COLOUR_TEMPERATURE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.COLOUR_RENDERING_INDEX" />,
    showInDashboard: true,
    selector: 'COLOUR_RENDERING_INDEX',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.LIGHT_INTENSITY_DISTRIBUTION_CURVE_TYPE" />,
    showInDashboard: true,
    selector: 'LIGHT_INTENSITY_DISTRIBUTION_CURVE_TYPE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.TECHNICAL_CONSTRAINTS.FLICKER_PERCENT" />,
    showInDashboard: true,
    selector: 'FLICKER_PERCENT',
    isCard: true
  },


  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.START_DATE" />,
    showInDashboard: true,
    selector: 'START_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.EXPLOITATION_END_DATE" />,
    showInDashboard: true,
    selector: 'EXPLOITATION_END_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.ORGANIZATION" />,
    showInDashboard: true,
    selector: 'ORGANIZATION',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.OWNER" />,
    showInDashboard: true,
    selector: 'OWNER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.BALANCE_SHEET_HOLDER" />,
    showInDashboard: true,
    selector: 'BALANCE_SHEET_HOLDER',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.GUARANTEE_PERIOD" />,
    showInDashboard: true,
    selector: 'GUARANTEE_PERIOD',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.LIGHT_FIXTURE_WORK_RESOURCE" />,
    showInDashboard: true,
    selector: 'LIGHT_FIXTURE_WORK_RESOURCE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.PRODUCER_WARRANTY_EXPIRATION_DATE" />,
    showInDashboard: true,
    selector: 'PRODUCER_WARRANTY_EXPIRATION_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.SUPPLIER_WARRANTY_EXPIRATION_DATE" />,
    showInDashboard: true,
    selector: 'SUPPLIER_WARRANTY_EXPIRATION_DATE',
    isCard: true
  },


  {
    passportParameter: <Lang id="passport.fields.LOCATION.PROJECT" />,
    showInDashboard: true,
    selector: 'PROJECT',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.LOCATION.LATITUDE" />,
    showInDashboard: true,
    selector: 'latitude',
  },
  {
    passportParameter: <Lang id="passport.fields.LOCATION.LONGITUDE" />,
    showInDashboard: true,
    selector: 'longitude',
  },
  {
    passportParameter: <Lang id="passport.fields.LOCATION.REGION" />,
    showInDashboard: true,
    selector: 'REGION',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.LOCATION.CITY" />,
    showInDashboard: true,
    selector: 'CITY',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.LOCATION.STREET" />,
    showInDashboard: true,
    selector: 'STREET',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.LOCATION.HOME_NUMBER" />,
    showInDashboard: true,
    selector: 'HOME_NUMBER',
    isCard: true
  },



  {
    passportParameter: <Lang id="passport.fields.OBJECT_CHARACTERISTIC.LOCATION_TYPE" />,
    showInDashboard: true,
    selector: 'LOCATION_TYPE',
    isCard: true
  },


  {
    passportParameter: <Lang id="passport.fields.SERVICING.PLAN_PPR_DATE" />,
    showInDashboard: true,
    selector: 'PLAN_PPR_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SERVICING.FACT_PPR_DATE" />,
    showInDashboard: true,
    selector: 'FACT_PPR_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.SERVICING.MODERNIZATION" />,
    showInDashboard: true,
    selector: 'MODERNIZATION',
    isCard: true
  },
]
