import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export const StateOptions = [
  {
   title: <Lang id="objectsGroups.control.on" />,
   value: '100'
  },
  {
   title: <Lang id="objectsGroups.control.off" />,
   value: '0'
  },
]