import {
  put, takeLatest, select,
} from 'redux-saga/effects'
import {
  START_SET_WIDGET_SETTINGS,
  updateWidgetsList,
  updateApplicationsList,
} from '@/store/actions/appSettings'
import {
  WIDGET_SHOP_APP,
  WIDGET_SHOP_WIDGET,
} from '@/constants/names'
import { getWidgets, getApplications } from '@/store/selectors/appSettings'
import createNotifications from "@/helpers/notification";

function* setSettings({
  payload: {
    id, settings, widgetType,
  },
}) {
  try {
    if (widgetType === WIDGET_SHOP_APP) {
      const widgets = yield select(getApplications)
      const updatedWidgets = widgets.map((widget) => {
        if (widget.id === id) {
          return ({
            ...widget,
            settings,
            isSettingSet: true,
          })
        }
        return widget
      })
      yield put(updateApplicationsList(updatedWidgets))
      return null
    }
    if (widgetType === WIDGET_SHOP_WIDGET) {
      const widgets = yield select(getWidgets)
      const updatedWidgets = widgets.map((widget) => {
        if (widget.id === id) {
          return ({
            ...widget,
            settings,
            isSettingSet: true,
          })
        }
        return widget
      })
      yield put(updateWidgetsList(updatedWidgets))
      return null
    }
  } catch (error) {
    console.log('TCL: function*addWidgetToGeneralPage -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
  return null
}

export default function* () {
  yield takeLatest(START_SET_WIDGET_SETTINGS, setSettings)
}
