import { GET_PPR_FILE } from './index'

export const requestPprFile = (payload) => ({
  type: GET_PPR_FILE.REQUEST,
  payload,
})

export const successPprFile = (payload) => ({
  type: GET_PPR_FILE.SUCCESS,
  payload,
})

export const errorPprFile = (payload) => ({
  type: GET_PPR_FILE.ERROR,
  payload,
})
