import { takeLatest } from 'redux-saga/effects'
import request from '@/helpers/axios'
import { RESET_SCHEDULE_URL } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { RESET_SCHEDULE } from '@/store/actions/objectsGroups'

function* resetSchedule({ payload }) {
  const toast = createNotifications()
  try {
    const { scheduleId, justificationComment } = payload
    const { data } = yield request({
      url: RESET_SCHEDULE_URL({
        id: scheduleId,
        params: {
          justificationComment
        },
      }),
      method: 'patch',
    })
    if (data) {
      toast({
        title: 'Отключение расписания',
        type: 'success',
        description: 'Расписание успешно отключено.',
      })
    }
  } catch (error) {
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось отключить расписание. \nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* () {
  yield takeLatest(RESET_SCHEDULE.REQUEST, resetSchedule)
}
