import React, { useState } from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import UsersIcons from '@/components/icons/users'
import CoreIcons from '@/components/icons/core'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import ControlsBlock from '@/components/blocks/HeaderPageMobile/ControlsBlock'
import HeaderIcons from '@/components/icons/header'
import BackIcon from '@/components/icons/maps/BackIcon'



import {
  HeaderContainer,
  IconContainer,
  Title,
  Container,
  TitleAndIconContainer,
  ObjectTitle,
  ObjectNameRow,
  ControlsContainer,
  HeaderPassport
} from './styles'

const PassportHeader = ({
  name,
  isSettingsOpen,
  toggleSettings,
  dirty,
  handleResetForm,
  requestAddPassportFile,
  setEditForm,
  submitForm,
  editPassport,
  selectedNode,
  isMobile,
  onClosePassport,
  handlePinIconClick,
  pinnedNode
}) => {

  const [wasSubmitted, setWasSubmitted] = useState(false)

  const toggleSettingsHandler = () => {
    if (!editPassport) return
    toggleSettings(!isSettingsOpen)
  }

  const onSubmitHandler = () => {
    if (!wasSubmitted) {
      setWasSubmitted(true)
      requestAddPassportFile()
      submitForm()
    }
  }

  const cancelEditHandler = () => {
    handleResetForm()
    setEditForm(false)
  }

  const startEditHandler = () => {
    setWasSubmitted(false)
    if (!editPassport) return
    setEditForm(true)
  }

  const filters = [
    {
      icon: pinnedNode && pinnedNode.id
        ? <HeaderIcons.SelectedPinIcon />
        : <HeaderIcons.NoSelectedPinIcon />,
      name: 'HeaderIcons.SelectedPinIcon',
      onClick: handlePinIconClick,
    },
    {
      icon: <CoreIcons.GearIcon />,
      name: "tooltip.fieldSettings",
      onClick: toggleSettingsHandler
    },
    {
      icon: <CoreIcons.EditIcon />,
      name: "tooltip.edit",
      onClick: startEditHandler
    }
  ] 

  if (dirty) {
    return (
      <HeaderContainer>
        <Title>
          <Lang id="passportization.changeTitle" />
        </Title>
        <Container>
          <IconContainer
            type="green"
            onClick={onSubmitHandler}
            border="right"
          >
            <Lang id="passportization.passportSidebar.yes" />
          </IconContainer>
          <IconContainer
            type="red"
            onClick={cancelEditHandler}
            border="left"
          >
            <Lang id="passportization.passportSidebar.no" />
          </IconContainer>
        </Container>
      </HeaderContainer>
    )
  }

  if (isMobile) {
    if (isSettingsOpen) {
      return (
        <HeaderContainer>
            <Title>
              {name}
            </Title>
            <PortalTooltip
              title={(<Lang id="tooltip.close" />)}
              renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                <IconContainer
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                  onClick={toggleSettingsHandler}
                >
                  <UsersIcons.CrossIcon />
                </IconContainer>
              )}
            />
        </HeaderContainer>
      )
    }
    return (
      <HeaderPassport>
        <ObjectNameRow>
          <BackIcon color={'#1061DF'} onClick={onClosePassport}/>
          <ObjectTitle>
            {name}
          </ObjectTitle>
          <ControlsContainer>
            <ControlsBlock
              controls={filters}
              spaced
            />
        </ControlsContainer>
        </ObjectNameRow>
      </HeaderPassport>
    )
  }
  return (
    <HeaderContainer>
      {!isSettingsOpen
        ? (
          <>
            <TitleAndIconContainer>
              <PortalTooltip
                title={(<Lang id="tooltip.fieldSettings" />)}
                renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                  <IconContainer
                    ref={wrapperRef}
                    onMouseEnter={onMouseEnterHandler}
                    onMouseLeave={onMouseLeaveHandler}
                    onClick={toggleSettingsHandler}
                    disabled ={!editPassport}
                  >
                    <CoreIcons.GearIcon />
                  </IconContainer>
                )}
              />
              <Title>
                {name}
              </Title>
            </TitleAndIconContainer>
            <PortalTooltip
              title={editPassport && (<Lang id="tooltip.edit" />)}
              renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                <IconContainer
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                  onClick={startEditHandler}
                  disabled ={!editPassport}
                >
                  <CoreIcons.EditIcon />
                </IconContainer>
              )}
            />
          </>
        )
        : (
          <>
            <Title>
              {name}
            </Title>
            <PortalTooltip
              title={(<Lang id="tooltip.close" />)}
              renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                <IconContainer
                  ref={wrapperRef}
                  onMouseEnter={onMouseEnterHandler}
                  onMouseLeave={onMouseLeaveHandler}
                  onClick={toggleSettingsHandler}
                >
                  <UsersIcons.CrossIcon />
                </IconContainer>
              )}
            />
          </>
        )}
    </HeaderContainer>
  )
}


PassportHeader.propTypes = {
  name: pt.string,
  openedPage: pt.string,
  isSettingsOpen: pt.bool,
  isParentPassportOpen: pt.bool,
  noOptions: pt.bool,
  toggleSettings: pt.func,
  setOperatedElement: pt.func,
  setOpenedPage: pt.func,
  setEditForm: pt.func,
  submitForm: pt.func,
  setIsParentPassportOpen: pt.func,
  selectedNode: pt.objectOf(pt.object),
}


PassportHeader.defaultProps = {
  name: '',
  isSettingsOpen: false,
  isParentPassportOpen: false,
  noOptions: false,
  toggleSettings: noop,
  setOperatedElement: noop,
  setOpenedPage: noop,
  setEditForm: noop,
  setIsParentPassportOpen: noop,
  submitForm: noop,
  selectedNode: {},
  openedPage: '',
}

export default PassportHeader
