import styled from 'styled-components'

export const PopupContainer = styled.div`
  padding: 20px 25px;
  background: ${({ theme }) => theme.colors.backgrounds.dark}; 
  border-radius: 4px;
  width: fit-content;
  opacity: 0;
  box-shadow: 2px 3px 15px rgba(61, 61, 61, 0.3);
  color: ${({ theme }) => theme.colors.colors.default};
  ${({ shadow, theme }) => shadow && `
    box-shadow: 2px 3px 15px ${theme.colors.shadows.widget};
  `};
  position: absolute;
  top: 0px;
  right: -116px;
  z-index: 9999;
  transition: 0.4s;
  pointer-events: none;
  ${({ versionPopup }) => versionPopup && `
    right: 420px;
    padding: 0;
  `}
  ${({ opened }) => opened && `
    top: calc(100% + 25px);
    opacity: 1;
    pointer-events: auto;
  `}

  @media (max-width: 910px) {
    height: 565px;
    width: 100vw;
    right: -49px;
    top: calc(100vh - 578px);
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    ${({ isLayer }) => isLayer && `
      right: -15px;
      height: 248px;
      top: calc(100vh - 260px);
      background: #F5F5F5; 
    `}
`
export const Container = styled.div`
  position: relative;
  &::before{
    content: '';
    opacity: 0;
    position: absolute;
    width: 15px;
    height: 10px;
    left: 50%;
    margin: 0;
    padding: 0;
    clip-path: polygon(50% 0%, 0% 100%, 100% 100%);
    top: 0px;
    z-index: -10;
    transform: translate(-50%, -50%);
    transition: 0.4s;
    background: ${({ theme }) => theme.colors.backgrounds.dark};
    ${({ opened }) => opened && `
      top: calc(100% + 22px);
      opacity: 1;
    `}
  }
`

export const PopupWrapper = styled.div`
  position: relative;
  width: fit-content;
`
export const IconContainer = styled.div`
  position: absolute;
  right: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  .svgFill {
    fill: ${({ theme }) => theme.colors.colors.default};
    width: 14px;
    height: 14px;
  }
  cursor: pointer;

`
