import {
  take, call, put, select,
} from 'redux-saga/effects'
import noop from 'lodash/noop'
import get from 'lodash/get'
import createNotifications from '@/helpers/notification'
import {
  getSystems,
  getSyncForm,
  getIntegrationSchemeById,
} from '@/store/selectors/integrations'
import { updatedScheme, selectSystem, setMode } from '@/store/actions/integrations'
import {
  successRepeatIntegration,
  errorRepeatIntegration,
} from '@/store/actions/integrations/repeatIntegration'
import { requestGetIntegrations } from '@/store/actions/integrations/getIntegrations'
import {
  REPETITION_ISYNCRONIZATION_FAIL,
  INTEGRATION_SYNCHONIZE,
  ERRORS_TO_ERROR_MESSAGES,
  REQUEST_ATM_ERROR_UNKNOWN,
  REQUEST_KULON_ERROR_UNKNOWN,
  KULON,
  ATM,
  BRIZ,
  MESH,
  UNILIGHT,
} from '@/constants/integrations'
import { repeatIntegrationsChannel } from './channels'
import messagesAliasCreator from '../notificationElements'

const MESSAGES_ALIASES = {
  [KULON]: messagesAliasCreator('АСУНО', 'КУЛОН'),
  [ATM]: messagesAliasCreator('АСУ', '1SIM'),
  [BRIZ]: messagesAliasCreator('АСУ', 'БРИЗ'),
  [MESH]: messagesAliasCreator('АСУ', 'МЭШ СЕТЬ'),
  [UNILIGHT]: messagesAliasCreator('АСУ', 'ЮНИЛАЙТ'),
}

function* repeatIntegrationSaga() {
  try {
    const toast = createNotifications()
    const channel = yield call(repeatIntegrationsChannel)

    while (true) {
      const eventData = yield take(channel)
      const form = yield select(getSyncForm)
      const { integrationType } = eventData
      const integrationsFromStore = yield select(getSystems)
      const currentIntegration = get(integrationsFromStore, `${[integrationType]}.children`, [])
        .find((integration) => integration.id === eventData.aliasId)

      switch (eventData.status) {
        case 'ON_FAILED': {
          yield put(requestGetIntegrations())
          const schemeByID = yield select(getIntegrationSchemeById)
          const newScheme = {
            ...schemeByID,
            children: [{
              ...schemeByID.children[0],
              isSync: false,
              integrationOption: true,
            }],
          }
          yield put(updatedScheme(newScheme))
          const errorNotifications = ERRORS_TO_ERROR_MESSAGES[eventData.errors[0].subject] || {}
          const messageConfig = MESSAGES_ALIASES[integrationType][REPETITION_ISYNCRONIZATION_FAIL]
            || noop
          const errorsObject = eventData.errors.reduce((accumulator, error) => {
            const theme = ERRORS_TO_ERROR_MESSAGES[error.subject] || {}
            const message = theme[error.message] || `${error.subject}: ${error.message}`
            return {
              ...accumulator,
              [(error.subject || '').toLowerCase()]: message,
            }
          }, {})
          toast(messageConfig(
            eventData.alias,
            errorNotifications[eventData.errors[0].message]
            || errorNotifications[eventData.description]
            || `${eventData.description}` || '',
          ))
          const errorsMessage = eventData.errors.map((element) => {
            const theme = ERRORS_TO_ERROR_MESSAGES[element.subject] || {}
            const message = theme[element.message] || `${element.subject}: ${element.message}`
            if (
              message.indexOf(REQUEST_KULON_ERROR_UNKNOWN) >= 0
              || message.indexOf(REQUEST_ATM_ERROR_UNKNOWN) >= 0
            ) {
              return 'Ошибка синхронизации'
            }
            return message
          }).join('\n')
          if (Object.keys(form).length > 0 && Object.keys(form.form).length > 0) {
            form.form.setSubmitting(false)
            form.form.setErrors(errorsObject)
          }
          yield put(errorRepeatIntegration(errorsMessage))
          break
        }
        case 'COMPLETED': {
          const messageConfig = MESSAGES_ALIASES[integrationType][INTEGRATION_SYNCHONIZE]
            || noop
          toast(messageConfig(currentIntegration.name || ''))
          if (Object.keys(form).length > 0) {
            form.form.setSubmitting(false)
          }
          yield put(requestGetIntegrations())
          yield put(updatedScheme({}))
          yield put(selectSystem(null))
          yield put(successRepeatIntegration())
          yield put(setMode('CONNECTED_INTEGRATION'))
          break
        }
        default: break
      }
    }
  } catch (e) {
    console.log('TCL: e', e)
  }
}

export default repeatIntegrationSaga
