import {
    put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_EXPLOITATION_ORGANIZATIONS_URL } from '@/constants/apiRoutes'
import { GET_ALL_EXPLOITATION_ORGANIZATIONS } from '@/store/actions/dictionaries'
import createNotifications from '@/helpers/notification'
import {
    errorGetAllExploitationOrganizations,
    responseGetAllExploitationOrganizations
} from '@/store/actions/dictionaries/exploitationOrganizations'

function* getDictionary() {
    try {
        const response = yield request({
            url: GET_EXPLOITATION_ORGANIZATIONS_URL,
            method: 'get',
        })
        if (response && response.data) {
            yield put(responseGetAllExploitationOrganizations(response.data))
        }
    } catch (error) {
        yield put(errorGetAllExploitationOrganizations(error))
        const toast = createNotifications()
        toast({
            title: 'Ошибка операции!',
            description: 'Не удалось получить данные всех эксплуатирующих организаций.\nПовторите попытку позже.',
            type: 'error',
        })
    }
}

export default function* () {
    yield takeLatest(GET_ALL_EXPLOITATION_ORGANIZATIONS.REQUEST, getDictionary)
}