import { GET_SEARCH_TABLE } from './index'

export const requestSearchTable = (payload) => ({
    type: GET_SEARCH_TABLE.REQUEST,
    payload,
})

export const successSearchTable = (payload) => ({
    type: GET_SEARCH_TABLE.SUCCESS,
    payload,
})

export const errorSearchTable = (payload) => ({
    type: GET_SEARCH_TABLE.ERROR,
    payload,
})