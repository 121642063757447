import React, { useMemo } from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import {
  Container,
  OptionContainer,
  TitleContainer,
  ItemContainer,
  Mark,
  Error,
} from './styles'

const CustomLegend = ({
  seriesNames,
  title,
  formattedData,
  pl,
  align,
}) => {
  const isOptionMoreThenOne = useMemo(() => seriesNames.length > 1, [seriesNames])
  return (
    <Container pl={pl} align={align}>
      <TitleContainer>
        {title}
        {title && isOptionMoreThenOne && ':'}
        {title && !isOptionMoreThenOne && !!formattedData.length && seriesNames.map((element) => (
          <ItemContainer>
             <Mark color={element.fill} />
          </ItemContainer>
        ))}
      </TitleContainer>
      {isOptionMoreThenOne && formattedData.length !== 0 && (
        <OptionContainer>
          {seriesNames.map((element) => (
            <ItemContainer>
              <Mark color={element.fill} />
              {element.title}
            </ItemContainer>
          ))}
        </OptionContainer>
      )}
      {formattedData.length === 0 && (
        <Error>
          <Lang id="tyumenOurHome.noData" />
        </Error>
      )}
    </Container>
  )
}

CustomLegend.propTypes = {
  seriesNames: pt.arrayOf(pt.object),
  formattedData: pt.arrayOf(pt.object),
  title: pt.string,
  dashboardWidget: pt.bool,
}
CustomLegend.defaultProps = {
  seriesNames: [],
  formattedData: [],
  title: null,
  dashboardWidget: false,
  align: null
}

export default CustomLegend
