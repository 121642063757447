import {
  ROLE_SUPER_ADMIN,
  ROLE_TENANT_ADMIN,
  ROLE_USER,
} from '@/constants/names'

export default {
  group: 'Группа',
  signOutButton: 'Выйти из системы',
  oldPassword: 'Старый пароль',
  newPassword: 'Новый пароль',
  changePasswordButton: 'Сменить пароль',
  forgotPasswordButton: 'Забыли пароль?',
  number: '8 888 888 88 88',
  numberDescription: 'Единая служба поддержки пользователей',
  roles: {
    [ROLE_SUPER_ADMIN]: 'Супер администратор',
    [ROLE_TENANT_ADMIN]: 'Администратор',
    [ROLE_USER]: 'Пользователь',
  },
  jobTitle: 'Должность'
}
