export const NAME = 'name'
export const COLOR = 'color'
export const OBJECTS = 'objects'
export const STREET = 'street'
export const REGISTRY_NUMBER = 'registryNumber'
export const OBJECT_TYPE = 'objectType'
export const VOLS = 'vols'
export const UTILITY_POLE_TYPE = 'utilityPoleType'
export const LIGHT_FIXTURE_TYPE = 'lightFixtureType'
export const PEDESTRIAN_CROSSING_SIGN = 'pedestrianCrossingSign'
export const CONTROL_CUPBOARD = 'controlCupboard'
export const NETWORK_COMPANY = 'networkCompany'

export default {
  NAME,
  COLOR,
  OBJECTS,
  STREET,
  REGISTRY_NUMBER,
  OBJECT_TYPE,
  VOLS,
  UTILITY_POLE_TYPE,
  LIGHT_FIXTURE_TYPE,
  PEDESTRIAN_CROSSING_SIGN,
  CONTROL_CUPBOARD,
  NETWORK_COMPANY
}