import {
  takeLatest, put, all,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_TYUMEN_DATE_GRAPH } from '@/store/actions/tyumenOurHome'
import { generateTyumenOurHomeDateGraphUrl } from '@/constants/apiRoutes'
import {
  successGetTyumenDateGraph,
  errorGetTyumenDateGraph,
} from '@/store/actions/tyumenOurHome/getDateGraph'
import {
  OUTDOOR_LIGHTNING,
  ROAD_REPAIRING,
  TRANSPORT_STATION
} from '@/constants/objectTypes'

function* getDateGraph() {
  try {
    const response = yield all({
      [OUTDOOR_LIGHTNING]: (yield request({
        url: generateTyumenOurHomeDateGraphUrl(OUTDOOR_LIGHTNING),
        method: 'get',
      })).data,
      [ROAD_REPAIRING]: (yield request({
        url: generateTyumenOurHomeDateGraphUrl(ROAD_REPAIRING),
        method: 'get',
      })).data,
      [TRANSPORT_STATION]: (yield request({
        url: generateTyumenOurHomeDateGraphUrl(TRANSPORT_STATION),
        method: 'get',
      })).data,
    })
    yield put(successGetTyumenDateGraph(response))
  } catch (error) {
    yield put(errorGetTyumenDateGraph(error))
  }

  return null
}

export default function* root() {
  yield takeLatest(GET_TYUMEN_DATE_GRAPH.REQUEST, getDateGraph)
}
