import {
  takeLatest, put, call,
} from 'redux-saga/effects'
import { treeHandlers } from 'react-hyper-tree'
import request from '@/helpers/axios'
import { CREATE_GEOZONE } from '@/store/actions/installation'
import { INSTALLATIONS_GEOZONE_ENDPOINT } from '@/constants/apiRoutes'
import { successCreateGeoZone } from '@/store/actions/installation/createGeoZone'
import updateNodeCount from '@/helpers/updateNodeCount'
import createNotifications from '@/helpers/notification'
import { GEOZONE, ROOT } from '@/constants/objectTypes'
import { requestGetRootElements } from '@/store/actions/installation/getRootElemenet'
import { setGlobalLoading, setTrueStatusGlobalFilter } from '@/store/actions/appSettings'
import get from "lodash/get";

function* createGeoZoneSaga({ payload }) {
  const {
    formValues, values, setUrlFormValues, maxRadiusValue
  } = payload
  const {
    city, lat, lon, mark, name, region, radius, maxRadius
  } = values
  try {
    const body = {
      parentId: formValues.id,
      name,
      city,
      region,
      point: {
        latitude: lat,
        longitude: lon,
      },
      note: mark,
      radius,
      maxRadius: maxRadius ? maxRadius : maxRadiusValue
    }
    yield put(setGlobalLoading(true))
    const { data } = yield request({
      url: INSTALLATIONS_GEOZONE_ENDPOINT,
      method: 'post',
      body,
    })
    const { rootId, path, FILTER } = formValues
    const toast = createNotifications()
    if (rootId) {
      const tree = treeHandlers.trees['installations-tree']
      const parent = tree.instance.getNodeById(data.parentId)
      const parentChildren = yield call(parent.data.getChildren, parent)
      tree.handlers.setRawChildren(parent, parentChildren, 'first', true)
      const node = tree.instance.getNodeById(data.id)
      updateNodeCount(tree, path, 'add')
      const selectedNodePath = get(node, 'options.path', '')
      tree.handlers.setSelectedByPath(selectedNodePath)
    } else {
      yield put(requestGetRootElements())
    }
    setUrlFormValues({
      id: data.id,
      rootId,
      parentGeoZoneId: data.parentId,
      parentTreeId: data.parentId,
      path: `${path}/${data.id}`,
      type: data.parentId ? GEOZONE : ROOT,
      FILTER,
    })
    yield put(setGlobalLoading(false))
    toast({
      title: 'Создание геозоны',
      type: 'success',
      description: 'Геозона успешно создана.',
    })
    yield put(setTrueStatusGlobalFilter())
    yield put(successCreateGeoZone())
  } catch (error) {
    yield put(setGlobalLoading(false))
    const toast = createNotifications()
    toast({
      title: 'Создание геозоны',
      type: 'error',
      description: formValues.id ? 'Не удалось создать геозону, обратитесь к администратору.' : 'Не удалось создать корневую геозону, обратитесь к разработчикам платформы',
    })
    const { FILTER } = formValues
    setUrlFormValues({ FILTER })
    console.log('function*createGeoZoneSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(CREATE_GEOZONE.REQUEST, createGeoZoneSaga)
}
