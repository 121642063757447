import styled from 'styled-components'

export const SettingsContainer = styled.div`
  padding: 20px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 15px 0;
  background-color: transparent;
`;

export const TableHeader = styled.th`
  padding: 12px;
  text-align: left;
  border-bottom: 2px solid #ddd;
  font-size: 1.5rem;
  width: ${(props) => props.width || '200px'};
`;

export const TableCell = styled.td`
  padding: 12px;
  border-bottom: 1px solid #ddd;
  text-align: left;
  font-size: 1.4rem;
  font-weight: ${props => (props.isBold || props.isStatus ? 600 : 300)};
  cursor: pointer;
  color: ${props => props.status};
`;

export const ActiveStatus = styled.span`
  display: inline-block;
  padding: 5px 10px;
  color: ${props => (props.isActive ? '#4caf50' : '#f44336')};
  font-size: 1.4rem;
  font-weight: 600;
`;

export const LoaderContainer = styled.div`
  height: 65vh;
`;
