import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import LiveTime from '@/components/icons/livetime'
import numberWithSpaces from '@/helpers/numberWithSpaces'
import {
  OUTDOOR_LIGHTING,
  HEAT_SUPPLY,
} from '@/constants/names'

export const consumptionAnalysisFieldsElectricity = [
  {
    id: 'descr',
    name: <Lang id="tableFields.consumptionAnalysis.descr" />,
    value: 'ASC',
  },
  {
    id: 'geoZone',
    name: <Lang id="tableFields.consumptionAnalysis.geoZone" />,
    value: 'ASC',
  },
  {
    id: 'address',
    name: <Lang id="tableFields.consumptionAnalysis.address" />,
    value: 'ASC',
  },
  {
    id: 'currentPeriod',
    name: <Lang id="tableFields.consumptionAnalysis.currentPeriod" />,
    value: 'ASC',
  },
  {
    id: 'planedValue',
    name: <Lang id="tableFields.consumptionAnalysis.planedValue" />,
    value: 'ASC',
    customWidth: 248,
    customCell: (props) => (numberWithSpaces(props.value)),
  },
  {
    id: 'currentValue',
    name: <Lang id="tableFields.consumptionAnalysis.currentValue" />,
    value: 'ASC',
    customWidth: 248,
    customCell: (props) => (numberWithSpaces(props.value)),
  },
  {
    id: 'planedCurrentDifference',
    name: <Lang id="tableFields.consumptionAnalysis.planedCurrentDifference" />,
    value: 'ASC',
    customWidth: 248,
    customCell: (props) => (numberWithSpaces(props.value)),
  },
  {
    id: 'prevPeriod',
    name: <Lang id="tableFields.consumptionAnalysis.prevPeriod" />,
    value: 'ASC',
    customWidth: 248,
  },
  {
    id: 'prevValue',
    name: <Lang id="tableFields.consumptionAnalysis.prevValue" />,
    value: 'ASC',
    customWidth: 248,
    customCell: (props) => (numberWithSpaces(props.value)),
  },
  {
    id: 'difference',
    name: <Lang id="tableFields.consumptionAnalysis.difference" />,
    value: 'ASC',
    customWidth: 248,
    customCell: (props) => (numberWithSpaces(props.value)),
  },
]

export const consumptionAnalysisFieldsHeat = [
  {
    id: 'object',
    name: <Lang id="tableFields.consumptionAnalysis.object" />,
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'geoZone',
    name: <Lang id="tableFields.consumptionAnalysis.geoZone" />,
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'address',
    name: <Lang id="tableFields.consumptionAnalysis.address" />,
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'date',
    name: <Lang id="tableFields.consumptionAnalysis.date" />,
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'planT1',
    name: (
      <>
        t
        <sub><Lang id="tableFields.consumptionAnalysis.planT1" /></sub>
        , °С
      </>
    ),
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'planT2',
    name: (
      <>
        t
        <sub><Lang id="tableFields.consumptionAnalysis.planT2" /></sub>
        , °С
      </>
    ),
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'factT1',
    name: (
      <>
        t
        <sub><Lang id="tableFields.consumptionAnalysis.factT1" /></sub>
        , °С
      </>
    ),
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'factT2',
    name: (
      <>
        t
        <sub><Lang id="tableFields.consumptionAnalysis.factT2" /></sub>
        , °С
      </>
    ),
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'planQ',
    name: (
      <>
        Q
        <sub><Lang id="tableFields.consumptionAnalysis.planQ" /></sub>
        , <Lang id="tableFields.consumptionAnalysis.energy" />
      </>
    ),
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'factQ',
    name: (
      <>
        Q
        <sub><Lang id="tableFields.consumptionAnalysis.factQ" /></sub>
        , <Lang id="tableFields.consumptionAnalysis.energy" />
      </>
    ),
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'overheat',
    name: <Lang id="tableFields.consumptionAnalysis.overheat" />,
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'rate',
    name: <Lang id="tableFields.consumptionAnalysis.rate" />,
    value: 'ASC',
    customWidth: 150,
  },
  {
    id: 'overpayment',
    name: <Lang id="tableFields.consumptionAnalysis.overpayment" />,
    value: 'ASC',
    customWidth: 150,
  },
]


export const consumptionAnalysisExportOptions = [
  {
    name: 'EXCEL',
    value: 'excel',
    icon: LiveTime.EXELIcon,
  },
  {
    name: 'PDF',
    value: 'pdf',
    icon: LiveTime.PDFIcon,
  },
]

export const consumptionAnalysisFieldsByType = {
  [OUTDOOR_LIGHTING]: consumptionAnalysisFieldsElectricity,
  [HEAT_SUPPLY]: consumptionAnalysisFieldsHeat,
}
