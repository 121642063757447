import { EDIT_SCHEDULE } from './index'

export const requestEditSchedule = (payload) => ({
  type: EDIT_SCHEDULE.REQUEST,
  payload,
})

export const successEditSchedule = (payload) => ({
    type: EDIT_SCHEDULE.SUCCESS,
    payload,
})

export const errorEditSchedule = (payload) => ({
    type: EDIT_SCHEDULE.ERROR,
    payload,
})