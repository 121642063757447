import { GET_OBJECTS_GROUPS_FILTER_OPTIONS } from '@/store/actions/dictionaries/index'

export const requestGetObjectsGroupsFilterOptions = (payload) => ({
  type: GET_OBJECTS_GROUPS_FILTER_OPTIONS.REQUEST,
  payload
})

export const successGetObjectsGroupsFilterOptions = (data) => ({
  type: GET_OBJECTS_GROUPS_FILTER_OPTIONS.SUCCESS,
  payload: data,
})

export const errorGetObjectsGroupsFilterOptions = (error) => ({
  type: GET_OBJECTS_GROUPS_FILTER_OPTIONS.ERROR,
  payload: error,
})