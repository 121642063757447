import {
  GROUP_OBJECT,
  HEATING_DEVICE,
  COLD_WATER_DEVICE,
  CENTRAL_HEATING_DEVICE,
  COMPANY_OBJECT
} from '@/constants/instalationPassport/types'
import groupObject from './groupObject'
import heat from './heat'
import water from './water'

export default {
  [HEATING_DEVICE]: heat,
  [CENTRAL_HEATING_DEVICE]: heat,
  [COLD_WATER_DEVICE]: water,
  [GROUP_OBJECT]: groupObject,
  [COMPANY_OBJECT]: groupObject,
}
