import React, { useCallback } from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import isEmpty from 'lodash/isEmpty'
import EditUserForm from '@/forms/EditUserForm'
import Loader from '@/components/blocks/Loader'
import UserFormHeader from '@/components/blocks/Header'
import {
  Container,
  LoaderContainer,
} from './styles'

const EditUser = ({
  selectedAdmin, formData, seUserImageUrl, updateUser,
  isFormModifying, setFormIsModifying, closeForm,
}) => {
  const editAdminForm = React.createRef()
  // const [isValidForm, setValidForm] = useState({})
  // const [isDirty, setIsDirty] = useState(false)

  // const handleIsDirty = useCallback((value) => {
  //   setIsDirty(value)
  // }, [setIsDirty])

  const handleSubmit = useCallback(() => {
    if (isEmpty(editAdminForm.current.state.errors)) {
      updateUser({
        forms: editAdminForm.current,
        tenantId: selectedAdmin.tenantId,
        id: selectedAdmin.id,
        isMe: selectedAdmin.isMe,
      })
    }
  }, [editAdminForm, selectedAdmin, updateUser])

  return (
    <Container>
      <UserFormHeader
        editTitle={<Lang id="usersPage.formHeader.changingProfile" />}
        editMode={isFormModifying}
        saveForm={handleSubmit}
        closeForm={closeForm}
        title={<Lang id="usersPage.formHeader.user" />}
        setFormIsModifying={setFormIsModifying}
        withoutDeleting
        popupSettings={{
          error: {
            title: <Lang id="usersPage.popup.attention" />,
            message: isFormModifying
              ? <Lang id="usersPage.popup.cancelChangeUser" />
              : (
                <>
                  <Lang id="usersPage.popup.deleteUser" />
                  {`${selectedAdmin.name}?`}
                </>
              ),
          },
          warning: {
            title: <Lang id="usersPage.popup.impossibleExecute" />,
            message: <Lang id="usersPage.popup.saveUser" />,
          },
        }}
        conditionsForAdding
      />
      {Object.keys(selectedAdmin).length === 0
        ? (
          <LoaderContainer>
            <Loader />
          </LoaderContainer>
        )
        : (
          <EditUserForm
            ref={editAdminForm}
            formData={formData}
            seUserImageUrl={seUserImageUrl}
            disableFields
            defaultFields
            isFormModifying={isFormModifying}
          />
        )}
    </Container>
  )
}
EditUser.propTypes = {
  type: pt.string,
  setType: pt.func,
  seUserImageUrl: pt.func,
  userImageUrl: pt.bool,
  setEditMode: pt.func,
}
EditUser.defaultProps = {
  type: '',
  setType: noop,
  setEditMode: noop,
  seUserImageUrl: noop,
  userImageUrl: false,
}

export default EditUser
