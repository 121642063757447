import styled from 'styled-components'

import Button from '@/components/blocks/Button'
import {
PRIMARY300,
GRAY,
MINE_SHAFT,
LIGHT_GRAY
} from '@/constants/styles/defaultTheme'

export const Container = styled.div`
  position: relative;
  width: 270px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 48px;
  border-radius: 20px;    
  background: #FAFBFB;
  overflow: hidden;
  gap: 20px;
  ${({ios}) => ios &&`
    width: calc(100vw - 40px);
  `}
  `

export const Title = styled.div`
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.6rem;
  text-align: left;
  font-weight: bold;
  text-align: center;
  ${({ios}) => ios &&`
    margin-bottom: 20px;
  `}
`
export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  max-width: 500px;
  gap: 20px;
  margin: auto;
`
export const StyledButton = styled(Button)`
  height: 38px;
  border-radius: 4px;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  text-transform: none;
  display: flex;
  align-items: center;
  min-width: 99px;
  ${({ usage }) => usage === 'cancel' && `
    background: ${GRAY};
    color: ${MINE_SHAFT};
  `}
  ${({ usage }) => usage === 'install' && `
    background: ${PRIMARY300};
  `}
  ${({ usage }) => usage === 'ok' && `
    background: ${PRIMARY300};
    width: 100%;
  `}
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex-direction: column;
`
export const Message = styled.div`
  font-style: normal;
  font-size: 1.3rem;
  line-height: 1.6rem;
  text-align: center;
  font-weight: 400px;
  padding: 0 13px 0 13px;
`

export const HeaderGroups = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  position: absolute;
  top: -90px;
  left: -76px;
`
export const IconContainer = styled.div`
  min-width: 72px;
  min-height: 72px;
  border-radius: 17px;
  background: #DDDDDD;
   ${({opacity}) => opacity &&`
    background: ${LIGHT_GRAY};
  `}
`

export const Main = styled.div`
  margin-top: 100px;
  width: 289px;
  position: relative;
`

export const Row = styled.div`
  font-style: normal;
  font-size: 1.3rem;
  line-height: 1.6rem;
  text-align: left;
  font-weight: 400px;
  color: #838D99;
`

export const Accent = styled.span`
  color: ${PRIMARY300};
  ${({bold}) => bold &&`
    color: #3D3D3D;
  `}
`

