import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import {
  GET_FAULT_ANALYTICS_FILE,
} from '@/store/actions/faultAnalytics'
import {
  ALARM_ANALYTICS_REPORT_SUMMARY_TABLE_FILE_ENDPOINT,
} from '@/constants/apiRoutes'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'
import createNotifications from '@/helpers/notification'
import {
  successSummaryAlarmAnalyticsFile,
  errorSummaryAlarmAnalyticsFile,
} from '@/store/actions/faultAnalytics/getFile'
import {
  getTableParameters,
} from '@/store/selectors/faultAnalytics'

function* getAlarmManagerFileSaga({ payload }) {
  const toast = createNotifications()
  try {
    const tableParameters = yield select(getTableParameters)

    const response = yield request({
      url: ALARM_ANALYTICS_REPORT_SUMMARY_TABLE_FILE_ENDPOINT({
        params: {
          excludeColumns: payload.allColumns.filter(x => !tableParameters.activeFields.includes(x)),
          pageNumber: tableParameters.page - 1,
          pageSize: tableParameters.perPage,
          sort: [`${tableParameters.sort.field}: ${tableParameters.sort.direction}`],
          name: tableParameters.requestQuery || '',
        },
        format: payload.value
      }),
      method: 'get',
      options: {
        responseType: 'blob',
      },
    })

    downloadFileFromBlob({
      blob: response.data,
      fileName: `SLS24_summary-analytics-alarms.${payload.value}`,
    })
    yield put(successSummaryAlarmAnalyticsFile())
  } catch (error) {
    yield put(errorSummaryAlarmAnalyticsFile(error))
    toast({
      title: 'Ошибка операции!',
      description: 'Невозможно выгрузить отчет. Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_FAULT_ANALYTICS_FILE.REQUEST, getAlarmManagerFileSaga)
}
