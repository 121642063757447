import styled from 'styled-components'
import {
  LIGHT_THEME_NAME,
} from '@/constants/names'

export const Container = styled.div`
  position: relative;
  width: 270px;
  height: 130px;
  padding-left: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  cursor: pointer;
  margin-top: 15px;
  margin-right: 15px;
  ${({ withoutTransition }) => withoutTransition && `
    cursor: default;
  `};
  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
        border: 1px solid ${theme.colors.borders.redisign.contrast};
        background: ${theme.colors.backgrounds.redisign.default};
        box-shadow: 5px 5px 10px ${theme.colors.shadows.widgetPrimary},
          -5px -5px 10px ${theme.colors.shadows.widgetSecondary};
      `
    }
    return `
      background: ${theme.colors.backgrounds.header}
      box-shadow: -8px 12px 18px ${theme.colors.shadows.widget}
    `
  }}
  @media (max-width: 910px) {
    flex: 40%;
    height: 80px;
    padding-left: 10px;
  }
`

export const Count = styled.div`
  font-weight: bold;
  font-size: 2.4rem;
  line-height: 2.8rem;
  color: ${({ theme }) => theme.colors.colors.default};
  margin-top: 32px;
  ${({ customColor, theme }) => customColor && `
    color: ${theme.colors.messages.error};
  `};
  @media (max-width: 910px) {
    margin-top: 5px;
    font-size: 2rem;
  }
`

export const Text = styled.div`
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.colors.colors.default};
  margin-top: 20px;
  @media (max-width: 910px) {
    font-weight: 400;
    margin-top: 10px;
    text-align: left;
  }
`

export const IconContainer = styled.div`
  position: absolute;
  right: 0px;
  top: 0px;
  width: 130px;
  height: 130px;
  @media (max-width: 910px) {
    width: 70px;
    height: 72px;
  } 
`
