import { GET_ALL_EXPLOITATION_ORGANIZATIONS } from '@/store/actions/dictionaries/index'

export const requestGetAllExploitationOrganizations = () => ({
    type: GET_ALL_EXPLOITATION_ORGANIZATIONS.REQUEST,
})

export const responseGetAllExploitationOrganizations = (data) => ({
    type: GET_ALL_EXPLOITATION_ORGANIZATIONS.SUCCESS,
    payload: data,
})

export const errorGetAllExploitationOrganizations = (error) => ({
    type: GET_ALL_EXPLOITATION_ORGANIZATIONS.ERROR,
    payload: error,
})