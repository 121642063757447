import createAction from '@/helpers/redux/createAction'

export const GET_ALL_CITY_PROCESSES_OPTIONS = createAction('@/GET_ALL_CITY_PROCESSES_OPTIONS')
export const GET_ALL_APPLICATIONS = createAction('@/GET_ALL_APPLICATIONS')
export const GET_ALL_APPLICATIONS_MODELS = createAction('@/GET_ALL_APPLICATIONS_MODELS')
export const GET_ALL_OBJECT_TYPES = createAction('@/GET_ALL_OBJECT_TYPES')
export const GET_ALL_EXPLOITATION_ORGANIZATIONS = createAction('@/GET_ALL_EXPLOITATION_ORGANIZATIONS')
export const GET_ALL_OWNERS = createAction('@/GET_ALL_OWNERS')
export const GET_ALL_CONNECTED_INTEGRATIONS = createAction('@/GET_ALL_CONNECTED_INTEGRATIONS')
export const GET_SEARCH_FIELDS_OPTIONS = createAction('@/GET_SEARCH_FIELDS_OPTIONS')
export const GET_OBJECTS_GROUPS_FILTER_OPTIONS = createAction('@/GET_OBJECTS_GROUPS_FILTER_OPTIONS')
export const GET_ALERT_FIELDS_OPTIONS = createAction('@/GET_ALERT_FILDS_OPTIONS')
