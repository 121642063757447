import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import cookie from 'react-cookies'
import { requestUserLogout } from '@/store/actions/user/logout'
import { requestUserPing } from '@/store/actions/user/userPing'
import createNotifications from '@/helpers/notification'
import { ACTIVE_PING_ITEM, ACTIVE_REQUEST_ITEM, SLS24_ACCESS_TOKEN } from '@/constants/names'
import emitter from '@/helpers/eventEmitter'

const DEFAULT_TIMEOUT = 15 * 60 * 1000 // 15 minutes
const DEFAULT_INTERVAL_TIMEOUT = 1 * 60 * 1000 // 1 minute

const getHiddenName = () => {
  let name
  if (typeof document.hidden !== 'undefined') {
    name = 'hidden'
  }
  if (typeof document.msHidden !== 'undefined') {
    name = 'msHidden'
  }
  if (typeof document.webkitHidden !== 'undefined') {
    name = 'webkitHidden'
  }

  return name
}

const VISIBILITY_BY_HIDDEN = {
  hidden: 'visibilitychange',
  msHidden: 'msvisibilitychange',
  webkitHidden: 'webkitvisibilitychange',
}

const hidden = getHiddenName()

export const useHandleActivity = (
  timeout = DEFAULT_TIMEOUT,
  intervalTimeout = DEFAULT_INTERVAL_TIMEOUT,
) => {
  const dispatch = useDispatch()
  const toast = createNotifications()
  let blurStartTime = null
  let intervalId

  function requestPing() {
    const lifeTime = cookie.load('lifeTime')
    const startTime = cookie.load('startTime')
    const timeDiff = new Date().getTime() - startTime
    const canCallPingByTime = timeDiff >= lifeTime * 1000 - 60000
    if (canCallPingByTime) {
      window.localStorage.setItem(`${ACTIVE_PING_ITEM}:time`, `${new Date().getTime()}`)
      dispatch(requestUserPing())
    }
  }

  function handleVisibilityChange() {
    if (!document[hidden]) {
      blurStartTime = null
      requestPing()
    } else {
      blurStartTime = (new Date()).getTime()
      window.localStorage.setItem('sls:handle-start', `${new Date()}`)
    }
  }

  document.addEventListener(VISIBILITY_BY_HIDDEN[hidden], handleVisibilityChange, false)

  if (!intervalId) {
    intervalId = setInterval(() => {
      const currentActive = !document[hidden]
      const accessToken = cookie.load(SLS24_ACCESS_TOKEN)

      if (!accessToken) {
        clearInterval(intervalId)
        return
      }

      const hasPingRequest = window.localStorage.getItem(ACTIVE_PING_ITEM) === '1'
      const hasActiveRequests = Number.parseInt(
        window.localStorage.getItem(ACTIVE_REQUEST_ITEM),
        10,
      ) > 0

      if (hasPingRequest || hasActiveRequests) {
        emitter.subscribe([ACTIVE_PING_ITEM, ACTIVE_REQUEST_ITEM], (_, events) => {
          if (
            events[ACTIVE_PING_ITEM] === 0
            && events[ACTIVE_REQUEST_ITEM] === 0
          ) {
            requestPing()
          }
        })
      }

      if (!currentActive && blurStartTime === null) {
        blurStartTime = (new Date()).getTime()
        window.localStorage.setItem('sls:handle-start', `${new Date()}`)
      } else if (!currentActive && blurStartTime !== null) {
        const timeDiff = Math.floor(((new Date()).getTime() - blurStartTime) / 1000)
        const timeoutMinLevel = (timeout - intervalTimeout) / 1000
        console.log('TIMES:', timeDiff >= timeout, timeDiff, timeoutMinLevel);
        if (timeDiff >= timeoutMinLevel && accessToken) {
          dispatch(requestUserLogout())
          window.localStorage.setItem('sls:handle-end', `${new Date()}`)
          clearInterval(intervalId)
          toast({
            title: 'ЗАВЕРШЕНИЕ СЕССИИ!',
            description: 'Сессия завершена из-за длительной неактивности. Для работы с системой необходима повторная авторизация',
            type: 'error',
          }, { autoClose: false })
        }
      }
    }, intervalTimeout)
  }

  useEffect(() => () => {
    clearInterval(intervalId)
  })

  return null
}

export default useHandleActivity
