import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import moment from 'moment/moment'
import request from '@/helpers/axios'
import {
  GET_PPR_FILE,
} from '@/store/actions/Ppr'
import  { getSortField }  from '@/helpers/getSortField'
import {
  PPR_FILE_ENDPOINT,
} from '@/constants/apiRoutes'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'
import createNotifications from '@/helpers/notification'
import {
  successAlarmManagerFile,
  errorAlarmManagerFile,
} from '@/store/actions/analyticsAlarms/getFile'
import {
  getTableParameters,
  getYear,
  getStatus,
  getDatesRange
} from '@/store/selectors/ppr'
import { getSelectedNode } from '@/store/selectors/appSettings'

function* getPprFileSaga({payload}) {
  const toast = createNotifications()
  try {
    const node = yield select(getSelectedNode)
    const year = yield select(getYear)
    const tableParameters = yield select(getTableParameters)
    const status = yield select(getStatus)
    const { start, end } = yield select(getDatesRange)
    const sortField = getSortField(tableParameters.sort.field)
    
    const response = yield request({
      url: PPR_FILE_ENDPOINT({
        params: {
          excludeColumns: payload.allColumns.filter(x => !tableParameters.activeFields.includes(x)),
          pageNumber: tableParameters.page - 1,
          pageSize: tableParameters.perPage,
          parentId: node.id,
          sort: [`${sortField}: ${tableParameters.sort.direction}`],
          search: tableParameters.requestQuery || '',
          year,
          status,
          fromDate: start && moment(start).format('DD.MM.YYYY'),
          toDate: end && moment(end).format('DD.MM.YYYY')
        },
      }),
      method: 'get',
      options: {
        responseType: 'blob',
      },
    })

    downloadFileFromBlob({
      blob: response.data,
      fileName: `SLS24-report_ppr.xlsx`,
    })
    yield put(successAlarmManagerFile())
  } catch (error) {
    yield put(errorAlarmManagerFile(error))
    toast({
      title: 'Ошибка операции!',
      description: 'Невозможно выгрузить ППР. Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_PPR_FILE.REQUEST, getPprFileSaga)
}
