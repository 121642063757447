import styled from 'styled-components'
import PortalTooltip from '@/components/blocks/PortalTooltip'

export const Wrapper = styled.div`
  width: auto;
  height: 48px;
  display: flex;
  align-items: center;
  z-index: 1000;
  position: relative;
`

export const StyledPortalTooltip = styled(PortalTooltip)`
  
`

export const ContentContainer = styled.div`
  padding: 5px;
  z-index: 1100;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.borders.default};
  background: ${({ theme }) => theme.colors.backgrounds.dark};
  position: absolute;
  top: 50px;
  right: 2px;
  width: 160px;
`

export const ButtonContainer = styled.div`
  cursor: pointer;
  width: auto;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  ${({ disabled }) => disabled && `
    cursor: not-allowed;
    ${ButtonContainer} {
      cursor: not-allowed;
    }
    ${ButtonContainer} > svg {
      opacity: 0.5;
    }
  `}
`

export const OptionTitle = styled.div`
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 3rem;
  text-align: left;
  white-space: nowrap;
  overflow-x: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-left: 10px;
  border-radius: 2px;
  ${({ selected, theme }) => selected && `
    color: ${theme.colors.colors.light};
    background: ${theme.colors.backgrounds.redisign.blueRoyal};
  `}
`

export const OptionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
   ${({ checkBox }) => checkBox && `
    border-top: 1px solid #BDC3C7;
    padding-top: 5px;
  `}
`

