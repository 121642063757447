import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const SortIcon = ({ color, onClick }) => {

  return (
    <svg onClick={onClick} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_1672_10774)">
        <path d="M3 12.9985H15V10.9985H3V12.9985ZM3 5.99854V7.99854H21V5.99854H3ZM3 17.9985H9V15.9985H3V17.9985Z" fill="#3E3C3E"/>
      </g>
      <defs>
        <clipPath id="clip0_1672_10774">
          <rect width="24" height="24" fill="white"/>
        </clipPath>
      </defs>
    </svg>
  )
}

SortIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

SortIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(SortIcon)
