import { globalFiltersDefaultValues } from '@/constants/filters'
import { OBJECT_ELEMENT } from '@/constants/objectTypes'
import { getZoomByType } from '@/helpers/maps'
import isEqual from 'lodash/isEqual'

 const DEFAULT_ROOT_CENTER = [64.045274, 90.513266]
 const DEFAULT_ROOT_ZOOM = 4


export const getIdForDisplayElementsRequest = (node, globalFilters) => {
    return isEqual(globalFiltersDefaultValues, globalFilters) ?
        {
            id: node.treeNodeType !== OBJECT_ELEMENT ? node.id : node.parentId,
            zoom: getZoomByType(node.type),
            center: [node.point.latitude, node.point.longitude]
        } :
        {
            id: node.path[0] || node.id,
            zoom: DEFAULT_ROOT_ZOOM,
            center: DEFAULT_ROOT_CENTER
        }
}

export default getIdForDisplayElementsRequest