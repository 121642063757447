import { GET_CONTROL_DATA } from './index'

export const requestGetControlData = (payload) => ({
  type: GET_CONTROL_DATA.REQUEST,
  payload,
})

export const successGetControlData = (payload) => ({
  type: GET_CONTROL_DATA.SUCCESS,
  payload,
})

export const errorGetControlData = (payload) => ({
  type: GET_CONTROL_DATA.ERROR,
  payload,
})