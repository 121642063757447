import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import get from 'lodash/get'
import request from '@/helpers/axios'
import { generateManagementData, generatePutAdmin } from '@/constants/apiRoutes'
import { UPDATE_USER, setFormIsModifying, setSelectedTenant } from '@/store/actions/users'
import { errorUpdateUser } from '@/store/actions/users/user/updateUser'
import { ROLE_TENANT_ADMIN } from '@/constants/names'
import { requestGetAdminTenant } from '@/store/actions/users/administrator/getAdminTenant'
import { requestGetAllAdminTenants } from '@/store/actions/users/administrator/getAllAdminTenants'
import { requestGetAllUsers } from '@/store/actions/users/user/getAllUsers'
import { requestGetTenantGroups } from '@/store/actions/users/group/getTenantGroup'
import { getUserData } from '@/store/selectors/appSettings'
import createNotifications from '@/helpers/notification'
import { isSuperAdmin } from '@/helpers/userPermissions'
import getFullName from '@/helpers/fullName'
import toastErrorHelper from '@/helpers/toastHelper'
import {
  ROLE_SUPER_ADMIN,
} from '@/constants/role'
import { successGetCurrentUser } from '@/store/actions/users/getCurrentUser'

const ERRORS = {
  'validation_failed.email:not_unique': 'Пользователь с таким адресом электронной почты уже существует',
  'validation_failed.email:invalid': 'Неверный адрес электронной почты',
  'validation_failed.group_permissions:not_allowed_for_tenant': 'Группа может иметь процессы которые принадлежат тенанту',
}

function* updateUserTenantSaga({ payload }) {
  const {
    forms, tenantId, id, isMe,
  } = payload
  const toast = createNotifications()
  try {
    const currentUser = yield select(getUserData)
    forms.setSubmitting(true)
    const requestBody = {
      ...forms.state.values,
      role: ROLE_TENANT_ADMIN,
    }

    delete requestBody.photoUri

    const url = currentUser.role === ROLE_SUPER_ADMIN
      ? generateManagementData(id)
      : generatePutAdmin(id)

    const response = yield request({
      url,
      method: 'put',
      body: requestBody,
    })

    const fullName = getFullName(
      response.data.lastName,
      response.data.firstName,
      response.data.middleName,
    )
    yield put(requestGetAllAdminTenants({ id: tenantId }))
    yield put(requestGetAdminTenant({ tenantId, id }))
    if (!isSuperAdmin(currentUser.role)) {
      yield put(requestGetTenantGroups(tenantId))
      const groupId = get(response, 'data.group.id')
      if (groupId) {
        yield put(requestGetAllUsers({
          tenantId,
          groupId,
        }))
      }
    }
    if (isMe) {
      const userData = yield select(getUserData)
      yield put(successGetCurrentUser({ ...userData, ...response.data }))
      toast({
        title: 'Успешная операция!',
        description: 'Изменения в профиле успешно сохранены',
      })
    } else {
      if (response.data.blocked) {
        toast({
          title: 'Успешная операция!',
          description: `Пользователь "${fullName}" успешно заблокирован`,
        })
      }
      if (!response.data.blocked) {
        toast({
          title: 'Успешная операция!',
          description: `Пользователь "${fullName}" успешно разблокирован`,
        })
      }
    }
    yield put(setSelectedTenant(tenantId))
    forms.setSubmitting(false)
    forms.resetForm()
    yield put(setFormIsModifying(false))
  } catch (error) {
    yield put(errorUpdateUser())
    toast({
      title: 'Ошибка операции!',
      description: `Ошибка сохранения изменений! Повторите попытку позже. Код ошибки: ${toastErrorHelper(error)}`,
      type: 'error',
    })
    forms.setErrors({
      [error.response.data.subject]: ERRORS[error.response.data.errorCode],
    })
    forms.setSubmitting(false)
    console.log('TCL: function*getAdminTenantSaga -> error', error)
  }
}

export default function* root() {
  yield takeLatest(UPDATE_USER.REQUEST, updateUserTenantSaga)
}
