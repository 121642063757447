import { fork, takeLatest, all } from 'redux-saga/effects'
import { GET_ONLINE_USERS } from '@/store/actions/users'
import onlineUsersSaga from './getOnlineUsers'


function* watchStateRequest() {
  yield takeLatest(GET_ONLINE_USERS.EVENTS, onlineUsersSaga)
}

const stateSaga = [fork(watchStateRequest)]

export default function* rootSaga() {
  yield all([...stateSaga])
}
