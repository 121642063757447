import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_SCHEDULE_OPTIONS_URL } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { GET_SCHEDULE_OPTIONS } from '@/store/actions/objectsGroups'
import {
  errorGetScheduleOptions,
  successGetScheduleOptions
} from '@/store/actions/objectsGroups/getScheduleOptions'

function* getScheduleOptions({ payload }) {
  try {
    const { installationType } = payload
    const response = yield request({
      url: GET_SCHEDULE_OPTIONS_URL({
        params: {
          installationType
        }
      }),
      method: 'get',
    })
    const formattedData = response.data.map(item => {
      return {
        title: item.name,
        value: item.id,
        groupId: item.groupId,
      }
    })
    yield put(successGetScheduleOptions(formattedData))
    } catch (error) {
      yield put(errorGetScheduleOptions(error))
      const toast = createNotifications()
      toast({
        title: 'Ошибка операции!',
        description: 'Не удалось получить список доступных расписаний. \nПовторите попытку позже.',
        type: 'error',
      })
    }
}

export default function* () {
  yield takeLatest(GET_SCHEDULE_OPTIONS.REQUEST, getScheduleOptions)
}
