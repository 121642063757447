import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const CloseIcon = ({ onClick }) =>(
  <svg onClick={onClick} width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_2882_101554)">
      <path fillRule="evenodd" clipRule="evenodd" d="M3.28661 12.714C5.89012 15.3175 10.1112 15.3175 12.7147 12.714C15.3182 10.1105 15.3182 5.88939 12.7147 3.28588C10.1112 0.682376 5.89012 0.682376 3.28661 3.28588C0.683108 5.88939 0.683108 10.1105 3.28661 12.714ZM10.7505 6.03574L8.78633 7.99993L10.7505 9.96411L9.96484 10.7498L8.00066 8.7856L6.03647 10.7498L5.2508 9.96411L7.21498 7.99993L5.2508 6.03574L6.03647 5.25007L8.00066 7.21425L9.96484 5.25007L10.7505 6.03574Z" fill="#3E3C3E" fillOpacity="0.6"/>
    </g>
    <defs>
      <clipPath id="clip0_2882_101554">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
)

CloseIcon.propTypes = {
  onClick: pt.func,
}

CloseIcon.defaultProps = {
  onClick: noop,
}

export default memoWithName(CloseIcon)
