import React, {
  useCallback,
  useRef,
  useMemo,
  useState,
  useEffect,
} from 'react'
import pt from 'prop-types'
import { Formik } from 'formik'
import moment from 'moment'
import noop from 'lodash/noop'
import debounce from 'lodash/debounce'
import SelectField from '@/components/fields/SelectField'
import Button from '@/components/blocks/Button'
import TextControl from '@/components/controls/TextControl'
import UsersIcons from '@/components/icons/users'
import getFastInterval from '@/helpers/getFastInterval'
import useUrlParseAndPush from '@/hooks/useUrlParseAndPush'
import createNotifications from '@/helpers/notification'
import {
  START_DATE,
  END_DATE,
  PERIOD,
  INTERVAL,
} from '@/constants/forms/tyumenOurHomeFilter'
import {
  DATE_INTERVAL,
  ANNUALLY,
} from '@/constants/tyumenOurHome'
import { DEBOUNCE_DELAY_SMALL } from '@/constants/time'
import {
  TABLE,
  GRAPH,
} from '@/constants/ObjectControllStatistic'
import {
  periodOptions,
  intervalOptions,
  tableConfig,
  exportOptions,
  structureOptions,
} from './config'
import {
  FormContainer,
  CustomDatePicker,
  SearchContainer,
  ElementContainer,
  Text,
} from './styles'
import { FormattedMessage as Lang } from 'react-intl'

const TyumenOurHomeFilter = ({
  setFormValues,
}) => {
  const [displayElementType, setDisplayElementType] = useState(GRAPH)
  
  const formicForm = useRef(null)
  const [initialValues, setInitialValues] = useUrlParseAndPush()
  useEffect(() => {
    const { structure } = initialValues
    if (!structure) {
      setInitialValues({
        ...initialValues,
        structure: GRAPH,
      })
    }
    if (structure && displayElementType !== structure) {
      setDisplayElementType(structure)
    }
  }, [setDisplayElementType, initialValues, setInitialValues, displayElementType])

  const parsedInitialValues = useMemo(() => {
    const newUrlParse = {
      ...initialValues,
    }
    const startDate = initialValues[START_DATE] ? { [START_DATE]: new Date(initialValues[START_DATE]) } : {}
    const endDate = initialValues[END_DATE] ? { [END_DATE]: new Date(initialValues[END_DATE]) } : {}
    return {
      ...newUrlParse,
      ...startDate,
      ...endDate,
    }
  }, [initialValues])

  // const handleResetFilter = useCallback(() => {
  //   setFormValues({})
  //   formicForm.current.resetForm({})
  // }, [setFormValues])

  const showNotification = debounce(() => {
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Измените диапазон или выберите другой интервал',
      type: 'error',
    })
  }, DEBOUNCE_DELAY_SMALL)

  const datePickerFilter = useCallback((formValues) => {
    const startDate = new Date(formValues[START_DATE])
    const endDate = new Date(formValues[END_DATE])
    const dateDifference = moment(formValues[END_DATE]).diff(moment(formValues[START_DATE]), 'days')
    if (formValues[INTERVAL] !== ANNUALLY) {
      if ((dateDifference > DATE_INTERVAL[formValues[INTERVAL]]
          || startDate > endDate)
          && formValues[START_DATE]
          && formValues[END_DATE]
      ) {
        showNotification()
      }
    }
  }, [showNotification])

  const handleValidateValues = useCallback((formValues) => {
    if(displayElementType !== formValues.structure) {
      setInitialValues({
        structure: formValues.structure,
      })
    } else {
      datePickerFilter(formValues)
      setInitialValues({
        ...formValues,
      })
    }
    setDisplayElementType(formValues.structure)
    return null
  }, [setInitialValues, datePickerFilter, displayElementType])

  const handleSetFastInterval = useCallback((formValues) => (fieldName, value) => {
    getFastInterval(formValues, value, formicForm, setFormValues)
  }, [setFormValues])

  const onSubmit = useCallback(() => {}, [])

  const handleSetGraphValue = useCallback(() => (fieldName, value) => {
    const comparison = Object.prototype.toString.call(value) === '[object Date]'
      ? value.valueOf() !== new Date(initialValues[fieldName]).valueOf()
      : value !== initialValues[fieldName]
    if (comparison) {
      formicForm.current.setFieldValue(PERIOD, null)
    }
  }, [initialValues])

  return (
    <Formik
      ref={formicForm}
      onSubmit={onSubmit}
      validate={handleValidateValues}
      initialValues={parsedInitialValues}
      render={({
        handleSubmit,
        values,
      }) => (
        <FormContainer onSubmit={handleSubmit}>
          {values.structure === TABLE && (
            <SearchContainer>
                <TextControl
                  dark
                  placeholder={<Lang id={"mapsPage.titles.tableSearch"}/>}
                  name="search"
                  icon={UsersIcons.MagnifierIcon}
                  onChange={noop}
                />
                <Button>
                  <Lang id="search.buttons.accept" />
                </Button>
            </SearchContainer>
          )}
          <ElementContainer>
            <SelectField
              withRadioButton
              largeOptions
              name={'structure'}
              placeholder={"Структура"}
              options={structureOptions}
              onAfterChange={handleSetFastInterval(values)}
            />
          </ElementContainer>
          {values.structure === TABLE && (
            <ElementContainer>
              <SelectField
                largeOptions
                name={'column'}
                multiselect
                placeholder={"Настроить столбцы"}
                options={tableConfig}
                onAfterChange={handleSetFastInterval(values)}
              />
            </ElementContainer>
          )}
          {values.structure && (
            <ElementContainer>
                <SelectField
                  withRadioButton
                  largeOptions
                  name={'period'}
                  withSearch
                  placeholder={"Период"}
                  options={periodOptions}
                  onAfterChange={handleSetFastInterval(values)}
                />
              </ElementContainer>
          )}
          {values.structure && (
            <>
              <ElementContainer datePicker>
                <CustomDatePicker
                  name={START_DATE}
                  maxDate={new Date()}
                  onAfterChange={handleSetGraphValue()}
                />
              </ElementContainer>
              <Text datePicker>
                -
              </Text>
              <ElementContainer datePicker>
                <CustomDatePicker
                  name={END_DATE}
                  maxDate={new Date()}
                  onAfterChange={handleSetGraphValue()}
                />
              </ElementContainer>
            </>
          )}
          {values.structure && (
            <ElementContainer>
              <SelectField
                largeOptions
                withRadioButton
                name={'interval'}
                placeholder={"Интервал"}
                options={intervalOptions}
                onAfterChange={handleSetFastInterval(values)}
              />
            </ElementContainer>
          )}
          {values.structure === TABLE && (
            <ElementContainer>
              <SelectField
                largeOptions
                name={'export'}
                withSearch
                placeholder={"Экспорт"}
                options={exportOptions}
                onAfterChange={handleSetFastInterval(values)}
              />
            </ElementContainer>
          )}
        </FormContainer>
      )}
    />
  )
}

TyumenOurHomeFilter.propTypes = {
  setFormValues: pt.func,
  getData: pt.func,
}

TyumenOurHomeFilter.defaultProps = {
  setFormValues: noop,
  getData: noop,
}

export default React.memo(TyumenOurHomeFilter)
