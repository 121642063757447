import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const ApplicationsContainer = styled.div`
  width: calc(100vw - 30px);
  min-height: 72px;
  display: flex;
  // justify-content: space-evenly
  margin: 7.5px 7.5px 0 7.5px;
  gap: 5px;
  .swiper-slide{
    width: auto;
  }
`
export const StyledLink = styled(Link)`
  text-decoration: none;
`