import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import MapsIcons from '@/components/icons/maps'
import generateWidget from './generateWidget'

export const STATUS = generateWidget({
  config: {
    id: 'STATUS',
    title: <Lang id="widgets.objectStatus" />,
    type: 'status',
    timeSelector: 'timestamp',
  },
})

export const LIGHT_MESH = generateWidget({
  config: {
    id: 'LIGHT_MESH',
    title: <Lang id="widgets.illuminanceLevel" />,
    minValue: 0,
    maxValue: 100,
    units: '%',
    percentValue: true,
  },
  selectorTemplate: 'illuminanceLevel',
})

export const TRASH = generateWidget({
  config: {
    id: 'TRASH',
    type: 'trash',
  },
  mockValue: {
    temperature: 5,
    fillingLevel: 54,
  },
})

export const LAST_DEVASTATION_SMALL = generateWidget({
  config: {
    id: 'LAST_DEVASTATION_SMALL',
    title: <Lang id="widgets.lastDevastation" />,
    type: 'smallWidget',
    units: 'time',
  },
  selectorTemplate: 'dateLastEmptying'
})

export const TYPE_OF_WASTE = generateWidget({
  config: {
    id: 'TYPE_OF_WASTE',
    title: <Lang id="widgets.wasteType" />,
    type: 'smallWidget',
    units: 'string',
    icon: <MapsIcons.TypeOfWasteIcon />,
  },
  selectorTemplate: 'storedWasteKind',
})

export const CATEGORY_OF_WASTE = generateWidget({
  config: {
    id: 'CATEGORY_OF_WASTE',
    title: <Lang id="widgets.category" />,
    type: 'smallWidget',
    units: 'string',
  },
  selectorTemplate: 'category',
})

export const LAST_CLEANING = generateWidget({
  config: {
    id: 'LAST_CLEANING',
    title: <Lang id="widgets.lastCleaning" />,
    type: 'smallWidget',
    units: 'time',
  },
  selectorTemplate: 'dateLastCleaning'
})

export const NEXT_CLEANING = generateWidget({
  config: {
    id: 'NEXT_CLEANING',
    title: <Lang id="widgets.nextCleaning" />,
    type: 'smallWidget',
    units: 'time',
  },
  selectorTemplate: 'nextCleaningDeadline'
})

// export const LAST_DEVASTATION_BIG = generateWidget({
//   config: {
//     id: 'LAST_DEVASTATION_BIG',
//     title: 'Последнее опустошение',
//     units: 'ч.',
//   },
// })

export const LIGHT_MESH_STATUS = generateWidget({
  config: {
    id: 'LIGHT_MESH_STATUS',
    title: <Lang id="widgets.objectStatus" />,
    type: 'status',
    timeSelector: 'timestamp',
    mode: true,
  },
})

// export const LIGHT_LEVEL_SMALL = generateWidget({
//   config: {
//     id: 'LIGHT_LEVEL_SMALL',
//     title: 'Уровень диммирования',
//     type: 'smallWidget',
//     icon: <MapsIcons.LightLevelIcon />,
//     units: '%',
//   },
//   selectorTemplate: 'illuminanceLevel',
// })
//
// export const VOLTAGE_SMALL = generateWidget({
//   config: {
//     id: 'VOLTAGE_SMALL',
//     title: 'Напряжение',
//     type: 'smallWidget',
//     icon: <MapsIcons.VoltageIcon />,
//     units: 'В',
//   },
//   selectorTemplate: 'phase{number}Voltage',
// })

export const BIG_LIGHT = generateWidget({
  config: {
    id: 'BIG_LIGHT',
    type: 'bigLight',
  },
})

export const LAST_INCLUSION = generateWidget({
  config: {
    id: 'LAST_INCLUSION',
    title: <Lang id="widgets.lastOn" />,
    type: 'smallWidget',
    units: 'time',
  },
  selectorTemplate: 'dateLastSwitchingOn',
})

export const LAST_SHUTDOWN = generateWidget({
  config: {
    id: 'LAST_SHUTDOWN',
    title: <Lang id="widgets.lastOff" />,
    type: 'smallWidget',
    units: 'time',
  },
  selectorTemplate: 'dateLastSwitchingOff',
})

export const WORKING_MODE = generateWidget({
  config: {
    id: 'WORKING_MODE',
    title: <Lang id="widgets.availableModes" />,
    type: 'smallWidget',
    units: 'text',
    condition: {
      semiautomatic: <Lang id="widgets.semiautomatic" />,
      automatic: <Lang id="widgets.automatic" />,
    },
  },
  selectorTemplate: 'currentWorkingMode',
})
export const CONTROLLER_WORKING_MODE = generateWidget({
  config: {
    id: 'CONTROLLER_WORKING_MODE',
    title: <Lang id="widgets.availableModes" />,
    type: 'smallWidget',
    units: 'text',
    condition: {
      semiautomatic: <Lang id="widgets.semiautomatic" />,
      automatic: <Lang id="widgets.automatic" />,
    },
  },
  selectorTemplate: 'workingMode',
})

export const LAST_UPDATE = generateWidget({
  config: {
    id: 'LAST_UPDATE',
    title: <Lang id="widgets.lastUpdate" />,
    type: 'smallWidget',
    units: 'time',
  },
  selectorTemplate: 'dateModified',
})

export const ILLUMINANCE = generateWidget({
  config: {
    id: 'ILLUMINANCE',
    title: <Lang id="widgets.illuminance" />,
    units: <Lang id="widgets.illuminanceUnits" />,
  },
  selectorTemplate: 'illuminance',
})

export const LIGHT_LOCATION = generateWidget({
  config: {
    id: 'LIGHT_LOCATION',
    title: <Lang id="widgets.locationCategory" />,
    type: 'smallWidget',
    units: 'text',
    condition: {
      sidewalk: <Lang id="widgets.sidewalk" />,
    },
  },
  selectorTemplate: 'locationCategor',
})

export const ACTIVE_POWER = generateWidget({
  config: {
    id: 'ACTIVE_POWER',
    title: <Lang id="widgets.powerConsumption" />,
    units: <Lang id="widgets.powerUnits" />,
  },
  selectorTemplate: 'activePower',
})

export const LAMP_ACTIVE_POWER = generateWidget({
  config: {
    id: 'LAMP_ACTIVE_POWER',
    title: <Lang id="widgets.lightPowerConsumption" />,
    units: <Lang id="widgets.powerUnits" />,
  },
  selectorTemplate: 'lampActivePower',
})
