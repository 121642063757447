import { GET_SEARCH_REPORT_FILE } from './index'

export const requestSearchReportFile = (payload) => ({
    type: GET_SEARCH_REPORT_FILE.REQUEST,
    payload,
})

export const successSearchReportFile = (payload) => ({
    type: GET_SEARCH_REPORT_FILE.SUCCESS,
    payload,
})

export const errorSearchReportFile = (payload) => ({
    type: GET_SEARCH_REPORT_FILE.ERROR,
    payload,
})