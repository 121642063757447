import { connect } from 'react-redux'
import { onToggleMenu, pinnedSideBarMenu } from '@/store/actions/appSettings'
import {
  getUserData,
  getApplications,
  getSideBarMenuPinnedState,
  getIsMenuOpen,
} from '@/store/selectors/appSettings'
import ApplicationMobile from './component'

export default connect(
  (state) => ({
    user: getUserData(state),
    applications: getApplications(state),
    isSideBarMenuPinned: getSideBarMenuPinnedState(state),
    isMenuOpen: getIsMenuOpen(state),
  }),
  (dispatch) => ({
    pinnedSideBarMenu: (state) => dispatch(pinnedSideBarMenu(state)),
    onToggleMenu: (state) => dispatch(onToggleMenu(state)),
  }),
)(ApplicationMobile)