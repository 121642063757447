import React from 'react'
import pt from 'prop-types'
import { FormattedMessage as Lang } from 'react-intl'
import debounce from 'lodash/debounce'
import get from 'lodash/get'
import { getIsNotRegion, getZoomByType } from '@/helpers/maps'
import { getNodePath } from '@/helpers/viewTree/formaters'
import { filterTypes } from '@/constants/maps'
import { mash, mashDisplayElements } from '@/data/mapTree/mashTreeData'
import YandexMap from '@/components/blocks/YandexMap'
import { DEBOUNCE_DELAY_SMALL } from '@/constants/time'
import ObjectPassportization from './components/ObjectPassportization'
import useHtmlTitle from '@/hooks/useHtmlTitle'
import {
  Main,
  MapControls,
  MapControlsContent,
} from './styles'
import { MAP_SERVICE } from '@/constants/applications'
import {
  GEOZONE,
  GROUP,
  INTEGRATION,
  PROJECT,
  ROOT
} from '@/constants/objectTypes'
import { INSTALLED } from '@/constants/objectStatuses'
import TreeSidebar from '@/containers/pages/Installation/components/TreeSidebar'
import HeaderPageMobile from '@/components/blocks/HeaderPageMobile'
import REQUEST_STATUSES from '@/constants/requests'
import { CONNECTED_LINES, MESH } from '@/constants/instalationPassport/types'
import useGetIsMobile from '@/hooks/useGetIsMobile'
import ButtonTree from '@/components/blocks/ButtonTree'


const DEFAULT_CENTER = [64.045274, 90.513266]

const withHooks = (Component) => {
  return function(props) {
    const isMobile = useGetIsMobile()
    useHtmlTitle(props.intl.messages['menu.objects'])
    return (
        <Component {...props} isMobile={isMobile}/>
    );
  };
}

class Maps extends React.PureComponent {
  handleChange = debounce((value) => {
    this.setState({ search: value })
  }, DEBOUNCE_DELAY_SMALL)

  constructor(props) {
    super(props)
    const { selectedNode, userApplications } = props
    this.state = {
      mapZoom: (selectedNode && selectedNode.aliasId) ? 19 : 3,
      mapCenter: selectedNode.location || DEFAULT_CENTER,
      search: '',
      hoverZoneByID: null,
      hoveredItemByID: null,
      passportIsOpen: null,
      typesOfRegionPins: filterTypes,
      selectedZoneIdentifier: null,
      userApplications: userApplications,
      fromTree: true,
      isChildren: true,
      openTree: false,
      openMapLayer: false,
    }
  }

  componentDidMount() {
    const { displayElements, successGetPinsAndZonesAndTelemetry, isMobile } = this.props
    if (displayElements.length !== 0) {
      successGetPinsAndZonesAndTelemetry({
        displayElements: [],
      })
    }
    if (isMobile) {
      this.setState(() => ({
        passportIsOpen: false,
      }))
    }
  }

  componentDidUpdate() {
    const { selectedNode, displayElementAndTelemetryRequestStatus,requestGetPinsAndZonesAndTelemetry, roots } = this.props
    const { fromTree } = this.state
    if (
      displayElementAndTelemetryRequestStatus === REQUEST_STATUSES.NOT_REQUESTED && 
      roots.length && 
      selectedNode.id && 
      selectedNode.treeNodeType !== PROJECT && 
      selectedNode.treeNodeType !== INTEGRATION && 
      selectedNode.type !== ROOT &&
      fromTree 
      ) {
      requestGetPinsAndZonesAndTelemetry({
        parentTreeId: selectedNode.path[1] || selectedNode.id,
        isMonitoring: true
      })
        this.setState(() => ({
          selectedZoneIdentifier: selectedNode.treeNodeType === GEOZONE ? selectedNode.id : selectedNode.path[1],
          mapCenter: [selectedNode.point.latitude, selectedNode.point.longitude],
          mapZoom:  getZoomByType(selectedNode.type),
          fromTree: false,
      }))
    }
  }

  setSelectedZoneIdentifier = (id) => {
    this.setState(() => ({
      selectedZoneIdentifier: id,
    }))
  }

  getIDOfSelectedZone = (element) => () => {
    this.setState(() => ({
      mapCenter: [element.point.latitude, element.point.longitude],
      mapZoom: getZoomByType(element.type),
    }))
  }

  setMapCenterForLocationZoom = (element) => () => {
    this.setState(() => ({
      mapCenter: [element.point.latitude, element.point.longitude],
      mapZoom: getZoomByType(element.treeNodeType),
    }))
  }

  setIdOfSelectedPinOnMouseEnter = (element) => {
    this.setState({ hoveredItemByID: element.id })
  }

  setIdOfSelectedPinOnMouseLeave = () => {
    this.setState({ hoveredItemByID: null })
  }

  listItemMapMouseEnter = (element) => {
    this.setState({ hoveredItemByID: element.id })
  }

  listItemMapMouseLeave = () => {
    this.setState({ hoveredItemByID: null })
  }

  treeZoneMouseEnter = (element) => {
    this.setState({ hoverZoneByID: element.id })
  }

  treeZoneMouseLeave = () => {
    this.setState({ hoverZoneByID: null })
  }

  handleMapLayer = (value) => {
    this.setState({openMapLayer: value})
  }

  selectNodeByZone = (node) => {
    const { requestGetPinsAndZonesAndTelemetry, isMobile } = this.props
    requestGetPinsAndZonesAndTelemetry({
      includeAll: true,
      parentTreeId: node.id,
      isMonitoring:true
    })
    this.setMapCenterForLocationZoom(node)()
    if (!isMobile) {
      this.setState(() => ({
        passportIsOpen: true,
      }))
    }
  }

  getSelectedNode = () => {
    const { treeData, selectedNode } = this.props
    return get(treeData, getNodePath(selectedNode), {})
  }

  updateMapZoom = (zoom) => {
    this.setState(() => ({ mapZoom: zoom }))
  }

  getCard = (node) => {
    const { requestGetCard, requestGetObjectTelemetry, setDisplayElementAndTelemetryRequestStatus } = this.props
    if (node.installationType === GROUP) {
      setDisplayElementAndTelemetryRequestStatus(REQUEST_STATUSES.NOT_REQUESTED)
    } else {
      requestGetCard({ id: node.id, type: node.systemType })
      requestGetObjectTelemetry({
        aliasId: node.asuId,
        integrationType: node.integrationType,
        objectId: node.objectIdOuter,
        objectType: node.installationType
      })
    }
  }

  getNodeStatistic = (node) => {
    const { requestGetNodeStatistic } = this.props
    if (node.treeNodeType === GEOZONE && node.id) {
      requestGetNodeStatistic({ id: node.id })
      this.setState(() => ({
        isStatisticRequested: true,
      }))
    }
  }

  handleOpenPassport = (node, clickFromTree) => {
    const { setNode, selectedNode, mapLayer, isMobile, setSearchTree } = this.props
    const { selectedZoneIdentifier } = this.state
    if (mapLayer !== MESH) {
      setNode(node)
      this.getCard(node)
      this.getIDOfSelectedZone(node)()
      if (selectedNode.path) {
        this.setState(() => ({
          passportIsOpen: !isMobile || !clickFromTree,
          fromTree: ((selectedNode.path[1] !== node.path[1]) || node.treeNodeType === GEOZONE || !selectedZoneIdentifier)
        }))
      }
      if (!clickFromTree) {
        this.setState(() => ({
          isChildren: true
        }))
        setSearchTree('')
      }
      if (selectedNode && node && selectedNode.id !== node.id) {
        if (clickFromTree) {
          this.setSelectedZoneIdentifier(node.treeNodeType === GEOZONE ? node.id : node.path[1])
        }
      }
    }
  }

  getIsPassportOpen = () => {
    const { passportIsOpen } = this.state
    const { selectedNode } = this.props
    if (
      selectedNode
      && passportIsOpen !== false
      && (selectedNode.objectState === INSTALLED || selectedNode.treeNodeType === GEOZONE)
      && selectedNode.id
    ) {
      return true
    }
    return false
  }

  getIsTreeOpen = () => {
    const { isGlobalFilterOpen } = this.props
    const { openTree, openMapLayer } = this.state

    if (isGlobalFilterOpen || this.getIsPassportOpen() || openMapLayer) {
      return true
    }

    if ( !openTree ) {
      return true
    }

    return false
  }

  handleClosePassport = () => {
    this.setState({
      passportIsOpen: false,
    })
  }

  onClickButtonTree = () => {
    this.setState({
      openTree: true,
    })
  }

  onCloseTree = () => {
    this.setState({
      openTree: false,
    })
  }

  viewTreeOnSelect = (node, actionType) => {
    const { mapLayer } = this.props
    if (mapLayer !== MESH) {
      if (actionType === 'click') {
        this.setState(() => ({
          fromTree: true,
          isChildren: !!node.childrenCount
        }))
        this.getNodeStatistic(node)
        this.handleOpenPassport(node, true)
      }
      if (actionType === 'in') {
        return getIsNotRegion(node.type)
            ? this.listItemMapMouseEnter(node)
            : this.treeZoneMouseEnter(node)
      }
      if (actionType === 'out') {
        return getIsNotRegion(node.type)
            ? this.listItemMapMouseLeave(node)
            : this.treeZoneMouseLeave(node)
      }
      return null
    } else {
      if (actionType === 'click') {
        this.setState(() => ({
          fromTree: true
        }))
        this.setSelectedZoneIdentifier(node.treeNodeType === GEOZONE ? node.id : node.path[1])
        this.getIDOfSelectedZone(node)()
      }
    }
  }

  render() {
    const {
      mapCenter,
      mapZoom,
      hoveredItemByID,
      hoverZoneByID,
      selectedZoneIdentifier,
      userApplications,
      isChildren,
    } = this.state
    const yandexKey = userApplications.filter(item => item.code === MAP_SERVICE)[0]?.settings
    const {
      zones,
      refreshIntegration,
      pinnedNode,
      objectImage,
      selectedNode,
      displayElements,
      globalFilters,
      mapLayer,
      requestGetConnectedLinesCoordinates,
      isMobile,
      isRussianBorders,
    } = this.props
    return (
        <Main>
          {isMobile && 
            <>
              {!this.getIsPassportOpen() && <HeaderPageMobile title={'Центр Мониторинга'} moveToPinnedNode={this.handleOpenPassport} mapLayer={mapLayer} handleMapLayer={this.handleMapLayer}/>}
              <TreeSidebar
                title={<Lang id={"mapsPage.title"}/>}
                onSelect={this.viewTreeOnSelect}
                hidden={this.getIsTreeOpen()}
                iconDropdown
                selectedNode={selectedNode}
                isMashMode={mapLayer === MESH}
                isMonitoring
                isChildren={isChildren}
                onCloseTree={this.onCloseTree}
                isMobile={isMobile}
              />
              {!this.getIsPassportOpen() && <ButtonTree onClick={this.onClickButtonTree}/>}
            </>
          }
          {!isMobile && 
            <TreeSidebar
              title={<Lang id={"mapsPage.title"}/>}
              onSelect={this.viewTreeOnSelect}
              hidden={false}
              iconDropdown
              selectedNode={selectedNode}
              isMashMode={mapLayer === MESH}
              isMonitoring
              isChildren={isChildren}
              onCloseTree={this.onCloseTree}
              isMobile={isMobile}
            />
          }
          <MapControls>
            <MapControlsContent>
              {this.getIsPassportOpen() && mapLayer !== MESH && (
                  <ObjectPassportization
                      onClose={this.handleClosePassport}
                      node={selectedNode}
                      getCard={this.getCard}
                      getNodeStatistic={this.getNodeStatistic}
                      refreshIntegration={refreshIntegration}
                      image={objectImage}
                      requestGetConnectedLinesCoordinates={requestGetConnectedLinesCoordinates}
                      mapLayer={mapLayer}
                      isMobile={isMobile}
                      pinnedNode={pinnedNode}
                  />
              )}
              <YandexMap
                  pinnedNode={pinnedNode}
                  globalZoneId={selectedZoneIdentifier}
                  setSelectedZoneIdentifier={this.setSelectedZoneIdentifier}
                  zones={zones}
                  isMonitoring
                  onClick={this.handleClosePassport}
                  pins={mapLayer === MESH ? mashDisplayElements : displayElements}
                  mapCenter={mapCenter}
                  mapZoom={mapZoom}
                  mash={mapLayer === MESH ? mash : []}
                  isMashMode={mapLayer === MESH}
                  isLinesMode={mapLayer === CONNECTED_LINES}
                  hoveredPinByID={hoveredItemByID}
                  hoveredZoneByID={hoverZoneByID}
                  updateMapZoom={this.updateMapZoom}
                  handleOpenPassport={this.handleOpenPassport}
                  pinOnEnterHandler={this.setIdOfSelectedPinOnMouseEnter}
                  pinOnLeaveHandler={this.setIdOfSelectedPinOnMouseLeave}
                  selectNodeByZone={this.selectNodeByZone}
                  yandexKey={yandexKey}
                  globalFilters={globalFilters}
                  isMobile={isMobile}
                  isRussianBorders={isRussianBorders}
              />
            </MapControlsContent>
          </MapControls>
        </Main>
    )
  }
}

Maps.propTypes = {
  loading: pt.bool.isRequired,
  objectImage: pt.string.isRequired,
  setNode: pt.func.isRequired,
  requestGetCard: pt.func,
  refreshIntegration: pt.func.isRequired,
  requestObjectImage: pt.func.isRequired,
  regions: pt.arrayOf(pt.object).isRequired,
  objectTelemetry: pt.objectOf(pt.object).isRequired,
  pins: pt.arrayOf(pt.object).isRequired,
  selectedNode: pt.shape({
    id: pt.oneOfType([pt.string, pt.number]),
    location: pt.arrayOf(pt.number),
    type: pt.string,
    status: pt.string,
  }).isRequired,
  userApplications: pt.arrayOf(pt.shape({
    id: pt.oneOfType([pt.string, pt.number]),
    code: pt.string,
    defaultType: pt.string,
    name: pt.string,
    placement: pt.string,
    settings: pt.string,
    type: pt.string,
  })),
}

export default withHooks(Maps)
