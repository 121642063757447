import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const CheckedIcon = ({ color, onClick }) => {
  return (
    <svg onClick={onClick} enableBackground="new 0 0 32 32" height="32px" id="Слой_1" version="1.1" viewBox="0 0 32 32" width="32px" xmlSpace="preserve" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_2861_81404)">
            <rect width="32" height="32" rx="16" fill={color}/>
            <g clip-path="url(#clip1_2861_81404)">
                <path d="M16.0003 2.66699C8.66699 2.66699 2.66699 8.66699 2.66699 16.0003C2.66699 23.3337 8.66699 29.3337 16.0003 29.3337C23.3337 29.3337 29.3337 23.3337 29.3337 16.0003C29.3337 8.66699 23.3337 2.66699 16.0003 2.66699ZM16.0003 26.667C10.1203 26.667 5.33366 21.8803 5.33366 16.0003C5.33366 10.1203 10.1203 5.33366 16.0003 5.33366C21.8803 5.33366 26.667 10.1203 26.667 16.0003C26.667 21.8803 21.8803 26.667 16.0003 26.667ZM22.1203 10.107L13.3337 18.8937L9.88032 15.4537L8.00033 17.3337L13.3337 22.667L24.0003 12.0003L22.1203 10.107Z" fill="#FAFBFB"/>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_2861_81404">
                <rect width="32" height="32" rx="16" fill="white"/>
            </clipPath>
            <clipPath id="clip1_2861_81404">
                <rect width="32" height="32" fill="white"/>
            </clipPath>
        </defs>
    </svg>
)}

CheckedIcon.propTypes = {
  onClick: pt.func,
}

CheckedIcon.defaultProps = {
  onClick: noop,
}

export default memoWithName(CheckedIcon)
