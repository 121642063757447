import React, { useMemo, useState, useCallback } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import Button from '@/components/blocks/Button'
import MapsIcons from '@/components/icons/maps'
import HeaderIcons from '@/components/icons/header'
import {
  WidgetContainer,
  WidgetHeader,
  WidgetCloseIcon,
  WidgetContent,
  Title,
  WidgetComponent,
  WidgetDescription,
} from './styles'
import { FormattedMessage as Lang } from 'react-intl'

const BaseWidget = ({
  id,
  large,
  menuIcon: Icon,
  maxCount,
  title,
  description,
  widgetType,
  renderComponent,
  onAddWidget,
  addedWidgets,
}) => {
  const [isOpen, setIsOpen] = useState(false)
  const hasMaxCount = useMemo(() => (
    maxCount
      ? addedWidgets.filter((application) => application.type === id).length === maxCount
      : false
  ), [addedWidgets, id, maxCount])
  const isEnabledWidget = useMemo(
    () => !!(description && renderComponent && !hasMaxCount),
    [description, hasMaxCount, renderComponent],
  )

  const handleOpenWidget = useCallback(() => {
    if (isEnabledWidget) {
      setIsOpen(true)
    }

    return null
  }, [isEnabledWidget, setIsOpen])
  const handleCloseWidget = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
    setIsOpen(false)
  }, [setIsOpen])

  const handleAddWidget = useCallback(() => {
    onAddWidget(id, widgetType, large)
    setIsOpen(false)
  }, [onAddWidget, widgetType, id, large])

  return (
    <WidgetContainer>
      <WidgetHeader disabled={!isEnabledWidget} onClick={handleOpenWidget}>
        <Icon />
        <Title>{title}</Title>
        {isOpen && (
          <WidgetCloseIcon onClick={handleCloseWidget}>
            <HeaderIcons.MenuCloseIcon />
          </WidgetCloseIcon>
        )}
      </WidgetHeader>
      {isOpen && (
        <WidgetContent>
          <WidgetDescription>{description}</WidgetDescription>
          <WidgetComponent>{renderComponent()}</WidgetComponent>
          <Button onClick={handleAddWidget}>
            <Lang id="buttons.addToDesktop" />
          </Button>
        </WidgetContent>
      )}
    </WidgetContainer>
  )
}

BaseWidget.propTypes = {
  id: pt.number,
  large: pt.bool,
  maxCount: pt.number,
  menuIcon: pt.element,
  title: pt.string,
  description: pt.string,
  widgetType: pt.string,
  renderComponent: pt.func,
  onAddWidget: pt.func,
  addedWidgets: pt.arrayOf(pt.object),
}
BaseWidget.defaultProps = {
  id: null,
  large: false,
  maxCount: null,
  menuIcon: MapsIcons.DotsIcon,
  title: null,
  description: null,
  widgetType: null,
  renderComponent: noop,
  onAddWidget: noop,
  addedWidgets: [],
}

export default BaseWidget
