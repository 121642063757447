import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import moment from 'moment'
import request from '@/helpers/axios'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'
import createNotifications from '@/helpers/notification'
import {
  GET_CONSUMPTION_ANALYSIS_FILE,
} from '@/store/actions/consumptionAnalysis'
import { generateResourceAnalyticsReportFileUrl } from '@/constants/apiRoutes'
import {
  successConsumptionAnalysisFile,
  errorConsumptionAnalysisFile,
} from '@/store/actions/consumptionAnalysis/getFile'
import { getSelectedNode } from '@/store/selectors/appSettings'
import {
  getForm,
  getTableParameters,
} from '@/store/selectors/consumptionAnalysis'
import { INSTALLED, UNINSTALLED } from '@/constants/objectStatuses'

export const EXTENSION_BY_TYPE = {
  pdf: 'pdf',
  excel: 'xlsx',
}

function* getConsumptionAnalysisFileSaga({ payload }) {
  const toast = createNotifications()
  try {
    const formValues = yield select(getForm)
    const node = yield select(getSelectedNode)
    const tableParameters = yield select(getTableParameters)
    const {
      type,
      dateFirst,
      dateSecond,
    } = formValues

    const response = yield request({
      url: generateResourceAnalyticsReportFileUrl({
        fileType: payload.value,
        params: {
          objectStates: [INSTALLED, UNINSTALLED],
          aliasId: node.asuId,
          startDate: moment(dateFirst).format('DD.MM.YYYY'),
          endDate: moment(dateSecond).format('DD.MM.YYYY'),
          includedColumns: tableParameters.activeFields,
          pageNumber: tableParameters.page - 1,
          pageSize: tableParameters.perPage,
          parentId: node.id,
          query: tableParameters.requestQuery || '',
          sort: [`${tableParameters.sort.field}: ${tableParameters.sort.direction}`],
          type: type.toUpperCase()
        }
      }),
      method: 'get',
      options: {
        responseType: 'blob',
      },
    })

    downloadFileFromBlob({
      blob: response.data,
      fileName: `SLS24-${node.id || 'all'}_${type}_table_report.${EXTENSION_BY_TYPE[payload.value]}`,
    })
    yield put(successConsumptionAnalysisFile())
  } catch (error) {
    yield put(errorConsumptionAnalysisFile(error))
    toast({
      title: 'Ошибка операции!',
      description: 'Невозможно выгрузить отчет.\nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_CONSUMPTION_ANALYSIS_FILE.REQUEST, getConsumptionAnalysisFileSaga)
}
