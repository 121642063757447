import React, { useCallback, useEffect } from 'react'
import {
  Route, Switch, useHistory,
} from 'react-router-dom'
import { FormattedMessage as Lang } from 'react-intl'
import qs from 'query-string'
import pt from 'prop-types'
import noop from 'lodash/noop'
import useHtmlTitle from '@/hooks/useHtmlTitle'
import withPermissions from '@/hocs/withPermissions'
import {
  ALARMS_ANALYTICS_URL,
  ALARMS_ANALYTICS_URL_WITH_MASK,
  SUMMARY_ALARMS_ANALYTICS_URL,
} from '@/constants/routes'
import { ROOT } from '@/constants/objectTypes'
import { ID_BY_APPLICATIONS } from '@/constants/widgets'
import SummaryAlarmsAnalyticsBlock from './components/SummaryAlarmsAnalytics'
import AlarmsAnalyticsBlock from './components/AlarmsAnalytics'
import { Main } from './styles'
import REQUEST_STATUSES from '@/constants/requests'
import TreeSidebar from '@/containers/pages/Installation/components/TreeSidebar'
import useGetIsMobile from '@/hooks/useGetIsMobile'

const AlarmsAnalyticsPage = ({
  tree,
  setNode,
  requestGetTree,
  requestState,
  selectedNode,
  intl,
}) => {
  const { push } = useHistory()
  useHtmlTitle(intl.messages['menu.analyticsAlarms'])
  const isMobile = useGetIsMobile()

  const node = {...selectedNode, original: {options: {path: `/${selectedNode.parentId}/${selectedNode.id}`}}}

  useEffect(() => {
    if (tree && !tree.id && requestState.tree === REQUEST_STATUSES.NOT_REQUESTED) {
      if (selectedNode.type === ROOT) {
        setTimeout(() => {
          push(SUMMARY_ALARMS_ANALYTICS_URL)
        })
      } else if (selectedNode.id) {
        setTimeout(() => {
          push({
            pathname: `${ALARMS_ANALYTICS_URL}/${selectedNode.id}`,
            search: qs.stringify({
              name: encodeURIComponent(selectedNode.name),
            }),
          })
        })
      }
      requestGetTree()
    }
  }, [tree, requestGetTree, requestState, selectedNode, push])

  const onSelectNode = useCallback((node, event) => {
    if (event === 'click') {
      setNode(node)
    }
  },
  [setNode])

  return (
    <Main>
      {!isMobile && 
        <TreeSidebar
        title= {<Lang id="widgets.alarmAnalytics" />}
        onSelect={onSelectNode}
        hidden={false}
        selectedNode={node}
        treeId='analytics-alarms-tree'
        treeData={tree}
      />
      }
      <Switch>
        <Route
          exact
          path={SUMMARY_ALARMS_ANALYTICS_URL}
          component={withPermissions(
            SummaryAlarmsAnalyticsBlock,
            ID_BY_APPLICATIONS.ALARM_ANALYTICS,
          )}
        />
        <Route
          exact
          path={ALARMS_ANALYTICS_URL_WITH_MASK}
          component={withPermissions(
            AlarmsAnalyticsBlock,
            ID_BY_APPLICATIONS.ALARM_ANALYTICS,
          )}
        />
      </Switch>
    </Main>
  )
}

AlarmsAnalyticsPage.propTypes = {
  tree: pt.objectOf(pt.object),
  setNode: pt.func,
}
AlarmsAnalyticsPage.defaultProps = {
  tree: {},
  setNode: noop,
}

export default React.memo(AlarmsAnalyticsPage)
