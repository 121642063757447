import styled from 'styled-components'
import { Form } from 'formik'
import CheckBoxField from '@/components/fields/CheckBoxField'
import Button from '@/components/blocks/Button'
import { LIGHT_THEME_NAME } from '@/constants/names'
import DatePickerField from '@/components/fields/DatePickerField'

export const CustomDatePicker = styled(DatePickerField)`
  height: 28px;
`

export const CustomButton = styled(Button)`
  padding: 7px;
  margin-right: 10px;
`

export const FormContainer = styled(Form)`
  position: relative;
  width: 100%;
  min-height: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-left: 15px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borders.default};

  ${({ theme }) => {
    if (theme.themeName === LIGHT_THEME_NAME) {
      return `
          background: ${theme.colors.backgrounds.redisign.topDownGradient};
        `
    }
  }}
`

export const CustomCheckBoxField = styled(CheckBoxField)`
  margin-right: 10px;
`

export const ContentContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  input {
    width: 100%;
  }
  ${({ interval }) => interval && `
    margin-left: 10px;
  `};
  ${({ datePicker }) => datePicker && `
    margin-right: 0;
    width: 135px;
    .react-datepicker-popper {
      width: 300px;
      z-index: 4;
      top: -30px !important;
    }
  `};
`

export const Text = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
  ${({ reset }) => reset && `
    cursor: pointer;
  `};
  ${({ appeals }) => appeals && `
    padding: 0 10px;
    cursor: pointer;
  `};
  ${({ datePicker }) => datePicker && `
    margin-right: 16px;
    margin-left: 16px;
  `};
  ${({ title, theme }) => title && `
    height: 100%;
    display: flex;
    align-items: center;
    border-right: 1px solid ${theme.colors.borders.default};
    padding: 0 10px;
    min-width: 221px;
  `};
`
