import styled from 'styled-components'
export const IconContainer = styled.div`
  ${({ playAnimation }) => playAnimation && `
    svg {
      animation-duration: 1s;
      animation-iteration-count: infinite;
      animation-name: rotate;
    }

    @keyframes rotate {
      0% {
        transform: rotate(0deg);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  `}
`
export const Title = styled.div`
  font-weight: ${({ theme }) => theme.fontWeights.regular};
  color: ${({ theme, online }) => theme.colors.colors.redisign.default};
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 1.6rem;
  text-align: left;
  ${({ manyTitle, theme }) => manyTitle && `
    color: ${theme.colors.colors.redisign.default};
    width: 35%;
  `}

  ${({ disabled, theme }) => disabled && `
    color: ${theme.colors.colors.redisign.default};
    margin-top: 20px;
  `}
  ${({ status }) => status && `
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
  `}
  ${({ nowrap }) => nowrap && `
    white-space: nowrap;
  `}
    ${({ pb }) => pb && `
    padding-bottom: ${pb}px;
  `}
`