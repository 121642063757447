import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import {
  SCHEDULE,
  // UPLOAD_SCHEDULE_FILE
} from '@/constants/objectTypes'

export const createOptions = [
  { value: SCHEDULE, title: <Lang id="scheduleManager.form.newSchedule" /> },
  // { value: UPLOAD_SCHEDULE_FILE, title: <Lang id="scheduleManager.form.scheduleFile" /> },
]