import React from 'react'

import { Title, CheckBoxContainer, RadioButtonStyled, Container, CloseIconContainer, IconContainer } from './styles'
import Icons from '@/components/icons/users'



const SortShutter = ({
  title,
  onClose,
  withName,
  actions,
  setIsOpenPopup,
  setTypeSort,
  typeSort
}) => {
  
  const handleSetCheckBox = (value) => {
    setTypeSort(value)
    setIsOpenPopup(false)
    if (value === 'name') {
      actions.setSort({
        sortType: 'objectName',
        sortValue: 'ASC',
      })
    }
    if (value === 'ascendingDate') {
      actions.setSort({
        sortType: 'occurrenceDate',
        sortValue: 'ASC',
      })
    }
    if (value === 'descendingDate') {
      actions.setSort({
        sortType: 'occurrenceDate',
        sortValue: 'DESC',
      })
    }
  }

 

  return (
    <Container>
      <Title main>{title}</Title>
      <CloseIconContainer>
        <Icons.CrossIcon onClick={onClose}/>
      </CloseIconContainer>
      {withName && 
        <CheckBoxContainer>
          <RadioButtonStyled
            active={typeSort === 'name'}
            onChange={() => handleSetCheckBox('name')}
            label='Название объекта'
          />
          <IconContainer>
            <Icons.SortAlphabeticalIcon/>
          </IconContainer>
        </CheckBoxContainer>
      } 
      <CheckBoxContainer>
        <RadioButtonStyled
          active={typeSort === 'ascendingDate'}
          onChange={() => handleSetCheckBox('ascendingDate')}
          label='Дата возникновения'
        />
        <IconContainer>
          <Icons.SortDescendingIcon/>
        </IconContainer>
      </CheckBoxContainer>
      <CheckBoxContainer >
        <RadioButtonStyled
          active={typeSort === 'descendingDate'}
          onChange={() => handleSetCheckBox('descendingDate')}
          label='Дата возникновения'
        />
        <IconContainer>
          <Icons.SortAscendingIcon/>
        </IconContainer>
      </CheckBoxContainer>
    </Container>
  )
}



export default SortShutter
