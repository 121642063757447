import React, { useCallback } from 'react'
import memoWithName from '@/hocs/memoWithName'
import {
  IndicatorWrapper,
} from '@/components/widgets/StateIndicator/styles'
import CoreIcons from '@/components/icons/core'
import {
  IconContainer,
  Text,
} from './styles'

const TrashIndicator = ({
  className,
  telemetry
}) => {
  const fillingLevel = telemetry.fillingLevel ? (telemetry.fillingLevel.toFixed(2) * 100).toFixed(0) : '0'
  const calculateIndicatorHeight = useCallback((maxHeight, value, maxValue) => (
    (((maxHeight * value) / maxValue) || 0).toFixed(0)
  ), [])
  const calculateBackground = useCallback((value) => {
    if (value < 50) {
      return '#90C737'
    }
    if (value >= 50 && value < 80) {
      return '#FF9900'
    }
    if (value >= 80) {
      return '#F83D14'
    }
  }, [])
  return (
    <IndicatorWrapper
      column
      small
      className={className}
      withoutSvgStyle
    >
      <Text>
        {telemetry.temperature}
        °C
      </Text>
      <IconContainer
        thermometer
        value={telemetry.temperature}
        height={calculateIndicatorHeight(105, telemetry.temperature, 50)}
      >
        <CoreIcons.ThermometerIcon />
      </IconContainer>
      <IconContainer
        trash
        value={ fillingLevel || 0}
        background={calculateBackground(Number(fillingLevel))}
        height={calculateIndicatorHeight(107, fillingLevel || 0, 100)}
      >
        <CoreIcons.TrashCanIcon />
      </IconContainer>
    </IndicatorWrapper>
  )
}

TrashIndicator.propTypes = {
}
TrashIndicator.defaultProps = {
}

export default memoWithName(TrashIndicator)
