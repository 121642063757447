import {
  take, call
} from 'redux-saga/effects'
import createNotifications from '@/helpers/notification'
import { verifyIntegrationChannel } from './channels'
import { INTEGRATION_VERIFIED } from '@/constants/integrations'
import { treeHandlers } from 'react-hyper-tree'

function* verifyIntegrationSaga() {
    const toast = createNotifications()
    const channel = yield call(verifyIntegrationChannel)
    while (true) {
      const eventData = yield take(channel)
      console.log('function*verifyIntegrationsSaga -> eventData', eventData);
      switch (eventData.status) {
        case 'COMPLETED': {
          if (eventData.description === INTEGRATION_VERIFIED) {
            const tree = treeHandlers.trees['installations-tree']
            if (tree) {
              const parent = tree.instance.getNodeById(eventData.parentId)
              if (parent && Object.keys(parent).length) {
                const parentChildren = yield call(parent.data.getChildren, parent)
                tree.handlers.setRawChildren(parent, parentChildren, 'first', true)
                tree.handlers.rerender()
              }
              toast({
                title: 'Успешная операция!',
                type: 'success',
                description: `Верификация дерева АСУ\nинтеграции "${eventData.integrationName}" завершена.`,
              })
            }
          }
          break
        }
        case 'ON_FAILED': {
          console.log('function*verifyIntegrationSaga -> error')
          const toast = createNotifications()
          toast({
            title: 'Ошибка операции!',
            description: 'Верификация дерева АСУ не завершена.\nПовторите попытку позже.',
            type: 'error',
          })
          break
        }
        default: break
      }
    }
}

export default verifyIntegrationSaga
