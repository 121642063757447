export const OK = 'NO_PROBLEM'
export const WARNING = 'WARNING'
export const ERROR = 'ERROR'
export const OFF = 'ERROR'
export const INFO = 'UNDEFINED'
export const FIXED = 'FIXED'
export const NORMAL = 'NORMAL'

export const UNKNOWN = 'UNKNOWN'
export const NOT_VERIFIED = 'NOT_VERIFIED'
export const VERIFIED = 'VERIFIED'
export const NEW = 'NEW'
export const NOT_EXIST = 'NOT_EXIST'
export const NOT_INSTALLED = 'NOT_INSTALLED'
export const INSTALLED = 'INSTALLED'
export const UNINSTALLED = 'UNINSTALLED'

export const NULL = 'null'
