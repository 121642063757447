import { GET_PASSPORTIZATION_OPTIONS } from './index'

export const requestGetPassportizationOptions = (payload) => ({
  type: GET_PASSPORTIZATION_OPTIONS.REQUEST,
  payload,
})

export const successGetPassportizationOptions = (payload) => ({
  type: GET_PASSPORTIZATION_OPTIONS.SUCCESS,
  payload,
})

export const errorGetPassportizationOptions = (payload) => ({
  type: GET_PASSPORTIZATION_OPTIONS.ERROR,
  payload,
})