import React from 'react'
import memoWithName from '@/hocs/memoWithName'
import { TextButton } from './styles'

const ButtonTree = ({onClick}) => {
  return (
    <TextButton onClick={onClick}>Дерево объектов</TextButton>
  )
}

export default memoWithName(ButtonTree)
