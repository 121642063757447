import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import moment from 'moment'
import request from '@/helpers/axios'
import {
  GET_TYUMEN_MESSAGES_LIST,

  TYUMEN_MESSAGES_SET_SEARCH,
  TYUMEN_MESSAGES_SET_DATES,
} from '@/store/actions/tyumenOurHome'
import { generateTyumenOurHomeUrl } from '@/constants/apiRoutes'
import {
  successTyumenMessagesList,
  errorTyumenMessagesList,
} from '@/store/actions/tyumenOurHome/getMessagesList'
import {
  OK,
  WARNING,
  ERROR,
} from '@/constants/alarms'
import {
  START_DATE,
  END_DATE,
  STATUS,
} from '@/constants/forms/tyumenOurHomeFilter'
import {
  getType,
} from '@/store/selectors/tyumenOurHome'
import {
  IN_PROGRESS_STATUSSES,
  ERROR_STATUSSES,
  DONE_STATUSSES,
} from '@/constants/tyumenOurHomeProccesses'

function* getMessagesList({ payload }) {
  try {
    const type = yield select(getType)

    const status = () => {
      if (payload[STATUS] === OK) {
        return DONE_STATUSSES
      }
      if (payload[STATUS] === ERROR) {
        return ERROR_STATUSSES
      }
      if (payload[STATUS] === WARNING) {
        return IN_PROGRESS_STATUSSES
      }
      return [
        ...DONE_STATUSSES,
        ...ERROR_STATUSSES,
        ...IN_PROGRESS_STATUSSES,
      ]
    }

    const response = yield request({
      url: generateTyumenOurHomeUrl(type),
      method: 'post',
      body: {
        startDate: payload[START_DATE]
          ? moment(payload[START_DATE]).format('YYYY-MM-DD')
          : '2000-01-01',
        endDate: payload[END_DATE]
          ? moment(payload[END_DATE]).format('YYYY-MM-DD')
          : moment().format('YYYY-MM-DD'),
        query: payload.query,
        page: 0,
        size: 999999999,
        status: status(),
      },
    })

    const parsedResponse = (response.data.content || []).reduce((accumulator, row) => {
      if (IN_PROGRESS_STATUSSES.includes(row.status)) {
        return {
          ...accumulator,
          [WARNING]: [...accumulator[WARNING], row],
        }
      }
      if (ERROR_STATUSSES.includes(row.status)) {
        return {
          ...accumulator,
          [ERROR]: [...accumulator[ERROR], row],
        }
      }
      if (DONE_STATUSSES.includes(row.status)) {
        return {
          ...accumulator,
          [OK]: [...accumulator[OK], row],
        }
      }
      return accumulator
    }, {
      [OK]: [],
      [WARNING]: [],
      [ERROR]: [],
    })

    const dataMobile = (response.data.content || []).map((row) => {
      if (IN_PROGRESS_STATUSSES.includes(row.status)) {
        return {
          ...row,
          type: WARNING
        }
      }
      if (ERROR_STATUSSES.includes(row.status)) {
        return {
          ...row,
          type: ERROR
        }
      }
      if (DONE_STATUSSES.includes(row.status)) {
        return {
          ...row,
          type: OK
        }
      }
      return null
    })

    yield put(successTyumenMessagesList({
      data: parsedResponse,
      dataMobile: dataMobile,
    }))
  } catch (error) {
    yield put(errorTyumenMessagesList(error))
  }

  return null
}

export default function* root() {
  yield takeLatest(GET_TYUMEN_MESSAGES_LIST.REQUEST, getMessagesList)
  yield takeLatest(TYUMEN_MESSAGES_SET_SEARCH, getMessagesList)
  yield takeLatest(TYUMEN_MESSAGES_SET_DATES, getMessagesList)
}
