import widgets from '../widgets'

const {
    PHASE_STATE,
    ALARM_STATE,
    STATUS
} = widgets

export default [
    STATUS,
    PHASE_STATE({}),
    ALARM_STATE({}),
]