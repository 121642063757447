export const LIGHTS = [
  {
    id: 'svd',
    title: 'СВД',
    selector: 'lightFixtureCount'
  },
  {
    id: 'shuo',
    title: 'ШУНО',
    selector: 'controlCupboardCount'
  },
  {
    id: 'bs',
    title: 'БС',
    selector: 'baseStationCount'
  },
  {
    id: 'ahp',
    title: 'АХП',
    disabled: true,
  },
  {
    id: 'jku',
    title: 'ЖКУ',
    disabled: true,
  },
  {
    id: 'drl',
    title: 'ДРЛ',
    disabled: true,
  },
]

export const SOCIALS = [
  {
    id: 'school',
    title: 'Школы',
    selector: 'companyObjectCount'
  },
  {
    id: 'dou',
    title: 'ДОУ',
    disabled: true,
  },
  {
    id: 'medical',
    title: 'Больницы',
    disabled: true,
  },
]

export const ROADS = [
  {
    id: 'chargeStations',
    title: 'Зарядные автостанции',
    disabled: true,
  },
  {
    id: 'svetofor',
    title: 'Светофор',
    disabled: true,
  },
  {
    id: 'infoTable',
    title: 'Инфотабло',
    disabled: true,
  },
]
