import { GET_PASSPORTIZATION_TABLE_FILE } from './index'

export const requestPassportizationTableFile = (payload) => ({
  type: GET_PASSPORTIZATION_TABLE_FILE.REQUEST,
  payload,
})

export const successPassportizationTableFile = (payload) => ({
  type: GET_PASSPORTIZATION_TABLE_FILE.SUCCESS,
  payload,
})

export const errorPassportizationTableFile = (payload) => ({
  type: GET_PASSPORTIZATION_TABLE_FILE.ERROR,
  payload,
})