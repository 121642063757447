import {
  put, takeLatest, call
} from 'redux-saga/effects'
import { treeHandlers } from 'react-hyper-tree'
import request from '@/helpers/axios'
import { CREATE_INTEGRATION } from '@/store/actions/installation'
import {
  INSTALLATIONS_INTEGRATION_ENDPOINT,
  generateGetProjectCard
} from '@/constants/apiRoutes'
import {
  successCreateIntegration,
} from '@/store/actions/installation/createIntegration'
import createNotifications from '@/helpers/notification'
import { INTEGRATION } from '@/constants/objectTypes'
import updateNodeCount from '@/helpers/updateNodeCount'
import { setGlobalLoading } from '@/store/actions/appSettings'
import get from 'lodash/get'
import { requestGetAllConnectedIntegrations } from '@/store/actions/dictionaries/connectedIntegrations'

function* createIntegrationSaga({ payload }) {
  const {
    formValues, values, setUrlFormValues,
  } = payload
  const {
    name, integrationType,
  } = values
  try {
    yield put(setGlobalLoading(true))
    const { data: parentProjectData } = yield request({
      url: generateGetProjectCard(formValues.id),
      method: 'get',
    })
    const body = {
      parentId: parentProjectData.id,
      parentName: parentProjectData.parentName,
      name,
      integrationType,
    }
    const { data } = yield request({
      url: INSTALLATIONS_INTEGRATION_ENDPOINT,
      method: 'post',
      body,
    })
    const {
      rootId, FILTER, path,
    } = formValues
    const toast = createNotifications()
    const tree = treeHandlers.trees['installations-tree']
    const parent = tree.instance.getNodeById(data.parentId)
    const parentChildren = yield call(parent.data.getChildren, parent)
    tree.handlers.setRawChildren(parent, parentChildren, 'first', true)
    const node = tree.instance.getNodeById(data.id)
    updateNodeCount(tree, path, 'add')
    const selectedNodePath = get(node, 'options.path', '')
    tree.handlers.setSelectedByPath(selectedNodePath)
    setUrlFormValues({
      id: data.id,
      type: INTEGRATION,
      integrationType: data.integrationType,
      parentGeoZoneId: parentProjectData.parentId,
      rootId,
      parentTreeId: data.parentId,
      path: `${path}/${data.id}`,
      FILTER,
    })
    yield put(setGlobalLoading(false))
    toast({
      title: 'Создание интеграции',
      type: 'success',
      description: 'Интеграция успешно создана.',
    })
    yield put(requestGetAllConnectedIntegrations())
    yield put(successCreateIntegration())
  } catch (error) {
    const { FILTER } = formValues
    setUrlFormValues({ FILTER })
    console.log('function*createIntegrationSaga -> error', error)
    yield put(setGlobalLoading(false))
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось создать интеграцию. Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(CREATE_INTEGRATION.REQUEST, createIntegrationSaga)
}
