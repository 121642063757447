import {
  GEOZONE,
  PROJECT,
  INTEGRATION,
  OBJECT_ELEMENT,
} from '@/constants/objectTypes'

const defaultConfig = {
  [GEOZONE]: 0,
  [PROJECT]: 0,
  [INTEGRATION]: 0,
  [OBJECT_ELEMENT]: 0,
}

const sumThoObject = (first, second) => {
  return Object.keys(defaultConfig).reduce((buffer, elementName) => {
    return {
      ...buffer,
      [elementName]: (first[elementName] || 0) + (second[elementName] || 0),
    }
  }, [])
}

const countElementTypes = (node, config = defaultConfig, doNotCountFirst = false) => {
  let localConfig = { ...defaultConfig }
  if ((node.children || []).length === 0 && !doNotCountFirst) {
    return {
      ...config,
      [node.type]: (config[node.type] + 1) || 1,
    }
  }
  if (node && !doNotCountFirst) {
    localConfig = {
      ...localConfig,
      [node.type]: (config[node.type] + 1) || 1,
    }
  }

  return (node.children || []).reduce((accumulator, element) => {
    const childCount = (element.children || []).reduce((buffer, child) => {
      return sumThoObject(buffer, countElementTypes(child))
    }, defaultConfig)
    const newAccumulator = {
      ...accumulator,
      [element.type]: (accumulator[element.type] + 1) || 1,
    }
    return sumThoObject(newAccumulator, childCount)
  }, localConfig)
}

const createMainConfig = (node, config = {}) => {
  if (node.type === OBJECT_ELEMENT) {
    return config
  }

  if ((node.children || []).length === 0) {
    return {
      ...config,
      [node.treeElementId]: countElementTypes(node, defaultConfig, true),
    }
  }
  return node.children.reduce((accumulator, element) => {
    const elementChild = node.children.reduce((buffer, child) => {
      return {
        ...buffer,
        ...createMainConfig(child, defaultConfig),
      }
    }, {})
    return {
      ...accumulator,
      ...elementChild,
      [element.treeElementId]: countElementTypes(element, defaultConfig, true),
    }
  }, config)
}

const findNodeById = (node, nodeSelector, id) => {
  let findNode = null
  if (node[nodeSelector] === id) {
    return node
  }
  node.children && node.children.map((childNode) => {
    if (findNode) {
      return childNode
    }
    const result = findNodeById(childNode, nodeSelector, id)
    if (result) {
      findNode = result
      return childNode
    }
    return childNode
  })
  return findNode
}

export const calculateElementStructure = (tree, nodeSelector, nodeId) => {
  const findNode = findNodeById(tree, nodeSelector, nodeId)
  return countElementTypes(findNode || {}, defaultConfig, true)
}

export const mapElementStructure = (statistic) => {
  let localConfig = { ...defaultConfig }
  localConfig.GEOZONE = statistic.geoZoneCount || 0
  localConfig.INTEGRATION = statistic.integrationCount || 0
  localConfig.OBJECT_ELEMENT = statistic.objectElementCount || 0
  localConfig.PROJECT = statistic.projectCount || 0
  return localConfig
}

export default (node) => {
  return createMainConfig({ children: node }, {})
}
