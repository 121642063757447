import { takeLatest } from 'redux-saga/effects'
import { REFRESH_WIDGET_MAP } from '@/store/actions/maps'
import request from '@/helpers/axios'
import { REFRESH_OBJECT_TELEMETRY } from '@/constants/apiRoutes'
import createNotifications from "@/helpers/notification";

function* getKulonMapEquipment({ payload }) {
  try {
    yield request({
      url: REFRESH_OBJECT_TELEMETRY,
      body: payload,
      method: 'post',
    })
  } catch (error) {
    console.log('TCL: function*getKulonMapEquipment -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(REFRESH_WIDGET_MAP.REQUEST, getKulonMapEquipment)
}
