import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import generateWidget from './generateWidget'
import {
  UnitPressure,
  UnitvolumetricFlow,
  UnitVolume
} from './unitElements'

export const HEAT_SUPPLY_PIPE_TEMPERATURE = generateWidget({
  config: {
    id: 'HEAT_SUPPLY_PIPE_TEMPERATURE',
    title: <Lang id="passportization.fields.technicalLimitations.temperatureInPipe" />,
    units: '°C',
  },
  selectorTemplate: 'temperatureInPipe',
})

export const HEAT_RETURN_PIPE_TEMPERATURE = generateWidget({
  config: {
    id: 'HEAT_RETURN_PIPE_TEMPERATURE',
    title: <Lang id="passportization.fields.technicalLimitations.temperatureInReversePipe" />,
    units: '°C',
  },
  selectorTemplate: 'temperatureInReversePipe',
})

export const HEAT_SUPPLY_PIPE_PRESSURE = generateWidget({
  config: {
    id: 'HEAT_SUPPLY_PIPE_PRESSURE',
    title: <Lang id="widgets.pressureInPipe" />,
    units: UnitPressure,
  },
  selectorTemplate: 'pressureInPipe',
})

export const HEAT_RETURN_PIPE_PRESSURE = generateWidget({
  config: {
    id: 'HEAT_RETURN_PIPE_PRESSURE',
    title: <Lang id="widgets.pressureInReversePipe" />,
    units: UnitPressure,
  },
  selectorTemplate: 'pressureInReversePipe',
})

export const HEAT_SUPPLY_PIPE_MASS = generateWidget({
  config: {
    id: 'HEAT_SUPPLY_PIPE_MASS',
    title: <Lang id="passportization.fields.technicalLimitations.massFlowInPipe" />,
    units: <Lang id="widgets.massFlowUnits" />,
  },
  selectorTemplate: 'massFlowInPipe',
})

export const HEAT_RETURN_PIPE_MASS = generateWidget({
  config: {
    id: 'HEAT_RETURN_PIPE_MASS',
    title: <Lang id="passportization.fields.technicalLimitations.massFlowInReversePipe" />,
    units: <Lang id="widgets.massFlowUnits" />,
  },
  selectorTemplate: 'massFlowInReversePipe',
})

export const HEAT_SUPPLY_PIPE_FLOW = generateWidget({
  config: {
    id: 'HEAT_SUPPLY_PIPE_FLOW',
    title: <Lang id="passportization.fields.technicalLimitations.straitPipelineVolumeConsumption" />,
    units: UnitvolumetricFlow,
  },
  selectorTemplate: 'volumetricFlowInPipe',
})

export const HEAT_RETURN_PIPE_FLOW = generateWidget({
  config: {
    id: 'HEAT_RETURN_PIPE_FLOW',
    title: <Lang id="passportization.fields.technicalLimitations.reversePipelineVolumeConsumption" />,
    units: UnitvolumetricFlow,
  },
  selectorTemplate: 'volumetricFlowInReversePipe',
})

export const HEAT_CONSUMED_RESURCE_VOLUME = generateWidget({
  config: {
    id: 'HEAT_CONSUMED_RESURCE_VOLUME',
    title: <Lang id="passportization.fields.technicalLimitations.consumedVolume" />,
    units: UnitVolume,
  },
  selectorTemplate: 'temperature',
})

export const HEAT_TERMAL_ENERGY = generateWidget({
  config: {
    id: 'HEAT_TERMAL_ENERGY',
    title: <Lang id="passportization.fields.technicalLimitations.energy" />,
    units: <Lang id="constants.graph.energy" />,
  },
  selectorTemplate: 'energy',
})

export const HEAT_COST = generateWidget({
  config: {
    id: 'HEAT_COST',
    title: <Lang id="passportization.fields.technicalLimitations.heatCosts" />,
    units: <Lang id="analyticExpenses.form.savingValue" />,
  },
  selectorTemplate: 'heatCosts',
})

export const HEAT_DETECT_UNDERDOSE = generateWidget({
  config: {
    id: 'HEAT_DETECT_UNDERDOSE',
    title: <Lang id="passportization.fields.technicalLimitations.detectionOfUnderheat" />,
    units: <Lang id="constants.graph.energy" />,
  },
  selectorTemplate: 'detectionOfUnderheat',
})

export const HEAT_DETECT_UPPERDOSE = generateWidget({
  config: {
    id: 'HEAT_DETECT_UPPERDOSE',
    title: <Lang id="passportization.fields.technicalLimitations.detectionOfOverheat" />,
    units: <Lang id="constants.graph.energy" />,
  },
  selectorTemplate: 'detectionOfOverheat',
})
