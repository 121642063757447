import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import {
  ALL_CHARTS,
  APPEAL_TOPICS,
  APPEAL_STATUSES,
  APPEAL_STATISTIC,
  APPEAL_TIME,

  ALL_PERIOD,
  YEAR,
  QUARTER,
  MONTH,
  WEEK,
  DAY,

  ANNUALLY,
  MONTHLY,
  WEEKLY,
  DAILY,
} from '@/constants/tyumenOurHome'
import {
  OK,
  WARNING,
  ERROR,
} from '@/constants/alarms'
import {
  OUTDOOR_LIGHTNING,
  ROAD_REPAIRING,
  TRANSPORT_STATION
} from '@/constants/objectTypes'

export const urbanProcesses = [
  { value: OUTDOOR_LIGHTNING, title: <Lang id="widgets.light" /> },
  { value: ROAD_REPAIRING, title: <Lang id="widgets.roadRepairing" /> },
  { value: TRANSPORT_STATION, title: <Lang id="widgets.publicTransport" /> },
]

export const chartTypeOptions = [
  { value: ALL_CHARTS, title: <Lang id="tyumenOurHome.chartTypes.all" /> },
  { value: APPEAL_TOPICS, title: <Lang id="tyumenOurHome.chartTypes.topics" /> },
  { value: APPEAL_STATUSES, title: <Lang id="tyumenOurHome.chartTypes.statuses" /> },
  { value: APPEAL_STATISTIC, title: <Lang id="tyumenOurHome.chartTypes.statistic" /> },
  { value: APPEAL_TIME, title: <Lang id="tyumenOurHome.chartTypes.time" /> },
]

export const selectOptions = [
  // {
  //   title: 'Все статусы',
  //   value: null,
  // },
  {
    title: <Lang id="widgets.solving" />,
    value: WARNING,
  },
  {
    title: <Lang id="widgets.failOrNoProblem" />,
    value: ERROR,
  },
  {
    title: <Lang id="widgets.resolved" />,
    value: OK,
  },
]

export const periodOptions = [
  { value: ALL_PERIOD, title: <Lang id="tyumenOurHome.periodOptions.all" /> },
  { value: YEAR, title: <Lang id="tyumenOurHome.periodOptions.year" /> },
  { value: QUARTER, title: <Lang id="tyumenOurHome.periodOptions.quarter" /> },
  { value: MONTH, title: <Lang id="tyumenOurHome.periodOptions.month" /> },
  { value: WEEK, title: <Lang id="tyumenOurHome.periodOptions.week" /> },
  { value: DAY, title: <Lang id="tyumenOurHome.periodOptions.day" /> },
]

export const intervalOptions = [
  { value: ANNUALLY, title: <Lang id="consumptionAnalysis.intervalOptions.annualy" /> },
  { value: MONTHLY, title: <Lang id="consumptionAnalysis.intervalOptions.monthly" /> },
  { value: WEEKLY, title: <Lang id="consumptionAnalysis.intervalOptions.weekly" /> },
  { value: DAILY, title: <Lang id="consumptionAnalysis.intervalOptions.daily" /> },
]
