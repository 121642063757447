import {
    put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_OWNERS_URL } from '@/constants/apiRoutes'
import { GET_ALL_OWNERS } from '@/store/actions/dictionaries'
import createNotifications from '@/helpers/notification'
import { errorGetAllOwners, responseGetAllOwners } from '@/store/actions/dictionaries/owners'

function* getDictionary() {
    try {
        const response = yield request({
            url: GET_OWNERS_URL,
            method: 'get',
        })
        if (response && response.data) {
            yield put(responseGetAllOwners(response.data))
        }
    } catch (error) {
        yield put(errorGetAllOwners(error))
        const toast = createNotifications()
        toast({
            title: 'Ошибка операции!',
            description: 'Не удалось получить данные всех собственников.\nПовторите попытку позже.',
            type: 'error',
        })
    }
}

export default function* () {
    yield takeLatest(GET_ALL_OWNERS.REQUEST, getDictionary)
}