import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import MenuIcon from '@/components/icons/menu'

export default {
  warning: {
    icon: MenuIcon.AttentionIcon,
    buttons: [
      {
        statusType: 'warning',
        onClickSelector: 'primaryAction',
        title: <Lang id="menu.globalPopup.accept" />
      },
    ],
  },
  success: {
    icon: MenuIcon.GeoZoneIcon,
    buttons: [
      {
        statusType: 'flat',
        onClickSelector: 'secondaryAction',
        title: <Lang id="menu.globalPopup.save" />
      },
      {
        statusType: 'success',
        onClickSelector: 'primaryAction',
        title: <Lang id="menu.globalPopup.verify" />
      },
    ],
  },
  editSuccess: {
    icon: MenuIcon.GeoZoneIcon,
    buttons: [
      {
        statusType: 'flat',
        onClickSelector: 'secondaryAction',
        title: <Lang id="menu.globalPopup.cancel" />
      },
      {
        statusType: 'success',
        onClickSelector: 'primaryAction',
        title: <Lang id="menu.globalPopup.save" />
      },
    ],
  },
  critical: {
    icon: MenuIcon.TreshIcon,
    buttons: [
      {
        statusType: 'flat',
        onClickSelector: 'secondaryAction',
        title: <Lang id="menu.globalPopup.no" />
      },
      {
        statusType: 'critical',
        onClickSelector: 'primaryAction',
        title: <Lang id="menu.globalPopup.yes" />
      },
    ],
  },
}
