import React from 'react'
import PprCell from '@/components/blocks/DutyTable/customCells/PprCell'
import { FormattedMessage as Lang } from 'react-intl'

import LiveTime from '@/components/icons/livetime'


export const exportOptions = [
  {
    name: 'EXCEL',
    icon: LiveTime.EXELIcon,
  },
]

export const fields =[
  {
    id: 'number',
    name: '№',
    value: 'ASC',
    customWidth: 60,
  },
  {
    id: 'installation',
    name: <Lang id="tableFields.PPR.object" />,
    value: 'ASC',
    type: 'string',
    customWidth: 220,
  },
  {
    id: 'address',
    name: <Lang id="tableFields.PPR.address" />,
    value: 'ASC',
    type: 'string',
    customWidth: 320,
  },
  {
    id: 'registerNumber',
    name: <Lang id="tableFields.PPR.registerNumber" />,
    value: 'ASC',
    customWidth: 170,
  },
  {
    id: 'originalInstallationType',
    name: <Lang id="tableFields.PPR.installationType" />,
    value: 'ASC',
    customWidth: 220,
    customCell: (props) => <PprCell installationType {...props} />,
  },
  {
    id: 'planDate',
    name: <Lang id="tableFields.PPR.planDate" />,
    value: 'ASC',
    customWidth: 248,
    customCell: (props) => <PprCell {...props} />,
  },
  {
    id: 'factDate',
    name: <Lang id="tableFields.PPR.factDate" />,
    value: 'ASC',
    customWidth: 248,
    customCell: (props) => <PprCell factDate {...props} />,
  },
]

export const settingsOptions = [
  {
    name: 'Не просрочен',
    id: 'NOT_EXPIRED',
  },
  {
    name: 'Просрочен',
    id: 'EXPIRED',
  },
]

export const settingsYear = [
  {
    name: '2024',
    id: '2024',
  },
  {
    name: '2023',
    id: '2023',
  },
  {
    name: '2022',
    id: '2022',
  },
  {
    name: '2021',
    id: '2021',
  },
]