import {
  takeLatest, select, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { generateAllUsers } from '@/constants/apiRoutes'
import { getAllUsers } from '@/store/selectors/users'
import { USER, ADMIN } from '@/constants/viewTree'
import { ROLE_TENANT_ADMIN } from '@/constants/names'
import {
  responseGetAllUsers,
  errorGetAllUsers,
} from '@/store/actions/users/user/getAllUsers'
import { GET_GROUP_USERS } from '@/store/actions/users'
import getFullName from '@/helpers/fullName'
import getUserStatus from '@/helpers/getUserStatus'
import createNotifications from "@/helpers/notification";

function* getAllUsersSaga({ payload }) {
  const { tenantId, groupId } = payload
  try {
    const response = yield request({
      url: generateAllUsers(groupId),
      method: 'get',
    })
    if (response) {
      const newUsers = response.data.map((element) => ({
        type: element.role === ROLE_TENANT_ADMIN ? ADMIN : USER,
        ...element,
        status: getUserStatus(element.enabled, element.blocked),
        name: getFullName(element.lastName, element.firstName, element.middleName),
      }))
      const users = yield select(getAllUsers)
      if (response.error) {
        return null
      }
      const usersWithId = {
        ...users,
        [`${tenantId}-${groupId}`]: newUsers,
      }
      yield put(responseGetAllUsers(usersWithId))
    } else {
      // error handler
    }
  } catch (error) {
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось получить список пользователей. \nПовторите попытку позже.',
      type: 'error',
    })
    yield put(errorGetAllUsers(error))
  }
  return null
}

export default function* root() {
  yield takeLatest(GET_GROUP_USERS.REQUEST, getAllUsersSaga)
}
