export const TOPIC_INTEGRATIONS = '/topic/integrations'
export const TOPIC_ASU = '/topic/asu'
export const TOPIC_STATUS_PREFIX = '/status'
export const TOPIC_MONITORING_PREFIX = '/monitoring'
export const TOPIC_ALERT_PREFIX = '/alerts'

export const TOPIC_CREATION_NOTIFICATIONS = `${TOPIC_INTEGRATIONS}${TOPIC_STATUS_PREFIX}/creation`

export const generateUpdateIntegrationTopic = (
  id,
) => `${TOPIC_INTEGRATIONS}/${id}${TOPIC_STATUS_PREFIX}/update`
export const generateRepeatIntegrationTopic = (
  id,
) => `${TOPIC_INTEGRATIONS}/${id}${TOPIC_STATUS_PREFIX}/refresh`
export const generateDeleteIntegrationTopic = (
  id,
) => `${TOPIC_INTEGRATIONS}/${id}${TOPIC_STATUS_PREFIX}/deletion`
export const generateMonitoringNotificationsTopic = (
  id,
) => `${TOPIC_INTEGRATIONS}/${id}${TOPIC_MONITORING_PREFIX}/notifications`
export const generateMonitoringErrorsTopic = (
  id,
) => `${TOPIC_INTEGRATIONS}/${id}${TOPIC_MONITORING_PREFIX}/errors`
export const generateAlertNotificationTopic = (
  id,
) => `${TOPIC_INTEGRATIONS}/${id}${TOPIC_ALERT_PREFIX}/notifications`
export const generateVerifyIntegrationTopic = (
  id,
) => `${TOPIC_INTEGRATIONS}/${id}/status/verification`
export const generateIntegrationVerificationProgressTopic = (
  id,
) => `${TOPIC_ASU}/${id}/verification`

export const NOTIFICATION_REFRESH_INTEGRATION = '/topic/pushStatusRefreshIntegrationEquipmentState'
export const CHANGE_OBJECT_STATE = '/topic/status/object/telemetry'

// alarms count
export const NOTIFICATION_ALARMS_COUNT_TOPIC = '/topic/pushNotificationAboutUnreadAlerts'

// service desk
export const ADD_INTEGRATION_TOPIC = '/topic/integration/service-desk'

export const FIRE_MESH_NOTIFICATION = '/topic/pushMeshParseStateEquipment'
export const FIRE_BRIZ_NOTIFICATION = '/topic/pushBrizParseStateEquipment'

export const INSTALLATION_UPLOAD_NOTIFICATION = '/topic/pushParsingInstallationFileState'
export const generateInstallationUploadProgressTopic = (
  id,
) => `/topic/node/${id}/upload-file/status`
export const ONLINE_USERS_TOPIC = '/topic/users-online'
