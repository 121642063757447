import { GET_PASSPORTIZATION_DATA } from './index'

export const requestGetPassportizationData = () => ({
    type: GET_PASSPORTIZATION_DATA.REQUEST,
})

export const successGetPassportizationData = (payload) => ({
    type: GET_PASSPORTIZATION_DATA.SUCCESS,
    payload,
})

export const errorGetPassportizationData = (payload) => ({
    type: GET_PASSPORTIZATION_DATA.ERROR,
    payload,

})