import widgets from '../widgets'

const {
  LIGHT_MESH_STATUS,
  LIGHT_LEVEL,
  CONTROLLER_WORKING_MODE,
  ENERGY_CONSUMED_ARRAY,
} = widgets

export default [
  LIGHT_MESH_STATUS({}),
  CONTROLLER_WORKING_MODE({}),
  LIGHT_LEVEL({}),
  ENERGY_CONSUMED_ARRAY({ count: 4, number: 1 }),
]
