export default {
  formTitles: {
    ASU: 'ACS:',
    integration: 'Integration:',
    objectTypes: 'Object types:',
    process: 'City processes:',
    objectState: 'Object State:',
    owner: 'Owner:',
    exploitationOrganization: 'Exploitation organization:',
  },
  selectPlaceholders: {
    ASU: 'All ACS',
    integration: 'All integrations',
    objectTypes: 'All object types',
    process: 'All city processes',
    exploitationOrganizations: 'All organizations',
    owners: 'All owners',
  },
  buttons: {
    reset: 'Reset',
    accept: 'Apply',
  },
}