import createTableActions from '@/helpers/redux/createTableActions'
import createAction from '@/helpers/redux/createAction'

export const SCHEDULE_MANAGER_FAILURE_LOG_TABLE = createTableActions('@/SCHEDULE_MANAGER_FAILURE_LOG_TABLE')
export const GET_SCHEDULE_MANAGER_FAILURE_LOG_TABLE = createAction('@/GET_SCHEDULE_MANAGER_FAILURE_LOG_TABLE')
export const SET_FAILURE_LOG_DATES_RANGE = 'SET_FAILURE_LOG_DATES_RANGE'
export const SET_ERROR_STATUS = 'SET_ERROR_STATUS'

export const SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE = createTableActions('@/SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE')
export const GET_SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE = createAction('@/GET_SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE')
export const SET_ACTIVITY_LOG_DATES_RANGE = 'SET_ACTIVITY_LOG_DATES_RANGE'
export const SET_ACTION = 'SET_ACTION'

export const GET_SCHEDULE_MANAGER_TREE = createAction('@/GET_SCHEDULE_MANAGER_TREE')
export const GET_SCHEDULE_MANAGER_REPORT_FILE = createAction('@/GET_SCHEDULE_MANAGER_REPORT_FILE')
export const SET_URL_PARAMS = '@/SCHEDULE_MANAGER_PAGE_SET_URL_PARAMS'
export const UPLOAD_SCHEDULE = createAction('@/SCHEDULE_MANAGER_PAGE_UPLOAD_SCHEDULE')
export const SET_SCHEDULE_TREE_NODE = '@/SCHEDULE_MANAGER_SET_SCHEDULE_TREE_NODE'

export const GET_SCHEDULE = createAction('@/SCHEDULE_MANAGER_PAGE_GET_SCHEDULE')
export const CREATE_SCHEDULE = createAction('@/SCHEDULE_MANAGER_PAGE_CREATE_SCHEDULE')
export const EDIT_SCHEDULE = createAction('@/SCHEDULE_MANAGER_PAGE_EDIT_SCHEDULE')
export const DELETE_SCHEDULE = createAction('@/SCHEDULE_MANAGER_PAGE_DELETE_SCHEDULE')

export const setScheduleTreeNode = (payload) => ({
  type: SET_SCHEDULE_TREE_NODE,
  payload,
})

export const setFailureLogDatesRange = (payload) => ({
  type: SET_FAILURE_LOG_DATES_RANGE,
  payload,
})

export const setErrorStatus = (payload) => ({
  type: SET_ERROR_STATUS,
  payload,
})

export const setActivityLogDatesRange = (payload) => ({
  type: SET_ACTIVITY_LOG_DATES_RANGE,
  payload,
})

export const setAction = (payload) => ({
  type: SET_ACTION,
  payload,
})
