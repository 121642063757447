import AdminIcon from './AdminIcon'
import ArrIcon from './ArrIcon'
import CompanyIcon from './CompanyIcon'
import DepartmentIcon from './DepartmentIcon'
import GearIcon from './GearIcon'
import GeoIcon from './GeoIcon'
import GroupIcon from './GroupIcon'
import MagnifierIcon from './MagnifierIcon'
import MailIcon from './MailIcon'
import PhoneIcon from './PhoneIcon'
import PlusIcon from './PlusIcon'
import TrashIcon from './TrashIcon'
import UserIcon from './UserIcon'
import GridIcon from './GridIcon'
import AlphabetIcon from './AlphabetIcon'
import ListIcon from './ListIcon'
import DateSortIcon from './DateSortIcon'
import DownArrow from './DownArrow'
import UpperArrow from './UpperArrow'
import AdminProfileIcon from './AdminProfileIcon'
import AdminProfilePhotoIcon from './AdminProfilePhotoIcon'
import OKIcon from './OKIcon'
import CrossIcon from './CrossIcon'
import UsersGroupIcon from './UsersGroupIcon'
import WarningIcon from './WarningIcon'
import UserGroupIcon from './UserGroupIcon'
import TenantFolderIcon from './TenantFolderIcon'
import InformationIcon from './InformationIcon'
import UploadIcon from './UploadIcon'
import AttentionIcon from './AttentionIcon'
import DownIcon from './DownIcon'
import UpIcon from './UpIcon'
import UserAvatarIcon from './UserAvatarIcon'
import SortDescendingIcon from './SortDescendingIcon'
import SortAscendingIcon from './SortAscendingIcon'
import SortAlphabeticalIcon from './SortAlphabeticalIcon'
import CloseIcon from './CloseIcon'

export default {
  AdminIcon,
  ArrIcon,
  CompanyIcon,
  DepartmentIcon,
  GearIcon,
  GeoIcon,
  GroupIcon,
  MagnifierIcon,
  MailIcon,
  PhoneIcon,
  PlusIcon,
  TrashIcon,
  UserIcon,
  GridIcon,
  AlphabetIcon,
  ListIcon,
  DateSortIcon,
  DownArrow,
  UpperArrow,
  AdminProfileIcon,
  AdminProfilePhotoIcon,
  OKIcon,
  CrossIcon,
  UsersGroupIcon,
  WarningIcon,
  UserGroupIcon,
  TenantFolderIcon,
  InformationIcon,
  UploadIcon,
  AttentionIcon,
  DownIcon,
  UpIcon,
  UserAvatarIcon,
  SortDescendingIcon,
  SortAscendingIcon,
  SortAlphabeticalIcon,
  CloseIcon,
}
