import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { setSelectedNode } from '@/store/actions/appSettings'

import AlarmsAnalyticsPage from './component'
import { requestGetTreeForWidgets } from '@/store/actions/widgetDashboard/getTreeForWidgets'
import { getRequestState, getTreeForWidgets } from '@/store/selectors/widgetDashboard'
import { getSelectedNode } from '@/store/selectors/appSettings'

const mapStateToProps = (state) => ({
  tree: getTreeForWidgets(state),
  requestState: getRequestState(state),
  selectedNode: getSelectedNode(state),
})
const mapDispatchToProps = (dispatch) => ({
  setNode: (payload) => dispatch(setSelectedNode(payload)),
  requestGetTree: () => dispatch(requestGetTreeForWidgets()),
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(AlarmsAnalyticsPage))
