import {
  take, call, put
} from 'redux-saga/effects'
import get from 'lodash/get'
import { treeHandlers } from 'react-hyper-tree'
import createNotifications from '@/helpers/notification'
import updateNodeCount from '@/helpers/updateNodeCount'
import { createInstallationUploadChannel } from './channels'
import { requestGetCard } from '@/store/actions/installation/getCard'
import { INTEGRATION } from '@/constants/objectTypes'

function* verifyIntegrationSaga() {
  try {
    const toast = createNotifications()
    const channel = yield call(createInstallationUploadChannel)
    while (true) {
      const eventData = yield take(channel)
      console.log('function*verifyIntegrationsSaga -> eventData', eventData);
      const description = get(eventData, 'description', 'undefined')
      const id = get(eventData, 'rootTreeElementId', 'undefined')
      const objectsCount = get(eventData, 'sizeLoadedElements', 'undefined')
      if (description === 'parsing.installation.file.was.successful') {
        const tree = treeHandlers.trees['installations-tree']
        const node = tree && tree.instance.getNodeById(id)
        if (node) {
          const parentChildren = yield call(node.data.getChildren, node)
          tree.handlers.setRawChildren(node, parentChildren, 'first', true)
          tree.handlers.setOpen(node, true)
          tree.handlers.setSelected(node, true)
          updateNodeCount(tree, node.options.path, 'add', objectsCount)
          tree.handlers.rerender()
          yield put(requestGetCard({id, type: INTEGRATION}))
        }
        toast({
          title: 'УСПЕШНАЯ ОПЕРАЦИЯ!',
          type: 'success',
          description: 'Обработка файла прошла успешно.'})
      } else {
        toast({
          title: 'ОШИБКА ОПЕРАЦИИ!',
          type: 'error',
          description: 'Обработка файла прошла неудачно.\nПовторите попытку позже.',
        })
      }
    }
  } catch (error) {
    console.log('function*verifyIntegrationSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default verifyIntegrationSaga
