// import { useState, useEffect, useCallback } from 'react'
import { useSelector } from 'react-redux'
import { getIsMobile } from '@/store/selectors/appSettings'


// const breakPoints = {
//   mobile: 910,
//  }

const useGetIsMobile = () => {
  const isMobile = useSelector(getIsMobile)
  // const [width, setWidth] = useState(window.innerWidth)
  // const updateWindowDimensions = useCallback(() => {
  //   setWidth(window.innerWidth)
  // }, [setWidth])

  // const updateSizes = useCallback(() => updateWindowDimensions(), [updateWindowDimensions])

  // useEffect(() => {
  //   updateSizes()
  //   window.addEventListener('resize', updateSizes)

  //   return () => {
  //     window.removeEventListener('resize', updateSizes)
  //   }
  // })

  return isMobile 
}

export default useGetIsMobile