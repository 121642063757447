import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import {
  COLOR,
  OBJECTS,
  TEXT
} from '@/constants/semanticNames'
import CREATE_OBJECTS_GROUP_NAMES from '@/constants/forms/createObjectsGroup'

export const objectsGroupConfig = [
  {
   title: <Lang id={'objectsGroups.form.name'} />,
   type: TEXT,
   selector: CREATE_OBJECTS_GROUP_NAMES.NAME,
  },
  {
   title: <Lang id={'objectsGroups.form.color'} />,
   type: COLOR,
   selector: CREATE_OBJECTS_GROUP_NAMES.COLOR,
  },
  {
   title: <Lang id={'objectsGroups.form.objects'} />,
   type: OBJECTS,
   selector: CREATE_OBJECTS_GROUP_NAMES.OBJECTS,
  },
]

export default objectsGroupConfig