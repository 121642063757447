import React from "react";
import { FormattedMessage as Lang } from 'react-intl'
import Icons from '@/components/icons/mobileIcons'

import {
  Container,
  Title,
  ButtonsWrapper,
  StyledButton,
  Message,
  Header,
  HeaderGroups,
  IconContainer,
  Main,
  Row,
  Accent,
} from './styles'

const installMobilePopup = ({ onClick, onClose, isIos }) => {

  if (isIos) {
    return (
      <Container ios>
       <Main>
        <HeaderGroups>
          <IconContainer opacity ></IconContainer>
          <IconContainer></IconContainer>
          <IconContainer>
            <Icons.Logo/>
          </IconContainer>
          <IconContainer ></IconContainer>
          <IconContainer opacity ></IconContainer>
        </HeaderGroups>
          <Title ios>
            Для установки приложения выполните действия:
          </Title>
          <Row>
            1. На панели инструментов нажать на иконку <Accent>«Поделиться».</Accent>
          </Row>
          <Icons.TabBar/>
          <Row>
            2. Выбрать пункт меню <Accent bold>«На экран Домой»</Accent>       
          </Row>
          <Icons.AddHomeScreen/>
          <Row>
            3. Подтвердить установку, нажав на кнопку <Accent>«Добавить».</Accent>        
          </Row>
          <Icons.Add/>
            <StyledButton usage={'ok'} onClick={onClose}>
            <Lang id="buttons.clear" />
          </StyledButton>
        </Main>
      </Container>
    )
  }
  return (
    <Container>
      <Header>
        <Title>
          <Lang id="mobile.installTitle" />
        </Title>
        <Message>
          <Lang id="mobile.installDescription" />
        </Message>
      </Header>
      <ButtonsWrapper>
        <StyledButton usage={'cancel'} onClick={onClose}>
          <Lang id="buttons.cancel" />
        </StyledButton>
        <StyledButton usage={'install'} onClick={onClick}>
          <Lang id="buttons.install" />
        </StyledButton>
      </ButtonsWrapper>
    </Container>
  )
}

export default installMobilePopup