import get from 'lodash/get'
import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'

export const getDictionaries = createSelector(
  getState,
  (state) => get(state, 'dictionaries', {}),
)

export const getObjectsGroupsFilterOptions = createSelector(
  getDictionaries,
  (dictionaries) => get(dictionaries, 'objectsGroupsFilterOptions', []),
)

export const getObjectsGroupsFilterOptionsData = createSelector(
  getObjectsGroupsFilterOptions,
  (dictionaries) => get(dictionaries, 'data', []),
)

export const getObjectsGroupsFilterOptionsLoading = createSelector(
  getObjectsGroupsFilterOptions,
  (dictionaries) => get(dictionaries, 'isLoading', []),
)