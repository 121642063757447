import { EDIT_OBJECTS_GROUP } from './index'

export const requestEditObjectsGroup = (payload) => ({
  type: EDIT_OBJECTS_GROUP.REQUEST,
  payload,
})

export const successEditObjectsGroup = (payload) => ({
  type: EDIT_OBJECTS_GROUP.SUCCESS,
  payload,
})

export const errorEditObjectsGroup = (payload) => ({
  type: EDIT_OBJECTS_GROUP.ERROR,
  payload,
})