import React, { useMemo, useState, useCallback } from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import pt from 'prop-types'
import memoWithName from '@/hocs/memoWithName'
import WidgetsIcons from '@/components/icons/widgets'
import {
  IndicatorWrapper,
  Title,
  Status,
  DescriptionWrapper,
  IconContainer,
} from '@/components/widgets/StateIndicator/styles'
import {
  getSplitTime,
  getStringFromSplit,
} from '@/helpers/formaters/getStringTimeFromNumber'

const WorkTimeIndicator = ({
  color,
  online,
  workTime,
  className,
  intl
}) => {
  const [iconInfo, setIconInfo] = useState(false)
  const workTimeParsed = useMemo(() => getSplitTime(workTime), [workTime])
  const includedWorkingTime = useMemo(() => {
    const todayDate = new Date()
    todayDate.setDate(todayDate.getDate() - workTimeParsed.days)
    todayDate.setHours(todayDate.getHours() - workTimeParsed.hours)
    todayDate.setMinutes(todayDate.getMinutes() - workTimeParsed.minutes)
    const day = todayDate.getDate()
    const month = todayDate.getMonth() + 1
    const year = todayDate.getFullYear()
    const hours = todayDate.getHours()
    const minutes = todayDate.getMinutes()
    return {
      day,
      month,
      year,
      hours,
      minutes,
    }
  }, [
    workTimeParsed.days,
    workTimeParsed.hours,
    workTimeParsed.minutes,
  ])
  const handlerOnMouse = useCallback((value) => () => (
    setIconInfo(value)
  ), [setIconInfo])
  const workTimeString = useMemo(() => getStringFromSplit(workTimeParsed), [workTimeParsed])
  const renderIconInfo = useMemo(() => {
    if (iconInfo) {
      return (
        <>
          <Title online>
            {online
                ? <Lang id="menu.onLong" />
                : <Lang id="menu.offLong" />
            }
            :
          </Title>
          <Status time>
            {`${includedWorkingTime.day}.${includedWorkingTime.month}.${includedWorkingTime.year} ${includedWorkingTime.hours}:${includedWorkingTime.minutes}`}
          </Status>
        </>
      )
    }
    return (
      <>
        <Title online>
          <Lang id="widgets.currentMode" />:
        </Title>
        <Status spaceBetween color={color}>
          {online
              ? <Lang id="menu.on" />
              : <Lang id="menu.off" />
          }
        </Status>
      </>
    )
  }, [iconInfo, color, includedWorkingTime, online])
  return (
    <IndicatorWrapper
      column
      className={className}
    >
      <DescriptionWrapper row>
        <IconContainer
          mr
          onMouseEnter={handlerOnMouse(true)}
          onMouseLeave={handlerOnMouse(false)}
        >
          <WidgetsIcons.InfoIcon />
        </IconContainer>
        {renderIconInfo}
      </DescriptionWrapper>
      <DescriptionWrapper row pt={20}>
        <Title online={online}>
          {`${online 
              ? intl.messages['menu.working']
              : intl.messages['menu.notWorking']}: ${workTimeString}`}
        </Title>
      </DescriptionWrapper>
    </IndicatorWrapper>
  )
}

WorkTimeIndicator.propTypes = {
  online: pt.bool,
  status: pt.bool,
  workTime: pt.number,
  className: pt.string,
  color: pt.string,
}
WorkTimeIndicator.defaultProps = {
  online: false,
  status: false,
  workTime: 0,
  className: '',
  color: '',
}

export default memoWithName(WorkTimeIndicator)
