import { connect } from 'react-redux'
import { injectIntl } from 'react-intl'
import { getCityProcessesOptions } from '@/store/selectors/dictionaries/cityProcesses'
import {
  getApplications,
  getApplicationsModels,
} from '@/store/selectors/dictionaries/applications'
import {
  getTenants,
  getSelectedTenant,
  getAdmins,
  getGroups,
  getType,
  getSelectedEntity,
  getAllUsers,
  getUserSortBy,
  getUserSortOrder,
  getStatus,
  getSortedUsers,
  getIsFormModifying,
} from '@/store/selectors/users'

import {
  updateSelectedUserType,
  setSelectedTenant,
  setEntity,
  setUsersSort,
  setFormIsModifying,
} from '@/store/actions/users'
import { getUserData, getTenantServiceDeskStatus } from '@/store/selectors/appSettings'
// COMMON
import { requestGetAllCityProcessesOptions } from '@/store/actions/dictionaries/cityProcesses'
import { requestGetApplications } from '@/store/actions/dictionaries/applications'
import { requestGetApplicationsModels } from '@/store/actions/dictionaries/applicationsModels'
import { requestAddIntegration } from '@/store/actions/serviceDeskMyCity/addIntegration'
import { requestDeleteIntegration } from '@/store/actions/serviceDeskMyCity/deleteIntegration'
import { setTenantServiceDesk } from '@/store/actions/serviceDeskMyCity'
// TENANT
import { requestCreateTenant } from '@/store/actions/users/tenant/createTenant'
import { requestDeleteTenant } from '@/store/actions/users/tenant/deleteTenant'
import { requestGetAllTenants } from '@/store/actions/users/tenant/getAllTenants'
import { requestGetTenant } from '@/store/actions/users/tenant/getTenant'
import { requestUpdateTenant } from '@/store/actions/users/tenant/updateTenant'
// GROUPS
import { requestCreateTenantGroup } from '@/store/actions/users/group/createGroup'
import { requestDeleteTenantGroup } from '@/store/actions/users/group/deleteGroup'
import { requestGetTenantGroup } from '@/store/actions/users/group/getGroup'
import { requestGetTenantGroups } from '@/store/actions/users/group/getTenantGroup'
import { requestUpdateTenantGroup } from '@/store/actions/users/group/updateGroup'
// ADMIN
import { requestCreateAdminTenant } from '@/store/actions/users/administrator/createAdminTenant'
import { requestDeleteAdminTenant } from '@/store/actions/users/administrator/deleteAdminTenant'
import { requestGetAdminTenant } from '@/store/actions/users/administrator/getAdminTenant'
import { requestGetAllAdminTenants } from '@/store/actions/users/administrator/getAllAdminTenants'
// USER
import { requestUpdateUser } from '@/store/actions/users/user/updateUser'
import { requestUpdateUserFromAdmin } from '@/store/actions/users/user/updateUserFromAdmin'
import { requestCreateUser } from '@/store/actions/users/user/createUser'
import { requestGetAllUsers } from '@/store/actions/users/user/getAllUsers'
import { requestGetUser } from '@/store/actions/users/user/getUser'
import { requestDeleteUser } from '@/store/actions/users/user/deleteUser'
import { requestUsersMultipleEditing } from '@/store/actions/users/user/multipleEditing'
import { requestUsersMultipleDelete } from '@/store/actions/users/user/multipleDelete'
import { getSystems } from '@/store/selectors/integrations'
import Users from './component'

const mapStateToProps = (state) => ({
  zoneProcessOptions: getCityProcessesOptions(state),
  type: getType(state),
  tenants: getTenants(state),
  admins: getAdmins(state),
  groups: getGroups(state),
  users: getAllUsers(state),
  selectedTenant: getSelectedTenant(state),
  selectedEntity: getSelectedEntity(state),
  loggedInUser: getUserData(state),
  userSortBy: getUserSortBy(state),
  userSortOrder: getUserSortOrder(state),
  status: getStatus(state),
  sortedUsers: getSortedUsers(state),
  systems: getSystems(state),
  isFormModifying: getIsFormModifying(state),
  applications: getApplications(state),
  applicationsModels: getApplicationsModels(state),
  serviceDeskStatus: getTenantServiceDeskStatus(state),
})

const mapDispatchToProps = (dispatch) => ({
  // common
  setFormIsModifying: (payload) => dispatch(setFormIsModifying(payload)),
  setEntity: (payload) => dispatch(setEntity(payload)),
  setType: (payload) => dispatch(updateSelectedUserType(payload)),
  setSelectedTenant: (payload) => dispatch(setSelectedTenant(payload)),
  getCityProcesses: (payload) => dispatch(requestGetAllCityProcessesOptions(payload)),
  setUsersSort: (payload) => dispatch(setUsersSort(payload)),
  requestGetApplications: (payload) => dispatch(requestGetApplications(payload)),
  requestGetApplicationsModels: (payload) => dispatch(requestGetApplicationsModels(payload)),
  addSDIntegration: (payload) => dispatch(requestAddIntegration(payload)),
  requestDeleteSDIntegration: (payload) => dispatch(requestDeleteIntegration(payload)),
  setTenantServiceDesk: (payload) => dispatch(setTenantServiceDesk(payload)),
  tenantActions: {
    requestCreateTenant: (payload) => dispatch(requestCreateTenant(payload)),
    requestDeleteTenant: (payload) => dispatch(requestDeleteTenant(payload)),
    requestGetAllTenants: (payload) => dispatch(requestGetAllTenants(payload)),
    requestGetTenant: (payload) => dispatch(requestGetTenant(payload)),
    requestUpdateTenant: (payload) => dispatch(requestUpdateTenant(payload)),
  },
  adminActions: {
    requestCreateAdminTenant: (payload) => dispatch(requestCreateAdminTenant(payload)),
    requestDeleteAdminTenant: (payload) => dispatch(requestDeleteAdminTenant(payload)),
    requestGetAdminTenant: (payload) => dispatch(requestGetAdminTenant(payload)),
    requestGetAllAdminTenants: (id, role) => dispatch(requestGetAllAdminTenants({ id, role })),
  },
  groupActions: {
    requestCreateTenantGroup: (payload) => dispatch(requestCreateTenantGroup(payload)),
    requestDeleteTenantGroup: (payload) => dispatch(requestDeleteTenantGroup(payload)),
    requestGetTenantGroup: (payload) => dispatch(requestGetTenantGroup(payload)),
    requestGetTenantGroups: (payload) => dispatch(requestGetTenantGroups(payload)),
    requestUpdateTenantGroup: (payload) => dispatch(requestUpdateTenantGroup(payload)),
  },
  userActions: {
    requestCreateUser: (payload) => dispatch(requestCreateUser(payload)),
    requestUpdateUser: (payload) => dispatch(requestUpdateUser(payload)),
    requestUpdateUserFromAdmin: (payload) => dispatch(requestUpdateUserFromAdmin(payload)),
    requestGetAllUsers: (payload) => dispatch(requestGetAllUsers(payload)),
    requestGetUser: (payload) => dispatch(requestGetUser(payload)),
    requestDeleteUser: (payload) => dispatch(requestDeleteUser(payload)),
    requestUsersMultipleEditing: (payload) => dispatch(requestUsersMultipleEditing(payload)),
    requestUsersMultipleDelete: (payload) => dispatch(requestUsersMultipleDelete(payload)),
  },
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(injectIntl(Users))
