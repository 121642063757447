import React from 'react'

import { Title, CheckBoxContainer, RadioButtonStyled, Container, CloseIconContainer, IconContainer } from './styles'
import Icons from '@/components/icons/users'



const SortShutterSD = ({
  title,
  onClose,
  actions,
  setIsOpenPopup,
  setTypeSort,
  typeSort
}) => {
  
  const handleSetCheckBox = ({id, value}) => {
    setTypeSort(`${id}${value}`)
    setIsOpenPopup(false)
    actions.setSort({
        sortType: id,
        sortValue: value,
      })
  }

 
  return (
    <Container>
      <Title main>{title}</Title>
      <CloseIconContainer>
        <Icons.CrossIcon onClick={onClose}/>
      </CloseIconContainer>
        <CheckBoxContainer>
          <RadioButtonStyled
            active={typeSort === 'registrationDateASC'}
            onChange={() => handleSetCheckBox({id: 'registrationDate', value: 'ASC'})}
            label='Дата регистрации'
            />
          <IconContainer>
          <Icons.SortDescendingIcon/>
          </IconContainer>
        </CheckBoxContainer>
      <CheckBoxContainer>
        <RadioButtonStyled
          active={typeSort === 'registrationDateDESC'}
          onChange={() => handleSetCheckBox({id: 'registrationDate', value: 'DESC'})}
          label='Дата регистрации'
        />
        <IconContainer>
        <Icons.SortAscendingIcon/>
        </IconContainer>
      </CheckBoxContainer>
      <CheckBoxContainer >
        <RadioButtonStyled
          active={typeSort === 'plannedExecutionDateASC'}
          onChange={() => handleSetCheckBox({id: 'plannedExecutionDate', value: 'ASC'})}
          label='Плановая дата исполнения'
        />
        <IconContainer>
        <Icons.SortDescendingIcon/>
        </IconContainer>
      </CheckBoxContainer>
      <CheckBoxContainer >
        <RadioButtonStyled
          active={typeSort === 'plannedExecutionDateDESC'}
          onChange={() => handleSetCheckBox({id: 'plannedExecutionDate', value: 'DESC'})}
          label='Плановая дата исполнения'
        />
        <IconContainer>
          <Icons.SortAscendingIcon/>
        </IconContainer>
      </CheckBoxContainer>
      <CheckBoxContainer >
        <RadioButtonStyled
          active={typeSort === 'actualDateOfExecutionASC'}
          onChange={() => handleSetCheckBox({id: 'actualDateOfExecution', value: 'ASC'})}
          label='Фактическая дата исполнения'
        />
        <IconContainer>
        <Icons.SortDescendingIcon/>
        </IconContainer>
      </CheckBoxContainer>
      <CheckBoxContainer >
        <RadioButtonStyled
          active={typeSort === 'actualDateOfExecutionDESC'}
          onChange={() => handleSetCheckBox({id: 'actualDateOfExecution', value: 'DESC'})}
          label='Фактическая дата исполнения'
        />
        <IconContainer>
          <Icons.SortAscendingIcon/>
        </IconContainer>
      </CheckBoxContainer>
    </Container>
  )
}



export default SortShutterSD
