import { SET_CONTROL_DATA } from './index'

export const requestSetControlData = (payload) => ({
  type: SET_CONTROL_DATA.REQUEST,
  payload,
})

export const successSetControlData = (payload) => ({
  type: SET_CONTROL_DATA.SUCCESS,
  payload,
})

export const errorSetControlData = (payload) => ({
  type: SET_CONTROL_DATA.ERROR,
  payload,
})