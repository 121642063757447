import {
  takeLatest, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { generateGetTenantGroup } from '@/constants/apiRoutes'
import {
  NAME,
} from '@/constants/forms/group'
import {
  GET_GROUP,
  updateSelectedUserType,
  setEntity,
} from '@/store/actions/users'
import {
  ERROR,
} from '@/constants/objectStatuses'
import { USERS_GROUP } from '@/constants/viewTree'
import createNotifications from "@/helpers/notification";

function* getGroupSaga({ payload }) {
  const {
    groupId,
  } = payload
  try {
    const response = yield request({
      url: generateGetTenantGroup(groupId),
      method: 'get',
    })

    if (response.data) {
      const responseData = {
        type: USERS_GROUP,
        ...response.data,
        [NAME]: response.data.name,
        children: response.children || [],
        status: response.data.blocked ? ERROR : null,
        name: response.data.blocked ? `${response.data.name} (заблок.)` : response.data.name,
      }
      yield put(setEntity(responseData))
      yield put(updateSelectedUserType('groups_edit'))
    } else {
      // error handler
    }
  } catch (error) {
    console.log('TCL: error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_GROUP.REQUEST, getGroupSaga)
}
