import AvatarProfileIcon from './AvatarProfileIcon'
import FoldMenuIcon from './FoldMenuIcon'
import GraphicsIcon from './GraphicsIcon'
import HelpIcon from './HelpIcon'
import InstallationIcon from './InstallationIcon'
import InstallationsIcon from './InstallationsIcon'
import MenuCloseIcon from './MenuCloseIcon'
import MenuHamburgerIcon from './MenuHamburgerIcon'
import NotificationsIcon from './NotificationsIcon'
import ReportIcon from './ReportIcon'
import SearchIcon from './SearchIcon'
import SettingsIcon from './SettingsIcon'
import UsersIcon from './UsersIcon'
import DeleteIcon from './DeleteIcon'
import AcceptIcon from './AcceptIcon'
import SelectedPinIcon from './SelectedPinIcon'
import NoSelectedPinIcon from './NoSelectedPinIcon'
import GlobalFilterIcon from './GlobalFilterIcon'
import ActiveGlobalFilterIcon from './ActiveGlobalFilterIcon'
import PopupHeaderIcon from './PopupHeaderIcon'
import LayersIcon from './LayersIcon'
import ActiveSearchIcon from './ActiveSearchIcon'

export default {
  AvatarProfileIcon,
  FoldMenuIcon,
  GraphicsIcon,
  HelpIcon,
  InstallationIcon,
  InstallationsIcon,
  MenuCloseIcon,
  MenuHamburgerIcon,
  NotificationsIcon,
  ReportIcon,
  SearchIcon,
  SettingsIcon,
  UsersIcon,
  DeleteIcon,
  AcceptIcon,
  SelectedPinIcon,
  NoSelectedPinIcon,
  GlobalFilterIcon,
  ActiveGlobalFilterIcon,
  PopupHeaderIcon,
  ActiveSearchIcon,
  LayersIcon
}
