import { LIGHT, SHUO } from '@/constants/objectTypes'
import controller from './controller'
import light from './light'
import { BASE_STATION, LIGHT_FIXTURE, CONTROL_CUPBOARD, CONTROLLER, MULTIRATE_METER } from '@/constants/instalationPassport/types';

export default {
  [CONTROLLER]: controller,
  [BASE_STATION]: controller,
  [SHUO]: controller,
  [CONTROL_CUPBOARD]: controller,
  [MULTIRATE_METER]: controller,
  [LIGHT]: light,
  [LIGHT_FIXTURE]: light,
}
