import React from 'react'
import pt from 'prop-types'
import get from 'lodash/get'
import { Field } from 'formik'
import SelectControl from '@/components/controls/SelectControl'
import SelectControlMobile from '@/components/controls/SelectControlMobile'
import useGetIsMobile from '@/hooks/useGetIsMobile'


const SelectField = ({ label, onAfterChange, error, errorMessage, ...ownProps }) => {
  
  const isMobile = useGetIsMobile()

  return (
  <Field {...ownProps}>
    {({ field, form }) => {
      const onChange = (value) => {
        form.setFieldValue(field.name, value)
        if (onAfterChange) {
          onAfterChange(field.name, value)
        }
      }
      if (isMobile) {
        return (
        <SelectControlMobile
          {...field}
          {...ownProps}
          onChange={onChange}
          disabled={ownProps.disabled}
          isMaps
        /> 
        )
      }
      return (
          <SelectControl
            {...field}
            {...ownProps}
            errorMessage={errorMessage}
            error={get(form, `errors.${field.name}`, false)}
            onChange={onChange}
            disabled={ownProps.disabled}
          />
      )
    }}
  </Field>
)}

SelectField.defaultProps = {
  label: null,
  onAfterChange: null,
}

SelectField.propTypes = {
  label: pt.oneOfType([pt.string, pt.element]),
  onAfterChange: pt.func,
}

export default React.memo(SelectField)
