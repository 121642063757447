import React, {
  useCallback,
  useEffect,
  useState
} from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import noop from 'lodash/noop'
import pt from 'prop-types'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import SelectControl from '@/components/controls/SelectControl'
import { FieldsContainer } from '@/forms/ObjectsGroups/CreateEditObjectsGroupForm/styles'
import {
  MessageContainer,
  SubmitButton,
  CancelButton
} from './styles'
import AlarmsIcons from '@/components/icons/alarms'
import { StateOptions } from './config'
import { PHASES } from '@/forms/ScheduleManager/CreateEditScheduleForm/config'
import TextControl from '@/components/controls/TextControl'
import { ONLY_NUMBERS } from '@/constants/validationFields'

const ControlTab = ({
  data,
  groupId,

  requestGetControlData,
  requestSetControlData,
}) => {
  const [value, setValue] = useState()
  const [phase, setPhase] = useState()

  useEffect(() => {
    requestGetControlData({ groupId })
  }, [requestGetControlData, groupId])

  const handleValueChange = useCallback((value) => {
    const valueByPattern = (value.match(new RegExp('[0-9]', 'g')) || []).join('')
    const valueForSave = (valueByPattern.match(ONLY_NUMBERS) || []).join('')
    if (valueForSave <= 100) {
      setValue(valueForSave)
    }
  }, [setValue])

  const handlePhaseChange = useCallback((value) => {
    setPhase(value)
  }, [setPhase])

  const handleSetData = useCallback(() => {
    requestSetControlData({ groupId, value, phase })
  }, [requestSetControlData, groupId, value, phase])

  const handleCancelData = useCallback(() => {
    console.log('cancel')
  }, [])

  return (
    <>
      <FieldsContainer>
        <MessageContainer>
          <AlarmsIcons.WarningIcon />
          <Lang id={`objectsGroups.control.warning`} />
        </MessageContainer>
        { data.type !== 'RM' &&
          <>
            <LabelWithIcon
              title={<Lang id={`objectsGroups.control.labelRM`} />}
            />
            <TextControl
              fieldProps={{
                autoComplete: 'off',
                format: 'number',
                pattern: '',
              }}
              value={value}
              onChange={handleValueChange}
            />
          </>
        }
        { data.type !== 'SHUNO' &&
          <>
            <LabelWithIcon
              title={<Lang id={`objectsGroups.control.labelSHUNO`} />}
            />
            <SelectControl
              value={value}
              onChange={handleValueChange}
              options={StateOptions}
              placeholder={<Lang id={`objectsGroups.control.placeholderState`} />}
            />

            <LabelWithIcon
              title={<Lang id={`objectsGroups.control.labelPhase`} />}
            />
            <SelectControl
              value={phase}
              onChange={handlePhaseChange}
              options={PHASES}
              placeholder={<Lang id={`objectsGroups.control.placeholderPhase`} />}
              multiselect
            />
          </>
        }
        { ((data.type === 'SHUNO' && value !== data.value && phase !== data.phase) || (data.type === 'RM' && value !== data.value)) &&
           <SubmitButton onClick={handleSetData}>
             <Lang id={`objectsGroups.control.apply`} />
           </SubmitButton>
        }
        { !!data.value &&
          <CancelButton onClick={handleCancelData}>
            <Lang id={`objectsGroups.control.cancel`} />
          </CancelButton>
        }
      </FieldsContainer>
    </>
  )
}

ControlTab.defaultProps = {
  data: {},
  groupId: null,
  requestGetControlData: noop,
  requestSetControlData: noop,
}
ControlTab.propTypes = {
  data: pt.object,
  groupId: pt.number,
  requestGetControlData: pt.func,
  requestSetControlData: pt.func,
}

export default ControlTab

