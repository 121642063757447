import { connect } from 'react-redux'
import { getVerificationOptions, getVerificationOptionsStatus, getVerificationStatus } from '@/store/selectors/objectsGroups'
import { requestGetVerificationOptions } from '@/store/actions/objectsGroups/getVerificationOptions'
import { requestSetVerificationStatus } from '@/store/actions/objectsGroups/setVerificationStatus'
import { requestVerificationAll } from '@/store/actions/objectsGroups/verificationAll'
import { injectIntl } from 'react-intl'
import VerificationTab from './component'

const mapStateToProps = (state) => ({
  verificationOptions: getVerificationOptions(state),
  verificationOptionsStatus: getVerificationOptionsStatus(state),
  verificationStatus: getVerificationStatus(state),
})

const mapDispatchToProps = (dispatch) => ({
  requestGetVerificationOptions: (payload) => dispatch(requestGetVerificationOptions(payload)),
  requestSetVerificationStatus: (payload) => dispatch(requestSetVerificationStatus(payload)),
  requestVerificationAll: (payload) => dispatch(requestVerificationAll(payload))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(VerificationTab))
