import { all } from 'redux-saga/effects'

import addWidget from './addWidget'
import deleteWidget from './deleteWidget'
import setWidgetSettings from './setSettings'
import getApplicationsVersion from './getApplicationsVersion'
import getAllApplicationsFile from './getAllApplicationsFile'

export default function* root() {
  yield all([
    addWidget(),
    deleteWidget(),
    setWidgetSettings(),
    getApplicationsVersion(),
    getAllApplicationsFile(),
  ])
}
