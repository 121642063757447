import styled from 'styled-components'
import RadioButton from '@/components/controls/RadioButton'


export const Title = styled.div`
  display: flex;
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.colors.colors.charts};
  text-align: start;
  width: 300px;
  ${({ main, theme }) => main && `
    text-transform: uppercase;
    font-weight: ${theme.fontWeights.bold};
    color: ${theme.colors.colors.title};
    margin-left: 15px;
  `};
  ${({ graphTitle, theme }) => graphTitle && `
    position: absolute;
    color: ${theme.colors.white};
    width: auto;
    left: 38px;
    z-index: 1;
  `};
`

export const CheckBoxContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-left: 15px;
  background-color: #FAFBFB;
  border-radius: 10px;
  justify-content: space-between;
`

export const RadioButtonStyled = styled(RadioButton)`
  margin-right: 10px;
  padding: 14px 0 14px 20px;
`

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 20px 25px;
  background: rgba(245, 245, 245, 1); 
  border-radius: 4px;
  width: fit-content;
  color: ${({ theme }) => theme.colors.colors.default};
  ${({ shadow, theme }) => shadow && `
    box-shadow: 2px 3px 15px ${theme.colors.shadows.widget};
  `};
  transition: 0.4s;
  width: 100vw;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
`

export const CloseIconContainer = styled.div`
  position: absolute;
  right: 17px;
  top: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  .svgFill {
    fill: ${({ theme }) => theme.colors.colors.default};
    width: 14px;
    height: 14px;
  }
  cursor: pointer;

`

export const IconContainer = styled.div`
  padding-right: 10px;
`
