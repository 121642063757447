import React, { useState } from 'react'

import { Title, CheckBoxContainer, RadioButtonStyled, Container, CloseIconContainer, ButtonsWrapper, StyledButton } from './styles'
import Icons from '@/components/icons/users'



const ExportStatisticShutter = ({
  title,
  onClose,
  values,
  onFileSelect,
  allColumns
}) => {
  const [type, setType] = useState('xlsx')
  
  const handleSetCheckBox = (value) => {
    setType(value)
  }

  const onFileExport = () => {
    onFileSelect({value: type, allColumns, totalElements: values.resultCount})
    onClose()
  }

  return (
    <Container>
      <Title main>{title}</Title>
      <CloseIconContainer>
        <Icons.CrossIcon onClick={onClose}/>
      </CloseIconContainer>
      <CheckBoxContainer>
        <RadioButtonStyled
          active={type === 'xlsx'}
          onChange={() => handleSetCheckBox('xlsx')}
          label='EXCEL'
        />
      </CheckBoxContainer>
      <CheckBoxContainer >
        <RadioButtonStyled
          active={type === 'pdf'}
          onChange={() => handleSetCheckBox('pdf')}
          label='PDF'
        />
      </CheckBoxContainer>
      <ButtonsWrapper>
        <StyledButton usage="reset" onClick={onClose}>
          Отмена
        </StyledButton>
        <StyledButton usage="accept" onClick={onFileExport}>
          Экспортировать
        </StyledButton>
      </ButtonsWrapper>
    </Container>
  )
}



export default ExportStatisticShutter
