import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export default [
  {
    passportParameter: <Lang id="passportization.fields.generalInfo.name" />,
    showInDashboard: true,
    selector: 'name',
  },
  {
    passportParameter: <Lang id="passportization.fields.generalInfo.identifier" />,
    showInDashboard: true,
    selector: 'objectIdOuter',
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.REGISTRY_NUMBER" />,
    showInDashboard: true,
    selector: 'REGISTRY_NUMBER',
  },
  {
    passportParameter: <Lang id="passportization.fields.generalInfo.equipmentType" />,
    showInDashboard: true,
    selector: 'equipmentType',
  },
  {
    passportParameter: <Lang id="passport.fields.GENERAL_INFORMATION.VERSION_API" />,
    showInDashboard: true,
    selector: 'versionApi',
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.START_DATE" />,
    showInDashboard: true,
    selector: 'START_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.EXPLOITATION.EXPLOITATION_END_DATE" />,
    showInDashboard: true,
    selector: 'EXPLOITATION_END_DATE',
  },
  {
    passportParameter: <Lang id="passportization.fields.exploitation.organization" />,
    showInDashboard: true,
    selector: 'organization',
  },
  {
    passportParameter: <Lang id="passportization.fields.exploitation.owner" />,
    showInDashboard: true,
    selector: 'owner',
  },
  {
    passportParameter: <Lang id="passport.fields.PLAN_PPR_DATE" />,
    showInDashboard: true,
    selector: 'PLAN_PPR_DATE',
    isCard: true
  },
  {
    passportParameter: <Lang id="passport.fields.FACT_PPR_DATE" />,
    showInDashboard: true,
    selector: 'FACT_PPR_DATE',
    isCard: true
  },
]