export default {
  messages: {
    default: 'Выберите геозону и установите интервал для отображения диаграммы энергосбережения',
    date: 'Дата:',
    time: 'Время:',
    saved: 'Сэкономлено',
    lose: 'Перерасход',
  },
  intervalOptions: {
    annualy: 'Ежегодно',
    monthly: 'Ежемесячно',
    weekly: 'Еженедельно',
    daily: 'Ежедневно',
  },
  legend: {
    saving: '- экономия',
    overrun: '- перерасход',
    previous: '- предыдущий период',
    abbreviatedPrevious: '- пред. период',
    current: '- текущий период',
    actualConsumption: '- фактическое потребление',
    plannedConsumption: '- плановое потребление',
  },
  names: {
    linearChart: 'Линейный график',
    histogramChart: 'Гистограмма',
    export: 'Экспортировать',
  },
}
