import styled from 'styled-components'
import StateIndicator from '@/components/widgets/StateIndicator'
import StatusIndicator from '@/components/widgets/StateIndicator/StatusIndicator'
import WorkTimeIndicator from '@/components/widgets/StateIndicator/WorkTimeIndicator'
import TrashIndicator from '@/components/widgets/StateIndicator/TrashIndicator'
import SmallWidgetIndicator from '@/components/widgets/StateIndicator/SmallWidgetIndicator'
import LightIndicator from '@/components/widgets/StateIndicator/LightIndicator'
import ConnectionStatusIndicator from '@/components/widgets/StateIndicator/ConnectionStatusIndicator';
import ControllerStatusIndicator from '@/components/widgets/StateIndicator/ControllerStatusIndicator';
import MultiDimmingIndicator from '@/components/widgets/StateIndicator/MultiDimmingIndicator';

export const IndicatorsBlock = styled.div`
  height: auto;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 10px;
`

export const IndicatorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`

export const TrashIndicatorStyled = styled(TrashIndicator)`
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  width: 100%;
  min-width: 290px;
  align-items: center;
  justify-content: flex-end;
`

export const SmallWidgetIndicatorStyled = styled(SmallWidgetIndicator)`
  margin-bottom: 10px;
  width: 100%;
  min-width: 290px;
  min-height: 44px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`

export const LightIndicatorStyled = styled(LightIndicator)`
  margin-bottom: 10px;
  width: 100%;
  min-width: 290px;
  min-height: 175px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
`

export const StateIndicatorStyled = styled(StateIndicator)`
  margin-bottom: 10px;
  width: 100%;
  min-width: 290px;
`

export const StatusIndicatorStyled = styled(StatusIndicator)`
  margin-bottom: 10px;
  width: 100%;
  min-width: 290px;
`
export const ControllerStatusIndicatorStyled = styled(ControllerStatusIndicator)`
  margin-bottom: 10px;
  width: 100%;
  min-width: 290px;
`

export const WorkTimeIndicatorStyled = styled(WorkTimeIndicator)`
  margin-bottom: 10px;
  width: 100%;
  min-width: 290px;
`
export const ConnectionStatusIndicatorStyled = styled(ConnectionStatusIndicator)`
  margin-bottom: 10px;
  width: 100%;
  min-width: 290px;
`
export const MultiDimmingIndicatorStyled = styled(MultiDimmingIndicator)`
  margin-bottom: 10px;
  width: 100%;
  min-width: 290px;
`
