import React, { useCallback, useState } from 'react'
import noop from 'lodash/noop'
import pt from 'prop-types';
import { FormattedMessage as Lang } from 'react-intl'
import memoWithName from '@/hocs/memoWithName'
import {
    DescriptionWrapper,
    IndicatorWrapper, Status,
    Title,
    Value,
    ValueBorder,
    ValueBorders,
} from '@/components/widgets/StateIndicator/styles'
import {
    ValueWrapper,
    DriverWrapper,
    DriverContainer,
    DriverTitle
} from '@/components/widgets/StateIndicator/MultiDimmingIndicator/styles'
import LineStatus from '@/components/widgets/StateIndicator/MultiDimmingIndicator/lineStatus'
import ObjectWidgetSettings from '@/forms/ObjectWidgetSettings'
import { MARKS_COLORS } from '@/constants/styles/mapsMarks'
import { INFO, OK } from '@/constants/objectStatuses'

const MultiDimmingIndicator = ({
    className,
    title,
    telemetry,
    units,
    minValue,
    maxValue,
    onUpdateSettings
  }) => {
    const [settingsView, setSettingsView] = useState(false)

    const handleCloseSettings = useCallback(
        (event) => {
            if (event) {
                event.preventDefault()
            }
            setSettingsView(false)
        },
        [setSettingsView],
    )
    const getType = (type) => {
        switch(type) {
            case -8: {
                return <Lang id="menu.builtInRelay" />
            }
            case -9: {
                return <Lang id="menu.driver" />
            }
            default: {
                return <Lang id="menu.driverDali" />
            }
        }
    }

    const renderValueBorders = useCallback(
        () => (
            <ValueBorders>
                <ValueBorder>
                    <Lang id="menu.min" />:
                    {' '}
                    {minValue}
                    {' '}
                    {units}
                    {' '}
                </ValueBorder>
                <ValueBorder>
                    <Lang id="menu.max" />:
                    {' '}
                    {maxValue}
                    {' '}
                    {units}
                    {' '}
                </ValueBorder>
            </ValueBorders>
        ),
        [units, minValue, maxValue],
    )

    const renderSettingsView = useCallback(() => (
            <IndicatorWrapper
                className={className}
                column
            >
                <ObjectWidgetSettings
                    onCancel={handleCloseSettings}
                    onSubmit={onUpdateSettings}
                    initialValues={{
                        minValue: minValue,
                        maxValue: maxValue,
                    }}
                />
            </IndicatorWrapper>
        ),
        [
            minValue,
            maxValue,
            className,
            handleCloseSettings,
            onUpdateSettings,
        ])

    if (settingsView) {
        return renderSettingsView()
    }

    return (
        <IndicatorWrapper
            column
            small
            className={className}
            withoutSvgStyle
        >
            <DescriptionWrapper row>
                <Title pb={5}>{title}</Title>
            </DescriptionWrapper>
            <ValueWrapper>
                {
                    telemetry.drivers && telemetry.drivers.map((driver, index) => {
                        return (
                            <DriverContainer key={`${index}-${driver.rn}`}>
                                <DriverWrapper>
                                    <DriverTitle>
                                        <span>{getType(driver.rn)}</span>
                                    </DriverTitle>
                                    {
                                        driver.rn === -8 ?
                                            <Status spaceBetween color={
                                                driver.dim
                                                    ? MARKS_COLORS[OK]
                                                    : MARKS_COLORS[INFO]
                                            }>
                                                {driver.dim
                                                    ? <Lang id="menu.on" />
                                                    : <Lang id="menu.off" />
                                                }
                                            </Status> :
                                            <Value>
                                                <span>{`${driver.dim} `} {units}</span>
                                            </Value>
                                    }
                                </DriverWrapper>
                                {
                                    driver.rn === -8 ?
                                        <>
                                        </> :
                                        <>
                                            <LineStatus
                                                id={`${index}-${driver.rn}`}
                                                value={driver.dim}
                                                minValue={minValue}
                                                maxValue={maxValue}
                                            />
                                            <DescriptionWrapper row>
                                                {renderValueBorders()}
                                            </DescriptionWrapper>
                                        </>
                                }
                            </DriverContainer>
                            )
                    })
                }
            </ValueWrapper>
        </IndicatorWrapper>
    )
}

MultiDimmingIndicator.propTypes = {
    className: pt.string,
    minValue: pt.oneOfType([pt.number, pt.string]),
    maxValue: pt.oneOfType([pt.number, pt.string]),
    title: pt.string.isRequired,
    units: pt.string,
    onUpdateSettings: pt.func,
}
MultiDimmingIndicator.defaultProps = {
    minValue: 0,
    maxValue: 0,
    units: null,
    className: '',
    telemetry: {},
    onUpdateSettings: noop,
}

export default memoWithName(MultiDimmingIndicator)
