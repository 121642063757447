import {
  takeLatest, put, select,
} from 'redux-saga/effects'
import { push } from 'connected-react-router'
import get from 'lodash/get'
import qs from 'query-string'
import request from '@/helpers/axios'
import { getRouterLocation } from '@/store/selectors/router'
import { DASHBOARD_URL } from '@/constants/routes'
import { LOGIN_API_URL } from '@/constants/apiRoutes'
import { USER_LOGIN } from '@/store/actions/user'
import { loginSuccess, loginError } from '@/store/actions/user/authorization'
import { PASSWORD } from '@/constants/forms/signIn'
import {
  isSuperAdmin,
  isAdmin,
  isUser,
} from '@/helpers/userPermissions'
import cookie from 'react-cookies'

const ERRORS = {
  'access_denied.user_login:temporary_blocked': 'validation.passwordTimesLimit',
  'access_denied.auth_credentials:password_expired': 'validation.passwordExpired',
  'unauthorized.auth_credentials:wrong': 'validation.incorrectCredentials',
  'validation_failed.password:is_dictionary_word': 'validation.passwordNotValid',
  'access_denied.tenant:blocked': 'validation.tenantBlocked',
  'access_denied.group:blocked': 'validation.usersGroupBlocked',
  'access_denied.auth_credentials:user_blocked': 'validation.accessDenied',
  'access_denied.auth_credentials:group_blocked': 'validation.accessDenied',
}
const BLOCK_ERRORS = [
  'access_denied.auth_credentials:user_blocked',
  'access_denied.auth_credentials:group_blocked',
  'access_denied.auth_credentials:tenant_blocked',
]

function* loginUser({ payload }) {
  const { values, form } = payload
  const body = {
    username: values.username,
    password: values.password,
    grant_type: 'password',
  }
  const bodyString = qs.stringify(body)
  const headers = {
    'Content-Type': 'application/x-www-form-urlencoded',
    Authorization: `Basic ${window.btoa('gateway:gateway')}`,
  }

  try {
    const response = yield request({
      url: LOGIN_API_URL,
      method: 'post',
      headers,
      body: bodyString,
      isSecure: false,
    })

    cookie.save('lifeTime', response.data.expires_in)
    cookie.save('startTime', new Date().getTime())

    const { search } = yield select(getRouterLocation)
    const queryObject = qs.parse(search)
    const { user } = response.data
    const userRole = {
      isSuperAdmin: isSuperAdmin(user.role),
      isAdmin: isAdmin(user.role),
      isUser: isUser(user.role),
    }
    yield put(loginSuccess({
      ...response.data,
      user: {
        ...user,
        applications: user.permissions.applications.map((app) => app.id),
        roles: userRole,
      },
    }))
    if (queryObject.redirect) {
      const decodedUrl = decodeURIComponent(queryObject.redirect)
      yield put(push(decodedUrl))
    } else {
      yield put(push(DASHBOARD_URL))
    }
  } catch (error) {
    const errorCode = get(error, 'response.data.errorCode', '')
    if (BLOCK_ERRORS.includes(errorCode) || !!ERRORS[errorCode]) {
      form.setErrors({
        [PASSWORD]: ERRORS[errorCode] || 'validation.errorTryLater',
      })
      form.setSubmitting(false)
    }
    yield put(loginError(error))
  }
}

export default function* root() {
  yield takeLatest(USER_LOGIN.BEGIN, loginUser)
}
