import styled from 'styled-components'
import { Form } from 'formik'
import CheckBoxField from '@/components/fields/CheckBoxField'
import Button from '@/components/blocks/Button'
import DatePickerField from '@/components/fields/DatePickerField'
import {
  WHITE,
  GRAY,
  MINE_SHAFT,
} from '@/constants/styles/defaultTheme'
import { ACCEPT, RESET } from '@/constants/forms/globalFilterForm'



export const Container = styled.div`
  background-color: #FAFBFB;
  border-bottom: 1px solid #DDDDDD;
`

export const CustomDatePicker = styled(DatePickerField)`
  height: 28px;

`

export const CustomButton = styled(Button)`
  padding: 7px;
`

export const mainTitleContainer = styled(Button)`

`

export const FilterContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  padding: 5px;
  padding-left: 10px;
  // position: relative;
  border-left: 1px solid ${({ theme }) => theme.colors.borders.default};
  background: ${({ theme }) => theme.colors.backgrounds.header};
  && > div {
    padding: 5px;
  }
  ${({ mobile }) => mobile &&`
    flex-direction: column;
    width: 100%;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    padding-top: 15px;
  `}

`

export const FormContainer = styled(Form)`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  padding-left: 15px;
  ${({ mobile }) => mobile &&`
    padding: 0;
  `}
`

export const CustomCheckBoxField = styled(CheckBoxField)`
  margin-right: 10px;
`

export const ContentContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
  ${({ interval }) => interval && `
    margin-left: 10px;
  `};
  ${({ datePicker }) => datePicker && `
    margin-right: 0px;
    width: 135px;
    position: relative;
    .react-datepicker-popper {
      width: 300px;
      z-index: 4;
      top: -30px !important;
    }
  `};

  ${({title}) => title &&`
    position: relative;
  `}

  @media (max-width: 910px) {
    width: 100%;
    flex-direction: column;
    ${({ isMobile }) => isMobile &&`
    .react-datepicker-popper {
      left: -115px !important;
    }
    `}
  }
`

export const Text = styled.div`
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.8rem;
  ${({ reset }) => reset && `
    cursor: pointer;
  `};
  ${({ appeals }) => appeals && `
    padding: 0px 10px;
    cursor: pointer;
  `};
  ${({ datePicker }) => datePicker && `
    margin-right: 16px;
    margin-left: 16px;
  `};
  ${({ title, theme }) => title && `
    height: 100%;
    display: flex;
    align-items: center;
    justify-cintent: flex-start:
    min-width: 221px;
    white-space: nowrap;
    padding-right: 10px;
  `};
`

export const Title = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  display: flex;
  align-self: flex-start;
  align-items: center;
  margin-bottom: 10px;
  color: ${({ theme }) => theme.colors.colors.default};
  `

export const DateWrapper = styled.div`
  display: flex;
  align-items: center
  width: 100%;
`

export const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
  margin-top: 60px;
  width: 100%;
  margin-bottom: 20px;
  max-width: 400px;
`

export const StyledButton = styled(Button)`
  height: 38px;
  box-shadow: ${({ theme }) => theme.colors.shadows.field};
  border-radius: 4px;
  font-style: normal;
  font-size: 14px;
  line-height: 14px;
  text-transform: none;
  display: flex;
  align-items: center;
  ${({ usage }) => usage === ACCEPT && `
    background: linear-gradient(180deg, #FBBA58 0%, #FF9A02 100%);
    color: ${WHITE};
  `}
  ${({ usage }) => usage === RESET && `
    background: ${GRAY};
    color: ${MINE_SHAFT};
  `}
  &:first-child {
    margin-right: 5px;
  }

  @media (max-width: 910px) {
  ${({ usage }) => usage === ACCEPT && `
    background: #1061DF;
  `}
  width: 165px;
  }
`

export const IconContainer = styled.div`
  position: absolute;
  right: 12px;
  top: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  .svgFill {
    fill: ${({ theme }) => theme.colors.colors.default};
    width: 14px;
    height: 14px;
  }
  cursor: pointer;

`
