import styled from 'styled-components'
import Button from '@/components/blocks/Button'

export const MessageContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
  padding: 10px;
  border-radius: 4px;
  background: #F9EAD5;
  color: #E5910C;
  font-size: 1.2rem;
  line-height: 1.6rem;
  font-weight: 500;
  text-align: left;
  svg {
    width: 22px;
    margin: -1px 5px 0 0;
  }
  ${({ completed }) => completed && `
    background: rgba(84, 129, 12, 0.1);
    color: #54810C;
  `}
`

export const SubmitButton = styled(Button)`
  width: 100%;
  padding: 7px;
  margin-top: 15px;
  color: ${({ theme }) => theme.colors.colors.light};
  background: ${({ theme }) => theme.colors.scheme.backgrounds.fuelYellow};
  text-transform: capitalize;
  :hover {
    background: ${({ theme }) => theme.colors.scheme.backgrounds.fuelYellow};
  }
  ${({ disabled }) => disabled && `
    pointer-events: none;
    opacity: 0.7;
  `}
`