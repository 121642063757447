import createAction from '@/helpers/redux/createAction'

export const GET_INTEGRATIONS = createAction('@/GET_INTEGRATIONS')

export const CREATE_INTEGRATION = createAction('@/CREATE_INTEGRATION')
export const GET_INTEGRATION = createAction('@/GET_INTEGRATION')
export const UPDATE_INTEGRATION = createAction('@/UPDATE_INTEGRATION')
export const DELETE_INTEGRATION = createAction('@/DELETE_INTEGRATION')
export const REPEAT_INTEGRATION = createAction('@/REPEAT_INTEGRATION')
export const VERIFY_INTEGRATION = createAction('@/REPEAT_INTEGRATION')
export const INTEGRATION_VERIFICATION_PROGRESS = createAction('@/INTEGRATION_VERIFICATION_PROGRESS')

export const SELECT_SYSTEM = '@/SELECT_SYSTEM'
export const SELECT_INTEGRATION = '@/SELECT_INTEGRATION'
export const SET_MODE = '@/SET_MODE'
export const SET_SYNC = '@/SET_SYNC'
export const UPDATE_CUSTOM_INTEGRATION_SCHEME = '@/UPDATE_CUSTOM_INTEGRATION_SCHEME'

export const updatedScheme = (newScheme) => ({
  type: UPDATE_CUSTOM_INTEGRATION_SCHEME,
  payload: newScheme,
})

export const selectSystem = (id) => ({
  type: SELECT_SYSTEM,
  payload: id,
})

export const setMode = (mode) => ({
  type: SET_MODE,
  payload: mode,
})

export const setSync = (sync) => ({
  type: SET_SYNC,
  payload: sync,
})

export const setIntegration = (integration) => ({
  type: SELECT_INTEGRATION,
  payload: integration,
})
