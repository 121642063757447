import {
  takeLatest, put,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { TENANT_ENDPOINT } from '@/constants/apiRoutes'
import { DELETE_TENANT } from '@/store/actions/users'
import { requestGetAllTenants } from '@/store/actions/users/tenant/getAllTenants'
import { errorDeleteTenant } from '@/store/actions/users/tenant/deleteTenant'
import createNotifications from '@/helpers/notification'

function* deleteTenantSaga({ payload }) {
  const { id, name } = payload
  const toast = createNotifications()
  try {
    const response = yield request({
      url: `${TENANT_ENDPOINT}/${id}`,
      method: 'delete',
    })

    if (response) {
      yield put(requestGetAllTenants())
      toast({
        title: 'Успешная операция!',
        description: `Организация "${name}" успешно удалена!`,
      })
    }
  } catch (error) {
    yield put(errorDeleteTenant())
    console.log('TCL: function*deleteIntegrationSaga -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(DELETE_TENANT.REQUEST, deleteTenantSaga)
}
