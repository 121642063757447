import styled from 'styled-components'

export const NotificationsContainer = styled.div`
  position: relative;
  width: calc(100vw - 15px);
  max-width: 343px;
  min-height: 86px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
`
export const NotificationsIconContainer = styled.div`
  position: absolute;
  height: 86px;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
`

export const Title = styled.div` 
  position: relative;
  z-index: 10;

`

export const Text = styled.div`
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.2rem;
  color: #FAFBFB;
  text-align: left;
  position: relative;
  z-index: 10;
  ${({ headerTitle }) => headerTitle && `
    text-align: left;
    line-height: 1.7rem;
    font-size: 1.6rem;
    color: #FAFBFB;
    text-overflow: ellipsis;
    overflow: hidden;
  `}
`

export const ContainerInfo = styled.div`
  position: relative;
  display:flex;
  flex-direction: column;
  padding: 20px 0 0 20px;
  gap: 10px;
`

export const CloseButtonWraper = styled.div`
   width: 16px;
   height: 16px;
   z-index: 15;
   position: absolute;
   right: 12px;
   top: 15px;

   @media (max-width: 375px) {
    right: 5px;
  }
`