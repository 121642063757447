import React, {
  useRef,
  useState,
  useEffect,
  useMemo,
} from 'react'
import { createPortal } from 'react-dom'
import pt from 'prop-types'
import getPosition from '@/helpers/getPosition'
import {
  DEFAULT_COMMON_TOOLTIP_WIDTH,
  DEFAULT_COMMON_TOOLTIP_HEIGHT,
} from '@/constants/sizes'
import { defaultPriorityList } from './config'
import { ToolTip } from './styles'
import noop from 'lodash';
import useGetIsMobile from '@/hooks/useGetIsMobile'

const PortalTooltip = ({
  title,
  renderChildren,
  gap,
  priorityList,
  containerName,
  portalName,
  byClass,
  handleIconClick
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const frameElement = document.getElementById(containerName)
  const portalElement = document.getElementById(portalName)

  const wrapperRef = useRef(null)
  const toolTipRef = useRef(null)
  const [tooltipHeight, setTooltipHeight] = useState(DEFAULT_COMMON_TOOLTIP_HEIGHT)
  const [tooltipWidth, setTooltipWidth] = useState(DEFAULT_COMMON_TOOLTIP_WIDTH)
  const [toolTipPosition, setTooltipPosition] = useState(null)
  const isMobile = useGetIsMobile()


  useEffect(() => {
    if (toolTipRef && toolTipRef.current) {
      const {
        width = 0,
        height = 0,
      } = getPosition(toolTipRef.current)
      setTooltipHeight(height)
      setTooltipWidth(width)
    }
  }, [toolTipRef, isVisible])

  const defaultGetCoords = useMemo(() => () => ({}), [])

  const [frameHeight, setFrameHeight] = useState(null)
  const [frameWidth, setFrameWidth] = useState(null)
  const [toolTipPositionLeft, setToolTipPositionLeft] = useState()
  const [toolTipPositionTop, setToolTipPositionTop] = useState()

  useEffect(() => {
    setFrameWidth((frameElement || {}).clientWidth)
  }, [frameElement])

  useEffect(() => {
    setFrameHeight((frameElement || {}).clientHeight)
  }, [frameElement])

  const onMouseEnterHandler = (event) => {
    const currentElement = byClass
      ? event.target.closest('.portal-Tooltip')
      : event.currentTarget
    const {
      top, left, width, height,
    } = getPosition(currentElement)
    const selectedPriority = priorityList.filter(
      (element) => element.validate({
        gap,
        frameHeight,
        frameWidth,
        elementTop: top,
        elementLeft: left,
        elementHeight: height,
        elementWidth: width,
        toolTipWidth: tooltipWidth,
        toolTipHeight: tooltipHeight,
      }),
    )[0] || {}
    const {
      tooltipLeft = null,
      tooltipTop = null,
    } = (
      selectedPriority.getCoords || defaultGetCoords
    )({
      gap,
      frameHeight,
      frameWidth,
      elementTop: top,
      elementLeft: left,
      elementHeight: height,
      elementWidth: width,
      toolTipWidth: tooltipWidth,
      toolTipHeight: tooltipHeight,
    })
    setToolTipPositionLeft(tooltipLeft)
    setToolTipPositionTop(tooltipTop)
    setTooltipPosition(selectedPriority.name || null)
    setIsVisible(true)
  }

  const onMouseLeaveHandler = () => {
    setIsVisible(false)
  }

  const onClickHandler = (event) => {
    if (event.detail > 1) {
      return noop
    }
    if (event.detail === 1) {
      onMouseLeaveHandler()
      handleIconClick()
    }
    onMouseLeaveHandler()
  }

  const renderTooltip = (elementTitle) => createPortal(
    (
      <ToolTip
        top={toolTipPositionTop}
        left={toolTipPositionLeft}
        ref={toolTipRef}
        isVisible={title && isVisible}
        tipPosition={toolTipPosition}
      >
        {elementTitle}
      </ToolTip>
    ),
    portalElement,
  )

  return (
    <>
      {!isMobile && renderTooltip(title)}
      {renderChildren(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler, onClickHandler)}
    </>
  )
}

PortalTooltip.propTypes = {
  title: pt.oneOfType([pt.string, pt.element]),
  gap: pt.number,
  containerName: pt.string,
  renderChildren: pt.func.isRequired,
  portalName: pt.string,
  priorityList: pt.arrayOf(pt.shape({
    name: pt.string,
    validate: pt.func,
    getCoords: pt.func,
  })),
  byClass: pt.bool,
  handleIconClick: pt.func
}
PortalTooltip.defaultProps = {
  title: null,
  byClass: false,
  gap: 10,
  containerName: 'app-wrapper',
  portalName: 'portal-tooltip',
  priorityList: defaultPriorityList,
}

export default PortalTooltip
