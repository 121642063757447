import styled from 'styled-components'

const DraggableItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 710px) {
     width: 100%;
   }
`
export const MoveIconWrapper = styled.div`
  display: flex;
  @media (max-width: 910px) {
    display: none;
   }
`

export default DraggableItemWrapper