export default {
  messages: {
    setEvens: 'Set events',
    setStats: 'Set up statuses',
    setFields: 'Set columns',
    export: 'Export',
  },
  alarmsTypeTitle: {
    error: 'Faults',
    warning: 'Warnings',
    fixed: 'Good',
  },
  alarmTypeTitle: {
    error: 'Fault',
    warning: 'Warning',
    fixed: 'Good',
    unknown: 'Undefined',
  },
  buttonsTitles: {
    buildTable: 'Build table',
    discardTable: 'Reset range',
  },
  forms: {
    dateRange: 'Date range',
    events: 'Events',
  },
  options: {
    correct: 'Correct',
    faulty: 'Faulty',
    warning: 'Warning',
    undefined: 'Undefined',
  },
  alarmToolTip: 'Mark all as viewed',
}