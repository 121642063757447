import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import LiveTime from '@/components/icons/livetime'

export const searchFields = [
    {
        id: 'name',
        name: <Lang id="tableFields.search.name" />,
        value: 'ASC',
    },
    {
        id: 'installationType',
        name: <Lang id="tableFields.search.installationType" />,
        value: 'ASC',
        customCell: (props) => {
            const { value } = props
            const title = `globalNames.objectTypes.${value}`
            return (
                <Lang id={title} />
            )
        },
    },
    {
        id: 'geoZone',
        name: <Lang id="tableFields.search.geoZone" />,
        value: 'ASC',
    },
    {
        id: 'project',
        name: <Lang id="tableFields.search.project" />,
        value: 'ASC',
    },
    {
        id: 'integrationName',
        name: <Lang id="tableFields.search.integrationName" />,
        value: 'ASC',
    },
    {
        id: 'region',
        name: <Lang id="tableFields.search.region" />,
        value: 'ASC',
    },
    {
        id: 'city',
        name: <Lang id="tableFields.search.city" />,
        value: 'ASC',
    },
    {
        id: 'exploitationDateStart',
        name: <Lang id="tableFields.search.exploitationDateStart" />,
        value: 'ASC',
    },
    {
        id: 'exploitationDateEnd',
        name: <Lang id="tableFields.search.exploitationDateEnd" />,
        value: 'ASC',
    },
    {
        id: 'exploitationOrganization',
        name: <Lang id="tableFields.search.exploitationOrganization" />,
        value: 'ASC',
    },
    {
        id: 'owner',
        name: <Lang id="tableFields.search.owner" />,
        value: 'ASC',
    },
]

export const searchExportOptions = [
    {
        name: 'EXCEL',
        value: 'excel',
        icon: LiveTime.EXELIcon,
    }
]

export default {
    searchFields,
    searchExportOptions
}
