import React, {
  useState,
  useContext,
  useEffect,
  useMemo, useCallback, useRef
} from 'react'
import {
  getObjectStateChildStatistic,
} from '@/helpers/getTelementry'
import pt from 'prop-types'
import noop from 'lodash/noop'
// import debounce from 'lodash/debounce'
import { ThemeContext } from 'styled-components'
import memoWithName from '@/hocs/memoWithName'
import { injectIntl } from 'react-intl'
import { treeHandlers } from 'react-hyper-tree'
import {
  clusterOptions,
} from '@/constants/maps'
import { circle } from "@turf/circle";
// import zoneConfig from '@/data/mapTree/zoneConfig'

import { GEOZONE, OBJECT_ELEMENT } from '@/constants/objectTypes'
// import { DEBOUNCE_DELAY_MEDIUM } from '@/constants/time'
import renderJSXToString from '@/helpers/renderJSXToString'
// import pinImage from '@/assets/icons/maps/pinBack.png'
// import CREATE_REGION_NAMES from '@/constants/forms/createGeoZone'
import { getZoneStatistic } from '@/helpers/dataFormator'
import { StyledMap } from './styles'
import PinIcon from './components/PinIcon'
import PinIconHover from './components/PinIconHover'
import MeshWithStatsHover from './components/MeshWithStatsHover'
import ControllerIcon from './components/ControlerIcon'
import ControllerIconHover from './components/ControlerIconHover'
import ZoneIcon from './components/ZoneIcon'
import ZoneIconHover from './components/ZoneIconHover'
// import PinIconSelectedByController from './components/PinIconSelectedByControler'
import ClusterIcon from './components/ClusterIcon'
import ClusterIconHover from './components/ClusterIconHover'
import {
  isController,
} from './utlis'
import { STATE_TO_STATUS } from '@/components/blocks/SidebarTree/components/TreeNode/component'
// import { getZIndexByType } from '@/helpers/maps'
import CheckedIconPin from '@/components/blocks/YandexMap/components/CheckedIcon'
import CheckIconPinHover from '@/components/blocks/YandexMap/components/CheckIconHover'

import {
  YMap,
  YMapComponentsProvider,
  YMapDefaultSchemeLayer,
  YMapDefaultFeaturesLayer,
  YMapCustomClusterer,
  YMapMarker,
  YMapFeature,
  // YMapListener,
} from "ymap3-components";

// const returnZoneSize = (element) => {
//   if (element.count <= 99) {
//     return [40, 40]
//   }
//   if (element.count > 99 && element.count <= 999) {
//     return [60, 60]
//   }
//   if (element.count > 999) {
//     return [80, 80]
//   }
//   return [40, 40]
// }
// const returnZoneOffset = (element) => {
//   if (element.count <= 99) {
//     return [-20, -20]
//   }
//   if (element.count > 99 && element.count <= 999) {
//     return [-30, -30]
//   }
//   if (element.count > 999) {
//     return [-40, -40]
//   }
//   return [-20, -20]
// }

const YandexMap = (props) => {
  const {
    // defaultState,
    // clustersOptions,
    mapCenter,
    hoveredPinByID,
    mapZoom,
    // updateMapCenter,
    // updateMapZoom,
    // onClick,
    handleOpenPassport,
    // mash,
    globalZoneId,
    setSelectedZoneIdentifier,
    selectNodeByZone,
    zones,
    pinnedNode,
    pins,
    // isMobile,
  
    createPoint,
    // pointsArray,
    // createElementType,
    isDisplayElements,
    // formRef,
    intl,
    yandexKey,
    isMonitoring,
    isPassportization,
    handleClickZone,
    objectStateFilters,
    // globalFilters,
    // connectedLinesCoordinates,
    isMashMode,
    // isLinesMode,
    objectsGroups,
    groupCreateEditMode,
    // selectedNodes,
    // checkedNodes,
    // color,
    // handleCheckNode,
    // isRussianBorders
  } = props
  const theme = useContext(ThemeContext)
  const [selectedZoneById, setSelectedZoneById] = useState(null)
  // const rootZone = useMemo(() => zoneConfig[1] ,[])
  // const [mapZoomTest, setMapZoomTest] = useState(mapZoom)
  const ymap3Ref = useRef();
  const [hoverMarkers, setHoverMarkers] = useState({});
  const [location, setLocation] = useState({ center: [64.045274, 90.513266], zoom: 4 })
  const updatePins = useMemo(() => {
    return pins.map((item) => {
      if (isController(item.type) && isDisplayElements) {
        return {
          ...item,
          statistic: getObjectStateChildStatistic(pins.filter((pin) => pin.parentId === item.id))
        }
      }
      return item
    })
  },
  [pins, isDisplayElements])


  useEffect(() => {
    setLocation({center: mapCenter.reverse(), zoom: mapZoom })
  },[mapCenter, mapZoom])

  // const objectManagerRef = useRef(null)

  // useEffect(() => {
  //   setSelectedZoneIdentifier(null)
  //   setMapZoomTest(4)
  // }, [globalFilters, setSelectedZoneIdentifier])

  useEffect(() => {
    if (pinnedNode?.id && (!isMonitoring || !isPassportization)) {
      setHoverMarkers({[pinnedNode.id]: true})
    }
    return () => setHoverMarkers({})

  }, [pinnedNode, isMonitoring, isPassportization])

  // const [activeMash, setActiveMash] = useState(0)
  // ## CODE RELATED TO MAP AND CLUSTERRER INSTANSE ##

  // const [map, setMap] = useState(null)
  // const [isOnResizeEventWasAdd, setIsOnResizeEventWasAdd] = useState(false)
  // const [isCreateNewPointWasAdd, setIsCreateNewPointWasAdd] = useState(false)

  // useEffect(() => {
  //   if (mash.length > 0) {
  //     const interval = setInterval(() => {
  //       const mashIndex = activeMash < mash.length - 1
  //         ? (activeMash + 1)
  //         : 0
  //       setActiveMash(mashIndex)
  //     }, 1000)

  //     return () => {
  //       clearInterval(interval)
  //     }
  //   }
  // }, [mash, activeMash, setActiveMash])

  const displayedZonesWithTelemetry = useMemo(() => {
    if (objectsGroups) {
      return []
    }
    const arrayOfDisableZonesIds = Object.keys(zones)
      .filter((elementKey) => elementKey !== globalZoneId && elementKey !== 1)
    const disableZones = arrayOfDisableZonesIds.map((element) => zones[element])
    const disableZonesUpdate = disableZones.map((element) => getZoneStatistic(element, objectStateFilters, isMonitoring))
    return disableZonesUpdate.filter((zone) => zone.count !== 0 && zone.id !== globalZoneId)
  }, [globalZoneId, isMonitoring, zones, objectStateFilters, objectsGroups])

  const displayedPins = useMemo(() => {
    if (isMashMode) {
      return updatePins
    }
    if (objectsGroups) {
      return pins
    }
    if (!globalZoneId || !(zones[globalZoneId] || {}).id) {
      return []
    }
    const selector = (zones[globalZoneId] || {}).id || null

    const selectedPinsByZoneSelector = updatePins.filter((element) => element.geoZoneId === selector && element.treeNodeType === OBJECT_ELEMENT)
    return selectedPinsByZoneSelector.map((element) => ({
      ...element,
      location: element.location,
      name: element.name,
      status: isPassportization ? undefined : STATE_TO_STATUS[element.status]
    }))
  }, [globalZoneId, isPassportization, zones, isMashMode, updatePins, pins, objectsGroups])

  // const displayedZonesBorders = useMemo(() => {
  //   const selectedZoneIds = []
  //   if (selectedZoneById) {
  //     selectedZoneIds.push(selectedZoneById)
  //   }
  //   return selectedZoneIds.map((elementId) => zones[elementId])
  // }, [zones, selectedZoneById])

  const zonesMaps = useMemo(()=>{
      const zonesID = Object.keys(zones)
      return zonesID.map((id) => zones[id]).map((el) => ({...el, location: el.location.reverse()}))
  },[zones])

  const selectZoneHandler = (zone) => {
    setSelectedZoneIdentifier(zone.id)
    selectNodeByZone(zone)
    setSelectedZoneById(null)
    handleClickZone(zone)
  }

  // const zoneOnEnterHandler = (zone) => {
  //   setSelectedZoneById(zone.id)
  // }
  // const zoneOnLeaveHandler = () => {
  //   setSelectedZoneById(null)
  // }

  // const deselectSelectedZone = () => {
  //   setSelectedZoneById(null)
  // }

  const hoverSelectedPins = (element, noHover = false) => {
    const activeElementId = hoveredPinByID || selectedZoneById || pinnedNode.id
    if (element.mash && element.id === hoveredPinByID) {
      return MeshWithStatsHover
    }
    if (element.mash) {
      return PinIcon
    }

    if (objectsGroups) {
      return element.checked ? CheckedIconPin : PinIcon
    }

    if (
      isController(element.installationType || element.type )
      && element.id === activeElementId
      && !createPoint
      && !isPassportization
      && element.count
    ) {
      return ControllerIconHover
    }

    if (isController(element.installationType || element.type) && !isPassportization && element.count) {
      return ControllerIcon
    }

    // if (
    //   activeElementId === element.id
    //   && !noHover
    //   && !createPoint
    // ) {
    //   return PinIconHover
    // }

    return PinIcon
  }
  const createUpdateElement = (element) => {
    if (objectsGroups) {
      return element
    }
    const count = Object.keys(element?.statistic).reduce((accumulator, type) => {
      const typeCount = element?.statistic[type] || 0
        return accumulator + typeCount
      }, 0)
    return {
      ...element,
      count,
    }
  }

  const createIconPin = (icon, element, pinColor) => {
    return renderJSXToString(
      icon,
      element,
      theme,
      intl,
      isMonitoring,
      isPassportization,
      pinColor
    )
  }


  // const openHoverPin = (objectId) => {
  //   if (!objectManagerRef.current){
  //     return null
  //   }
  //   const objects = objectManagerRef.current.objects
  //   objects.setObjectOptions(objectId, {
  //     'zIndex': YMSP_Z_INDEX.geoObject.large,
  //     'zIndexHover': YMSP_Z_INDEX.geoObject.large
  //   })
  //   const element = objects.getById(objectId)?.objectData
  //   if (!element) {
  //     return null
  //   }
  //   if (objectId !== pinnedNode.id && !objectsGroups) {
  //     closeHoverPin(pinnedNode.id)
  //   }
  //   if (isController(element.type) && !objectsGroups) {
  //     displayedPins.forEach((pin) => {
  //       if (pin.parentId === objectId) {
  //         objects.setObjectProperties(pin.id, createIconPin(PinIconSelectedByController, pin))
  //       }
  //     })
  //     objects.setObjectProperties(objectId, createIconPin(isPassportization || !element.count ? PinIconHover : ControllerIconHover, element))
  //     return null
  //   }
  //   objects.setObjectProperties(objectId, createIconPin(objectsGroups ? CheckIconPinHover : PinIconHover, element))
  // }

  // const closeHoverPin = (objectId) => {
  //   if (!objectManagerRef.current){
  //     return null
  //   }
  //   const objects = objectManagerRef.current.objects
  //   const element = objects.getById(objectId)?.objectData
  //   if (!element) {
  //     return null
  //   }
  //   if (!objectsGroups && isController(element.type)) {
  //     displayedPins.forEach((pin)=>{
  //       if ((pin.parentId === objectId || pin.parentTreeId === objectId)) {
  //         objects.setObjectProperties(pin.id, createIconPin(PinIcon, pin))
  //       }
  //     })
  //     objects.setObjectProperties(objectId, createIconPin(isPassportization || !element.count ? PinIcon : ControllerIcon, element))
  //     objects.setObjectOptions(objectId, {
  //       'zIndex': YMSP_Z_INDEX.geoObject.big,
  //       'zIndexHover': YMSP_Z_INDEX.geoObject.big
  //     })
  //     return null
  //   }
  //   objects.setObjectOptions(objectId, {
  //     'zIndex': YMSP_Z_INDEX.geoObject.small,
  //     'zIndexHover': YMSP_Z_INDEX.geoObject.small
  //   })
  //   if (objectsGroups) {
  //     const isChecked = checkedNodes.length ? checkedNodes.some(node => node.id === element.id) : element.checked
  //     objects.setObjectProperties(objectId, createIconPin(isChecked ? CheckedIconPin : PinIcon, element, element.color || color ))
  //     return null
  //   }
  //   objects.setObjectProperties(objectId, createIconPin(PinIcon, element))
  // }

  // const clusterOnEnter = (id) => {
  //     const cluster = objectManagerRef.current.clusters.getById(id)
  //     if (!cluster || isPassportization ) {
  //       return
  //     }
  //     const children = cluster.properties.geoObjects
  //     objectManagerRef.current.clusters.setClusterOptions(id, {
  //       'clusterIconContentLayout': clusterHoverIconContentLayout(children),
  //       'zIndex': YMSP_Z_INDEX.geoObject.large,
  //       'zIndexHover': YMSP_Z_INDEX.geoObject.large
  //     })  
  // }
  
  // const clusterOnLeave = (id) => {
  //   const cluster = objectManagerRef.current.clusters.getById(id)
  //     if (!cluster || isPassportization) {
  //       return
  //     }
  //     const children = cluster.properties.geoObjects
  //     objectManagerRef.current.clusters.setClusterOptions(id, {
  //       'clusterIconContentLayout': clusterIconContentLayout(children),
  //       'zIndex': YMSP_Z_INDEX.geoObject.small,
  //       'zIndexHover': YMSP_Z_INDEX.geoObject.small
  //     })
  // }

  // const mouseEnterHandler = (event) => {
  //   const objectId = event.get('objectId')
  //   if(typeof objectId === 'string') {
  //     clusterOnEnter(objectId)
  //     return null
  //   }
  //   openHoverPin(objectId)
  // }

  // const mouseLeaveHandler = (event) => {
  //   const objectId = event.get('objectId')
  //   if(typeof objectId === 'string') {
  //     clusterOnLeave(objectId)
  //     return null
  //   }
  //   closeHoverPin(objectId)
  // }

  // useEffect(() => {
  //   if (objectsGroups && objectManagerRef.current) {
  //     const objects = objectManagerRef.current.objects
  //     selectedNodes.forEach(node => {
  //       objects.setObjectProperties(node.id, createIconPin(node.checked ? CheckedIconPin : PinIcon, node, color))
  //     })
  //   }
  // }, [selectedNodes, color, objectsGroups])


  const pinOnClickHandler = (object) => {
    if (objectsGroups && groupCreateEditMode) {
        const tree = treeHandlers.trees['objects-groups-form-tree']
        const path = object.objectData.original.options.path
        tree.handlers.setSelectedByPath(path.slice(1))
        object.objectData.checked = !object.objectData.checked
        // objects.setObjectProperties(id, createIconPin(CheckedIconPin, element, color))
        // handleCheckNode([element], false)
      return
    }
    handleOpenPassport(object.objectData)
  }

  // const clusterIconContentLayout = (children) => {
  //   if (!map) {
  //     return null
  //   }
  //   return map.templateLayoutFactory.createClass(renderJSXToString(ClusterIcon, children, theme, intl, isMonitoring, isPassportization))
  // }

  // const clusterHoverIconContentLayout = (children) => {
  //   if (!map) {
  //     return null
  //   }
  //   return map.templateLayoutFactory.createClass(renderJSXToString(
  //     ClusterIconHover, children, theme, intl, isMonitoring, isPassportization
  //   ))
  // }

  // const updateMapZoomHandler = (newZoom, newCenter) => {
  //   updateMapZoom(newZoom)
  //   updateMapCenter(newCenter)
  // }

  // const selectZoneByHandler = (
  //   newZoom,
  //   oldZoom,
  //   center,
  //   bounds,
  //   displayZones,
  //   selectedZoneId,
  // ) => {
  //   if (
  //     displayZones
  //     && newZoom
  //     && center
  //     && bounds
  //     && newZoom >= mapZoomBreakPoints.big
  //     && selectedZoneId === 1
  //     && newZoom > oldZoom
  //   ) {
  //     const [leftBottom, topRight] = bounds
  //     const [bottom, left] = leftBottom
  //     const [top, right] = topRight
  //     const [centerY, centerX] = topRight
  //     const pinsInFrame = displayZones.filter((element) => {
  //       const [y, x] = element.location
  //       if (
  //         y >= left
  //         && y <= right
  //         && x >= bottom
  //         && x <= top
  //       ) {
  //         return false
  //       }
  //       return true
  //     })
  //     const closestZone = pinsInFrame.reduce((accumulator, element) => {
  //       const [y, x] = element.location
  //       const deltaY = Math.abs(centerY - y)
  //       const deltaX = Math.abs(centerX - x)
  //       const delta = deltaY + deltaX
  //       if (delta > accumulator.delta) {
  //         return accumulator
  //       }
  //       return {
  //         ...element,
  //         delta,
  //       }
  //     }, {})
  //     setSelectedZoneIdentifier(closestZone.id)
  //     setSelectedZoneById(null)
  //   }
  // }

  // const debounceZoom = debounce(updateMapZoomHandler, DEBOUNCE_DELAY_MEDIUM)
  // const debounceZone = debounce(selectZoneByHandler, DEBOUNCE_DELAY_MEDIUM)
  // const changeBoundsHandler = function (event) {
  //   const zoneId = get(event, 'originalEvent.map.state.zoneId', null)
  //   const displayZones = get(event, 'originalEvent.map.state.displayZones', [])
  //   debounceZoom(
  //     event.originalEvent.newZoom,
  //     event.originalEvent.oldZoom,
  //     event.originalEvent.newCenter,
  //     zoneId,
  //   )
  //   debounceZone(
  //     event.originalEvent.newZoom,
  //     event.originalEvent.oldZoom,
  //     event.originalEvent.newCenter,
  //     event.originalEvent.newBounds,
  //     displayZones,
  //     zoneId,
  //   )
  // }

  // const onCreatePointHandler = (event) => {
  //   const form = get(event, 'originalEvent.map.state.formRef.current', {})
  //   const localCreateElementType = get(event, 'originalEvent.map.state.createElementType', {})
  //   const coordinates = event.get('coords')
  //   const lat = coordinates[0].toFixed(6)
  //   const lon = coordinates[1].toFixed(6)
  //   const setFieldValue = get(form, 'setFieldValue', noop)
  //   if (localCreateElementType === 'city') {
  //     map.geocode(coordinates).then((res) => {
  //       const firstGeoObject = res.geoObjects.get(0);
  //       const setFieldValue = get(form, 'setFieldValue', noop)
  //       setFieldValue(CREATE_REGION_NAMES.LATITUDE, lat)
  //       setFieldValue(CREATE_REGION_NAMES.LONGITUDE, lon)
  //       setFieldValue(CREATE_REGION_NAMES.NAME, firstGeoObject.getLocalities()[0] || '')
  //       setFieldValue(CREATE_REGION_NAMES.CITY, firstGeoObject.getLocalities()[0] || '')
  //     })
  //   } else {
  //     setFieldValue('LOCATION.LATITUDE', lat)
  //     setFieldValue('LOCATION.LONGITUDE', lon)
  //   }
  // }

  // const mapInitListeners = (ref) => {
  //   if (ref) {
  //     // eslint-disable-next-line
  //     ref.state.createElementType = createElementType
  //     ref.state.createPoint = createPoint
  //     ref.state.formRef = formRef
  //     ref.state.zoneId = globalZoneId
  //     ref.state.displayZones = displayedZonesWithTelemetry
  //     if (ref && !isOnResizeEventWasAdd) {
  //       ref.events.add('click', onClick)
  //       ref.events.add(
  //         'boundschange',
  //         changeBoundsHandler,
  //         { zoneId: globalZoneId, displayZones: displayedZonesWithTelemetry },
  //       )
  //       setIsOnResizeEventWasAdd(true)
  //     }
  //     if (ref && createPoint && !isCreateNewPointWasAdd) {
  //       ref.events.add('click', onCreatePointHandler)
  //       setIsCreateNewPointWasAdd(true)
  //     }
  //     if (!createPoint) {
  //       ref.cursors.push('grab')
  //     } else {
  //       ref.cursors.push('crosshair')
  //     }
  //     return null
  //   }
  // }

  // const objectManagerInit = (ref) => {
  //   if(!ref){
  //     return 
  //   }
  //   objectManagerRef.current = ref
  //   const clusters = ref.clusters.getAll()
  //   clusters.forEach((cluster) => {
  //     ref.clusters.setClusterOptions(cluster.id, {
  //       'clusterIconContentLayout': clusterIconContentLayout(cluster.properties.geoObjects)
  //     })
  //   })
  // }

  // const createTemplateLayoutFactory = (ymaps) => {
  //   setMap(ymaps)
  // }
  // const featureCollectionPins = useMemo(() => {
  //   const features = {
  //     'type': 'FeatureCollection',
  //     'features': []
  //   }
  //   displayedPins.forEach((pin) => {
  //     if (isController(pin.installationType)) {
  //       pin = createUpdateElement(pin)
  //     }
  //     const properties = {
  //       iconContent: renderJSXToString(
  //         hoverSelectedPins(pin),
  //         pin,
  //         theme,
  //         intl,
  //         isMonitoring,
  //         isPassportization,
  //         pin.color
  //       ),
  //     }
          
  //     const options = {
  //       zIndex: getZIndexByType(pin, pinnedNode),
  //       iconLayout: 'default#imageWithContent',
  //       iconImageHref: pinImage,
  //       iconImageSize: [18, 18],
  //       iconImageOffset: [-3, 0],
  //       draggable: false,
  //     }

  //     if (pin.parentTreeId && pin.elementType !== GEOZONE && pin.type !== 'UNKNOWN') {
  //       features.features.push(
  //         {
  //           "type": "Feature",
  //           "id": pin.id,
  //           "geometry": {
  //             "type": "Point",
  //             "coordinates": pin.location
  //           },
  //           'options': options,
  //           "properties": properties,
  //           'objectData' : pin
  //         })

  //     }
  //   })

  //   return JSON.stringify(features) 

  // },[displayedPins, intl, theme])

  const featureCollectionPins = useMemo(() => {
    const features = []
    displayedPins.forEach((pin) => {
      if (isController(pin.installationType)) {
        pin = createUpdateElement(pin)
      }
      const properties = {
        iconContent: renderJSXToString(
          hoverSelectedPins(pin),
          pin,
          theme,
          intl,
          isMonitoring,
          isPassportization,
          pin.color
        ),
      }
          
      // const options = {
      //   zIndex: getZIndexByType(pin, pinnedNode),
      //   iconLayout: 'default#imageWithContent',
      //   iconImageHref: pinImage,
      //   iconImageSize: [18, 18],
      //   iconImageOffset: [-3, 0],
      //   draggable: false,
      // }

      if (pin.parentTreeId && pin.elementType !== GEOZONE && pin.type !== 'UNKNOWN') {
        features.push(
          {
            "type": "Feature",
            "id": pin.id,
            "geometry": {
              "type": "Point",
              "coordinates": pin.location.reverse()
            },
            // 'options': options,
            "properties": properties,
            'objectData' : pin
          })

      }
    })

    return features

  },[displayedPins, intl, theme])

// const featureCollectionZone = useMemo(() => {
//     const features = []
//   // const features = isRussianBorders ? 
//   //   [
//   //     {
//   //       "type": "Feature",
//   //       "id": 'root',
//   //       "geometry": {
//   //         "type": "Polygon",
//   //         "coordinates": rootZone.border
//   //       },
//   //       'options': defaultZoneOptions,
//   //     },
//   //   ]
//   // : [

//   // ]
  

//   zonesMaps.forEach((element) => {
//         features.push(
//           {
//           "type": "Feature",
//           "id": element.id,
//           "geometry": {
//             "type": "Circle",
//             "coordinates": element.location,
//             'radius': element.radius * 1000,
//           },
//           'options': defaultZoneOptions,
//           })
//   })

//   return features

// }, [zonesMaps, rootZone, isRussianBorders])


const markerMouseOver = useCallback((id) => {
  setHoverMarkers({[id]: true});
}, []);

const markerMouseOut = useCallback(() => {
  setHoverMarkers({});
}, []);

const cluster = (coordinates, features) => {
  const clusterId = `${features[0].id}-${features.length}`
    return (
    <YMapMarker key={`${features[0].id}-${features.length}`} coordinates={coordinates} zIndex={hoverMarkers[clusterId]? 99999 : 0}>
      <div
      onMouseEnter={() => markerMouseOver(clusterId)}
      onMouseLeave={() => markerMouseOut()}
      >
      {renderJSXToString(hoverMarkers[clusterId] ? ClusterIconHover : ClusterIcon, features, theme, intl, isMonitoring, isPassportization)}
      </div>

    </YMapMarker>
  )}

const marker = (feature) => {
    return (
      <YMapMarker coordinates={feature.geometry.coordinates} zIndex={hoverMarkers[feature.id]? 9999 : 0} >
        <div 
          onMouseEnter={() => markerMouseOver(feature.id)}
          onMouseLeave={() => markerMouseOut(feature.id)}
          onClick={()=> pinOnClickHandler(feature)}
        >
        {hoverMarkers[feature.id]? createIconPin(objectsGroups ? CheckIconPinHover : PinIconHover, feature.objectData) : feature.properties.iconContent}
        </div>
      </YMapMarker>
  )}

  console.log(ymap3Ref)
  return (
      <YMapComponentsProvider apiKey={yandexKey} >
        <StyledMap >
        <YMap 
          location={location}
          zoomRange={{min: 3, max: 18}}
          ref={ymap3Ref}
          >
          <YMapDefaultSchemeLayer/>
          <YMapDefaultFeaturesLayer />
           { !createPoint && <YMapCustomClusterer
              cluster={cluster}
              marker={marker}
              gridSize={128}
              features={featureCollectionPins}
              maxZoom={17}
            />}
            {!createPoint && displayedZonesWithTelemetry.map((zone) => 
              <YMapMarker coordinates={[zone.point.longitude, zone.point.latitude]} >
                <div 
                  onClick={() => selectZoneHandler(zone) }
                  onMouseEnter={() => setSelectedZoneById(zone.id)}
                  onMouseLeave={() => setSelectedZoneById(null)}
                  >
                  {renderJSXToString(
                    selectedZoneById === zone.id ? 
                      ZoneIconHover 
                    : 
                      ZoneIcon,
                    zone,
                    theme,
                    intl,
                  )}
                </div>
              </YMapMarker>
            )}
            {!createPoint && zonesMaps.map((zone) => 
              <YMapFeature 
              geometry={circle(zone.location, zone.radius * 1000, {units: 'meters'}).geometry} 
              style={{
                simplificationRate: 0,
                stroke: [
                {
                  color: 'red',
                  width: 2,
                }
              ],
              fill: '#00000000'}} 
              />
            )}
        </YMap>
        </StyledMap>
     </YMapComponentsProvider>
    );
  // return (
  //   <StyledYMaps query={{ apikey: yandexKey, load: 'geocode' }}>
  //     <StyledMap
  //       onLoad={createTemplateLayoutFactory}
  //       state={{ center: mapCenter, zoom: mapZoomTest }}
  //       defaultState={defaultState}
  //       modules={[
  //         'layout.ImageWithContent',
  //         'borders',
  //         'templateLayoutFactory',
  //         'geoObject.addon.balloon',
  //         'geoObject.addon.hint',
  //         'ObjectManager'
  //       ]}
  //       instanceRef={mapInitListeners}
  //       width="100%"
  //       height="100%"
  //       id="ymap"
  //       options={{
  //         maxAnimationZoomDifference: 5,
  //         autoFitToViewport: 'always',
  //         avoidFractionalZoom: false,
  //         minZoom: mapZoomBreakPoints.min,
  //         maxZoom: mapZoomBreakPoints.max - 1,
  //         yandexMapDisablePoiInteractivity: true,
  //         suppressMapOpenBlock: true
  //       }}
  //       onClick={deselectSelectedZone}
  //     >
  //     {isMobile && <ZoomControl options={{ float: "bottom" }} />}
  //     {!createPoint && !!displayedPins.length && (
  //       <>
  //        <ObjectManager
  //          instanceRef={objectManagerInit}
  //          options={{
  //            clusterize: true,
  //            gridSize: gridSize.middle,
  //            maxZoom: 15,
  //          }}
  //          clusters={{
  //            ...clustersOptions,
  //            zIndexHover: YMSP_Z_INDEX.large
  //          }}
  //          features={featureCollectionPins}
  //          onClick={pinOnClickHandler}
  //          onMouseenter={mouseEnterHandler}
  //          onMouseleave={mouseLeaveHandler}
  //        />
  //       </>
  //       )}
  //        {!createPoint && !objectsGroups &&
  //           <ObjectManager
  //             options={{
  //               clusterize: false,
  //             }}
  //             features={featureCollectionZone}
  //           />
  //         }
  //       {/* {!isMashMode && !isDisplayElements
  //         && displayedZonesBorders?.length > 0
  //         && displayedZonesBorders.map((border) => {
  //           return (
  //             <Circle
  //               key={`${border.id}-borderBorder`}
  //               geometry={[border.location, border.radius * 1000]}
  //               options={defaultZoneOptions}
  //               onClick={deselectSelectedZone}
  //             />
  //           )
  //         })} */}
  //       {!isMashMode && displayedZonesWithTelemetry.map((zone) => (
  //         <>
  //           {zone.id !== globalZoneId && (
  //           <GeoObject
  //             key={`${zone.id}-zone`}
  //             geometry={{
  //               type: 'Point',
  //               coordinates: zone.location,
  //             }}
  //             properties={{
  //               iconContent: renderJSXToString(
  //                 selectedZoneById === zone.id
  //                   ? ZoneIconHover
  //                   : ZoneIcon,
  //                 zone,
  //                 theme,
  //                 intl,
  //               ),
  //             }}
  //             options={{
  //               iconLayout: 'default#imageWithContent',
  //               iconImageHref: pinImage,
  //               draggable: false,
  //               iconImageSize: returnZoneSize(zone) || [40, 40],
  //               iconImageOffset: returnZoneOffset(zone) || [-20, -20],
  //               zIndex: zone.count > 1000 ? 5000 : 1000,
  //               zIndexHover: 5000
  //             }}
  //             onClick={selectZoneHandler(zone)}
  //             onMouseenter={zoneOnEnterHandler(zone)}
  //             onMouseleave={zoneOnLeaveHandler(zone)}
  //           />
  //           )}
  //         </>
  //       ))}
  //       {isMashMode && mapZoom >= mapZoomBreakPoints.max - 2 && mash[activeMash].map((lines) => (
  //         <Polyline
  //           key={`border-${lines.id}`}
  //           geometry={lines}
  //           options={{
  //             balloonCloseButton: false,
  //             strokeColor: '#06AAF2',
  //             strokeWidth: 4,
  //             strokeOpacity: 0.5,
  //           }}
  //         />
  //       ))}
  //       {isLinesMode && !createPoint && mapZoom >= mapZoomBreakPoints.max - 2 && !!displayedPins.length && connectedLinesCoordinates && connectedLinesCoordinates.map((lines) => (
  //         <Polyline
  //           key={`border-${lines.id}`}
  //           geometry={lines}
  //           options={{
  //             balloonCloseButton: false,
  //             strokeColor: '#000000',
  //             strokeWidth: 3,
  //           }}
  //         />
  //       ))}
  //       {isDisplayElements && !!pointsArray.length && (
  //         <>
  //           {pointsArray.map((zone) => (
  //             <GeoObject
  //               key={`${zone.id}-zone`}
  //               geometry={{
  //                 type: 'Point',
  //                 coordinates: zone.location,
  //               }}
  //               properties={{
  //                 iconContent: renderJSXToString(
  //                   zone.type === CITY ? ZoneIcon : hoverSelectedPins(zone, true),
  //                   zone,
  //                   theme,
  //                   intl,
  //                 ),
  //               }}
  //               options={{
  //                 iconLayout: 'default#imageWithContent',
  //                 iconImageHref: pinImage,
  //                 draggable: false,
  //                 iconImageSize: zone.type === CITY ? (returnZoneSize(zone) || [40, 40]) : [18, 18],
  //                 iconImageOffset: zone.type === CITY ? (returnZoneOffset(zone) || [-20, -20]) : [-9, -9],
  //               }}
  //             />
  //           ))}
  //         </>
  //       )}
  //     </StyledMap>
  //   </StyledYMaps>
  // )
}

YandexMap.defaultProps = {
  pinnedNode: {},
  defaultState: { center: [55.75, 37.57], zoom: 9, controls: [] },
  clustersOptions: clusterOptions,
  zones: pt.objectOf(pt.object),
  regionsWithPins: false,
  hoveredZoneByID: null,
  hoveredPinByID: null,
  createPoint: false,
  isDisplayElements: false,
  pointsArray: [],
  displayElements: [],
  updateMapZoom: noop,
  updateMapCenter: noop,
  handleOpenPassport: noop,
  setSelectedZoneIdentifier: noop,
  onClick: noop,
  pinOnEnterHandler: noop,
  pinOnLeaveHandler: noop,
  selectNodeByZone: noop,
  handleClickZone: noop,
  yandexKey: '',
  isMonitoring: false,
}

YandexMap.propTypes = {
  pinnedNode: pt.objectOf(pt.shape({
    treeElementId: pt.oneOfType([pt.string, pt.number]),
  })),
  defaultState: pt.shape({
    zoom: pt.number,
    center: pt.arrayOf(pt.number),
  }),
  clustersOptions: pt.shape({
    groupByCoordinates: pt.bool,
    clusterDisableClickZoom: pt.bool,
    clusterHideIconOnBalloonOpen: pt.bool,
    geoObjectHideIconOnBalloonOpen: pt.bool,
    clusterNumbers: pt.arrayOf(pt.number),
  }),
  zones: {},
  mapCenter: pt.arrayOf(pt.number).isRequired,
  hoveredPinByID: pt.number,
  hoveredZoneByID: pt.number,
  mapZoom: pt.number.isRequired,
  regionsWithPins: pt.bool,
  isDisplayElements: pt.bool,
  updateMapZoom: pt.func,
  updateMapCenter: pt.func,
  handleOpenPassport: pt.func,
  onClick: pt.func,
  pinOnEnterHandler: pt.func,
  pinOnLeaveHandler: pt.func,
  handleClickZone: pt.func,
  setSelectedZoneIdentifier: pt.func,
  createPoint: pt.bool,
  pointsArray: pt.arrayOf(pt.object),
  displayElements: pt.arrayOf(pt.object),
  yandexKey: pt.string,
  isMonitoring: pt.bool,
}

export default injectIntl(memoWithName(YandexMap))
