import styled from 'styled-components'

export default styled.div`
  display: flex;
  flex-wrap: wrap;
  padding-left: 15px;
  max-width: 1155px;
  ${({ loading }) => loading && `
    align-items: center;
    justify-content: center;
    max-width: 100%;
    height: 100%;
    padding-left: 0px;
  `};
  @media (max-width: 910px) {
    padding-top: 50px;
  }
`
