import { GET_SEARCH_FIELDS_OPTIONS } from '@/store/actions/dictionaries/index'

export const requestGetSearchFieldsOptions = (payload) => ({
    type: GET_SEARCH_FIELDS_OPTIONS.REQUEST,
    payload
})

export const responseGetSearchFieldsOptions = (data) => ({
    type: GET_SEARCH_FIELDS_OPTIONS.SUCCESS,
    payload: data,
})

export const errorGetSearchFieldsOptions = (error) => ({
    type: GET_SEARCH_FIELDS_OPTIONS.ERROR,
    payload: error,
})