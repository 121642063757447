import React, { useCallback, useEffect } from 'react'
import useReduxTable from '@/hooks/useReduxTable'
import { FormattedMessage as Lang } from 'react-intl'
import { SCHEDULE_MANAGER_FAILURE_LOG_TABLE } from '@/store/actions/scheduleManager'
import { getFailureLogTableParameters } from '@/store/selectors/scheduleManager/failureLog'
import DutyTable from '@/components/blocks/DutyTable'
import {
  errorStatusOptions,
  failureLogFields
} from '@/constants/tablesConfig/scheduleManager'

const ScheduleFailureLog = ({
  data,
  loadingTable,
  dates,
  errorStatus,
  scheduleSelectedNode,

  requestGetTable,
  setDatesRange,
  setErrorStatus
}) => {

  const tableProps = useReduxTable(SCHEDULE_MANAGER_FAILURE_LOG_TABLE, getFailureLogTableParameters)

  useEffect(() => {
    requestGetTable()
  }, [requestGetTable, scheduleSelectedNode])

  const handleGetData = useCallback((datesRange) => {
    setDatesRange(datesRange)
    requestGetTable()
  }, [requestGetTable, setDatesRange])

  return (
    <DutyTable
      {...tableProps}
      withDates
      dates={dates}
      fields={failureLogFields}
      getTableData={handleGetData}
      onUpdateData={requestGetTable}
      data={data}
      status={errorStatus}
      statusTitle={<Lang id="scheduleManager.titles.errorStatus" />}
      setStatus={setErrorStatus}
      settingsStatus={errorStatusOptions}
      isDataLoading={loadingTable}
    />
  )
}

export default ScheduleFailureLog
