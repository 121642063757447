import { call } from 'redux-saga/effects'

import { createRefreshEquipmentChannel } from './channels'
import createNotifications from '@/helpers/notification';

function* refreshEquipment() {
  try {
    yield call(createRefreshEquipmentChannel)
  } catch (error) {
    console.log("function*refreshEquipment -> error", error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось обновить статус объекта.\nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default refreshEquipment