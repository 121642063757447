import {
  takeLatest, put, select
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { ALARM_MANAGER_SET_ALL_READ_ALARM_ENDPOINT } from '@/constants/apiRoutes'
import { SET_SELECTED_ALL_ELEMENTS } from '@/store/actions/alarmManager'
import {
  requestAlarmManagerTable,
} from '@/store/actions/alarmManager/getTable'
import {
  requestCheckAllAlarms,
} from '@/store/actions/alarmManager/checkAllAlarms'
import {
  requestGetAlarmsCount,
} from '@/store/actions/alarmManager/getAlarmsCount'
import createNotifications from '@/helpers/notification'
import {
  getEndDate,
  getEventStatus,
  getEventTypes,
  getStartDate,
} from '@/store/selectors/alarmManager'
import { INSTALLED, UNINSTALLED } from '@/constants/objectStatuses'
import moment from 'moment/moment'
import { getSelectedNode } from '@/store/selectors/appSettings'
import getIsReadByStatus from '@/helpers/alarmsHelper'

function* setEventAllRead({ payload }) {
  const { id } = payload
  const eventTypes = yield select(getEventTypes)
  const startDate = yield select(getStartDate)
  const endDate = yield select(getEndDate)
  const node = yield select(getSelectedNode)
  const eventStatus = yield select(getEventStatus)
  const body = {
    objectStates: [INSTALLED, UNINSTALLED],
    alertTypes: eventTypes,
    fromDate: startDate ? moment(startDate).format('DD.MM.YYYY') : null,
    toDate: endDate ? moment(endDate).format('DD.MM.YYYY') : null,
    parentId: node.id,
    isRead: getIsReadByStatus(eventStatus),
  }
  try {
    yield request({
      url: ALARM_MANAGER_SET_ALL_READ_ALARM_ENDPOINT,
      method: 'put',
      body
    })
    yield put(requestGetAlarmsCount())
    yield put(requestAlarmManagerTable())
    yield put(requestCheckAllAlarms({ id }))
  } catch (error) {
    console.log('TCL: function*setEventAllRead -> error', error)
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Невозвможно отметить все неисправности как прочитанные. Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(SET_SELECTED_ALL_ELEMENTS, setEventAllRead)
}
