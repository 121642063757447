import {
  LIGHT,
  CONTROLLER,
  LIGHT_SENSOR,
} from '@/constants/objectTypes'
import trash from './trash'
import light from './light'
import controller from './controller'
import lightSensor from './lightSensor'
import switchingGroup from './switchingGroup'
import {
  TRASH,
  CONTROL_CUPBOARD,
  STREETLIGHT,
  STREETLIGHT_CONTROL_CABINET,
  WASTE_CONTAINER,
  SWITCHING_GROUP
} from '@/constants/instalationPassport/types'

export default {
  [TRASH]: trash,
  [WASTE_CONTAINER]: trash,
  [LIGHT]: light,
  [STREETLIGHT]: light,
  [CONTROLLER]: controller,
  [CONTROL_CUPBOARD]: controller,
  [STREETLIGHT_CONTROL_CABINET]: controller,
  [LIGHT_SENSOR]: lightSensor,
  [SWITCHING_GROUP]: switchingGroup,
}
