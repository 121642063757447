import {
  select, takeLatest, put, all
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_MAP_OBJECTS } from '@/store/actions/maps'
import { getTreeDataOld } from '@/store/selectors/maps/socketEvents'
import {
  getSelectedNode,
  getGlobalFilters,
} from '@/store/selectors/appSettings'
import {
  successGetMapObjects,
  errorGetMapObjects,
} from '@/store/actions/maps/socketEvents'
import {
  INSTALLATION_ROOT_ELEMENT,
  generateGetParentTree,
  INSTALLATION_ELEMENT_CHILDREN,
  generateGetGeoZoneCardStatistic,
  generateGetZonesStatistic
} from '@/constants/apiRoutes'
import { CITY, COUNTRY } from '@/constants/objectTypes'

import { setTreePath } from '@/data/mapData'
import getPinsByTree from '@/helpers/formaters/getPinsByTree'
import generateZoneBorder from '@/helpers/generateZoneBorder'
import createNotifications from '@/helpers/notification'
import russiaBorders from '@/data/regionCoords/russia'
import { INSTALLED } from '@/constants/objectStatuses'
import get from 'lodash/get'
import { treeHandlers } from 'react-hyper-tree'

export const searchInTreeById = (element = {}, matchingId) => {
  if (element.id === matchingId) {
    return element
  }
  if (element.children != null) {
    let i
    let result = null
    for (i = 0; result == null && i < element.children.length; i += 1) {
      result = searchInTreeById(element.children[i], matchingId)
    }
    return result
  }
  return null
}

function* getMapEquipment() {
  const { asu , objectsTypes, integrations, processes, ...statuses} = yield select(getGlobalFilters)
  const statusesTrue = Object.keys(statuses).filter((status) => statuses[status]) 
  const telemetryStatuses = statusesTrue.length === Object.keys(statuses).length ? [] : statusesTrue
  try {
    const localTree = yield select(getTreeDataOld)

    let newZones = {}

    let roots
    try {
      const { data } = yield request({
        url: INSTALLATION_ROOT_ELEMENT,
        method: 'get',
      })
      roots = yield all(data.map((element) => {
        return {
          ...element,
          type: COUNTRY,
          name: element.name,
          location: [element.point.latitude, element.point.longitude],
          border: russiaBorders,
        }
      }))
    } catch (error) {

    }
    let parentZoneSelector = []
    try {
      const { data: geoZones } = yield request( {
        url: INSTALLATION_ELEMENT_CHILDREN({
          params: {
            parentId: roots[0].id,
          },
        }),
        method: 'get',
      })
      roots[0].children = geoZones.map(item => {
        newZones[item.id] = {
          ...item,
          location: [item.point.latitude, item.point.longitude],
          type: CITY,
          zoneSelector: [item.id]
        }
        parentZoneSelector.push(item.id)
        return {
          ...item,
          location: [item.point.latitude, item.point.longitude],
          type: CITY,
          zoneSelector: [item.id]
        }
      })
      roots[0].zoneSelector = parentZoneSelector
    } catch (error) {

    }

    try {
      const {data} = yield request( {
        url: generateGetZonesStatistic({   
          params: {
            parentId: roots[0].id,
            includeParent: true,
            integrationTypes: asu,
            installationTypes: objectsTypes,
            integrationIds: integrations,
            telemetryStatuses : telemetryStatuses,
          }}),
        method: 'get'
      })
      data.forEach((zone) => {
        if (newZones[zone.id] || zone.id === roots[0].id) {
          newZones[zone.id] = {
            ...newZones[zone.id],
            statistic: {
              NO_PROBLEM: zone.ok,
              WARNING: zone.warning,
              ERROR: zone.error,
              UNDEFINED: zone.undefined
            }
          }
        }
      })
    } catch (error) {

    }
    const getNodeChildren = (node, data, geoZoneId) => {
      const selectType = (element) => {
        if (!element.parentId) {
          return 'country'
        } else if (!element.installationType) {
          return 'UNKNOWN'
        } else {
          return element.installationType
        }
      }
      let children = data.filter(item => item.parentId === node.id)
      if (children.length) {
        children.forEach(item => {
          if (item.point) {
            item.location = [item.point.latitude, item.point.longitude]
          }
          if (!!node.integrationType || !!node.system) {
            item.system = node.integrationType || node.system
          }
          item.geoZoneId = geoZoneId
          item.type = selectType(item)
          item.children = getNodeChildren(item, data, geoZoneId)
        })
      }
      return children
    }
    try {
      const data = yield request( {
        url: generateGetParentTree({
          params: {
           pageSize: 999999,
           objectStates: [INSTALLED],
           integrationTypes: asu,
           installationTypes: objectsTypes,
           integrationIds: integrations,
           telemetryStatuses : telemetryStatuses,
          }
        }),
        method: 'get',
      })
      const formattedRequestData = data.data.content
      roots[0].children.forEach(item => {
        item.children = getNodeChildren(item, formattedRequestData, item.id)
      })
    } catch (error) {

    }
    let statistic
    try {
      const { data } = yield request({
        url: generateGetGeoZoneCardStatistic({
          id: roots[0].id,
          params: {
            objectStates: [INSTALLED]
          }
        }),
        method: 'get',
      })
      statistic = data
    } catch(error) {}

    const treeWithPaths = setTreePath({ data: roots ? roots[0] : {} })
    const treeWithPathsOld = setTreePath({ data: localTree })
    const pins = getPinsByTree(treeWithPaths)
    const newZonesWithBorders = Object.keys(newZones).reduce((accumulator, zoneKey) => {
      const selectors = (newZones[zoneKey] || {}).zoneSelector || []
      const relatedPins = pins.filter((pin) => selectors.includes(pin.id))
      if (newZones[zoneKey].name === 'Россия') {
        return {
          ...accumulator,
          [newZones[zoneKey].id]: {
            ...(newZones[zoneKey] || {}),
          },
        }
      }
      return {
        ...accumulator,
        [newZones[zoneKey].id]: {
          ...(newZones[zoneKey] || {}),
          border: generateZoneBorder(relatedPins),
        },
      }
    }, {})

    yield put(successGetMapObjects({
      tree: [treeWithPaths],
      treeOld: treeWithPathsOld,
      pins,
      countOfPins: statistic?.objectElementCount,
      countOfProjects: statistic?.projectCount,
      zones: newZonesWithBorders,
    }))

    const selectedNode = yield select(getSelectedNode)
    const selectedNodePath = get(selectedNode, 'original.options.path', null)

    setTimeout(() => {
      const tree = treeHandlers.trees['maps-tree']
      if (selectedNodePath && tree && tree.handlers) {
        tree.handlers.setSelectedByPath(selectedNodePath)
      }
    })

  } catch (error) {
    console.log('error', error)
    yield put(errorGetMapObjects())
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Повторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* root() {
  yield takeLatest(GET_MAP_OBJECTS.REQUEST, getMapEquipment)
}
