import { connect } from 'react-redux'
import { getCityProcessesForUser } from '@/store/selectors/dictionaries/cityProcesses'
import {
  startDeleteWidgetFromGeneralPage,
  startSetWidgetSettings,
} from '@/store/actions/appSettings'
import { requestAddIntegration } from '@/store/actions/serviceDeskMyCity/addIntegration'
import { requestGetInfoWidget } from '@/store/actions/serviceDeskMyCity/getInfoWidget'
import { requestDeleteIntegration } from '@/store/actions/serviceDeskMyCity/deleteIntegration'
import { setUserServiceDesk } from '@/store/actions/serviceDeskMyCity'
import { getIntegrationStatus, getUserData, getUserApplications } from '@/store/selectors/appSettings'
import WidgetSideBar from '@/components/regions/sidebars/WidgetSideBar'
import { getTreeForWidgets } from '@/store/selectors/widgetDashboard'
import { requestGetAllObjectTypes } from '@/store/actions/dictionaries/objectTypes'

export default connect(
  (state) => ({
    tree: getTreeForWidgets(state),
    cityProcesses: getCityProcessesForUser(state),
    serviceDeskStatus: getIntegrationStatus(state),
    loggedInUser: getUserData(state),
    userApplications: getUserApplications(state),
  }),
  (dispatch) => ({
    onDeleteWidget: (id, widgetType, integrationId) => dispatch(
      startDeleteWidgetFromGeneralPage(id, widgetType, integrationId),
    ),
    onSetSettings: (
      id,
      settings,
      widgetType,
    ) => dispatch(startSetWidgetSettings(id, settings, widgetType)),
    addIntegration: (payload) => dispatch(requestAddIntegration(payload)),
    requestDeleteIntegration: (payload) => dispatch(requestDeleteIntegration(payload)),
    requestGetInfoWidget: (payload) => dispatch(requestGetInfoWidget(payload)),
    setUserServiceDesk: (payload) => dispatch(setUserServiceDesk(payload)),
    requestGetAllObjectTypes: (payload) => dispatch(requestGetAllObjectTypes(payload)),
  }),
)(WidgetSideBar)
