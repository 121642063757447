import { connect } from 'react-redux'
import { connectToSocket } from '@/store/actions/socket'
import {
  getUserAccessToken,
  getRefreshTokenStatus,
  getCurrentUserData
} from '@/store/selectors/user'
import { requestGetSearchFieldsOptions } from '@/store/actions/dictionaries/searchFieldsOptions'
import { requestUserRefreshToken } from '@/store/actions/user/refreshToken'
import AppContainer from './component'

const mapStateToProps = (state) => ({
  accessToken: getUserAccessToken(state),
  refreshTokenStatus: getRefreshTokenStatus(state),
  user: getCurrentUserData(state),
})
const mapDispatchToProps = (dispatch) => ({
  connectToSocket: (data) => dispatch(connectToSocket(data)),
  requestGetSearchFieldsOptions: (payload) => dispatch(requestGetSearchFieldsOptions(payload)),
  requestUserRefreshToken: () => dispatch(requestUserRefreshToken())
})

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AppContainer)
