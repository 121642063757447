import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const LayersIcon = ({ color, onClick }) => {

  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11.9999 16.0001L19.3599 10.2701L20.9999 9.00006L11.9999 2.00006L2.99988 9.00006L4.62988 10.2701L11.9999 16.0001ZM11.9999 18.5401L4.61988 12.8101L2.99988 14.0701L11.9999 21.0701L20.9999 14.0701L19.3699 12.8001L11.9999 18.5401Z" fill="#30333A"/>
    </svg>
  )
}

LayersIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

LayersIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(LayersIcon)
