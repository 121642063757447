import widgets from '../widgets'

const {
  STATUS,
  TRASH,
  TYPE_OF_WASTE,
  CATEGORY_OF_WASTE,
  LAST_DEVASTATION_SMALL,
  LAST_CLEANING,
  NEXT_CLEANING,
  ACTIVE_POWER
} = widgets

export default [
  STATUS({}),
  TRASH({}),
  TYPE_OF_WASTE({}),
  CATEGORY_OF_WASTE({}),
  LAST_DEVASTATION_SMALL({}),
  LAST_CLEANING({}),
  NEXT_CLEANING({}),
  ACTIVE_POWER({}),
]
