import React, {
  useMemo,
} from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import debounce from 'lodash/debounce'
import { Formik, Form } from 'formik'
import { FormattedMessage as Lang } from 'react-intl'
import get from 'lodash/get'
import LabelWithIcon from '@/components/blocks/LabelWithIcon'
import TextField from '@/components/fields/TextField'
import CoreIcons from '@/components/icons/core'
import PortalTooltip from '@/components/blocks/PortalTooltip'
import SelectField from '@/components/fields/SelectField'
import { createIntegration } from '@/constants/validationFields'
import CREATE_INTEGRATION_NAMES from '@/constants/forms/createIntegration'
import Loader from '@/components/blocks/Loader'
import { DEBOUNCE_DELAY_LARGE } from '@/constants/time'
import {
  TEXT,
  SELECT,
} from '@/constants/semanticNames'
import { integrationConfig } from './config'

import {
  InputAndLabelContainer,
  IconContainer,
  Container,
  Header,
  HeaderTitle,
  FieldsContainer,
  Button,
} from './styles'

const IntegrationForm = ({
  integrationTypes,
  selectedNode,
  setUrlFormValues,
  submit,
  submitError,
  title,
  onCancel,

  formValues,
  edit,
}) => {
  const formattedConfig = useMemo(() => integrationConfig.map((element) => {
    if (element.selector === CREATE_INTEGRATION_NAMES.TYPE) {
      return {
        ...element,
        options: integrationTypes,
      }
    }
    return element
  }), [integrationTypes])
  const localInitialValues = useMemo(() => {
    const initialValues = integrationConfig.reduce((accumulator, field) => ({
      ...accumulator,
      [field.selector]: get(selectedNode, field.selector, ''),
    }), {})
    initialValues.id = selectedNode.id
    return initialValues
  }, [selectedNode])

  const debounceSubmitHandler = (values, setSubmitting) => debounce(() => {
    setSubmitting(true)
    submit({
      formValues,
      values,
      setSubmitting,
      setUrlFormValues,
    })
  }, DEBOUNCE_DELAY_LARGE)

  const setFieldToNull = (selector, setFieldValue) => () => {
    setFieldValue(selector, '')
  }

  const renderOption = (errors, touched, isSubmitting, values, setFieldValue) => (element) => {
    const {
      selector,
      type,
      options,
      format,
      mask,
      immutable,
      editBlock,
    } = element
    return (
      <InputAndLabelContainer>
        {type === TEXT && !isSubmitting && (
          <>
            <LabelWithIcon
              isError={(touched[selector] && errors[selector])}
              title={(<Lang id={`installation.integrationForm.${selector}`} />)}
            />
            <TextField
              error={(touched[selector] && errors[selector])}
              name={selector}
              mask={mask}
              fieldProps={{
                autoComplete: 'off',
                disabled: isSubmitting || (immutable && !isSubmitting),
                format,
                onlyRead: isSubmitting,
              }}
              controls={(
                <>
                  {(get(values, selector, null)) && !(isSubmitting || isSubmitting || immutable)
                  && (
                    <PortalTooltip
                      title={(<Lang id="tooltip.erase" />)}
                      renderChildren={(
                        wrapperRef,
                        onMouseEnterHandler,
                        onMouseLeaveHandler,
                      ) => (
                        <IconContainer
                          type="cross"
                          onClick={setFieldToNull(selector, setFieldValue)}
                          ref={wrapperRef}
                          onMouseEnter={onMouseEnterHandler}
                          onMouseLeave={onMouseLeaveHandler}
                        >
                          <CoreIcons.EraserIcon />
                        </IconContainer>
                      )}
                    />
                  )}
                </>
              )}
            />
          </>
        )}
        {type === SELECT && !isSubmitting && (
          <>
            <LabelWithIcon
              isError={(touched[selector] && errors[selector])}
              title={(<Lang id={`installation.integrationForm.${selector}`} />)}
            />
            <SelectField
              error={(touched[selector] && errors[selector])}
              name={selector}
              mask={mask}
              withSearch
              placeholder="Выбрать"
              options={options || []}
              light
              disabled={isSubmitting || (edit && editBlock)}
              fieldProps={{
                autoComplete: 'off',
                format,
                onlyRead: isSubmitting,
              }}
            />
          </>
        )}
      </InputAndLabelContainer>
    )
  }

  const preverntDefaultBehaviour = (event) => event.preventDefault()

  return (
    <Formik
      key={JSON.stringify(localInitialValues)}
      enableReinitialize
      initialValues={localInitialValues}
      validationSchema={createIntegration}
      validateOnMount
      validateOnChange
      render={({ touched, errors, isSubmitting, values, setFieldValue, handleChange, setSubmitting, isValid,
      }) => (
        <Form onSubmit={preverntDefaultBehaviour} id="integrationForm" name="integrationForm">
          <Container>
            <Header>
              <HeaderTitle>
                {title}
              </HeaderTitle>
              <Button type="green" onClick={isValid ? debounceSubmitHandler(values, setSubmitting) : submitError} isSubmitting={isSubmitting}>
                <Lang id="installation.integrationForm.save" />
              </Button>
              <Button type="red" onClick={onCancel}>
                <Lang id="installation.integrationForm.cancel" />
              </Button>
            </Header>
            {(edit && !localInitialValues[CREATE_INTEGRATION_NAMES.NAME])
              || integrationTypes.length === 0|| isSubmitting
              ? (
                <>
                  <Loader />
                </>
              )
              : (
                <FieldsContainer>
                  {formattedConfig.map(renderOption(touched, errors, isSubmitting, values, setFieldValue, handleChange))}
                </FieldsContainer>
              )}
          </Container>
        </Form>
      )}
    />
  )
}

IntegrationForm.defaultProps = {
  selectedNode: {},
  setUrlFormValues: noop,
  submit: noop,
  submitError: noop,
  onCancel: noop,
  ghostMode: true,
  title: false,
  integrationTypes: [],
  formValues: {},
  getParentZoneOptions: noop,
  parentElements: [],
  edit: false,
}

IntegrationForm.propTypes = {
  selectedNode: pt.objectOf(pt.object),
  setUrlFormValues: pt.func,
  submit: pt.func,
  submitError: pt.func,
  onCancel: pt.func,
  ghostMode: pt.bool,
  title: pt.string,
  integrationTypes: pt.shape({
    value: pt.string,
    title: pt.string,
  }),
  formValues: pt.objectOf(pt.string),
  getParentZoneOptions: pt.func,
  parentElements: pt.arrayOf(pt.object),
  edit: pt.bool,
}

export default IntegrationForm
