import { GET_SCHEDULE_MANAGER_FAILURE_LOG_TABLE } from './index'

export const requestScheduleManagerFailureLogTable = (payload) => ({
  type: GET_SCHEDULE_MANAGER_FAILURE_LOG_TABLE.REQUEST,
  payload,
})

export const successScheduleManagerFailureLogTable = (payload) => ({
  type: GET_SCHEDULE_MANAGER_FAILURE_LOG_TABLE.SUCCESS,
  payload,
})

export const errorScheduleManagerFailureLogTable = (payload) => ({
  type: GET_SCHEDULE_MANAGER_FAILURE_LOG_TABLE.ERROR,
  payload,
})