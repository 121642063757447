import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'
import { EmptyDataStyled } from './styles'

const EmptyDataState = ({title}) => {
    const emptyDataImage = require('@/assets/images/no-data.png')

    return (
        <EmptyDataStyled>
            <img alt="No data" src={emptyDataImage} />
            {title || <Lang id="notFound.nothingToShow" />}
        </EmptyDataStyled>
    )
}

export default EmptyDataState
