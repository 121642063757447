import React, { useRef } from 'react'
import noop from 'lodash/noop'
import pt from 'prop-types'
import useOutsideClickHandler from '@/hooks/useOutsideClickHandler'
import {
    Container,
    ButtonContainer,
    ContentContainer,
    Wrapper,
    StyledPortalTooltip,
} from './styles'

const IconDropdown = ({
   content,
   icon: Icon,
   isOpen,
   onClick,
   disabled,
   isActive,
   title
}) => {
   const ref = useRef(null)
   useOutsideClickHandler({
     ref,
     callback: onClick,
     opened: isOpen,
   })

    return (
        <Wrapper ref={ref}>
            <Container
                isOpen={isOpen}
                onClick={disabled ? noop : onClick}
                disabled={disabled}
                active={isOpen}
            >
                <StyledPortalTooltip
                    title={title}
                    renderChildren={(wrapperRef, onMouseEnterHandler, onMouseLeaveHandler) => (
                        <>
                            <ButtonContainer
                                isOpen={isOpen}
                                ref={wrapperRef}
                                isActive={isActive}
                                onMouseEnter={onMouseEnterHandler}
                                onMouseLeave={onMouseLeaveHandler}
                            >
                                <Icon/>
                            </ButtonContainer>
                        </>
                    )}
                />
            </Container>
            {isOpen && (
                <ContentContainer>
                    {content}
                </ContentContainer>
            )}
        </Wrapper>
    )
}

IconDropdown.propTypes = {
    content: pt.element,
    icon: pt.element,
    title: pt.oneOfType([
        pt.string,
        pt.element,
    ]),
    isOpen: pt.bool,
    onClick: pt.func,
    disabled: pt.bool,
    isActive: pt.bool,
}

IconDropdown.defaultProps = {
    isOpen: false,
    onClick: noop,
    content: null,
    title: '',
    icon: null,
    disabled: false,
    isActive: false,
}

export default IconDropdown
