import {
    take,
    call,
    select,
    put
} from 'redux-saga/effects'
import { createChangeObjectStateChannel } from '@/store/sagas/maps/events/channels'
import createNotifications from '@/helpers/notification'
import { getSelectedNode } from '@/store/selectors/appSettings'
import { treeHandlers } from 'react-hyper-tree'
import { setDisplayElementAndTelemetryRequestStatus } from '@/store/actions/installation'
import REQUEST_STATUSES from '@/constants/requests'
import { requestGetObjectTelemetry } from '@/store/actions/installation/getObjectTelemetry'

function* changeObjectState() {
    try {
        const channel = yield call(createChangeObjectStateChannel)
        while (true) {
            const eventData = yield take(channel)
            const selectedNode = yield select(getSelectedNode)
            if (selectedNode.objectIdOuter === eventData.objectId) {
                const tree = treeHandlers.trees['installations-tree']
                const parent = tree.instance.getNodeById(selectedNode.parentId)
                if (Object.keys(parent).length) {
                    const parentChildren = yield call(parent.data.getChildren, parent)
                    tree.handlers.setRawChildren(parent, parentChildren, 'first', true)
                    tree.handlers.rerender()
                }
                yield put(requestGetObjectTelemetry({
                    aliasId: selectedNode.asuId,
                    integrationType: selectedNode.integrationType,
                    objectId: selectedNode.objectIdOuter,
                    objectType: selectedNode.installationType
                }))
                yield put(setDisplayElementAndTelemetryRequestStatus(REQUEST_STATUSES.NOT_REQUESTED))
            }
        }
    } catch (error) {
        console.log('TCL: function*changeObjectState -> error', error)
        const toast = createNotifications()
        toast({
            title: 'Ошибка операции!',
            description: 'Не удалось изменить статус объекта.',
            type: 'error',
        })
    }
}

export default changeObjectState
