import styled from 'styled-components'

export const Container = styled.div`
  min-width: 852px;
  min-height: 640px;
`

export const Header = styled.div`
  height: 48px;
  border-bottom: 1px solid rgba(16, 97, 223, 1);
  background-color: rgba(16, 97, 223, 0.1);
  display: flex;
  align-items: center
`
export const Title = styled.div`
  color: rgba(16, 97, 223, 1);
  font-size: 1.4rem;
  font-weight: 500;
  padding-left: 20px;
`
export const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 72px;
  width: 100%;
  border-top: 1px solid rgba(221, 221, 221, 1);
  background-color: rgba(221, 221, 221, 0.1);
  position: absolute;
  bottom: 0;
`
export const ButtonWrapper = styled.div`
  width: 106px;
  height: 40px;
`

export const SideBar = styled.div`
  min-width: 188px;
  height: 520px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid rgba(221, 221, 221, 1);
  padding-top: 5px;
`
export const NumberVersion = styled.div`
  width: 100%;
  font-weight: 500;
  font-size: 1.3rem;
  text-align: start;
  color: rgba(62, 60, 62, 0.6);
  padding: 4px 6px 4px 10px;
  cursor: pointer;
  ${({active}) => active &&`
  background-color: #1061DF;
  color: #FAFBFB;
  `}
`

export const Content = styled.div`
  display: flex;
`
export const Descriptions = styled.div`
  padding: 10px 10px 10px 20px;
  ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: #3E3C3E;
    font-weight: 500;
    font-size: 1.3rem;
    gap: 5px;
    text-align: start;
  }
`

export const DescriptionsTitle = styled.div`
  color: #3E3C3E;
  font-size: 1.7rem;
  font-weight: 500;
  display: flex;
  align-items: flex-start;
  margin-left: -13px;
  padding-top: 10px;
  padding-bottom: 10px;
`
