import React, { useState, useMemo } from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import {
  OK,
  WARNING,
  ERROR,
  INFO,
} from '@/constants/objectStatuses'
import IntegrationStatisticWidget from '@/components/widgets/IntegrationStatisticWidget'
import WidgetSetUpp from '@/components/widgets/WidgetSetUpp'
import Container from './styles'
import cloneDeep from 'lodash/cloneDeep'

const ACYWidget = ({
  onSubmit,
  settings,
  systemAlias,
  intl
}) => {
  const [isSettings, setIsSettings] = useState(false)
  const SwitcherHandler = () => {
    setIsSettings(!isSettings)
  }

  const onFormSubmit = (data) => {
    onSubmit(systemAlias, data)
    setIsSettings(!isSettings)
  }

  const statusesCounts = cloneDeep(systemAlias)
  delete statusesCounts.type

  const showWidget = useMemo(() => (
    !!(statusesCounts[OK]
      + statusesCounts[WARNING]
      + statusesCounts[ERROR]
      + statusesCounts[INFO])
  ), [statusesCounts])

  if (showWidget) {
    return (
      <Container>
        {isSettings
          ? (
            <WidgetSetUpp
              onDenie={SwitcherHandler}
              onSubmit={onFormSubmit}
              refreshTime={settings}
            />
          )
          : (
            <IntegrationStatisticWidget
              title={intl.messages[`globalNames.integrationType.${systemAlias.type}`]}
              statusesCounts={statusesCounts}
              onSettings={SwitcherHandler}
            />
          )}
      </Container>
    )
  }
  return React.Fragment
}

ACYWidget.defaultProps = {
  onSubmit: noop,
  system: '',
  systemAlias: '',
  settings: 0,
}

ACYWidget.propTypes = {
  onSubmit: pt.func,
  system: pt.string,
  systemAlias: pt.string,
  settings: pt.number,
  node: pt.objectOf(pt.object).isRequired,
  nodeStatistic: pt.arrayOf(pt.object).isRequired,
}

export default ACYWidget
