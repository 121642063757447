import { handleActions } from 'redux-actions'

import {
    GET_ALL_CONNECTED_INTEGRATIONS,
} from '@/store/actions/dictionaries'

const initialState = {
    data: [],
}

export const reducer = handleActions(
    {
        [GET_ALL_CONNECTED_INTEGRATIONS.SUCCESS]: (state, { payload }) => ({
            ...state,
            data: payload,
        }),
        [GET_ALL_CONNECTED_INTEGRATIONS.ERROR]: (state) => ({
            ...state,
        }),
    },
    initialState,
)

export default reducer