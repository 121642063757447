import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import memoWithName from '@/hocs/memoWithName'

const AboutAppIcon = ({ color, onClick }) => {

  return (
    <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10.5 2C6.08172 2 2.5 5.58172 2.5 10C2.5 14.4183 6.08172 18 10.5 18C14.9183 18 18.5 14.4183 18.5 10C18.5 5.58172 14.9183 2 10.5 2ZM0.5 10C0.5 4.47715 4.97715 -4.76837e-07 10.5 0C16.0228 4.76837e-07 20.5 4.47715 20.5 10C20.5 15.5228 16.0228 20 10.5 20C4.97715 20 0.5 15.5228 0.5 10ZM10.5 5.625C11.0523 5.625 11.5 6.07272 11.5 6.625V6.66455C11.5 7.21684 11.0523 7.66455 10.5 7.66455C9.94771 7.66455 9.5 7.21684 9.5 6.66455V6.625C9.5 6.07272 9.94771 5.625 10.5 5.625ZM10.5 9C11.0523 9 11.5 9.44772 11.5 10L11.5 14.5C11.5 15.0523 11.0523 15.5 10.5 15.5C9.94771 15.5 9.5 15.0523 9.5 14.5V10C9.5 9.44771 9.94772 9 10.5 9Z" fill="#838D99"/>
    </svg>
  )
}

AboutAppIcon.propTypes = {
  color: pt.string,
  onClick: pt.func,
}

AboutAppIcon.defaultProps = {
  color: null,
  onClick: noop,
}

export default memoWithName(AboutAppIcon)