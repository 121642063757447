import {
  put, takeLatest,
} from 'redux-saga/effects'
import request from '@/helpers/axios'
import { GET_CONTROL_DATA_URL } from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import { GET_CONTROL_DATA } from '@/store/actions/objectsGroups'
import {
  errorGetControlData,
  successGetControlData
} from '@/store/actions/objectsGroups/getControlData'

function* getControlData({ payload }) {
  try {
    const { groupId } = payload
    const response = yield request({
      url: GET_CONTROL_DATA_URL({
        params: {
          groupId
        }
      }),
      method: 'get',
    })
    yield put(successGetControlData(response.data))
  } catch (error) {
    yield put(errorGetControlData(error))
    const toast = createNotifications()
    toast({
      title: 'Ошибка операции!',
      description: 'Не удалось получить данные управления. \nПовторите попытку позже.',
      type: 'error',
    })
  }
}

export default function* () {
    yield takeLatest(GET_CONTROL_DATA.REQUEST, getControlData)
}
