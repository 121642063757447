import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'
import get from 'lodash/get'

export const getScheduleManagerFailureLog = createSelector(
  getState,
  (state) => get(state, 'scheduleManagerFailureLog', {}),
)

export const getFailureLogTableData = createSelector(
  getScheduleManagerFailureLog,
  (scheduleManagerFailureLogState) => get(scheduleManagerFailureLogState, 'data', []),
)

export const getFailureLogTableParameters = createSelector(
  getScheduleManagerFailureLog,
  (scheduleManagerFailureLogState) => get(scheduleManagerFailureLogState, 'table', {}),
)

export const getFailureLoadingTable = createSelector(
  getScheduleManagerFailureLog,
  (scheduleManagerFailureLogState) => get(scheduleManagerFailureLogState, 'loadingTable'),
)

export const getFailureDates = createSelector(
  getScheduleManagerFailureLog,
  (scheduleManagerFailureLogState) => get(scheduleManagerFailureLogState, 'dates', {}),
)

export const getFailureLogStartDate = createSelector(
  getFailureDates,
  (scheduleManagerFailureLogState) => get(scheduleManagerFailureLogState, 'start', null),
)

export const getFailureLogEndDate = createSelector(
  getFailureDates,
  (scheduleManagerFailureLogState) => get(scheduleManagerFailureLogState, 'end', null),
)

export const getErrorStatus = createSelector(
  getScheduleManagerFailureLog,
  (scheduleManagerFailureLogState) => get(scheduleManagerFailureLogState, 'errorStatus', null),
)