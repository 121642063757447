import { all } from 'redux-saga/effects'
import cityProcessesRoot from './cityProcesses'
import applicationsRoot from './applications'
import applicationsModelsRoot from './applicationsModels'
import objectTypesRoot from './objectTypes'
import exploitationOrganizationsRoot from './exploitationOrganizations'
import ownersRoot from './owners'
import connectedIntegrationsRoot from './connectedIntegrations'
import searchFieldsOptionsRoot from './searchFieldsOptions'
import objectsGroupsFilterOptionsOptionsRoot from './objectsGroupsFilterOptions'
import alertFieldsOptions from './alertFieldsOptions'

export default function* root() {
  yield all([
    cityProcessesRoot(),
    applicationsRoot(),
    applicationsModelsRoot(),
    objectTypesRoot(),
    exploitationOrganizationsRoot(),
    ownersRoot(),
    connectedIntegrationsRoot(),
    searchFieldsOptionsRoot(),
    alertFieldsOptions(),
    objectsGroupsFilterOptionsOptionsRoot(),
  ])
}
