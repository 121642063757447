import { createSelector } from 'reselect'
import { getState } from '@/store/reducers'
import get from 'lodash/get'

export const getSearch = createSelector(
  getState,
  (state) => get(state, 'search', {}),
)
export const getTableData = createSelector(
  getSearch,
  (searchState) => get(searchState, 'data', []),
)
export const getLoadingTable = createSelector(
  getSearch,
  (searchState) => get(searchState, 'loadingTable'),
)
export const getSearchTableParameters = createSelector(
  getSearch,
  (searchState) => get(searchState, 'table', {}),
)
export const getSearchValues = createSelector(
  getSearch,
  (searchState) => get(searchState, 'searchValues', {}),
)
export const getFileExportStatus = createSelector(
  getSearch,
  (searchState) => get(searchState, 'fileExportStatus'),
)