import React from 'react'
import { FormattedMessage as Lang } from 'react-intl'

export const fields = [
  {
    id: 'dateStep',
    name: <Lang id="tableFields.objectControlStatistic.dateStep" />,
    value: 'ASC',
  },
  {
    id: 'geoZone',
    name: <Lang id="tableFields.objectControlStatistic.geoZone" />,
    value: 'ASC',
  },
  {
    id: 'project',
    name: <Lang id="tableFields.objectControlStatistic.project" />,
    value: 'ASC',
  },
  {
    id: 'objectInstalled',
    name: <Lang id="tableFields.objectControlStatistic.objectInstalled" />,
    value: 'ASC',
  },
  {
    id: 'OKObject',
    name: <Lang id="tableFields.objectControlStatistic.OKObject" />,
    value: 'ASC',
  },
  {
    id: 'persents',
    name: <Lang id="tableFields.objectControlStatistic.persents" />,
    value: 'ASC',
  },
]
