import { combineReducers } from 'redux'
import cityProcessesReducer from './cityProcesses'
import applicationsReducer from './allApplications'
import objectTypesReducer from './objectTypes'
import exploitationOrganizationsReducer from './exploitationOrganizations'
import ownersReducer from './owners'
import connectedIntegrationsReducer from './connectedIntegrations'
import searchFieldsOptionsReducer from './searchFieldsOptions'
import objectsGroupsFilterOptionsReducer from './objectsGroupsFilterOptions'
import alerFildOptionsReducer from './alertFildsOptions'

export default combineReducers({
  cityProcesses: cityProcessesReducer,
  allApplications: applicationsReducer,
  objectTypes: objectTypesReducer,
  exploitationOrganizations: exploitationOrganizationsReducer,
  owners: ownersReducer,
  connectedIntegrations: connectedIntegrationsReducer,
  searchFieldsOptions: searchFieldsOptionsReducer,
  objectsGroupsFilterOptions: objectsGroupsFilterOptionsReducer,
  alerFildOptions: alerFildOptionsReducer,
})
