import { createStore, applyMiddleware } from 'redux'
import { createBrowserHistory } from 'history'
import { routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger'
import {
  persistStore,
  persistReducer,
  createTransform
} from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import runSaga from '@/store/sagas'

import { createRootReducer } from './reducers'
import * as Flatted from 'flatted'

const transformCircular = createTransform(
  (inboundState, key) => Flatted.stringify(inboundState),
  (outboundState, key) => Flatted.parse(outboundState),
)

const persistConfig = {
  key: 'sls24',
  storage,
  transforms: [transformCircular],
  whitelist: [
    'appSettings',
    'dictionaries',
    'maps',
  ],
}

const history = createBrowserHistory()
const routeMiddleware = routerMiddleware(history)
const sagaMiddleware = createSagaMiddleware()
const logger = createLogger({
  collapsed: true,
  diff: true,
})
const middleWares = [sagaMiddleware, routeMiddleware]

if (process.env.REACT_APP_REDUX_LOGGER === 'false') {
  middleWares.push(logger)
}

const persistedReducer = persistReducer(
  persistConfig,
  createRootReducer(history),
)

const store = createStore(
  persistedReducer,
  applyMiddleware(...middleWares),
)
const persistor = persistStore(store)
sagaMiddleware.run(runSaga)

export { store, persistor, history }
