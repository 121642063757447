import React, { useCallback, useMemo } from 'react';
import {
    IndicatorLine,
    IndicatorLineActive,
} from "@/components/widgets/StateIndicator/styles";
import pt from 'prop-types';
import numberWithSpaces from '@/helpers/numberWithSpaces';

const LineStatus = ({
    id,
    value,
    maxValue,
    minValue,
}) => {
    const valueNumber = useMemo(() => {
        return numberWithSpaces(Number.parseFloat(value || 0, 10).toFixed(3))
    }, [value])

    const minValueNumber = useMemo(
        () => numberWithSpaces(Number.parseFloat(minValue || 0, 10)),
        [minValue],
    )
    const maxValueNumber = useMemo(
        () => numberWithSpaces(Number.parseFloat(maxValue || 0, 10)),
        [maxValue],
    )

    const calculateIndicatorWidth = useCallback((targetValue, valueMinBorder, valueMaxBorder) => {
            const targetValueNumber = Number.parseFloat(targetValue, 10)
            if (valueMinBorder === 0 && valueMaxBorder === 0) {
                return 'withoutBorder'
            }
            if (targetValueNumber <= valueMinBorder) {
                return 0
            }
            if (targetValueNumber >= valueMaxBorder) {
                return 100
            }
            if (targetValueNumber > valueMinBorder && targetValueNumber < valueMaxBorder) {
                return ((targetValueNumber / valueMaxBorder) * 100 || 0).toFixed(0)
            }
        },
        [])

    const indicatorWidth = useMemo(
        () => calculateIndicatorWidth(
            valueNumber,
            minValueNumber,
            maxValueNumber,
        ),
        [calculateIndicatorWidth, valueNumber, minValueNumber, maxValueNumber],
    )

    const uniqueKey = useMemo(() => (`${id}-${indicatorWidth}`).replace(/\s/g, ''), [id, indicatorWidth])

    return (
        <IndicatorLine>
            <IndicatorLineActive
                uniqueKey={uniqueKey}
                widthValue={indicatorWidth}
            />
        </IndicatorLine>
    )
}

LineStatus.propTypes = {
    id: pt.string.isRequired,
    value: pt.oneOfType([pt.number, pt.string]),
    minValue: pt.oneOfType([pt.number, pt.string]),
    maxValue: pt.oneOfType([pt.number, pt.string]),
    status: pt.bool,
}
LineStatus.defaultProps = {
    id: '',
    value: 0,
    minValue: 0,
    maxValue: 0,
    status: false,
}

export default LineStatus