import {
    takeLatest
} from 'redux-saga/effects'
import get from 'lodash/get'
import request from '@/helpers/axios'
import {
    SCHEDULE_FILE_SCHEME_UPLOAD,
    SCHEDULE_ERROR_FILE_DOWNLOAD
} from '@/constants/apiRoutes'
import createNotifications from '@/helpers/notification'
import downloadFileFromBlob from '@/helpers/downloadFileFromBlob'
import { UPLOAD_SCHEDULE } from '@/store/actions/scheduleManager'

const typeToConfig = {
    'error.file.id': {
        type: 'error',
        title: 'ОШИБКА ОПЕРАЦИИ!',
        description: 'Ошибка валидации файла.',
    },
    'error.file': {
        type: 'error',
        title: 'ОШИБКА ОПЕРАЦИИ!',
        description: 'Неверный размер файла.',
    },
    'error.wrong.file.media.type': {
        type: 'error',
        title: 'ОШИБКА ОПЕРАЦИИ!',
        description: 'Неверное расширение файла.',
    },
    'error.file.not.found.required.sheet.names': {
        type: 'error',
        title: 'ОШИБКА ОПЕРАЦИИ!',
        description: 'В "xlsx" файле отсутствуют необходимые листы.',
    },
    parentElement: {
        type: 'error',
        title: 'ОШИБКА ОПЕРАЦИИ!',
        description: 'Неверный идентификатор елемента.',
    },
    undefined: {
        type: 'error',
        title: 'ОШИБКА ОПЕРАЦИИ!',
        description: 'Ошибка загрузки файла.',
    },
}

function* uploadScheduleFromFile({ payload }) {
    const { file } = payload
    const toast = createNotifications()
    try {
        toast({
            type: 'info',
            title: 'НАЧАЛО ОПЕРАЦИИ!',
            description: 'Начата загрузка и обработка файла.',
        })
        const formData = new window.FormData()
        formData.append('file', file)
        yield request({
            url: SCHEDULE_FILE_SCHEME_UPLOAD(),
            method: 'post',
            body: formData,
            headers: {
                'Content-Type': 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
            },
        })
    } catch (error) {
        const fileId = get(error, 'response.data.details[0].subject', false) === 'error.file.id'
            ? get(error, 'response.data.details[0].message', 'null')
            : false
        const type = get(error, 'response.data.details[0].subject', 'undefined')
        toast(typeToConfig[type])
        if (fileId) {
            const { data: errorFile } = yield request({
                url: SCHEDULE_ERROR_FILE_DOWNLOAD(fileId),
                method: 'get',
                options: {
                    responseType: 'blob',
                },
            })
            downloadFileFromBlob({
                blob: errorFile,
                fileName: 'SLS24_SCHEDULE_ERROR.xlsx',
            })
        }
    }
}

export default function* root() {
    yield takeLatest(UPLOAD_SCHEDULE.REQUEST, uploadScheduleFromFile)
}
