import { GET_ALERT_FIELDS_OPTIONS } from '@/store/actions/dictionaries/index'

export const requestGetAlertFieldsOptions = (payload) => ({
    type: GET_ALERT_FIELDS_OPTIONS.REQUEST,
    payload
})

export const responseGetAlertFieldsOptions = (data) => ({
    type: GET_ALERT_FIELDS_OPTIONS.SUCCESS,
    payload: data,
})

export const errorGetAlertFieldsOptions = (error) => ({
    type: GET_ALERT_FIELDS_OPTIONS.ERROR,
    payload: error,
})