import React from 'react'
import pt from 'prop-types'
import noop from 'lodash/noop'
import {
  TITLE_TO_STATUS,
  REVERSE_ICON_TO_TYPE
} from '@/constants/maps'
import {
  STATUS_COLORS,
} from '@/constants/styles/mapsMarks'
import {
  Container,
  Header,
  PrimaryTitle,
  ContentContainer,
  Row,
  IconContainer,
  MainTitle
} from './styles'

const IntegrationStatisticWidget = ({
  title,
  statusesCounts,
}) => {
  return (
    <Container>
      <Header>
        <MainTitle>
          {title}
        </MainTitle>
      </Header>
      <ContentContainer>
        {Object.keys(statusesCounts).map((element) => {
          const Icon = REVERSE_ICON_TO_TYPE[element]
          return (
            <Row>
              <IconContainer>
                {Icon && (<Icon color={STATUS_COLORS[element]} />)}
              </IconContainer>
              <PrimaryTitle status={element} flex>
                {TITLE_TO_STATUS[element]}
                :
              </PrimaryTitle>
              <PrimaryTitle status={element}>
                {statusesCounts[element]}
              </PrimaryTitle>
            </Row>
          )
        })}
      </ContentContainer>
    </Container>
  )
}

IntegrationStatisticWidget.defaultProps = {
  title: '',
  onSync: noop,
  onSettings: noop,
  refreshTime: 60,
}

IntegrationStatisticWidget.propTypes = {
  title: pt.string,
  refreshTime: pt.number,
  onSync: pt.func,
  onSettings: pt.func,
  statusesCounts: pt.objectOf(pt.number).isRequired,
}

export default React.memo(IntegrationStatisticWidget)
