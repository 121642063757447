import React, { useCallback, useEffect } from 'react'
import useReduxTable from '@/hooks/useReduxTable'
import { SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE } from '@/store/actions/scheduleManager'
import { getActivityLogTableParameters } from '@/store/selectors/scheduleManager/activityLog'
import DutyTable from '@/components/blocks/DutyTable'
import {
  actionOptions,
  activityLogFields
} from '@/constants/tablesConfig/scheduleManager'

const UserActivityLog = ({
  data,
  loadingTable,
  dates,
  action,
  scheduleSelectedNode,

  requestGetTable,
  setDatesRange,
  setAction
}) => {

  const tableProps = useReduxTable(SCHEDULE_MANAGER_ACTIVITY_LOG_TABLE, getActivityLogTableParameters)

  useEffect(() => {
    requestGetTable()
  }, [requestGetTable, scheduleSelectedNode])

  const handleGetData = useCallback((datesRange) => {
    setDatesRange(datesRange)
    requestGetTable()
  }, [requestGetTable, setDatesRange])

  return (
    <DutyTable
      {...tableProps}
      withDates
      dates={dates}
      fields={activityLogFields}
      getTableData={handleGetData}
      onUpdateData={requestGetTable}
      data={data}
      scheduleActions={action}
      setScheduleActions={setAction}
      scheduleActionsOptions={actionOptions}
      isDataLoading={loadingTable}
    />
  )
}

export default UserActivityLog
